<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-redeem bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Change Log</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <a-timeline>
                <a-timeline-item
                  v-for="(data, index) of historylogs"
                  :key="`index-${index}`"
                  :color="index == 0 ? 'green' : 'gray'"
                >
                  <div
                    :class="index == 0 ? 'bg-active-timeline' : 'bg-timeline'"
                  >
                    <a-row>
                      <a-col class="pl-3" :md="16" :lg="16" :xl="16">
                        <div :class="index == 0 ? 'fw-700-active' : 'fw-700'">
                          <div
                            v-if="data.log_message"
                            v-html="data.log_message"
                          ></div>
                          <div v-else>
                            <span
                              v-for="(title, index) of Object.keys(
                                data.new_data,
                              )"
                              :key="`index-${index}`"
                            >
                              <span>
                                Campaign ID :
                                {{ data.new_data[title].CampaignId }}
                              </span>
                              <span class="pl-2">
                                Serial :
                                {{ data.new_data[title].Serial }}
                              </span>
                              <div>
                                Edit used date from
                                {{
                                  data.original_data[
                                    title.replace('New', 'Original')
                                  ]
                                    ? formatDate(
                                        data.original_data[
                                          title.replace('New', 'Original')
                                        ].UsedDate,
                                      )
                                    : '-'
                                }}
                                postpone to
                                {{
                                  formatDate(
                                    data.new_data[title].UsedDate,
                                    'DD/MMM/YYYY',
                                  )
                                }}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div v-if="data.auditable" class="by">
                          by : {{ data.auditable.name }}
                        </div>
                      </a-col>
                      <a-col class="pl-3" :md="1" :lg="1" :xl="1">
                      </a-col>
                      <a-col :md="7" :lg="7" :xl="7">
                        <div class="by">
                          {{
                            formatDate(data.created_date, 'DD MMM YYYY - HH:mm')
                          }}
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a-timeline-item>
              </a-timeline>
            </div>
          </div>
          <div class="flex-none px-4 py-3">
            <a-button @click="dismiss(true)"> Close </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import moment from 'moment'
// import _ from 'lodash'
export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    getdate(created_date) {
      return moment(created_date).format(this.formatdate)
    },
    gettime(created_date) {
      return moment(created_date).format(this.formattime)
    },
    dismiss: function () {
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log-redeem {
  width: 35rem;
}
.documentstatus {
  margin-left: 73px;
  margin-top: -22px;
}
</style>
