<template>
  <div class="wrap-table-app">
    <!-- <a-table class="fixed-head" :dataSource="data" :columns="columns" :pagination="false" rowKey="application">
      <template v-slot:bodyCell="{ text, record, column }">
        <template v-if="column.key === 'logo'">
          <div class="logo">
            <img class="img" :src="record.application.logo" />
          </div>
        </template>
        <template v-else-if="column.key === 'actions'">
          <a class="btn" @click="selectApp(record.application)"> Manage </a>
        </template>
        <template v-else-if="column.key === 'loyal'"> - </template>
        <template v-else>
          {{ text.toLocaleString() }}
        </template>
      </template>
    </a-table> -->
    <a-table
      :columns="columns"
      :row-key="record => record.appid"
      :dataSource="app"
      :scroll="{ y: tableHeight }"
      :custom-row="handleSelectApp"
      :pagination="{
        pageSize: 30,
        showSizeChanger: false,
        hideOnSinglePage: true,
      }"
    >
      <template #name="{ record }">
        <div class="d-flex align-items-center">
          <div class="logo">
            <img :src="record.logo" alt="logo-table" @error="imageLoadError" />
          </div>
          <span>{{ record.name }}</span>
        </div>
      </template>
      <template #appid="{ record }">
        <span>{{ record.appid }}</span>
      </template>
      <template #agency_name="{ record }">
        <span>{{ record.agency_name }}</span>
      </template>
      <template #agency_id="{ record }">
        <span>{{ record.agency_id }}</span>
      </template>
    </a-table>
  </div>
</template>
<script>
import CacheHelper from '@/helper/CacheHelper.js'
var cache = new CacheHelper('cookie')
import { config } from '@/config/bzbsconfig.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import Account from '@/helper/AccountHelper'
import ConfigManager from '@/config/ConfigManager.js'
import ResponsiveMixin from '@/mixin/ResponsiveMixin'
var cacheSearch = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'TableApplication',
  props: {
    app: Object,
  },
  components: {},
  mixins: [ResponsiveMixin],
  emits: [],
  data() {
    return {
      data: [],
      dataad: [],
      dataapp: {},
      columnswidth: null,
      permissionbyapp: [],
      // columns: [
      //   {
      //     title: '',
      //     width: 80,
      //     key: 'logo',
      //   },
      //   {
      //     title: 'Application',
      //     dataIndex: 'name',
      //     key: 'name',
      //   },
      //   {
      //     title: 'Daily New',
      //     dataIndex: 'new_user',
      //     key: 'new_user',
      //   },
      //   {
      //     title: 'Daily Active',
      //     dataIndex: 'active_user',
      //     key: 'active_user',
      //   },
      //   {
      //     title: '30 Days Active',
      //     dataIndex: 'active_30day',
      //     key: 'active_30day',
      //   },
      //   {
      //     title: 'Total',
      //     dataIndex: 'total_users',
      //     key: 'total_users',
      //   },
      //   {
      //     title: 'Analytics',
      //     key: 'actions',
      //   },
      // ],
      columns: [
        {
          title: 'Application',
          dataIndex: 'name',
          // fixed: 'left',
          slots: { customRender: 'name' },
          sorter: (a, b) => ('' + a.name).localeCompare(b.name),
          width: 250,
        },
        {
          title: 'Application ID',
          dataIndex: 'appid',
          slots: { customRender: 'appid' },
          width: 250,
          sorter: (a, b) => a.appid - b.appid,
        },
        {
          title: 'Agency',
          dataIndex: 'agency_name',
          slots: { customRender: 'agency_name' },
          width: 250,
          sorter: (a, b) => ('' + a.agency_name).localeCompare(b.agency_name),
        },
        {
          title: 'Agency ID',
          dataIndex: 'agency_id',
          slots: { customRender: 'agency_id' },
          width: 180,
          sorter: (a, b) => a.agency_id - b.agency_id,
        },
        {
          title: 'Daily New',
          dataIndex: 'new_user',
          key: 'new_user',
          width: 180,
        },
        {
          title: 'Daily Active',
          dataIndex: 'active_user',
          key: 'active_user',
          width: 180,
        },
        {
          title: '30 Days Active',
          dataIndex: 'active_30day',
          key: 'active_30day',
          width: 180,
        },
        {
          title: 'Total',
          dataIndex: 'total_users',
          key: 'total_users',
          width: 120,
        },
      ],
      current: 1,
    }
  },
  computed: {
    pagesize: {
      get() {
        if (this.windowWidth < 380) {
          return 2
        } else {
          if (this.windowWidth < 576) {
            return 6
          } else {
            if (this.windowWidth < 1024) {
              return 12
            } else {
              if (this.windowWidth < 1440) {
                return 15
              } else {
                return 18
              }
            }
          }
        }
      },
    },
    tableHeight: {
      get() {
        console.log(this.windowHeight)
        if (this.windowWidth < 768) {
          console.log(this.windowHeight)
          return this.windowHeight - 360
        } else {
          console.log(this.windowHeight)
          return this.windowHeight - 307
        }
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    imageLoadError(event) {
      event.target.src =
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/blank.png'
    },
    onChangePage(pageNumber) {
      this.current = pageNumber
    },
    handleSelectApp(record) {
      return {
        onClick: () => {
          this.selectApp(record)
        },
      }
    },
    selectApp(app) {
      cache.setCache('app', {
        id: app.appid,
        name: app.name,
        logo: app.logo,
        purchase_type: app.purchase_type,
        has_coupon: app.has_coupon,
      })
      cacheSearch.removeCache('setFilter')
      cacheSearch.removeCache('setFilterTotal')
      cacheSearch.removeCache('filterUser')
      cacheSearch.removeCache('handleSearch')
      cacheSearch.removeCache('SearchRealtime')
      cacheSearch.removeCache('totalBadge')
      this.$store.commit('setIsSearch', false)
      this.getad().then(() => {
        this.$store.commit('setIsSearch', false)
        localStorage.setItem('permission', JSON.stringify(this.dataapp))
        localStorage.setItem(
          'permissionbyapp',
          JSON.stringify(this.permissionbyapp),
        )
        this.$forceUpdate()
        Account.getMSMenu2(app.appid).then(() => {
          Account.setDefaultMenu()
        })
      })
    },
    getad() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            this.dataad = res.result
            this.dataapp = this.dataad.applications.find(
              x => x.application_id == cache.getCache('app')?.id,
            )
            if (this.dataapp) {
              this.permissionbyapp = this.dataapp.features.map(x => x.name)
            }
            resolve(this.dataad)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.wrap-table-app {
  border-radius: 6px;
  background: #fff;

  .img {
    border-radius: 5px;
  }

  .btn {
    color: $color-primary;
    display: block;
    text-decoration: underline;
  }
}
</style>
