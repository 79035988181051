<template>
  <div class="manage-subject flex-1 w-full">
    <!-- <div
        v-if="datasetFeatures.includes('Manage-Subject')"
        class="mb-3 text-right createsub"
      >
        <a-button type="primary" @click="createNewSubject()"
          ><plus-outlined />Create New Subject</a-button
        >
      </div> -->
    <div class="mb-4 b-6 bg-white pl-4 pr-4 d-flex justify-content-end">
      <div class="py-2 d-flex">
        <a-select
          class="select-search"
          v-model:value="statussearch"
          placeholder="All Status"
          :options="status_option"
        />
        <a-button type="primary" @click="clicksearch()" class="ml-4 mr-3"
          ><search-outlined />Search</a-button
        >
        <div class="border-l">
          <a-tooltip placement="bottom" class="ml-3">
            <template #title>
              <div>Create New Subject</div>
            </template>
            <a-button
              class="hover btn-icon"
              @click="createNewSubject()"
              :color="'blue-1'"
            >
              <img class="left" src="@/assets/icons/button/ico-plus.svg" alt />
            </a-button>
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="wrap-table" @scroll="handleScroll">
      <!-- <VLoadingTable :value="overlaytable" /> -->
      <a-table class="fixed-head" :columns="columns" :pagination="false">
      </a-table>
      <a-collapse
        v-for="(item, index) in dataSubject.filter(
          x => x.parent_problem_type_id == 0,
        )"
        :key="item"
        v-model:activeKey="activeKey"
      >
        <template #expandIcon="{ isActive }">
          <caret-right-outlined
            v-if="topicType(item).length > 0"
            :rotate="isActive ? 90 : 0"
          />
        </template>
        <a-collapse-panel :key="index" :style="customStyle">
          <template #header>
            <div class="row" style="width: -webkit-fill-available">
              <div class="col-5">
                <a
                  class="text-link"
                  @click="$event => toggleDisplayFieldsEdit($event, itemgroup)"
                >
                  <h4 class="text-link">
                    {{ item.type }}
                  </h4>
                </a>
              </div>
              <div class="col-5"></div>
              <div class="col-2">
                <template v-for="(itemstatus, index) in status">
                  <a-tag
                    v-if="item.is_show == itemstatus.name"
                    :key="index"
                    :color="
                      itemstatus.color == 'default' ? null : itemstatus.color
                    "
                    class="tag-status"
                  >
                    <a-badge
                      v-if="item.is_show == itemstatus.name"
                      :status="itemstatus.color"
                      :text="itemstatus.text"
                    />
                  </a-tag>
                </template>
              </div>
            </div>
          </template>
          <div class="">
            <div
              class="row pb-type border-b"
              v-for="(itemtype, index2) in topicType(item)"
              :key="index2"
            >
              <div class="col-5 cursor-pointer" @click="clicktest(index2)">
                <div class="mb-16">
                  <caret-right-outlined
                    @click="clicktest(true, index2)"
                    v-if="
                      !check[index2] && topicTypeDetail(itemtype.id).length > 0
                    "
                    class="icon"
                  />
                  <caret-down-outlined
                    @click="clicktest(false, index2)"
                    v-if="
                      check[index2] && topicTypeDetail(itemtype.id).length > 0
                    "
                    class="icon"
                  />
                  {{ itemtype.type }}
                </div>
              </div>
              <div class="col-5"></div>
              <div class="col-2">
                <template v-for="(itemstatus, index) in status">
                  <a-tag
                    v-if="itemtype.is_show == itemstatus.name"
                    :key="index"
                    :color="
                      itemstatus.color == 'default' ? null : itemstatus.color
                    "
                    class="tag-status"
                  >
                    <a-badge
                      v-if="itemtype.is_show == itemstatus.name"
                      :status="itemstatus.color"
                      :text="itemstatus.text"
                    />
                  </a-tag>
                </template>
              </div>
              <div v-if="check[index2]">
                <div
                  class="row pb-detail border-t"
                  v-for="(itemtypedetail, index3) in topicTypeDetail(
                    itemtype.id,
                  )"
                  :key="index3"
                >
                  <div class="col-5">
                    <div>
                      {{ itemtypedetail.type }}
                    </div>
                  </div>
                  <div class="col-5">
                    {{ itemtypedetail.basic_solution }}
                  </div>
                  <div class="col-2 pl-24">
                    <template v-for="(itemstatus, index) in status">
                      <a-tag
                        v-if="itemtypedetail.is_show == itemstatus.name"
                        :key="index"
                        :color="
                          itemstatus.color == 'default'
                            ? null
                            : itemstatus.color
                        "
                        class="tag-status"
                      >
                        <a-badge
                          v-if="itemtypedetail.is_show == itemstatus.name"
                          :status="itemstatus.color"
                          :text="itemstatus.text"
                        />
                      </a-tag>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="check" class="mt-3">
                <div class="row py-2 border-tb">
                  <div class="col-5">
                    <div class="content-col">
                      {{ topicTypeDetail() }}
                    </div>
                  </div>
                  <div class="col-5">{{ topicTypeDetailSolution() }}</div>
                  <div class="col-2">
                    <template v-for="(item, index) in status">
                      <a-tag
                        v-if="active == item.name"
                        :key="index"
                        :color="item.color == 'default' ? null : item.color"
                        class="tag-status"
                      >
                        <a-badge
                          v-if="active == item.name"
                          :status="item.color"
                          :text="item.text"
                        />
                      </a-tag>
                    </template>
                  </div>
                </div>
              </div> -->
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
  <DisplayFieldsCreateSubject
    :value="showModalSubject"
    @clicksearch="clicksearch()"
    @handleClose="showModalSubject = false"
  >
  </DisplayFieldsCreateSubject>
  <DisplayFieldsEditSubject
    :value="showModalEditSubject"
    @handleClose="showModalEditSubject = false"
  >
  </DisplayFieldsEditSubject>
</template>

<script>
import DisplayFieldsCreateSubject from '@/module/CallCenter/component/DisplayFieldsCreateSubject.vue'
import DisplayFieldsEditSubject from '@/module/CallCenter/component/DisplayFieldsEditSubject.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    DisplayFieldsCreateSubject,
    DisplayFieldsEditSubject,
  },
  data: function () {
    return {
      appId_list: JSON.parse(localStorage.getItem('applist')),
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      check: [],
      activeKey: null,
      dataSubject: [],
      columns: [
        {
          title: 'Subject',
          dataIndex: 'subject',
          slots: { customRender: 'subject' },
          key: 'subject',
          width: 500,
          align: 'left',
        },
        {
          title: 'Basic Solution',
          dataIndex: 'basic_solution',
          slots: { customRender: 'basic_solution' },
          key: 'basic_solution',
          width: 500,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 200,
          align: 'left',
        },
      ],
      showModalSubject: false,
      showModalEditSubject: false,
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
      active: true,
      statussearch: null,
      status_option: [
        {
          label: 'All status',
          value: null,
        },
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      toppictyeid: '',
      checkTypeDetail: null,
    }
  },
  computed: {},
  mounted() {
    this.getSubject().then(() => {
      this.$store.commit('setLoading', false)
    })
  },
  created() {},
  methods: {
    clicksearch() {
      this.getSubject().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    clicktest(index) {
      this.check[index] = !this.check[index]
    },
    topicType(item) {
      var toppictye = this.dataSubject.filter(
        x => x.parent_problem_type_id == item.id,
      )
      return toppictye
    },
    topicTypeDetail(id) {
      var toppictyedetail = this.dataSubject.filter(
        x => id == x.parent_problem_type_id,
      )
      return toppictyedetail
    },
    topicTypeDetailSolution() {
      var toppictyedetail = this.dataSubject.find(
        x => this.toppictyeid == x.parent_problem_type_id,
      )
      if (toppictyedetail) {
        return toppictyedetail.basic_solution
      }
    },
    getSubject() {
      this.$store.commit('setLoading', true)
      var fromData = {}
      fromData = {
        appId_list: this.appId_list.map(x => x.appId),
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTicketMasterJoblist(fromData)
          .then(res => {
            this.dataSubject = res.data.result.items
            this.$store.commit('setLoading', false)
            resolve(res.result)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    createNewSubject() {
      this.showModalSubject = !this.showModalSubject
    },
    toggleDisplayFieldsEdit(event) {
      event.stopPropagation()
      this.showModalEditSubject = !this.showModalEditSubject
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.manage-subject {
  .select-search {
    width: 200px;
  }
  .ant-table-tbody {
    display: none !important;
  }
  .ant-collapse {
    margin-top: 10px !important;
  }
  .ant-collapse-header {
    background-color: #ffffff !important;
    border-radius: 12px !important;
    padding: 16px !important;
  }
  .ant-collapse-content-box {
    background-color: #f9f9f9 !important;
    border-radius: 12px !important;
  }
  // .ant-collapse-borderless > .ant-collapse-item {
  //   border-bottom: none !important;
  // }
  .icon {
    margin-right: 12px;
    width: 12px;
    color: #797979;
  }
  .content-col {
    padding-left: 45px;
    // border-top: 1px solid #f0f0f0;
    // border-bottom: 1px solid #f0f0f0;
  }
  .border-tb {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  .h-col {
    margin: 0px 16px 0px 35px;
  }
  .createsub {
    position: absolute;
    right: 22px;
    top: 130px;
  }
  .wrap-table {
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 6px;
    height: calc(100vh - 370px);
  }
  .pl-50 {
    padding-left: 50px;
  }
  .pl-24 {
    padding-left: 5px;
  }
  .pb-type {
    padding: 16px 0px 0px 40px !important;
  }
  .pb-detail {
    padding: 16px 0px 16px 50px !important;
  }
  .mb-16 {
    margin-bottom: 16px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px 0px 0px !important;
  }
  .border-b {
    width: 100% !important;
  }
}
</style>
