<template>
  <div class="flex-1 w-full px-4 approveadjustpoint">
    <div class="table-scroll">
      <nav class="nav-search">
        <a-select
          class="select-search"
          style="width: 150px"
          v-model:value="application"
          placeholder="All Application"
          show-search
          :options="application_option"
        />
        <a-select
          class="select-search"
          style="width: 150px"
          v-model:value="statusdropdown"
          placeholder="All Status"
          :options="status_dropdown"
        />
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter()">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
            @handleCloseDaterange="showDateFilter = false"
          />
        </div>
        <a-button
          :disabled="(startValue && !endValue) || (!startValue && endValue)"
          type="primary"
          class="btn-search"
          @click="clicksearch()"
          ><search-outlined /> Search</a-button
        >
      </nav>
      <div class="wrap-table-level" @scroll="handleScroll">
        <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="id"
          class="fixed-head"
          :dataSource="dataApprove"
          :columns="columns"
          :pagination="false"
        >
          <template #reference_id="{ text, record }">
            <a class="font-level" @click="toggleApprovePointByID(record)">{{
              text
            }}</a>
          </template>
          <template #reason="{ record }">
            <div>{{ record.reason || '-' }}</div>
          </template>
          <template #additional_comment="{ record }">
            <div>{{ record.additional_comment || '-' }}</div>
          </template>
          <template #user="{ record }">
            <a class="font-level" @click="toggleApprovePointByID(record)">
              <div class="font-level">
                {{ record.user_id }}
              </div>
            </a>
            <div class="fs-12">
              {{ record.name_of_user }}
            </div>
          </template>
          <template #create_date="{ text }">
            <div>
              {{ formatDate(text, 'DD/MMM/YYYY', this.isTimezone * -1) }}
            </div>
          </template>
          <template #create_by="{ record }">
            <div>{{ record.auditable.name }}</div>
          </template>
          <template #send_notification="{ record }">
            <template v-for="(item, index) in status_noti">
              <a-tag
                v-if="record.notification.send_notification == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge
                  v-if="record.notification.send_notification == item.name"
                  :status="item.color"
                  :text="item.text"
                />
              </a-tag>
            </template>
          </template>
          <template #message="{ record }">
            <div>
              {{
                record.notification.message ? record.notification.message : '-'
              }}
            </div>
          </template>
          <template #channel="{}">
            <a-tag color="purple" class="tag-status">
              <a-badge status="purple" text="App Noti" />
            </a-tag>
          </template>
          <template #status="{ text }">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="text == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge :status="item.color" :text="textStatus(text)" />
              </a-tag>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
  <DisplayFieldsApproveDetail
    :value="showDisplayFieldsApproveDetail"
    :dataApproveByID="dataApproveByID"
    :approvepointpermission="approvepointpermission"
    :rejectpermission="rejectpermission"
    :managepointpermission="managepointpermission"
    @getapprovelist="getApprovePointslist($event)"
    @handleClose="showDisplayFieldsApproveDetail = false"
  >
  </DisplayFieldsApproveDetail>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFieldsApproveDetail from '@/module/CallCenter/component/DisplayFieldsApprovePoint.vue'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import { CalendarOutlined } from '@ant-design/icons-vue'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserLevel',
  components: {
    // Footer,
    DateFilter,
    CalendarOutlined,
    DisplayFieldsApproveDetail,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Setting',
        },
        {
          name: null,
          breadcrumbName: 'Approve List',
        },
      ],
      appId_list: JSON.parse(localStorage.getItem('applist')),
      activeKey: '',
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      showDisplayFieldsApproveDetail: false,
      showDisplayFieldsApproveDataPrivacy: false,
      approvelistpermission: true,
      approvepointlistpermission: true,
      approvepointpermission: true,
      rejectpermission: true,
      managepointpermission: true,
      searchObject: {
        status: '',
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdropdown: '',
      status_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting',
          value: 'Create',
        },
        {
          label: 'Approved',
          value: 'L1Approved',
        },
        {
          label: 'Rejected',
          value: 'Reject',
        },
      ],
      status_noti: [
        {
          id: 1,
          name: false,
          text: 'No',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Yes',
          color: 'green',
        },
      ],
      columns: [
        {
          title: 'Application Name',
          dataIndex: 'application_name',
          slots: { customRender: 'application_name' },
          key: 'application_name',
          width: 120,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 120,
          align: 'left',
        },
        {
          title: 'Name',
          dataIndex: 'user',
          slots: { customRender: 'user' },
          key: 'user',
          width: 120,
          align: 'left',
        },
        {
          title: 'Reason',
          dataIndex: 'reason',
          slots: { customRender: 'reason' },
          key: 'reason',
          width: 160,
          align: 'left',
        },
        {
          title: 'Additional Comment',
          dataIndex: 'additional_comment',
          slots: { customRender: 'additional_comment' },
          key: 'additional_comment',
          width: 160,
          align: 'left',
        },
        {
          title: 'Create Date',
          dataIndex: 'created_date',
          slots: { customRender: 'create_date' },
          key: 'create_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Create By',
          dataIndex: 'create_by',
          slots: { customRender: 'create_by' },
          key: 'create_by',
          width: 120,
          align: 'left',
        },
        {
          title: 'Send Notification',
          dataIndex: 'send_notification',
          slots: { customRender: 'send_notification' },
          key: 'send_notification',
          width: 140,
          align: 'center',
        },
        // {
        //   title: 'Notification Message',
        //   dataIndex: 'message',
        //   slots: { customRender: 'message' },
        //   key: 'message',
        //   width: 220,
        //   align: 'left',
        // },
        // {
        //   title: 'Channel',
        //   dataIndex: 'channel',
        //   slots: { customRender: 'channel' },
        //   key: 'channel',
        //   width: 120,
        //   align: 'left',
        // },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'L1Approved',
          color: 'green',
        },
      ],
      dataApprove: [],
      dataApproveByID: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      application: '',
      application_option: [],
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'Setting',
        },
        {
          text: '<div class="font-normal text-sm text-gray-400">Approve List</div>',
        },
      ]
    },
  },
  mounted() {
    this.$store.commit('setLoading', false)
    if (this.$route.query.tab == 'adjustpoint') {
      this.clicksearch()
    }
  },
  created() {
    this.application_option = this.appId_list.map(item => {
      return {
        label: item.appName,
        value: `${item.appId}|${item.appName}`,
      }
    })
    this.application_option.unshift({
      label: 'All Application',
      value: '',
    })
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'adjustpoint') {
        this.clicksearch()
      }
    },
  },
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Waiting'
        case 'L1Approved':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.searchObject.status = this.statusdropdown
      this.page = 1
      this.showDateFilter = false
      this.getApprovePointslist().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getApprovePointslist() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetAdjustPointCallcenter(
          this.application.split('|')[0],
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.status = this.statusdropdown
            ? this.statusdropdown
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataApprove = []
            }
            _.forEach(data.result, item => {
              this.dataApprove.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            resolve(this.dataApprove)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getApprovePointslist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApprovePointByID(data_approveid) {
      this.dataApproveByID = data_approveid
      this.showDisplayFieldsApproveDetail = !this.showDisplayFieldsApproveDetail
    },
    toggleApproveListByID() {
      this.showDisplayFieldsApproveDataPrivacy =
        !this.showDisplayFieldsApproveDataPrivacy
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.approvepoint {
  overflow-x: hidden;
  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }
  @media only screen and (max-width: 1800px) {
    .datefilter {
      right: -320px !important;
    }
  }
  .nav-search {
    display: flex;
    gap: 8px;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;
    .search {
      float: left;
      width: auto;
      .input-search {
        width: 200px;
        border-right: none;
      }
      .select-search {
        width: 130px;
      }
    }
    .wrap-date-filter {
      position: relative;
    }
    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }
    .btn-date-filter {
      width: 260px;
      text-align: left;
      .anticon {
        float: right;
        margin-top: 2px;
      }
    }
    .group-btn-right {
      display: flex;
      float: right;
      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;
    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }
  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }
  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }
  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }
      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;
        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;
          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }
      &:hover {
        .wrap-sort span {
          display: inline-block;
          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.approveadjustpoint {
  .datefilter {
    right: unset !important;
  }
  .ant-table-row {
    vertical-align: top !important;
  }
  .ant-table-thead {
    vertical-align: top !important;
  }
  .ant-tabs-nav-wrap {
    background: #fff;
    border-radius: 6px;
    padding: 0 15px;
    border-bottom-width: 1px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    // .ant-tabs-ink-bar {
    //   background: $color-primary;
    // }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: unset !important;
  }
}
</style>
