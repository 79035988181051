// import Vue from 'vue';
import Util from '@/helper/Utility.js'
import CacheHelper from '@/helper/CacheHelper.js'
import Locale from '@/helper/locale/Locale.js'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsAuth from '@/core/Account/service/BzbsAuth.js'
import _ from 'lodash'
import store from '@/store'
import router from '@/router'
import Modal from 'ant-design-vue/lib/modal'
import Utility from '@/helper/Utility.js'
let cache = new CacheHelper(ConfigManager.getCacheType())

// import BzbsUser from '@/core/UserManagement/callapi/BzbsUser.js'
// import BzbsProfile from '@/core/Account/service/BzbsProfile.js';
// import { ProfileModel, ProfileAddressModel, ProfileShippingAddressModel } from '@/core/Account/model/AccountModel.js';
// import { AuthDeviceLoginBuilder } from '@/core/Account/model/AuthConfigModel.js';

//Main key.
const KEY_COOKIE_USER = 'account'
// const KEY_COOKIE_PROFILE = 'BzbsProfile';
// const KEY_COOKIE_PROFILE_ADDRESS = 'BzbsProfileAddress';
// const KEY_COOKIE_PROFILE_SHIPPING_ADDRESS = 'BzbsProfileShippingAddress';

/**
 * Token
 * @method getToken
 * @method getTokenShuffle
 * @method updateToken
 *
 * Cache
 * @method getCacheUser
 * @method setCacheUser
 * @method removeCacheUser
 * @method getCachePoint
 * @method getCachePointUpdateDate
 * @method updatePoint
 *
 * Login
 * @method setLogin
 * @method setLogout
 * @method loginStatus
 * @method isLogin
 *
 * Api login
 * @method apiGetPoint
 * @method apiBzbsLogin
 * @method apiDeviceLogin
 * @method apiFBLogin
 * @method apiLogout
 *
 * Cache profile
 * @method getCacheProfile
 * @method setCacheProfile
 * @method removeCacheProfile
 * @method getCacheProfileAddress
 * @method setCacheProfileAddress
 * @method removeCacheProfileAddress
 * @method getCacheProfileShippingAddres
 * @method setCacheProfileShippingAddres
 * @method removeCacheProfileShippingAddres
 * @method setCacheAllProfile
 * @method removeCacheAllProfile
 *
 * Api profile
 * @method apiGetProfile
 * @method apiUpdateProfile
 * @method apiUpdateProfileLocale
 * @method apiUpdateProfileShippingAddress
 */

export default {
  //! rework this block
  // this.initial = function () {
  //     this.apiGetProfile().then(() => {
  //         this.sendInitCompleted();
  //     }).catch(() => {
  //         this.sendInitCompleted();
  //     })
  // };
  // this.sendInitCompleted = function () {
  //     for (var i of Vue.subInitList) {
  //         i.next();
  //     }
  // };

  getIsMSUser() {
    return true
  },

  setFeatures(data) {
    return localStorage.setItem('permissionbyapp', JSON.stringify(data))
  },

  getMSUser() {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.getCache('msUserData')
  },

  setUserCache(user) {
    var data = {
      agency_id: user.agency_id,
      agency_name: user.agency_name,
      application_id: user.application_id,
      application_name: user.application_name,
      username: user.username,
    }
    return cache.setCache('BzBsUser', JSON.stringify(data))
  },
  //#region user
  /**
   * Get user token.
   * @return {string|null} user token.
   */
  getToken: function () {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.getCache('msToken') ? cache.getCache('msToken') : null
  },
  /**
   * Get shuffled user's token.
   * @return {string|null} user token.
   */
  getTokenShuffle: function () {
    var token = this.getToken()
    return Util.shuffleToken(token)
  },
  /**
   * Update user token.
   * @param {Object} strToken
   * @return {object} user.
   */
  updateToken: function (token) {
    var user = this.getCacheUser()
    user.token = token
    this.setCacheUser(user)

    return user
  },

  /**
   * Get cache user.
   * @return {object} Cache user.
   */
  getCacheUser: function () {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.getCache(KEY_COOKIE_USER)
  },

  /**
   * Set cache user.
   * @param {object} user
   */
  setCacheUser: function (user) {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.setCache(KEY_COOKIE_USER, user)
  },

  getUserLevel: function () {
    var user = this.getCacheUser()
    if (user != null) {
      return user.userLevel
    }
    return 0
  },

  /**
   * Get point.
   * @return {number} User point.
   */
  getCachePoint: function () {
    if (this.isLogin()) {
      var user = this.getCacheUser()
      if (user != null) {
        return user.point.point
      }
    }

    return 0
  },
  /**
   * Get user's point last update date.
   * @return {number} User's point date.
   */
  getCachePointUpdateDate: function () {
    if (this.isLogin()) {
      var user = this.getCacheUser()
      if (user != null) {
        return user.point.time
      }
    }

    return 0
  },
  /**
   * Remove cache user.
   */
  removeCacheUser: function () {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    cache.removeCache(KEY_COOKIE_USER)
  },

  /**
   * Set user login.
   * @param {object} user
   */
  setLogin: function (user) {
    this.setCacheUser(user)
    var localeCode = user.locale
    Locale.setLocale(localeCode)
  },

  /**
   * Set user logout.
   * @return {promises} Logout response.
   */
  setLogout: function () {
    this.removeCacheUser()
    // this.removeCacheAllProfile();
  },

  /**
   * Update point.
   * @param {object} point
   */
  updatePoint: function (point) {
    var user = this.getCacheUser()
    // var profile = this.getCacheProfile();
    user.point = point
    // profile.updated_points = point;
    this.setCacheUser(user)
    // this.setCacheProfile(profile);
  },

  /**
   * Check login wait for check token status.
   * @return Promise
   */
  //! rework
  // this.loginStatus = function () {
  //     return new Promise((resolve) => {
  //         if (Vue.initFinished) {
  //             var rs = this.isLogin();
  //             resolve(rs);
  //         } else {
  //             Vue.observInit.subscribe(() => {
  //                 var rs = this.isLogin();
  //                 resolve(rs);
  //             });
  //         }
  //     });
  // };

  /**
   * Check login.
   * @return {boolean} Login status.
   */
  isLogin: function () {
    var token = this.getToken()
    return !Util.isEmptyStr(token)
  },

  /**
   * Get user point.
   * @returns {promises} Update point object.
   */
  // this.apiGetPoint = function () {
  //     return new Promise((resolve, reject) => {
  //         let token = this.getToken();
  //         let apiProfile = new BzbsProfile(this.vue);
  //         apiProfile.getPoint(token).subscribe((pointData) => {
  //             this.updatePoint(pointData);
  //             resolve(pointData);
  //         }, (err) => {
  //             reject(err);
  //         })
  //     });
  // };

  /**
   * Bzbs login.
   * @param {string} strUsername
   * @param {string} strPassword
   * @return {object} Return user and profile.
   */
  // this.apiBzbsLogin = function (strUsername, strPassword) {
  //     return new Promise((resolve, reject) => {
  //         BzbsAuth.apiLogin(strUsername, strPassword, null, null, null, false)
  //             .subscribe((data) => {
  //                 //Set User to cache.
  //                 this.setLogin(data);
  //                 //Get Profile to cache.
  //                 this.apiGetProfile().then((objProfile) => {
  //                     //Return User Data from api.
  //                     var objResult = {
  //                         "user": data,
  //                         "profile": objProfile
  //                     };
  //                     resolve(objResult);
  //                 }).catch((error) => {
  //                     reject(error);
  //                 });
  //             }, (err) => {
  //                 reject(err);
  //             });
  //     });
  // };

  // apiDeviceLogin: function () {
  //     return new Promise((resolve, reject) => {
  //         var authConfig = new AuthDeviceLoginBuilder()
  //                         .genUUID()
  //                         .create();
  //         var params = authConfig.getParams();

  //         BzbsAuth.apiLoginDevice(params).subscribe((data) => {
  //             //Set User to cache.
  //             this.setLogin(data);
  //             //Get Profile to cache.
  //             this.apiGetProfile().then((objProfile) => {
  //                 //Return User Data from api.
  //                 var objResult = {
  //                     "user": data,
  //                     "profile": objProfile
  //                 };
  //                 resolve(objResult);
  //             }).catch((error) => {
  //                 reject(error);
  //             });
  //         }, (err) => {
  //             reject(err);
  //         });
  //     });
  // },

  // apiDeviceLoginWithUUID: function (uuid) {
  //     return new Promise((resolve, reject) => {
  //         var authConfig = new AuthDeviceLoginBuilder()
  //                         .setUUID(uuid)
  //                         .create();
  //         var params = authConfig.getParams();

  //         BzbsAuth.apiLoginDevice(params).subscribe((data) => {
  //             //Set User to cache.
  //             this.setLogin(data);
  //             //Get Profile to cache.
  //             this.apiGetProfile().then((objProfile) => {
  //                 //Return User Data from api.
  //                 var objResult = {
  //                     "user": data,
  //                     "profile": objProfile
  //                 };
  //                 resolve(objResult);
  //             }).catch((error) => {
  //                 reject(error);
  //             });
  //         }, (err) => {
  //             reject(err);
  //         });
  //     });
  // },

  // apiFBLogin: function () {

  // },

  /**
   * Logout.
   */
  apiLogout: function (token) {
    return new Promise((resolve, reject) => {
      if (!token) {
        resolve(null)
      } else {
        BzbsAuth.apiLogout(token, null, null).subscribe(
          data => {
            resolve(data)
          },
          err => {
            reject(err)
          },
        )
      }
    })
  },

  //#endregion user

  //#region profile
  /**
   * Get cache profile.
   * @return {object|null} Profile.
   */
  // getCacheProfile: function () {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     return cache.getCache(KEY_COOKIE_PROFILE);
  // },

  /**
   * Set cache profile.
   * @param {object|null} objProfile
   */
  // setCacheProfile: function (objProfile) {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     cache.setCache(KEY_COOKIE_PROFILE, objProfile);
  // },

  /**
   * Remove cache profile.
   */
  // removeCacheProfile: function () {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     cache.removeCache(KEY_COOKIE_PROFILE);
  // },

  /**
   * Get cache profile address.
   * @return {object|null}
   */
  // getCacheProfileAddress: function () {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     return cache.getCache(KEY_COOKIE_PROFILE_ADDRESS);
  // },

  /**
   * Set cache profile address.
   * @param {object|null} objProfileAddress
   */
  // setCacheProfileAddress: function (objProfileAddress) {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     cache.setCache(KEY_COOKIE_PROFILE_ADDRESS, objProfileAddress);
  // },

  /**
   * Remove cache profile address.
   */
  // removeCacheProfileAddress: function () {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     cache.removeCache(KEY_COOKIE_PROFILE_ADDRESS);
  // },

  /**
   * Get cache profile shipping address.
   * @return {object|null} Profile shipping address
   */
  // getCacheProfileShippingAddres: function () {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     return cache.getCache(KEY_COOKIE_PROFILE_SHIPPING_ADDRESS);
  // },

  /**
   * Set cache profile shipping address.
   * @param {object|null} objProfileShippingAddress
   */
  // setCacheProfileShippingAddres: function (objProfileShippingAddress) {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     cache.setCache(KEY_COOKIE_PROFILE_SHIPPING_ADDRESS, objProfileShippingAddress);
  // },
  /**
   * Remove cache profile shipping address.
   */
  // removeCacheProfileShippingAddres: function () {
  //     var cache = new CacheHelper(ConfigManager.getCacheType());
  //     cache.removeCache(KEY_COOKIE_PROFILE_SHIPPING_ADDRESS);
  // },

  /**
   * Set cache for profile, profile address, profile shipping address.
   * @param {object|null} objProfile
   * @param {object|null} objProfileAddress
   * @param {object|null} objProfileShippingAddress
   */
  // setCacheAllProfile: function (objProfile, objProfileAddress, objProfileShippingAddress) {
  //     this.removeCacheAllProfile();
  //     this.setCacheProfile(objProfile);
  //     this.setCacheProfileAddress(objProfileAddress);
  //     this.setCacheProfileShippingAddres(objProfileShippingAddress);
  // },

  /**
   * Remove all profile cache.
   */
  // removeCacheAllProfile: function () {
  //     this.removeCacheProfile();
  //     this.removeCacheProfileAddress();
  //     this.removeCacheProfileShippingAddres();
  // },

  /**
   * Get profile.
   * @return {object|null} Profile.
   */
  // apiGetProfile: function () {
  //     return new Promise((resolve, reject) => {
  //         var strToken = this.getToken();
  //         if (!strToken) {
  //             resolve(null);
  //         } else {
  //             BzbsProfile.getProfile(strToken).subscribe((data) => {
  //                 var objProfile = new ProfileModel(data);
  //                 var objProfileAddress = new ProfileAddressModel(data);
  //                 var objProfileShippingAddress = new ProfileShippingAddressModel(data);
  //                 this.setCacheAllProfile(objProfile, objProfileAddress, objProfileShippingAddress);
  //                 resolve(objProfile);
  //             }, (err) => {
  //                 if (err.id == 412) {
  //                     this.removeCacheUser();
  //                     this.removeCacheAllProfile();
  //                     location.reload();
  //                 }
  //                 reject(err);
  //             });
  //         }
  //     });
  // },

  /**
   * Update user profile.
   * @param {object} objProfile
   * @param {object} objProfileAddress
   * @param {string} strExtendJson
   */
  // apiUpdateProfile: function (objProfile, objProfileAddress, strExtendJson) {
  //     return new Promise((resolve, reject) => {
  //         var strToken = this.getToken();
  //         if (!strToken) {
  //             resolve(null);
  //         } else {
  //             BzbsProfile.updateProfile(strToken, objProfile, objProfileAddress, strExtendJson).subscribe((data) => {
  //                 //Update cache profile.
  //                 var objNewProfile = new ProfileModel(data);
  //                 var objNewProfileAddress = new ProfileAddressModel(data);
  //                 var objNewProfileShippingAddress = new ProfileShippingAddressModel(data);
  //                 this.setCacheAllProfile(objNewProfile, objNewProfileAddress, objNewProfileShippingAddress);
  //                 //Update cache user.
  //                 var objUser = this.getCacheUser();
  //                 console.log(objUser);
  //                 objUser.token = data.Token;
  //                 objUser.locale = data.Locale;
  //                 objUser.updated_points = objNewProfile.updated_points;
  //                 this.setCacheUser(objUser);

  //                 var objResult = {
  //                     "objUser": objUser,
  //                     "objProfile": objProfile
  //                 };
  //                 resolve(objResult);
  //             }, (err) => {
  //                 reject(err);
  //             });
  //         }
  //     });
  // },

  /**
   * Update user shipping address.
   * @param {object} objProfileShippingAddress AddressModel /Model/AddressModel.js
   */
  // apiUpdateProfileShippingAddress: function (objProfileShippingAddress) {
  //     return new Promise((resolve, reject) => {
  //         var strToken = this.getToken();
  //         if (!strToken) {
  //             resolve(null);
  //         } else {
  //             BzbsProfile.updateShippingAddress(strToken, objProfileShippingAddress).subscribe((objResult) => {
  //                 var objNewProfileShippingAddress = new ProfileShippingAddressModel(objResult);
  //                 this.setCacheProfileShippingAddres(objNewProfileShippingAddress);
  //                 resolve(objNewProfileShippingAddress);
  //             }, (err) => {
  //                 reject(err);
  //             });
  //         }
  //     });
  // },
  //#endregion profile
  getMSPermission(parmas) {
    return new Promise((resolve, reject) => {
      BzbsAuth.apiGetApplications(parmas)
        .then(response => {
          if (response.data.result.applications.length) {
            console.log(response.data.result)
          } else {
            this.getMenuProducts().then(() => {
              router.push({ name: 'unauthorized' })
            })
          }
          resolve(response)
        })
        .catch(objerror => {
          this.checkErrorApiAD(objerror)
            .then(() => {
              this.getMSPermission(parmas).then(res => {
                resolve(res)
              })
            })
            .catch(() => {
              reject(objerror)
            })
        })
    })
  },
  getMenuProducts(appid) {
    var parmas = {}
    if (appid) {
      parmas = {
        buzzebees_application_id: appid,
      }
    }
    return new Promise((resolve, reject) => {
      BzbsAuth.apiGetMenu(parmas).then(
        res => {
          const otherproducts = _.filter(res.data.result.products, product => {
            if (product.icon)
              return _.map(res.data.result.products, product => {
                return {
                  display_name: product.display_name,
                  icon: product.icon,
                  name: product.name,
                  url: product.url,
                }
              })
          })
          localStorage.setItem('setMenuProduct', JSON.stringify(otherproducts))
          resolve(res)
        },
        err => {
          this.errorApi(err)
          reject(err)
        },
      )
    })
  },
  getMSMenu2(appid) {
    var parmas = {}
    if (appid) {
      parmas = {
        buzzebees_application_id: appid,
      }
    }
    return new Promise((resolve, reject) => {
      BzbsAuth.apiGetMenu(parmas).then(
        res => {
          let products = res.data.result.products
          const menu = products.filter(f => {
            return f.name == 'User-Management'
          })[0].menus
          const product = _.filter(products, product => {
            if (product.name == 'User-Management') return product
          })
          const otherproducts = _.filter(products, product => {
            if (product.icon)
              return _.map(products, product => {
                return {
                  display_name: product.display_name,
                  icon: product.icon,
                  name: product.name,
                  url: product.url,
                }
              })
          })
          const HelpcenterProduct = products.find(
            product => product.name == 'Help-Center',
          )
          if (HelpcenterProduct) {
            localStorage.setItem(
              'HelpcenterProduct',
              JSON.stringify(HelpcenterProduct),
            )
          }
          store.commit('setMenuPermission', product[0].menus)
          if (menu) {
            localStorage.setItem(
              'setMenuPermissionNew',
              JSON.stringify(Utility.mapnewMenu(menu)),
            )
          }
          localStorage.setItem('setMenuProduct', JSON.stringify(otherproducts))
          resolve(product[0].menus)
          resolve(res)
        },
        err => {
          this.errorApi(err)
          reject(err)
        },
      )
    })
  },
  setDefaultMenu() {
    var menu = null
    var permissionbyapp = JSON.parse(localStorage.getItem('permissionbyapp'))
    try {
      menu = JSON.parse(store.state.menupermission)
    } catch {
      menu = store.state.menupermission
    }
    console.log(menu)
    if (menu && menu.length > 0) {
      if (this.getIsMSUser()) {
        if (menu[0].page && typeof menu[0].page.route_name == 'string') {
          if (
            menu[0].page.route_name == 'list' &&
            permissionbyapp.includes('User-List-Real-Time-Data') &&
            permissionbyapp.includes('User-List-View')
          ) {
            router.push({ name: 'list' })
          } else if (
            menu[0].page.route_name == 'list' &&
            permissionbyapp.includes('User-List-Real-Time-Data')
          ) {
            router.push({ name: 'listrealtime' })
          } else {
            router.push({ name: menu[0].page.route_name })
          }
        } else {
          if (menu[0].menus) {
            if (menu[0].menus[0].page) {
              if (
                menu[0].menus[0].page.route_name == 'list' &&
                permissionbyapp.includes('User-List-Real-Time-Data') &&
                permissionbyapp.includes('User-List-View')
              ) {
                router.push({ name: 'list' })
              } else if (
                menu[0].menus[0].page.route_name == 'list' &&
                permissionbyapp.includes('User-List-Real-Time-Data')
              ) {
                router.push({ name: 'listrealtime' })
              } else {
                router.push({ name: menu[0].menus[0].page.route_name })
              }
            } else {
              if (menu[0].menus[0].menus && menu[0].menus[0].menus[0].page) {
                if (
                  menu[0].menus[0].menus[0].page.route_name == 'list' &&
                  permissionbyapp.includes('User-List-Real-Time-Data') &&
                  permissionbyapp.includes('User-List-View')
                ) {
                  router.push({ name: 'list' })
                } else if (
                  menu[0].menus[0].menus[0].page.route_name == 'list' &&
                  permissionbyapp.includes('User-List-Real-Time-Data')
                ) {
                  router.push({ name: 'listrealtime' })
                } else {
                  router.push({
                    name: menu[0].menus[0].menus[0].page.route_name,
                  })
                }
              }
            }
          }
        }
      } else {
        store.commit('setLoading', false)
        Modal.error({
          title: 'Please try again.',
          content: 'User do not have a permission',
          centered: true,
          autoFocusButton: null,
          onOk: () => {
            this.logout()
            router.push({
              name: 'login',
            })
          },
        })
      }
    } else {
      store.commit('setLoading', false)
      Modal.error({
        title: 'Please try again.',
        content: 'User do not have a permission',
        centered: true,
        autoFocusButton: null,
        onOk: () => {
          this.logout()
          router.push({
            name: 'login',
          })
        },
      })
    }
  },
  checkErrorApiAD(objerror) {
    return new Promise((resolve, reject) => {
      store.state.isAppLoad = false
      if (_.has(objerror, 'response.data.error')) {
        if (objerror.response.data.error) {
          if (objerror.response.status == 401) {
            if (
              objerror.response.data.error[0].id == '2006' ||
              objerror.response.data.error[0].code == 'TokenExpired'
            ) {
              this.TokenSilent()
                .then(() => {
                  resolve()
                })
                .catch(() => {
                  reject()
                })
            } else {
              cache.removeCache('msToken')
              router.push({ name: 'logout' })
              reject()
            }
          } else {
            reject()
          }
        } else {
          reject()
        }
      } else {
        reject()
      }
    })
  },
}
