export default {
  /****** String helper *******
   * @function isEmptyStr
   * @function newLineToBr
   * @function formatNum
   * @function removeEmojis
   * @function contactNumberFormat
   * @function isNationalIdCard
   * @function stripeHtmlTags
   ****************************/
  isEmptyStr: function (str) {
    return str === null || str === undefined || str === ''
  },
  newLineToBr: function (str) {
    if (!str) return ''
    return str.replace(/\n/g, '<br />').trim()
  },
  mapnewMenu(data) {
    let i = 1
    return data.map(m => {
      let menuItem = {}
      menuItem.icon = m.icon_name
      menuItem.name = m.display_name
      menuItem.sequence = i
      i++
      menuItem.sub_menu = []
      if (m.page) {
        menuItem.path = m.page.url_path
        menuItem.route_name = m.page.route_name
      } else {
        if (m.menus) {
          menuItem.sub_menu = m.menus.map(n => {
            let submenu = {
              name: n.display_name,
              route_name: n.page ? n.page.route_name : '',
              sequence: i,
            }
            i++
            if (n.page) {
              submenu.path = n.page.url_path
            } else {
              submenu.function = 'isShowRefillModal'
            }
            return submenu
          })
        }
      }
      return menuItem
    })
  },
  formatNum: function (n, d) {
    var str = (n || 0).toFixed(d)
    var x = str.split('.')
    var x1 = x[0]
    var x2 = x.length > 1 ? '.' + x[1] : ''
    var rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2')
    }
    return x1 + x2
  },
  removeEmojis: function (string) {
    var regex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
    return string.replace(regex, '')
  },
  contactNumberFormat: function (strContactNumber, delimeter) {
    if (strContactNumber.length < 10) {
      return strContactNumber
    }

    var n1 = strContactNumber.substr(0, 3)
    var n2 = strContactNumber.substr(3, 3)
    var n3 = strContactNumber.substr(6)

    return `${n1}${delimeter}${n2}${delimeter}${n3}`
  },
  isNationalIdCard: function (id) {
    if (id.length != 13) {
      return false
    }

    let sum = 0
    for (let i = 0; i < 12; i++) {
      sum += parseFloat(id.charAt(i)) * (13 - i)
    }
    if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) {
      return false
    }
    return true
  },
  stripeHtmlTags: function (value) {
    const div = document.createElement('div')
    div.innerHTML = value
    const text = div.textContent || div.innerText || ''
    return text
  },

  /******* Bzbs utility ********
   * @function shuffleToken
   * @function reShuffleToken
   * @function getBrowserVersion
   * @function getOSName
   * @function normalObject
   * @function stringFormat
   * @function cleanObjectNullKey
   * @function cleanObjectEmptyStr
   * @function isEmptyObject
   * @function log
   * @function logJson
   * @function scrollTop
   * @function disableZoomIos
   * @function getUUID
   * @function getWebUrl
   * @function arrayRemoveAt
   * @function toJsonStr
   * @function jsonParse
   * @function isNumber
   * @function parseInt
   * @function isFunction
   * @function copyArray
   *****************************/
  shuffleToken: function (bzbtoken) {
    return (
      bzbtoken.substring(bzbtoken.length - 5, bzbtoken.length) +
      bzbtoken.substring(1, bzbtoken.length - 5)
    )
  },
  reShuffleToken: function (token) {
    let head = token.substr(0, 5)
    let tail = token.substr(5)
    let newToken = '.' + tail + head
    return newToken
  },
  getBrowserVersion: function () {
    var ua = navigator.userAgent,
      tem,
      M =
        ua.match(
          /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i,
        ) || []
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || []
      return 'IE ' + (tem[1] || '')
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) return 'Opera ' + tem[1]
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1])
    return M.join(' ')
  },
  getOSName: function () {
    var OSName = 'Unknown OS'
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows'
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS'
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX'
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux'

    return OSName
  },
  normalObject: function (obj) {
    if (!obj) {
      return null
    }
    var json = JSON.stringify(obj)
    var normalObj = JSON.parse(json)
    return normalObj
  },

  cleanObjectNullKey: function (object) {
    var cleanObject = {}
    for (var i in object) {
      if (object[i] != null) {
        cleanObject[i] = object[i]
      }
    }
    return cleanObject
  },
  cleanObjectEmptyStr: function (object) {
    var cleanObject = {}
    for (var i in object) {
      if (!this.isEmptyStr(object[i])) {
        cleanObject[i] = object[i]
      }
    }
    return cleanObject
  },
  stringFormat: function () {
    var s = arguments[0]
    if (this.isEmptyStr(s)) {
      return ''
    }

    for (var i = 0; i < arguments.length - 1; i += 1) {
      var reg = new RegExp('\\{' + i + '\\}', 'gm')
      s = s.replace(reg, arguments[i + 1])
    }
    return s
  },
  isEmptyObject: function (obj) {
    if (obj == null || obj == '') {
      return true
    }

    var str = JSON.stringify(obj)

    if (str == '{}' || str == '[]') {
      return true
    }

    return false
  },
  log: function (text1, text2) {
    if (text2) {
      console.log(text1, this.normalObject(text2))
    } else {
      if (text1 == null) {
        console.log(text1)
        return
      }
      console.log(this.normalObject(text1))
    }
  },
  /**
   * @param {Object|Array} obj
   * @param {String} color red green blue purple
   */
  logJson: function (obj, color) {
    if (obj == null) {
      console.log(obj)
      return
    }
    var json = JSON.stringify(obj, null, 2)
    var colorCode = null
    switch (color) {
      case 'red':
        colorCode = '\x1b[31m%s\x1b[0m'
        break
      case 'green':
        colorCode = '\x1b[32m%s\x1b[0m'
        break
      case 'blue':
        colorCode = '\x1b[34m%s\x1b[0m'
        break
      case 'purple':
        colorCode = '\x1b[35m%s\x1b[0m'
        break
      default:
        colorCode = null
    }

    if (colorCode != null) {
      console.log(colorCode, json)
    } else {
      console.log(json)
    }
  },
  scrollTop: function (smoot) {
    var config = { top: 0 }
    if (smoot) {
      config.behavior = 'smooth'
    }
    window.scrollTo(config)
  },
  disableZoomIos: function () {
    //https://stackoverflow.com/questions/37808180/disable-viewport-zooming-ios-10-safari

    document.documentElement.addEventListener(
      'touchstart',
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      },
      false,
    )

    document.addEventListener(
      'touchmove',
      function (event) {
        event = event.originalEvent || event
        if (event.scale > 1) {
          event.preventDefault()
        }
      },
      false,
    )

    document.documentElement.addEventListener(
      'gesturestart',
      function (event) {
        event.preventDefault()
      },
      false,
    )
  },
  getUUID: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  },
  getWebUrl: function (path) {
    var protocol = window.location.protocol + '//'
    var host = window.location.hostname + '/'
    return protocol + host + path
  },
  arrayRemoveAt: function (array, index) {
    if (array.length > index) {
      return array.splice(index, 1)
    }
    return array
  },
  toJsonStr: function (obj) {
    if (obj) {
      try {
        var json = JSON.stringify(obj)
        return json
      } catch (e) {
        return null
      }
    } else {
      return null
    }
  },
  jsonParse: function (jsonStr) {
    if (!this.isEmptyStr(jsonStr)) {
      try {
        var data = JSON.parse(jsonStr)
        return data
      } catch (e) {
        return null
      }
    }
    return null
  },
  isNumber: function (num) {
    return !isNaN(num)
  },
  strToInt: function (str, defaultValue) {
    if (this.isNumber(str)) {
      return parseInt(str)
    } else if (defaultValue != null) {
      return defaultValue
    } else {
      return 0
    }
  },
  /**
   *
   * @param {Array} source array
   */
  copyArray: function (arr) {
    let items = []
    for (let index = 0; index < arr.length; index++) {
      const element = arr[index]
      const copy = Object.assign({}, element)
      items.push(copy)
    }
    return items
  },
  isFunction: function (fn) {
    return typeof fn === 'function'
  },

  /********* File **********
   * @function isFileTypeExcel
   * @function isFileTypeJpg
   * @function isFileTypePng
   * @function isFileTypeSvg
   * @function isFileTypeImage
   * @function isFileTypeZip
   * @function isFileTypePdf
   *************************/

  /**
   * @param {string} fileType
   */
  isFileTypeExcel: function (fileType) {
    var typeList = [
      'application/excel',
      'application/vnd.ms-excel',
      'application/vnd.msexcel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]
    var rs = typeList.find(item => {
      return fileType == item
    })
    return rs != null
  },

  /**
   * @param {string} fileType
   */
  isFileTypeJpg: function (fileType) {
    var typeList = ['image/jpeg', 'image/jpg']
    var rs = typeList.find(item => {
      return fileType == item
    })
    return rs != null
  },

  /**
   * @param {string} fileType
   */
  isFileTypePng: function (fileType) {
    var typeList = ['image/png']
    var rs = typeList.find(item => {
      return fileType == item
    })
    return rs != null
  },

  /**
   * @param {string} fileType
   */
  isFileTypeSvg: function (fileType) {
    var typeList = ['image/svg+xml']
    var rs = typeList.find(item => {
      return fileType == item
    })
    return rs != null
  },

  /**
   * @param {string} fileType
   */
  isFileTypeImage: function (fileType) {
    return (
      this.isFileTypeJpg(fileType) ||
      this.isFileTypePng(fileType) ||
      this.isFileTypeSvg()
    )
  },

  /**
   * @param {string} fileType
   */
  isFileTypeZip: function (fileType) {
    var typeList = ['application/x-zip-compressed', 'application/zip']
    var rs = typeList.find(item => {
      return fileType == item
    })
    return rs != null
  },

  /**
   * @param {string} fileType
   */
  isFileTypePdf: function (fileType) {
    var typeList = ['application/pdf']
    var rs = typeList.find(item => {
      return fileType == item
    })
    return rs != null
  },
}
