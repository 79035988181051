// import dayjs from 'dayjs'; //https://day.js.org/
// import utc from 'dayjs/plugin/utc';
import Locale from "@/helper/locale/Locale.js";
import { DateTime } from "luxon"; //https://moment.github.io/luxon

export { DateTime }

export default {
    /********* Datetime *********
     * @function convertDateToTimeStampUnix
     * @function getTimeZoneOffsetHour
     * @function isDatetimeValid
     * @function cleanTimeFormDatetime
     * @function getEndOfMonth
     * @function getEndOfMonthDay
     * @function getDateByValue
     * @function unixTimestampToDate
     * @function stringToDate
    ****************************/
    /**
     * @param {Date} date
     */
    convertDateToTimeStampUnix: function (date) {
        var unixTime = Math.floor((date.getTime() - date.getTimezoneOffset() * 60 * 1000) / 1000);
        return unixTime;
    },

    getTimeZoneOffsetHour: function () {
        let h = new Date().getTimezoneOffset() / 60;
        return h;
    },

    /**
     * @param {number|string} y - A.D. year only, ปี ค.ศ. เท่านั้น (Thai year need to convert before check.)
     * @param {number|string} m - (1 - 12)
     * @param {number|string} d - (1 - 31)
     */
    isDatetimeValid: function (y, m, d) {
        let dt = DateTime.fromObject({ year: y, month: m, day: d });
        return dt.isValid;
    },
    cleanTimeFormDatetime: function (datetime) {
        var date = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
        return date;
    },
    getEndOfMonth: function (year, month) {
        let dt = DateTime.fromObject({ year: year, month: month, day: 1 });
        let end = dt.endOf('month').toJSDate();
        return end;
    },
    getEndOfMonthDay: function (year, month) {
        let lastDate = this.getEndOfMonth(year, month);
        return lastDate.getDate();
    },
    /**
     * @param {string|number} year YYYY
     * @param {string|number} month MM : 1-12
     * @param {string|number} day DD : 1-31
     */
    getDateByValue: function (year, month, day) {
        let dt = DateTime.fromObject({ year: year, month: month, day: day });
        return dt.toJSDate();
    },

    unixTimestampToDate: function (timestamp) {
        let dt = DateTime.fromSeconds(timestamp, { zone: 'utc' });
        let offset = this.getTimeZoneOffsetHour();
        dt = dt.plus({ hours: offset });
        return dt.toJSDate();
    },

    stringToDate: function (string, format) {
        let dt = DateTime.fromFormat(string, format);
        return dt.toJSDate();
    },

    /********** Date Format ***********
     * @function timestampFullFormat
     * @function dateFullFormat
     * @function getDatetimeFullFormat
     * @function getYearByLocale
     * @function timeFormat
     **********************************/

    timestampFullFormat: function (timestamp) {
        let date = this.unixTimestampToDate(timestamp);
        return this.dateFullFormat(date);
    },
    dateFullFormat: function (datetime) {
        let dt = DateTime.fromJSDate(datetime);

        if (Locale.getLocaleCode() == 1054) {
            return dt.reconfigure({ outputCalendar: "buddhist" })
                .setLocale("th")
                .toFormat('dd MMMM yyyy');
        } else {
            return dt.toFormat('dd MMMM yyyy');
        }
    },
    getDatetimeFullFormat: function (timestamp) {
        let date = this.unixTimestampToDate(timestamp);
        let dt = DateTime.fromJSDate(date);

        if (Locale.getLocaleCode() == 1054) {
            return dt.reconfigure({ outputCalendar: 'buddhist' })
                .setLocale("th")
                .toFormat('dd MMMM yyyy - HH:mm');
        } else {
            return dt.toFormat('dd MMMM yyyy - HH:mm');
        }
    },
    getYearByLocale: function (year) {
        if (Locale.getLocaleCode() == 1033) {
            return year;
        }
        return parseInt(year) + 543;
    },
    timeFormat: function (timestamp) {
        let date = this.unixTimestampToDate(timestamp);
        let dt = DateTime.fromJSDate(date);
        return dt.toFormat('HH:mm:ss');
    },
    /**
     *
     * @param {Date} dt
     * @param {String} [format=]
     */
    formatJSDate: function (dt, format = 'dd/MM/yyyy') {
        return DateTime.fromJSDate(dt).toFormat(format)
    },
    /**
     *
     * @param {Date} dt
     * @returns {string}
     */
    JSDateToISODate: function (dt) {
        return DateTime.fromJSDate(dt).toISODate()
    }
}
