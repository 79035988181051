<template>
  <label
    class="items-center"
    @click.stop.prevent="toggleValue()"
    :class="[display]"
  >
    <input
      type="checkbox"
      class="form-checkbox inline-block"
      :value="value"
      :class="{ checked: value }"
    />
    <template v-if="$slots['label']">
      <slot name="label" v-bind="$props" v-on="listeners"></slot>
    </template>
    <template v-else-if="label != null && label != undefined">
      <template v-if="stripeHTMLTags">
        <div class="ml-3 inline-block">
          {{ stripeHTMLTagsFunc(label) }}
        </div>
      </template>
      <template v-else>
        <div class="ml-3 inline-block" v-html="label"></div>
      </template>
    </template>
  </label>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    stripeHTMLTags: {
      type: Boolean,
      default: true,
    },
    display: {
      type: String,
      default: 'inline-flex',
    },
  },
  methods: {
    toggleValue: function () {
      this.$emit('update:value', !this.value)
      this.$nextTick(() => {
        this.$emit('change')
      })
    },
    stripeHTMLTagsFunc: function (value) {
      const div = document.createElement('div')
      div.innerHTML = value
      const text = div.textContent || div.innerText || ''
      return text
    },
  },
}
</script>
