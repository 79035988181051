<template>
    <div
        class="fixed top-0 right-0 h-screen z-40 bg-opacity-60 bg-black"
        :style="containerStyle"
        :class="{ 'w-full': value }"
    >
        <transition
            enter-active-class="transition ease-out duration-250"
            enter-from-class="transform translate-x-full"
            enter-to-class="transform  translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="transform translate-x-0"
            leave-to-class="transform translate-x-full"
        >
            <div
                class="absolute top-0 right-0 h-full shadow-xl bg-white border-l"
                :style="mainBlockStyle"
                v-if="value"
                v-click-away="onClickAway"
            >
                <div class="flex flex-col h-full">
                    <div class="panel-header flex-none border-b-2">
                        <div
                            class="flex-grow font-semibold text-sm text-gray-1"
                        >
                            My Account
                        </div>
                        <div class="flex-none"></div>
                    </div>
                    <div class="border-b-2 flex-auto overflow-y-auto">
                        <div class="p-5">
                            <div class="flex items-start border-b-2 pb-4">
                                <div class="mr-4 flex-none">
                                    <v-avatar
                                        :text="user.shortedName"
                                        bg-color="bg-orange-2"
                                        color="text-orange-1"
                                        :size="50"
                                    ></v-avatar>
                                </div>
                                <div class="flex-auto">
                                    <div
                                        class="text-base font-semibold mb-2 leading-none text-gray-1"
                                    >
                                        {{ user.displayName }}
                                    </div>
                                    <div
                                        class="text-gray-1 text-xs leading-relaxed"
                                    >
                                        {{ user.role }}
                                    </div>
                                    <div
                                        class="text-gray-1 text-xs leading-relaxed"
                                    >
                                        {{ user.email }}
                                    </div>
                                    <!-- <div
                                        class="text-gray-1 text-xs leading-relaxed"
                                    >
                                        {{ user.tel }}
                                    </div> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="text-gray-1">
                            <div class="v-list v-list-selectable-light">
                                <div class="v-list-item justify-between px-5">
                                    <div>
                                        <span class="text-sm">Language</span>
                                    </div>
                                    <div>
                                        <v-menu
                                            origin="origin-top-right"
                                            :width="120"
                                        >
                                            <template
                                                #activator="{ on, attrs }"
                                            >
                                                <div
                                                    class="p-2 flex items-center rounded border"
                                                    v-on="on"
                                                >
                                                    <div class="flex-none mr-2">
                                                        <img
                                                            src="@/assets/icons/flag/en-US.svg"
                                                            alt
                                                        />
                                                    </div>
                                                    <div
                                                        class="text-xs w-8 uppercase"
                                                    >
                                                        {{
                                                            localeCode == 1033
                                                                ? "Eng"
                                                                : "Th"
                                                        }}
                                                    </div>
                                                    <div class="flex-none">
                                                        <img
                                                            src="@/assets/icons/arrow/arrow-chevron-down.svg"
                                                            class="transform duration-100"
                                                            :class="{
                                                                'rotate-180':
                                                                    attrs.show
                                                            }"
                                                            alt
                                                        />
                                                    </div>
                                                </div>
                                            </template>

                                            <div
                                                class="v-list v-list-selectable v-list-dense"
                                            >
                                                <div
                                                    class="v-list-item px-2"
                                                    @click="switchLang(1033)"
                                                >
                                                    <div class="flex-none mr-2">
                                                        <img
                                                            src="@/assets/icons/flag/en-US.svg"
                                                            alt
                                                        />
                                                    </div>
                                                    <div
                                                        class="flex-auto text-sm"
                                                    >
                                                        English
                                                    </div>
                                                </div>
                                                <div
                                                    class="v-list-item px-2"
                                                    @click="switchLang(1054)"
                                                >
                                                    <div class="flex-none mr-2">
                                                        <img
                                                            src="@/assets/icons/flag/en-US.svg"
                                                            alt
                                                        />
                                                    </div>
                                                    <div
                                                        class="flex-auto text-sm"
                                                    >
                                                        ไทย
                                                    </div>
                                                </div>
                                            </div>
                                        </v-menu>
                                    </div>
                                </div>

                                <div class="v-list-item justify-between px-5">
                                    <div>
                                        <div class="text-sm">Profile</div>
                                        <div class="text-gray-400 text-xs">
                                            Name, Email, Position, ...
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src="@/assets/icons/arrow/arrow-chevron-down.svg"
                                            class="transform -rotate-90 w-3"
                                            alt
                                        />
                                    </div>
                                </div>
                                <div class="v-list-item justify-between px-5">
                                    <div>
                                        <div class="text-sm">Settings</div>
                                        <div class="text-gray-400 text-xs">
                                            Currency, Time Zone, ...
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src="@/assets/icons/arrow/arrow-chevron-down.svg"
                                            class="transform -rotate-90 w-3"
                                            alt
                                        />
                                    </div>
                                </div>
                                <div class="v-list-item justify-between px-5">
                                    <div>
                                        <div class="text-sm">Logs</div>
                                    </div>
                                    <div>
                                        <img
                                            src="@/assets/icons/arrow/arrow-chevron-down.svg"
                                            class="transform -rotate-90 w-3"
                                            alt
                                        />
                                    </div>
                                </div>
                                <div class="v-list-item justify-between px-5">
                                    <div>
                                        <div class="text-sm">
                                            Help & Support
                                        </div>
                                    </div>
                                    <div>
                                        <img
                                            src="@/assets/icons/arrow/arrow-chevron-down.svg"
                                            class="transform -rotate-90 w-3"
                                            alt
                                        />
                                    </div>
                                </div>
                                <div class="v-list-item justify-between px-5">
                                    <div>
                                        <div class="text-sm">About</div>
                                    </div>
                                    <div>
                                        <img
                                            src="@/assets/icons/arrow/arrow-chevron-down.svg"
                                            class="transform -rotate-90 w-3"
                                            alt
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="border-b-2 flex-none">
                        <div class="p-3 text-center">
                            <button
                                class="text-sm text-blue-1 h-9 w-full"
                                @click="sightout()"
                            >
                                <span>Sign Out</span>
                            </button>
                        </div>
                    </div>
                    <div class="flex-none">
                        <div class="p-3 text-center">
                            <button
                                class="text-sm text-blue-1 h-9 w-full"
                                @click="dismiss()"
                            >
                                <span>Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import VAvatar from "@/components/Avatar/Avatar.vue";
// import VMenu from "@/components/Menu/Menu.vue";
import CacheHelper from "@/helper/CacheHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
// helper
import PropsHelper from "@/helper/PropsHelper";
// mixin
import LocaleMixin from "@/mixin/LocaleMixin";
import * as Msal from "@azure/msal-browser";
import { config } from "@/config/bzbsconfig.js";

const myMSAL = new Msal.PublicClientApplication(config.msalConfig);

var Cache = new CacheHelper(ConfigManager.getCacheType());
export default {
    name: "UserAccountPanel",
    props: {
        width: {
            type: [Number, String],
            default: 364,
        },
    },
    mixins: [LocaleMixin],
    inject: ["TopBarStore"],
    components: {
        VAvatar,
        // VMenu
    },
    data: function () {
        return {
            items: [],
            muted: true,
        };
    },
    computed: {
        user: function () {
            // this should get from account store
            return {
                img: null,
                shortedName: Cache.getCache("account").shortedname,
                displayName: Cache.getCache("account").name,
                role: "",
                email: Cache.getCache("account").email,
                tel: "081-234-5678",
            };
        },
        mainBlockStyle: function () {
            return {
                width: PropsHelper.getBoxUnit(this.width),
            };
        },
        containerStyle: function () {
            return {};
        },
        value: {
            get: function () {
                return this.TopBarStore.state.userAccountPanel;
            },
            set: function (newVal) {
                this.TopBarStore.methods.setUserAccountPanel(newVal);
            },
        },
    },
    watch: {
        value: function (val) {
            if (val) {
                this.disableScroll();
            } else {
                this.enableScroll();
            }
        },
    },
    emits: ["update:value", "update:muted"],
    created() {},
    methods: {
        onClickAway() {
            this.dismiss();
            this.$nextTick(() => {
                this.$emit("onClickAway");
            });
        },
        dismiss() {
            this.value = false;
        },
        sightout() {
            this.value = false;
            Cache.removeCache("msToken");
            localStorage.removeItem("permission");
            localStorage.removeItem("permissionbyapp");
            localStorage.removeItem("isSearch");
            Cache.setCache("loading", false);
            const logoutRequest = {
                postLogoutRedirectUri:
                    config.msalConfig.auth.postLogoutRedirectUri,
            };

            myMSAL.logoutRedirect(logoutRequest);
            // this.$router.push({ name: "login_ad" });
        },
        disableScroll() {
            setTimeout(() => {
                document.body.classList.add("overflow-hidden");
            }, 100);
        },
        enableScroll() {
            document.body.classList.remove("overflow-hidden");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style/base/base.scss";
.panel-header {
    height: $topbar-height;
}
</style>
