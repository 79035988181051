<template>
  <transition name="fade">
    <div
      class="datefilter"
      v-if="value"
      :class="{ 'has-calendar': hasCalendar }"
    >
      <div class="wrap-range">
        <div class="panel-header flex-none">
          <div class="flex-grow font-semibold">By Date Range</div>
        </div>
        <div
          class="wrap-btn"
          v-for="(range, idx) of list_range"
          :key="`datefilter-${idx}`"
        >
          <a-button
            :class="{ active: select == range.value }"
            @click="selectRange(range)"
          >
            {{ range.label }}
          </a-button>
        </div>
        <div class="wrap-reset">
          <a class="reset-all" @click="resetAll(Today)">Reset all</a>
        </div>
      </div>
      <div v-if="hasCalendar" class="wrap-calendar">
        <div class="panel-header flex-none">
          <div class="flex-grow font-semibold">Custom Start-End</div>
          <a @click="dismiss()"><close-outlined /></a>
        </div>
        <a-calendar
          class="box-calendar"
          :value="start"
          :fullscreen="false"
          :disabledDate="disabledStart"
          @select="onSelectStart"
        >
          <template #headerRender="{ value: current, onChange }">
            <a-row type="flex" class="row-head" justify="space-between">
              <a-col>
                <a
                  class="btn-nav-calendar"
                  @click="
                    onChange(
                      current
                        ? current.month(current.month() - 1)
                        : today.month(today.month() - 1),
                    )
                  "
                >
                  <img src="@/assets/icons/arrow/icon-pre.svg" />
                </a>
              </a-col>
              <a-col>
                <a-select
                  size="small"
                  :dropdown-match-select-width="false"
                  :value="
                    current ? String(current.month()) : String(today.month())
                  "
                  @change="
                    selectedMonth => {
                      current
                        ? onChange(
                            current.month(parseInt(String(selectedMonth), 10)),
                          )
                        : onChange(
                            today.month(parseInt(String(selectedMonth), 10)),
                          )
                    }
                  "
                >
                  <a-select-option
                    v-for="(val, index) in current
                      ? getMonths(current)
                      : getMonths(today)"
                    :key="String(index)"
                    class="month-item"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select
                  size="small"
                  :dropdown-match-select-width="false"
                  class="my-year-select"
                  :value="
                    current ? String(current.year()) : String(today.year())
                  "
                  @change="
                    newYear => {
                      current
                        ? onChange(current.year(newYear))
                        : onChange(today.year(newYear))
                    }
                  "
                >
                  <a-select-option
                    v-for="val in current ? getYears(today) : getYears(today)"
                    :key="String(val)"
                    class="year-item"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a
                  :class="{
                    disable: current
                      ? current.month() == today.month()
                      : 'disable',
                  }"
                  class="btn-nav-calendar"
                  @click="
                    onChange(
                      current
                        ? current.month(current.month() + 1)
                        : today.month(today.month() + 1),
                    )
                  "
                >
                  <img src="@/assets/icons/arrow/icon-next.svg" />
                </a>
              </a-col>
            </a-row>
          </template>
        </a-calendar>
        <a-calendar
          class="box-calendar"
          :value="end"
          :fullscreen="false"
          :disabledDate="disabledEnd"
          @select="onSelectEnd"
        >
          <template #headerRender="{ value: current, onChange }">
            <a-row type="flex" class="row-head" justify="space-between">
              <a-col>
                <a
                  class="btn-nav-calendar"
                  @click="
                    onChange(
                      current
                        ? current.month(current.month() - 1)
                        : today.month(today.month() - 1),
                    )
                  "
                >
                  <img src="@/assets/icons/arrow/icon-pre.svg" />
                </a>
              </a-col>
              <a-col>
                <a-select
                  size="small"
                  :dropdown-match-select-width="false"
                  :value="
                    current ? String(current.month()) : String(today.month())
                  "
                  @change="
                    selectedMonth => {
                      current
                        ? onChange(
                            current.month(parseInt(String(selectedMonth), 10)),
                          )
                        : onChange(
                            today.month(parseInt(String(selectedMonth), 10)),
                          )
                    }
                  "
                >
                  <a-select-option
                    v-for="(val, index) in current
                      ? getMonths(current)
                      : getMonths(today)"
                    :key="String(index)"
                    class="month-item"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select
                  size="small"
                  :dropdown-match-select-width="false"
                  class="my-year-select"
                  :value="
                    current ? String(current.year()) : String(today.year())
                  "
                  @change="
                    newYear => {
                      current
                        ? onChange(current.year(newYear))
                        : onChange(today.year(newYear))
                    }
                  "
                >
                  <a-select-option
                    v-for="val in current ? getYears(today) : getYears(today)"
                    :key="String(val)"
                    class="year-item"
                  >
                    {{ val }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a
                  :class="{
                    disable: current
                      ? current.month() == today.month()
                      : 'disable',
                  }"
                  class="btn-nav-calendar"
                  @click="
                    onChange(
                      current
                        ? current.month(current.month() + 1)
                        : today.month(today.month() + 1),
                    )
                  "
                >
                  <img src="@/assets/icons/arrow/icon-next.svg" />
                </a>
              </a-col>
            </a-row>
          </template>
        </a-calendar>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'
import dayjs from 'dayjs'

export default {
  name: 'DateFilter',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hasCalendar: {
      type: Boolean,
      default: false,
    },
    Today: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      list_range: [
        {
          label: 'Today',
          value: 'Today',
        },
        {
          label: 'This Week',
          value: 'This Week',
        },
        {
          label: 'Last Week',
          value: 'Last Week',
        },
        {
          label: 'This Month',
          value: 'This Month',
        },
        {
          label: 'Last Month',
          value: 'Last Month',
        },
        {
          label: 'Last 3 Weeks',
          value: 'Last 3 Weeks',
        },
        {
          label: 'Last 6 Weeks',
          value: 'Last 6 Weeks',
        },
        {
          label: 'Last 3 Months',
          value: 'Last 3 Months',
        },
        {
          label: 'Last 6 Months',
          value: 'Last 6 Months',
        },
      ],
      select: null,
      start: '',
      end: '',
      firsttime: true,
      today: null,
      disabledDate: null,
      resetnull: null,
    }
  },
  watch: {
    value(value) {
      if (value && this.firsttime) {
        this.firsttime = false
      }
    },
  },
  emits: ['resetDatefilter'],
  created: function () {
    this.today = ref(dayjs())
  },
  methods: {
    disabledStart(current) {
      return current && current.valueOf() > Date.now()
    },
    disabledEnd(current) {
      if (this.start) {
        return (
          (current && current.valueOf() < this.start.valueOf()) ||
          (current && current.valueOf() > Date.now())
        )
      } else {
        return current && current.valueOf() > Date.now()
      }
    },
    dismiss: function () {
      this.$emit('handleCloseDaterange')
    },
    // dismiss: function () {
    //   this.$emit('update:value', false)
    // },
    resetAll(today) {
      this.select = today ? 'Today' : ''
      this.$emit('reset', this.resetnull)
    },
    setToday() {
      this.select = 'Today'
    },
    selectRange(range) {
      this.select = range.value
      this.start = null
      this.end = null
      this.$emit('selectRange', range)
    },
    selectCalendar() {
      var range = {
        start: this.start,
        end: this.end,
      }
      this.$emit('selectCalendar', range)
    },
    onSelectStart(value) {
      this.select = null
      this.start = value
      this.disabledEnd()
      this.selectCalendar()
    },
    onSelectEnd(value) {
      this.select = null
      this.end = value
      this.selectCalendar()
    },
    getMonths(value) {
      const localeData = value.localeData()
      const currentyear = new Date().getFullYear()
      let monthrange = 12
      const months = []
      if (value.year() == currentyear) {
        monthrange = new Date().getMonth() + 1
      }
      for (let i = 0; i < monthrange; i++) {
        months.push(localeData.months(value.month(i)))
      }
      return months
    },
    getYears(value) {
      const year = value.year()
      const years = []
      for (let i = year - 10; i <= year; i += 1) {
        years.push(i)
      }
      return years
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/base.scss';
.datefilter {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 3;
  width: 290px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(102, 108, 139, 0.2);
  &.has-calendar {
    width: 900px;
  }
  .panel-header {
    padding: 1rem 0.5rem;
  }
  .wrap-range {
    padding: 0 8px 16px;
    width: 290px;
    float: left;
    .wrap-btn {
      width: 50%;
      padding: 0 8px 16px;
      float: left;
    }
    .ant-btn {
      width: 100%;
      &.active {
        color: #fff;
        border-color: #f9a601;
        background: #f9a601;
      }
    }
    .wrap-reset {
      width: fit-content;
      padding-top: 118%;
      padding-left: 42%;
    }
  }
  .wrap-calendar {
    padding: 0 8px 16px;
    width: 610px;
    float: left;
    border-left: 1px solid $color-bg-app;
    .row-head {
      padding-bottom: 10px;
    }
    .ant-picker-panel {
      border: none;
      .ant-picker-body {
        padding: 10px 0;
      }
      .ant-picker-content {
        th {
          font-weight: bold;
          padding-bottom: 10px;
        }
        td {
          padding: 0;
          border: 1px solid $color-bg-app;
        }
      }
    }
    .box-calendar {
      width: 50%;
      float: left;
      padding: 0.5rem;
      .ant-picker-cell .ant-picker-cell-inner {
        width: 100%;
        height: 40px;
        line-height: 40px;
      }
    }
    .btn-nav-calendar {
      width: 25px;
      padding: 5px;
      display: block;
      &.disable {
        pointer-events: none;
        opacity: 0.5;
      }
      img {
        width: 100%;
      }
    }
  }
  .reset-all {
    color: #f79443;
    text-decoration: underline;
  }
}
</style>
