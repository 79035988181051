<template>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Card Redemtion</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <div class="mb-2">Card ID</div>
              <a-input v-model:value="levelname" @change="onChange" />
              <div class="mt-5">Source Issuer</div>
              <a-input v-model:value="levelname" @change="onChange" />
              <div class="mb-2 mt-5">Destination Issuer</div>
              <a-select
                style="width: 100%"
                v-model:value="test"
                :options="year_dropdown"
              />
              <div class="mb-2 mt-5">Destination Card ID</div>
              <a-select
                style="width: 100%"
                v-model:value="test"
                :options="year_dropdown"
              />
              <div class="mb-2 mt-5">Exchange Issuer</div>
              <a-select
                style="width: 100%"
                v-model:value="test"
                :options="year_dropdown"
              />
              <a-row class="mt-5">
                <a-col :span="8"
                  ><div>Current Point</div>
                  <a-input
                    class="input-redeem"
                    v-model:value="levelname"
                    @change="onChange"
                /></a-col>
                <a-col :span="8"
                  ><div>Earn Point</div>
                  <a-input
                    class="input-redeem"
                    v-model:value="levelname"
                    @change="onChange"
                /></a-col>
                <a-col :span="8"
                  ><div>Use Point</div>
                  <a-input
                    class="input-redeem"
                    v-model:value="levelname"
                    @change="onChange"
                /></a-col>
              </a-row>
              <div class="mb-2 mt-5">Remark</div>
              <a-textarea
                v-model:value="benefits"
                @change="onChange"
                :maxlength="200"
                :auto-size="{ minRows: 4, maxRows: 4 }"
              />
            </div>
          </div>
          <div class="flex-none px-4 py-3" style="text-align: -webkit-right">
            <a-button
              class="mr-3 btn-create"
              type="blue-2"
              @click="dismiss(true)"
            >
              Cancel
            </a-button>
            <a-button
              :disabled="setdisable"
              type="primary"
              class="btn-search btn-create"
              @click="UpdateUserLevel(true)"
            >
              Submit
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import Util from '@/helper/Utility'
// import moment from 'moment'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
// import _ from 'lodash'
export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    datalevel: {
      type: Array,
      default: () => [],
    },
    datalevelname: {
      type: String,
      default: '',
    },
    disablesubmit: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['getlevel'],
  data: function () {
    return {
      test: 'Please select',
      newdata: {},
      drag: false,
      setdisable: null,
      levelname: '',
      description: '',
      earnpoint: '',
      benefits: '',
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setdisable = this.disablesubmit
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
      this.setinput()
    },
  },
  mounted() {},
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    onChange() {
      this.setdisable = false
    },
    setinput() {
      this.levelname = this.datalevelname.level
      this.description = this.datalevelname.description
      this.earnpoint = this.datalevelname.mission
      this.benefits = this.datalevelname.benefits
    },

    UpdateUserLevel() {
      var userlevelupdate = {
        appid: Cache.getCache('app').id,
        items: [
          {
            level_id: this.datalevelname.key,
            level_name: this.levelname,
            description: this.description,
            mission: this.earnpoint,
            benefits: this.benefits,
          },
        ],
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutDataUserlevel(userlevelupdate)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('getlevel')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function (flagResetState = true) {
      console.log('dismiss')
      if (flagResetState) {
        this.resetState()
      } else {
        this.setState()
      }
      this.$emit('handleClose')
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log {
  width: 30rem;
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.btn-create {
  width: 120px !important;
}
</style>
<style>
.input-redeem {
  width: 140px !important;
}
</style>
