<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs class="mt-4" title="Help Chat" />
    </template>
    <div class="main-menu helpchat overflow-x-hidden flex flex-col">
      <div class="flex-1 w-full px-4">
        <div class="table-scroll">
          <nav class="nav-search">
            <div class="group-btn-right">
              <a-input-group class="search" compact>
                <a-select
                  class="select-search"
                  v-model:value="optionssearch"
                  :options="options_search"
                />
                <a-input
                  v-model:value="search"
                  placeholder="Search"
                  class="input-search"
                />
              </a-input-group>
              <a-select
                class="select-search mx-2"
                v-model:value="statusdropdown"
                placeholder="All Status"
                :options="status_dropdown"
              />
              <div class="wrap-date-filter">
                <a-button class="btn-date-filter" @click="toggleDateFilter()">
                  {{ dateFilterValue }}
                  <calendar-outlined />
                </a-button>
                <DateFilter
                  v-model:value="showDateFilter"
                  :hasCalendar="true"
                  @selectRange="selectRange($event)"
                  @selectCalendar="selectCalendar($event)"
                  @reset="resetDatefilter($event)"
                  @handleCloseDaterange="showDateFilter = false"
                />
                <!-- <DateFilter
                v-model:value="showDateFilter"
                :hasCalendar="true"
                @selectRange="selectRange($event)"
                @reset="resetDatefilter($event)"
                @selectCalendar="selectCalendar($event)"
              /> -->
              </div>
              <!-- <a-date-picker
              class="ml-3"
              v-model:value="startValue"
              :disabled-date="disabledStartDate"
              placeholder="Start Date"
              @openChange="handleStartOpenChange"
            />
            <a-date-picker
              class="ml-3"
              v-model:value="endValue"
              :disabled-date="disabledEndDate"
              placeholder="End Date"
              :open="endOpen"
              @openChange="handleEndOpenChange"
            /> -->
              <a-button
                :disabled="
                  (startValue && !endValue) || (!startValue && endValue)
                "
                type="primary"
                class="btn-search"
                @click="clicksearch()"
                ><search-outlined /> Search</a-button
              >
            </div>
          </nav>
          <div class="wrap-table-level" @scroll="handleScroll">
            <VLoadingTable :value="overlaytable" />
            <a-table
              class="fixed-head"
              :data-source="dataHelpChatList"
              :columns="columns"
              :pagination="false"
            >
              <template #help_topic="{ record }">
                <div class="helptopic">
                  <a class="font-level" @click="toggleHelpChatDetail(record)">{{
                    record.help_topic
                  }}</a>
                </div>
              </template>
              <template #image="{ record }">
                <div class="imgHelpchat" v-if="record.image">
                  <div class="boxs-imgs">
                    <div class="box-img2 w-100">
                      <img class="img80" :src="record.image" />
                      <div
                        class="overlay"
                        @click="handleOpenPreview(record.image)"
                      >
                        <a><eye-outlined /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #user="{ record }">
                <div>{{ record.user_id }}</div>
                <div class="fs-12">{{ record.name }}</div>
              </template>
              <template #rating="{ record }">
                <div class="chat-rate">
                  <div class="flex">
                    <div v-if="record.rating == 0">-</div>
                    <div v-if="record.rating == 1">
                      <img src="@/assets/icons/star.svg" />
                    </div>
                    <div v-if="record.rating == 2">
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                    </div>
                    <div v-if="record.rating == 3">
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                    </div>
                    <div v-if="record.rating == 4">
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                    </div>
                    <div v-if="record.rating == 5">
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                      <img src="@/assets/icons/star.svg" />
                    </div>
                  </div>
                </div>
              </template>
              <template #created_date="{ record }">
                <div>
                  {{ formatDate(record.created_date, 'DD/MMM/YYYY -  HH:mm') }}
                </div>
              </template>
              <template #last_reply="{ record }">
                <div>
                  {{
                    formatDate(
                      record.latest_admin_comment_date,
                      'DD/MMM/YYYY -  HH:mm',
                      this.isTimezone * -1,
                    )
                  }}
                </div>
              </template>
              <template #total="{ record }">
                {{ record.total.toLocaleString('en-US') }}
              </template>
              <template #status="{ text }">
                <template v-for="(item, index) in status">
                  <a-tag
                    v-if="text == item.name"
                    :key="index"
                    :color="item.color == 'default' ? null : item.color"
                    class="tag-status"
                  >
                    <a-badge :status="item.color" :text="text" />
                  </a-tag>
                </template>
                <!-- <div class="status">
                <div
                  v-show="record.status != 'MarkRead'"
                  :class="{
                    green: record.status === 'Read',
                    red: record.status === 'Unread',
                    orange: record.status === 'MarkRead',
                  }"
                >
                  {{ record.status }}
                </div>
                <div
                  v-show="record.status == 'MarkRead'"
                  :class="{
                    orange: record.status === 'MarkRead',
                  }"
                >
                  Mark Read
                </div>
              </div> -->
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- <div class="flex-none">
      <Footer />
    </div> -->
      <a-modal
        class="img-modal"
        :visible="previewImgDetail"
        :title="false"
        :footer="null"
        :centered="true"
        :closable="false"
        @cancel="handleCancel"
      >
        <a class="close-preview" @click="handleCancel">
          <span class="icon-x">X</span>
        </a>
        <img alt="example" style="width: 100%" :src="url" />
      </a-modal>
      <DisplayFieldsHelpChat
        v-if="showDisplayFieldsApproveDetail"
        :value="showDisplayFieldsApproveDetail"
        :helpchatdetail="helpchatdetail"
        :dataHelpChatDetail="dataHelpChatDetail"
        @getHelpchatList="getHelpchatList($event)"
        @getHelpChatDetail="getHelpChatDetail($event)"
        @handleClose="showDisplayFieldsApproveDetail = false"
      >
      </DisplayFieldsHelpChat>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFieldsHelpChat from '@/module/HelpChat/component/DisplayFieldsHelpchatDetail.vue'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    // Footer,
    DateFilter,
    DisplayFieldsHelpChat,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Help chat',
        },
      ],
      test: require('@/assets/background/bg.png'),
      startValue: null,
      endValue: null,
      endOpen: false,
      overlaytable: false,
      nomore: false,
      page: 1,
      search: '',
      showDisplayFieldsApproveDetail: false,
      userId: this.$route.params.id,
      status: [
        {
          id: 1,
          name: 'Read',
          color: 'green',
        },
        {
          id: 2,
          name: 'Unread',
          color: 'red',
        },
        {
          id: 3,
          name: 'MarkRead',
          color: 'yellow',
        },
      ],
      optionssearch: 'user_id',
      options_search: [
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'Help ID',
          value: 'help_id',
        },
      ],
      searchObject: {
        help_id: '',
        user_id: '',
        name: '',
        status: '',
        startdate: '',
        enddate: '',
      },
      statusdropdown: null,
      status_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Read',
          value: 'Read',
        },
        {
          label: 'Unread',
          value: 'Unread',
        },
        {
          label: 'Mark Read',
          value: 'MarkRead',
        },
      ],
      columns: [
        {
          title: 'Help Topic',
          dataIndex: 'help_topic',
          slots: { customRender: 'help_topic' },
          key: 'help_topic',
          width: 300,
          align: 'left',
        },
        {
          title: 'Image',
          dataIndex: 'image',
          slots: { customRender: 'image' },
          key: 'image',
          width: 180,
          align: 'center',
        },
        {
          title: 'User',
          dataIndex: 'user',
          slots: { customRender: 'user' },
          key: 'user',
          width: 180,
          align: 'left',
        },
        {
          title: 'Create Date',
          dataIndex: 'created_date',
          slots: { customRender: 'created_date' },
          key: 'created_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Last Admin Reply',
          dataIndex: 'latest_admin_comment_date',
          slots: { customRender: 'last_reply' },
          key: 'last_reply',
          width: 220,
          align: 'left',
        },
        {
          title: 'Message',
          dataIndex: 'total',
          slots: { customRender: 'total' },
          key: 'total',
          width: 120,
          align: 'right',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 120,
          align: 'center',
        },
      ],
      dataHelpChatList: [],
      helpchatdetail: [],
      dataLevelname: '',
      buzzkey: '',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      previewImgDetail: false,
      url: '',
      formatdate: 'DD/MMM/YYYY',
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      if (this.userId == this.search) {
        return [
          {
            test: 'userid',
            text: `User ID : ${this.userId}`,
          },
          {
            test: 'help',
            text: '<div class="font-normal text-sm text-gray-400">Help Chat</div>',
          },
        ]
      } else {
        return [
          {
            test: 'help',
            text: 'Help Chat',
          },
        ]
      }
    },
  },
  mounted() {
    if (this.userId) {
      this.searchObject.user_id = this.userId
      this.search = this.userId
    }
    this.startdate = moment().startOf('month').format('YYYY-MM-DD')
    this.enddate = moment().format('YYYY-MM-DD')
    this.dateFilterValue =
      moment(this.startdate).format('DD/MM/YYYY') +
      ' to ' +
      moment(this.enddate).format('DD/MM/YYYY')
    this.clicksearch()
  },
  watch: {},
  methods: {
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.searchObject.user_id =
        this.optionssearch == 'user_id' ? this.search : ''
      this.searchObject.help_id =
        this.optionssearch == 'help_id' ? this.search : ''
      this.searchObject.name = this.optionssearch == 'name' ? this.search : ''
      this.searchObject.status = this.statusdropdown
      this.showDateFilter = false
      this.getHelpchatList().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getHelpchatList() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetHelpchatList(
          this.searchObject.user_id,
          this.searchObject.help_id,
          this.searchObject.name,
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.status = this.statusdropdown
            ? this.statusdropdown
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataHelpChatList = []
            }
            _.forEach(data.result, item => {
              this.dataHelpChatList.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            resolve(this.dataHelpChatList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getHelpchatList().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getdetail() {
      this.getHelpChatDetail()
    },
    getHelpChatDetail() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetHelpChatDetail(this.buzzkey).subscribe(
          res => {
            this.dataHelpChatDetail = res.result
            this.$forceUpdate()
            resolve(this.dataHelpChatDetail)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleHelpChatDetail(record) {
      this.helpchatdetail = record
      this.buzzkey = record.buzz_key
      this.getHelpChatDetail().then(() => {
        this.showDisplayFieldsApproveDetail =
          !this.showDisplayFieldsApproveDetail
      })
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      console.log(this.startValue)
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    handleOpenPreview(url) {
      this.url = url
      this.previewImgDetail = true
    },
    handleCancel() {
      this.previewImgDetail = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.helpchat {
  overflow-x: hidden;
  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 195px);
    max-height: calc(100vh - 195px);
  }
  .nav-search {
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;
    .search {
      float: left;
      width: auto;
      .input-search {
        width: 200px;
        border-left: none;
      }
      .select-search {
        width: 130px;
      }
    }
    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
    }
    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }
    .btn-date-filter {
      width: 260px;
      text-align: left;
      .anticon {
        float: right;
        margin-top: 2px;
      }
    }
    .group-btn-right {
      display: flex;
      float: right;
      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 270px);
    overflow-x: auto;
  }
  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
    .ant-table-row {
      height: 120px !important;
    }
  }
  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }
  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }
      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;
        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;
          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }
      &:hover {
        .wrap-sort span {
          display: inline-block;
          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
.img-modal {
  a:hover {
    color: #f79443 !important;
  }
}
</style>
<style lang="scss">
.ant-image-preview-img {
  display: unset !important;
}
.chat-rate {
  display: inline-table;
}
.helpchat {
  .ant-table-row {
    height: 100px !important;
    vertical-align: top !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    left: 85% !important;
  }
}
</style>
