<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-ticket bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Create Ticket</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto p-4">
            <a-form
              layout="vertical"
              :model="formState"
              v-bind="formItemLayout"
            >
              <a-form-item
                label="Subject"
                :rules="[
                  { required: true, message: 'Please input your username!' },
                ]"
              >
                <a-select
                  v-model:value="formState.Topic_type"
                  placeholder="Select subject"
                  :options="option_topictype"
                ></a-select>
              </a-form-item>
              <a-form-item
                label="Topic Type"
                :rules="[
                  { required: true, message: 'Please input your username!' },
                ]"
              >
                <a-select
                  v-model:value="formState.reason"
                  placeholder="Select topic types"
                  :options="option_reason"
                ></a-select>
              </a-form-item>
              <a-form-item
                label="Topic details"
                :rules="[
                  { required: true, message: 'Please input your username!' },
                ]"
              >
                <a-select
                  v-model:value="formState.Topic_detail"
                  placeholder="Select topic details"
                  :options="option_topicdetail"
                ></a-select>
              </a-form-item>
              <a-form-item
                label="Remark"
                :rules="[
                  { required: true, message: 'Please input your username!' },
                ]"
              >
                <a-textarea
                  v-model:value="formState.remark"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
                />
              </a-form-item>
              <a-form-item label="Campaign ID">
                <!-- <a-select
                  v-model:value="formState.Campaign_id"
                  show-search
                  placeholder="Search Campaign ID"
                  :options="options"
                  :filter-option="filterOption"
                ></a-select> -->
              </a-form-item>
              <div class="row">
                <div class="col-6">
                  <a-form-item
                    label="Priority"
                    :rules="[
                      {
                        required: true,
                        message: 'Please input your username!',
                      },
                    ]"
                  >
                    <a-select
                      v-model:value="formState.priority"
                      placeholder="Search Priority"
                      :options="options_priority"
                    ></a-select>
                  </a-form-item>
                </div>
                <div class="col-6">
                  <a-form-item label="Expect Date">
                    <a-date-picker
                      class="w-100"
                      v-model:value="formState.expirydate"
                      placeholder="Select Expect Date"
                      :showToday="false"
                      :disabled-date="disabledDate"
                    />
                  </a-form-item>
                </div>
              </div>
              <a-form-item
                label="Channel"
                :rules="[
                  { required: true, message: 'Please input your username!' },
                ]"
              >
                <a-select
                  v-model:value="formState.channel"
                  placeholder="Search Channel"
                  :options="options_channel"
                ></a-select>
              </a-form-item>
            </a-form>
            <a-upload
              v-model:file-list="fileList"
              list-type="picture-card"
              class="testtest"
              :beforeUpload="[
                function () {
                  false
                },
              ]"
              @preview="handlePreview"
            >
              <div>Upload Images (Maximum 5 images)</div>
              <a-button class="ml-3" style="width: 100px">
                <div class="d-flex">
                  <img src="@/assets/icons/plus.svg" />
                  <div class="ml-2">Browse</div>
                </div>
              </a-button>
            </a-upload>
          </div>
          <div class="flex-none px-4 py-3">
            <a-button type="primary" @click="dismiss(true)" ghost>
              Close
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
// import _ from 'lodash'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    dismiss: function () {
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log-ticket {
  width: 35rem;
}
</style>
