import { reactive } from 'vue'
import BzbsApi from '@/core/Http/BzbsApi.js'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import Config from '@/config/vueconfig.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
const state = reactive({})

const methods = {
  init: function () {
    this.apiGetConfig()
  },
  apiGetConfig: function () {
    if (!Cache.getCache('bzbsConfig')) {
      var url
      if (window.location.hostname == 'localhost') {
        url =
          'https://dev-web-productization-backoffice.azurewebsites.net/api/version'
      } else {
        url = window.location.origin + '/api/version'
      }
      BzbsApi.connectBzbsGetBlob(url).then(res => {
        var response = res.data.api_setting
        var env = res.data.api_setting.build
        var texterror = 'Please set config '
        let errorArray = {}
        let catchConfig = {}
        if (env !== 'dev') {
          catchConfig.apiUrl = ConfigManager.getUsermgrAPI()
          catchConfig.baseUrl = ConfigManager.getUsermgrUrl()
          catchConfig.blobUrl = ConfigManager.getBlobUrl()
          catchConfig.campaignUrl = Config().CampaignManagementUrl
        } else {
          Object.keys(response).forEach(i => {
            if (response[i]) {
              if (response[i].includes(env) || i == 'baseVersion') {
                catchConfig[i] = response[i]
              } else {
                errorArray[i] = i
              }
            } else {
              errorArray[i] = i
            }
          })
        }
        if (
          Object.keys(errorArray).length > 0 &&
          window.location.pathname !== '/error/notfindconfig'
        ) {
          this.errorApi(texterror.concat(errorArray))
        } else {
          Cache.setCache('bzbsConfig', catchConfig)
          this.$router.go()
        }
      })
    }
  },
}

export default {
  state: state,
  methods: methods,
}
