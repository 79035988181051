<template>
  <a-modal
    title="Add Users to Tag"
    class="modal-usertags"
    :visible="modaltags"
    @cancel="handleCancel"
    @ok="AddusertoTag"
    okText="Save"
    :width="450"
    :centered="true"
    :closable="false"
  >
      <div>
        Tag Group
        <a-select
          v-model:value="tag_group"
          :options="options_tag_group"
          class="width-fill-full"
          @change="selectTag(tag_group)"
        ></a-select>
      </div>
      <div>
        Tag Name
        <a-select
          v-model:value="tag_name"
          :options="options_tag_name"
        ></a-select>
      </div>
  </a-modal>
</template>
<script>
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import _ from 'lodash'
import ConfigManager from '@/config/ConfigManager.js'
// import CacheHelper from '@/helper/CacheHelper.js'
// import { CloseOutlined } from '@ant-design/icons-vue'
// var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserListToolbar',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedRowKeys: {
      type: Array,
    },
    clearall:{
      type: Function,
    },
  },
  inject: ['tableHeaders', 'toolbarStates'],
  components: {
    // CloseOutlined,
  },
  mixins: [],
  emits: [],
  data: function () {
    return {
      modaltags: false,
      usersid: [],
      tag_group:null,
      options_tag_group:[],
      tag_name:null,
      options_tag_name:[],
      dataTagtypes:[]
    }
  },
  computed: {},
  created: function () {},
  watch: {
    visible(value, oldvalue) {
      if (value) {
        this.usersid = this.selectedRowKeys
        this.getTagTypes()
      }
      if (value != oldvalue) {
        this.modaltags = value
      }
    },
  },
  mounted: function () {},
  methods: {
    getTagTypes() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagTypes().subscribe(
          res => {
            this.dataTagtypes = res.result
            _.forEach(this.dataTagtypes, (item, index) => {
              this.options_tag_group[index] = {
                label: item.row_key,
                value: item.row_key,
              }
            })
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    selectTag(tag_group) {
        this.tag_name = null
      this.options_tag_name = []
      var itemtag = this.dataTagtypes.find(x => x.row_key == tag_group)
      _.forEach(itemtag.tag_types, item => {
          this.options_tag_name.push({
            label: item.row_key,
            value: item.row_key,
          })
      })
    },
    AddusertoTag() {
      var datatagtypes = {
        appid: ConfigManager.getAppId(),
        user_ids: this.usersid,
        tag_group: this.tag_group,
        tag_type: this.tag_name,
      }
      this.PostTagTypes(datatagtypes)
    },
    PostTagTypes(datatagtypes) {
        this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUserTags(datatagtypes)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$emit('handleCloseModalTag')
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.tag_group = null
                this.tag_name = null
                this.clearall()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleCancel() {
      this.$emit('handleCloseModalTag')
    },
  },
}
</script>