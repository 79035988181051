import { h } from 'vue'
export default {
    name: 'VSvgIcon',
    props: {
        src: {
            type: String,
            default: ''
        },
        left: {
            type: String,
            default: undefined
        },
        right: {
            type: String,
            default: undefined
        },
        small: {
            type: String,
            default: undefined
        }
    },
    data: function () {
        return {
            currentSrc: '', // Set from srcset
            image: null,
        }
    },
    mounted() {
        this.loadImage()
    },
    methods: {
        onLoad() {
            this.getSrc()
            this.$emit('load', this.src)
            // if (
            //     this.image &&
            //     (this.src.endsWith('.svg') || this.src.startsWith('data:image/svg+xml'))
            // ) {
            //     if (this.image.naturalHeight && this.image.naturalWidth) {
            //         this.naturalWidth = this.image.naturalWidth
            //         this.calculatedAspectRatio = this.image.naturalWidth / this.image.naturalHeight
            //     } else {
            //         this.calculatedAspectRatio = 1
            //     }
            // }
        },
        onError() {
            this.$emit('error', this.src)
        },
        getSrc() {
            /* istanbul ignore else */
            if (this.image) this.currentSrc = this.image.currentSrc || this.image.src
        },
        loadImage() {
            const image = new Image()
            this.image = image

            image.onload = () => {
                /* istanbul ignore if */
                if (image.decode) {
                    image.decode().catch((err) => {
                        console.warn(
                            `Failed to decode image, trying to render anyway\n\n` +
                            `src: ${this.src}` +
                            (err.message ? `\nOriginal error: ${err.message}` : ''),
                            this
                        )
                    }).then(this.onLoad)
                } else {
                    this.onLoad()
                }
            }
            image.onerror = this.onError
            image.src = this.src
            this.$emit('loadstart', this.src)
            this.getSrc()
        },
    },
    render: function () {
        let imgIconClass = ['icon', { 'left': this.left != undefined, 'right': this.right != undefined, 'small': this.small != undefined }]

        return h('img', {
            src: this.currentSrc,
            class: imgIconClass,
        })

    }
}
