<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs
        class="mt-2"
        title="Config Database"
        :databreadcrumbs="breadcrumbs"
      />
    </template>
    <div
      class="main-menu approvepoint configdatabase overflow-x-hidden flex flex-col"
    >
      <div class="flex-1 w-full px-4">
        <div class="table-scroll">
          <div class="p-4">
            <div class="row mb-4">
              <div class="col-3">
                <div class="text-gray">PartitionKey :</div>
                <a-input v-model:value="partitionkey" :disabled="!Edit" />
              </div>
              <div class="col-3">
                <div class="text-gray">RowKey :</div>
                <a-input v-model:value="rowkey" :disabled="!Edit" />
              </div>
              <div v-if="!Edit" class="col-6 right">
                <a-button type="primary" @click="EditConfig()" class="mt-4"
                  >Edit</a-button
                >
              </div>
            </div>
            <div class="border-t detail">
              <div class="config pt-4 pr-4">
                <div class="row">
                  <div class="col-3">
                    <div class="text-gray">Property</div>
                  </div>
                  <div class="col-1">
                    <div class="text-gray">Type</div>
                  </div>
                  <div class="col-8">
                    <div class="text-gray">Value</div>
                  </div>
                </div>
                <div class="row mt-2" style="line-height: 60px">
                  <div class="col-3">
                    <div class="text-gray">TableName :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-input v-model:value="valueTableName" :disabled="!Edit" />
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                  <div class="col-3">
                    <div class="text-gray">TablePerfix :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-input
                      v-model:value="valueTablePerfix"
                      :disabled="!Edit"
                    />
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                  <div class="col-3">
                    <div class="text-gray">PointLogTableName :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-radio-group
                      v-model:value="pointlogtable"
                      :disabled="!Edit"
                    >
                      <a-radio :value="1">PointLog</a-radio>
                      <a-radio :value="2">PointMLog</a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                  <div class="col-3">
                    <div class="text-gray">PurchaseType :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-radio-group
                      v-model:value="purchasetype"
                      :disabled="!Edit"
                    >
                      <a-radio :value="1">Good</a-radio>
                      <a-radio :value="2">Food</a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                  <div class="col-3">
                    <div class="text-gray">UserAddressTaxTableName :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-select
                      v-model:value="valueUserAddressTaxTableName"
                      mode="tags"
                      :disabled="!Edit"
                      class="width-fill-full"
                    ></a-select>
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                  <div class="col-3">
                    <div class="text-gray">PerfixOfUserAccptanceLog :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-input
                      v-model:value="valuePerfixOfUserAccptanceLog"
                      :disabled="!Edit"
                    />
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                  <div class="col-3">
                    <div class="text-gray">RemoveTableNames :</div>
                  </div>
                  <div class="col-1">
                    <div>String</div>
                  </div>
                  <div :class="Edit ? 'col-6' : 'col-8'">
                    <a-select
                      v-model:value="valueRemoveTableNames"
                      mode="tags"
                      :disabled="!Edit"
                      class="width-fill-full"
                    ></a-select>
                  </div>
                  <div v-if="Edit" class="col-2">
                    <a-button type="primary" @click="setData()">Save</a-button>
                  </div>
                </div>
              </div>
              <div class="additionalconfig p-4">
                <div class="d-flex justify-content-between">
                  <div>AdditionalProfileConfigs : String</div>
                  <!-- <div>
                    <a class="text-link" @click="AddAdditionalConfigs()"
                      >+ Add Account</a
                    >
                  </div> -->
                </div>
                <div
                  v-for="(itemaccount, index) of additionnal_config"
                  :key="`additionnalconfigindex-${index}`"
                >
                  <div class="p-3 mt-3 profileconfig">
                    <div class="row mt-3">
                      <div class="col-4">
                        <div>Account :</div>
                      </div>
                      <div class="col-8">
                        <a-input
                          v-model:value="additionnal_config_account"
                          :disabled="!Edit"
                        />
                      </div>
                    </div>
                    <hr class="mt-3" />
                    <div class="d-flex justify-content-between pt-3">
                      <div>Table Config</div>
                      <a-button
                        type="primary"
                        class="btn-create"
                        @click="AddTableConfigs()"
                        :disabled="!Edit"
                        ghost
                        >+ Add Table</a-button
                      >
                    </div>
                    <div
                      v-for="(item, index) of table_configs"
                      :key="`tableconfigindex-${index}`"
                    >
                      <div class="row mt-3">
                        <div class="col-4">
                          <div>Name :</div>
                        </div>
                        <div class="col-8">
                          <a-input
                            v-model:value="item.table_name"
                            :disabled="!Edit"
                          />
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-4">
                          <div>HasMultiRows :</div>
                        </div>
                        <div class="col-8">
                          <a-switch
                            size="small"
                            v-model:checked="item.hasmultirows"
                            :disabled="!Edit"
                          />
                        </div>
                      </div>
                      <hr v-if="table_configs.length > 1" class="mt-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="Edit && !checkData" class="text-center pt-3 border-t">
              <a-button type="primary" @click="setData()">Create</a-button>
            </div>
            <div v-if="Edit && checkData" class="text-center pt-3 border-t">
              <a-button type="primary" @click="setData()"
                >Save & Close</a-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from '@/components/Layout/default.vue'
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
// import Sider from '@/components/Layout/Sider'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
  },
  data: function () {
    return {
      // breadcrumbs: [
      //   {
      //     name: null,
      //     breadcrumbName: 'Config Database',
      //   },
      // ],
      table_configs: [],
      additionnal_config: [{}],
      purchasetype: null,
      pointlogtable: null,
      page: 1,
      dataTablenamelist: {},
      partitionkey: '',
      rowkey: '',
      valuePerfixCode: '',
      valueTableName: '',
      valueTablePerfix: '',
      valueUserAddressTaxTableName: [],
      valuePerfixOfUserAccptanceLog: '',
      remarks: '',
      valueRemoveTableNames: [],
      additionnal_config_account: '',
      Edit: false,
      purchasetypename: '',
      pointlogtablename: '',
      checkData: false,
    }
  },
  computed: {},
  mounted() {
    this.$store.commit('setLoading', false)
    this.clicksearch()
  },
  watch: {},
  methods: {
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.getConfigTablenamelist().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getConfigTablenamelist() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetConfigTablenamelist(this.page).subscribe(
          data => {
            if (this.page == 1) {
              this.dataTablenamelist = []
            }
            if (data.result.length > 0) {
              this.checkData = true
              _.forEach(data.result, item => {
                this.dataTablenamelist.push(item)
                this.partitionkey = item.partition_key
                this.rowkey = item.row_key
                this.valuePerfixCode = item.table_prefix
                this.valueTableName = item.prefix_table_name_of_coupon_history
                  ? item.prefix_table_name_of_coupon_history
                  : '-'
                this.valueTablePerfix = item.table_prefix
                if (!item.point_log_table_names.table_names) {
                  this.pointlogtable = null
                } else if (
                  item.point_log_table_names.table_names[0] == 'PointLog'
                ) {
                  this.pointlogtable = 1
                } else if (
                  item.point_log_table_names.table_names[0] == 'PointMLog'
                ) {
                  this.pointlogtable = 2
                } else {
                  this.pointlogtable = null
                }
                if (item.purchase_type == 'Good') {
                  this.purchasetype = 1
                } else if (item.purchase_type == 'Food') {
                  this.purchasetype = 2
                } else {
                  this.purchasetype = null
                }
                this.valueUserAddressTaxTableName = item
                  .user_address_tax_table_names.table_names
                  ? item.user_address_tax_table_names.table_names
                  : []
                this.valuePerfixOfUserAccptanceLog = item
                  .prefix_of_user_acceptance_log.table_names
                  ? item.prefix_of_user_acceptance_log.table_names
                  : '-'
                this.remarks = '-'
                this.valueRemoveTableNames = item.remove_record_table_names
                  .table_names
                  ? item.remove_record_table_names.table_names
                  : '-'
                this.additionnal_config_account = item.addition_profile_configs
                  ? item.addition_profile_configs[0].account
                  : '-'
                this.table_configs = item.addition_profile_configs
                  ? item.addition_profile_configs[0].table_configs
                  : '-'
              })
            } else {
              this.Edit = true
            }
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            resolve(this.dataTablenamelist)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    setData() {
      this.Edit = false
      if (this.purchasetype == 1) {
        this.purchasetypename = 'Good'
      } else {
        this.purchasetypename = 'Food'
      }
      if (this.pointlogtable == 1) {
        this.pointlogtablename = 'PointLog'
      } else {
        this.pointlogtablename = 'PointMLog'
      }
      var configtablenamelist = {
        appid: Cache.getCache('app').id,
        prefix_table_name_of_coupon_history: this.partitionkey,
        table_prefix: this.rowkey,
        purchase_type: this.purchasetypename,
        point_log_table_names: {
          table_names: [this.pointlogtablename],
        },
        user_address_tax_table_names: {
          table_names: this.valueUserAddressTaxTableName,
        },
        prefix_of_user_acceptance_log: {
          table_names: [this.valuePerfixOfUserAccptanceLog],
        },
        remove_record_table_names: {
          table_names: this.valueRemoveTableNames,
        },
        addition_profile_configs: {
          account: this.additionnal_config_account,
          table_configs: this.table_configs,
        },
      }
      console.log(configtablenamelist)
      this.PostConfigTablenamelist(configtablenamelist)
    },
    PostConfigTablenamelist(configtablenamelist) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostConfigTablenamelist(configtablenamelist)
          .then(res => {
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getConfigTablenamelist
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    AddAdditionalConfigs() {
      var itemaccount = {}
      this.additionnal_config.push(itemaccount)
    },
    AddTableConfigs() {
      var itemtable = {}
      this.table_configs.push(itemtable)
    },
    EditConfig() {
      this.Edit = true
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.approvepoint {
  .table-scroll {
    background: #fff;
    overflow: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
  }
  .ant-radio-wrapper {
    color: #636363 !important;
    font-weight: 400 !important;
  }
}
.profileconfig {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.detail {
  overflow: auto;
  display: flex;
  min-height: calc(100vh - 330px);
  max-height: calc(100vh - 330px);
  .config {
    width: 52%;
  }
  .additionalconfig {
    border-left-width: 1px;
    width: 48%;
  }
}
@media only screen and (max-width: 1900px) {
  .detail {
    overflow: auto;
    display: block;
    min-height: calc(100vh - 330px);
    max-height: calc(100vh - 330px);
    .config {
      width: 100%;
    }
    .additionalconfig {
      border-left-width: inherit;
      width: 100%;
    }
  }
}
</style>