<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40 addtag"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Add Tag</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto text-gray">
            <div class="p-4 select-group">
              <div class="mb-2 addtag">Tag Group</div>
              <a-select
                v-model:value="tag_group"
                :options="options_tag_group"
                class="width-fill-full"
                @change="selectTag(tag_group)"
              ></a-select>
            </div>
            <div class="p-4">
              <div class="mb-2">Tag Type</div>
              <a-select
                v-model:value="tag_name"
                :options="options_tag_name"
                :disabled="options_tag_name.length == 0"
                mode="tags"
                class="width-fill-full"
              ></a-select>
            </div>
          </div>
          <div class="flex-none px-4 py-3 right">
            <a-button
              class="mr-3 btn-create"
              type="blue-2"
              @click="dismiss(true)"
            >
              Cancel
            </a-button>
            <a-button
              type="primary"
              class="btn-search btn-create"
              @click="saveTags()"
            >
              Save
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    title="Add Users to Tag"
    class="modal-usertags"
    :visible="save_modal"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="border-t-2 p-4">
      <div>
        <label class="text-save-modal">“Area”</label>
        was created as a new tag group
      </div>
      <div class="mt-3">
        <label class="text-save-modal">11 Tags</label>
        were added successfully
      </div>
      <div class="mt-3">
        <label class="text-save-modal">1 Tags</label>
        was failed to add
      </div>
    </div>
  </a-modal>
</template>
<script>
// helper
// import moment from 'moment'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import _ from 'lodash'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dataTags: {
      type: Array,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['getTags'],
  data: function () {
    return {
      select_tag: null,
      options_select_tag: [
        {
          value: 'Gender',
          label: 'Gender',
        },
        {
          value: 'Area',
          label: 'Area',
        },
        {
          value: 'Level',
          label: 'Level',
        },
        {
          value: 'Age',
          label: 'Age',
        },
        {
          value: 'Engagement',
          label: 'Engagement',
        },
        {
          value: 'Action',
          label: 'Action',
        },
      ],
      options_tag_name: [],
      save_modal: false,
      tag_new: null,
      tag_name: [],
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      options_tag_group: [],
      tag_group: '',
      dataTagsUser: [],
      allTypes: [],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getTagTypes()
        this.dataTagsUser = this.dataTags
        this.getTagtypeAll()
      }
    },
  },
  mounted() {},
  created: function () {},
  methods: {
    getTagTypes() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagTypes().subscribe(
          res => {
            this.dataTagtypes = res.result
            _.forEach(this.dataTagtypes, (item, index) => {
              this.options_tag_group[index] = {
                label: item.row_key,
                value: item.row_key,
              }
            })
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getTagtypeAll() {
      this.allTypes = this.dataTagsUser
        .map(x => {
          return x.user_tags.map(z => z.tag_type)
        })
        .flat()
    },
    selectTag(tag_group) {
      this.options_tag_name = []
      var itemtag = this.dataTagtypes.find(x => x.row_key == tag_group)
      _.forEach(itemtag.tag_types, item => {
        if (!this.allTypes.includes(item.row_key)) {
          this.options_tag_name.push({
            label: item.row_key,
            value: item.row_key,
          })
        }
      })
    },
    saveTags() {
      var datatagtypes = {
        appid: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        tag_group: this.tag_group,
        tag_types: this.tag_name,
      }
      this.PostTagTypes(datatagtypes)
    },
    PostTagTypes(datatagtypes) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTags(datatagtypes)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('getTags')
                this.tag_group = ''
                this.tag_name = []
                this.$emit('handleClose')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    dismiss: function () {
      this.tag_group = ''
      this.tag_name = []
      this.$emit('handleClose')
    },
    saveAddTag() {
      this.$emit('handleClose')
      this.save_modal = true
    },
    handleCancel() {
      this.save_modal = false
      this.select_tag = []
      this.tag_name = []
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
  .input-error {
    border-color: #ff5252 !important;
  }
  .text-error {
    color: #ff5252;
  }
}
.select-group {
  border-bottom: 2px solid #ecedf3;
}
.pointer {
  cursor: pointer;
}
.w-log {
  width: 30rem;
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.btn-create {
  width: 120px !important;
}
.addtag {
  .ant-select-multiple .ant-select-selection-item-remove {
    vertical-align: middle !important;
  }
  .anticon {
    vertical-align: inherit !important;
  }
}
.input-tag {
  width: 447px !important;
}
.right {
  text-align: -webkit-right;
}
.text-save-modal {
  font-weight: 600;
  color: #666666;
}
</style>
