export default {
  data() {
    return {
      currentRoute: this.$router.currentRoute,
      datapermissionbyapp: [],
    }
  },
  computed: {},
  created() {
    if (this.currentRoute.name == 'select_application') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'callcentermanagement') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'ticketmanagement') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'unauthorized') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'unauthorizedview') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'downloadcenter-callcenter') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'listrealtime') {
      this.$store.commit('permissionPage', true)   
    } else if (this.currentRoute.name == 'approvereceiptdetail') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'searchuser') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'joblist') {
      this.$store.commit('permissionPage', true)
    } else if (this.currentRoute.name == 'approvelist-callcenter') {
      this.$store.commit('permissionPage', true)
    } else {
      this.datapermissionbyapp = JSON.parse(
        localStorage.getItem('permissionbyapp'),
      )
      this.checkPermission()
    }
  },
  methods: {
    checkPermission() {
      console.log(this.currentRoute)
      this.$store.commit('permissionPage', false)
      if (
        this.currentRoute.name == 'list' &&
        this.datapermissionbyapp.includes('User-List-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'level' &&
        this.datapermissionbyapp.includes('User-Level-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'userflag' &&
        this.datapermissionbyapp.includes('User-Flag-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'user_tag' &&
        this.datapermissionbyapp.includes('User-Tag-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'searchserial' &&
        this.datapermissionbyapp.includes('Search-Serial-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'download' &&
        this.datapermissionbyapp.includes('Download-Center-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'approvelist' &&
        this.datapermissionbyapp.includes('Approve-List-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'configdatabase' &&
        this.datapermissionbyapp.includes('Config-Storage-TableNameList')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'import' &&
        this.datapermissionbyapp.includes('Import-List-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'helpchat' &&
        this.datapermissionbyapp.includes('Help-Chat-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'faq' &&
        this.datapermissionbyapp.includes('FAQ-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'managesubject' &&
        this.datapermissionbyapp.includes('Subject-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'chanel' &&
        this.datapermissionbyapp.includes('Channel-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (
        this.currentRoute.name == 'otplog' &&
        this.datapermissionbyapp.includes('OTP-Log-View')
      ) {
        this.$store.commit('permissionPage', true)
      }
      if (this.currentRoute.fullPath.includes('/list/user')) {
        this.$store.commit('permissionPage', true)
      }
    },
  },
}
