<template>
  <div class="flex flex-col text-left justify-left items-left left-timeline">
    <div class="flex relative justify-left mb-3 mt-2">
      <div class="profile-img">
        <img :src="profile.image_url" alt />
      </div>
    </div>
    <div class="d-flex justify-between">
      <div class="text-left font-bold text-black-1">
        Joined : {{ Joined ? Joined : '-' }}
      </div>
      <div v-if="Joined != '-'" class="text-gray-2 pr-3">
        {{ difference }}
      </div>
    </div>
    <div class="d-flex mb-3 justify-between">
      <div class="font-bold text-black-1">Last Seen : {{ LastSeen }}</div>
      <div class="text-gray-2 pr-3">{{ profile.user_device.os }}</div>
    </div>
    <div v-if="profile.coupon_info.error_coupon_count != 0" class="mb-2 w-100">
      <div class="mr-3">
        <div class="bg-coupon">
          <div class="p-2 text-left">
            <div class="d-flex">
              <warning-filled class="mr-2 pt-1" />
              <div>
                This user enter the wrong coupon code
                {{ profile.coupon_info.error_coupon_count }}
                <span>{{
                  profile.coupon_info.error_coupon_count > 1 ? 'times' : 'time'
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="profile.is_blocked_coupon" class="mb-2 w-100">
      <div class="mr-3">
        <div class="bg-block lock">
          <div class="p-2 text-left">
            <div class="d-flex">
              <warning-filled class="mr-2 pt-1 lock" />
              <h4 class="font-bold lock">User was block coupon</h4>
            </div>
            <div class="mt-2">
              User will be blocked until
              {{
                formatDate(
                  profile.coupon_info.ban_coupon_date,
                  'DD/MMM/YYYY - HH:mm',
                  this.isTimezone * -1,
                )
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="profile.is_blocked_user" class="mb-2 w-100">
      <div class="mr-3 bg-block lock">
        <div class="p-2 text-left">
          <div class="d-flex">
            <warning-filled class="mr-2 pt-1 lock" />
            <h4 class="font-bold lock">Blocked User</h4>
          </div>
          <div class="mt-2">This user was blocked from system.</div>
        </div>
      </div>
    </div>
    <div v-if="profile.is_blacklist" class="mb-2 w-100">
      <div class="mr-3 bg-block lock">
        <div class="p-2 text-left">
          <div class="d-flex">
            <warning-filled class="mr-2 pt-1 lock" />
            <h4 class="font-bold lock">Blacklisted User</h4>
          </div>
          <div class="mt-2">
            This user used the system for illegal purposes, such as distributing
            illegal content or conducting illegal business.
          </div>
        </div>
      </div>
    </div>
    <div v-if="profile.is_in_active_user" class="mb-2 w-100">
      <div class="mr-3 bg-block lock">
        <div class="p-2 text-left">
          <div class="d-flex">
            <warning-filled class="mr-2 pt-1 lock" />
            <h4 class="font-bold lock">Deactivate user</h4>
          </div>
          <div class="mt-2">
            This user was deactivate
            {{
              formatDate(
                profile.user_info?.in_active_date,
                'DD/MMM/YYYY - HH:mm',
                this.isTimezone * -1,
              )
            }}
          </div>
          <div>
            Reason :
            {{ profile.user_info.in_active_reason }}
          </div>
        </div>
      </div>
    </div>
    <div :class="checkPermissionBlock() ? 'border-bt-ac' : 'border-b-ac'">
      <div v-if="checkPermissionBlock()" class="py-3">
        <div class="mr-3">
          <div class="d-flex justify-content-between">
            <div class="text-left mb-2 font-bold">Block & Blacklist</div>
            <a v-if="showAll" @click="showUnblock()"><caret-up-outlined /></a>
            <a v-if="notShow" @click="showAllList()"><caret-down-outlined /></a>
          </div>
          <div v-if="checkPermissionBlock()">
            <div v-if="showAll">
              <div
                v-if="
                  checkblockcoupon &&
                  datapermissionbyapp.includes(
                    'User-List-Account-Unblock-Coupon',
                  )
                "
                class="py-2"
              >
                <div class="d-flex justify-content-between">
                  <div class="text-black-1 font-bold text-left">
                    Block Coupon
                  </div>
                  <a-switch
                    v-model:checked="checkblockcoupon"
                    :disabled="!checkblockcoupon"
                    checked-children="Blocking"
                    un-checked-children="OFF"
                    @change="toggleunblockcoupon()"
                    >Block Coupon</a-switch
                  >
                </div>
              </div>
              <div
                v-if="
                  datapermissionbyapp.includes(
                    'User-List-Account-Block-Unblock-User',
                  )
                "
                class="py-2"
              >
                <div class="d-flex justify-content-between">
                  <div class="text-black-1 font-bold text-left">Block User</div>
                  <a-switch
                    v-model:checked="checkblock"
                    checked-children="Blocking"
                    un-checked-children="OFF"
                    @change="toggleunblock(checkblock)"
                    >Block User</a-switch
                  >
                </div>
              </div>
              <div
                v-if="
                  datapermissionbyapp.includes(
                    'User-List-Account-Blacklist-Unblacklist-User',
                  )
                "
                class="py-2"
              >
                <div class="d-flex justify-content-between">
                  <div class="text-black-1 font-bold text-left">
                    Blacklist User (Fraud User)
                  </div>
                  <a-switch
                    v-model:checked="checkblacklist"
                    checked-children="Banning"
                    un-checked-children="OFF"
                    :disabled="profile.is_whitelist"
                    @change="toggleunblacklist(checkblacklist)"
                    >Blacklist</a-switch
                  >
                </div>
              </div>
              <!-- <div v-if="checkDataunblock > 0" class="text-left">
            Currently Blocked
          </div>
          <div
            v-for="(content, index) of filterPermissionunblock(dataunblock)"
            :key="`index-${index}`"
            class="p-2 mt-2 bg-block"
          >
            <div class="d-flex justify-content-between">
              <div v-if="content == 'is_blocked_user'">
                <div class="lock font-bold text-left">User</div>
              </div>
              <div v-if="content == 'is_blacklist'">
                <div class="lock font-bold text-left">Blacklist</div>
              </div>
              <div v-if="content == 'is_blocked_coupon'">
                <div class="lock font-bold text-left">Coupon</div>
                <div class="lock text-left">
                  Ban : {{ profile.coupon_info.ban_count }} , Error :
                  {{ profile.coupon_info.error_coupon_count }}
                </div>
              </div>
              <a-button
                @click="toggleunblockall(content, false)"
                type="danger"
                :disabled="
                  (content == 'is_blocked_coupon' &&
                    !datapermissionbyapp.includes(
                      'User-List-Account-Unblock-Coupon',
                    )) ||
                  (content == 'is_blocked_user' &&
                    !datapermissionbyapp.includes(
                      'User-List-Account-Block-Unblock-User',
                    )) ||
                  (content == 'is_blacklist' &&
                    !datapermissionbyapp.includes(
                      'User-List-Account-Blacklist-Unblacklist-User',
                    ))
                "
                ><unlock-outlined />Unblock</a-button
              >
            </div>
          </div> -->
            </div>
          </div>
          <!-- <div v-if="showAll">
          <div v-if="checkDatablock > 0" class="text-left mt-3">
            Block Lists
          </div>
          <div
            v-for="(content, index) of filterPermissionblock(datablock)"
            :key="`index-${index}`"
            class="p-2 mt-2 bg-unblock"
          >
            <div class="d-flex justify-content-between">
              <div v-if="content == 'is_blocked_user'">
                <div class="font-bold text-left">User</div>
              </div>
              <div v-if="content == 'is_blacklist'">
                <div class="font-bold text-left">Blacklist</div>
              </div>
              <div v-if="content == 'is_blocked_coupon'">
                <div class="font-bold text-left">Coupon</div>
              </div>
              <a-button
                @click="toggleblockall(content, true)"
                :disabled="
                  (content == 'is_blocked_coupon' &&
                    !datapermissionbyapp.includes(
                      'User-List-Account-Unblock-Coupon',
                    )) ||
                  (content == 'is_blocked_user' &&
                    !datapermissionbyapp.includes(
                      'User-List-Account-Block-Unblock-User',
                    )) ||
                  (content == 'is_blacklist' &&
                    !datapermissionbyapp.includes(
                      'User-List-Account-Blacklist-Unblacklist-User',
                    ))
                "
                danger
                ><lock-outlined />Block</a-button
              >
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>
    <a-row class="border-b-ac">
      <a-col
        v-if="permissionhelpchat"
        :span="
          permissionadjustpoint &&
          profile.user_info?.membership_user_name &&
          datapermissionbyapp.includes('User-List-Account-Reset-Password')
            ? 8
            : permissionadjustpoint ||
              (profile.user_info?.membership_user_name &&
                datapermissionbyapp.includes(
                  'User-List-Account-Reset-Password',
                ))
            ? 12
            : 24
        "
        class="px-2 my-2"
      >
        <a class="text-webkit-center">
          <router-link
            :to="{
              name: 'helpchat',
              params: {
                id: this.$route.params.id,
              },
            }"
          >
            <img class="pt-2" src="@/assets/icons/help-chat.svg" width="16" />
            <div class="title-icon">Help Chat</div>
          </router-link>
        </a>
      </a-col>
      <a-col
        v-if="permissionadjustpoint"
        :span="
          permissionhelpchat &&
          profile.user_info?.membership_user_name &&
          datapermissionbyapp.includes('User-List-Account-Reset-Password')
            ? 8
            : permissionhelpchat ||
              (profile.user_info?.membership_user_name &&
                datapermissionbyapp.includes(
                  'User-List-Account-Reset-Password',
                ))
            ? 12
            : 24
        "
        :class="[
          !profile.user_info?.membership_user_name && !permissionhelpchat
            ? 'px-2 my-2'
            : resetpermission &&
              profile.user_info?.membership_user_name &&
              !permissionhelpchat
            ? 'px-2 my-2'
            : 'px-2 my-2 border-l-2',
        ]"
      >
        <a class="text-webkit-center" @click="showModalAdjustpoint()">
          <img class="pt-2" src="@/assets/icons/adjust-points.svg" width="16" />
          <div class="title-icon">Adjust Points</div>
        </a>
      </a-col>
      <a-col
        :span="
          !permissionadjustpoint && !permissionhelpchat
            ? 24
            : permissionadjustpoint && permissionhelpchat
            ? 8
            : 12
        "
        v-if="resetpermission && profile.user_info?.membership_user_name"
        :class="
          !permissionadjustpoint && !permissionhelpchat
            ? 'px-2 my-2'
            : 'border-l-2 px-2 my-2'
        "
      >
        <a @click="showModal()" class="text-webkit-center">
          <img
            class="pt-2"
            src="@/assets/icons/reset-password.svg"
            width="16"
          />
          <div class="title-icon">Reset Password</div>
        </a>
      </a-col>
    </a-row>
    <a-row class="border-b-ac">
      <a-col
        v-if="
          datapermissionbyapp.includes('User-List-Account-Transaction-Wallet')
        "
        :span="
          profile.is_unlink_line_account &&
          datapermissionbyapp.includes('User-List-Account-Inactive-User') &&
          !profile.is_in_active_user
            ? 8
            : profile.is_unlink_line_account ||
              (datapermissionbyapp.includes(
                'User-List-Account-Inactive-User',
              ) &&
                !profile.is_in_active_user)
            ? 12
            : 24
        "
        class="my-2"
      >
        <a class="text-webkit-center" @click="toggleCreateWallet()">
          <img class="pt-2" src="@/assets/icons/wallet.svg" width="16" />
          <div class="title-icon">Adjust Wallet</div>
        </a>
      </a-col>
      <a-col
        v-if="
          datapermissionbyapp.includes(
            'User-List-Account-Unlink-Line-Account',
          ) && profile.is_unlink_line_account
        "
        :span="
          datapermissionbyapp.includes(
            'User-List-Account-Transaction-Wallet',
          ) &&
          datapermissionbyapp.includes('User-List-Account-Inactive-User') &&
          !profile.is_in_active_user
            ? 8
            : datapermissionbyapp.includes(
                'User-List-Account-Transaction-Wallet',
              ) ||
              (datapermissionbyapp.includes(
                'User-List-Account-Inactive-User',
              ) &&
                !profile.is_in_active_user)
            ? 12
            : 24
        "
        :class="
          datapermissionbyapp.includes('User-List-Account-Transaction-Wallet')
            ? 'border-l-2 my-2'
            : 'my-2'
        "
      >
        <a class="text-webkit-center" @click="showModalUnlink()">
          <img class="pt-2" src="@/assets/icons/line.svg" width="16" />
          <div class="title-icon">Unlink LINE Account</div>
        </a>
      </a-col>
      <a-col
        v-if="
          datapermissionbyapp.includes('User-List-Account-Inactive-User') &&
          !profile.is_in_active_user
        "
        :span="
          datapermissionbyapp.includes(
            'User-List-Account-Transaction-Wallet',
          ) &&
          datapermissionbyapp.includes(
            'User-List-Account-Unlink-Line-Account',
          ) &&
          profile.is_unlink_line_account
            ? 8
            : datapermissionbyapp.includes(
                'User-List-Account-Transaction-Wallet',
              ) ||
              (datapermissionbyapp.includes(
                'User-List-Account-Unlink-Line-Account',
              ) &&
                profile.is_unlink_line_account)
            ? 12
            : 24
        "
        :class="
          datapermissionbyapp.includes(
            'User-List-Account-Transaction-Wallet',
          ) ||
          (datapermissionbyapp.includes(
            'User-List-Account-Unlink-Line-Account',
          ) &&
            profile.is_unlink_line_account)
            ? 'border-l-2 my-2'
            : 'my-2'
        "
      >
        <a class="text-webkit-center" @click="showModalInactive()">
          <img
            class="pt-2 ico-inactive"
            src="@/assets/icons/user-minus.svg"
            width="16"
          />
          <div class="title-icon">Deactivate User</div>
        </a>
      </a-col>
    </a-row>
    <a-row class="border-b-ac">
      <a-col
        v-if="
          !profile.is_whitelist &&
          datapermissionbyapp.includes('User-List-Account-Whitelist-User')
        "
        :span="
          datapermissionbyapp.includes('User-List-Account-Transfer-Points')
            ? 12
            : 24
        "
        class="my-2"
      >
        <a class="text-webkit-center" @click="toggleWhitelist()">
          <img class="pt-2" src="@/assets/icons/starfour.svg" width="16" />
          <div class="title-icon">Whitelist User</div>
        </a>
      </a-col>
      <a-col
        v-if="datapermissionbyapp.includes('User-List-Account-Transfer-Points')"
        :span="
          !profile.is_whitelist &&
          datapermissionbyapp.includes('User-List-Account-Whitelist-User')
            ? 12
            : 24
        "
        :class="
          !profile.is_whitelist &&
          datapermissionbyapp.includes('User-List-Account-Whitelist-User')
            ? 'border-l-2 my-2'
            : 'my-2'
        "
      >
        <a
          class="text-webkit-center"
          @click="modalTranferAction(true, 'modalTransferPoint', profile)"
        >
          <img
            class="pt-2"
            src="@/assets/icons/transfer-point.svg"
            width="16"
          />
          <div class="title-icon">Transfer Points</div>
        </a>
      </a-col>
    </a-row>
  </div>
  <DisplayFieldsTicket
    :value="showModalTicket"
    @handleClose="showModalTicket = false"
  >
  </DisplayFieldsTicket>
  <a-modal
    title="Reset Password"
    class="modal-reset"
    wrap-class-name="modal-reset"
    :visible="visible"
    @cancel="handleCancel"
    @ok="resetpassword()"
    okText="Save"
    :centered="true"
    :closable="false"
  >
    <div class="font-modal-delete">
      <div class="mb-2">New Password</div>
      <a-input-password v-model:value="newpassword" />
      <div class="mb-2 mt-3">Confirm Password</div>
      <a-input-password v-model:value="confirmpassword" />
      <div v-show="textpassword" class="lock">{{ textpassword }}</div>
    </div>
  </a-modal>
  <a-modal
    class="modal-reset"
    wrap-class-name="modal-deactivate"
    :visible="modalInactive"
    okText="Deactivate"
    :centered="true"
    :closable="false"
    :ok-button-props="{ disabled: !reason_deactive }"
  >
    <div class="font-modal-delete">
      <question-circle-outlined class="icon" />
      <div class="title mb-2">Are you sure to deactivate this user?</div>
      <div class="mb-2">Accounts will be promptly deactivate.</div>
      <div class="mb-1">
        <label class="false mr-1 ml-re">*</label>Please enter reason to
        deactivate user :
      </div>
      <a-textarea
        v-model:value="reason_deactive"
        :maxlength="200"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
      <div class="fs-12 mt-2">
        Remark: This account cannot able to activate again.
      </div>
    </div>
    <template #footer
      ><a-button @click="handleCancel()" type="default">Cancel</a-button>
      <a-button
        @click="DeactivateUser()"
        type="danger"
        :disabled="!reason_deactive"
        >Deactivate</a-button
      >
    </template>
  </a-modal>
  <a-modal
    title="Adjust Points"
    class="modal-reset"
    :visible="modaladjustpoint"
    @cancel="handleCancel"
    @ok="PostAdjustpoint()"
    okText="Save"
    :centered="true"
    :closable="false"
  >
    <div class="text-adjustpoint">
      <div class="mb-1"><label class="false mr-1 ml-re">*</label>Points</div>
      <a-input-number
        :class="[isErrorPoint ? 'input-error mb-2 w-100' : 'mb-2 w-100']"
        v-model:value="point"
        @change="onChangePoint(point)"
        :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
        :maxlength="8"
        :min="-999999"
        :max="999999"
      />
      <div class="text-error" v-show="isErrorPoint">
        {{ textrequirepoint }}
      </div>
      <div class="mb-1"><label class="false mr-1 ml-re">*</label>Reason</div>
      <a-select
        :class="[isErrorReason ? 'input-error w-max' : 'w-max mb-2']"
        v-model:value="reason"
        placeholder="Select Reason"
        :options="
          datapermissionbyapp.includes('User-Management-English-Version')
            ? option_reason_vn
            : option_reason
        "
        @change="onChangeReason"
      />
      <div class="text-error" v-show="isErrorReason">
        {{ textrequirereason }}
      </div>
      <a-textarea
        class="mt-2"
        v-model:value="comments"
        placeholder="Additional Comments (Optional)"
        :maxlength="200"
        :auto-size="{ minRows: 4, maxRows: 4 }"
      />
      <a-checkbox class="my-3 text-adjustpoint" v-model:checked="checknoti"
        >Send Notification
      </a-checkbox>
      <a-textarea
        v-model:value="message"
        :disabled="!checknoti"
        placeholder="Notification Message"
        :maxlength="200"
        :auto-size="{ minRows: 4, maxRows: 4 }"
      />
    </div>
  </a-modal>
  <a-modal
    title="Adjust Transaction Wallet"
    wrap-class-name="modal-wallet"
    :visible="showModalWallet"
    @cancel="handleCancel"
    @ok="adjustWallet()"
    okText="Save"
    :width="640"
    :centered="true"
    :closable="false"
    :ok-button-props="{ disabled: checkrequire }"
  >
    <div style="display: -webkit-inline-box">
      <div class="mr-3">Type :</div>
      <a-radio-group v-model:value="typewallet" @change="onChange">
        <a-radio :value="1"> Wallet </a-radio>
        <a-radio :value="2"> True Money Wallet </a-radio>
      </a-radio-group>
    </div>
    <div class="d-flex mt-4">
      <div>
        <div><label class="false mr-1 ml-re">*</label>Amount :</div>
        <a-input-number
          v-model:value="amount"
          :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
          :parser="value => value.substring(0, 8).replace(/[^0-9]/g, '')"
          addon-after="THB"
        />
      </div>
      <div v-if="typewallet == 1" class="ml-3">
        <div><label class="false mr-1 ml-re">*</label>Payment ID :</div>
        <a-input v-model:value="payment_id" />
      </div>
      <div v-if="typewallet == 2" class="ml-3">
        <div><label class="false mr-1 ml-re">*</label>Transaction ID :</div>
        <a-input v-model:value="transaction_id" />
      </div>
      <div v-if="typewallet == 2" class="ml-3">
        <div><label class="false mr-1 ml-re">*</label>Campaign ID :</div>
        <a-input v-model:value="campaign_id" />
      </div>
      <div v-if="typewallet == 1" class="ml-3">
        <div><label class="false mr-1 ml-re">*</label>Card ID :</div>
        <a-select
          class="option-card"
          v-model:value="card_id"
          :options="options_card_id"
          show-search
          allowClear
        />
      </div>
    </div>
    <div v-if="typewallet == 2" class="mt-3">
      <div><label class="false mr-1 ml-re">*</label>Customer ID</div>
      <a-input style="width: 200px" v-model:value="customer_id" allow-clear />
    </div>
    <div class="mt-3">
      <div><label class="false mr-1 ml-re">*</label>Details :</div>
      <a-textarea
        v-model:value="detail"
        :auto-size="{ minRows: 2, maxRows: 2 }"
      />
    </div>
    <div class="mt-3">
      <div><label class="false mr-1 ml-re">*</label>Upload :</div>
      <a-upload
        v-model:file-list="fileList"
        :beforeUpload="[
          function () {
            false
          },
        ]"
        :previewIcon="false"
        list-type="picture-card"
      >
        <div v-if="fileList.length < 5">
          <plus-outlined />
          <div>Upload</div>
        </div>
      </a-upload>
      <div class="text-gray">
        Recommended resolution is 640*640 with file size less than 2MB.
      </div>
    </div>
  </a-modal>
  <a-modal
    class="modal-reset"
    wrap-class-name="modal-deactivate"
    :visible="modalBlock"
    okText="Deactive"
    :centered="true"
    :closable="false"
  >
    <div class="font-modal-delete">
      <question-circle-outlined class="icon" />
      <div v-if="checkStatusBlock" class="title mb-2">
        Are you sure to Block this user?
      </div>
      <div v-else class="title mb-2">Are you sure to Unblock this user?</div>
      <div v-if="checkStatusBlock" class="mb-2">
        This user will not login or redeem from people on the block list.
      </div>
      <div v-else class="mb-2">
        This user will be able to login or redeem on the application again.
      </div>
      <div v-if="checkStatusBlock" class="mb-1">
        <label class="false mr-1 ml-re">*</label>Please enter reason to block
        user :
      </div>
      <div v-else class="mb-1 mt-4">
        <label class="false mr-1 ml-re">*</label>Please enter reason to unblock
        this user :
      </div>
      <a-textarea
        v-model:value="remarkBlock"
        :maxlength="200"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
    </div>
    <template #footer
      ><a-button @click="handleCancel()" type="default">Cancel</a-button>
      <a-button
        @click="PostUserUnblock()"
        :type="checkStatusBlock ? 'danger' : 'primary'"
        :disabled="!remarkBlock"
      >
        {{
          checkStatusBlock ? 'Block this user' : 'Unblock this user'
        }}</a-button
      >
    </template>
  </a-modal>
  <a-modal
    class="modal-reset"
    wrap-class-name="modal-deactivate"
    :visible="modalBlacklist"
    okText="Deactive"
    :centered="true"
    :closable="false"
  >
    <div class="font-modal-delete">
      <question-circle-outlined class="icon" />
      <div v-if="checkStatusBlacklist" class="title mb-2">
        Are you sure to Blacklist (Fraud) this user?
      </div>
      <div v-else class="title mb-2">
        Are you sure to UnBlacklist (Remove Fraud) this user?
      </div>
      <div v-if="checkStatusBlacklist" class="mb-2">
        This user is about associated with fraud will be blacklist.
      </div>
      <div v-else class="mb-2">
        This user will be able to login or redeem on the application again.
      </div>
      <!-- <div v-if="false">
        <div class="bg-why">
          <div class="d-flex gap-2">
            <img :src="imgWhy" />
            <div class="title">Why user was block</div>
          </div>
          <div>“This is a message when user was block for admin or Reason”</div>
        </div>
      </div> -->
      <div v-if="checkStatusBlacklist" class="mb-1">
        <label class="false mr-1 ml-re">*</label>Please enter reason to
        blacklist user :
      </div>
      <div v-else class="mb-1 mt-4">
        <label class="false mr-1 ml-re">*</label> Please enter reason to
        unblacklist this user :
      </div>
      <a-textarea
        v-model:value="remarkBlacklist"
        :maxlength="200"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
    </div>
    <template #footer
      ><a-button @click="handleCancel()" type="default">Cancel</a-button>
      <a-button
        @click="PostUnBlacklist()"
        :type="checkStatusBlacklist ? 'danger' : 'primary'"
        :disabled="!remarkBlacklist"
      >
        {{
          checkStatusBlacklist ? 'Blacklist this user' : 'Unblacklist this user'
        }}</a-button
      >
    </template>
  </a-modal>
  <a-modal
    class="modal-reset"
    wrap-class-name="modal-deactivate"
    :visible="modalWhitelist"
    okText="Deactive"
    :centered="true"
    :closable="false"
  >
    <div class="font-modal-delete">
      <question-circle-outlined class="icon" />
      <div class="title mb-2">Are You Sure To Whitelist This User?</div>
      <div class="mb-4"></div>
      <div class="mb-1">
        <label class="false mr-1 ml-re">*</label>Please enter reason to
        whitelist user :
      </div>
      <a-textarea
        v-model:value="remarkWhitelist"
        :maxlength="200"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
    </div>
    <template #footer
      ><a-button @click="handleCancel()" type="default">Cancel</a-button>
      <a-button
        @click="PostWhiteList()"
        type="primary"
        :disabled="!remarkWhitelist"
      >
        Whitelist this user</a-button
      >
    </template>
  </a-modal>
  <TranferPointModal ref="modaltranferpoint" />
</template>

<script>
// import VTag from "@/components/Tag/Tag.vue";
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import moment from 'moment'
import {
  UnlockOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  WarningFilled,
} from '@ant-design/icons-vue'
var Cache = new CacheHelper(ConfigManager.getCacheType())
var cacheHelper = new CacheHelper(ConfigManager.getCacheType())
import { h } from 'vue'
import DisplayFieldsTicket from '@/module/User/component/DisplayFieldsCreateTicket.vue'
import _ from 'lodash'
import { config } from '@/config/bzbsconfig.js'
import TranferPointModal from '@/module/User/UserDetail/component/UserProfile/TranferPoint.vue'

export default {
  name: 'UserProfileLeftTimeline',
  components: {
    DisplayFieldsTicket,
    CaretDownOutlined,
    CaretUpOutlined,
    WarningFilled,
    TranferPointModal,
  },
  props: {
    profile: {
      type: Object,
      default: null,
    },
    dataapp: {
      type: Array,
      default: () => [],
    },
    datauserblock: {
      type: Boolean,
    },
    datablacklist: {
      type: Boolean,
    },
    datacouponblock: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      ticket: true,
      modalTransferPoint: false,
      pagePoint: 1,
      isVietnam: config.businessRule.webFor.VN,
      unlink: Cache.getCache('app').has_unlink,
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      test: require('@/assets/background/bg.png'),
      reason: null,
      message: '',
      comments: '',
      point: null,
      showModalWallet: false,
      fileList: [],
      typewallet: 1,
      detail: '',
      amount: null,
      payment_id: '',
      transaction_id: '',
      campaign_id: '',
      card_id: '',
      customer_id: '',
      option_reason: [
        {
          label: 'โอนคะแนนบัญชีเก่าไปบัญชีใหม่',
          value: 'โอนคะแนนบัญชีเก่าไปบัญชีใหม่',
        },
        {
          label: 'เพิ่มคะแนนจากการสั่งซื้อสินค้า',
          value: 'เพิ่มคะแนนจากการสั่งซื้อสินค้า',
        },
        {
          label: 'เพิ่มคะแนนจากไม่มีสินค้าทดแทน',
          value: 'เพิ่มคะแนนจากไม่มีสินค้าทดแทน',
        },
        {
          label: 'เพิ่มคะแนนจากกิจกรรมพิเศษ',
          value: 'เพิ่มคะแนนจากกิจกรรมพิเศษ',
        },
        {
          label: 'เพิ่มคะแนนจากการยกเลิกรายการ',
          value: 'เพิ่มคะแนนจากการยกเลิกรายการ',
        },
        {
          label: 'เพิ่มคะแนนจากการได้คะแนนผิด',
          value: 'เพิ่มคะแนนจากการได้คะแนนผิด',
        },
        {
          label: 'เพิ่มคะแนนจากการใช้คะแนนเป็นส่วนลด',
          value: 'เพิ่มคะแนนจากการใช้คะแนนเป็นส่วนลด',
        },
        {
          label: 'ลบคะแนนจากการได้รับสินค้าเกิน',
          value: 'ลบคะแนนจากการได้รับสินค้าเกิน',
        },
        {
          label: 'ลบคะแนนจากการจบกิจกรรมพิเศษ',
          value: 'ลบคะแนนจากการจบกิจกรรมพิเศษ',
        },
        {
          label: 'ลบคะแนนจากการยกเลิกรายการ',
          value: 'ลบคะแนนจากการยกเลิกรายการ',
        },
        {
          label: 'ลบคะแนนจากการได้คะแนนผิด',
          value: 'ลบคะแนนจากการได้คะแนนผิด',
        },
        {
          label: 'ลบคะแนนจากการใช้คะแนนเป็นส่วนลด',
          value: 'ลบคะแนนจากการใช้คะแนนเป็นส่วนลด',
        },
        {
          label: 'อื่นๆ',
          value: 'อื่นๆ',
        },
      ],
      option_reason_vn: [
        {
          label: 'Transfer points from old account to new account.',
          value: 'Transfer points from old account to new account.',
        },
        {
          label: 'Add points from ordering products.',
          value: 'Add points from ordering products.',
        },
        {
          label: 'Add points from not having substitute products.',
          value: 'Add points from not having substitute products.',
        },
        {
          label: 'Add points from special activities.',
          value: 'Add points from special activities.',
        },
        {
          label: 'Add points from canceling order.',
          value: 'Add points from canceling order.',
        },
        {
          label: 'Add points from getting incorrect points.',
          value: 'Add points from getting incorrect points.',
        },
        {
          label: 'Add points by using points as discounts.',
          value: 'Add points by using points as discounts.',
        },
        {
          label:
            'Delete points from receiving more than the amount of products.',
          value:
            'Delete points from receiving more than the amount of products.',
        },
        {
          label: 'Delete points from completed special events.',
          value: 'Delete points from completed special events.',
        },
        {
          label: 'Delete points from canceling an item.',
          value: 'Delete points from canceling an item.',
        },
        {
          label: 'Delete points from incorrect points.',
          value: 'Delete points from incorrect points.',
        },
        {
          label: 'Delete points from using points as a discount.',
          value: 'Delete points from using points as a discount.',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
      optionssearch: 'help_ID',
      options_search: [
        {
          label: 'Help ID',
          value: 'help_ID',
        },
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'Name',
          value: 'name',
        },
      ],
      statusdropdown: 'all',
      status_dropdown: [
        {
          label: 'All Status',
          value: 'all',
        },
        {
          label: 'Read',
          value: 'read',
        },
        {
          label: 'Unread',
          value: 'unread',
        },
        {
          label: 'Hide',
          value: 'hide',
        },
      ],
      channel: null,
      checknoti: false,
      disablechannel: true,
      userblock: null,
      blacklist: null,
      isErrorPoint: false,
      isErrorReason: false,
      textrequirereason: '',
      textrequirepoint: '',
      resetpermission: true,
      permissionblacklist: true,
      permissionblockuser: true,
      permissionunlinkline: true,
      permissionadjustpoint: true,
      permissionmanagepoint: true,
      permissionhelpchat: true,
      visible: false,
      visible2: false,
      visible3: false,
      modalunlink: false,
      modaladjustpoint: false,
      modalhelpchat: false,
      tags: [],
      textpassword: '',
      newpassword: '',
      confirmpassword: '',
      formatdate: 'DD/MMM/YYYY - HH:mm',
      Joined: null,
      difference: null,
      LastSeen: null,
      logo: ConfigManager.getAppLogo(),
      profileImg:
        ConfigManager.getbzbUrl() +
        '/api/profile/' +
        this.$route.params.id +
        '/picture?type=large',
      showDisplayFieldsApproveDetail: false,
      imgline: require('@/assets/icons/line.svg'),
      imgWhy: require('@/assets/icons/icon-why.svg'),
      showModalTicket: false,
      modalBlock: false,
      showBlock: true,
      historylogsodata: [],
      historylogs: [],
      historylogsblacklist: [],
      historylogscoupon: [],
      logs: false,
      logs2: false,
      logs3: false,
      formatdatelog: 'DD MMM YYYY',
      formattime: 'HH:mm',
      datacoupon: true,
      dataprofile: this.profile,
      datablock: [],
      dataunblock: [],
      showAll: false,
      checkDataunblock: null,
      checkDatablock: null,
      adjustwallet: {},
      adjusttruewallet: {},
      checkblacklist: null,
      checkblock: null,
      checkblockcoupon: null,
      notShow: true,
      permissionBlock: [
        'User-List-Account-Blacklist-Unblacklist-User',
        'User-List-Account-Block-Unblock-User',
        'User-List-Account-Unblock-Coupon',
      ],
      modalInactive: false,
      reason_deactive: '',
      options_card_id: [],
      modalBlacklist: false,
      remarkBlacklist: '',
      checkStatusBlacklist: null,
      checkStatusBlock: null,
      modalWhitelist: false,
      remarkWhitelist: '',
      remarkBlock: '',
    }
  },
  emits: ['changeTab'],
  watch: {
    profile(val) {
      this.reProfile(val)
      console.log(val)
    },
    $route(to) {
      if (to.query.tab == 'account') {
        this.init()
      }
    },
  },
  computed: {
    checkrequire() {
      if (
        this.typewallet == 1 &&
        this.amount &&
        this.payment_id &&
        this.card_id &&
        this.detail &&
        this.fileList.length
      ) {
        return false
      } else if (
        this.typewallet == 2 &&
        this.amount &&
        this.transaction_id &&
        this.campaign_id &&
        this.customer_id &&
        this.detail &&
        this.fileList.length
      ) {
        return false
      } else {
        return true
      }
    },
  },
  mounted() {
    this.reProfile(this.profile)
    this.dataunblock = Object.keys(this.profile).filter(
      x =>
        this.profile[x] &&
        x != 'is_unlink_line_account' &&
        x != 'image_url' &&
        x != 'user_info' &&
        x != 'user_device' &&
        x != 'delivery_address_infos' &&
        x != 'coupon_info' &&
        x != 'tax_address_infos',
    )
    this.datablock = Object.keys(this.profile).filter(
      x =>
        !this.profile[x] &&
        x != 'is_unlink_line_account' &&
        x != 'image_url' &&
        x != 'user_info' &&
        x != 'user_device' &&
        x != 'delivery_address_infos' &&
        x != 'is_blocked_coupon' &&
        x != 'coupon_info' &&
        x != 'tax_address_infos',
    )
    if (this.$route.query.tab == 'account') {
      this.init()
    }
  },
  created: function () {
    // this.gettag()
  },
  methods: {
    modalTranferAction(state, type, profile) {
      this.$refs.modaltranferpoint.modalTranferAction(state, type, profile)
    },
    nextPointPage(page) {
      this.pagePoint = page
    },
    showModalInactive() {
      this.modalInactive = true
    },
    checkPermissionBlock() {
      if (
        this.permissionBlock.some(x => this.datapermissionbyapp.includes(x))
      ) {
        return true
      } else {
        return false
      }
    },
    filterPermissionunblock(dataunblock) {
      this.checkDataunblock = dataunblock.length
      return dataunblock
    },
    filterPermissionblock(datablock) {
      this.checkDatablock = datablock.length
      return datablock
    },
    init() {
      this.checkblock = this.datauserblock
      this.checkblacklist = this.datablacklist
      this.checkblockcoupon = this.datacouponblock
      if (
        this.permissionBlock.some(x => this.datapermissionbyapp.includes(x))
      ) {
        this.showAll = true
        this.notShow = false
      }
      this.setperrmission()
      this.setperrmissionunlinkline()
      this.setperrmissionadjustpoint()
      this.setpermissionmanagepoint()
      this.setperrmissionhelpchat()
      // this.gethistorylogs()
    },
    handleInput(event) {
      this.point = event.target.value
        .substring(0, 7)
        .replace(/[^0-9]/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    togglelogs(type) {
      if (type == 1) {
        this.logs = !this.logs
        console.log('this.logs')
      } else if (type == 2) {
        this.logs2 = !this.logs2
      } else {
        this.logs3 = !this.logs3
      }
    },
    gethistorylogs() {
      var type = 'account'
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetLogHistoryLog(this.$route.params.id, type).subscribe(
          res => {
            this.historylogsodata = res.result
            _.forEach(res.result, item => {
              item.new_data = JSON.parse(item.new_data)
              item.original_data = JSON.parse(item.original_data)
            })
            this.historylogs = this.historylogsodata.filter(
              x =>
                x.account_state_type == 'Blocked' ||
                x.account_state_type == 'Unblock',
            )
            this.historylogsblacklist = this.historylogsodata.filter(
              x =>
                x.account_state_type == 'Backlist' ||
                x.account_state_type == 'Unbacklist',
            )
            this.historylogscoupon = this.historylogsodata.filter(
              x => x.account_state_type == 'None',
            )
            this.$store.commit('setLoading', false)
            resolve(this.historylogs)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getdate(created_date) {
      return moment(created_date).format(this.formatdatelog)
    },
    gettime(created_date) {
      return moment(created_date).format(this.formattime)
    },
    handleScrollModal(event) {
      if (!this.nomoremodal && !this.overlaytablemodal) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytablemodal = true
          this.modalPage++
          // this.getRealtime().then(() => {
          //   this.overlaytablemodal = false
          // })
        }
      }
    },
    reProfile(val) {
      this.Joined =
        val.user_info?.join_date == null
          ? val.user_info?.join_date
          : moment(val.user_info?.join_date)
              .add(this.isTimezone * -1, 'hours')
              .format(this.formatdate)
      this.LastSeen = moment(val.user_info?.timestamp).format(this.formatdate)

      if (val.user_info?.join_date != null) {
        var today = moment().startOf('day')
        var joined = moment(val.user_info?.join_date).startOf('day')
        var years = today.diff(joined, 'year')
        joined.add(years, 'years')
        var months = today.diff(joined, 'months')
        joined.add(months, 'months')
        var days = today.diff(joined, 'days')
        this.difference = years + 'Y ' + months + 'M ' + days + 'D'
      }
    },
    toggleDisplayFieldsUpdateLevel() {
      this.showDisplayFieldsApproveDetail = !this.showDisplayFieldsApproveDetail
    },
    setperrmission() {
      if (
        this.dataapp.features.every(
          x => x.name != 'User-List-Account-Reset-Password',
        )
      ) {
        return (this.resetpermission = false)
      }
    },
    setperrmissionadjustpoint() {
      if (
        this.dataapp.features.every(
          x => x.name != 'User-List-Account-Adjust-Points',
        )
      ) {
        return (this.permissionadjustpoint = false)
      }
    },
    setperrmissionunlinkline() {
      if (
        this.dataapp.features.every(
          x => x.name != 'User-List-Account-Unlink-Line-Account',
        )
      ) {
        return (this.permissionunlinkline = false)
      }
    },
    setpermissionmanagepoint() {
      if (
        this.dataapp.features.every(
          x => x.name != 'Approve-List-Adjust-Points-Edit',
        )
      ) {
        return (this.permissionmanagepoint = false)
      }
    },
    setperrmissionhelpchat() {
      if (this.dataapp.features.every(x => x.name != 'Help-Chat-View')) {
        return (this.permissionhelpchat = false)
      }
    },
    openTab: function (tab) {
      this.$emit('changeTab', tab)
    },
    PostUserUnblock() {
      this.$store.commit('setLoading', true)
      var unblock = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        remark: this.remarkBlock,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUserUnblock(unblock)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.modalBlock = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleConfirmUnlock() {
      this.PostUserUnblock()
      this.visible2 = false
    },
    handleConfirmBlacklist() {
      this.PostUnBlacklist()
      this.visible3 = false
    },
    PostUnBlacklist() {
      this.$store.commit('setLoading', true)
      var unblock = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        remark: this.remarkBlacklist,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUnBlacklist(unblock)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.modalBlacklist = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostUnBlockCoupon() {
      this.$store.commit('setLoading', true)
      var unblock = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUnBlockCoupon(unblock)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostUnLinkline() {
      this.$store.commit('setLoading', true)
      var unlinkline = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        contact_number: this.profile.user_info?.contact_number,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUnLinkline(unlinkline)
          .then(res => {
            this.$store.commit('setLoading', false)
            if (res.data.result.find(x => !x.success)) {
              let error = res.data.result.find(x => !x.success)
              this.$error({
                title: error.table_name,
                content: error.message,
                okType: 'default',
                centered: true,
                onOk: () => {
                  window.location.reload()
                },
              })
            } else {
              this.$success({
                title: 'Success',
                okType: 'default',
                centered: true,
                onOk: () => {
                  window.location.reload()
                },
              })
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostWhiteList() {
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        remark: this.remarkWhitelist,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostWhiteList(data)
          .then(res => {
            this.modalWhitelist = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleConfirmUnlinkline() {
      this.PostUnLinkline()
      this.modalunlink = false
    },
    gettag() {
      let params = {
        tagid: '',
        staffid: cacheHelper.getCache('msToken'),
        userid: this.$route.params.id,
      }
      if (this.$route.query.tagid != null) {
        params.tagid = this.$route.query.tagid
      }
      BzbsUser.apiPostUserintag(params)
        .then(res => {
          res.data.data.forEach((element, index) => {
            let obj = {}
            obj.id = index
            obj.name = element.userintag.tagdescription
            this.tags.push(obj)
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    resetpassword() {
      this.$store.commit('setLoading', true)
      if (this.newpassword == this.confirmpassword) {
        var params = {
          app_id: cacheHelper.getCache('app').id,
          user_id: this.$route.params.id,
          username: this.profile.user_info?.membership_user_name,
          new_password: this.newpassword,
        }
        BzbsUser.apiPutResetPassword(params)
          .then(() => {
            this.visible = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
            })
          })
          .catch(() => {
            this.$store.commit('setLoading', false)
            this.$error({
              title: 'ทำรายการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              centered: true,
            })
            this.visible = false
          })
      } else {
        this.$store.commit('setLoading', false)
        this.textpassword = 'กรุณากรอกรหัสผ่านให้ถูกต้อง'
      }
    },
    DeactivateUser() {
      this.$store.commit('setLoading', true)
      var params = {
        app_id: cacheHelper.getCache('app').id,
        user_id: this.$route.params.id,
        reason: this.reason_deactive,
      }
      BzbsUser.apiPutDeactivateUser(params)
        .then(() => {
          this.modalInactive = false
          this.$store.commit('setLoading', false)
          this.$success({
            title: 'Success',
            okType: 'default',
            centered: true,
            onOk: () => {
              this.reason_deactive = ''
              window.location.reload()
            },
          })
        })
        .catch(error => {
          this.$store.commit('setLoading', false)
          this.errorApi(error)
        })
    },
    onChangePoint(point) {
      var points = point.toString()
      if (points == '0') {
        this.isErrorPoint = true
        this.textrequirepoint = 'Please enter your points.'
      } else if (points.includes('.')) {
        this.isErrorPoint = true
        this.textrequirepoint = 'Enter points (number only)'
      } else {
        this.isErrorPoint = false
      }
    },
    onChangeReason() {
      if (this.reason) {
        this.isErrorReason = false
      }
    },
    PostAdjustpoint() {
      console.log(!this.point)
      if (this.reason && this.point != 0 && Number.isInteger(this.point)) {
        this.$store.commit('setLoading', true)
        var adjustpoint = {
          appid: Cache.getCache('app').id,
          user_id: this.$route.params.id,
          points: this.point,
          reason: this.reason,
          additional_comment: this.comments,
          notification: {
            send_notification: this.checknoti,
            message: this.message,
          },
        }
        return new Promise((resolve, reject) => {
          BzbsUser.apiPostAdjustPoint(adjustpoint)
            .then(res => {
              this.modaladjustpoint = false
              this.$store.commit('setLoading', false)
              this.$success({
                title: 'Success',
                okType: 'default',
                centered: true,
              })
              resolve(res.data.data)
            })
            .catch(error => {
              this.errorApi(error)
              reject(error)
            })
        })
      } else {
        if (!this.reason) {
          this.textrequirereason = 'Please select your reason!'
          this.isErrorReason = true
        }
        if (this.point == 0 || !this.point) {
          this.textrequirepoint = 'Please enter your points.'
          this.isErrorPoint = true
        } else if (!Number.isInteger(this.point)) {
          this.textrequirepoint = 'Enter points (number only).'
          this.isErrorPoint = true
        }
      }
    },
    toggleunblockall(type, unblock) {
      if (type == 'is_blocked_user') {
        this.toggleunblock(unblock)
      } else if (type == 'is_blacklist') {
        this.toggleunblacklist(unblock)
      } else {
        this.toggleunblockcoupon(unblock)
      }
    },
    toggleblockall(type, block) {
      if (type == 'is_blocked_user') {
        this.toggleunblock(block)
      } else if (type == 'is_blacklist') {
        this.toggleunblacklist(block)
      } else {
        this.toggleunblockcoupon(block)
      }
    },
    toggleunblock(checkblock) {
      this.remarkBlock = ''
      this.checkStatusBlock = checkblock
      this.modalBlock = true
      // this.$confirm({
      //   centered: true,
      //   autoFocusButton: null,
      //   title: checkblock ? 'Block User?' : 'Unblock User',
      //   content: checkblock
      //     ? 'You are about to block this user account.'
      //     : 'You are about to unblock this user account.',
      //   okText: checkblock
      //     ? 'Yes, Block this account'
      //     : 'Yes, Unblock this account',
      //   cancelText: 'Cancel',
      //   icon: checkblock ? (
      //     <LockOutlined style="color:#ff4d4f" />
      //   ) : (
      //     <UnlockOutlined style="color:#ff4d4f" />
      //   ),
      //   width: '480px',
      //   okButtonProps: {
      //     type: 'primary',
      //     danger: true,
      //   },
      //   onOk: () => {
      //     this.PostUserUnblock()
      //   },
      //   onCancel: () => {
      //     this.handleCancel()
      //   },
      // })
    },
    toggleunblacklist(check) {
      this.remarkBlacklist = ''
      this.checkStatusBlacklist = check
      this.modalBlacklist = true
      // this.$confirm({
      //   centered: true,
      //   autoFocusButton: null,
      //   title: blacklist ? 'Blacklist User?' : 'Unblacklist User',
      //   content: blacklist
      //     ? 'You are about to blacklist this user account.'
      //     : 'You are about to unblacklist this user account.',
      //   okText: blacklist
      //     ? 'Yes, Blacklist this account'
      //     : 'Yes, Unblacklist this account',
      //   cancelText: 'Cancel',
      //   icon: blacklist ? (
      //     <LockOutlined style="color:#ff4d4f" />
      //   ) : (
      //     <UnlockOutlined style="color:#ff4d4f" />
      //   ),
      //   width: '480px',
      //   okButtonProps: {
      //     type: 'primary',
      //     danger: true,
      //   },
      //   onOk: () => {
      //     this.PostUnBlacklist()
      //   },
      //   onCancel: () => {
      //     this.handleCancel()
      //   },
      // })
    },
    toggleunblockcoupon() {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Unblock Coupon',
        content: 'You are about to unblock coupon this user account.',
        okText: 'Yes, Unblock coupon this account',
        cancelText: 'Cancel',
        icon: <UnlockOutlined style="color:#ff4d4f" />,
        width: '480px',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostUnBlockCoupon()
        },
        onCancel: () => {
          this.handleCancel()
        },
      })
    },
    showModalUnlink() {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Unlink LINE account?',
        content: 'You are about to unlink LINE account this user account.',
        okText: 'Yes, Unlink line account this account',
        cancelText: 'Cancel',
        icon: () =>
          h('img', {
            src: require('@/assets/icons/line.svg'),
            style: {
              width: '35px',
              float: 'left',
              marginRight: '16px',
            },
          }),
        width: '490px',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostUnLinkline()
        },
        onCancel: () => {
          this.handleCancel()
        },
      })
    },
    showModalAdjustpoint() {
      this.modaladjustpoint = true
      this.reason = null
      this.message = ''
      this.comments = ''
      this.point = ''
    },
    showModalHelpChat() {
      this.modalhelpchat = true
    },
    showModal() {
      this.newpassword = ''
      this.confirmpassword = ''
      this.textpassword = ''
      this.visible = true
    },
    handleCancel() {
      this.visible = false
      this.visible2 = false
      this.visible3 = false
      this.modalunlink = false
      this.modaladjustpoint = false
      this.modalhelpchat = false
      this.isErrorReason = false
      this.isErrorPoint = false
      this.modalBlock = false
      this.showModalWallet = false
      this.modalInactive = false
      this.reason_deactive = ''
      this.checkblock = this.datauserblock
      this.checkblacklist = this.datablacklist
      this.checkblockcoupon = this.datacouponblock
      this.modalBlacklist = false
      this.modalWhitelist = false
      this.modalTransferPoint = false
    },
    toggleCreateTicket() {
      this.showModalTicket = !this.showModalTicket
    },
    toggleCreateWallet() {
      this.typewallet = 1
      this.card_id = ''
      this.payment_id = ''
      this.transaction_id = ''
      this.campaign_id = ''
      this.customer_id = ''
      this.amount = ''
      this.detail = ''
      this.fileList = []
      this.options_card_id = []
      this.showModalWallet = !this.showModalWallet
      this.customer_id = this.profile.user_info?.contact_number
        ? this.profile.user_info?.contact_number
        : ''
      this.getWallets()
    },
    getWallets() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetWallets(
          this.$route.params.id,
          '',
          '',
          '',
          '',
          '',
        ).subscribe(
          res => {
            this.datawallets = res.result
            _.forEach(this.datawallets, (item, index) => {
              this.options_card_id[index] = {
                label: item.card_id,
                value: item.card_id,
              }
            })
            resolve(this.datawallets)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    togglemodalBlock() {
      this.modalBlock = !this.modalBlock
    },
    showUnblock() {
      this.notShow = true
      this.showAll = false
    },
    showAllList() {
      this.showAll = true
      this.notShow = false
    },
    adjustWallet() {
      if (this.typewallet == 1) {
        this.postAdjustWallet('Wallet')
      } else {
        this.postAdjustTrueWallet('Truemoneywallet')
      }
    },
    postAdjustWallet(type) {
      this.$store.commit('setLoading', true)
      console.log(this.fileList)
      if (this.fileList) {
        this.adjustwallet = {
          AppId: ConfigManager.getAppId(),
          user_id: this.$route.params.id,
          Type: type,
          Amount: this.amount,
          payment_id: this.payment_id,
          card_id: this.card_id,
          Detail: this.detail,
          formfiles: this.fileList.map(x => x.originFileObj),
        }
      } else {
        this.adjustwallet = {
          AppId: ConfigManager.getAppId(),
          user_id: this.$route.params.id,
          Type: type,
          Amount: this.amount,
          payment_id: this.payment_id,
          card_id: this.card_id,
          Detail: this.detail,
        }
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostAdjustWallets(this.adjustwallet)
          .then(res => {
            this.showModalWallet = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {},
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    postAdjustTrueWallet(type) {
      this.$store.commit('setLoading', true)
      if (this.fileList) {
        this.adjusttruewallet = {
          AppId: ConfigManager.getAppId(),
          user_id: this.$route.params.id,
          Type: type,
          Amount: this.amount,
          transaction_id: this.transaction_id,
          campaign_id: this.campaign_id,
          customer_id: this.customer_id,
          Detail: this.detail,
          formfiles: this.fileList.map(x => x.originFileObj),
        }
      } else {
        this.adjusttruewallet = {
          AppId: ConfigManager.getAppId(),
          user_id: this.$route.params.id,
          Type: type,
          Amount: this.amount,
          transaction_id: this.transaction_id,
          campaign_id: this.campaign_id,
          customer_id: this.customer_id,
          Detail: this.detail,
        }
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostAdjustTrueWallets(this.adjusttruewallet)
          .then(res => {
            this.showModalWallet = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {},
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    toggleWhitelist() {
      this.remarkWhitelist = ''
      this.modalWhitelist = true
    },
    toggleTransferPoint() {
      this.pagePoint = 1
      this.modalTransferPoint = true
    },
  },
}
</script>

<style lang="scss" scoped>
.left-timeline {
  .bg-block {
    border-radius: 4px;
    background-color: #fff1f0;
  }

  .bg-unblock {
    border-radius: 4px;
    background-color: #f5f5f5;
  }

  .bg-coupon {
    border-radius: 4px;
    background-color: #fff6e6;
    color: #f9a601;
  }
}

.vertical-timeline-divider {
  width: 2px;
}

.text-error {
  color: #ff5252;
}

.profile-img {
  background: #6b7280;
  width: 80px;
  height: 80px;
  border: 3px solid #ecedf3;
  overflow: hidden;
  border-radius: 10%;

  img {
    width: 100%;
    height: auto;
  }
}

.logo-img {
  width: 44px;
  text-align: -webkit-center;
  height: 44px;
  margin-top: -45px;
  border-radius: 54%;
  background-color: #ecedf3;
  position: relative;
}

.img-logo {
  border-radius: 50%;
  width: 43px;
  height: 44px;
  padding: 2.3px 2px 2px 2px;
}

.text-detec-c {
  padding: 40px;
  margin-top: 40px;
  color: #666c8b;
}

.border-b-ac {
  border-bottom: 2px solid #ecedf3;
  width: 100%;
}

.border-bt-ac {
  border-top: 2px solid #ecedf3;
  border-bottom: 2px solid #ecedf3;
  width: 100%;
}

.modal-reset {
  .ant-input-affix-wrapper {
    width: 100% !important;
  }
}
</style>
<style lang="scss">
.left-timeline {
  .ant-switch-checked {
    background-color: #ff4d4f;
  }
}
.text-adjustpoint {
  color: #666c8b !important;
  font-weight: unset !important;
  font-family: unset !important;
}

.w-max {
  width: -webkit-fill-available !important;
}

.input-error {
  border-color: #ff5252 !important;
  background-color: #fff1f0 !important;

  .ant-select-selector {
    background-color: #fff1f0 !important;
    border-color: #ff5252 !important;
  }
}

.lock {
  color: #ff4d4f !important;

  .ant-timeline-item-head-blue {
    border-color: #ff4d4f !important;
    background-color: #ff4d4f !important;
  }

  .ant-timeline-item-tail {
    border-left: 2px solid #ffa39e !important;
  }
}

.by-lock {
  font-size: 12px;
  color: #ffa39e !important;
}

.modal-wallet {
  .ant-upload-list-item-actions {
    margin-left: 35px !important;
  }
  .option-card {
    width: 180px !important;
  }
}
.ico-inactive {
  width: 18px;
}
.modal-deactivate {
  .ant-modal-body {
    background-color: unset !important;
  }
  .title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    font-size: calc(20 / 14 * 1rem);
    line-height: 1.2;
    width: calc(100% - 51px);
  }
  .icon {
    font-size: calc(35 / 14 * 1rem);
    display: inline-block;
    color: #f9a601;
    margin-bottom: 10px;
    float: left;
    margin-right: 16px;
  }
  .ant-modal-body {
    padding: 32px 30px 0px 30px;
  }
}
.bg-why {
  border-radius: 4px;
  padding: 4px 12px;
  margin-bottom: 20px;
  margin-top: 20px;
  background: var(--Blue-Blue-1, #e6f7ff);
  .title {
    margin-top: 5px;
    font-weight: 700;
    font-size: 14px;
    color: var(--Blue-Blue-5, #40a9ff);
  }
}
.title-icon {
  color: #636363;
  font-size: 12px;
  margin-top: 5px;
  text-decoration: unset !important;
}
</style>
