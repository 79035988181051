<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <div class="d-flex">
        <a-button class="btn-icon mt-35" @click="backToApproveReceiptList()"
          ><arrow-left-outlined
        /></a-button>
        <TitleBreadcrumbs class="mt-3 ml-4" title="Review Receipt" />
      </div>
    </template>
    <div class="flex-1 w-full px-4 receiptDetail">
      <div class="table-scroll">
        <div>
          <a-card v-if="detailReceipt">
            <div>
              <div class="bg-white">
                <div class="d-flex justify-content-between mb-2">
                  <div class="d-flex gap-2">
                    <h3>
                      {{ detailReceipt.first_name }}
                      {{ detailReceipt.last_name }}
                    </h3>
                    <div class="text-gray mt-1">
                      Contact number:
                      {{
                        detailReceipt.contact_number
                          ? contactNumber(detailReceipt.contact_number)
                          : '-'
                      }}
                    </div>
                    <div class="text-gray mt-1">
                      Email: {{ detailReceipt.email || '-' }}
                    </div>
                  </div>
                  <div>
                    <template v-for="(itemstatus, index) in status">
                      <a-tag
                        v-if="detailReceipt.status == itemstatus.status"
                        :key="index"
                        :color="
                          itemstatus.color == 'default'
                            ? null
                            : itemstatus.color
                        "
                        class="tag-status"
                      >
                        <a-badge
                          :status="itemstatus.color"
                          :text="itemstatus.name"
                        />
                      </a-tag>
                    </template>
                  </div>
                </div>
                <hr />
                <div class="bg-detail flex">
                  <div class="w-25">
                    <a-image
                      v-if="detailReceipt.receipt_image"
                      :src="detailReceipt.receipt_image"
                    />
                  </div>
                  <div class="w-75">
                    <div>
                      <div class="p-3">
                        <div class="row">
                          <div class="col-6">
                            <div>
                              Upload Date :
                              {{
                                detailReceipt.upload_date
                                  ? formatDateWithTime(
                                      detailReceipt.upload_date,
                                    )
                                  : '-'
                              }}
                            </div>
                          </div>
                          <div class="col-6">
                            <div>Amount : {{ detailReceipt.amount }}</div>
                          </div>
                          <!-- <div class="col-4 text-end">
                            <div class="text-gray">Redeem Amount :</div>
                            <div>{{ dataDetailItem.redeem_amount }}</div>
                          </div> -->
                        </div>
                      </div>
                      <hr />
                      <div
                        :class="
                          detailReceipt.status != 0
                            ? 'detail-cons'
                            : 'detail-cons-action'
                        "
                      >
                        <a-collapse
                          v-model:activeKey="activeKeyCam"
                          expand-icon-position="right"
                          :bordered="false"
                        >
                          <template #expandIcon="{ isActive }">
                            <down-outlined :rotate="isActive ? 180 : 0" />
                          </template>
                          <a-collapse-panel key="1">
                            <template #header>
                              <div class="fw-700">Campaign Info</div>
                            </template>
                            <div class="row pl-3">
                              <div class="item-list-receipt">
                                <div class="title">Campaign Name :</div>
                                <div class="">
                                  {{ detailReceipt.campaign_name || '-' }}
                                </div>
                              </div>
                              <div class="item-list-receipt">
                                <div class="title">Campaign Period :</div>
                                <div class="">
                                  {{
                                    formatDate(
                                      detailReceipt.campaign_start_date,
                                    )
                                  }}
                                  -
                                  {{
                                    formatDate(
                                      detailReceipt.campaign_expire_date,
                                    )
                                  }}
                                </div>
                              </div>
                              <div class="item-list-receipt">
                                <div class="title">Campaign ID :</div>
                                <div class="">
                                  {{ detailReceipt.campaign_id || '-' }}
                                </div>
                              </div>
                              <div class="item-list-receipt">
                                <div class="title">Campaign Item :</div>
                                <div class="">
                                  {{ detailReceipt.campaign_item_no || '-' }}
                                </div>
                              </div>
                            </div>
                          </a-collapse-panel>
                        </a-collapse>
                        <a-collapse
                          v-if="detailReceipt.ocr_key"
                          v-model:activeKey="activeKeyCom"
                          expand-icon-position="right"
                          :bordered="false"
                        >
                          <template #expandIcon="{ isActive }">
                            <down-outlined :rotate="isActive ? 180 : 0" />
                          </template>
                          <a-collapse-panel key="1">
                            <template #header>
                              <div class="w-100 flex justify-between">
                                <div class="fw-700">Company Info</div>
                                <div v-if="editinvoice" class="d-flex gap-2">
                                  <a-button @click="cancelEdit()"
                                    >Cancel</a-button
                                  >
                                  <a-button
                                    type="primary"
                                    :disabled="editinvoice && !isChange"
                                    @click="saveEdit($event)"
                                    >Save</a-button
                                  >
                                </div>
                              </div>
                            </template>
                            <div class="row pl-3">
                              <div class="col-6">
                                <div class="item-list-com">
                                  <div class="title">OCR Transaction ID :</div>
                                  <div class="">
                                    {{ dataDetailItem?.id || '-' }}
                                  </div>
                                </div>
                                <div class="item-list-com">
                                  <div
                                    :class="
                                      datapermissionbyapp.includes(
                                        'Approve-List-Receipt-Edit-Invoice-ID',
                                      ) && editinvoice
                                        ? 'title mt-2'
                                        : 'title'
                                    "
                                  >
                                    Receipt No. / Invoice No. :
                                  </div>
                                  <div
                                    v-if="
                                      datapermissionbyapp.includes(
                                        'Approve-List-Receipt-Edit-Invoice-ID',
                                      ) && editinvoice
                                    "
                                  >
                                    <a-input
                                      v-model:value="dataDetailItem.invoice_id"
                                      :maxLength="30"
                                      @change="changeInputinvoice()"
                                    />
                                  </div>
                                  <div v-else>
                                    {{ dataDetailItem?.invoice_id || '-' }}
                                  </div>
                                  <a
                                    v-if="
                                      detailReceipt.status == 0 &&
                                      datapermissionbyapp.includes(
                                        'Approve-List-Receipt-Edit-Invoice-ID',
                                      )
                                    "
                                    class="text-link ml-2"
                                    :class="
                                      datapermissionbyapp.includes(
                                        'Approve-List-Receipt-Edit-Invoice-ID',
                                      ) && editinvoice
                                        ? 'mt-2'
                                        : ''
                                    "
                                    @click="actionEditInvoice()"
                                    ><edit-outlined
                                  /></a>
                                </div>
                                <div class="item-list-com">
                                  <div class="title">Company Name :</div>
                                  <div class="">
                                    {{ dataDetailItem?.customer_name || '-' }}
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="item-list-com">
                                  <div class="title">Company Tax ID :</div>
                                  <div class="">
                                    {{ dataDetailItem?.customer_tax_id || '-' }}
                                  </div>
                                </div>
                                <div class="item-list-com">
                                  <div class="title">Supplier Name :</div>
                                  <div class="">
                                    {{ dataDetailItem?.supplier_name || '-' }}
                                  </div>
                                </div>
                                <div class="item-list-com">
                                  <div class="title">Supplier Tax ID :</div>
                                  <div class="">
                                    {{ dataDetailItem?.supplier_tax_id || '-' }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a-collapse-panel>
                        </a-collapse>
                        <a-collapse
                          v-if="detailReceipt.ocr_key && dataDetailItem"
                          v-model:activeKey="activeKeyField"
                          expand-icon-position="right"
                          :bordered="false"
                        >
                          <template #expandIcon="{ isActive }">
                            <down-outlined :rotate="isActive ? 180 : 0" />
                          </template>
                          <a-collapse-panel key="1">
                            <template #header>
                              <div class="w-100 flex justify-between">
                                <div class="d-flex gap-2">
                                  <div class="fw-700">Required Field</div>
                                  <a
                                    v-if="
                                      detailReceipt.status == 0 &&
                                      datapermissionbyapp.includes(
                                        'Approve-List-Receipt-Edit',
                                      )
                                    "
                                    class="text-link"
                                    @click="actionEdit($event)"
                                    ><edit-outlined
                                  /></a>
                                </div>
                                <div v-if="edit" class="d-flex gap-2">
                                  <a-button @click="cancelEdit()"
                                    >Cancel</a-button
                                  >
                                  <a-button
                                    type="primary"
                                    :disabled="edit && !isChange"
                                    @click="saveEdit($event)"
                                    >Save</a-button
                                  >
                                </div>
                              </div>
                            </template>
                            <div class="field-item px-3">
                              <div
                                v-for="(item, index) of dataDetailItem?.items"
                                :key="`index-${index}`"
                                class="mt-3 row w-100"
                              >
                                <div class="d-flex gap-3">
                                  <div class="px-2 mt-2">{{ index + 1 }}.</div>
                                  <div class="row">
                                    <div class="col-4">
                                      <div class="d-flex gap-2">
                                        <div class="mt-2 title-field">
                                          SKU :
                                        </div>
                                        <a-input
                                          v-model:value="item.product_code"
                                          class="input-field"
                                          placeholder="Input SKU"
                                          :disabled="!edit"
                                          allowClear
                                          @change="changeInput()"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-4">
                                      <div class="d-flex gap-2">
                                        <div class="mt-2 title-field">
                                          SKU Name :
                                        </div>
                                        <a-input
                                          v-model:value="item.description"
                                          class="input-field"
                                          placeholder="Input SKU Name"
                                          :disabled="!edit"
                                          allowClear
                                          @change="changeInput()"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-4"></div>
                                    <div class="col-4 mt-3">
                                      <div class="d-flex gap-2">
                                        <div class="mt-2 title-field">
                                          Quantity :
                                        </div>
                                        <a-input-number
                                          v-model:value="item.quantity"
                                          class="input-field"
                                          placeholder="Input Quantity"
                                          :disabled="!edit"
                                          @keypress="
                                            onChangeNumQuantity($event)
                                          "
                                          @change="changeInput()"
                                          allowClear
                                        />
                                      </div>
                                    </div>
                                    <div class="col-4 mt-3">
                                      <div class="d-flex gap-2">
                                        <div class="mt-2 title-field">
                                          Price Per Unit :
                                        </div>
                                        <a-input-number
                                          v-model:value="item.unit_price"
                                          placeholder="Input Price Per Unit"
                                          class="input-field"
                                          :disabled="!edit"
                                          allowClear
                                          @keypress="onChangeNum($event)"
                                          @change="changeInput()"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-4 mt-3">
                                      <div class="d-flex gap-2">
                                        <div class="mt-2 title-field">
                                          Amount Price :
                                        </div>
                                        <a-input-number
                                          v-model:value="item.amount_value"
                                          placeholder="Input Amount Price"
                                          class="input-field"
                                          :disabled="!edit"
                                          allowClear
                                          @keypress="onChangeNum($event)"
                                          @change="changeInput()"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div v-if="edit" class="mt-auto">
                                    <a-tooltip placement="bottom">
                                      <template #title>
                                        <span>Delete</span>
                                      </template>
                                      <a
                                        class="fraud"
                                        @click="deleteItem(index)"
                                        ><delete-outlined
                                      /></a>
                                    </a-tooltip>
                                  </div>
                                </div>
                                <hr
                                  v-if="
                                    index != dataDetailItem?.items.length - 1
                                  "
                                  class="mt-3"
                                />
                              </div>
                              <a-button
                                v-if="edit"
                                class="mt-3"
                                @click="addItem()"
                                >+ Add</a-button
                              >
                            </div>
                          </a-collapse-panel>
                        </a-collapse>
                      </div>
                    </div>
                    <div v-if="detailReceipt.status != 0" class="px-4 mt-2">
                      <div class="row">
                        <div class="col-4">
                          <div class="d-flex gap-2">
                            <div>Remark :</div>
                            <div>
                              <div v-if="!dataRemark">
                                {{
                                  detailReceipt.remark
                                    ? detailReceipt.remark
                                    : '-'
                                }}
                              </div>
                              <div v-else>
                                {{
                                  dataRemark.approve_reject_reason
                                    ? dataRemark.approve_reject_reason
                                    : '-'
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="d-flex gap-2">
                            <div>Responded date :</div>
                            <div>
                              <div v-if="!dataRemark">
                                {{
                                  detailReceipt.modify_date
                                    ? formatDate(
                                        detailReceipt.modify_date,
                                        'DD/MMM/YYYY',
                                      )
                                    : '-'
                                }}
                              </div>
                              <div v-else>
                                {{
                                  dataRemark.approve_reject_date
                                    ? formatDate(
                                        dataRemark.approve_reject_date,
                                        'DD/MMM/YYYY',
                                      )
                                    : '-'
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-4">
                          <div class="d-flex gap-2">
                            <div>Respondent :</div>
                            <div>
                              <div v-if="!dataRemark">
                                {{
                                  detailReceipt.modify_by
                                    ? detailReceipt.modify_by
                                    : '-'
                                }}
                              </div>
                              <div v-else>
                                {{
                                  dataRemark.approve_reject_by
                                    ? dataRemark.approve_reject_by.name
                                    : '-'
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <a-row>
                        <a-col :span="8">
                          <div class="h-detail">Remark :</div>
                        </a-col>
                        <a-col :span="14">
                          <div v-if="!dataRemark">
                            {{
                              detailReceipt.remark ? detailReceipt.remark : '-'
                            }}
                          </div>
                          <div v-else>
                            {{
                              dataRemark.approve_reject_reason
                                ? dataRemark.approve_reject_reason
                                : '-'
                            }}
                          </div>
                        </a-col>
                        <a-col :span="8">
                          <div class="h-detail">Responded date :</div>
                        </a-col>
                        <a-col :span="14">
                          <div v-if="!dataRemark">
                            {{
                              detailReceipt.modify_date
                                ? formatDate(detailReceipt.modify_date)
                                : '-'
                            }}
                          </div>
                          <div v-else>
                            {{
                              dataRemark.approve_reject_date
                                ? formatDate(
                                    dataRemark.approve_reject_date,
                                    'DD/MMM/YYYY',
                                  )
                                : '-'
                            }}
                          </div>
                        </a-col>
                        <a-col :span="8">
                          <div class="h-detail">Respondent :</div>
                        </a-col>
                        <a-col :span="14">
                          <div v-if="!dataRemark">
                            {{
                              detailReceipt.modify_by
                                ? detailReceipt.modify_by
                                : '-'
                            }}
                          </div>
                          <div v-else>
                            {{
                              dataRemark.approve_reject_by
                                ? dataRemark.approve_reject_by.name
                                : '-'
                            }}
                          </div>
                        </a-col>
                      </a-row> -->
                    </div>
                    <div
                      v-if="
                        (detailReceipt.status == 0 &&
                          datapermissionbyapp.includes(
                            'Approve-List-Receipt-Resubmit',
                          )) ||
                        (detailReceipt.status == 0 &&
                          datapermissionbyapp.includes(
                            'Approve-List-Receipt-Edit',
                          ))
                      "
                      class="w-100 px-3 py-3 d-flex gap-2 justify-center border-t"
                    >
                      <div
                        v-if="
                          detailReceipt.status == 0 &&
                          datapermissionbyapp.includes(
                            'Approve-List-Receipt-Resubmit',
                          )
                        "
                        class="action-bot"
                      >
                        <a-button
                          :disabled="edit"
                          type="defalut"
                          @click="confirmAction('Resubmit')"
                        >
                          Resubmit
                        </a-button>
                      </div>
                      <div
                        class="action-bot"
                        v-if="
                          detailReceipt.status == 0 &&
                          datapermissionbyapp.includes(
                            'Approve-List-Receipt-Edit',
                          )
                        "
                      >
                        <a-button
                          @click="confirmAction('Reject')"
                          :disabled="edit"
                          type="danger"
                          >Reject</a-button
                        >
                      </div>

                      <div class="action-bot">
                        <a-button
                          v-if="
                            detailReceipt.status == 0 &&
                            datapermissionbyapp.includes(
                              'Approve-List-Receipt-Edit',
                            )
                          "
                          type="success"
                          :disabled="edit"
                          @click="Approve"
                        >
                          Approve
                        </a-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </div>
    <DisplayFieldsApproveReceipt
      :value="showDisplayFieldsApproveReceipt"
      :dataDetailReceipt="dataDetailReceipt"
      @callRefresh="callRefresh()"
      @handleClose="showDisplayFieldsApproveReceipt = false"
    >
    </DisplayFieldsApproveReceipt>
    <a-modal
      class="modal-set-expire"
      wrap-class-name="modal-deactivate"
      :visible="modalReject"
      @cancel="handleCancel"
      okText="Confirm"
      :centered="true"
      :closable="false"
      :ok-button-props="{ disabled: !remark }"
      @ok="confirmRejectorResubmit()"
    >
      <div class="font-modal-delete">
        <div v-if="action == 'Reject'">
          <warning-outlined class="icon fraud" />
          <div class="title mb-2">Are you sure you want to Reject Receipt?</div>
          <div class="mb-2">
            Please carefully review the receipt details as this process cannot
            be reversed.
          </div>
          <div class="mb-1">
            <label class="false mr-1 ml-re">*</label>Why was the receipt
            rejected? :
          </div>
        </div>
        <div v-else>
          <file-outlined class="icon" />
          <div class="title mb-2">Request more information from user?</div>
          <div class="mb-2">Unclear photo? Mission required information?</div>
          <div class="mb-2">
            Resubmit will request user to upload receipt photo or add more
            information.
          </div>
          <div class="mb-1">
            <label class="false mr-1 ml-re">*</label>Remark :
          </div>
        </div>
        <a-textarea
          v-model:value="remark"
          :auto-size="{ minRows: 3, maxRows: 3 }"
        />
      </div>
      <template #footer
        ><a-button @click="handleCancel()" type="default">Cancel</a-button>
        <a-button
          @click="confirmRejectorResubmit()"
          :type="action == 'Reject' ? 'danger' : 'primary'"
          :disabled="!remark"
          >{{ action == 'Reject' ? 'Reject' : 'Resubmit' }}</a-button
        >
      </template>
    </a-modal>
  </AppLayout>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import dayjs from 'dayjs'
import Util from '@/helper/Utility.js'
import DisplayFieldsApproveReceipt from '@/module/Setting/ApproveList/component/DisplayFieldsApproveReceipt.vue'
import { config } from '@/config/bzbsconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserList',
  components: {
    // Footer,
    DisplayFieldsApproveReceipt,
    AppLayout,
    TitleBreadcrumbs,
  },
  data: function () {
    return {
      datapermissionbyapp: {},
      detailReceipt: {},
      local_permissionbyapp:
        JSON.parse(localStorage.getItem('permissionbyapp')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('permissionbyapp')),
      local_detailReceipt:
        JSON.parse(localStorage.getItem('detailReceipt')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('detailReceipt')),
      showDisplayFieldsApproveReceipt: false,
      dataDetailReceipt: [],
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: null,
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveDataPrivacy: false,
      searchObject: {
        user_id: '',
        contact_number: '',
        receipt_no: '',
        campaign_id: '',
      },
      status: [
        {
          id: 1,
          status: 0,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          status: 1,
          name: 'Approve',
          color: 'green',
        },
        {
          id: 3,
          status: -1,
          name: 'Reject',
          color: 'red',
        },
      ],
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting',
          value: 'Waiting',
        },
        {
          label: 'Approved',
          value: 'Approved',
        },
        {
          label: 'Rejected',
          value: 'Reject',
        },
      ],
      typesearch: 'user_id',
      options_search: [
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'Contact number',
          value: 'contact_number',
        },
        {
          label: 'Receipt No',
          value: 'receipt_no',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
      ],
      columnsReceipt: [
        {
          title: 'User',
          dataIndex: 'user_id',
          slots: { customRender: 'user_id' },
          key: 'user_id',
          width: 160,
          align: 'left',
        },
        {
          title: 'Receipt No',
          dataIndex: 'receipt_no',
          slots: { customRender: 'receipt_no' },
          key: 'receipt_no',
          width: 100,
          align: 'left',
        },
        {
          title: 'Campaign',
          dataIndex: 'campaign',
          slots: { customRender: 'campaign' },
          key: 'campaign',
          width: 160,
          align: 'left',
        },
        {
          title: 'Campaign Period',
          dataIndex: 'campaign_period',
          slots: { customRender: 'campaign_period' },
          key: 'campaign_period',
          width: 160,
          align: 'left',
        },
        {
          title: 'Shop Name',
          dataIndex: 'shop_name',
          slots: { customRender: 'shop_name' },
          key: 'shop_name',
          width: 140,
          align: 'left',
        },
        {
          title: 'Branch Name',
          dataIndex: 'branch_name',
          slots: { customRender: 'branch_name' },
          key: 'branch_name',
          width: 140,
          align: 'left',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          slots: { customRender: 'amount' },
          key: 'amount',
          width: 100,
          align: 'right',
        },
        {
          title: 'Purchase From',
          dataIndex: 'purchase_from',
          slots: { customRender: 'purchase_from' },
          key: 'purchase_from',
          width: 120,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 100,
          align: 'left',
        },
        {
          title: 'Upload Date',
          dataIndex: 'upload_date',
          slots: { customRender: 'upload_date' },
          key: 'upload_date',
          width: 100,
          align: 'left',
        },
      ],
      datalists: [],
      dataReceiptList: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetails: [],
      id: '',
      datalist: [],
      showModalConfirm: false,
      options_eventname: [],
      eventName: '',
      statussearch: '',
      selected: [],
      dataDetailItem: [],
      isCheckActive: '',
      activeKeyuser: '1',
      activeKeyCam: '2',
      activeKeyCom: '3',
      activeKeyField: '',
      modalReject: false,
      remark: '',
      dataRemark: [],
      noData: false,
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      options_field: [
        {
          label: 'SKU',
          value: 'SKU',
        },
        {
          label: 'SKU Name',
          value: 'SKU Name',
        },
        {
          label: 'Quantity',
          value: 'Quantity',
        },
        {
          label: 'Price Per Unit',
          value: 'Price Per Unit',
        },
        {
          label: 'Amount Price',
          value: 'Amount Price',
        },
      ],
      edit: false,
      isChange: false,
      editinvoice: false,
    }
  },
  computed: {},
  mounted() {
    this.getListEventName().then(() => {
      this.startdate = moment().format('YYYY-MM-DD')
      this.enddate = moment().format('YYYY-MM-DD')
      this.dateFilterValue = 'Today'
      this.statussearch = 'Waiting'
      if (this.local_permissionbyapp) {
        this.datapermissionbyapp = this.local_permissionbyapp
      }
      if (this.local_detailReceipt) {
        this.detailReceipt = this.local_detailReceipt
      }
      this.clicksearch()
    })
  },
  watch: {
    local_permissionbyapp: function () {
      this.datapermissionbyapp = this.local_permissionbyapp
    },
    local_detailReceipt: function () {
      this.detailReceipt = this.local_detailReceipt
    },
  },
  methods: {
    onChangeNumQuantity($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)
      if (match == null) {
        $event.preventDefault()
      }
    },
    onChangeNum($event) {
      const regex = /^[0-9.]\d*$/
      var match = $event.key.match(regex)
      if (match == null) {
        $event.preventDefault()
      }
    },
    deleteItem(index) {
      this.dataDetailItem?.items.splice(index, 1)
      this.isChange = true
    },
    actionEdit(event) {
      event.stopPropagation()
      this.edit = !this.edit
      if (this.edit) {
        this.activeKeyField = '1'
      }
    },
    actionEditInvoice() {
      this.editinvoice = !this.editinvoice
    },
    backToApproveReceiptList() {
      this.$router.push({
        name: 'approvelist',
        query: { tab: 'receipt' },
      })
    },
    callRefresh() {
      this.$store.commit('setLoading', true)
      this.getListEventName().then(() => {
        this.clicksearch()
      })
    },
    convertDate(date) {
      return Util.convertDate(date)
    },
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    toggleApproveReceipt(record) {
      this.dataDetailReceipt = record
      this.showDisplayFieldsApproveReceipt =
        !this.showDisplayFieldsApproveReceipt
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.showDateFilter = false
      if (this.detailReceipt.ocr_key) {
        this.getReceiptsDetail().then(() => {
          this.activeKeyCom = '1'
          this.activeKeyField = '1'
          this.$store.commit('setLoading', false)
        })
      } else {
        this.activeKeyCam = '1'
        this.getRemark()
        this.$store.commit('setLoading', false)
      }
    },
    addItem() {
      var item = {}
      this.dataDetailItem.items.push(item)
    },
    getReceiptsDetail() {
      let appid = ConfigManager.getAppId()
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetReceiptsDetail(
          appid,
          this.detailReceipt._id.split('|')[1],
          this.detailReceipt.ocr_key,
        ).subscribe(
          data => {
            console.log(data)
            this.dataDetailItem = data.result
            this.getRemark()
            // if (this.dataReceiptList.length > 0) {
            //   this.isCheckActive = this.dataReceiptList[0]._id
            //   this.dataDetailItem = this.dataReceiptList[0]
            //   this.getRemark()
            //   this.noData = false
            // } else {
            //   this.noData = true
            // }
            this.$store.commit('setLoading', false)
            resolve(this.dataReceiptList)
          },
          err => {
            this.noData = true
            this.$store.commit('setLoading', false)
            reject(err)
          },
        )
      })
    },
    getReceiptslist() {
      let appid = ConfigManager.getAppId()
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetReceipts(
          appid,
          '',
          this.detailReceipt.eventName,
          '',
          '',
          '',
          this.detailReceipt.startdate,
          this.detailReceipt.enddate,
          '',
          1,
          this.detailReceipt.row_key,
        ).subscribe(
          data => {
            console.log(data)
            let datalist = data.result
            datalist = datalist.filter(
              x => x.row_key == this.detailReceipt.row_key,
            )
            let mapData = datalist.map(i => {
              return {
                ...i,
                typesearch: this.detailReceipt.typesearch,
                search: this.detailReceipt.search,
                startdate: this.detailReceipt.startdate,
                enddate: this.detailReceipt.enddate,
                statussearch: this.detailReceipt.statussearch,
                page: this.detailReceipt.page,
                eventName: this.detailReceipt.eventName,
                dateFilterValue: this.detailReceipt.dateFilterValue,
              }
            })
            this.detailReceipt = mapData[0]
            localStorage.setItem(
              'detailReceipt',
              JSON.stringify(this.detailReceipt),
            )
            this.$store.commit('setLoading', false)
            resolve(data)
          },
          err => {
            this.$store.commit('setLoading', false)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getReceiptslist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getListEventName() {
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetListEventName(appid).subscribe(
          data => {
            if (data.result.length == 1) {
              this.eventName = data.result[0].event_name
              console.log('eventName', this.eventName)
            }
            let dataoption = data.result
            _.forEach(dataoption, (item, index) => {
              this.options_eventname[index] = {
                label: item.event_name,
                value: item.event_name,
              }
            })
            resolve(data)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApproveListByID(id) {
      this.id = id
      this.getdataDetails().then(() => {
        this.checkData()
        this.showDisplayFieldsApproveDataPrivacy =
          !this.showDisplayFieldsApproveDataPrivacy
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      console.log(range)
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .add(-1, 'months')
          .endOf('month')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    setmanagepermission() {
      if (this.datapermission.features.every(x => x.name != 'Manage-Privacy')) {
        return (this.managepermission = false)
      }
    },
    confirmAction(action) {
      this.action = action
      this.remark = ''
      this.modalReject = true
    },
    confirmRejectorResubmit() {
      if (this.action == 'Reject') {
        this.Reject()
      } else {
        this.PostResubmit()
      }
    },
    Approve() {
      this.$store.commit('setLoading', true)
      if (
        !this.detailReceipt.receipt_no &&
        this.datapermissionbyapp.includes(
          'Approve-List-Receipt-Edit-Invoice-ID',
        )
      ) {
        this.PostReceiptUpdate(false, 'Approve')
      } else {
        this.PostApprove().then(() => {
          this.$store.commit('setLoading', false)
          this.$success({
            title: 'Success',
            okType: 'default',
            centered: true,
            onOk: () => {
              this.remark = ''
              this.getReceiptslist()
              this.clicksearch()
              this.getRemark()
            },
          })
        })
      }
    },
    PostApprove() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.detailReceipt.user_id ? this.detailReceipt.user_id : '',
        event_name: this.detailReceipt._id
          ? this.detailReceipt._id.split('|')[1]
          : '',
        receipt_rowkey: this.detailReceipt.row_key
          ? this.detailReceipt.row_key
          : '',
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApproveReceipt(data)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    Reject() {
      this.$store.commit('setLoading', true)
      this.PostReject().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.getReceiptslist()
            this.clicksearch()
            this.getRemark()
            this.remark = ''
          },
        })
      })
    },
    PostReject() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.detailReceipt.user_id ? this.detailReceipt.user_id : '',
        event_name: this.detailReceipt._id
          ? this.detailReceipt._id.split('|')[1]
          : '',
        receipt_rowkey: this.detailReceipt.row_key
          ? this.detailReceipt.row_key
          : '',
        remark: this.remark,
      }
      this.modalReject = false
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostResubmit() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.detailReceipt.user_id ? this.detailReceipt.user_id : '',
        event_name: this.detailReceipt._id
          ? this.detailReceipt._id.split('|')[1]
          : '',
        receipt_rowkey: this.detailReceipt.row_key
          ? this.detailReceipt.row_key
          : '',
        remark: this.remark,
      }
      this.modalReject = false
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostResubmitReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                this.getReceiptslist()
                this.clicksearch()
                this.getRemark()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleCancel() {
      this.modalReject = false
    },

    PostReceiptUpdate(check_popup, check_action) {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        id: this.dataDetailItem.id,
        user_id: this.detailReceipt.user_id,
        event_name: this.detailReceipt.eventName,
        receipt_rowkey: this.detailReceipt.row_key,
        items: this.dataDetailItem.items,
        receipt_no: this.dataDetailItem.invoice_id,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostReceiptUpdate(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            console.log('check_popup', check_popup)
            if (check_popup) {
              this.$success({
                title: 'Success',
                okType: 'default',
                centered: true,
                onOk: () => {
                  this.getReceiptslist()
                  this.edit = false
                  this.editinvoice = false
                  this.isChange = false
                  this.clicksearch()
                },
              })
            } else {
              if (check_action == 'Approve') {
                this.PostApprove().then(() => {
                  this.$store.commit('setLoading', false)
                  this.$success({
                    title: 'Success',
                    okType: 'default',
                    centered: true,
                    onOk: () => {
                      this.remark = ''
                      this.getReceiptslist()
                      this.clicksearch()
                      this.getRemark()
                    },
                  })
                })
              }
              this.edit = false
              this.editinvoice = false
              this.isChange = false
            }

            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    getRemark() {
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetReceiptsApproveDetail(
          appid,
          this.detailReceipt.partition_key,
          this.detailReceipt.row_key,
        ).subscribe(
          data => {
            this.dataRemark = data.result
            resolve(this.dataRemark)
          },
          err => {
            this.dataRemark = null
            this.$store.commit('setLoading', false)
            console.log(this.dataRemark)
            reject(err)
          },
        )
      })
    },
    cancelEdit() {
      this.edit = false
      this.editinvoice = false
      this.isChange = false
      this.clicksearch()
    },
    changeInput() {
      this.edit = true
      this.isChange = true
    },
    changeInputinvoice() {
      this.editinvoice = true
      this.isChange = true
    },
    saveEdit(event) {
      event.stopPropagation()
      this.PostReceiptUpdate(true)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approvepoint {
  overflow-x: hidden;

  .table-scroll {
    // background: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
  }

  .bg-list {
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 6px;
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
  }

  .bg-detail {
    overflow: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 500px);
    max-height: calc(100vh - 500px);
  }

  .h-date {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
  }

  .img-detail {
    background-color: #f5f5f5;
  }

  .nav-search {
    background-color: #fff;
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-left: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      padding-left: 10px;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;

    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.main-menu {
  .datefilter {
    right: 0;
    left: unset;
  }
}

.approvepoint {
  .ant-table-row {
    vertical-align: top !important;
  }

  .ant-table-thead {
    vertical-align: top !important;
  }
  .ml-10 {
    margin-left: 10px;
  }
}
.item-list-receipt {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  .title {
    color: #aaaaaa;
    width: 120px;
  }
}
.item-list-com {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  .title {
    color: #aaaaaa;
    width: 170px;
  }
}
.isactive-list {
  background-color: #fff6e6;
  border-right: 2px solid #feca62;
}

// .detail-con {
//   overflow-x: hidden;
//   overflow-y: auto;
//   border-radius: 6px;
//   min-height: calc(100vh - 430px);
//   max-height: calc(100vh - 430px);
// }
// .detail-con-action {
//   overflow-x: hidden;
//   overflow-y: auto;
//   border-radius: 6px;
//   min-height: calc(100vh - 430px);
//   max-height: calc(100vh - 430px);
// }
.receiptDetail {
  @media only screen and (max-width: 1545px) {
    .ant-btn {
      width: 20px !important;
    }
  }
  .bg-detail {
    overflow: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
    .ant-image {
      position: relative !important;
      display: inline-block !important;
      height: 100% !important;
      width: 100% !important;
      align-content: center !important;
    }
    .ant-image-img {
      width: 100% !important;
      height: auto !important;
      vertical-align: middle !important;
    }
  }
}
.detail-cons {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  min-height: calc(100vh - 320px);
  max-height: calc(100vh - 320px);
}
.detail-cons-action {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  min-height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
}
.action-bot {
  text-align: center;
  width: calc(100% / 3);
  .ant-btn {
    width: 100% !important;
  }
}
.field-input {
  width: calc(100% / 2);
}
.title-field {
  width: 135px;
}
.title-field-sku {
  width: 118px;
}
.title-field-remark {
  width: 110px;
}
.input-field {
  width: 285px !important;
}
.field-item {
  @media only screen and (max-width: 1545px) {
    .ant-input {
      height: 29px !important;
    }
    .ant-input-number-input {
      height: 29px !important;
    }
    .title-field {
      width: 145px;
    }
    .ant-btn {
      width: 100px !important;
      height: 29px !important;
      padding: 0px 15px !important;
    }
    font-size: 13px !important;
  }
}
</style>
