<template>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Change Log</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <a-timeline>
                <a-timeline-item
                  v-for="(data, index) of historylogs.filter(x => x.log_message)"
                  :key="`index-${index}`"
                  :color="index == 0 ? 'green' : 'gray'"
                >
                  <div
                    :class="index == 0 ? 'bg-active-timeline' : 'bg-timeline'"
                  >
                    <a-row>
                      <a-col :md="24" :lg="24" :xl="24">
                        <div
                          v-if="data.log_message"
                          v-html="data.log_message"
                        ></div>
                        <div
                          v-else
                          v-for="(title, index2) of Object.keys(data.new_data)"
                          :key="`index-${index2}`"
                        >
                          <div
                            v-if="
                              Array.isArray(data.new_data[title])
                                ? data.new_data[title].length > 0
                                : Object.keys(data.new_data[title]).length > 0
                            "
                          >
                            <!-- <div
                              :class="index == 0 ? 'fw-700-active' : 'fw-700'"
                            >
                              {{ changeTitle(title) }}
                            </div> -->
                            <div>
                              <div
                                v-if="
                                  Array.isArray(data.new_data[title])
                                    ? false
                                    : true
                                "
                              >
                                <div
                                  v-for="(content, index2) of Object.keys(
                                    data.new_data[title],
                                  )"
                                  :key="`index-${index2}`"
                                >
                                  Change field {{ content }}
                                  {{
                                    data.original_data[
                                      title.replace('New', 'Original')
                                    ][content] ?? '-'
                                  }}
                                  to
                                  {{ data.new_data[title][content] ?? '-' }}
                                </div>
                              </div>
                              <div v-else>
                                <div
                                  v-for="(content, index3) of data.new_data[
                                    title
                                  ]"
                                  :key="`index-${index3}`"
                                >
                                  <div
                                    v-for="(
                                      contentaddress, index4
                                    ) of Object.keys(content.Data)"
                                    :key="`index-${index4}`"
                                  >
                                    Change field {{ contentaddress }}
                                    {{
                                      data.original_data[
                                        title.replace('New', 'Original')
                                      ][index3].Data[contentaddress] ?? '-'
                                    }}
                                    to
                                    {{ content.Data[contentaddress] ?? '-' }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                      </a-col>
                    </a-row>
                    <a-row>
                      <a-col :md="12" :lg="12" :xl="12">
                        <div v-if="data.auditable" class="by ">
                          by : {{ data.auditable.name }}
                        </div>
                      </a-col>
                      <a-col :md="12" :lg="12" :xl="12">
                        <div class="by text-end">
                          {{
                            formatDate(data.created_date, 'DD MMM YYYY - HH:mm')
                          }}
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a-timeline-item>
              </a-timeline>
            </div>
          </div>
          <div class="flex-none px-4 py-3">
            <a-button @click="dismiss(true)"> Close </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import Util from '@/helper/Utility'
import moment from 'moment'
// import _ from 'lodash'
export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  data: function () {
    return {
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
       
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {
  },
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    changeTitle(title) {
      let regEx = new RegExp('New', 'gi')
      return 'Table ' + title.replace(regEx, '')
    },
    getdate(created_date) {
      return moment(created_date).format(this.formatdate)
    },
    gettime(created_date) {
      return moment(created_date).format(this.formattime)
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function (flagResetState = true) {
      console.log('dismiss')
      if (flagResetState) {
        this.resetState()
      } else {
        this.setState()
      }
      console.log('handleClose')
      this.$emit('handleClose')
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log {
  width: 30rem;
}
</style>
