<template>
  <div class="box-loader" :class="{ loaded: !$store.state.loading }">
    <div class="wrapper-loaading">
      <span class="loader"></span>
      <p v-if="text">{{ text }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    isShow: Boolean,
    text: String,
  },
  data() {
    return {}
  },
  watch: {
    // '$store.state.loading': function (val) {
    //   if (val) {
    //     this.disableScroll()
    //   } else {
    //     this.enableScroll()
    //   }
    // },
  },
  methods: {
    // disableScroll: function () {
    //   document.body.classList.add('overflow-hidden')
    // },
    // enableScroll: function () {
    //   document.body.classList.remove('overflow-hidden')
    // },
    // closeLoading() {
    //   this.$store.commit('setLoading', false)
    // },
  },
}
</script>
<style lang="scss" scope>
.box-loader {
  display: none !important;
}
</style>
