<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <transition name="fade">
    <div class="filter row" v-if="value">
      <div v-if="FilterConfig?.status" class="col-3 px-0">
        <div class="filter-colums">
          <div>Status :</div>
        </div>
      </div>
      <div v-if="FilterConfig?.status" class="col-9">
        <a-radio-group class="" v-model:value="status" name="radioGroup">
          <a-radio
            v-for="(title, index) of FilterConfig?.options"
            :class="title.all ? 'all-status' : ''"
            :key="`index-${index}`"
            :value="title.value"
            class="pt-2"
            >{{ title.label }}</a-radio
          >
        </a-radio-group>
      </div>
      <div class="col-3 px-0 mt-3">
        <div v-if="FilterConfig?.daterange" class="filter-colums">
          <div>Period :</div>
        </div>
      </div>
      <div class="col-9 mt-3">
        <div
          v-if="FilterConfig?.daterange"
          :class="
            windowWidth > 1540 ? 'wrap-date-rang' : 'wrap-date-rang-resize'
          "
        >
          <a-button class="btn-date-filter" @click="toggleDateFilter">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter2
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRangeCalendar($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
            @handleCloseDaterange="showDateFilter = false"
          />
        </div>
      </div>
      <div
        class="action d-flex justify-content-end bg-white align-items-center"
      >
        <a-button class="mr-3" @click="resetFilter()">Clear</a-button>
        <a-button type="primary" @click="applyFilter()">Apply Filter</a-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref } from 'vue'
import dayjs from 'dayjs'
import moment from 'moment'
import DateFilter2 from '@/module/User/component/DateFilter.vue'

export default {
  name: 'DateFilter',
  components: {
    DateFilter2,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    hasCalendar: {
      type: Boolean,
      default: false,
    },
    Today: {
      type: Boolean,
    },
    FilterConfig: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      status: null,
      list_range: [
        {
          label: 'Today',
          value: 'Today',
        },
        {
          label: 'This Week',
          value: 'This Week',
        },
        {
          label: 'Last Week',
          value: 'Last Week',
        },
        {
          label: 'This Month',
          value: 'This Month',
        },
        {
          label: 'Last Month',
          value: 'Last Month',
        },
        {
          label: 'Last 3 Weeks',
          value: 'Last 3 Weeks',
        },
        {
          label: 'Last 6 Weeks',
          value: 'Last 6 Weeks',
        },
        {
          label: 'Last 3 Months',
          value: 'Last 3 Months',
        },
        {
          label: 'Last 6 Months',
          value: 'Last 6 Months',
        },
      ],
      rangePresets: [
        {
          label: 'Last 7 Days',
          value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
          label: 'Last 14 Days',
          value: [dayjs().add(-14, 'd'), dayjs()],
        },
        {
          label: 'Last 30 Days',
          value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
          label: 'Last 90 Days',
          value: [dayjs().add(-90, 'd'), dayjs()],
        },
      ],
      select: '',
      start: '',
      end: '',
      firsttime: true,
      today: null,
      disabledDate: null,
      resetnull: null,
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      windowWidth: window.innerWidth,
    }
  },
  watch: {
    value(value) {
      if (value && this.firsttime) {
        this.firsttime = false
        this.selectRange(this.list_range[0])
      }
    },
  },
  emits: ['resetDatefilter'],
  created: function () {
    this.today = ref(dayjs())
  },
  mounted: function () {
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.$forceUpdate()
    },
    dismiss: function () {
      this.$emit('handleClose')
    },
    selectRange(type) {
      this.select = type
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    applyFilter() {
      var filter = {
        status: this.status,
        startdate: this.startdate,
        enddate: this.enddate,
      }
      this.$emit('applyFilter', filter)
    },
    resetFilter() {
      this.status = null
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
      this.showDateFilter = false
      this.$emit('resetFilter')
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
      this.showDateFilter = false
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
        this.showDateFilter = false
      }
    },
    selectRangeCalendar(range) {
      if (range.value) {
        this.showDateFilter = false
      }
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
      if (range.start && range.end) {
        this.showDateFilter = false
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/base.scss';
.filter {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 15;
  width: 600px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px 0 rgba(102, 108, 139, 0.2);
  .wrap-date-rang {
    position: relative;
  }
  .wrap-date-rang-resize {
    position: absolute;
    .datefilter {
      position: absolute;
      top: unset;
      left: unset;
      transform: translate(-18%, -50%);
    }
  }
  .action {
    padding: 8px;
    border-radius: 0px 0px 8px 8px;
  }
  .filter-colums {
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    background-color: #fff;
    padding: 8px;
    align-items: center;
  }
  .filter-colums-active {
    display: flex;
    justify-content: space-between;
    background-color: #fff6e6;
    padding: 8px;
    align-items: center;
    border-bottom: 1px solid #c9c9c9;
  }
  // .filter-selected {
  //   position: relative;
  //   min-height: calc(100vh - 700px);
  // }
  .all-status {
    position: absolute;
    bottom: 0;
    margin-bottom: 5px;
    right: 0;
    left: 0;
    padding-left: 8px;
    margin-right: 0;
    border-top: 1px solid #ecedf3;
  }
  .advanced-search {
    z-index: 20;
    width: 300px !important;
    padding: 0px 12px !important;
    .anticon {
      font-size: 18px !important;
      color: #4a4a4a !important;
    }
  }
}
.ico-filter {
  width: 20px;
  filter: invert(63%) sepia(3%) saturate(0%) hue-rotate(69deg) brightness(93%)
    contrast(93%);
  &:hover {
    filter: invert(82%) sepia(36%) saturate(7063%) hue-rotate(1deg)
      brightness(104%) contrast(99%);
  }
  &.active {
    filter: invert(82%) sepia(36%) saturate(7063%) hue-rotate(1deg)
      brightness(104%) contrast(99%);
  }
}
</style>
