<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40 addtag"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-tag bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="d-flex">
              <h4 class="pr-2">Tag Group : {{ datagroup.row_key }}</h4>
              <a-switch
                checked-children="Active"
                un-checked-children="Inactive"
                v-model:checked="datagroup.active"
              />
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <h5 class="mb-2">Group name :</h5>
              <div class="d-flex">
                <a-input
                  v-model:value="datagroup.row_key"
                  :disabled="true"
                ></a-input>
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="total-content mt-3">
                    <div class="d-flex">
                      <user-outlined class="pt-1" />
                      <div class="pl-2">User in Group</div>
                    </div>
                    <div class="font-bold text-right mt-3">
                      {{ datagroup.count ? datagroup.count : '0' }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="total-content mt-3">
                    <div class="d-flex">
                      <tags-outlined class="pt-1" />
                      <div class="pl-2">Tags</div>
                    </div>
                    <div class="font-bold text-right mt-3">
                      {{
                        datagroup.tag_types.length
                          ? datagroup.tag_types.length
                          : '0'
                      }}/{{ checkActive(datagroup.tag_types) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="datagroup.tag_types.length > 0" class="px-4">
              <h5 class="mb-2">Tag Name :</h5>
              <div
                v-for="(itemtype, index) of datagroup.tag_types"
                :key="`tableconfigindex-${index}`"
              >
                <div class="mt-3 d-flex">
                  <a-input v-model:value="itemtype.row_key"></a-input>
                  <a-switch
                    class="mt-3"
                    size="small"
                    v-model:checked="itemtype.active"
                  />
                </div>
              </div>
            </div> -->
            <div v-if="datagroup.tag_types.length > 0" class="px-4">
              <a-table
                row-key="partitionkey"
                class="fixed-head"
                :dataSource="datagroup.tag_types"
                :columns="columns"
                :pagination="false"
              >
                <template #tags="{ record }">
                  <a-input
                    class="tags"
                    :style="{
                      width: (record.row_key.length + 1) * 10 + 'px',
                    }"
                    v-model:value="record.row_key"
                  />
                </template>
                <template #count="{ record }">
                  {{ record.count ? record.count : '0' }}
                </template>
                <template #status="{ record }">
                  <a-switch
                    checked-children="Active"
                    un-checked-children="Inactive"
                    v-model:checked="record.active"
                  />
                </template>
                <template #action="{ record }">
                  <delete-outlined class="fraud" @click="deleteTag(record)" />
                </template>
              </a-table>
            </div>
          </div>
          <div class="px-4 py-3 d-flex justify-content-between">
            <a class="fraud align-self-center" @click="deleteTaggroup()"
              >Delete tag group</a
            >
            <div>
              <a-button
                class="mr-3 btn-create"
                type="blue-2"
                @click="dismiss(true)"
              >
                Cancel
              </a-button>
              <a-button
                type="primary"
                class="btn-search btn-create"
                :disabled="!tag_name"
                @click="saveEditTag()"
              >
                Save
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    title="Add Users to Tag"
    class="modal-usertags"
    :visible="save_modal"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="border-t-2 p-4">
      <div>
        <label class="text-save-modal"> “Area” </label>
        was created as a new tag group
      </div>
      <div class="mt-3">
        <label class="text-save-modal">11 Tags </label>
        were added successfully
      </div>
      <div class="mt-3">
        <label class="text-save-modal">1 Tag </label>
        was failed to add
      </div>
    </div>
  </a-modal>
</template>

<script>
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import _ from 'lodash'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    datalogs: {
      type: Array,
      default: () => [],
    },
    itemgroup: {
      type: Array,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['getTagTypes', 'getTagGroup'],
  data: function () {
    return {
      isstatus: false,
      save_modal: false,
      tag_new: null,
      tag_name: 'Male',
      select_tag: 'Gender',
      value2: null,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      levelname: '',
      description: '',
      earnpoint: '',
      benefits: '',
      isErrorName: false,
      datagroup: [],
      datatype: [],
      columns: [
        {
          title: 'Tags',
          dataIndex: 'tags',
          slots: { customRender: 'tags' },
          key: 'tags',
          width: 20,
          align: 'left',
        },
        {
          title: 'User in Tags',
          dataIndex: 'count',
          slots: { customRender: 'count' },
          key: 'count',
          width: 100,
          align: 'right',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 20,
          align: 'lett',
        },
        {
          title: '',
          dataIndex: 'action',
          slots: { customRender: 'action' },
          key: 'action',
          width: 20,
          align: 'lett',
        },
      ],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.datagroup = _.cloneDeep(this.itemgroup)
      }
    },
  },
  mounted() {},
  created: function () {},
  methods: {
    checkActive(tagtype) {
      var totalactive = null
      totalactive = tagtype.filter(x => x.active)
      return totalactive.length
    },
    deleteTag(record) {
      console.log(record)
    },
    deleteTaggroup() {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you sure you want to delete this tag group?',
        content:
          'This action cannot be undone. Deleting this tag will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {},
        onCancel: () => {},
      })
    },
    saveEditTag() {
      this.datagroup.tag_types.forEach((x, idx) => {
        if (
          x.row_key != this.itemgroup.tag_types[idx].row_key ||
          x.active != this.itemgroup.tag_types[idx].active
        ) {
          var datatagtypes = {
            app_id: ConfigManager.getAppId(),
            group_name: this.datagroup.row_key,
            name: x.row_key,
            active: x.active,
          }
          this.PostTagTypes(datatagtypes)
        }
      })
      // var datatagtypes = {
      //   app_id: ConfigManager.getAppId(),
      //   group_name: this.datagroup.row_key,
      //   name: 'type',
      //   active: 'active',
      // }
      // this.PostTagTypes(datatagtypes)
    },
    PostTagTypes(datatagtypes) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTagTypes(datatagtypes)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('handleClose')
                this.$emit('getTagTypes')
                this.$emit('getTagGroup')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    dismiss: function () {
      this.$emit('handleClose')
    },
    saveAddTag() {
      this.$emit('handleClose')
      this.save_modal = true
    },
    handleCancel() {
      this.save_modal = false
    },
  },
}
</script>
<style lang="scss">
.w-log-tag {
  width: 40rem;
  .total-content {
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    padding: 8px 16px 8px 16px;
  }
  .tags {
    font-size: 12px;
    line-height: 20px;
    height: auto;
    padding: 0px 7px;
    background: #fafafa;
  }
}
</style>
