<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-ticket bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">
              Ticket ID : {{ dataDetailAll.job_id }}
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <a-form
              layout="vertical"
              :model="formState"
              v-bind="formItemLayout"
            >
              <div v-if="dataDetailAll.user_id" class="px-4 pb-3 b-bot">
                <div class="mt-2 mb-2">User Information</div>
                <a-collapse :bordered="false" expand-icon-position="right">
                  <template #expandIcon="{ isActive }">
                    <down-outlined :rotate="isActive ? 180 : 0" />
                  </template>
                  <a-collapse-panel key="1">
                    <template #header>
                      <div class="row pl-3 fs-12">
                        <div class="col-2 p-0 text-h-content">
                          <div>User ID :</div>
                        </div>
                        <div class="col-4">
                          <div v-if="dataDetailAll.user_id.length < 25">
                            {{ dataDetailAll.user_id }}
                          </div>
                          <a-tooltip v-else placement="bottom">
                            <template #title>
                              <span>{{ dataDetailAll.user_id }}</span>
                            </template>
                            <span
                              >{{
                                dataDetailAll.user_id.substring(0, 25)
                              }}...</span
                            >
                          </a-tooltip>
                        </div>
                        <div class="col-2 p-0 text-h-content">
                          <div>Application :</div>
                        </div>
                        <div class="col-4">
                          <div>{{ appName }}</div>
                        </div>
                      </div>
                    </template>
                    <div class="px-4 fs-12 lh-26">
                      <div class="row">
                        <div class="col-2 p-0 text-h-content">
                          <div>Name :</div>
                          <div>Contact Number:</div>
                        </div>
                        <div class="col-4">
                          <div>
                            {{ dataUserInfo.user_info.first_name }}
                            {{ dataUserInfo.user_info.last_name }}
                          </div>
                          <div>{{ dataUserInfo.user_info.contact_number }}</div>
                        </div>
                        <div class="col-2 p-0 text-h-content">
                          <div>Birthday :</div>
                          <div>Email :</div>
                        </div>
                        <div class="col-4">
                          <div>{{ dataUserInfo.user_info.birthday }}</div>
                          <div>{{ dataUserInfo.user_info.email }}</div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
              <div class="px-4 pb-3">
                <div class="mt-2 mb-2">Ticket Information</div>
                <div class="bg-blue-l">
                  <div class="row p-2 fs-12 lh-26">
                    <div class="col-6">
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>Subject :</div>
                        </div>
                        <div class="col-8">
                          <div>
                            {{
                              dataDetailAll.subject
                                ? dataDetailAll.subject
                                : '-'
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>Topic type :</div>
                        </div>
                        <div class="col-8">
                          <div>
                            {{
                              dataDetailAll.topic_type
                                ? dataDetailAll.topic_type
                                : '-'
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>Topic detail :</div>
                        </div>
                        <div class="col-8">
                          <div>
                            {{
                              dataDetailAll.topic_detail
                                ? dataDetailAll.topic_detail
                                : '-'
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>Campaign :</div>
                        </div>
                        <div class="col-8">
                          <div v-if="dataDetailAll.campaign_name">
                            <div v-if="dataDetailAll.campaign_name.length < 35">
                              {{ dataDetailAll.campaign_name.substring(0, 35) }}
                            </div>
                            <a-tooltip v-else placement="bottom">
                              <template #title>
                                <span>{{ dataDetailAll.campaign_name }}</span>
                              </template>
                              <span
                                >{{
                                  dataDetailAll.campaign_name.substring(0, 35)
                                }}...</span
                              >
                            </a-tooltip>
                          </div>
                          <div v-else>-</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>Agency :</div>
                        </div>
                        <div class="col-8">
                          <div>
                            {{
                              dataDetailAll.agency_name
                                ? dataDetailAll.agency_name
                                : '-'
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>VSTS:</div>
                        </div>
                        <div class="col-8">
                          <a
                            class="text-vsts"
                            @click="gotovsts(dataDetailAll.vsts_id)"
                          >
                            {{
                              dataDetailAll.vsts_name
                                ? dataDetailAll.vsts_name
                                : '-'
                            }}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row">
                        <div class="col-4 pr-0 text-h-content">
                          <div>Status :</div>
                          <div>Channel :</div>
                          <div>Created By :</div>
                          <div>Created Date :</div>
                          <div>Last Updated :</div>
                          <div>Expect date :</div>
                        </div>
                        <div class="col-8">
                          <div>
                            <template v-for="(item, index) in statusprocess">
                              <a-tag
                                v-if="dataDetailAll.ticket_status == item.name"
                                :key="index"
                                :color="
                                  item.color == 'default' ? null : item.color
                                "
                                class="tag-status"
                              >
                                <a-badge
                                  :status="item.color"
                                  :text="dataDetailAll.ticket_status"
                                />
                              </a-tag>
                            </template>
                          </div>
                          <div>{{ dataDetailAll.channel_name }}</div>
                          <div>{{ dataDetailAll.create_by }}</div>
                          <div>
                            {{
                              dataDetailAll.create_date
                                ? formatDate(
                                    dataDetailAll.create_date,
                                    'DD MMM YYYY - HH:mm',
                                  )
                                : '-'
                            }}
                          </div>
                          <div>
                            {{
                              dataDetailAll.last_update
                                ? formatDate(
                                    dataDetailAll.last_update,
                                    'DD MMM YYYY - HH:mm',
                                  )
                                : '-'
                            }}
                          </div>
                          <div>
                            {{
                              dataDetailAll.expect_date
                                ? formatDate(
                                    dataDetailAll.expect_date,
                                    'DD MMM YYYY',
                                  )
                                : '-'
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="
                  dataDetailAll.ticket_status != 'Close'
                    ? 'px-4 pb-3 b-bot'
                    : 'px-4 pb-3'
                "
              >
                <div class="mt-2 mb-2">Detail</div>
                <a-collapse
                  v-for="(item, index) in dataDetailAll.center_job_models"
                  :key="item"
                  v-model:activeKey="activeKey"
                  :bordered="false"
                  class="mb-2"
                  expand-icon-position="right"
                >
                  <template #expandIcon="{ isActive }">
                    <down-outlined :rotate="isActive ? 180 : 0" />
                  </template>
                  <a-collapse-panel :key="index">
                    <template #header>
                      <div class="row fs-12">
                        <div class="col-2 p-0 text-h-content">
                          <div>Updated Date :</div>
                        </div>
                        <div class="col-4">
                          <div>
                            {{
                              item.create_date
                                ? formatDate(
                                    item.create_date,
                                    'DD MMM YYYY - HH:mm',
                                  )
                                : '-'
                            }}
                          </div>
                        </div>
                        <div class="col-2 p-0 text-h-content">
                          <div>Priority :</div>
                        </div>
                        <div class="col-4">
                          <template
                            v-for="(itemstatus, indexstatus) in statuspriority"
                          >
                            <a-tag
                              v-if="item.priority == itemstatus.name"
                              :key="indexstatus"
                              :color="
                                itemstatus.color == 'default'
                                  ? null
                                  : itemstatus.color
                              "
                              class="tag-status"
                            >
                              <a-badge
                                :status="itemstatus.color"
                                :text="item.priority"
                              />
                            </a-tag>
                          </template>
                        </div>
                      </div>
                    </template>
                    <div class="px-2 fs-12 lh-26">
                      <div class="row">
                        <div class="col-2 p-0 text-h-content">
                          <div>Remark :</div>
                        </div>
                        <div class="col-10">
                          <div>
                            {{ item.remark ? item.remark : '-' }}
                          </div>
                        </div>
                        <div
                          v-if="item.files.length > 0"
                          class="col-2 p-0 text-h-content"
                        >
                          <div>Image :</div>
                        </div>
                        <div
                          v-if="item.files.length > 0"
                          class="col-10 d-flex flex-wrap"
                        >
                          <div
                            v-for="(img, indeximg) in item.files"
                            :key="indeximg"
                          >
                            <div class="ml-2">
                              <a-image :width="120" :src="img" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </div>
              <div
                v-if="
                  checkPermission() && dataDetailAll.ticket_status != 'Close'
                "
                class="px-4"
              >
                <div class="mt-3">Discussion</div>
                <a-form-item
                  class="mt-3"
                  label="Remark"
                  :rules="[
                    {
                      required: true,
                      message: 'Please input your username!',
                    },
                  ]"
                >
                  <a-textarea
                    v-model:value="formState.remark"
                    :auto-size="{ minRows: 3, maxRows: 3 }"
                  />
                </a-form-item>
                <div class="row">
                  <div class="col-6">
                    <a-form-item
                      label="Status"
                      :rules="[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]"
                    >
                      <a-select
                        v-model:value="formState.status"
                        placeholder="Search Status"
                        :options="options_status"
                      ></a-select>
                    </a-form-item>
                  </div>
                  <div class="col-6">
                    <a-form-item
                      label="Priority"
                      :rules="[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]"
                    >
                      <a-select
                        v-model:value="formState.priority"
                        placeholder="Search Priority"
                        :options="options_priority"
                      ></a-select>
                    </a-form-item>
                  </div>
                </div>
              </div>
            </a-form>
            <div
              v-if="checkPermission() && dataDetailAll.ticket_status != 'Close'"
              class="px-4"
            >
              <div class="mb-2">Upload Images (Maximum 5 images)</div>
              <a-upload
                v-model:file-list="fileList"
                class="uploadimg"
                :beforeUpload="[
                  function () {
                    false
                  },
                ]"
                list-type="picture-card"
                :previewIcon="false"
              >
                <div v-if="fileList.length < 5">
                  <plus-outlined />
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </div>
          </div>
          <div class="flex-none px-4 py-3 right">
            <a-button
              :class="
                checkPermission() && dataDetailAll.ticket_status != 'Close'
                  ? 'btn-create'
                  : ''
              "
              type="default"
              @click="dismiss(true)"
            >
              Close
            </a-button>
            <a-button
              v-if="checkPermission() && dataDetailAll.ticket_status != 'Close'"
              :class="
                checkPermission() && dataDetailAll.ticket_status != 'Close'
                  ? 'ml-3 btn-create'
                  : ''
              "
              :disabled="
                !formState.priority || !formState.remark || !formState.status
              "
              type="primary"
              @click="PostUpdateJoblist()"
            >
              Update
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
    dataDetail: {
      type: Object,
    },
    dataDetailAll: {
      type: Object,
    },
    dataUserInfo: {
      type: Array,
    },
    appName: {
      type: String,
    },
  },
  computed: {},
  data: function () {
    return {
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      isTimezone: config.businessRule.timeZone.hours,
      datasetFeatures: JSON.parse(localStorage.getItem('setFeatures')),
      permission: JSON.parse(
        localStorage.getItem('permissionappforcallcenter'),
      ),
      statuspriority: [
        {
          id: 1,
          name: 'Medium',
          text: 'Medium',
          color: 'purple',
        },
        {
          id: 2,
          name: 'Low',
          text: 'Low',
          color: 'green',
        },
        {
          id: 3,
          name: 'High',
          text: 'High',
          color: 'pink',
        },
        {
          id: 4,
          name: 'Critical',
          text: 'Critical',
          color: 'red',
        },
      ],
      statusprocess: [
        {
          id: 1,
          name: 'Open',
          text: 'Open',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Pending',
          text: 'Pending',
          color: 'yellow',
        },
        {
          id: 3,
          name: 'Cancel',
          text: 'Cancel',
          color: 'red',
        },
        {
          id: 4,
          name: 'Close',
          text: 'Close',
          color: 'default',
        },
      ],
      datatest: {},
      remark: '',
      status: '',
      options_status: [
        {
          label: 'Open',
          value: 'Open',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
        {
          label: 'Cancel',
          value: 'Cancel',
        },
        {
          label: 'Close',
          value: 'Close',
        },
      ],
      priority: '',
      options_priority: [
        {
          label: 'Low',
          value: 'Low',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'High',
          value: 'High',
        },
        {
          label: 'Critical',
          value: 'Critical',
        },
      ],
      activeKey: null,
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.formState = {}
      }
    },
  },
  mounted() {},
  created: function () {},
  methods: {
    gotovsts(vsts_id) {
      let link =
        'https://dev.azure.com/buzzebees/Buzzebees/_workitems/edit/' + vsts_id
      window.open(link)
    },
    setDataDetail(data, appid) {
      this.job_id = data.job_id
      this.user_id = data.user_id
      this.appid = appid
      this.activeKey = data.center_job_models
        ? data.center_job_models.length - 1
        : null
    },
    checkPermission() {
      var check = this.permission.find(
        x => x.application_id == this.dataDetail.app_id,
      )
      console.log(check)
      if (check.features.find(y => y.name == 'Job-Discussion-Edit')) {
        return true
      } else {
        return false
      }
    },
    PostUpdateJoblist() {
      this.$store.commit('setLoading', true)
      var AppId = this.appid
      var fromData = {}
      fromData = {
        remark: this.formState?.remark || null,
        status: this.formState?.status || null,
        priority: this.formState?.priority || null,
        files:
          this.fileList.length > 0
            ? this.fileList?.map(x => x.originFileObj)
            : null,
        job_id: this.job_id || null,
        problem_type_id: this.dataDetailAll.topic_type_id || null,
        user_id: this.user_id || null,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUpdateJoblist(AppId, fromData)
          .then(res => {
            this.$emit('handleClose')
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.formState = {}
                this.fileList = []
                this.$emit('clicksearch')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    dismiss: function () {
      this.fileList = []
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log-ticket {
  width: 45rem !important;
  .ant-collapse > .ant-collapse-item {
    border-bottom: unset !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: block !important;
    padding-bottom: 6px !important;
    padding-top: 6px !important;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px 16px 0px !important;
  }
  .text-h-content {
    text-align: right;
    color: #aaa;
  }
  .b-bot {
    border-bottom: 3px solid #ecedf3;
  }
  .bg-blue-l {
    background-color: #f3f6f9;
    border-radius: 6px;
  }
  .lh-26 {
    line-height: 26px;
  }
}
.text-vsts {
  color: var(--blue-blue-6) !important;
}
</style>
