export function Loadusertaglog(obj) {
  this.appid = obj.appid
  this.id = obj.id
  this.title = obj.title
  this.description = obj.description
  // this.total_rows = obj.savelist_versions
  this.savelist_versions = obj.savelist_versions
  this.created_date = obj.created_date
  this.criteria = obj.criteria
  // this.version = obj.savelist_versions
}
