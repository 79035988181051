const apiConfig = {
  b2cScopes: [
    'https://stgaadb2cbuzzebees.onmicrosoft.com/c5cf674e-f6e7-4add-9cf2-6436a21c90b8/Access',
  ],
}
const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    editProfile: 'B2C_1A_PROFILE_EDIT',
    changePassword: 'B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
    },
    editProfile: {
      authority:
        'https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT',
    },
    changePassword: {
      authority:
        'https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
    },
  },

  // names: {
  //     signUpSignIn: "B2C_1_susi",
  //     editProfile: "B2C_1_pe",
  // },
  // authorities: {
  //     signUpSignIn: {
  //         authority:
  //             "https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1_susi",
  //     },
  //     editProfile: {
  //         authority:
  //             "https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1_pe",
  //     },
  // },
  authorityDomain: 'stgaadb2cbuzzebees.b2clogin.com',
}
export const config = {
  debug: false, //enable console.log

  // ------------- Staging Settings ----------------
  api: 'stg',
  adB2CUrl:
    'https://stg-api-active-directory-b2c-wrapper.azurewebsites.net/ppperm',
  bzbUrl: 'https://stg.buzzebees.com',
  bzbBlobUrl: 'https://stgproductization.blob.core.windows.net',
  usermanagementAPI: 'https://api-usermanagement.buzzebees-uat.com',
  usermgrUrl: 'https://stg-bzbs-usermgr-userlist.azurewebsites.net',
  bzbsModuleUrl: 'https://stg-module-app.azurewebsites.net',
  apiShoppingCartUrl: 'https://stgservice.buzzebees.com/modules/shoppingcart',
  bzbsShoppingCartUrl: 'https://stg-web-shoppingcart.azurewebsites.net',
  bzbsBlobUrl: 'https://stgproductplatformext.blob.core.windows.net',
  miscUrl: 'https://stgmisc.buzzebees.com',
  MKTengineUrl: 'https://stg-web-marketingengine.azurewebsites.net',
  bzbUrlCampaignManagement: 'https://campaign-management.buzzebees-uat.com',
  CampaignManagementUrl: 'https://campaign-management.buzzebees-uat.com',
  bzbUrlRuleBasedEngine: 'https://rule-based-engine.buzzebees-uat.com',
  UsermanagementUrl: 'https://end-users-management.buzzebees-uat.com',
  UsermanualUrl: 'https://stg-wordpress-sdkdocument.azurewebsites.net',
  apiAnnouncement: 'https://stg-api-product-announcement.azurewebsites.net',
  bzbsGaId: '',
  liffId: '',
  // ------------------ End Settings ---------------------
  fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
  fbPermissions: ['email'],
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  msalConfig: {
    auth: {
      clientId: '16c896a3-9849-48e1-a2cf-8e9a017295a6',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: window.location.origin + '/redirectms',
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  },
  b2cPolicies: b2cPolicies,
  loginRequest: {
    scopes: ['openid', ...apiConfig.b2cScopes],
    // prompt: 'select_account',
  },
  tokenRequest: {
    scopes: [...apiConfig.b2cScopes],
    forceRefresh: false,
  },
  businessRule: {
    webFor: {
      TH: true, //Thailand
      VN: false, //Vietnam
    },
    ticket: {
      problem_type_id: 107,
      channel: 4,
    },
    timeZone: {
      hours: 7, //Th
    },
  },
}
