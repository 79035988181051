<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-privacy bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex">
              {{ dataDetails.user_id }}
              <!-- <span v-if="dataDetails.first_name || dataDetails.last_name"
                >:
              </span>
              <span
                v-if="dataDetails.first_name || dataDetails.last_name"
                class="text-id"
                >{{ dataDetails.first_name }} {{ dataDetails.last_name }}</span
              > -->
              <div
                class="ml-1"
                v-for="(item, index) in status"
                :key="`index-${index}`"
              >
                <a-tag
                  v-if="dataDetails.document_status == item.name"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge
                    :status="item.color"
                    :text="textStatus(dataDetails.document_status)"
                  />
                </a-tag>
              </div>
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="bg-con">
              <div class="d-flex">
                <div class="mr-2">Create Date :</div>
                <div>
                  {{
                    formatDate(
                      dataDetails.created_date,
                      'DD/MMM/YYYY',
                      this.isTimezone * -1,
                    )
                  }}
                </div>
              </div>
              <div class="d-flex">
                <div class="mr-2">Created By :</div>
                {{
                  dataDetails.auditable_entity
                    ? dataDetails.auditable_entity.name
                    : '-'
                }}
              </div>
            </div>
            <!-- <div class="px-4">
              <a-row class="my-3">
                <a-col :span="4">
                  <div class="h-detail">Data List :</div>
                </a-col>
                <a-col :span="20">
                  <div class="font-semibold">{{ datalist }}</div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="4">
                  <div class="h-detail">Reason :</div>
                </a-col>
                <a-col :span="20">
                  <div class="font-semibold">
                    {{ dataDetails.new_data.reason }}
                  </div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="8">
                  <div class="h-detail">Status :</div>
                </a-col>
                <a-col :span="8">
                  <div class="h-detail">Create Date :</div>
                  <div>
                    {{
                      formatDate(dataDetails.created_date, 'DD/MMM/YYYY', -7)
                    }}
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="h-detail">Created By :</div>
                  <div>
                    {{
                      dataDetails.auditable_entity
                        ? dataDetails.auditable_entity.name
                        : '-'
                    }}
                  </div>
                </a-col>
              </a-row>
            </div> -->
            <!-- <hr />
            <a-row class="px-4" style="background-color: #f3f6f9">
              <a-col class="my-3" :span="12">
                <div class="mt-4 h-detail">Current Data Version</div>
              </a-col>
              <a-col class="my-3" :span="12">
                <div class="mt-4 h-detail">Request Data Version</div>
              </a-col>
            </a-row>
            <hr /> -->
            <div class="d-flex">
              <h4 class="mx-15">Request Data version</h4>
              <!-- <a-badge
                :count="'Update : ' + requesData"
                :overflow-count="999999"
                :class="type == 'default' ? null : 'count-' + 'list'"
              /> -->
            </div>
            <div class="mx-15 mt-3">
              <div class="bg-con-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.term_and_condition !=
                    dataDetails.new_data.term_and_condition
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.term_and_condition !=
                    dataDetails.new_data.term_and_condition
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Term & Condition</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.term_and_condition !=
                        dataDetails.new_data.term_and_condition
                      "
                      class="fade-color"
                    >
                      {{
                        dataDetails.original_data.term_and_condition
                          ? dataDetails.original_data.term_and_condition
                          : '-'
                      }}
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.term_and_condition !=
                        dataDetails.new_data.term_and_condition
                      "
                      class="pt-1 mx-4 fade-color"
                    />
                    <div>
                      {{
                        dataDetails.new_data.term_and_condition == -1
                          ? 'Unconsent'
                          : dataDetails.new_data.term_and_condition
                          ? dataDetails.new_data.term_and_condition
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-l-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.data_privacy !=
                    dataDetails.new_data.data_privacy
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.data_privacy !=
                    dataDetails.new_data.data_privacy
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Data Privacy Version</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.data_privacy !=
                        dataDetails.new_data.data_privacy
                      "
                      class="fade-color"
                    >
                      {{
                        dataDetails.original_data.data_privacy
                          ? dataDetails.original_data.data_privacy
                          : '-'
                      }}
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.data_privacy !=
                        dataDetails.new_data.data_privacy
                      "
                      class="pt-1 mx-4 fade-color"
                    />
                    <div>
                      {{
                        dataDetails.new_data.data_privacy == -1
                          ? 'Unconsent'
                          : dataDetails.new_data.data_privacy
                          ? dataDetails.new_data.data_privacy
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.consent_age !=
                    dataDetails.new_data.consent_age
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.consent_age !=
                    dataDetails.new_data.consent_age
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Consent Age</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.consent_age !=
                        dataDetails.new_data.consent_age
                      "
                      class="fade-color"
                    >
                      {{
                        dataDetails.original_data.consent_age
                          ? dataDetails.original_data.consent_age
                          : '-'
                      }}
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.consent_age !=
                        dataDetails.new_data.consent_age
                      "
                      class="pt-1 mx-4 fade-color"
                    />
                    <div>
                      {{
                        dataDetails.new_data.consent_age == -1
                          ? 'Unconsent'
                          : dataDetails.new_data.consent_age
                          ? dataDetails.new_data.consent_age
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-l-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.marketing_option !=
                    dataDetails.new_data.marketing_option
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.marketing_option !=
                    dataDetails.new_data.marketing_option
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Marketing Version</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.marketing_option !=
                        dataDetails.new_data.marketing_option
                      "
                      class="fade-color"
                    >
                      {{
                        dataDetails.original_data.marketing_option
                          ? dataDetails.original_data.marketing_option
                          : '-'
                      }}
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.marketing_option !=
                        dataDetails.new_data.marketing_option
                      "
                      class="pt-1 mx-4 fade-color"
                    />
                    <div>
                      {{
                        dataDetails.new_data.marketing_option == -1
                          ? 'Unconsent'
                          : dataDetails.new_data.marketing_option
                          ? dataDetails.new_data.marketing_option
                          : '-'
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.email_marketing !=
                    dataDetails.new_data.email_marketing
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.email_marketing !=
                    dataDetails.new_data.email_marketing
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Email Marketing</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.email_marketing !=
                        dataDetails.new_data.email_marketing
                      "
                    >
                      <div
                        class="fade-color"
                        v-if="dataDetails.original_data.email_marketing"
                      >
                        Yes
                      </div>
                      <div
                        class="fade-color"
                        v-if="!dataDetails.original_data.email_marketing"
                      >
                        No
                      </div>
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.email_marketing !=
                        dataDetails.new_data.email_marketing
                      "
                      class="pt-1 mx-4 fade-color"
                    />

                    <div
                      :class="
                        dataDetails.original_data.email_marketing !=
                        dataDetails.new_data.email_marketing
                          ? 'point-plus'
                          : ''
                      "
                      v-if="dataDetails.new_data.email_marketing"
                    >
                      Yes
                    </div>
                    <div
                      :class="
                        dataDetails.original_data.email_marketing !=
                        dataDetails.new_data.email_marketing
                          ? 'point-minus'
                          : ''
                      "
                      v-if="!dataDetails.new_data.email_marketing"
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-l-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.line_marketing !=
                    dataDetails.new_data.line_marketing
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.line_marketing !=
                    dataDetails.new_data.line_marketing
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Line Marketing</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.line_marketing !=
                        dataDetails.new_data.line_marketing
                      "
                    >
                      <div
                        class="fade-color"
                        v-if="dataDetails.original_data.line_marketing"
                      >
                        Yes
                      </div>
                      <div
                        class="fade-color"
                        v-if="!dataDetails.original_data.line_marketing"
                      >
                        No
                      </div>
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.line_marketing !=
                        dataDetails.new_data.line_marketing
                      "
                      class="pt-1 mx-4 fade-color"
                    />

                    <div
                      :class="
                        dataDetails.original_data.line_marketing !=
                        dataDetails.new_data.line_marketing
                          ? 'point-plus'
                          : ''
                      "
                      v-if="dataDetails.new_data.line_marketing"
                    >
                      Yes
                    </div>
                    <div
                      :class="
                        dataDetails.original_data.line_marketing !=
                        dataDetails.new_data.line_marketing
                          ? 'point-minus'
                          : ''
                      "
                      v-if="!dataDetails.new_data.line_marketing"
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.notification_marketing !=
                    dataDetails.new_data.notification_marketing
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.notification_marketing !=
                    dataDetails.new_data.notification_marketing
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>Notification Marketing</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.notification_marketing !=
                        dataDetails.new_data.notification_marketing
                      "
                    >
                      <div
                        class="fade-color"
                        v-if="dataDetails.original_data.notification_marketing"
                      >
                        Yes
                      </div>
                      <div
                        class="fade-color"
                        v-if="!dataDetails.original_data.notification_marketing"
                      >
                        No
                      </div>
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.notification_marketing !=
                        dataDetails.new_data.notification_marketing
                      "
                      class="pt-1 mx-4 fade-color"
                    />

                    <div
                      :class="
                        dataDetails.original_data.notification_marketing !=
                        dataDetails.new_data.notification_marketing
                          ? 'point-plus'
                          : ''
                      "
                      v-if="dataDetails.new_data.notification_marketing"
                    >
                      Yes
                    </div>
                    <div
                      :class="
                        dataDetails.original_data.notification_marketing !=
                        dataDetails.new_data.notification_marketing
                          ? 'point-minus'
                          : ''
                      "
                      v-if="!dataDetails.new_data.notification_marketing"
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-con-l-gray">
                <a-tag
                  v-if="
                    dataDetails.original_data.sms_marketing !=
                    dataDetails.new_data.sms_marketing
                  "
                  color="blue"
                  class="tag-status"
                >
                  <a-badge status="blue" text="Request Update" />
                </a-tag>
                <div
                  :class="
                    dataDetails.original_data.sms_marketing !=
                    dataDetails.new_data.sms_marketing
                      ? 'd-flex justify-content-between mt-2'
                      : 'd-flex justify-content-between'
                  "
                >
                  <div>SMS Marketing</div>
                  <div class="d-flex">
                    <div
                      v-if="
                        dataDetails.original_data.sms_marketing !=
                        dataDetails.new_data.sms_marketing
                      "
                    >
                      <div
                        class="fade-color"
                        v-if="dataDetails.original_data.sms_marketing"
                      >
                        Yes
                      </div>
                      <div
                        class="fade-color"
                        v-if="!dataDetails.original_data.sms_marketing"
                      >
                        No
                      </div>
                    </div>
                    <arrow-right-outlined
                      v-if="
                        dataDetails.original_data.sms_marketing !=
                        dataDetails.new_data.sms_marketing
                      "
                      class="pt-1 mx-4 fade-color"
                    />

                    <div
                      :class="
                        dataDetails.original_data.sms_marketing !=
                        dataDetails.new_data.sms_marketing
                          ? 'point-plus'
                          : ''
                      "
                      v-if="dataDetails.new_data.sms_marketing"
                    >
                      Yes
                    </div>
                    <div
                      :class="
                        dataDetails.original_data.sms_marketing !=
                        dataDetails.new_data.sms_marketing
                          ? 'point-minus'
                          : ''
                      "
                      v-if="!dataDetails.new_data.sms_marketing"
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="px-4"> -->
            <!-- <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">Data Privacy Version</div>
                  <div>
                    {{
                      dataDetails.original_data.data_privacy
                        ? dataDetails.original_data.data_privacy
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="pd-t-21">
                    <div>
                      {{
                        dataDetails.new_data.data_privacy
                          ? dataDetails.new_data.data_privacy
                          : '-'
                      }}
                    </div>
                  </div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">Consent Age</div>
                  <div>
                    {{
                      dataDetails.original_data.consent_age
                        ? dataDetails.original_data.consent_age
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="pd-t-21">
                    <div>
                      {{
                        dataDetails.new_data.consent_age
                          ? dataDetails.new_data.consent_age
                          : '-'
                      }}
                    </div>
                  </div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">Marketing Version</div>
                  <div>
                    {{
                      dataDetails.original_data.marketing_option
                        ? dataDetails.original_data.marketing_option
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="pd-t-21">
                    <div>
                      {{
                        dataDetails.new_data.marketing_option
                          ? dataDetails.new_data.marketing_option
                          : '-'
                      }}
                    </div>
                  </div>
                </a-col>
              </a-row>
              <hr /> -->
            <!-- <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">Email Marketing</div>
                  <div v-if="dataDetails.original_data.email_marketing">
                    Yes
                  </div>
                  <div v-if="!dataDetails.original_data.email_marketing">
                    No
                  </div>
                </a-col>
                <a-col :span="12">
                  <div
                    class="pd-t-21"
                    v-if="dataDetails.new_data.email_marketing"
                  >
                    Yes
                  </div>
                  <div
                    class="pd-t-21"
                    v-if="!dataDetails.new_data.email_marketing"
                  >
                    No
                  </div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">Line Marketing</div>
                  <div v-if="dataDetails.original_data.line_marketing">Yes</div>
                  <div v-if="!dataDetails.original_data.line_marketing">No</div>
                </a-col>
                <a-col :span="12">
                  <div
                    class="pd-t-21"
                    v-if="dataDetails.new_data.line_marketing"
                  >
                    Yes
                  </div>
                  <div
                    class="pd-t-21"
                    v-if="!dataDetails.new_data.line_marketing"
                  >
                    No
                  </div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">Notification Marketing</div>
                  <div v-if="dataDetails.original_data.notification_marketing">
                    Yes
                  </div>
                  <div v-if="!dataDetails.original_data.notification_marketing">
                    No
                  </div>
                </a-col>
                <a-col :span="12">
                  <div
                    class="pd-t-21"
                    v-if="dataDetails.new_data.notification_marketing"
                  >
                    Yes
                  </div>
                  <div
                    class="pd-t-21"
                    v-if="!dataDetails.new_data.notification_marketing"
                  >
                    No
                  </div>
                </a-col>
              </a-row>
              <hr />
              <a-row class="my-3">
                <a-col :span="12">
                  <div class="font-semibold">SMS Marketing</div>
                  <div v-if="dataDetails.original_data.sms_marketing">Yes</div>
                  <div v-if="!dataDetails.original_data.sms_marketing">No</div>
                </a-col>
                <a-col :span="12">
                  <div
                    class="pd-t-21"
                    v-if="dataDetails.new_data.sms_marketing"
                  >
                    Yes
                  </div>
                  <div
                    class="pd-t-21"
                    v-if="!dataDetails.new_data.sms_marketing"
                  >
                    No
                  </div>
                </a-col>
              </a-row>
              <hr /> -->
            <!-- </div> -->
          </div>
          <div
            v-if="dataDetails.document_status == 'Waiting' && managepermission"
            class="p-3"
          >
            <div>Remark :</div>
            <a-textarea
              v-model:value="remark"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
            <div class="mt-1 h-detail">
              * Rejection require remark, approval is optional.
            </div>
          </div>
          <div
            v-if="
              dataDetails.document_status == 'Approved' ||
              dataDetails.document_status == 'Rejected'
            "
          >
            <div class="bg-con">
              <a-row>
                <a-col :span="6">
                  <div class="h-detail">Remark :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataDetails.action_remarks
                        ? dataDetails.action_remarks
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="h-detail">Responded date :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataDetails.action_date
                        ? formatDate(
                            dataDetails.action_date,
                            'DD/MMM/YYYY',
                            this.isTimezone * -1,
                          )
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="6">
                  <div class="h-detail">Respondent :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{
                      dataDetails.action_by ? dataDetails.action_by.name : '-'
                    }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
          <div class="flex justify-between px-4 py-3">
            <div
              v-if="
                managepermission && dataDetails.document_status == 'Waiting'
              "
              class=""
            >
              <a-button
                @click="Reject()"
                type="danger"
                :disabled="remark.length < 1"
                class="btn-create mr-3"
                >Reject</a-button
              >
            </div>
            <div
              :class="
                !managepermission ||
                dataDetails.document_status == 'Approved' ||
                dataDetails.document_status == 'Rejected'
                  ? 'flex-auto'
                  : 'flex'
              "
            >
              <a-button
                type="primary-2"
                :class="
                  !managepermission ||
                  dataDetails.document_status == 'Approved' ||
                  dataDetails.document_status == 'Rejected'
                    ? 'w100 btn-create'
                    : 'mr-3 btn-create'
                "
                @click="dismiss(true)"
              >
                Close
              </a-button>
              <a-button
                v-if="
                  managepermission && dataDetails.document_status == 'Waiting'
                "
                type="success"
                class="btn-create"
                @click="Approve()"
              >
                Approve
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    class="modal-set-expire"
    :visible="modalReject"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="h-modal">
      <div>Reject reason</div>
    </div>
    <div class="border-t-2">
      <div class="font-modal-delete px-4 mb-5">
        <div class="pt-4">Reason</div>
        <a-textarea
          v-model:value="value2"
          :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </div>
      <div class="p-4 border-t-2 modal-footer">
        <a-button type="primary" @click="handleCancel()">Save</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
// helper
import Util from '@/helper/Utility'
// import moment from 'moment'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import _ from 'lodash'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import { config } from '@/config/bzbsconfig.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    requesData: {
      type: Number,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataDetails: {
      type: Array,
    },
    datalist: {
      type: Array,
    },
    managepermission: {
      type: Boolean,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['clicksearchprivacy'],
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      modalReject: false,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      remark: '',
      isErrorName: false,
      status: [
        {
          id: 1,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Rejected',
          color: 'red',
        },
        {
          id: 3,
          name: 'Approved',
          color: 'green',
        },
      ],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {},
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Waiting':
          return 'Waiting'
        case 'Approved':
          return 'Approved'
        case 'Rejected':
          return 'Rejected'
      }
    },
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    onChange() {
      this.setdisable = false
      if (this.levelname) {
        this.isErrorName = false
      }
    },
    Approve() {
      this.$store.commit('setLoading', true)
      this.PostApprove().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchprivacy')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostApprove() {
      var approvepoint = {
        appid: Cache.getCache('app').id,
        id: this.dataDetails.id,
        remarks: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApprovePrivacy(approvepoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    rejectconfirm() {
      this.modalReject = true
    },
    Reject() {
      this.$store.commit('setLoading', true)
      this.PostReject().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchprivacy')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostReject() {
      var rejectpoint = {
        appid: Cache.getCache('app').id,
        id: this.dataDetails.id,
        remarks: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectPrivacy(rejectpoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function () {
      this.levelname = ''
      this.description = ''
      this.earnpoint = ''
      this.benefits = ''
      this.remark = ''
      this.$emit('clicksearchprivacy')
      this.$emit('handleClose')
    },
    handleCancel() {
      this.modalReject = false
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
  .input-error {
    border-color: #ff5252 !important;
  }
  .text-error {
    color: #ff5252;
  }
  .pd-t-21 {
    padding-top: 21px;
  }
}
.w100 {
  width: 100% !important;
}
.pointer {
  cursor: pointer;
}
.w-log-privacy {
  width: 40rem;
  .mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .bg-con-gray {
    padding: 12px 16px;
    background-color: #e3e3e3;
    border-bottom: 1px solid #c9c9c9;
  }
  .bg-con-l-gray {
    padding: 12px 16px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #c9c9c9;
  }
  .fade-color {
    color: #b0b0b0;
  }
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}
.cursor-disabled {
  cursor: not-allowed;
}
</style>
