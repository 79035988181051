<template>
  <a-card class="w-full min-h-full wallet">
    <nav class="filter-nav d-flex justify-content-between mb-3">
      <div class="d-flex gab-10">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            v-model:value="optionssearch"
            :options="options_search"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @keyup="enterFilter($event)"
            :allowClear="true"
          >
            <template #prefix>
              <a @click="applyFilter">
                <search-outlined />
              </a>
            </template>
            <template #suffix>
              <a @click="toggleAdvaceSearch">
                <img
                  class="ico-filter"
                  :class="{ active: checkActive }"
                  src="@/assets/icons/faders.svg"
                />
              </a>
              <FilterSearch
                ref="FilterSearch"
                v-model:value="showAdvaceSearch"
                :FilterConfig="optionlist"
                @applyFilter="applyFilter($event)"
                @handleClose="showAdvaceSearch = false"
              />
            </template>
          </a-input>
        </a-input-group>
        <!-- <div class="wrap-date-filter pr-3">
          <a-select
            class="select-search"
            v-model:value="statusdropdown"
            :options="status_dropdown"
          />
        </div>
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
          />
        </div>
        <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch"
            ><search-outlined /> Search</a-button
          >
        </div> -->
      </div>
      <div class="d-flex gab-10">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <!-- <a-button
          v-if="dataapp.includes('User-List-Wallet-Export')"
          type="teriary"
          @click="exportWalletReport"
        >
          <file-excel-outlined /> Export as excel
        </a-button> -->
      </div>
    </nav>
    <div class="wrap-table" @scroll="handleScroll">
      <!-- <VLoadingTable :value="overlaytable" /> -->
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataSubscription"
        :columns="displayFields"
        :pagination="false"
      >
        <template #payment_id="{ record }">
          <a
            v-if="dataapp.includes('User-List-Subscription-Detail')"
            @click="showModalDetail(record)"
            class="font-level"
            >{{ record.payment_id }}</a
          >
          <div v-else>{{ record.payment_id }}</div>
        </template>
        <template #package_name="{ record }">
          {{ record.package_name || '-' }}
        </template>

        <template #campaign_period="{ record }">
          {{ formatDate(record.campaign_start_date, 'DD/MMM/YYYY') }} -
          {{ formatDate(record.campaign_expried_date, 'DD/MMM/YYYY') }}
        </template>
        <template #agency="{ record }">
          <div>
            {{ record.agency_name || '-' }}
            <label class="text-id pl-1">{{ record.agency_id || '-' }}</label>
          </div>
        </template>
        <template #subscription_purchase_date="{ record }">
          <div>
            {{
              formatDate(
                record.subscription_date,
                'DD/MMM/YYYY - HH:mm',
                this.isTimezone,
              )
            }}
          </div>
        </template>
        <template #price="{ record }">
          <div>{{ record.price.toLocaleString() || '-' }}</div>
        </template>
        <template #discount="{ record }">
          <div>{{ record.discount.toLocaleString() || '-' }}</div>
        </template>
        <template #delivery_fee="{ record }">
          <div>{{ record.delivery_fee.toLocaleString() || '-' }}</div>
        </template>
        <template #total="{ record }">
          <div>{{ record.total.toLocaleString() || '-' }}</div>
        </template>
        <template #status="{ record }">
          <template v-for="(itemstatus, index) in status">
            <a-tag
              v-if="record.status == itemstatus.id"
              :key="index"
              :color="itemstatus.color == 'default' ? null : itemstatus.color"
              class="tag-status"
            >
              <a-badge :status="itemstatus.color" :text="itemstatus.name" />
            </a-tag>
          </template>
        </template>
      </a-table>
    </div>
  </a-card>
  <a-modal
    :visible="modalDetail"
    @cancel="handleCancel"
    wrap-class-name="modal-subscription"
    :width="1400"
    :centered="true"
    :closable="false"
    cancelText="Close"
    :ok-button-props="{ style: { display: 'none' } }"
  >
    <template #title>
      <div>{{ dataDetailRecord.package_name }}</div>
    </template>
    <div class="main-content justify-center">
      <div v-if="dataDetail.length == 0">
        <div class="justify-center">
          <img :src="imgEmpty" />
          <div class="nodata text-center">No Data</div>
        </div>
      </div>
      <div class="main-content" v-else>
        <div class="list">
          <div v-for="(item, index) of dataDetail" :key="index">
            <a-card
              class="pointer"
              :class="{
                'isactive-list':
                  selected.key == item.key || isCheckActive == item.key,
              }"
              @click="onSelectList(item)"
            >
              <div class="d-flex justify-between">
                <div class="d-flex gap-2">
                  <div class="bg-index">{{ index + 1 }}</div>
                  <div>Subscription Status :</div>
                  <div v-if="!item.cancel && !item.hold">
                    <a-tag
                      :key="index"
                      :color="'green' == 'default' ? null : 'green'"
                      class="tag-status"
                    >
                      <a-badge status="green" text="Active" />
                    </a-tag>
                  </div>
                  <div v-if="!item.cancel && item.hold">
                    <a-tag
                      :key="index"
                      :color="'yellow' == 'default' ? null : 'yellow'"
                      class="tag-status"
                    >
                      <a-badge status="yellow" text="Hold" />
                    </a-tag>
                  </div>
                  <div v-if="item.cancel && !item.hold">
                    <a-tag
                      :key="index"
                      :color="'red' == 'default' ? null : 'red'"
                      class="tag-status"
                    >
                      <a-badge status="red" text="Cancel" />
                    </a-tag>
                  </div>
                  <div v-if="!item.cancel && item.hold">
                    Hold Date : {{ formatDate(item.hold_date, 'DD/MMM/YYYY') }}
                  </div>
                  <div v-if="item.cancel && !item.hold">
                    Cancel Date :
                    {{ formatDate(item.cancel_date, 'DD/MMM/YYYY') }}
                  </div>
                </div>
                <div>
                  <a
                    v-if="!check[index]"
                    class="text-active"
                    @click="$event => clickSee(index, $event)"
                    >See more</a
                  >
                  <a
                    v-if="check[index]"
                    class="text-active"
                    @click="$event => clickSee(index, $event)"
                    >See less</a
                  >
                </div>
              </div>

              <div class="d-flex justify-between mt-2">
                <div class="d-flex gap-2">
                  Purchase Status :
                  <template v-for="(itemstatus, index) in status">
                    <a-tag
                      v-if="item.status == itemstatus.id"
                      :key="index"
                      :color="
                        itemstatus.color == 'default' ? null : itemstatus.color
                      "
                      class="tag-status"
                    >
                      <a-badge
                        :status="itemstatus.color"
                        :text="itemstatus.name"
                      />
                    </a-tag>
                  </template>
                  <div v-if="item.status == 3" class="d-flex">
                    <div>
                      Purchase Date :
                      {{
                        formatDate(
                          item.purchase_date,
                          'DD/MMM/YYYY - HH:mm',
                          this.isTimezone,
                        )
                      }}
                    </div>
                    <div class="ml-2">
                      Shiping Date :
                      {{
                        formatDate(
                          item.ship_date,
                          'DD/MMM/YYYY - HH:mm',
                          this.isTimezone,
                        )
                      }}
                    </div>
                  </div>
                  <div v-else class="d-flex">
                    <div>
                      Next Purchase Date :
                      {{ formatDate(item.payment_date, 'DD/MMM/YYYY') }}
                    </div>
                    <div class="ml-2">
                      Next Shiping Date :
                      {{
                        formatDate(
                          item.ship_date,
                          'DD/MMM/YYYY',
                          this.isTimezone,
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="mt-2 d-flex gap-2">
              <div>Subscription Status :</div>
              <div v-if="!item.cancel && !item.hold">
                <a-tag
                  :key="index"
                  :color="'green' == 'default' ? null : 'green'"
                  class="tag-status"
                >
                  <a-badge status="green" text="Active" />
                </a-tag>
              </div>
              <div v-if="!item.cancel && item.hold">
                <a-tag
                  :key="index"
                  :color="'yellow' == 'default' ? null : 'yellow'"
                  class="tag-status"
                >
                  <a-badge status="yellow" text="Hold" />
                </a-tag>
              </div>
              <div v-if="item.cancel && !item.hold">
                <a-tag
                  :key="index"
                  :color="'red' == 'default' ? null : 'red'"
                  class="tag-status"
                >
                  <a-badge status="red" text="Cancel" />
                </a-tag>
              </div>
              <div v-if="!item.cancel && item.hold">
                Hold Date : {{ formatDate(item.hold_date, 'DD/MMM/YYYY') }}
              </div>
              <div v-if="item.cancel && !item.hold">
                Cancel Date : {{ formatDate(item.cancel_date, 'DD/MMM/YYYY') }}
              </div>
            </div> -->
              <!-- <div class="mt-2">
              Campaign : {{ item.campaign_name }}
              <label class="text-gray">{{ item.campaign_id }}</label>
            </div> -->
              <div class="border-total">
                <div class="d-flex gap-4 mt-1">
                  <div>
                    <label class="fw-500">Payment ID :</label>
                    {{ item.payment_id || '-' }}
                  </div>
                  <div>
                    <label class="fw-500">Channel :</label>
                    {{ item.channel || '-' }}
                  </div>
                  <div>
                    <label class="fw-500">Quantity:</label>
                    {{ item.quantity.toLocaleString() || '-' }}
                  </div>
                  <div>
                    <label class="fw-500">Price:</label>
                    {{ item.price.toLocaleString() || '-' }} THB
                  </div>
                  <div>
                    <label class="fw-500">Discount:</label>
                    {{ item.discount.toLocaleString() || '-' }} THB
                  </div>
                  <div>
                    <label class="fw-500">Total:</label>
                    {{ item.total.toLocaleString() || '-' }} THB
                  </div>
                </div>
              </div>
              <div v-if="check[index]">
                <div class="d-flex gap-4 mt-2">
                  <div>
                    <label class="fw-500 mr-1">ID : </label>
                    <label
                      class="fw-400"
                      v-if="item.receiver_user_id.length < 20"
                      >{{ item.receiver_user_id }}</label
                    >
                    <a-tooltip v-else placement="bottom">
                      <template #title>
                        <span>{{ item.receiver_user_id }}</span>
                      </template>
                      <span
                        >{{ item.receiver_user_id.substring(0, 20) }}...</span
                      >
                    </a-tooltip>
                  </div>
                  <div>
                    <label class="fw-500">Name :</label>
                    {{ item.receiver_fullname || '-' }}
                  </div>
                  <div>
                    <label class="fw-500">Contact number :</label>
                    {{ contactNumber(item.receiver_contact_number) || '-' }}
                  </div>
                  <div>
                    <label class="fw-500">Email :</label>
                    {{ item.receiver_email || '-' }}
                  </div>
                </div>
                <div class="d-flex gap-4">
                  <div>
                    <label class="fw-500">Address :</label>
                    {{ item.receiver_address || '-' }}
                  </div>
                </div>
                <div class="d-flex gap-4">
                  <div>
                    <label class="fw-500">Remark :</label>
                    {{ item.receiver_user_remark || '-' }}
                  </div>
                </div>
              </div>
            </a-card>
          </div>
        </div>
        <div class="total">
          <a-card>
            <div class="d-flex gap-2">
              <h4>รายการสินค้า</h4>
              <a-badge
                :count="selected.quantity"
                :number-style="{
                  backgroundColor: '#1890FF',
                }"
              />
            </div>
            <div v-if="skeletonLoading">
              <a-skeleton active />
            </div>
            <div v-else>
              <div v-if="dataDetailItem.length == 0">
                <div class="text-webkit-center">
                  <img :src="imgEmpty" />
                  <div class="nodata text-center">No Data</div>
                </div>
              </div>
              <div v-else>
                <div class="wrapper-content-item" @scroll="handleScrollItem">
                  <div v-for="(item, index) of dataDetailItem" :key="index">
                    <div class="border-total p-3">
                      <div class="d-flex gap-2">
                        <div>
                          {{ item.campaign_name
                          }}<label
                            class="ml-1"
                            style="
                              color: #969696;
                              font-size: 12px;
                              font-weight: 400;
                            "
                            >{{ item.campaign_id }}</label
                          >
                        </div>
                      </div>
                      <div class="mt-2">
                        Parcel No : {{ item.parcel_no || '-' }}
                      </div>
                      <div>
                        <div>Serial : {{ item.serial || '-' }}</div>
                      </div>
                      <div class="d-flex justify-between">
                        <div>
                          Quantity : {{ item.quantity.toLocaleString() || '-' }}
                        </div>
                        <div class="fw-500 text-end">
                          {{ item.price_per_unit.toLocaleString() || '-' }} THB
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="d-flex justify-between mt-2">
                  <div>Quantity : {{ selected.quantity }}</div>
                  <div>{{ selected.total }} THB</div>
                </div> -->
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </div>
  </a-modal>
  <DisplayFields
    ref="selectall"
    :value="showDisplayFields"
    :headers="columns"
    @apply="applyDisplayFields($event)"
    @handleClose="showDisplayFields = false"
  >
  </DisplayFields>
  <ExportModal
    :visible="showExportWalletModal"
    @handleSubmit="callAPiExportWallet($event)"
    @handleClose="showExportWalletModal = false"
  ></ExportModal>
  <ExportModal
    :visible="showExportWalletDetailModal"
    @handleSubmit="callAPiExportWalletDetail($event)"
    @handleClose="showExportWalletDetailModal = false"
  ></ExportModal>
  <PrepareModal
    :visible="showPrepareModal"
    @handleClose="showPrepareModal = false"
  ></PrepareModal>
</template>
<script>
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
// import { map } from "rxjs/operators";
import Util from '@/helper/Utility.js'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import FilterSearch from '@/components/Filter/FilterSearch.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import ConfigManager from '@/config/ConfigManager.js'
import { config } from '@/config/bzbsconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserWallet',
  components: {
    DisplayFields,
    FilterSearch,
    ExportModal,
    PrepareModal,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      visible2: false,
      drag: false,
      modalWallet: false,
      showDisplayFields: false,
      showDisplayFieldsRedeem: false,
      overlay: true,
      overlaytable: false,
      overlaytablemodal: false,
      nomoremodal: false,
      isLoadmore: false,
      modalPage: 1,
      has_conversion: false,
      setperrmissionexport: true,
      datawallets: [],
      card_id: '',
      statustype: [
        {
          id: 1,
          name: 'Stamp',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Wallet',
          color: 'yellow',
        },
      ],
      status: [
        {
          id: 0,
          name: 'Waiting',
          color: 'default',
        },
        {
          id: 1,
          name: 'Paid',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Void',
          color: 'red',
        },
        {
          id: 3,
          name: 'Completed',
          color: 'green',
        },
        {
          id: 4,
          name: 'Expired',
          color: 'red',
        },
        {
          id: 5,
          name: 'Waiting COD',
          color: 'yellow',
        },
        {
          id: 6,
          name: 'Invalid Payment',
          color: 'red',
        },
        {
          id: 7,
          name: 'Refund',
          color: 'purple',
        },
        {
          id: 8,
          name: 'Deduct B Point',
          color: 'default',
        },
        {
          id: 9,
          name: 'Pending COD',
          color: 'yellow',
        },
        {
          id: 10,
          name: 'Waiting Subscription',
          color: 'yellow',
        },
        {
          id: 11,
          name: 'Cancel',
          color: 'red',
        },
      ],
      statusdropdown: '',
      status_dropdown: [
        {
          label: 'All Card Type',
          value: '',
        },
        {
          label: 'Stamp',
          value: 'stamp',
        },
        {
          label: 'Wallet',
          value: 'wallet',
        },
      ],
      optionlist: {
        status: true,
        daterange: true,
        options: [
          {
            label: 'All Status',
            value: 'Default',
          },
          {
            label: 'Waiting',
            value: 'Waiting',
          },
          {
            label: 'Paid',
            value: 'Paid',
          },
          {
            label: 'Void',
            value: 'Void',
          },
          {
            label: 'Completed',
            value: 'Complete',
          },
          {
            label: 'Expired',
            value: 'Expired',
          },
          {
            label: 'Waiting COD',
            value: 'WaitingCod',
          },
          {
            label: 'Invalid Payment',
            value: 'InvalidPayment',
          },
          {
            label: 'Refund',
            value: 'Refund',
          },
          {
            label: 'Deduct B Point',
            value: 'DeductBPoint',
          },
          {
            label: 'Pending COD',
            value: 'PendingCod',
          },
          {
            label: 'Waiting Subscription',
            value: 'WaitingSubscription',
          },
        ],
      },
      searchObject: {
        cardid: '',
        status: '',
        paymentid: '',
        startdate: '',
        enddate: '',
      },
      optionssearch: 'paymentid',
      options_search: [
        {
          label: 'Payment ID',
          value: 'paymentid',
        },
        {
          label: 'Campaign ID',
          value: 'cardid',
        },
      ],
      search: '',
      page: 1,
      columns: [
        {
          title: 'Payment ID',
          dataIndex: 'payment_id',
          slots: { customRender: 'payment_id' },
          key: 'payment_id',
          width: 100,
          align: 'left',
        },
        {
          title: 'Package Name',
          dataIndex: 'package_name',
          slots: { customRender: 'package_name' },
          key: 'package_name',
          width: 140,
          align: 'left',
        },
        {
          title: 'Campaign Period',
          dataIndex: 'campaign_period',
          slots: { customRender: 'campaign_period' },
          key: 'campaign_period',
          width: 80,
          align: 'left',
        },
        {
          title: 'Agency',
          dataIndex: 'agency',
          slots: { customRender: 'agency' },
          key: 'agency',
          width: 160,
          align: 'left',
        },
        {
          title: 'Subscription Purchase Date',
          dataIndex: 'subscription_purchase_date',
          slots: { customRender: 'subscription_purchase_date' },
          key: 'subscription_purchase_date',
          width: 160,
          align: 'left',
        },
        {
          title: 'Last Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 80,
          align: 'left',
        },
        {
          title: 'Price',
          dataIndex: 'price',
          slots: { customRender: 'price' },
          key: 'price',
          width: 80,
          align: 'right',
        },
        {
          title: 'Delivery Fee',
          dataIndex: 'delivery_fee',
          slots: { customRender: 'delivery_fee' },
          key: 'delivery_fee',
          width: 80,
          align: 'right',
        },
        {
          title: 'Discount',
          dataIndex: 'discount',
          slots: { customRender: 'discount' },
          key: 'discount',
          width: 80,
          align: 'right',
        },
        {
          title: 'Total',
          dataIndex: 'total',
          slots: { customRender: 'total' },
          key: 'total',
          width: 80,
          align: 'right',
        },
        // {
        //   title: '',
        //   dataIndex: 'redeem',
        //   slots: { customRender: 'redeem' },
        //   key: 'redeem',
        //   width: 80,
        //   align: 'center',
        // },
      ],
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Expiry Date',
      startdate: null,
      enddate: null,
      formatdate: 'DD/MMM/YYYY',
      showPrepareModal: false,
      modaltext: '',
      modalCardId: '',
      showAdvaceSearch: false,
      checkActive: false,
      dataSubscription: [],
      modalDetail: false,
      dataDetailRecord: [],
      selected: [],
      dataDetailItem: [],
      isCheckActive: '',
      pageItem: 1,
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      skeletonLoading: null,
      check: [],
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'subscription') {
        this.$store.commit('setLoading', true)
        this.displayFields = _.cloneDeep(this.columns)
        this.showAdvaceSearch = false
        this.$refs.FilterSearch.resetFilter()
        this.checkActive = false
        this.getSubscription().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'subscription') {
      this.displayFields = _.cloneDeep(this.columns)
      this.showAdvaceSearch = false
      this.$refs.FilterSearch.resetFilter()
      this.checkActive = false
      this.getSubscription()
      this.overlay = false
    }
  },
  created() {},
  methods: {
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    clickSee(index, event) {
      event.stopPropagation()
      this.check[index] = !this.check[index]
    },
    enterFilter(key) {
      if (key.key == 'Enter' && this.showAdvaceSearch == false) {
        this.applyFilter()
      }
    },
    toggleAdvaceSearch() {
      this.showAdvaceSearch = !this.showAdvaceSearch
    },
    applyFilter(filter) {
      if (
        filter?.status != null ||
        (filter?.startdate?.length > 0 && filter?.enddate?.length > 0)
      ) {
        this.checkActive = true
      } else {
        this.checkActive = false
      }
      this.showAdvaceSearch = false
      this.$store.commit('setLoading', true)
      this.page = 1
      this.searchObject.cardid =
        this.optionssearch == 'cardid' ? this.search : ''
      this.searchObject.paymentid =
        this.optionssearch == 'paymentid' ? this.search : ''
      console.log('this.searchObject.playmentid', this.searchObject.playmentid)
      console.log('this.optionssearch', this.optionssearch)
      console.log('this.search', this.search)
      this.searchObject.status = filter?.status ? filter?.status : ''
      this.searchObject.startdate = filter?.startdate
        ? dayjs(filter?.startdate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.searchObject.enddate = filter?.enddate
        ? dayjs(filter?.enddate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.showDateFilter = false
      this.getSubscription().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getSubscription() {
      this.$store.commit('setLoading', true)
      var fromData = {}
      fromData = {
        AppId: Cache.getCache('app').id,
        userid: this.$route.params.id,
        startdate: this.searchObject.startdate,
        enddate: this.searchObject.enddate,
        status: this.searchObject.status ? this.searchObject.status : 'Default',
        subscription_type: !this.search
          ? null
          : this.optionssearch == 'cardid'
          ? 'CampaignId'
          : 'PaymentId',
        subscription_id: !this.search
          ? null
          : this.optionssearch == 'cardid'
          ? this.searchObject.cardid
          : this.searchObject.paymentid,
        page_number: this.page,
        limit: 20,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubscriptionList(fromData)
          .then(res => {
            if (this.page == 1) {
              this.dataSubscription = []
            }
            _.forEach(res.data.result, item => {
              this.dataSubscription.push(item)
            })
            if (res.data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(res.result)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    showModalDetail(record) {
      this.$store.commit('setLoading', true)
      this.dataDetailRecord = record
      this.isCheckActive = ''
      this.selected = []
      this.getSubscriptionDetail(record.payment_id).then(() => {
        this.modalDetail = true
        this.$store.commit('setLoading', false)
      })
    },
    getSubscriptionDetail(id) {
      var AppId = Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubscriptionDetail(AppId, id).subscribe(
          res => {
            this.dataDetail = res.result
            if (this.dataDetail.length > 0) {
              _.forEach(this.dataDetail, (item, index) => {
                item.key = index
              })
              this.isCheckActive = this.dataDetail[0].key
              this.onSelectList(this.dataDetail[0])
            }
            console.log(this.dataDetail)
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    onSelectList(item) {
      this.isCheckActive = item.key
      this.selected = item
      this.pageItem = 1
      this.skeletonLoading = true
      this.getSubscriptionDetailItem()
    },
    getSubscriptionDetailItem() {
      var AppId = Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubscriptionDetailItem(
          AppId,
          this.selected.payment_id,
          this.pageItem,
        ).subscribe(
          res => {
            if (this.pageItem == 1) {
              this.dataDetailItem = []
            }
            _.forEach(res.result, item => {
              this.dataDetailItem.push(item)
            })
            if (res.result.length < 20) {
              this.nomoreItem = true
            } else {
              this.nomoreItem = false
            }
            this.skeletonLoading = false
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    callAPiExportWallet(value) {
      this.paramsWallet.filter.appid = ConfigManager.getAppId()
      ;(this.paramsWallet.name = value.filename),
        (this.paramsWallet.description = value.description),
        (this.paramsWallet.filter.user_id = this.$route.params.id),
        (this.paramsWallet.filter.card_id = this.searchObject.cardid),
        (this.paramsWallet.filter.issuer = this.searchObject.issuer),
        (this.paramsWallet.filter.type = this.searchObject.status
          ? this.searchObject.status
          : null),
        (this.paramsWallet.filter.from_expired_date = this.searchObject
          .startdate
          ? this.searchObject.startdate
          : null),
        (this.paramsWallet.filter.to_expired_date = this.searchObject.enddate
          ? this.searchObject.enddate
          : null)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostWalletExport(this.paramsWallet)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      }).then(() => {
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    callAPiExportWalletDetail(value) {
      this.paramsWalletDetail.filter.appid = ConfigManager.getAppId()
      this.paramsWalletDetail.name = value.filename
      this.paramsWalletDetail.description = value.description
      this.paramsWalletDetail.filter.card_id = this.modalCardId
      this.paramsWalletDetail.filter.user_id = this.$route.params.id
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostWalletDetailExport(this.paramsWalletDetail)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      }).then(() => {
        this.showExportWalletDetailModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getSubscription().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    handleScrollItem(event) {
      if (!this.nomoreItem && !this.overlaytableItem) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytableItem = true
          this.pageItem++
          this.getSubscriptionDetailItem().then(() => {
            this.overlaytableItem = false
          })
        }
      }
    },
    handleCancel() {
      this.modalDetail = false
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    exportWalletReport() {
      this.showExportWalletModal = true
    },
    exportWalletDetailReport() {
      this.showExportWalletDetailModal = true
    },
    toggleDisplayFieldsRedeem(level_name) {
      this.dataLevelname = level_name
      this.showDisplayFieldsRedeem = !this.showDisplayFieldsRedeem
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }

  .nav-search .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .line {
    width: 2px;
    height: 64px;
    float: right;
    background-color: $color-grey-line;
    position: absolute;
    top: 14%;
    left: calc(100% - 158px);
  }

  .nav-search {
    .group-btn-left {
      float: right;
      display: flex;
      padding-right: 5px;
    }

    .group-btn {
      float: right;
      margin-left: 16px;
    }
  }

  .wrap-table {
    .ant-table-wrapper {
      min-width: inherit;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .card-type {
    text-align: center;
    width: 80px;
    color: #1d5ffb;
    border: 1px solid#1d5ffb;
    border-radius: 20px;
  }
}

.select-search {
  width: 130px !important;
}
</style>
<style lang="scss">
.modal-subscription {
  .main-content {
    display: flex;
    width: 100%;
    gap: 16px;
    .list {
      width: 68%;
    }
    .total {
      width: 32%;
    }
  }
  .border-total {
    margin-top: 10px;
    border: 1px solid #c9c9c9;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
  }
  .ant-card.isactive-list {
    background-color: #fff6e6;
    border-color: #feca62;
  }
  .ant-card {
    margin-bottom: 10px;
  }
}
.wrapper-content-item {
  display: block;
  width: 100%;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-x: auto;
}
.fw-400 {
  font-weight: 400;
}
.bg-index {
  width: 24px;
  text-align: center;
  color: #ffffff;
  background-color: #494949;
  border-radius: 4px;
}
</style>
