import { reactive, provide } from "vue"


/**
 * @typedef ToolbarStates
 * @property {boolean} showPanelFilter
 * @property {boolean} showPanelSavedList
 * @property {boolean} showPanelCustomFields
 * @property {boolean} showDialogCreateList
 * @property {boolean} showExport
 */

export default function useToolbarComposable() {
    const states = reactive({
        showPanelFilter: false,
        showPanelSavedList: false,
        showPanelCustomFields: false,
        showDialogCreateList: false,
        showExport: false

    })

    provide("toolbarStates", states)

    return {
        states
    }
}
