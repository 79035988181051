import Cookies from 'js-cookie'

/**
 * * constructor
 * @param {string} mode cookie  - use cookie storage (default)
 *                      session - use browser session storage
 *                      local   - use browser local storage
 *
 * * method
 * @method setCache
 * @method getCache
 * @method removeCache
 */
export default function CacheHelper(mode) {
    this.cache = null;

    this.construct = function(mode) {
        switch (mode) {
            case 'session':
                this.cache = new SessionCache();
                break;
            case 'local':
                this.cache = new LocalCache();
                break;
            default:
                this.cache = new CookieCache(this.vueCookie);
        }
    };
    this.construct(mode);

    /**
     * @param {string} key
     * @param {object|array} data
     */
    this.setCache = function(key, data) {
        this.cache.removeCache(key);
        this.cache.setCache(key, JSON.stringify(data));
    };

    /**
     * @param {string} key
     */
    this.getCache = function(key) {
        var data = this.cache.getCache(key);
        var json = null;
        try {
            json = JSON.parse(data);
            return json
        } catch {
            return data;
        }
    };

    this.getCacheRaw = function (key) {
        return this.cache.getCache(key);
    };

    /**
     * @param {string} key
     */
    this.removeCache = function(key) {
        this.cache.removeCache(key);
    };
    this.removeAllCache = function () {
        this.cache.removeAllCache()
    };
}

function CookieCache(vueCookie) {
    this.vueCookie = vueCookie;

    this.setCache = function(key, data) {
        // expire 10 day
        let cookieConfig = {
            expires: 10,
            path: '/',
            domain: '',
            secure: true,
            sameSite: 'Lax'
        };
        Cookies.set(key, data, cookieConfig);
    };
    this.getCache = function (key) {
        return Cookies.get(key);
    };
    this.removeCache = function(key) {
        Cookies.remove(key);
    };
    this.removeAllCache = function () {
        Object.keys(Cookies.get()).forEach(function(cookieName) {
  Cookies.remove(cookieName);
});
    };
}

function SessionCache() {
    this.setCache = function(key, data) {
        window.sessionStorage.setItem(key, data);
    };
    this.getCache = function(key) {
        return window.sessionStorage.getItem(key);
    };
    this.removeCache = function(key) {
        return window.sessionStorage.removeItem(key);
    };
}

function LocalCache() {
    this.setCache = function(key, data) {
        window.localStorage.setItem(key, data);
    };
    this.getCache = function(key) {
        return window.localStorage.getItem(key);
    };
    this.removeCache = function(key) {
        window.localStorage.removeItem(key);
    };
}

