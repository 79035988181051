<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <div class="d-flex justify-content-between">
        <TitleBreadcrumbs class="mt-4" title="User List" />
      </div>
    </template>
    <div class="mx-4 mb-4 b-6 bg-white pl-4 pr-4 d-flex">
      <div class="py-2 d-flex">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            style="width: 150px"
            v-model:value="typesearchrealtime"
            :options="options_searchrealtime"
            @change="ChangetypeSearchRealtime()"
          />
          <a-input
            v-model:value="searchrealtime"
            class="advanced-search"
            placeholder="Please search to view information"
            @pressEnter="clicksearch()"
            @input="handleInput($event)"
          >
            <template #prefix>
              <a @click="clicksearch()">
                <search-outlined />
              </a>
            </template>
          </a-input>
          <!-- <a-input
            v-model:value="searchrealtime"
            placeholder="Please search to view information"
            style="width: 270px"
          /> -->
        </a-input-group>
        <!-- <div v-if="cacheSearch && searchrealtime" class="ml-3 pt-2">
          <a @click="clearSearch()" class="text-link">Clear</a>
        </div>
        <a-button
          type="primary"
          @click="clicksearch()"
          :disabled="!searchrealtime"
          class="btn-search ml-4"
          ><search-outlined /> Search</a-button
        > -->
      </div>
    </div>
    <div class="wrap-table-level px-4 realtime" @scroll="handleScroll">
      <VLoadingTable :value="overlaytable" />
      <a-table
        row-key="partitionkey"
        class="fixed-head font-table-modal"
        :dataSource="dataRealtime"
        :columns="realtimecolumn"
        :pagination="false"
        :loading="loading"
      >
        <template #user_id="{ text, record }">
          <div class="userirealtimeddotafter">
            <router-link
              class="userid-modal"
              :to="{
                name: 'user_detail',
                params: {
                  id: text,
                  first_name: record.first_name,
                  last_name: record.last_name,
                },
              }"
            >
              {{ text }}
            </router-link>
          </div>
          <div>{{ record.first_name }} {{ record.last_name }}</div>
        </template>
        <template #contact_number="{ text }">
          <div>{{ formatPhone(text) }}</div>
        </template>
      </a-table>
    </div>
  </AppLayout>
  <!-- <a-modal
    :visible="visible"
    title="User List - Realtime data"
    @cancel="handleCancel"
    @ok="false"
    class="modalrealtime"
    width="900px"
    :ok-button-props="{ style: { display: 'none' } }"
    :closable="false"
    :centered="true"
  >
    <div class="d-flex">
      <div class="mr-3 mb-3">
        <a-input-group class="search" compact>
          <a-input
            v-model:value="searchrealtime"
            placeholder="Search"
            style="width: 270px"
          />
          <a-select
            class="select-search"
            style="width: 150px"
            v-model:value="typesearchrealtime"
            :options="options_searchrealtime"
          />
        </a-input-group>
      </div>
      <div>
        <a-button
          type="primary"
          @click="clicksearch()"
          class="btn-search"
          :disabled="!searchrealtime"
          >Search</a-button
        >
      </div>
    </div>
    <div class="wrap-table-modal" @scroll="handleScrollModal">
      <VLoadingTable :value="overlaytablemodal" />
      <a-table
        row-key="partitionkey"
        class="fixed-head font-table-modal"
        :dataSource="dataRealtime"
        :columns="realtimecolumn"
        :pagination="false"
        :loading="loading"
      >
        <template #user_id="{ text,record }">
          <div class="userirealtimeddotafter">
            <router-link
              class="userid-modal"
              :to="{
                name: 'user_detail',
                params: {
                  id: text,
                  first_name: record.first_name,
                  last_name: record.last_name,
                },
              }"
            >
              {{ text }}
            </router-link>
          </div>
        </template>
        <template #contact_number="{ text }">
          <div>{{ formatPhone(text) }}</div>
        </template>
      </a-table>
    </div>
  </a-modal> -->
</template>
<script>
import _ from 'lodash'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import AppLayout from '@/components/Layout/default.vue'
import moment from 'moment'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserListRealTime',
  props: {},
  components: {
    AppLayout,
    TitleBreadcrumbs,
  },
  mixins: [],
  emits: [],
  data: function () {
    return {
      count: null,
      update: null,
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      realtimecolumn: [
        {
          title: 'User ID',
          dataIndex: 'user_id',
          key: 'user_id',
          slots: { customRender: 'user_id' },
          width: 400,
          align: 'left',
        },
        // {
        //   title: 'First Name',
        //   dataIndex: 'first_name',
        //   key: 'first_name',
        //   width: 220,
        //   align: 'left',
        // },
        // {
        //   title: 'Last Name',
        //   dataIndex: 'last_name',
        //   key: 'last_name',
        //   width: 220,
        //   align: 'left',
        // },
        {
          title: 'Contact Number',
          dataIndex: 'contact_number',
          key: 'contact_number',
          slots: { customRender: 'contact_number' },
          width: 400,
          align: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          slots: { customRender: 'email' },
          width: 400,
          align: 'left',
        },
      ],
      typesearchrealtime: 'user_id',
      searchrealtime: '',
      options_searchrealtime: [
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'Contact Number',
          value: 'contact_number',
        },
        {
          label: 'First Name',
          value: 'first_name',
        },
        {
          label: 'Last Name',
          value: 'last_name',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Reference ID',
          value: 'ref_id',
        },
        {
          label: 'Help ID',
          value: 'help_id',
        },
      ],
      searchObject: {
        user_id: '',
        contact_number: '',
        first_name: '',
        last_name: '',
        email: '',
        ref_id: '',
        help_id: '',
        id_card_number: '',
      },
      dataRealtime: [],
      page: 1,
      cacheSearch: false,
    }
  },
  computed: {},
  created() {
    this.callGetTotallUser()
    if (Cache.getCache('SearchRealtime')) {
      this.searchrealtime = Cache.getCache('SearchRealtime')[0].search
      this.typesearchrealtime = Cache.getCache('SearchRealtime')[0].typesearch
      this.clicksearch()
      this.cacheSearch = true
    } else {
      this.cacheSearch = false
    }
    this.$store.commit('setLoading', false)
  },
  watch: {},
  mounted: function () {},
  methods: {
    ChangetypeSearchRealtime() {
      this.searchrealtime = ''
    },
    clearSearch() {
      this.$store.commit('setLoading', true)
      this.cacheSearch = false
      this.typesearchrealtime = 'user_id'
      this.searchrealtime = ''
      Cache.removeCache('SearchRealtime')
      this.searchObject.user_id =
        this.typesearchrealtime == 'user_id' ? this.searchrealtime : ''
      this.searchObject.contact_number =
        this.typesearchrealtime == 'contact_number' ? this.searchrealtime : ''
      this.searchObject.first_name =
        this.typesearchrealtime == 'first_name' ? this.searchrealtime : ''
      this.searchObject.last_name =
        this.typesearchrealtime == 'last_name' ? this.searchrealtime : ''
      this.searchObject.email =
        this.typesearchrealtime == 'email' ? this.searchrealtime : ''
      this.searchObject.ref_id =
        this.typesearchrealtime == 'ref_id' ? this.searchrealtime : ''
      this.searchObject.help_id =
        this.typesearchrealtime == 'help_id' ? this.searchrealtime : ''
      this.searchObject.id_card_number =
        this.typesearchrealtime == 'id_card_number' ? this.searchrealtime : ''
      this.dataRealtime = []
      this.getRealtime().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    clicksearch() {
      if (!this.searchrealtime) {
        this.clearSearch()
      } else {
        this.$store.commit('setLoading', true)
        this.dataRealtime = []
        this.searchObject.user_id =
          this.typesearchrealtime == 'user_id' ? this.searchrealtime : ''
        this.searchObject.contact_number =
          this.typesearchrealtime == 'contact_number' ? this.searchrealtime : ''
        this.searchObject.first_name =
          this.typesearchrealtime == 'first_name' ? this.searchrealtime : ''
        this.searchObject.last_name =
          this.typesearchrealtime == 'last_name' ? this.searchrealtime : ''
        this.searchObject.email =
          this.typesearchrealtime == 'email' ? this.searchrealtime : ''
        this.searchObject.ref_id =
          this.typesearchrealtime == 'ref_id' ? this.searchrealtime : ''
        this.searchObject.help_id =
          this.typesearchrealtime == 'help_id' ? this.searchrealtime : ''
        this.searchObject.id_card_number =
          this.typesearchrealtime == 'id_card_number' ? this.searchrealtime : ''
        var searchCache = []
        searchCache.push({
          search: this.searchrealtime,
          typesearch: this.typesearchrealtime,
        })
        if (!Cache.getCache('SearchRealtime')) {
          this.cacheSearch = true
        }
        Cache.setCache('SearchRealtime', searchCache)
        this.getRealtime().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
    handleScroll(event) {
      if (!this.nomoremodal && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getRealtime().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getRealtime() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserlistRealtime(
          this.searchObject.user_id,
          this.searchObject.contact_number,
          this.searchObject.first_name,
          this.searchObject.last_name,
          this.searchObject.email,
          this.searchObject.ref_id,
          this.searchObject.help_id,
          this.searchObject.id_card_number,
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataCard = []
            }
            _.forEach(data.result, item => {
              item.first_name = item.first_name ? item.first_name : '-'
              item.last_name = item.last_name ? item.last_name : '-'
              item.email = item.email ? item.email : '-'
              this.dataRealtime.push(item)
            })
            if (data.result.length < 20) {
              this.nomoremodal = true
            } else {
              this.nomoremodal = false
            }
            this.loading = false
            resolve(this.dataRealtime)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    formatPhone(dirtyNumber) {
      if (dirtyNumber == null) return '-'
      return dirtyNumber
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    callGetTotallUser() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTotallUser().subscribe(
          res => {
            this.update =
              'Latest Update : ' +
              moment(res.result.last_updated).format('DD MMM YY - hh:mm')
            this.count = res.result.total_rows.toLocaleString()
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.wrap-table-level {
  display: block;
  width: 100%;
  max-height: calc(100vh - 270px);
  min-height: calc(100vh - 270px);
  overflow-x: auto;
}
.realtime {
  .userirealtimeddotafter {
    width: 300px !important;
  }
}
</style>
