<template>
  <div class="wrapper-search">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col :span="5"> </a-col>
      <a-col :span="4">
        <a-input
          v-model:value="fileName"
          placeholder="Search download file name"
          @change="onChangefileName"
        >
        </a-input>
      </a-col>
      <a-col :span="13" class="d-flex">
        <div class="wrap-date-filter pl-3">
          <a-button class="btn-date-filter" @click="toggleDateFilter()">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
            @handleCloseDaterange="showDateFilter = false"
            :Today="true"
          />
        </div>
        <a-button class="ml-3" type="primary" @click="searchData"
          >Search</a-button
        >
        <!-- <span class="ml-10">
          <a-date-picker
            v-model:value="startdate"
            :format="dateFormat"
            :disabled-date="disabledStartDate"
            @change="onChangeStartDate"
          >
            <template #suffixIcon>
              <caret-downOutlined type="caretDownOutlined" />
            </template>
          </a-date-picker>
        </span>
        <span class="ml-10">
          <a-date-picker
            v-model:value="enddate"
            :disabled-date="disabledEndDate"
            :format="dateFormat"
            @change="onChangeEndDate"
          >
            <template #suffixIcon>
              <caret-downOutlined type="caretDownOutlined" />
            </template>
          </a-date-picker>
        </span> -->
      </a-col>
      <a-col :span="2" align="right">
        <span class="btn-remove" @click="clearDownloaded()"> Clear List </span>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
export default {
  name: 'Toolbar',
  components: {
    DateFilter,
  },
  data: function () {
    return {
      fileName: '',
      startdate: null,
      enddate: null,
      dateFormat: 'YYYY-MM-DD',
      showDateFilter: false,
      dateFilterValue: 'Today',
      formatdate: 'DD/MMM/YYYY',
    }
  },
  watch: {},
  emits: ['update:value', 'callsearchData'],
  created: function () {
    this.startdate = dayjs(dayjs(), this.dateFormat)
    this.enddate = dayjs(dayjs(), this.dateFormat)
    this.$emit(
      'startdate',
      dayjs(this.startdate, this.dateFormat).format(this.dateFormat),
    )
    this.$emit(
      'enddate',
      dayjs(this.enddate, this.dateFormat).format(this.dateFormat),
    )
  },
  methods: {
    moment,
    clearDownloaded() {
      this.$emit('clearDownloaded')
    },
    searchData() {
      let params = {}
      params.filename = this.fileName
      params.startdate = dayjs(this.startdate, this.formatdate).format(
        'YYYY-MM-DD',
      )
      params.enddate = dayjs(this.enddate, this.formatdate).format('YYYY-MM-DD')
      this.showDateFilter = false
      this.$emit('callsearchData', params)
    },
    onChangeStartDate(date, dateString) {
      console.log(date)
      this.$emit('startdate', dateString == '' ? '' : dateString)
    },
    onChangeEndDate(date, dateString) {
      console.log(date)
      this.$emit('enddate', dateString == '' ? '' : dateString)
    },
    onChangefileName(value) {
      this.$emit('onChangefileName', value)
    },
    disabledStartDate(current) {
      return current && current >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startdate) {
        return false
      }
      return (
        this.startdate.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    // disabledFutureDate(endValue) {
    //   return endValue && endValue > moment().endOf("Day");
    // },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Today'
      this.startdate = moment().startOf('day').format(this.formatdate)
      this.enddate = moment().endOf('day').format(this.formatdate)
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper-search {
  padding: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.btn-remove {
  cursor: pointer;
  color: #fb7290;
}
.btn-date-filter {
  width: 240px;
  text-align: left;
}
.datefilter {
  right: 0;
  left: unset;
}
.btn-date-filter {
  width: 260px;
  text-align: left;
  .anticon {
    float: right;
    margin-top: 2px;
  }
}
</style>
