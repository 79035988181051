export function Userlistmodel(obj) {
  this.userid = obj.userid
  this.profile_last_profile_timestamp = obj.profile_last_profile_timestamp
  this.birthday = obj.birthday_timestamp || '-'
  this.checked = obj.checked
  this.contact_number = obj.contact_number
  this.email = obj.email || '-'
  this.first_name = obj.first_name || '-'
  this.last_name = obj.last_name || '-'
  this.gender = obj.gender || '-'
  this.joined_date = obj.joined_date_timestamp || '-'
  this.province_name = obj.province_name || '-'
  this.fraud_status = obj.fraud_status || '-'
  this.data_privacy = obj.data_privacy || '-'
  this.marketing_option = obj.marketing_option || '-'
  this.consent_age = obj.consent_age || '-'
  this.line_marketing = obj.line_marketing
  this.notification_marketing = obj.notification_marketing
  this.sms_marketing = obj.sms_marketing
  this.email_marketing = obj.email_marketing
  this.term_and_condition = obj.term_and_condition || '-'
  this.level = obj.user_level_names || '-'
  this.total_earn = obj.total_earn.toLocaleString('en-US') || '0'
  this.total_burn = obj.total_burn
    ? Math.abs(obj.total_burn).toLocaleString('en-US')
    : '0'
  this.point_onhand = obj.point_onhand.toLocaleString('en-US') || '0'
  this.last_active = obj.last_active_timestamp || '-'
  this.last_redeem = obj.last_redeem_timestamp || '-'
  this.last_scan = obj.last_scan_timestamp || '-'
  this.registration_type = obj.registration_type || '-'
  this.number_of_friends =
    parseInt(obj.number_of_friends).toLocaleString('en-US') || '-'
  this.tagid = obj.tagid || '-'
  this.age = obj.age == '-999' ? '-' : obj.age
  this.timestamp = obj.timestamp || '-'
  this.appid = obj.appid || '-'
  this.line_user_id = obj.line_user_id
  this.id_card_number = obj.id_card_number || '-'
  this.landmark = obj.landmark || '-'
  this.inactive_date = obj.inactive_date || '-'
}
