<template>
    <div class="text-center text-lg">
        Page not found
    </div>
</template>
<script>
export default {
    name: 'Notfound',
}
</script>
<style lang="scss" scoped>
</style>
