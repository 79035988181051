<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">
              {{ helpchatdetail.name
              }}<span class="pl-2 text-id"
                >ID: {{ helpchatdetail.user_id }}</span
              >
            </div>
            <div class="cursor-pointer" @click="reloadHandler">
              <img src="@/assets/icons/reload.svg" alt="" />
            </div>
          </div>
          <div class="border-b flex-auto h-between">
            <div
              class="helpDetail"
              ref="title3"
              :class="
                helpchatdetail.help_topic.length > 275 && !isReadMore
                  ? 'smallTitle p-2'
                  : 'bigTitle pt-2 pb-2 pl-2'
              "
            >
              <a-row :class="isReadMore ? 'overflow-auto mb-2' : 'mb-2'">
                <a-col :span="7">
                  <div class="imgchat" v-if="helpchatdetail.image">
                    <div class="box-img w-100">
                      <img class="img130" :src="helpchatdetail.image" />
                      <div
                        class="overlay"
                        @click="handleOpenPreview(helpchatdetail.image)"
                      >
                        <a><eye-outlined /></a>
                      </div>
                    </div>
                  </div>
                  <a-modal
                    :visible="previewImgDetail"
                    class="img-modal"
                    :title="false"
                    :footer="null"
                    :centered="true"
                    :closable="false"
                    @cancel="handleCancel"
                  >
                    <a class="close-preview" @click="handleCancel">
                      <span class="icon-x">X</span>
                    </a>
                    <img alt="example" style="width: 100%" :src="url" />
                  </a-modal>
                </a-col>
                <a-col :span="helpchatdetail.image ? 17 : 24">
                  <div
                    v-if="helpchatdetail.help_topic.length < 275"
                    :class="
                      helpchatdetail.image ? 'title-text ml-2' : 'title-text'
                    "
                  >
                    {{ helpchatdetail.help_topic }}
                  </div>
                  <div
                    v-else
                    :class="[
                      helpchatdetail.image ? 'title-text ml-2' : 'title-text',
                      !isReadMore && 'dot',
                    ]"
                  >
                    {{
                      !isReadMore
                        ? helpchatdetail.help_topic.substring(0, 225)
                        : helpchatdetail.help_topic
                    }}
                  </div>
                </a-col>
              </a-row>
              <div class="group-btn-displayfield pt-2 text-grey">
                <div>
                  {{
                    formatDate(
                      helpchatdetail.created_date,
                      'DD MMM YYYY -  hh:mm A',
                    )
                  }}
                  / Platform : {{ helpchatdetail.platform }}
                </div>
                <div v-if="helpchatdetail.help_topic.length > 275">
                  <span
                    v-if="!isReadMore"
                    @click="toggleReadMore"
                    class="readmore-btn"
                    >More <down-outlined
                  /></span>
                  <span v-else @click="toggleReadMore" class="readmore-btn"
                    >Close <up-outlined
                  /></span>
                </div>
              </div>
            </div>

            <div
              class="overflow-y-auto p-4 chat-flex"
              ref="autoHeight"
              :class="[
                datapermission.includes('Help-Chat-Reply')
                  ? 'chat'
                  : 'chatNotReply',
                fileList.length > 0 && 'smallChat',
                isReadMore && 'miniChat',
              ]"
            >
              <div
                class="mb-3"
                v-for="(data, index) of dataHelpChatDetails"
                :key="`index-${index}`"
              >
                <div
                  :class="[
                    data.user_id.includes('BZB') ||
                    data.user_id.includes('system')
                      ? 'chatRight flex'
                      : 'chatLeft flex-reverse',
                  ]"
                >
                  <div
                    :class="[
                      data.user_id.includes('BZB') ||
                      data.user_id.includes('system')
                        ? 'flex'
                        : 'flex flex-reverse',
                    ]"
                  >
                    <div
                      :class="[
                        data.user_id.includes('BZB') ||
                        data.user_id.includes('system')
                          ? 'flex-none timeRight mt-1 mr-1'
                          : 'flex-none timeLeft ml-1 mt-1',
                      ]"
                    >
                      <div>
                        {{
                          formatDate(
                            data.created_date,
                            'DD MMM YYYY',
                            this.isTimezone * -1,
                          )
                        }}
                      </div>
                      <div>
                        {{
                          formatDate(
                            data.created_date,
                            'HH:mm',
                            this.isTimezone,
                          )
                        }}
                      </div>
                    </div>
                    <div
                      :class="[
                        data.user_id.includes('BZB') ||
                        data.user_id.includes('system')
                          ? 'boxChatRight'
                          : 'boxChatLeft',
                      ]"
                    >
                      <div class="comment">
                        <div class="box-img" v-if="data.image">
                          <img class="img130" :src="data.image" />
                          <div
                            class="overlay"
                            @click="handleOpenPreview(data.image)"
                          >
                            <a><eye-outlined /></a>
                          </div>
                        </div>
                        <div
                          :class="
                            data.image
                              ? 'dataComment chatwrapper '
                              : 'chatwrapper '
                          "
                        >
                          {{ data.help_topic }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="data.by" class="mt-1 date">
                    {{ data.by }}
                  </div>
                  <!-- <div v-if="!data.by" class="mt-1 date">
                    {{
                      formatDate(
                        data.created_date,
                        'DD/MMM/YYYY -  hh:mm A',
                        -7,
                      )
                    }}
                  </div> -->
                </div>
              </div>
            </div>
            <div
              v-if="datapermission.includes('Help-Chat-Reply')"
              class="replyComment p-2"
            >
              <div
                class="inputchat-main"
                ref="inputchat"
                :class="fileList.length > 0 ? 'bigBox' : ''"
              ></div>
              <div :class="fileList.length > 0 ? '' : ''">
                <a-upload
                  v-model:file-list="fileList"
                  name="file"
                  :maxCount="1"
                  list-type="picture-card"
                  @change="resize()"
                  @preview="handlePreview"
                  :beforeUpload="[
                    function () {
                      false
                    },
                  ]"
                >
                  <span>
                    <img src="@/assets/icons/upload-img.svg" alt />
                  </span>
                </a-upload>
              </div>
              <a-textarea
                class="mt-3 chat-textarea"
                ref="textArea"
                @input="resize()"
                :class="fileList.length > 0 ? 'flex-textarea' : ''"
                v-model:value="comments"
                placeholder="Type a new message"
                :rows="1"
              />
              <!-- <textarea class="mt-5" placeholder="write something..." @input="resize()" ref="textarea"></textarea> -->
              <a-modal
                class="img-modal"
                :visible="previewVisible"
                :title="false"
                :footer="null"
                :centered="true"
                :closable="false"
                @cancel="handleCancel"
              >
                <a class="close-preview" @click="handleCancel">
                  <span class="icon-x">X</span>
                </a>
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </div>
          <div class="group-btn-displayfield px-4 py-3">
            <div
              :class="
                !datapermission.includes('Help-Chat-Reply')
                  ? 'w100 btn-create'
                  : ''
              "
            >
              <a-button class="btn-create" @click="dismiss(true)">
                Close
              </a-button>
            </div>
            <div v-if="datapermission.includes('Help-Chat-Reply')" class="flex">
              <a-button
                :disabled="
                  helpchatdetail.status == 'MarkRead' ||
                  helpchatdetail.status == 'Read'
                "
                type="primary-2"
                class="mr-2 btn-create"
                @click="MarkRead()"
              >
                Mark Read
              </a-button>
              <a-button
                :disabled="!comments"
                type="primary"
                class="btn-create"
                @click="PostHelpChat()"
              >
                Sent
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import Util from '@/helper/Utility'
// import moment from 'moment'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
import { config } from '@/config/bzbsconfig.js'
// import _ from 'lodash'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataHelpChatDetail: {
      type: Array,
    },
    helpchatdetail: {
      type: Array,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['getHelpChatDetail', 'getHelpchatList'],
  data: function () {
    return {
      //preview Input Image
      isTimezone: config.businessRule.timeZone.hours,
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      previewVisible: false,
      previewImage: '',
      previewTitle: '',
      previewImgDetail: false,
      url: '',
      //readmore
      isReadMore: false,

      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      fileList: [],
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      levelname: '',
      description: '',
      earnpoint: '',
      benefits: '',
      comments: null,
      isErrorName: false,
      headers2: {
        authorization: 'authorization-text',
      },
      dataHelpChatDetails: [],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {
    this.resize()
  },
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
    this.dataHelpChatDetails = this.dataHelpChatDetail
  },
  methods: {
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    getHelpChatDetail() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetHelpChatDetail(this.helpchatdetail.buzz_key).subscribe(
          res => {
            this.dataHelpChatDetails = res.result
            this.$forceUpdate()
            resolve(this.dataHelpChatDetails)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    PostHelpChat() {
      const formData = {
        buzz_key: this.helpchatdetail.buzz_key,
        reply: this.comments,
        file: this.fileList[0] ? this.fileList[0].originFileObj : [],
        appid: Cache.getCache('app').id,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostHelpCahtReply(formData)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.postCreateTicket()
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getHelpChatDetail()
                this.comments = ''
                this.fileList = []
                this.$nextTick(() => {
                  // add my-component component in DOM
                  this.$forceUpdate()
                  this.resize()
                })
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    postCreateTicket() {
      if (this.fileList) {
        this.dataticket = {
          AppId: Cache.getCache('app').id,
          user_id: this.helpchatdetail.user_id,
          username: this.helpchatdetail.name,
          contact_number: '',
          subject: 'Help Chat',
          topic_type: 'ข้อมูลสอบถาม',
          topic_detail: '',
          problem_type_id: config.businessRule.ticket.problem_type_id,
          remark: this.comments,
          priority: 'Medium',
          channel: config.businessRule.ticket.channel,
          campaign_id: null,
          vsts_id: null,
          vsts_name: null,
          ticket_status: 'Close',
          expected_date: null,
          files: this.fileList.map(x => x.originFileObj),
        }
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateTicket(this.dataticket)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    //region preview Input Image
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
      this.previewTitle =
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    },
    handleCancel() {
      this.previewVisible = false
      this.previewTitle = ''
      this.previewImgDetail = false
    },
    //endregion preview Input Image
    toggleReadMore() {
      this.isReadMore = !this.isReadMore
      this.$nextTick(() => {
        // add my-component component in DOM
        this.resize()
      })
    },
    reloadHandler() {
      this.$emit('getHelpchatList')
    },
    MarkRead() {
      var params = {
        buzz_key: this.helpchatdetail.buzz_key,
        appid: Cache.getCache('app').id,
      }
      BzbsUser.apiPutMarkRead(params)
        .then(data => {
          this.$success({
            title: 'Success',
            okType: 'default',
            centered: true,
            onOk: () => {
              this.$emit('handleClose')
              window.location.reload()
            },
          })
          console.log(data)
        })
        .catch(error => {
          this.errorApi(error)
        })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function (flagResetState = true) {
      console.log('dismiss')
      if (flagResetState) {
        this.resetState()
      } else {
        this.setState()
      }
      this.comments = ''
      this.fileList = []
      this.$emit('getHelpchatList')
      this.$emit('handleClose')
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
    handleOpenPreview(url) {
      this.url = url
      this.previewImgDetail = true
    },
    resize() {
      this.$forceUpdate()
      let element = this.$refs['autoHeight']
      let element2 = this.$refs['textArea']
      let elementinput = this.$refs['inputchat']
      let titleheight = this.$refs['title3'].clientHeight
      element2.resizableTextArea.textArea.style.height = '18px'
      element2.resizableTextArea.textArea.style.height =
        element2.resizableTextArea.textArea.scrollHeight + 'px'
      if (
        parseInt(
          element2.resizableTextArea.textArea.style.height.substring(
            0,
            element2.resizableTextArea.textArea.style.height.length - 2,
          ),
        ) > (this.isReadMore ? 267 : 300)
      ) {
        element.style.height =
          'calc(100vh - ' +
          ((this.fileList.length > 0 ? titleheight + 180 : titleheight + 158) +
            (this.isReadMore ? 267 : 300)) +
          'px)'
        elementinput.style.height =
          (this.fileList.length > 0 ? 40 : 10) +
          (this.isReadMore ? 267 : 300) +
          'px'
        element2.resizableTextArea.textArea.style.height =
          this.fileList.length > 0
            ? this.isReadMore
              ? '184px'
              : '216px'
            : this.isReadMore
            ? '267px'
            : '300px'
      } else {
        element.style.height =
          'calc(100vh - ' +
          ((this.fileList.length > 0 ? titleheight + 268 : titleheight + 166) +
            parseInt(
              element2.resizableTextArea.textArea.style.height.substring(
                0,
                element2.resizableTextArea.textArea.style.height.length - 2,
              ),
            )) +
          'px)'
        elementinput.style.height =
          (this.fileList.length > 0 ? 120 : 10) +
          parseInt(
            element2.resizableTextArea.textArea.style.height.substring(
              0,
              element2.resizableTextArea.textArea.style.height.length - 2,
            ),
          ) +
          'px'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
a:hover {
  color: #f79443 !important;
}
.chatwrapper {
  overflow-wrap: anywhere;
  display: contents;
}
.h-between {
  flex-direction: column;
  justify-content: space-between !important;
}
.helpDetail {
  border-bottom: 3px solid #dddddd;
  background-color: #fff9f3;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-wrap: break-word;
  &.smallTitle {
    max-height: 200px;
    .dot::after {
      content: '...';
      font-weight: 600;
      color: #f79443;
      margin-left: 3px;
    }
  }
  &.bigTitle {
    max-height: 405px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
.helpDetail100 {
  min-height: calc(45vh - 20px);
  border-bottom: 3px solid #dddddd;
}
.chat {
  height: calc(100vh - 390px);
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.chatNotReply {
  height: calc(100vh - 312px);
  min-height: calc(100vh - 650px);
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.chatNoimg {
  height: calc(100vh - 328px);
  min-height: calc(100vh - 650px);
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.smallChat {
  height: calc(100vh - 500px);
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.miniChat {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.chatLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.boxChatLeft {
  border: 1px solid #dddddd;
  background-color: #fff9f3;
  width: fit-content;
  padding: 10px;
  max-width: 100%;
  border-radius: 15px 15px 15px 0px;
}
.boxChatRight {
  border: 1px solid #dddddd;
  width: fit-content;
  max-width: 100%;
  padding: 10px;
  border-radius: 15px 15px 0px 15px;
}
.chatRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.replyComment {
  border-top: 3px solid #dddddd;
  position: relative;
  .ant-input:focus {
    box-shadow: unset !important;
  }
  .inputchat-main {
    border: 1px solid #cecece;
    border-radius: 6px;
    height: 37px;
    width: 88%;
    position: absolute;
    right: 12px;
    &.bigBox {
      height: 166px;
    }
  }
  .chat-textarea {
    margin: -27px 0 0 43px !important;
    width: 86%;
    border: none;
    resize: none;
    overflow-y: auto;
    max-height: unset !important;
    position: absolute !important;
    border-radius: 6px;
    &.flex-textarea {
      display: flex;
      margin-top: 0;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  &.flex {
    display: flex;
  }
}
:deep(.ant-upload-picture-card-wrapper) {
  &.hidden {
    display: none;
  }

  .ant-upload-list {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .ant-upload-list-item {
      border: none;
      .ant-upload-list-item-actions {
        .ant-btn {
          background-color: transparent;
          border: none;
        }
      }
    }
    .ant-upload {
      height: 30px;
      border: none;
      border-radius: 6px;
      &.ant-upload-select {
        display: flex;
        justify-self: flex-start;
        width: 50px;
        margin: 0;
      }
    }
  }
}
.title-text {
  color: #666;
  font-weight: 600;
}
.mockUp {
  width: 150px;
  height: 200px;
  background-color: #d9d9d9;
  border-radius: 4px;
}
.border-t-none {
  border-top: none;
}
.icon-img {
  border: 1px solid #125eaf;
  border-radius: 4px;
}
.text-grey {
  color: #aaa;
  border-top: 1px solid #dddddd;
  font-size: 12px;
  .readmore-btn {
    color: #f79443 !important;
    cursor: pointer;
  }
}
.date {
  font-size: 10px;
  color: #888888;
}
.box-img {
  width: 130px;
  height: 130px;
  float: left;
  margin-right: 20px;
}
</style>
<style lang="scss">
.ant-image-img {
  flex: 1;
  border-radius: 4px;
  width: 120px !important;
  height: 120px !important;
}
.dataComment {
  flex: 1;
}
.flex-reverse {
  flex-direction: row-reverse;
}
.timeLeft {
  font-size: 10px;
  text-align: left;
}
.timeRight {
  font-size: 10px;
  text-align: right;
}
.img-modal {
  .ant-modal-body {
    padding: unset;
    overflow-y: unset;
    padding: unset;
  }
  .ant-modal-content img {
    padding-right: unset;
    max-height: 650px;
  }
}
.chat-flex {
  flex-grow: 1;
}
textarea {
  width: 300px;
  min-height: 10px;
  padding: 2px;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 4px;
  font-family: 'Inconsolata', monospace;
  font-size: 1rem;
  color: #000;
}

textarea:focus {
  outline: none;
}
.overflow-auto {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.text-id {
  color: rgb(170, 170, 170);
  font-size: 12px;
  font-weight: normal;
}
.img-modal {
  &.ant-modal {
    width: auto !important;
  }
}
</style>
