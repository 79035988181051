<template>
  <div class="wrapper-toolbar flex justify-between items-center middle toolbar">
    <div class="flex justify-start">
      <div
        v-if="checkselect || countbyloadsavelist || countbyfilter"
        class="flex py-2 max-content"
      >
        <div class="selected-text d-flex">
          <span v-if="!countbyfilter && !countbyloadsavelist">
            {{
              selectAll
                ? countTotalselects().toLocaleString()
                : selectedRowKeys.length
            }}
            Selected /
            {{ countbyfilter ? countbyfilter.toLocaleString() : count }} Users
          </span>
          <span v-if="countbyfilter && !countbyloadsavelist">
            {{
              selectAll
                ? countByFilterTotalselects().toLocaleString()
                : selectedRowKeystest().toLocaleString()
            }}
            Selected /
            {{ countbyfilter ? countbyfilter.toLocaleString() : count }} Users
          </span>
          <span v-if="countbyloadsavelist">
            <span v-if="!datapermission.includes('Search-Manual-Data')"
              >{{
                selectAll
                  ? countByLoadTotalselects().toLocaleString()
                  : selectedRowKeystest().toLocaleString()
              }}
              Selected /
            </span>
            {{
              countbyloadsavelist ? countbyloadsavelist.toLocaleString() : count
            }}
            Users
          </span>
          <div
            v-if="!datapermission.includes('Search-Manual-Data')"
            class="pl-2"
          >
            <a class="text-link pl-2 pr-2" @click="clearSelectuser">Clear </a>
          </div>
          <span
            v-if="checkload || loadnofilter"
            class="ml-3 titlesavelist d-inline-flex"
          >
            <a-tooltip placement="bottom">
              <template v-if="descriptionsavelist" #title>
                <div>{{ descriptionsavelist }}</div>
              </template>
              <h3 class="title-load">
                {{ titlebysavelist }}
              </h3>
            </a-tooltip>
            <a @click="resetloadsavelist"
              ><close-outlined class="ml-2 fs-15"
            /></a>
            <!-- <h3>
              {{ titlebysavelist }}
              <a @click="resetloadsavelist"><close-outlined class="ml-2 fs-15" /></a>
            </h3> -->
          </span>
          <div
            class="pl-2"
            v-if="
              (countbyloadsavelist || loadnofilter) &&
              datapermission.includes('User-List-Save-List-Edit') &&
              checkload
            "
          >
            |
            <a class="text-link pl-2" @click="Removeuser">Update Save List</a>
          </div>
        </div>
        <a-tooltip
          v-if="
            ((!countbyloadsavelist && !loadnofilter) || search) &&
            datapermission.includes('User-List-Save-List-Create')
          "
          placement="bottom"
        >
          <template #title>
            <div>Create save list</div>
          </template>
          <a-button class="pt-1 hover btn-icon" @click="showAddnewList"
            ><plus-outlined />
          </a-button>
        </a-tooltip>
        <a-tooltip
          v-if="
            !countbyloadsavelist &&
            !loadnofilter &&
            datapermission.includes('User-List-Tags-Create')
          "
          placement="bottom"
        >
          <template #title>
            <div>Add tags to user</div>
          </template>
          <a-button class="btn-icon hover ml-2" @click="toggleUsertags"
            ><tag-outlined />
          </a-button>
        </a-tooltip>
      </div>
    </div>
    <div class="flex box-btn-group justify-end">
      <div v-if="!checkload" class="flex box-search py-2">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            v-model:value="typesearch"
            :options="options_search"
            @change="changFieldSearch()"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @pressEnter="handleSearch()"
            @input="handleInput($event)"
          >
            <template #prefix>
              <a @click="handleSearch()">
                <search-outlined />
              </a>
            </template>
          </a-input>
          <!-- <a-input
            v-model:value="search"
            placeholder="Search"
            class="input-search"
            @input="handleInput($event)"
          /> -->
        </a-input-group>
        <!-- <a-button
          type="primary"
          :disabled="!search"
          @click="handleSearch()"
          class="btn-search"
          ><search-outlined /> Search</a-button
        > -->
        <!-- <div v-if="cacheSearch && search" class="ml-3 pt-2">
          <a @click="clearSearch()" class="text-link">Clear</a>
        </div> -->
      </div>
      <div v-if="windowWidth > 1500" class="py-2 d-flex">
        <a-tooltip v-if="!checkload" placement="bottom">
          <template #title>
            <div>Filter</div>
          </template>
          <a-button
            v-if="datapermission.includes('User-List-Filter')"
            :class="
              totalBadge > 0 ? 'hover btn-icon filter-active' : 'hover btn-icon'
            "
            @click="toggleFilter"
          >
            <div class="d-flex">
              <img
                v-if="totalBadge > 0"
                class="left"
                src="@/assets/icons/button/Filter-active.svg"
                alt
              />
              <img
                v-if="!totalBadge || totalBadge == 0"
                class="left"
                src="@/assets/icons/button/Filter.svg"
                alt
              />
              <a-badge
                v-if="totalBadge > 0"
                class="mt-1"
                :count="totalBadge"
                :showZero="true"
                :overflow-count="999999"
                :number-style="{
                  backgroundColor: '#f9a601',
                  color: '#999',
                  boxShadow: '0 0 0 1px #f9a601 inset',
                }"
              />
            </div>
          </a-button>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            <div>Display fields</div>
          </template>
          <a-button
            v-if="datapermission.includes('User-List-View')"
            class="hover btn-icon"
            @click="toggleDisplayFields()"
          >
            <img class="left top-5" src="@/assets/icons/button/Control.svg" />
          </a-button>
        </a-tooltip>
        <a-tooltip placement="bottom">
          <template #title>
            <div>Load save list</div>
          </template>
          <a-button
            v-if="datapermission.includes('User-List-Save-List')"
            class="hover btn-icon"
            @click="toggleSavedList()"
          >
            <img class="left" src="@/assets/icons/button/Schedule.svg" alt />
          </a-button>
        </a-tooltip>
        <a-tooltip v-if="checkload" placement="bottom">
          <template #title>
            <div>Export</div>
          </template>
          <a-button
            v-if="datapermission.includes('User-List-Export')"
            class="hover btn-icon"
            @click="clickExport()"
            :outlined="toolbarStates.showExport ? false : true"
            :color="'blue-1'"
          >
            <img class="left" src="@/assets/icons/button/Export.svg" alt />
          </a-button>
        </a-tooltip>
        <!-- toolbarStates.showExport ::{{ toolbarStates.showExport }} -->
        <a-tooltip v-if="!checkload" placement="bottom">
          <template #title>
            <div>Realtime data</div>
          </template>
          <a-button
            v-if="datapermission.includes('Search-Real-Time-Data')"
            class="hover btn-icon"
            @click="showUserlistRealtime()"
            ><img class="left" src="@/assets/icons/button/Solution.svg" alt />
          </a-button>
        </a-tooltip>
        <div>
          <!-- <a-tooltip
          v-if="!checkselect && !countbyfilter && !cacheSearch"
          placement="bottom"
        >
          <template #title>
            <div>Export All</div>
          </template>
          <a-button
            v-if="datapermission.includes('Search-Real-Time-Data')"
            class="hover btn-icon"
            @click="clickExportAll()"
            :outlined="toolbarStates.showExportAll ? false : true"
            ><img class="left" src="@/assets/icons/button/Export.svg" alt />
          </a-button>
        </a-tooltip> -->
        </div>
        <div
          v-if="
            !checkselect &&
            !countbyfilter &&
            !cacheSearch &&
            datapermission.includes('User-List-Export')
          "
        >
          <a-button type="teriary" @click="clickExportAll()"
            ><file-excel-outlined /> Export All</a-button
          >
        </div>
      </div>
      <div v-else class="py-2 d-flex">
        <a-tooltip v-if="!checkload" placement="bottom">
          <template #title>
            <div>Filter</div>
          </template>
          <a-button
            v-if="datapermission.includes('User-List-Filter')"
            :class="
              totalBadge > 0 ? 'hover btn-icon filter-active' : 'hover btn-icon'
            "
            @click="toggleFilter"
          >
            <div class="d-flex">
              <img
                v-if="totalBadge > 0"
                class="left"
                src="@/assets/icons/button/Filter-active.svg"
                alt
              />
              <img
                v-if="!totalBadge || totalBadge == 0"
                class="left"
                src="@/assets/icons/button/Filter.svg"
                alt
              />
              <a-badge
                v-if="totalBadge > 0"
                class="mt-1"
                :count="totalBadge"
                :showZero="true"
                :overflow-count="999999"
                :number-style="{
                  backgroundColor: '#f9a601',
                  color: '#999',
                  boxShadow: '0 0 0 1px #f9a601 inset',
                }"
              />
            </div>
          </a-button>
        </a-tooltip>
        <a-popover
          v-model:visible="moreFilter"
          trigger="click"
          placement="bottomRight"
        >
          <template #content>
            <div class="action-history">
              <div
                v-if="datapermission.includes('User-List-View')"
                class="filter-action"
                @click="toggleDisplayFields()"
              >
                <control-outlined />Display Fields
              </div>
              <div
                v-if="datapermission.includes('User-List-Save-List')"
                class="filter-action"
                @click="toggleSavedList()"
              >
                <schedule-outlined />Load Save List
              </div>
              <div
                v-if="
                  !checkload && datapermission.includes('Search-Real-Time-Data')
                "
                class="filter-action"
                @click="showUserlistRealtime()"
              >
                <solution-outlined />Real-Time Data
              </div>
              <div
                v-if="
                  !checkselect &&
                  !countbyfilter &&
                  !cacheSearch &&
                  datapermission.includes('User-List-Export')
                "
                class="filter-action"
                @click="clickExportAll()"
              >
                <file-excel-outlined />Export All
              </div>
              <div
                v-if="checkload && datapermission.includes('User-List-Export')"
                class="filter-action"
                @click="clickExport()"
              >
                <file-excel-outlined />Export
              </div>
            </div>
          </template>
          <a-tooltip placement="bottom">
            <template #title>
              <div>More</div>
            </template>
            <a-button class="hover btn-icon">
              <img src="@/assets/icons/more.svg" alt />
            </a-button>
          </a-tooltip>
        </a-popover>
      </div>
    </div>
  </div>
  <a-modal
    :visible="visible"
    title="User List - Realtime data"
    @cancel="handleCancel"
    @ok="false"
    class="modalrealtime"
    width="900px"
    :ok-button-props="{ style: { display: 'none' } }"
    :closable="false"
    :centered="true"
  >
    <div class="d-flex">
      <div class="mr-3 mb-3">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            style="width: 150px"
            v-model:value="typesearchrealtime"
            :options="options_searchrealtime"
          />
          <a-input
            v-model:value="searchrealtime"
            placeholder="Search"
            @pressEnter="clicksearch()"
          >
            <template #prefix>
              <a @click="clicksearch()">
                <search-outlined />
              </a>
            </template>
          </a-input>
        </a-input-group>
      </div>
      <!-- <div>
        <a-button
          type="primary"
          @click="clicksearch()"
          class="btn-search"
          :disabled="!searchrealtime"
          ><search-outlined /> Search</a-button
        >
      </div> -->
    </div>
    <div class="wrap-table-modal" @scroll="handleScrollModal">
      <VLoadingTable :value="overlaytablemodal" />
      <a-table
        row-key="partitionkey"
        class="fixed-head font-table-modal"
        :dataSource="dataRealtime"
        :columns="realtimecolumn"
        :pagination="false"
        :loading="loading"
      >
        <template #user_id="{ text, record }">
          <div class="userirealtimeddotafter">
            <a class="link" @click="goToUserDetail(record)">{{ text }}</a>
            <!-- <router-link
              class="userid-modal"
              :to="{
                name: 'user_detail',
                params: {
                  id: text,
                  first_name: record.first_name,
                  last_name: record.last_name,
                },
              }"
            >
              {{ text }}
            </router-link> -->
          </div>
        </template>
        <template #contact_number="{ text }">
          <div>{{ formatPhone(text) }}</div>
        </template>
      </a-table>
    </div>
  </a-modal>
</template>
<script>
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import _ from 'lodash'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserListToolbar',
  props: {
    dataapp: {
      type: Array,
      default: () => [],
    },
    permission: {
      type: Array,
    },
    toggleUsertags: {
      type: Function,
    },
    toggleFilter: {
      type: Function,
    },
    resetloadsavelist: {
      type: Function,
    },
    showAddnewList: {
      type: Function,
    },
    clearSelectuser: {
      type: Function,
    },
    Removeuser: {
      type: Function,
    },
    clearall: {
      type: Function,
    },
    checkselect: {
      type: Boolean,
    },
    selectedRowKeys: {
      type: Array,
    },
    count: {
      type: Number,
    },

    items: {
      type: Array,
    },

    selectAll: {
      type: Boolean,
    },
    loadnofilter: {
      type: Boolean,
    },
    checkload: {
      type: Boolean,
    },
    countbyloadsavelist: {
      type: Number,
    },
    titlebysavelist: {
      type: String,
    },
    descriptionsavelist: {
      type: String,
    },
    totalrows: {
      type: Number,
    },
    sumCheck: {
      type: Number,
    },
    totalBadge: {
      type: Number,
    },
  },
  inject: ['tableHeaders', 'toolbarStates'],
  components: {},
  mixins: [],
  emits: [],
  data: function () {
    return {
      type: 'list',
      moreFilter: false,
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      loading: false,
      setexpiry: 1,
      search: '',
      visible: false,
      usertags_modal: false,
      nomoremodal: false,
      overlaytablemodal: false,
      modalPage: 1,
      searchrealtime: '',
      title: '',
      description: '',
      typesearchrealtime: 'user_id',
      initselectedRowKeys: null,
      options_searchrealtime: [
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'Contact Number',
          value: 'contact_number',
        },
        {
          label: 'First Name',
          value: 'first_name',
        },
        {
          label: 'Last Name',
          value: 'last_name',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Reference ID',
          value: 'ref_id',
        },
        {
          label: 'Help ID',
          value: 'help_id',
        },
        {
          label: 'ID Card / Passport',
          value: 'id_card_number',
        },
      ],
      searchObject: {
        user_id: '',
        contact_number: '',
        first_name: '',
        last_name: '',
        email: '',
        ref_id: '',
        help_id: '',
        id_card_number: '',
      },
      typesearch: 'userid',
      options_search: [
        // {
        //   label: 'All',
        //   value: 'search_all',
        // },
        {
          label: 'User ID',
          value: 'userid',
        },
        {
          label: 'Contact Number',
          value: 'contact_number',
        },
        {
          label: 'First Name',
          value: 'first_name',
        },
        {
          label: 'Last Name',
          value: 'last_name',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Reference ID',
          value: 'ref_id',
        },
        {
          label: 'Help ID',
          value: 'help_id',
        },
        {
          label: 'ID Card / Passport',
          value: 'id_card_number',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
      ],
      dataRealtime: [],
      realtimecolumn: [
        {
          title: 'User ID',
          dataIndex: 'user_id',
          key: 'user_id',
          slots: { customRender: 'user_id' },
          width: 120,
          align: 'left',
        },
        {
          title: 'First Name',
          dataIndex: 'first_name',
          key: 'first_name',
          width: 120,
          align: 'left',
        },
        {
          title: 'Last Name',
          dataIndex: 'last_name',
          key: 'last_name',
          width: 120,
          align: 'left',
        },
        {
          title: 'Contact Number',
          dataIndex: 'contact_number',
          key: 'contact_number',
          slots: { customRender: 'contact_number' },
          width: 120,
          align: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          slots: { customRender: 'email' },
          width: 130,
          align: 'left',
        },
      ],
      totalFilter: null,
      countbyfilter: null,
      cacheSearch: false,
      windowWidth: window.innerWidth,
    }
  },
  computed: {
    headers: function () {
      return this.tableHeaders.value
    },
  },
  created() {
    this.setOptionsearch()
    if (Cache.getCache('handleSearch')) {
      this.search = Cache.getCache('handleSearch')[0].search
      this.typesearch = Cache.getCache('handleSearch')[0].typesearch
      this.cacheSearch = true
    }
  },
  watch: {
    selectAll(val) {
      if (val == true) {
        this.selectedRowKeystest(true)
      } else {
        this.selectedRowKeystest(false)
      }
    },
    // countbyfilter(val){
    //   if(Cache.getCache('setFilterTotal')){
    //     return val = Cache.getCache('setFilterTotal')
    //   }else{
    //     return val
    //   }
    // }
  },
  mounted: function () {
    this.countbyfilter = Cache.getCache('setFilterTotal')
    if (this.countbyfilter == 'undefined') {
      this.countbyfilter = null
    }
    this.onResize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.$forceUpdate()
    },
    handleInput(event) {
      if (this.typesearch == 'campaign_id')
        this.search = event.target.value.replace(/[^0-9]/g, '')
    },
    changFieldSearch() {
      this.search = ''
    },
    setCacheFilterTotal() {
      this.countbyfilter = Cache.getCache('setFilterTotal')
    },
    resetFilter() {
      this.countbyfilter = null
    },
    clearSearch() {
      this.cacheSearch = false
      this.typesearch = 'userid'
      this.search = ''
      var search = {
        userid: {
          value: '',
          criteria: 'And',
        },
        contact_number: {
          value: '',
          criteria: 'And',
        },
        first_name: {
          value: '',
          criteria: 'And',
        },
        last_name: {
          value: '',
          criteria: 'And',
        },
        email: {
          value: '',
          criteria: 'And',
        },
        ref_id: {
          value: '',
          criteria: 'And',
        },
        help_id: {
          value: '',
          criteria: 'And',
        },
        id_card_number: {
          value: '',
          criteria: 'And',
        },
        campaign_id: {
          value: null,
          criteria: 'And',
        },
      }
      search[this.typesearch].value = ''
      Cache.removeCache('handleSearch')
      this.$emit('handleClearSearch', search)
      console.log(search)
    },
    handleSearch() {
      if (!this.search) {
        this.clearSearch()
      } else {
        this.cacheSearch = true
        var search_campaignid = this.search
        if (this.typesearch == 'campaign_id') {
          search_campaignid = parseInt(this.search)
        }
        var search = {
          userid: {
            value: '',
            criteria: 'And',
          },
          contact_number: {
            value: '',
            criteria: 'And',
          },
          first_name: {
            value: '',
            criteria: 'And',
          },
          last_name: {
            value: '',
            criteria: 'And',
          },
          email: {
            value: '',
            criteria: 'And',
          },
          ref_id: {
            value: '',
            criteria: 'And',
          },
          help_id: {
            value: '',
            criteria: 'And',
          },
          id_card_number: {
            value: '',
            criteria: 'And',
          },
          campaign_id: {
            value: null,
            criteria: 'And',
          },
        }
        search[this.typesearch].value = search_campaignid
        search.contact_number.value = search.contact_number.value.replaceAll(
          '-',
          '',
        )
        var searchCache = []
        searchCache.push({
          search: search_campaignid,
          typesearch: this.typesearch,
        })
        Cache.setCache('handleSearch', searchCache)
        this.$emit('handleSearch', search)
      }
    },
    goToUserDetail(record) {
      localStorage.setItem('userinfo', JSON.stringify(record))
      this.$router.push({
        name: 'user_detail',
        params: {
          id: record.user_id,
        },
      })
    },
    countTotalselects() {
      var total =
        parseInt(this.count.replace(/[, ]+/g, '').trim()) -
        (this.items.length - this.selectedRowKeys.length)
      return total
    },
    countByFilterTotalselects() {
      var total =
        this.countbyfilter - (this.items.length - this.selectedRowKeys.length)
      return total
    },
    countByLoadTotalselects() {
      var total =
        this.countbyloadsavelist -
        (this.items.length - this.selectedRowKeys.length)
      return total
    },
    selectedRowKeystest(all = null) {
      var total = null
      if (all == false) {
        this.clearall()
        total = this.selectedRowKeys.length
      } else {
        total = this.totalrows - (this.sumCheck - this.selectedRowKeys.length)
      }
      // var total =  (this.totalrows - this.selectedRowKeys.length) + (this.totalrows - (this.totalrows  - this.selectedRowKeys.length))
      return total
      // return this.totalrows - this.selectedRowKeys.length
    },
    toggleSavedList: function () {
      this.moreFilter = false
      this.$emit('loadSavelist')
      this.toolbarStates.showPanelSavedList =
        !this.toolbarStates.showPanelSavedList
    },
    // toggleFilter: function () {
    //   this.toolbarStates.showPanelFilter = !this.toolbarStates.showPanelFilter
    // },
    toggleDisplayFields: function () {
      this.moreFilter = false
      this.toolbarStates.showPanelCustomFields =
        !this.toolbarStates.showPanelCustomFields
    },
    showDialogCreateNewList: function () {
      console.log(
        'showDialogCreateList',
        this.toolbarStates.showDialogCreateList,
      )
      this.toolbarStates.showDialogCreateList =
        !this.toolbarStates.showDialogCreateList
    },
    clickAddNewUser: function (e) {
      console.log(e)
    },
    clickExport: function () {
      this.toolbarStates.showExport = !this.toolbarStates.showExport
    },
    clickExportAll: function () {
      this.moreFilter = false
      this.toolbarStates.showExportAll = !this.toolbarStates.showExportAll
    },
    clicksearch() {
      this.dataRealtime = []
      this.modalPage = 1
      this.loading = true
      this.searchObject.user_id =
        this.typesearchrealtime == 'user_id' ? this.searchrealtime : ''
      this.searchObject.contact_number =
        this.typesearchrealtime == 'contact_number' ? this.searchrealtime : ''
      this.searchObject.first_name =
        this.typesearchrealtime == 'first_name' ? this.searchrealtime : ''
      this.searchObject.last_name =
        this.typesearchrealtime == 'last_name' ? this.searchrealtime : ''
      this.searchObject.email =
        this.typesearchrealtime == 'email' ? this.searchrealtime : ''
      this.searchObject.ref_id =
        this.typesearchrealtime == 'ref_id' ? this.searchrealtime : ''
      this.searchObject.help_id =
        this.typesearchrealtime == 'help_id' ? this.searchrealtime : ''
      this.searchObject.id_card_number =
        this.typesearchrealtime == 'id_card_number' ? this.searchrealtime : ''
      this.getRealtime().then(() => {})
    },
    showUserlistRealtime() {
      this.moreFilter = false
      this.visible = true
      this.modalPage = 1
      this.dataRealtime = []
      this.searchrealtime = ''
      this.typesearchrealtime = 'user_id'
    },
    handleScrollModal(event) {
      if (!this.nomoremodal && !this.overlaytablemodal) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytablemodal = true
          this.modalPage++
          this.getRealtime().then(() => {
            this.overlaytablemodal = false
          })
        }
      }
    },
    getRealtime() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserlistRealtime(
          this.searchObject.user_id,
          this.searchObject.contact_number,
          this.searchObject.first_name,
          this.searchObject.last_name,
          this.searchObject.email,
          this.searchObject.ref_id,
          this.searchObject.help_id,
          this.searchObject.id_card_number,
          this.modalPage,
        ).subscribe(
          data => {
            if (this.modalPage == 1) {
              this.dataCard = []
            }
            _.forEach(data.result, item => {
              item.first_name = item.first_name ? item.first_name : '-'
              item.last_name = item.last_name ? item.last_name : '-'
              item.email = item.email ? item.email : '-'
              this.dataRealtime.push(item)
            })
            if (data.result.length < 20) {
              this.nomoremodal = true
            } else {
              this.nomoremodal = false
            }
            this.loading = false
            resolve(this.dataRealtime)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    showUsertags() {
      this.usertags_modal = true
    },
    // AddnewList(){
    //   this.$emit('titleNewlist', this.title)
    //   this.$emit('descriptionNewlist', this.description)
    // },
    handleCancel() {
      this.visible = false
      this.usertags_modal = false
    },
    formatPhone(dirtyNumber) {
      if (dirtyNumber == null) return '-'
      return dirtyNumber
        .replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    },
    setOptionsearch() {
      if (Cache.getCache('app').id != '402956841938506') {
        this.options_search = this.options_search.filter(
          x => x.label != 'ID Card / Passport',
        )
        this.options_searchrealtime = this.options_searchrealtime.filter(
          x => x.label != 'ID Card / Passport',
        )
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
@import '@/style/_mixin.scss';

.wrapper-toolbar {
  display: flex;

  .btn.btn-default,
  .input-group {
    border-color: $color-grey-line;
  }

  @include breakpoint-max($desktop-xs) {
    flex-direction: column;
  }

  .selected-text {
    padding: 8px 14px 0px 0px;
  }

  .box-btn-group {
    width: 100%;

    .ant-btn {
      margin: 0 5px;
      vertical-align: middle;
    }

    .box-search {
      border-right: 1px solid $color-grey-line;
      margin-right: 10px;
      padding-right: 10px;
    }

    .search {
      float: left;
      width: auto;
      margin-right: 10px;

      .input-search {
        width: 200px;
        border-left: none;
      }

      .select-search {
        width: 150px;
      }
    }
  }

  .btn-search {
    color: white;
  }
}
</style>
<style lang="scss">
.wrapper-toolbar {
  .menu {
    top: 100%;
  }
}

.userid-modal {
  color: #f79443;
  text-decoration: underline;
}

.userid-modal:hover {
  color: #f79443 !important;
  text-decoration: underline;
}

.userirealtimeddotafter {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-usertags {
  .ant-input {
    width: -webkit-fill-available !important;
    margin-top: 10px;
  }

  .ant-select {
    width: -webkit-fill-available !important;
    margin-top: 10px;
  }
}

.titlesavelist {
  background-color: #fafafa;
  border-radius: 2px;
  padding: 4px;
  border: solid 1px #d9d9d9;
  margin-top: -8px;
}

.fs-15 {
  font-size: 15px;
}

.title-load {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 450px;
}

.filter-active {
  border-color: #f9a601 !important;
}

.filter-action {
  cursor: pointer;
  width: 200px;
  padding: 8px;
  display: flex;

  img {
    width: 16px;
    margin-right: 8px;
  }

  svg {
    margin-top: 3px;
    margin-right: 8px;
  }

  &:hover {
    background-color: #fff6e6;
    color: #f9a601;
  }
}
</style>
