<template>
    <AppLayout>
    <div>
        sda
    </div>
    </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/default.vue'
export default {
  name: 'CampaignList',
  components: {
    AppLayout,
    // SearchBar,
  },
  data() {
    return {
    
    }
  },
  computed: {
    
  },
  mounted() {
  },
  created() {
    this.$store.commit('setLoading', false)
  },
  methods: {
  },
}
</script>
