<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40 addtag"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <h4 class="">Add Tag</h4>
          </div>
          <div class="border-b flex-auto overflow-y-auto text-gray">
            <div class="">
              <a-radio-group v-model:value="setcreate">
                <div class="row ml-4 mt-3">
                  <div class="col-6">
                    <a-radio class="radio-expiry pt-1" :value="1">
                      Create tag group
                    </a-radio>
                  </div>
                  <div class="col-6">
                    <a-radio class="radio-expiry" :value="2">
                      Create tag type
                    </a-radio>
                  </div>
                </div>
              </a-radio-group>
              <div class="px-4" v-if="setcreate == 1">
                <div class="mb-2">Tag Group</div>
                <a-input
                  class="w-100"
                  v-model:value="tag_group"
                  :disabled="setcreate != 1"
                />
              </div>
              <div class="px-4" v-if="setcreate == 2">
                <div class="mb-2">Tag Group</div>
                <a-select
                  class="w-100"
                  v-model:value="tag_group_options"
                  :options="options_tag_group"
                  :disabled="setcreate != 2"
                />
                <div class="mt-3 mb-2">Tag Name</div>
                <a-input :disabled="setcreate != 2" v-model:value="tag_name" />
              </div>
            </div>
          </div>
          <div class="flex-none px-4 py-3 right">
            <a-button
              class="mr-3 btn-create"
              type="blue-2"
              @click="dismiss(true)"
            >
              Cancel
            </a-button>
            <a-button
              type="primary"
              class="btn-search btn-create"
              @click="createTag()"
            >
              Save
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    title="Add Users to Tag"
    class="modal-usertags"
    :visible="save_modal"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="border-t-2 p-4">
      <div>
        <label class="text-save-modal">“Area”</label>
        was created as a new tag group
      </div>
      <div class="mt-3">
        <label class="text-save-modal">11 Tags</label>
        were added successfully
      </div>
      <div class="mt-3">
        <label class="text-save-modal">1 Tags</label>
        was failed to add
      </div>
    </div>
  </a-modal>
</template>
<script>
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import _ from 'lodash'
export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    datalogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['getTagTypes', 'getTagGroup'],
  data: function () {
    return {
      setcreate: 1,
      save_modal: false,
      isactive: true,
      tag_new: null,
      tag_group: '',
      tag_name: '',
      options_tag_group: [],
      tag_group_options: '',
      select_tag: [],
      value2: null,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      levelname: '',
      description: '',
      earnpoint: '',
      benefits: '',
      isErrorName: false,
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getTagGroup()
      }
    },
  },
  mounted() {},
  created: function () {},
  methods: {
    getTagGroup() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagGroup().subscribe(
          res => {
            this.datagroup = res.result
            _.forEach(this.datagroup, (item, index) => {
              this.options_tag_group[index] = {
                label: item.name,
                value: item.name,
              }
            })
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    createTag() {
      if (this.setcreate == 1) {
        this.createTaggroup()
      } else {
        this.createTagtypes()
      }
    },
    createTaggroup() {
      var datataggroup = {
        appid: ConfigManager.getAppId(),
        name: this.tag_group,
        active: this.isactive,
      }
      this.PostTagGroup(datataggroup)
    },
    PostTagGroup(datataggroup) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTagGroup(datataggroup)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('handleClose')
                this.$emit('getTagTypes')
                this.$emit('getTagGroup')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    createTagtypes() {
      var datatagtypes = {
        app_id: ConfigManager.getAppId(),
        group_name: this.tag_group_options,
        name: this.tag_name,
        active: this.isactive,
      }
      this.PostTagTypes(datatagtypes)
    },
    PostTagTypes(datatagtypes) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTagTypes(datatagtypes)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('handleClose')
                this.$emit('getTagTypes')
                this.$emit('getTagGroup')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    dismiss: function () {
      this.tag_group = ''
      this.tag_group_options = ''
      this.tag_name = ''
      this.setcreate = 1
      this.$emit('handleClose')
    },
    saveAddTag() {
      this.$emit('handleClose')
      this.save_modal = true
    },
    handleCancel() {
      this.save_modal = false
      this.select_tag = []
      this.tag_name = []
    },
  },
}
</script>
