<template>
  <AppLayout :disable-menu="false" :menu-callcenter="true">
    <div class="d-flex justify-content-between">
      <TitleBreadcrumbs
        class="mt-4 ml-5"
        title="Job list"
        :databreadcrumbs="breadcrumbs"
      />
      <!-- <a class="link ticket" @click="goToTicketManagement()"
        ><setting-outlined class="mr-1" />Ticket Management</a
      > -->
    </div>
    <div class="callcenter">
      <Joblist />
    </div>
  </AppLayout>
</template>

<script>
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import AppLayout from '@/components/Layout/default.vue'
import Joblist from '@/module/CallCenter/pages/CallCenterManagement/Joblist.vue'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    // TopBar,
    // Footer,
    AppLayout,
    // SidebarMenu,
    TitleBreadcrumbs,
    Joblist,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: 'select_application',
          breadcrumbName: 'Select Applications',
        },
        {
          name: null,
          breadcrumbName: 'Call Center Management',
        },
      ],
      activeKey: '1',
      startdate: null,
      enddate: null,
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      datasetFeatures: JSON.parse(localStorage.getItem('setFeatures')),
      subject: null,
      sidebarHeaderconfig: { bgColor: '#fff' },
      sidebarMenu: [],
      sidebarOpen: true,
      overlay: true,
      applications: [],
      rawapp: [],
      mode: 'card',
      typesearch: 'serial_number',
      options_search: [
        {
          label: 'Serial Number',
          value: 'serial_number',
        },
      ],
      showModalAdvanceSearch: false,
      showModalTicketDetail: false,
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'My Applications',
        },
      ]
    },
  },
  mounted() {},
  created() {
    this.$store.commit('setLoading', false)
  },
  methods: {
    toggleAdvanceSearch() {
      this.showModalAdvanceSearch = !this.showModalAdvanceSearch
    },
    toggleTicketDetail() {
      this.showModalTicketDetail = !this.showModalTicketDetail
    },
    goToTicketManagement() {
      this.$router.push({
        name: 'ticketmanagement',
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    postChangeUserStatus() {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Confirm to change Use Status ?',
        content: 'You are about to change status to used.',
        okText: 'Confirm',
        cancelText: 'Cancel',
        width: '480px',
        icon: <QuestionCircleOutlined />,
        okButtonProps: {
          type: 'primary',
        },
        onOk: () => {},
        onCancel: () => {},
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.left-menu-width {
  // flex: 0 0 $left-menu-width;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 8%);
  width: $left-menu-width;
  z-index: 15;

  &.collapse {
    // width: 44px;
    width: 64px;
    // flex: 0 0 55px;
  }
}
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
  .box-select-app {
    padding: 0px 20px 20px 20px;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.callcenter {
  padding: 0px 20px 20px 20px;
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: 2px solid #c9c9c961;
  }
}
.ticket {
  margin: 70px 20px 16px 20px;
}
</style>
