<template>
  <a-modal
    v-model:visible="isPop"
    width="600px"
    wrap-class-name="modal-announcement"
    title="Term & Condition"
    :closable="false"
    :centered="true"
    :mask-closable="false"
    @cancel="closeModal()"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="data.content"></div>
    <template #footer>
      <div class="d-flex justify-content-between w-100 text-left">
        <a-checkbox @change="onChange"> Accept,Don't show again. </a-checkbox>
        <div class="d-flex">
          <a-button
            key="submit"
            type="primary"
            :disabled="!isDisable"
            @click="closeConfirm()"
          >
            Confirm Acceptation
          </a-button>
          <a-button @click="closeModal()"> Not Acceptation </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>

<script>
import ResponsiveMixin from '@/mixin/ResponsiveMixin'

export default {
  name: 'TermAndCondition',
  components: {},
  mixins: [ResponsiveMixin],
  props: {
    isShow: Boolean,
    data: Object,
  },
  data() {
    return {
      isDisable: false,
      isPop: this.isShow,
    }
  },
  created() {},
  methods: {
    onChange(e) {
      this.isDisable = e.target.checked
      this.$emit('checkBox', e.target.checked)
    },
    closeConfirm() {
      this.$emit('closeModal')
    },
    closeModal() {
      this.$router.push({ name: 'logout' })
    },
  },
}
</script>
