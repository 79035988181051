<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-importuser bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex">
              <h4 class="pr-2">{{ dataDetails.original_file_name }}</h4>
            </div>
          </div>
          <div class="flex-auto overflow-y-auto">
            <div class="p-3">
              <div class="fw-700 mb-3">
                Import User
                <a-badge
                  :count="dataDetails.import_result.totals"
                  :class="type == 'default' ? null : 'count-' + 'list'"
                />
              </div>
              <div class="d-flex gap-2">
                <div class="success">
                  <div
                    :class="
                      dataDetails.import_result.totals_fail == 0
                        ? 'border-status complete'
                        : 'border-status'
                    "
                  >
                    <div class="d-flex justify-between">
                      <div><check-circle-outlined class="true" /> Success</div>
                      <div>
                        {{
                          dataDetails.import_result.totals_success.toLocaleString()
                        }}
                        <label class="text-gray fs-14">items</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="failed">
                  <div
                    :class="
                      dataDetails.import_result.totals_fail > 0
                        ? 'border-status error'
                        : 'border-status'
                    "
                  >
                    <div class="d-flex justify-between">
                      <div><close-circle-outlined class="lock" /> Failed</div>
                      <div>
                        {{
                          dataDetails.import_result.totals_fail.toLocaleString()
                        }}
                        <label class="text-gray fs-14">items</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wrap-table-import">
                <VLoadingTable :value="overlaytable" />
                <a-table
                  row-key="id"
                  class="fixed-head"
                  :dataSource="dataImportsList"
                  :columns="columnsImports"
                  :pagination="false"
                >
                  <template #first_name="{ record }">
                    {{ record.first_name || '-' }}
                  </template>
                  <template #last_name="{ record }">
                    {{ record.last_name || '-' }}
                  </template>
                  <template #contact_number="{ record }">
                    {{ contactNumber(record.contact_number) || '-' }}
                  </template>
                  <template #email="{ record }">
                    {{ record.email || '-' }}
                  </template>
                  <template #id_card="{ record }">
                    {{ record.id_card_number || '-' }}
                  </template>
                  <template #gender="{ record }">
                    {{ record.gender || '-' }}
                  </template>
                  <template #birthday="{ record }">
                    {{ record.birth_day || '-' }}
                  </template>
                  <template #address="{ record }">
                    {{ record.address || '-' }}
                  </template>
                  <template #sub_district_code="{ record }">
                    {{ record.sub_district_code || '-' }}
                  </template>
                  <template #sub_district_name="{ record }">
                    {{ record.sub_district_name || '-' }}
                  </template>
                  <template #district_code="{ record }">
                    {{ record.district_code || '-' }}
                  </template>
                  <template #district_name="{ record }">
                    {{ record.district_name || '-' }}
                  </template>
                  <template #province_code="{ record }">
                    {{ record.province_code || '-' }}
                  </template>
                  <template #province_name="{ record }">
                    {{ record.province_name || '-' }}
                  </template>
                  <template #country_code="{ record }">
                    {{ record.country_code || '-' }}
                  </template>
                  <template #country_name="{ record }">
                    {{ record.country_name || '-' }}
                  </template>
                </a-table>
              </div>
            </div>
          </div>
          <div>
            <div class="bg-con">
              <div class="d-flex justify-between">
                <dvi
                  ><div>
                    Import Date :
                    {{
                      formatDate(
                        dataDetails.created_date,
                        'DD/MMM/YYYY - HH:mm',
                        -7,
                      )
                    }}
                  </div>
                  <div>
                    File name : {{ dataDetails.original_file_name }}
                  </div></dvi
                >
                <div v-if="dataDetails.import_result.totals_fail > 0">
                  <a-button type="primary" @click="dowloadFaield"
                    ><download-outlined />Download Failed list</a-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between px-4 py-3">
            <a-button class="w100" type="primary-2" @click="dismiss(true)">
              Close
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { config } from '@/config/bzbsconfig.js'
import _ from 'lodash'
import Util from '@/helper/Utility.js'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataDetails: {
      type: Object,
    },
  },
  computed: {},
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'Completed',
          color: 'green',
        },
        {
          id: 4,
          name: 'L1Approved',
          color: 'yellow',
        },
        {
          id: 5,
          name: 'Failed',
          color: 'red',
        },
      ],
      statusnoti: [
        {
          id: 1,
          name: 'No',
          color: 'red',
        },
        {
          id: 2,
          name: 'no',
          color: 'red',
        },
        {
          id: 3,
          name: 'Yes',
          color: 'green',
        },
        {
          id: 4,
          name: 'yes',
          color: 'green',
        },
      ],
      dataImportsList: [],
      columnsImports: [
        {
          title: 'First Name',
          dataIndex: 'first_name',
          slots: { customRender: 'first_name' },
          key: 'first_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'Last Name',
          dataIndex: 'last_name',
          slots: { customRender: 'last_name' },
          key: 'last_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'Contact Number',
          dataIndex: 'contact_number',
          slots: { customRender: 'contact_number' },
          key: 'contact_number',
          width: 160,
          align: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          slots: { customRender: 'email' },
          key: 'email',
          width: 160,
          align: 'left',
        },
        {
          title: 'ID Card Number',
          dataIndex: 'id_card',
          slots: { customRender: 'id_card' },
          key: 'id_card',
          width: 160,
          align: 'left',
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          slots: { customRender: 'gender' },
          key: 'gender',
          width: 120,
          align: 'left',
        },
        {
          title: 'Birthday',
          dataIndex: 'birthday',
          slots: { customRender: 'birthday' },
          key: 'birthday',
          width: 120,
          align: 'left',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          slots: { customRender: 'address' },
          key: 'address',
          width: 160,
          align: 'left',
        },
        {
          title: 'Sub District Code',
          dataIndex: 'sub_district_code',
          slots: { customRender: 'sub_district_code' },
          key: 'sub_district_code',
          width: 160,
          align: 'left',
        },
        {
          title: 'Sub District Name',
          dataIndex: 'sub_district_name',
          slots: { customRender: 'sub_district_name' },
          key: 'sub_district_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'District Code',
          dataIndex: 'district_code',
          slots: { customRender: 'district_code' },
          key: 'district_code',
          width: 160,
          align: 'left',
        },
        {
          title: 'District Name',
          dataIndex: 'district_name',
          slots: { customRender: 'district_name' },
          key: 'district_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'Province Code',
          dataIndex: 'province_code',
          slots: { customRender: 'province_code' },
          key: 'province_code',
          width: 160,
          align: 'left',
        },
        {
          title: 'Province Name',
          dataIndex: 'province_name',
          slots: { customRender: 'province_name' },
          key: 'province_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'Country Code',
          dataIndex: 'country_code',
          slots: { customRender: 'country_code' },
          key: 'country_code',
          width: 160,
          align: 'left',
        },
        {
          title: 'Country Name',
          dataIndex: 'country_name',
          slots: { customRender: 'country_name' },
          key: 'country_name',
          width: 160,
          align: 'left',
        },
      ],
      page: 1,
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.$store.commit('setLoading', true)
        this.getImportsUserslist().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {},
  created: function () {},
  methods: {
    dowloadFaield() {
      window.location = this.dataDetails.import_result.fail_blob_url
    },
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    getImportsUserslist() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetImportUserListDetail(
          this.dataDetails.id,
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataImportsList = []
            }
            _.forEach(data.result.data_items, item => {
              this.dataImportsList.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataImportsList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    dismiss: function () {
      this.remark = ''
      this.$emit('handleClose')
    },
    handleCancel() {
      this.modalReject = false
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .bg-con {
    margin: 15px;
    padding: 15px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }

  .ant-btn {
    width: 100%;
  }

  .input-error {
    border-color: #ff5252 !important;
  }

  .text-error {
    color: #ff5252;
  }

  .pd-t-21 {
    padding-top: 21px;
  }
}

.w100 {
  width: 100% !important;
}

.pointer {
  cursor: pointer;
}

.w-log-importuser {
  width: 70rem;
  .mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .bg-title {
    background-color: #f3f6f9;
    border-bottom: 4px solid #ecedf3;
  }
  .wrap-table-import {
    display: block;
    width: 100%;
    overflow-x: auto;
    max-height: calc(100vh - 359px);
    .ant-table-wrapper {
      min-width: 2600px !important;
      width: 100%;
    }
  }
  .border-status {
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    padding: 6px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    color: #4a4a4a;
    margin-bottom: 10px;
    &.error {
      background-color: #fff1f0;
      border: 1px solid #ffa39e;
    }
    &.complete {
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
    }
    .anticon {
      margin-right: 3px;
      margin-left: 3px;
      margin-top: -4px !important;
    }
  }
  .success {
    width: 50%;
  }
  .failed {
    width: 50%;
  }
}

.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}

.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}

.cursor-disabled {
  cursor: not-allowed;
}

.i-noti {
  color: #ff4d4f !important;
  font-size: 18px;
}

.noti {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #ecedf3;
  background-color: #fff;
  border-radius: 6px;
}

.text-noti {
  color: #ff4d4f;
  font-size: 12px;
}
</style>
