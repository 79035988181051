<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-subject bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Create New Subject</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4 border-b">
              <div class="mb-2">Subject :</div>
              <a-input v-model:value="subject" class="w-100" />
            </div>
          </div>
          <div class="flex-none px-4 py-3 right">
            <a-button class="btn-create" type="default" @click="dismiss(true)">
              Cancel
            </a-button>
            <a-button
              class="ml-3 btn-create"
              type="primary"
              @click="createSubject()"
            >
              Create
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
// import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      subject: '',
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    createSubject() {
      var subject = {
        id: 0,
        type_name: this.subject,
        is_show: true,
        require_detail: false,
        parent_problem_type_id: null,
        basic_solution: '',
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateSubject(subject)
          .then(res => {
            this.dismiss()
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                this.$emit('clicksearch')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    dismiss: function () {
      this.subject = ''
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
}

.pointer {
  cursor: pointer;
}

.w-log-subject {
  width: 35rem !important;

  .content-topic {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}
</style>
