import { createStore } from 'vuex'
// import { SideMenuStore } from "./module/SideMenuStore";
// import { AccountStore } from "./module/AccountStore.js";

export default createStore({
  state: {
    loading: false,
    collapsed: false,
    loading2: false,
    menu: [],
    themecolor: 'light',
    isSearch: false,
    showEmergency: false,
    isCallAnnouncement: false,
    menupermission: [],
    permissionPage: false,
  },
  mutations: {
    callAnnouncement(state, val) {
      state.isCallAnnouncement = val
    },
    Loading(state, val) {
      state.loading2 = val
    },
    setLoading(state, val) {
      state.loading = val
    },
    setCollapsed(state, value) {
      state.collapsed = value
    },
    setIsSearch(state, value) {
      state.isSearch = value
    },
    setMenu(state, value) {
      state.menu = value
    },
    setMenuPermission(state, value) {
      state.menupermission = value
    },
    showEmergency(state, value) {
      state.showEmergency = value
    },
    permissionPage(state, value) {
      state.permissionPage = value
    },
  },
  actions: {},
  modules: {},
})
