<template>
  <div class="flex-1 w-full px-4">
    <div class="table-scroll">
      <nav class="nav-search">
        <div class="group-btn-right">
          <a-input-group class="search" compact>
            <a-select class="select-search" v-model:value="typesearch" :options="options_search" />
            <a-input v-model:value="search" placeholder="Search" class="input-search" />
          </a-input-group>
          <div class="wrap-date-filter">
            <a-button class="btn-date-filter" @click="toggleDateFilter()">
              {{ dateFilterValue }}
              <calendar-outlined />
            </a-button>
            <DateFilter v-model:value="showDateFilter" :hasCalendar="true" @selectRange="selectRange($event)"
              @selectCalendar="selectCalendar($event)" @reset="resetDatefilter($event)"
              @handleCloseDaterange="showDateFilter = false" />
          </div>
          <a-button :disabled="(startValue && !endValue) || (!startValue && endValue)" type="primary" class="btn-search"
            @click="clicksearchprivacy()"><search-outlined />Search</a-button>
          <a-button type="teriary" @click="exportReport">
            <file-excel-outlined /> Export as excel
          </a-button>
        </div>
      </nav>
      <div class="list-table">
        <div class="list-survey">
          <a>
            <router-link :to="{
              name: 'approvesurveydetail',
              params: {
                name: '',
              },
            }">
              <a-card class="card-survey">
                <div class="row">
                  <div class="col-lg-3 col-md-6">
                    <div class="d-flex">
                      <div class="pt-1">
                        <a-progress type="circle" stroke-linecap="square" stroke-color="#F9A601" :strokeWidth="15"
                          :percent="5" />
                      </div>
                      <div class="ml-3 pt-3">
                        <div class="h-con">Survey name :</div>
                        <div class="con">
                          1234 Cosmetic - Clearance -2021 August
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pt-3">
                    <div class="h-con">Survey Period :</div>
                    <div class="con">
                      01 / Apr / 2022 - 00:00 - 30 / Apr / 2022 - 23:59
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="total">
                      <div class="row">
                        <div class="col-3">
                          <div class="h-con">Total redemption :</div>
                          <div class="con">1,200,000</div>
                        </div>
                        <div class="col-3 border-l-2">
                          <div class="h-con">
                            <a-badge status="success" text="Approved :" />
                          </div>
                          <div class="con">0</div>
                        </div>
                        <div class="col-3">
                          <div class="h-con">
                            <a-badge status="warning" text="Waiting :" />
                          </div>
                          <div class="con">1,199,998</div>
                        </div>
                        <div class="col-3">
                          <div class="h-con">
                            <a-badge status="error" text="Rejected :" />
                          </div>
                          <div class="con">2</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a-card>
            </router-link>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
// import _ from 'lodash'
// import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons-vue'

export default {
  name: 'UserList',
  components: {
    // Footer,
    DateFilter,
    CalendarOutlined,
    SearchOutlined,
  },
  data: function () {
    return {
      datapermission: JSON.parse(localStorage.getItem('permission')),
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveDataPrivacy: false,
      searchObject: {
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting Confirm',
          value: 'Waiting',
        },
        {
          label: 'Approved',
          value: 'Approved',
        },
        {
          label: 'Rejected',
          value: 'Rejected',
        },
      ],
      typesearch: 'survey_id',
      options_search: [
        {
          label: 'Survey ID',
          value: 'survey_id',
        },
        {
          label: 'Survey name',
          value: 'survey_name',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Rejected',
          color: 'red',
        },
        {
          id: 3,
          name: 'Approved',
          color: 'green',
        },
      ],
      datalists: [],
      dataApproveList: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetails: [],
      id: '',
      datalist: [],
    }
  },
  computed: {},
  mounted() {
    this.$store.commit('setLoading', false)
    // this.clicksearchprivacy()
  },
  watch: {},
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Waiting':
          return 'Created'
        case 'Approved':
          return 'Approved'
        case 'Rejected':
          return 'Rejected'
      }
    },
    clicksearchprivacy() {
      this.$store.commit('setLoading', true)
      this.searchObject.statusdata = this.statusdata
      this.page = 1
      this.showDateFilter = false
      this.setmanagepermission()
      this.getApprovePrivacylist().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getApprovePrivacylist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApproveListByID(id) {
      this.id = id
      this.getdataDetails().then(() => {
        this.checkData()
        this.showDisplayFieldsApproveDataPrivacy =
          !this.showDisplayFieldsApproveDataPrivacy
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    setmanagepermission() {
      if (this.datapermission.features.every(x => x.name != 'Manage-Privacy')) {
        return (this.managepermission = false)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approvepoint {
  .list-table {
    display: block;
    width: 100%;
    min-height: calc(100vh - 315px);
    max-height: calc(100vh - 315px);
    overflow-x: auto;
  }

  .list-survey {
    display: inline-block;
    width: -webkit-fill-available;
  }

  overflow-x: hidden;

  .nav-search {
    background-color: #fff;
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-left: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      padding-left: 10px;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .h-con {
    color: #969696;
  }

  .con {
    font-weight: 600;
    color: #636363;
  }

  .total {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
  }

  .border-l-2 {
    border-left-width: 2px;
  }

  .ant-card {
    cursor: pointer;
  }

  .ant-card-bordered {
    &:hover {
      border: 1px solid #f9a601 !important;
      background-color: #fff6e6 !important;
    }
  }
}
</style>
<style lang="scss">
.approvepoint {
  .ant-progress-inner {
    width: 60px !important;
    height: 60px !important;
    font-size: 18px !important;
  }

  .ant-progress-circle-trail {
    stroke: #d9d9d9 !important;
  }
}
</style>