<template>
  <a-modal
    v-model:visible="isPop"
    width="85%"
    wrap-class-name="modal-sla"
    :closable="true"
    :centered="true"
    :mask-closable="false"
    :footer="null"
    @cancel="closeModal()"
  >
    <template #title>
      {{ dataSLA.title || '' }}
      <span class="last-date mt-1">
        ( Last Update :
        {{
          dataSLA.updated_date
            ? formatDate(dataSLA.updated_date, 'DD/MMM/YYYY')
            : '-'
        }}
        )
      </span>
    </template>
    <div class="header-bg">
      <img :src="bgHeader" class="img" />
      <div class="center">
        <div class="title">Service Level Agreement</div>
        <div class="sub-title">of Buzzebees's sevice</div>
      </div>
    </div>
    <div class="content">
      <!-- eslint-disable vue/no-v-html -->
      <div v-html="dataSLA.content || ''"></div>
    </div>
  </a-modal>
</template>

<script>
import CommonMixin from '@/mixin/CommonMixin'
import DatetimeHelper from '@/helper/DatetimeHelper.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import bg from '@/assets/imgs/bg_header_sla.png'

export default {
  name: 'SLA',
  components: {},
  mixins: [CommonMixin, DatetimeHelper],
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      dataSLA: {
        title: '',
        content: '',
      },
      isPop: this.isShow,
      bgHeader: bg,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getSLA()
    },
    getSLA() {
      this.dataSLA = {}
      return new Promise(resolve => {
        BzbsUser.apiGetSLA().subscribe(res => {
          if (res.result) {
            this.dataSLA = res.result
          }
          resolve(true)
        })
        error => {
          console.log(error)
          resolve(false)
        }
      })
    },
    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss">
.modal-sla {
  .ant-modal-body {
    max-height: 85vh !important;
  }
  .header-bg {
    position: relative;
    .img {
      height: 70px;
      width: 100%;
    }
    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 18px;
      text-align: center;
    }
    .title {
      color: #604001;
      font-size: 24px;
      font-weight: 500;
      line-height: 33.6px;
    }
    .sub-title {
      color: #c68401;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .content {
    padding: 20px;
  }
  .last-date {
    color: gray;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
