<template>
  <div class="approvewallet flex-1 w-full px-4">
    <div class="table-scroll">
      <nav class="nav-search">
        <a-select
          class="select-search ml-3"
          v-model:value="statusdata"
          placeholder="All Status"
          :options="statusdata_dropdown"
        />
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter()">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
            @handleCloseDaterange="showDateFilter = false"
          />
        </div>
        <a-button
          type="primary"
          class="btn-search"
          @click="clicksearchadjustwallet()"
          ><search-outlined /> Search</a-button
        >
      </nav>
      <div class="wrap-table-level" @scroll="handleScroll">
        <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="id"
          class="fixed-head"
          :dataSource="dataAdjustWallet"
          :columns="columnsWallet"
          :pagination="false"
        >
          <template #user_id="{ record }">
            <a
              v-if="datapermissionbyapp.includes('Approve-List-Wallet-Edit')"
              class="font-level"
              @click="toggleApproveListByID(record)"
            >
              <div>{{ record.user_id }}</div>
            </a>
            <div v-else>{{ record.user_id }}</div>
            <div class="fs-12">
              <div>{{ record.name_of_user }}</div>
            </div>
          </template>
          <template #date="{ record }">
            <div>
              {{
                formatDate(
                  record.created_date,
                  'DD/MMM/YYYY',
                  this.isTimezone * -1,
                )
              }}
            </div>
          </template>
          <template #detail="{ record }">
            <div>{{ record.detail ? record.detail : '-' }}</div>
          </template>
          <template #requested_by="{ record }">
            <div>
              {{
                record.approve_reject_by.name
                  ? record.approve_reject_by.name
                  : '-'
              }}
            </div>
          </template>
          <template #type="{ record }">
            <div>
              {{
                record.type == 'Truemoneywallet'
                  ? 'True money wallet'
                  : 'Wallet'
              }}
            </div>
          </template>
          <template #document_status="{ record }">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="record.status == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge
                  :status="item.color"
                  :text="textStatus(record.status)"
                />
              </a-tag>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
  <DisplayFieldsApproveWallet
    :value="showDisplayFieldsApproveWallet"
    :dataDetailWallet="dataDetailWallet"
    @clicksearchadjustwallet="clicksearchadjustwallet()"
    @handleClose="showDisplayFieldsApproveWallet = false"
  >
  </DisplayFieldsApproveWallet>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import DisplayFieldsApproveWallet from '@/module/Setting/ApproveList/component/DisplayFieldsApproveWallet.vue'
var Cache = new CacheHelper(ConfigManager.getCacheType())
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserList',
  components: {
    // Footer,
    DateFilter,
    DisplayFieldsApproveWallet,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveWallet: false,
      searchObject: {
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Created',
          value: 'Create',
        },
        {
          label: 'Approved',
          value: 'Approve',
        },
        {
          label: 'Rejected',
          value: 'Reject',
        },
      ],
      columnsWallet: [
        {
          title: 'User ID',
          dataIndex: 'user_id',
          slots: { customRender: 'user_id' },
          key: 'user_id',
          width: 180,
          align: 'left',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          slots: { customRender: 'type' },
          key: 'type',
          width: 160,
          align: 'left',
        },
        {
          title: 'Details',
          dataIndex: 'detail',
          slots: { customRender: 'detail' },
          key: 'detail',
          width: 160,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'document_status',
          slots: { customRender: 'document_status' },
          key: 'document_status',
          width: 100,
          align: 'left',
        },
        {
          title: 'Created Date',
          dataIndex: 'date',
          slots: { customRender: 'date' },
          key: 'date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Created By',
          dataIndex: 'requested_by',
          slots: { customRender: 'requested_by' },
          key: 'requested_by',
          width: 160,
          align: 'left',
        },
        // {
        //   title: 'Response Date',
        //   dataIndex: 'response_date',
        //   slots: { customRender: 'response_date' },
        //   key: 'response_date',
        //   width: 120,
        //   align: 'left',
        // },
        // {
        //   title: 'Response By',
        //   dataIndex: 'response_by',
        //   slots: { customRender: 'response_by' },
        //   key: 'response_by',
        //   width: 160,
        //   align: 'left',
        // },
        // {
        //   title: 'Response Reason',
        //   dataIndex: 'response_reason',
        //   slots: { customRender: 'response_reason' },
        //   key: 'response_reason',
        //   width: 220,
        //   align: 'left',
        // },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'Approve',
          color: 'green',
        },
      ],
      dataAdjustWallet: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetailWallet: [],
      id: '',
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'Setting',
        },
        {
          text: '<div class="font-normal text-sm text-gray-400">Approve list</div>',
        },
      ]
    },
  },
  mounted() {
    this.$store.commit('setLoading', false)
    if (this.$route.query.tab == 'wallet') {
      this.clicksearchadjustwallet()
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'wallet') {
        this.clicksearchadjustwallet()
      }
    },
  },
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Created'
        case 'Approve':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    clicksearchadjustwallet() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.showDateFilter = false
      this.getAdjustWallet().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getAdjustWallet() {
      var appid = Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetAdjustWallet(
          appid,
          (this.searchObject.statusdata = this.statusdata
            ? this.statusdata
            : ''),
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataAdjustWallet = []
            }
            _.forEach(data.result, item => {
              this.dataAdjustWallet.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataAdjustWallet)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getAdjustWallet().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApproveListByID(record) {
      this.dataDetailWallet = record
      this.showDisplayFieldsApproveWallet = !this.showDisplayFieldsApproveWallet
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approvewallet {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }

  .nav-search {
    display: flex;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }
    }

    .wrap-date-filter {
      // min-width: 290px;
      position: relative;
      padding-right: 10px;
      padding-left: 10px;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;

    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.approvewallet {
  .datefilter {
    right: unset !important;
  }
  .select-search {
    width: 150px;
  }
}

.approvepoint {
  .ant-table-row {
    vertical-align: top !important;
  }

  .ant-table-thead {
    vertical-align: top !important;
  }
}
</style>
