<template>
  <a-modal
    v-model:visible="isPop"
    width="600px"
    class="modal-announcement"
    :title="isData.title"
    :closable="false"
    :centered="true"
    :mask-closable="false"
    @cancel="closeModal('Emergency')"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="isData.content"></div>
    <template #footer>
      <a-checkbox
        v-if="isData.is_required_accept"
        :checked="isChecked"
        :disabled="
          isData.is_unread != null
            ? isData.is_required_accept && !isData.is_unread
            : null
        "
        @change="onChange"
      >
        Accept, Don’t show again
      </a-checkbox>
      <span v-else :class="{'blank-checkbox': !isData.is_required_accept}"
        >&nbsp;</span
      >
      <a-button key="submit" type="primary" @click="closeModal('Emergency')">
        Close
      </a-button>
    </template>
  </a-modal>
</template>

<script>

export default {
  name: 'Announcement',
  components: {},
  props: {
    isShow: {
      type: Boolean,
    },
    data: {
      data: Object,
    },
  },
  data() {
    return {
      isPop: this.isShow,
      isData: this.data,
      isChecked: Boolean,
    }
  },
  created() {
    this.isChecked = this.data.is_unread != null ? !this.data.is_unread : null
  },
  methods: {
    onChange(e) {
      this.isChecked = e.target.checked
      this.$emit('checkBox', e.target.checked)
    },
    closeModal(type) {
      this.$emit('closeModalAnnouncement', type)
    },
  },
}
</script>
