<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs class="mt-4" title="Search Serial" />
    </template>
    <div class="main-menu searchserial overflow-x-hidden flex flex-col">
      <div class="flex-1 w-full px-4">
        <CampaignList
          v-if="
            typesearch == 'campaign' &&
            datapermission.includes('Search-Serial-Campaign-View')
          "
          @changeType="changeType($event)"
        />
        <CouponList
          v-if="
            typesearch == 'coupon' &&
            datapermission.includes('Search-Serial-Coupon-View')
          "
          @changeType="changeType($event)"
        />
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
// import _ from 'lodash'
// import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import moment from 'moment'
// import dayjs from 'dayjs'
import CampaignList from '@/module/SearchSerial/component/CampaignList.vue'
import CouponList from '@/module/SearchSerial/component/CouponList.vue'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    CampaignList,
    CouponList,
    // Footer,
    // DateFilter,
    // CalendarOutlined
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Help chat',
        },
      ],
      datapermission: [],
      startValue: null,
      endValue: null,
      endOpen: false,
      overlaytable: false,
      nomore: false,
      page: 1,
      search: '',
      showDisplayFieldsApproveDetail: false,
      userId: this.$route.params.id,
      typesearch: '',
      options_search: [
        {
          label: 'Campaign',
          value: 'campaign',
        },
        {
          label: 'Coupon',
          value: 'coupon',
        },
      ],
      typesearch2: 'serial',
      options_search2: [
        {
          label: 'Serial',
          value: 'serial',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
        // {
        //   label: 'Agency ID',
        //   value: 'agency_id',
        // },
        // {
        //   label: 'Terminal ID',
        //   value: 'terminal_id',
        // },
        // {
        //   label: 'Brand ID',
        //   value: 'brand_id',
        // },
      ],
      status: [
        {
          id: 1,
          name: true,
          text: 'Use',
          color: 'green',
        },
        {
          id: 2,
          name: false,
          text: 'Not use',
          color: 'red',
        },
      ],
      datajoblist: [
        {
          serial_number: '23012600001',
          item_number: '2',
          redeemed_date: '11/ Oct / 2021 - 15:30',
          used_status: false,
          used_date: '11/ Oct / 2021 - 15:30',
        },
      ],
      columns: [
        {
          title: 'Serial Number',
          dataIndex: 'serial_number',
          slots: { customRender: 'serial_number' },
          key: 'serial_number',
          width: 160,
          align: 'left',
        },
        {
          title: 'Campaign',
          dataIndex: 'campaign',
          slots: { customRender: 'campaign' },
          key: 'campaign',
          width: 260,
          align: 'left',
        },
        {
          title: 'Item Number',
          dataIndex: 'item_number',
          slots: { customRender: 'item_number' },
          key: 'item_number',
          width: 140,
          align: 'right',
        },
        {
          title: 'Agency',
          dataIndex: 'agency',
          slots: { customRender: 'agency' },
          key: 'agency',
          width: 180,
          align: 'left',
        },
        {
          title: 'User',
          dataIndex: 'user',
          slots: { customRender: 'user' },
          key: 'user',
          width: 260,
          align: 'left',
        },
        {
          title: 'Redeemed Date',
          dataIndex: 'redeemed_date',
          slots: { customRender: 'redeemed_date' },
          key: 'redeemed_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Used Status',
          dataIndex: 'used_status',
          slots: { customRender: 'used_status' },
          key: 'used_status',
          width: 160,
          align: 'left',
        },
        {
          title: 'Used Date',
          dataIndex: 'used_date',
          slots: { customRender: 'used_date' },
          key: 'used_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Terminal',
          dataIndex: 'terminal',
          slots: { customRender: 'terminal' },
          key: 'terminal',
          width: 140,
          align: 'left',
        },
        {
          title: 'Branch',
          dataIndex: 'branch',
          slots: { customRender: 'branch' },
          key: 'branch',
          width: 140,
          align: 'left',
        },
        {
          title: 'Brand',
          dataIndex: 'brand',
          slots: { customRender: 'brand' },
          key: 'brand',
          width: 140,
          align: 'left',
        },
        {
          title: 'Merchant',
          dataIndex: 'merchant',
          slots: { customRender: 'merchant' },
          key: 'merchant',
          width: 140,
          align: 'left',
        },
      ],
      formatdate: 'DD/MMM/YYYY',
    }
  },
  computed: {},
  created() {
    this.datapermission = JSON.parse(localStorage.getItem('permissionbyapp'))
    this.checkType()
  },
  mounted() {
    this.$store.commit('setLoading', false)
  },
  watch: {},
  methods: {
    changeType(type) {
      this.typesearch = type
    },
    checkType() {
      if (
        this.datapermission.includes('Search-Serial-Campaign-View') &&
        this.datapermission.includes('Search-Serial-Coupon-View')
      ) {
        this.typesearch = 'campaign'
      } else if (
        this.datapermission.includes('Search-Serial-Campaign-View') &&
        !this.datapermission.includes('Search-Serial-Coupon-View')
      ) {
        this.typesearch = 'campaign'
      } else if (
        !this.datapermission.includes('Search-Serial-Campaign-View') &&
        this.datapermission.includes('Search-Serial-Coupon-View')
      ) {
        this.typesearch = 'coupon'
      } else {
        this.$store.commit('permissionPage', false)
      }
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getHelpchatList().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    changeOptions(type) {
      var options = [
        {
          label: 'Serial',
          value: 'serial',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
        {
          label: 'Agency ID',
          value: 'agency_id',
        },
        {
          label: 'Terminal ID',
          value: 'terminal_id',
        },
        {
          label: 'Brand ID',
          value: 'brand_id',
        },
      ]
      if (type == 'coupon') {
        this.options_search2 = options.filter(x => x.value == 'serial')
      } else {
        this.options_search2 = options
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.searchserial {
  overflow-x: hidden;
  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
  .nav-search {
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;
    .search {
      float: left;
      width: auto;
      .input-search {
        width: 200px;
        border-left: none;
      }
      .select-search {
        width: 130px;
      }
    }
    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
    }
    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }
    .btn-date-filter {
      width: 260px;
      text-align: left;
      .anticon {
        float: right;
        margin-top: 2px;
      }
    }
    .group-btn-right {
      display: flex;
      float: right;
      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .wrap-table-level {
    display: block;
    width: 100%;
    min-width: 2200px;
    max-height: calc(100vh - 270px);
    overflow-x: auto;
  }
  .type-search {
    width: 150px;
  }
  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
    .ant-table-row {
      height: 120px !important;
    }
  }
  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }
  .ml-10 {
    margin-left: 10px;
  }
}
</style>
