<template>
  <label class="items-center">
    <input
      type="radio"
      :name="name"
      :value="value"
      :checked="value"
      :disabled="disabled"
      class="mr-3"
    />
    <p class="">{{ label }}</p>
  </label>
</template>

<script>
export default {
  name: 'VCheckbox',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    stripeHTMLTags: {
      type: Boolean,
      default: true,
    },
    display: {
      type: String,
      default: 'inline-flex',
    },
  },
  methods: {
    toggleValue: function () {
      this.$emit('update:value', !this.value)
      this.$nextTick(() => {
        this.$emit('change')
      })
    },
    stripeHTMLTagsFunc: function (value) {
      const div = document.createElement('div')
      div.innerHTML = value
      const text = div.textContent || div.innerText || ''
      return text
    },
  },
}
</script>
<style lang="scss" scoped>
label{
  input[type='radio'] {
    vertical-align: middle;
    width: 14px;
    height: 14px;
    display: inline-block;
  }
  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
  }
}
</style>
