<template>
  <div
    class="default-layout page-layout"
    :class="{ 'disable-menu': disableMenu }"
  >
    <a-config-provider>
      <a-layout>
        <a class="menu_mobile" @click="collapsed = !collapsed">
          <!-- <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" /> -->
          <img :src="logo" />
        </a>
        <Sider :menu-callcenter="menuCallcenter"></Sider>
        <a-layout>
          <Header />
          <a-layout-content
            :class="{
              collapsed: $store.state.collapsed,
              'has-emergency': $store.state.showEmergency,
            }"
          >
            <template
              v-if="hasSlot('breadcrumb') && $store.state.permissionPage"
            >
              <div class="px-4">
                <slot name="breadcrumb"></slot>
              </div>
            </template>
            <template v-if="hasSlot('toolbar') && $store.state.permissionPage">
              <div class="mx-4 mb-4 b-6 bg-white pl-4 pr-4">
                <slot name="toolbar"></slot>
              </div>
            </template>
            <Unauthorized v-if="!$store.state.permissionPage" />
            <slot v-if="$store.state.permissionPage"></slot>
            <div>
              <a-button
                v-if="
                  (currentRoute.name == 'select_application' &&
                    checkPermissionDownload()) ||
                  (currentRoute.path.includes('list') &&
                    !currentRoute.path.includes('approvelist') &&
                    datapermission &&
                    datapermission.includes('User-List-Create-Ticket'))
                "
                type="primary"
                :class="
                  currentRoute.name == 'select_application'
                    ? 'create-ticket-selectapp d-flex'
                    : 'create-ticket d-flex'
                "
                @click="toggleTicket()"
                ghost
              >
                <span><img src="@/assets/icons/clipboard-list.svg" /></span>
                <span class="pl-1">Create Ticket</span>
                <up-outlined />
              </a-button>
              <div v-if="modalTicket" class="modalticket">
                <div
                  class="d-flex justify-content-between pointer title-modalticket p-3"
                  @click="toggleCloseTicket()"
                >
                  <div class="d-flex align-items-center">
                    <img src="@/assets/icons/clipboard-list-w.svg" />
                    <div class="pl-1">Create Ticket</div>
                  </div>
                  <down-outlined class="pt-2" />
                </div>
                <div class="p-3 content-modalticket">
                  <a-form
                    layout="vertical"
                    :validateOnRuleChange="false"
                    :model="formState"
                  >
                    <a-form-item
                      label="Subject"
                      name="subject"
                      :validateFirst="false"
                      :rules="[
                        {
                          required: true,
                        },
                      ]"
                    >
                      <a-select
                        v-model:value="formState.subject"
                        placeholder="Select subject"
                        :allowClear="true"
                        :options="options_subject"
                        @change="selectSubject(formState.subject)"
                      ></a-select>
                    </a-form-item>
                    <a-form-item
                      label="Topic Type"
                      :rules="[
                        {
                          required: true,
                        },
                      ]"
                    >
                      <a-select
                        v-model:value="formState.topictype"
                        placeholder="Select topic types"
                        :options="option_topictype"
                        :allowClear="true"
                        @change="selectTopicType(formState.topictype)"
                      ></a-select>
                    </a-form-item>
                    <a-form-item
                      v-if="option_topicdetail.length > 0"
                      label="Topic Detail"
                      :rules="[
                        {
                          required: true,
                        },
                      ]"
                    >
                      <a-select
                        v-model:value="formState.topicdetail"
                        placeholder="Select topic details"
                        :options="option_topicdetail"
                        :allowClear="true"
                      ></a-select>
                    </a-form-item>
                    <a-form-item
                      label="Remark"
                      :rules="[
                        {
                          required: true,
                        },
                      ]"
                    >
                      <a-textarea
                        v-model:value="formState.remark"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                      />
                    </a-form-item>
                    <div v-if="!currentRoute.path.includes('user')" class="row">
                      <div class="col-6">
                        <a-form-item label="Name">
                          <a-input v-model:value="formState.name"></a-input>
                        </a-form-item>
                      </div>
                      <div class="col-6">
                        <a-form-item label="Contact number">
                          <a-input
                            v-model:value="formState.contact_number"
                            @input="handleInputNumber($event)"
                          ></a-input>
                        </a-form-item>
                      </div>
                    </div>
                    <a-form-item
                      v-if="currentRoute.name != 'select_application'"
                      label="Campaign ID"
                    >
                      <a-select
                        v-model:value="formState.campaign_id"
                        show-search
                        :allowClear="true"
                        placeholder="Search Campaign ID"
                        :options="options_campaignid"
                        :filter-option="false"
                        @input="handleInput($event)"
                        @search="searchCampaign"
                      ></a-select>
                    </a-form-item>
                    <a-form-item label="VSTS">
                      <a-select
                        v-model:value="formState.vsts"
                        show-search
                        placeholder="Search VSTS"
                        :options="options_vsts"
                        :filter-option="false"
                        :allowClear="true"
                        @input="handleInput($event)"
                        @search="searchVSTS"
                      ></a-select>
                    </a-form-item>
                    <div class="row">
                      <div class="col-6">
                        <a-form-item
                          label="Status"
                          :rules="[
                            {
                              required: true,
                            },
                          ]"
                        >
                          <a-select
                            v-model:value="formState.status"
                            placeholder="Select Status"
                            :options="options_status"
                            :allowClear="true"
                          ></a-select>
                        </a-form-item>
                      </div>
                      <div class="col-6">
                        <a-form-item
                          label="Priority"
                          :rules="[
                            {
                              required: true,
                            },
                          ]"
                        >
                          <a-select
                            v-model:value="formState.priority"
                            placeholder="Select Priority"
                            :options="options_priority"
                            :allowClear="true"
                          ></a-select>
                        </a-form-item>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <a-form-item
                          label="Channel"
                          :rules="[
                            {
                              required: true,
                            },
                          ]"
                        >
                          <a-select
                            v-model:value="formState.channel"
                            placeholder="Select Channel"
                            :options="options_channel"
                            :allowClear="true"
                          ></a-select>
                        </a-form-item>
                      </div>
                      <div class="col-6">
                        <a-form-item label="Expect Date">
                          <a-date-picker
                            class="w-100"
                            v-model:value="formState.expirydate"
                            placeholder="Select Expect Date"
                            :showToday="false"
                            :disabled-date="disabledDate"
                          />
                        </a-form-item>
                      </div>
                    </div>
                  </a-form>
                  <div class="mb-2">Upload Images (Maximum 5 images)</div>
                  <a-upload
                    v-model:file-list="fileList"
                    class="uploadimg"
                    :beforeUpload="[
                      function () {
                        false
                      },
                    ]"
                    list-type="picture-card"
                    :previewIcon="false"
                  >
                    <div v-if="fileList.length < 5">
                      <plus-outlined />
                      <div style="margin-top: 8px">Upload</div>
                    </div>
                  </a-upload>
                  <!-- <a-upload
                    v-model:file-list="fileList"
                    list-type="picture-card"
                    class="testtest"
                    :beforeUpload="[
                      function () {
                        false
                      },
                    ]"
                    @preview="handlePreview"
                  >
                    <div>Upload Images (Maximum 5 images)</div>
                    <a-button class="ml-3" style="width: 100px">
                      <div class="d-flex">
                        <img src="@/assets/icons/plus.svg" />
                        <div class="ml-2">Browse</div>
                      </div>
                    </a-button>
                  </a-upload> -->
                </div>
                <div
                  class="p-3 d-flex justify-content-between align-items-center border-t"
                >
                  <div>
                    <a class="link" @click="clearTicket()">Clear</a>
                  </div>
                  <div>
                    <a-button
                      type="default"
                      class="mr-10"
                      @click="toggleCloseTicket()"
                      >Close</a-button
                    >
                    <a-button
                      type="primary"
                      :disabled="
                        !formState.subject ||
                        !formState.topictype ||
                        !formState.remark ||
                        !formState.status ||
                        !formState.priority ||
                        !formState.channel
                      "
                      @click="postCreateTicket()"
                      >Create Ticket</a-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </a-layout-content>
        </a-layout>
      </a-layout>
      <template #renderEmpty>
        <!-- <a-result v-if="datapermission.includes('User-List-Call-Center') && !isSearch" title="Please search to view user">
          <template #icon>
            <img src="@/assets/icons/no-result.svg" />
          </template>
        </a-result> -->
        <a-empty
          :image="imgEmpty"
          :image-style="{
            height: '150px',
          }"
        />
      </template>
    </a-config-provider>
  </div>
</template>

<script>
import Sider from '@/components/Layout/SiderNew'
// import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import Header from '@/components/Layout/Header'
import { config } from '@/config/bzbsconfig.js'
import Unauthorized from '@/components/Auth/UnauthorizedView'
import CheckPermission from '@/mixin/CheckPermission'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'

var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  mixins: [CheckPermission],
  props: {
    breadcrumb: {
      type: String,
    },
    disableMenu: Boolean,
    menuCallcenter: Boolean,
    breadcrumbPropsMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Sider,
    // VBreadcrumb: Breadcrumb,
    Header,
    Unauthorized,
  },
  data() {
    return {
      logo: config.bzbBlobUrl + '/themebackoffice/assets/3/img/logo-icon.svg',
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      datapermission:
        localStorage.getItem('permissionbyapp') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('permissionbyapp')),
      userinfo:
        localStorage.getItem('Useraccount') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('Useraccount')),
      permissioncallcenter:
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket')),
      currentRoute: this.$router.currentRoute,
      formState: {},
      modalTicket: false,
      dataSubject: [],
      options_subject: [],
      Campaign_id: '',
      Topic_type: '',
      idSubject: '',
      option_topictype: [],
      idTopicType: '',
      option_topicdetail: [],
      options_priority: [
        {
          label: 'Low',
          value: 'Low',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'High',
          value: 'High',
        },
        {
          label: 'Critical',
          value: 'Critical',
        },
      ],
      options_status: [
        {
          label: 'Open',
          value: 'Open',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
        {
          label: 'Cancel',
          value: 'Cancel',
        },
        {
          label: 'Close',
          value: 'Close',
        },
      ],
      options_vsts: [],
      options_channel: [],
      options_campaignid: [],
      datacampaignlist: [],
      fileList: [],
      vsts: '',
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(newValue) {
        this.$store.commit('setCollapsed', newValue)
      },
    },
    isSearch: {
      get() {
        return this.$store.state.isSearch
      },
      set(newValue) {
        this.$store.commit('setIsSearch', newValue)
      },
    },
  },
  mounted() {
    // if (
    //   Cache.getCache('app') &&
    //   Cache.getCache('app').id &&
    //   this.datapermission &&
    //   this.datapermission.includes('User-List-Create-Ticket') &&
    //   this.currentRoute.path.includes('list') &&
    //   !this.currentRoute.path.includes('approvelist')
    // ) {
    //   this.getSubject()
    //   this.getChannel()
    // }
  },
  methods: {
    checkPermissionDownload() {
      if (this.permissioncallcenter) {
        var permissioncallcenter = this.permissioncallcenter.filter(
          x => x.application_id == '318353931566530',
        )
        if (
          permissioncallcenter.find(x =>
            x.features.find(y => y.name == 'User-List-Create-Ticket'),
          )
        ) {
          return true
        } else {
          return false
        }
      }
    },
    handleInputNumber(event) {
      this.formState.contact_number = event.target.value.replace(
        /[^0-9-+ ]/g,
        '',
      )
    },
    handleInput(event) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '')
    },
    disabledDate(startValue) {
      var date = new Date()
      return startValue.valueOf() < date.setDate(date.getDate() - 1).valueOf()
    },
    toggleCloseTicket() {
      this.modalTicket = false
    },
    toggleTicket() {
      this.getSubject()
      this.getChannel()
      ;(this.permissioncallcenter =
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket'))),
        (this.userinfo =
          localStorage.getItem('Useraccount') == 'undefined'
            ? null
            : JSON.parse(localStorage.getItem('Useraccount')))
      this.modalTicket = true
    },
    clearTicket() {
      this.formState = {}
      this.fileList = []
    },
    hasSlot: function (name = 'default') {
      return !!this.$slots[name]
    },
    getSubject() {
      var appid =
        this.currentRoute.name == 'select_application'
          ? '318353931566530'
          : Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubjectForCreateTicket(appid).subscribe(
          res => {
            this.dataSubject = res.result
            this.options_subject = []
            this.options_subject = this.dataSubject.items
              .filter(x => x.parent_problem_type_id == 0 && x.is_show)
              .map((item, index) => {
                return {
                  label: item.type,
                  value: item.type,
                  key: index,
                  id: item.id,
                }
              })
            this.idSubject = this.options_subject.id
            resolve(this.dataSubject)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    selectSubject(subject) {
      this.formState.topictype = ''
      this.formState.topicdetail = ''
      this.option_topictype = []
      this.option_topicdetail = []
      var itemtag = this.dataSubject.items.find(x => x.type == subject)
      this.idSubject = itemtag.id
      var itemtype = this.dataSubject.items.filter(
        x => x.parent_problem_type_id == itemtag.id,
      )
      if (itemtype) {
        this.option_topictype = itemtype
          .filter(x => x.is_show)
          .map((item, index) => {
            return {
              label: item.type,
              value: item.type,
              key: index,
            }
          })
      }
    },
    selectTopicType(subject) {
      this.formState.topicdetail = ''
      this.option_topicdetail = []
      var itemtag = this.dataSubject.items.find(x => x.type == subject)
      var itemtype = this.dataSubject.items.filter(
        x => x.parent_problem_type_id == itemtag.id,
      )
      if (itemtype) {
        this.option_topicdetail = itemtype
          .filter(x => x.is_show)
          .map((item, index) => {
            return {
              label: item.type,
              value: item.type,
              key: index,
            }
          })
      }
    },
    getChannel() {
      var appid =
        this.currentRoute.name == 'select_application'
          ? '318353931566530'
          : Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetChannelTicket(appid, true).subscribe(
          res => {
            this.dataChannel = res.result
            this.options_channel = []
            this.options_channel = this.dataChannel.items.map((item, index) => {
              return {
                label: item.name,
                value: item.id,
                key: index,
              }
            })
            resolve(this.dataChannel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    searchCampaign(value) {
      if (value.length >= 4) {
        var searchserial = {
          page_number: this.page,
          limit: 20,
          appid:
            this.currentRoute.name == 'select_application'
              ? '318353931566530'
              : Cache.getCache('app').id,
          campaign_id: value ? parseInt(value) : null,
        }
        return new Promise((resolve, reject) => {
          BzbsUser.apiPostSearchSerialCampaign(searchserial)
            .then(res => {
              this.datacampaignlist = res.data.result
              console.log(this.datacampaignlist)
              this.options_campaignid = []
              this.options_campaignid = [
                {
                  label: this.datacampaignlist[0].campaign_name,
                  value: this.datacampaignlist[0].campaign_id,
                },
              ]
              resolve(res.data.data)
            })
            .catch(error => {
              this.errorApi(error)
              reject(error)
            })
        })
      }
    },
    searchVSTS(value) {
      if (value) {
        var appid =
          this.currentRoute.name == 'select_application'
            ? '318353931566530'
            : Cache.getCache('app').id
        var vstsid = value
        this.getVSTS(appid, vstsid)
      }
    },
    getVSTS(appid, vstsid) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetVSTS(appid, vstsid).subscribe(
          res => {
            this.dataVSTS = res.result.value[0]
            console.log(this.dataVSTS)
            this.options_vsts = []
            this.options_vsts = [
              {
                label: this.dataVSTS.fields.system_title,
                value: this.dataVSTS.id,
              },
            ]
            resolve(this.dataVSTS)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    Ontest() {
      console.log('Ontest')
    },
    onFinish() {
      console.log('onFinish')
      this.postCreateTicket()
    },
    onFinishFailed() {
      console.log('onFinishFailed')
    },
    postCreateTicket() {
      this.$store.commit('setLoading', true)
      console.log(this.userinfo)
      if (this.fileList) {
        this.dataticket = {
          AppId:
            this.currentRoute.name == 'select_application'
              ? '318353931566530'
              : Cache.getCache('app').id,
          user_id: this.currentRoute.path.includes('user')
            ? this.$route.params.id
            : null,
          username: this.currentRoute.path.includes('user')
            ? this.userinfo.first_name + ' ' + this.userinfo.last_name
            : this.formState.name
            ? this.formState.name
            : '',
          contact_number: this.currentRoute.path.includes('user')
            ? this.userinfo.contact_number
            : this.formState.contact_number
            ? this.formState.contact_number
            : '',
          subject: this.formState.subject,
          topic_type: this.formState.topictype,
          topic_detail: this.formState.topicdetail,
          problem_type_id: this.idSubject,
          remark: this.formState.remark,
          priority: this.formState.priority,
          channel: this.formState.channel,
          campaign_id: this.formState.campaign_id
            ? this.formState.campaign_id
            : 0,
          vsts_id: this.formState.vsts ? this.formState.vsts : null,
          vsts_name: this.options_vsts[0] ? this.options_vsts[0].label : null,
          ticket_status: this.formState.status,
          expected_date: this.formState.expirydate
            ? this.formState.expirydate
            : null,
          files: this.fileList.map(x => x.originFileObj),
        }
      } else {
        this.dataticket = {
          AppId:
            this.currentRoute.name == 'select_application'
              ? '318353931566530'
              : Cache.getCache('app').id,
          user_id: this.currentRoute.path.includes('user')
            ? this.$route.params.id
            : null,
          username: this.currentRoute.path.includes('user')
            ? this.userinfo.first_name + ' ' + this.userinfo.last_name
            : this.formState.name
            ? this.formState.name
            : '',
          contact_number: this.currentRoute.path.includes('user')
            ? this.userinfo.contact_number
            : this.formState.contact_number
            ? this.formState.contact_number
            : '',
          subject: this.formState.subject,
          topic_type: this.formState.topictype,
          topic_detail: this.formState.topicdetail,
          problem_type_id: this.idSubject,
          remark: this.formState.remark,
          priority: this.formState.priority,
          channel: this.formState.channel,
          campaign_id: this.formState.campaign_id
            ? this.formState.campaign_id
            : 0,
          vsts_id: this.formState.vsts ? this.formState.vsts : null,
          vsts_name: this.options_vsts[0] ? this.options_vsts[0].label : null,
          ticket_status: this.formState.status,
          expected_date: this.formState.expirydate
            ? this.formState.expirydate
            : null,
        }
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateTicket(this.dataticket)
          .then(res => {
            this.modalTicket = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.formState = {}
                this.fileList = []
                this.options_vsts = []
                this.options_campaignid = []
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.ant-layout {
  display: none;
}

.create-ticket {
  position: absolute;
  bottom: 1%;
  right: 2.5%;
  z-index: 3;
  cursor: pointer;
}
.create-ticket-selectapp {
  position: absolute;
  bottom: 6%;
  right: 2.5%;
  z-index: 3;
  cursor: pointer;
}

.modalticket {
  position: absolute;
  width: 500px;
  right: 2.4%;
  z-index: 20;
  bottom: 1%;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 8px 16px 1px rgba(0, 0, 0, 0.08);

  .title-modalticket {
    background-color: #f9a601;
    color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .content-modalticket {
    max-height: 710px;
    overflow: auto;
  }

  .mr-10 {
    margin-right: 10px;
  }
}

@media only screen and (max-height: 890px) {
  .modalticket {
    bottom: 1%;

    .content-modalticket {
      max-height: 650px;
      overflow: auto;
    }
  }
}

@media only screen and (max-height: 740px) {
  .modalticket {
    bottom: 1%;

    .content-modalticket {
      max-height: 500px;
      overflow: auto;
    }
  }
}
</style>
<style lang="scss">
.uploadimg {
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    left: 85% !important;
  }
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    display: none !important;
  }
}
</style>
