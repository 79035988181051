<template>
  <AppLayout :disable-menu="false" :menu-callcenter="true">
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs class="mt-4" title="Approve List" />
    </template>
    <div class="main-menu approvepoint overflow-x-hidden flex flex-col">
      <Unauthorized v-if="noPermission" />
      <a-tabs v-else v-model:activeKey="activeKey" @change="changeTabs">
        <a-tab-pane
          v-for="tab in tabs"
          :key="tab.key"
          :tab="tab.title"
          force-render
        >
          <template v-if="tab.key == 'adjustpoint'">
            <ApprovePoint />
          </template>
          <template v-if="tab.key == 'importpoint'">
            <ApproveImportPoint />
          </template>
          <template v-if="tab.key == 'tranferpoint'">
            <ApproveTranferPoint />
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
  </AppLayout>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import _ from 'lodash'
// import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ApprovePoint from '@/module/CallCenter/component/ApprovePoint.vue'
import ApproveImportPoint from '@/module/CallCenter/component/ApproveImportPoint.vue'
import ApproveTranferPoint from '@/module/CallCenter/component/ApproveTranferPoint.vue'
import Unauthorized from '@/components/Auth/Unauthorized'
// import moment from 'moment'
// import dayjs from 'dayjs'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    // Footer,
    ApproveImportPoint,
    ApprovePoint,
    ApproveTranferPoint,
    Unauthorized,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Setting',
        },
        {
          name: null,
          breadcrumbName: 'Approve List',
        },
      ],
      permissioncallcenter:
        JSON.parse(localStorage.getItem('permissionappforcallcenter')) ==
        'undefined'
          ? null
          : JSON.parse(localStorage.getItem('permissionappforcallcenter')),
      activeKey: null,
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      showDisplayFieldsApproveDetail: false,
      showDisplayFieldsApproveDataPrivacy: false,
      approvelistpermission: true,
      approvepointlistpermission: true,
      approvepointpermission: true,
      rejectpermission: true,
      managepointpermission: true,
      dataApprove: [],
      dataApproveByID: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      pageId: this.$route.params.id,
      tabs: [
        {
          key: 'adjustpoint',
          title: 'Adjust Points',
          permission: 'Approve-List-Adjust-Points-View',
        },
        {
          key: 'importpoint',
          title: 'Import Points',
          permission: 'Approve-List-Import-Points-View',
        },
        {
          key: 'tranferpoint',
          title: 'Tranfers Points',
          permission: 'Approve-List-Transfer-Points-View',
        },
      ],
      tab2: [],
      noPermission: false,
    }
  },
  computed: {},
  mounted() {
    this.$store.commit('setLoading', false)
    this.clicksearch()
  },
  watch: {},
  methods: {
    changeTabs(activeKey) {
      this.activeKey = activeKey
      var query = { tab: activeKey }
      _.forEach(this.$route.query, (value, key) => {
        if (key != 'tab') {
          query[key] = value
        }
      })
      this.$router.push({
        name: 'approvelist-callcenter',
        query: query,
      })
    },
    // changeTabs(tabs){
    //   if(tabs == 2){
    //     this.clicksearchprivacy()
    //   }else if(tabs == 1){
    //     this.clicksearch()
    //   }
    // },
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Created'
        case 'L1Approved':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    clicksearch() {
      this.$store.commit('setLoading', false)
      this.page = 1
      this.showDateFilter = false
      let tabs = []
      tabs = this.filterMeniPermission(this.tabs)
      this.tabs = tabs.sort(function (a, b) {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
      if (this.$route.query.tab) {
        this.activeKey = this.$route.query.tab
        this.$store.commit('setLoading', false)
      } else {
        if (this.tabs.length > 0) {
          this.activeKey = this.tabs[0].key
          this.changeTabs(this.activeKey)
        } else {
          this.noPermission = true
        }
      }
    },
    filterMeniPermission(tab) {
      return (
        tab.filter(f =>
          this.permissioncallcenter.find(x =>
            x.features.find(i => i.name.includes(f.permission)),
          ),
        ) || []
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
</style>
<style lang="scss">
@import '@/style/base/base.scss';
</style>
