<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-wallet bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex">
              <h4 class="pr-2">
                {{ dataDetailWallet.user_id }}
              </h4>
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="dataDetailWallet.status == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge
                    :status="item.color"
                    :text="textStatus(dataDetailWallet.status)"
                  />
                </a-tag>
              </template>
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="bg-con">
              <div>
                Create Date :
                {{
                  formatDate(
                    dataDetailWallet.created_date,
                    'DD/MMM/YYYY - HH:mm',
                  )
                }}
              </div>
              <div>
                Create By : {{ dataDetailWallet.approve_reject_by.name }}
              </div>
            </div>
            <div class="bg-con">
              <div class="d-flex">
                <div class="pr-1">User : {{ dataDetailWallet.user_id }}</div>
                <div v-if="dataDetailWallet.name_of_user">
                  - {{ dataDetailWallet.name_of_user }}
                </div>
              </div>
              <div class="d-flex">
                <div>Amount :</div>
                <div class="font-bold pl-1">
                  {{ dataDetailWallet.amount ? dataDetailWallet.amount : '-' }}
                </div>
              </div>
              <div v-if="dataDetailWallet.type != 'Truemoneywallet'">
                Payment ID :
                {{
                  dataDetailWallet.payment_detail.payment_id
                    ? dataDetailWallet.payment_detail.payment_id
                    : '-'
                }}
              </div>
              <div v-if="dataDetailWallet.type != 'Truemoneywallet'">
                Card ID :
                {{
                  dataDetailWallet.payment_detail.card_id
                    ? dataDetailWallet.payment_detail.card_id
                    : '-'
                }}
              </div>
              <div v-if="dataDetailWallet.type == 'Truemoneywallet'">
                Campaign ID :
                {{
                  dataDetailWallet.true_wallet_detail.campaign_id
                    ? dataDetailWallet.true_wallet_detail.campaign_id
                    : '-'
                }}
              </div>
              <div v-if="dataDetailWallet.type == 'Truemoneywallet'">
                Customer ID :
                {{
                  dataDetailWallet.true_wallet_detail.customer_id
                    ? dataDetailWallet.true_wallet_detail.customer_id
                    : '-'
                }}
              </div>
              <div v-if="dataDetailWallet.type == 'Truemoneywallet'">
                Transaction ID :
                {{
                  dataDetailWallet.true_wallet_detail.transaction_id
                    ? dataDetailWallet.true_wallet_detail.transaction_id
                    : '-'
                }}
              </div>
              <div>
                Details :
                {{ dataDetailWallet.detail ? dataDetailWallet.detail : '-' }}
              </div>
              <div v-if="dataDetailWallet.image.length > 0" class="d-flex">
                <div class="mr-2">Image :</div>
                <div
                  v-for="(img, indeximg) in dataDetailWallet.image"
                  :key="indeximg"
                >
                  <div class="ml-2">
                    <a-image :width="120" :src="img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="dataDetailWallet.status == 'Create'" class="p-3">
            <div class="h-detail">Remark</div>
            <a-textarea
              v-model:value="remark"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
            <div class="mt-1 h-detail">
              * Rejection require remark, approval is optional.
            </div>
          </div>
          <div v-if="dataDetailWallet.status != 'Create'" class="p-4">
            <a-row>
              <a-col :span="6">
                <div class="h-detail">Remark :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    dataDetailWallet.approve_reject_reason
                      ? dataDetailWallet.approve_reject_reason
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="h-detail">Responded date :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    dataDetailWallet.approve_reject_date
                      ? formatDate(
                          dataDetailWallet.approve_reject_date,
                          'DD/MMM/YYYY',
                        )
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="h-detail">Respondent :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    dataDetailWallet.approve_reject_by
                      ? dataDetailWallet.approve_reject_by.name
                      : '-'
                  }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            :class="
              dataDetailWallet.status != 'Create'
                ? 'px-4 py-3'
                : 'flex justify-between px-4 py-3'
            "
          >
            <div v-if="dataDetailWallet.status == 'Create'">
              <a-button
                @click="Reject"
                type="danger"
                :disabled="remark.length < 1"
                class="btn-create mr-3"
                >Reject</a-button
              >
            </div>
            <div>
              <a-button
                type="primary-2"
                :class="
                  dataDetailWallet.status != 'Create'
                    ? 'w-maxmax btn-create'
                    : 'mr-3 btn-create'
                "
                @click="dismiss(true)"
              >
                Close
              </a-button>
              <a-button
                v-if="dataDetailWallet.status == 'Create'"
                type="success"
                class="btn-create"
                @click="Approve"
              >
                Approve
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    class="modal-set-expire"
    :visible="modalReject"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="h-modal">
      <div>Reject reason</div>
    </div>
    <div class="border-t-2">
      <div class="font-modal-delete px-4 mb-5">
        <div class="pt-4">Reason</div>
        <a-textarea
          v-model:value="value2"
          :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </div>
      <div class="p-4 border-t-2 modal-footer">
        <a-button type="primary" @click="handleCancel()">Save</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
// helper
import Util from '@/helper/Utility'
// import moment from 'moment'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import _ from 'lodash'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataDetailWallet: {
      type: Array,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['clicksearchprivacy'],
  data: function () {
    return {
      demoimg: require('@/assets/imgs/imgdemo-survey.png'),
      modalReject: false,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      remark: '',
      isErrorName: false,
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'Approve',
          color: 'green',
        },
      ],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {},
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Created'
        case 'Approve':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    onChange() {
      this.setdisable = false
      if (this.levelname) {
        this.isErrorName = false
      }
    },
    Approve() {
      this.$store.commit('setLoading', true)
      this.PostApprove().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchadjustwallet')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostApprove() {
      var approvepoint = {
        appid: Cache.getCache('app').id,
        reference_id: this.dataDetailWallet.reference_id
          ? this.dataDetailWallet.reference_id
          : '',
        amount: this.dataDetailWallet.amount
          ? this.dataDetailWallet.amount
          : '',
        card_id: this.dataDetailWallet.payment_detail.card_id
          ? this.dataDetailWallet.payment_detail.card_id
          : '',
        user_id: this.dataDetailWallet.user_id
          ? this.dataDetailWallet.user_id
          : '',
        detail: this.dataDetailWallet.detail
          ? this.dataDetailWallet.detail
          : '',
        reason: this.remark ? this.remark : '',
        campaign_id: this.dataDetailWallet.true_wallet_detail.campaign_id
          ? this.dataDetailWallet.true_wallet_detail.campaign_id
          : '',
        customer_id: this.dataDetailWallet.true_wallet_detail.customer_id
          ? this.dataDetailWallet.true_wallet_detail.customer_id
          : '',
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApproveWallet(approvepoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    rejectconfirm() {
      this.modalReject = true
    },
    Reject() {
      this.$store.commit('setLoading', true)
      this.PostReject().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchadjustwallet')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostReject() {
      var rejectpoint = {
        appid: Cache.getCache('app').id,
        reference_id: this.dataDetailWallet.reference_id
          ? this.dataDetailWallet.reference_id
          : '',
        amount: this.dataDetailWallet.amount
          ? this.dataDetailWallet.amount
          : '',
        card_id: this.dataDetailWallet.payment_detail.card_id
          ? this.dataDetailWallet.payment_detail.card_id
          : '',
        user_id: this.dataDetailWallet.user_id
          ? this.dataDetailWallet.user_id
          : '',
        detail: this.dataDetailWallet.detail
          ? this.dataDetailWallet.detail
          : '',
        reason: this.remark ? this.remark : '',
        campaign_id: this.dataDetailWallet.true_wallet_detail.campaign_id
          ? this.dataDetailWallet.true_wallet_detail.campaign_id
          : '',
        customer_id: this.dataDetailWallet.true_wallet_detail.customer_id
          ? this.dataDetailWallet.true_wallet_detail.customer_id
          : '',
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectWallet(rejectpoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function () {
      this.levelname = ''
      this.description = ''
      this.earnpoint = ''
      this.benefits = ''
      this.remark = ''
      this.$emit('clicksearchprivacy')
      this.$emit('handleClose')
    },
    handleCancel() {
      this.modalReject = false
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
  .input-error {
    border-color: #ff5252 !important;
  }
  .text-error {
    color: #ff5252;
  }
  .pd-t-21 {
    padding-top: 21px;
  }
}
.w100 {
  width: 100% !important;
}
.pointer {
  cursor: pointer;
}
.w-log-wallet {
  width: 40rem;
  .bg-con {
    margin: 15px;
    padding: 15px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}
.cursor-disabled {
  cursor: not-allowed;
}
</style>
