import moment from 'moment'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  data() {
    return {
      pageTitle: 'Buzzebees BackOffice',
      lbl: null,
    }
  },
  computed: {
    code: {
      get() {
        return this.$store.state.localecode
      },
    },
  },
  watch: {
    code(newVal, oldVal) {
      if (newVal != oldVal) {
        this.getlocalStorage()
      }
    },
  },
  created() {
    this.getlocalStorage()
  },
  methods: {
    getConfigAzure() {
      if (!Cache.getCache('bzbsConfig')) {
        var url
        if (window.location.hostname == 'localhost') {
          url = 'https://end-users-management.buzzebees-dev.com/api/version'
        } else {
          url = window.location.origin + '/api/version'
        }
        BzbsApi.connectBzbsGetBlob(url).then(res => {
          var response = res.data.api_setting
          // var env = res.data.app_setting.build
          let errorArray = {}
          let catchConfig = {}
          Object.keys(response).forEach(i => {
            if (response[i]) {
              catchConfig[i] = response[i]
            } else {
              errorArray[i] = i
            }
          })
          if (
            Object.keys(errorArray).length > 0 &&
            window.location.pathname !== '/notfindconfig'
          ) {
            this.$router.push({ name: 'Notfindconfig', query: errorArray })
          } else {
            Cache.setCache('bzbsConfig', catchConfig)
            this.$router.go()
          }
        })
      }
    },
    getlocalStorage() {
      var cacheHelper = new CacheHelper(ConfigManager.getCacheType())
      var locale = cacheHelper.getCache('BzbsLocale')
      if (localStorage.getItem('setJsonLocal_' + locale)) {
        this.lbl = JSON.parse(localStorage.getItem('setJsonLocal_' + locale))
      }
    },
    truncate(num, places) {
      return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places)
    },
    handleLoading(value) {
      this.$store.commit('Loading', value)
    },
    // for ant select search option
    // filterOption(input, option) {
    //   return (
    //     option.componentOptions.children[0].text
    //       .toLowerCase()
    //       .indexOf(input.toLowerCase()) >= 0
    //   )
    // },
    formatDate(dateString, format = 'DD/MMM/YYYY', calHours = null) {
      if (typeof dateString == 'number') {
        dateString = new Date(dateString * 1000)
      }
      if (!dateString || dateString == '-') return '-'
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    formatDateWithTime(
      dateString,
      format = 'DD/MMM/YYYY - HH:mm',
      calHours = -7,
    ) {
      if (typeof dateString == 'number') {
        dateString = new Date(dateString * 1000) 
      }
      if (!dateString || dateString == '-') return '-'
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    formatTime(dateString, format = 'HH:mm', calHours = null) {
      if (!dateString || dateString == '-') return '-'
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    formatDateString(dateString, format = 'DD-MMM-YYYY', calHours = null) {
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    formHasError(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field])
    },
    successResponse(text, router) {
      this.handleLoading(false)
      var texttitle = ''
      if (text) {
        texttitle = text
      } else {
        texttitle = this.lbl.main.success_popup
      }
      this.$success({
        title: texttitle,
        content: '',
        centered: true,
        okType: 'default',
        autoFocusButton: null,
        closable: true,
        onOk: () => {
          if (typeof router !== 'undefined') {
            if (!(typeof router === 'boolean')) {
              this.$router.push(router)
              this.handleLoading(true)
            }
          } else {
            this.$router.go()
            this.handleLoading(true)
          }
        },
      })
    },
    parseInt(value) {
      if (typeof value === 'string') {
        return parseInt(value)
      } else if (typeof value === 'number') {
        return value
      }
    },
  },
}
