<template>
  <div class="auth_unauthorized">
    <a-result status="error" title="You don't have Permission for this page">
      <template #icon>
        <img :src="imgUnauthorized" />
      </template>
    </a-result>
  </div>
</template>
<script>
import { config } from '@/config/bzbsconfig.js'
export default {
  name: 'AuthUnauthorized',
  data() {
    return {
      imgUnauthorized:
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/unauthorized.svg',
    }
  },
  created() {
    this.$store.commit('setLoading', false)
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
@import '@/style/_mixin.scss';
.auth_unauthorized {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  @include transform(translate(-50%, -50%));
}
</style>
