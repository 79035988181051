<template>
  <div class="fixed top-0 right-0 h-screen box-filter" :style="containerStyle">
    <div
      v-if="value"
      class="backdrop fixed top-0 left-0 w-full h-full"
      @click="dismiss()"
    ></div>
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        v-if="value"
        class="h-full shadow-xl bg-white border-l relative"
        :style="mainBlockStyle"
      >
        <div class="flex divide-y-2 flex-col h-full">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Filter User</div>
            <div class="flex-none btn-dismiss" @click="dismiss()">
              <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-hidden">
            <div class="flex divide-x divide-gray-300 h-full">
              <div class="flex flex-1 h-full flex-col">
                <div class="flex-none px-4 text-gray-500 border-b h-12">
                  <div class="flex items-center h-full">
                    <div class="flex-auto">Column</div>
                  </div>
                </div>
                <div class="flex-auto overflow-y-auto">
                  <div class="v-list v-list-selectable">
                    <template
                      v-for="(g, idx) of groupsorder"
                      :key="`tag-group-${idx}`"
                    >
                      <div
                        :class="{
                          'last:mb-0': idx == g.length - 1,
                          'v-list-item--active': activeGroupIdx == idx,
                        }"
                        @click="selectGroup(idx, g.tag_group_name)"
                        class="flex v-list-item"
                      >
                        <div class="flex-auto px-4">
                          {{ g.tag_group_name }}
                        </div>
                        <div
                          class="flex-none pr-2"
                          v-if="g.tagSelectedCount > 0"
                        >
                          <div
                            class="py-0 px-2 bg-blue-3 text-white text-xs rounded-lg"
                          >
                            {{ g.tagSelectedCount }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="flex flex-1 h-full flex-col tag">
                <div class="flex-none pl-4 pr-2 text-gray-500 border-b h-12">
                  <div class="flex items-center h-full">
                    <div class="flex-auto">Criteria</div>
                    <div class="flex-none flex items-center">
                      <div class="inline-flex pl-3 relative h-full">
                        <transition
                          leave-active-class="transition ease-in duration-100"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <template v-if="showDropdownSort">
                            <ul
                              class="origin-top-right absolute z-10 mt-10 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-sm overflow-auto focus:outline-none border border-black border-opacity-5"
                              tabindex="-1"
                              role="listbox"
                              aria-labelledby="listbox-label"
                              @mouseenter="onMouseEnter"
                              @mouseleave="onMouseLeave"
                            >
                              <template
                                v-for="(item, idx) of sortItems"
                                :key="`ddl-sort-${idx}`"
                              >
                                <li
                                  class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
                                  role="option"
                                  @click="selectSortItem(item)"
                                >
                                  <div class="flex items-center text-sm">
                                    {{ item.text }}
                                  </div>
                                </li>
                              </template>
                            </ul>
                          </template>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-auto overflow-y-auto">
                  <div class="v-list v-list-selectable">
                    <template v-for="(t, idx) of tagList" :key="`tag-${idx}`">
                      <div
                        v-if="t.tag_group_name == activeGroupName"
                        class="v-list-item"
                        :class="{
                          'last:mb-0': idx == t.length - 1,
                        }"
                      >
                        <v-checkbox
                          v-if="t.type == 'checkbox'"
                          display="flex"
                          :value="t.selected"
                          v-model:value="t.selected"
                          @change="changeCheckbox(t)"
                          :label="t.name"
                          class="px-4"
                        >
                        </v-checkbox>
                        <v-radio
                          v-if="t.type == 'radio'"
                          display="flex"
                          :name="t.tag_group_name"
                          :value="t.selected"
                          v-model:value="t.selected"
                          @change="changeRadio(t)"
                          :label="t.name"
                          class="px-4"
                        >
                        </v-radio>
                        <div
                          v-if="t.type != 'radio' && t.type != 'checkbox'"
                          class="box-label pl-4"
                        >
                          {{ t.name }}
                        </div>

                        <div
                          v-if="t.type == 'int' && t.name == 'From'"
                          class="box-number"
                        >
                          <a-input
                            :value="t.value"
                            :min="0"
                            :max="9999999999"
                            @keypress="onChangeNum($event)"
                            @change="changeInputNumber($event, idx, t)"
                          />
                        </div>
                        <div
                          v-if="t.type == 'int' && t.name == 'To'"
                          class="box-number"
                        >
                          <a-input
                            :value="t.value"
                            :min="0"
                            :max="9999999999"
                            @keypress="onChangeNum($event)"
                            @change="changeInputNumber($event, idx, t)"
                          />
                        </div>
                        <div
                          v-if="t.type == 'date' && t.name == 'From'"
                          class="box-date"
                        >
                          <label class="items-center">
                            <input
                              class="flex-auto form-textbox inline-block"
                              type="date"
                              :max="yesterday"
                              data-date-format="DD-MM-YYYY"
                              :value="t.value"
                              @change="changeDate($event, t)"
                            />
                          </label>
                        </div>
                        <div
                          v-if="t.type == 'date' && t.name == 'To'"
                          class="box-date"
                        >
                          <label class="items-center">
                            <input
                              class="flex-auto form-textbox inline-block"
                              type="date"
                              :min="tagList[idx - 1].value"
                              :max="today"
                              data-date-format="DD-MM-YYYY"
                              :value="t.value"
                              @change="changeDate($event, t)"
                            />
                          </label>
                        </div>
                        <!-- <div v-if="t.type != 'radio' && t.type != 'checkbox'" class="flex-auto pr-4 text-sm"></div> -->
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="flex flex-1 h-full flex-col criteria">
                <div class="flex-none px-4 text-gray-500 border-b h-12">
                  <div class="flex items-center h-full">
                    <div class="flex-auto">Result</div>
                  </div>
                </div>
                <div class="flex-auto overflow-y-auto">
                  <div class="v-list overflow-y-auto pl-4 pr-2">
                    <template
                      v-for="(groupid, idx1) of [
                        ...new Set(selectedTag.map(x => x.groupid)),
                      ]"
                      :key="`criteia-${idx1}`"
                    >
                      <a-row class="mt-4">
                        <a-col
                          :span="6"
                          v-if="
                            selectedTag.length !== 1 &&
                            selectedTag.filter(x => x.groupid == groupid)[0]
                              .index_sub == 0
                          "
                          class="criterial-in col-2"
                        >
                          <div
                            style="width: 95%"
                            class="rounded border inline-block text-center text-sm px-2 py-1 text-white bg-primary2"
                          >
                            IN
                          </div>
                        </a-col>
                        <a-col
                          :span="
                            selectedTag.length == 1 ||
                            selectedTag.filter(x => x.groupid == groupid)[0]
                              .type != 'checkbox'
                              ? 24
                              : 18
                          "
                          :class="
                            selectedTag.filter(x => x.groupid == groupid)[0]
                              .type == 'checkbox'
                              ? 'pl-2'
                              : ''
                          "
                        >
                          <span
                            v-for="(t, idx) of selectedTag.filter(
                              x => x.groupid == groupid,
                            )"
                            :key="`criteias-${idx}`"
                          >
                            <v-criteria-item
                              v-if="t.type != 'checkbox'"
                              :render="renderComponent"
                              :item="t"
                              :index="idx"
                              :hidetag="selectedTag.length == 1"
                              @change="setcriteria($event, t)"
                              @removeCriteria="removeCriteria($event, t)"
                            />
                            <v-criteria-checkbox-item
                              v-if="t.type == 'checkbox'"
                              :render="renderComponent"
                              :item="t"
                              :index="idx"
                              :hidetag="selectedTag.length == 1"
                              @change="setcriteria($event, t)"
                              @removeCriteria="
                                removeCriteriaCheckbox($event, t)
                              "
                            />
                          </span>
                        </a-col>
                      </a-row>
                    </template>
                  </div>
                  <div class="flex px-4 pt-2 justify-between">
                    <div>Total</div>
                    <div>
                      <a-badge
                        :count="count == 0 ? count : count.toLocaleString()"
                        :showZero="true"
                        :overflow-count="999999"
                        :class="type == 'default' ? null : 'count-' + type"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-none px-4 py-3">
            <div class="flex">
              <div class="flex-none w-2/3">
                <div class="flex-none w-1/3 pl-1 pt-2">
                  <a class="reset-filer" @click="resetFilter()">Reset Filter</a>
                </div>
              </div>
              <div class="flex-none w-1/3 pl-1">
                <a-button class="w-100" type="primary" @click="applyFilter()"
                  >Apply</a-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VCheckbox from '@/components/Form/Checkbox.vue'
// import VTextbox from "@/components/Form/Textbox.vue";
// import VInputDate from "@/components/Form/InputDate.vue";
import VRadio from '@/components/Form/Radio.vue'
import VCriteriaItem from '../component/CriteriaItem.vue'
import VCriteriaCheckboxItem from '../component/CriteriaCheckboxItem.vue'
// import BzbsApi from '@/core/Http/BzbsApi.js'
import { FormHelper } from '@/helper/FormHelper.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import _ from 'lodash'

import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var cache = new CacheHelper(ConfigManager.getCacheType())
export default {
  name: 'TableFilter',
  inject: ['tableHeaders', 'toolbarStates'],
  components: {
    VCheckbox,
    VRadio,
    VCriteriaItem,
    VCriteriaCheckboxItem,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 1200,
    },
    name: {
      type: String,
    },
    permission: {
      type: Array,
    },
    permissionmaskdata: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:hasPanel', 'update:value'],
  data: function () {
    return {
      type: 'list',
      totalBadge: [],
      overlay: false,
      renderComponent: 0,
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      errorInfo: [],
      groups: [],
      tagList: [],
      activeGroupIdx: 0,
      selectedTag: [],
      showDropdownSort: false,
      sortItems: [],
      selectedSortItem: null,
      datajson: null,
      province: null,
      tagList_temp: {
        tagList_selected: [],
      },
      apply: false,
      timer: undefined,
      birthday_just_show_first: false,
      filterParams: {},
      today: null,
      yesterday: null,
      formatdate: 'YYYY-MM-DD',
      min_max: {
        min: '',
        max: '',
      },
      count: null,
      groupList: [],
      checkedList: [],
      displayCheckBox: '',
      groupsorder: '',
      orderList: {
        profile_update: 1,
        joindate: 5,
        birthday: 2,
        province: 6,
        age: 4,
        gender: 3,
        fraud_status: 7,
        team_and_con: 8,
        dataprivacy: 9,
        marketing_option: 10,
        consent_age: 11,
        line_marketing: 12,
        notification_marketing: 13,
        sms_marketing: 14,
        email_marketing: 15,
        total_earn: 16,
        total_burn: 17,
        pointonhand: 18,
        point_date: 19,
        last_active: 20,
        last_redeem: 21,
        last_scan: 22,
        registration_type: 23,
        user_level: 128,
        number_of_friend: 24,
      },
    }
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
        width: `${this.width}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
    pointDateState: function () {
      let mintotalearn = this.get_min_maxbyname('total_earn').min
        ? this.get_min_maxbyname('total_earn').min == 'above'
          ? ''
          : Number(this.get_min_maxbyname('total_earn').min)
        : this.getvaluebygroupname('total_earn', 'From') == ''
        ? this.getvaluebygroupname('total_earn', 'From')
        : Number(this.getvaluebygroupname('total_earn', 'From'))

      let mintotalburn = this.get_min_maxbyname('total_burn').min
        ? this.get_min_maxbyname('total_burn').min == 'above'
          ? ''
          : Number(this.get_min_maxbyname('total_burn').min)
        : this.getvaluebygroupname('total_burn', 'From') == ''
        ? this.getvaluebygroupname('total_burn', 'From')
        : Number(this.getvaluebygroupname('total_burn', 'From'))

      let minpointonhabd = this.get_min_maxbyname('pointonhand').min
        ? this.get_min_maxbyname('pointonhand').min == 'above'
          ? ''
          : Number(this.get_min_maxbyname('pointonhand').min)
        : this.getvaluebygroupname('pointonhand', 'From') == ''
        ? this.getvaluebygroupname('pointonhand', 'From')
        : Number(this.getvaluebygroupname('pointonhand', 'From'))

      return mintotalearn > 0 || mintotalburn > 0 || minpointonhabd > 0
    },
  },
  watch: {
    value: function (val) {
      if (val) {
        this.disableScroll()
        this.overlay = true
      } else {
        this.enableScroll()
        this.overlay = false
      }
    },
  },
  mounted: function () {},
  created() {
    // this.getBlobApi().then(() => {
    // if (cache.getCache('loadSaveList')) {
    //   this.$emit('loadSaveList', cache.getCache('loadSaveList'))
    //   console.log('loadSaveList')
    // } else
    if (this.datapermission.includes('Search-Manual-Data')) {
      this.$store.commit('setLoading', false)
    } else {
      this.$emit('filterparam', this.filterParams)
    }
    // })
    this.errorInfo = FormHelper.genErrorInfo(6)
    this.today = moment().format(this.formatdate)
    this.yesterday = moment().format(this.formatdate)
  },
  methods: {
    // onClickAway: function () {
    //   this.dismiss()
    //   this.$nextTick(() => {
    //     this.$emit('onClickAway')
    //   })
    // },
    getBlobApi() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetFilterBlob().subscribe(
          res => {
            this.groups = [...res.result]
            this.groupsorder = res.result[0].tag_group
              .map(i => {
                return { ...i, order: i.order }
              })
              .sort((a, b) => a.order - b.order)
            if (!this.activeGroupName) {
              this.activeGroupName = this.groups[0].tag_group[0].tag_group_name
            }
            this.sortItems = [
              { text: 'Sort A-Z', value: 'asc' },
              { text: 'Sort Z-A', value: 'desc' },
            ]
            this.selectedSortItem = this.sortItems[0]
            this.getTagList().then(() => {
              resolve()
            })
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    // getblob: function () {
    //   const timestamp = Date.now()
    //   const url =
    //     ConfigManager.getBlobUrl() +
    //     '/usermanagement/config/usermanagement.json?v=' +
    //     timestamp
    //   return new Promise((resolve, reject) => {
    //     BzbsApi.connectBzbsGetBlob(url)
    //       .then(response => {
    //         this.groups = response.data
    //         this.activeGroupName = this.groups[0].tag_group[0].tag_group_name
    //         this.sortItems = [
    //           { text: 'Sort A-Z', value: 'asc' },
    //           { text: 'Sort Z-A', value: 'desc' },
    //         ]
    //         this.selectedSortItem = this.sortItems[0]
    //         this.getTagList().then(count => {
    //           resolve(count)
    //         })
    //       })
    //       .catch(error => {
    //         this.errorApi(error)
    //         reject(error)
    //       })
    //   })
    // },
    dismiss() {
      this.toolbarStates.showPanelFilter = false
      _.forEach(this.tagList, tag => {
        tag.selected = false
        if (tag.name == 'From' || tag.name == 'To') {
          tag.value = ''
        }
      })
      this.groupList = []
      this.selectedTag = []
      this.filterParams = {}
    },
    disableScroll() {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll() {
      document.body.classList.remove('overflow-hidden')
    },
    clearFilter() {
      _.forEach(this.tagList, tag => {
        tag.selected = false
        if (tag.name == 'From' || tag.name == 'To') {
          tag.value = ''
        }
      })
      this.groupList = []
      this.selectedTag = []
      this.filterParams = {}
      cache.removeCache('filterUser')
    },
    resetFilter() {
      this.activeGroupIdx = 0
      this.totalBadge = []
      this.clearFilter()
      this.callApiUserlistFilter().then(count => {
        this.count = count
        this.activeGroupName = this.groups[0].tag_group[0].tag_group_name
        cache.setCache('setFilterTotal', this.countbyfilter)
      })
      this.applyFilter(true)
      cache.removeCache('setFilterTotal')
      cache.removeCache('setFilter')
      cache.removeCache('filterUser')
      this.$emit('resetFilter')
    },
    applyFilter: function (reset = false) {
      this.$emit('filterparam', this.filterParams)
      if (reset) {
        this.$emit('reset', reset)
      }
      cache.removeCache('loadSaveList')
      var selectTagCache = []
      _.forEach(this.tagList, item => {
        if (item.selected) {
          selectTagCache.push({
            id: item.id,
            groupid: item.groupid,
            criteria: item.criteria,
            value: item.value,
          })
        }
        if ((item.name == 'From' || item.name == 'To') && item.value != '') {
          selectTagCache.push({
            id: item.id,
            groupid: item.groupid,
            name: item.name,
            value: item.value,
            criteria: item.criteria,
          })
        }
      })
      this.totalBadge = []
      if (this.selectedTag.length > 0) {
        this.selectedTag.forEach(x => {
          if (!this.totalBadge.includes(x.groupid)) {
            this.totalBadge.push(x.groupid)
          }
        })
      } else {
        this.totalBadge = []
      }
      cache.setCache('totalBadge', this.totalBadge.length)
      cache.setCache('filterUser', selectTagCache)
      this.dismiss()
    },
    //#region tag group functions
    selectGroup(idx, name) {
      this.activeGroupIdx = idx
      this.activeGroupName = name
    },
    changeCheckbox(tag) {
      this.selectTag(tag).then(() => {
        this.callApiUserlistFilter().then(count => {
          this.count = count
        })
      })
    },
    changeRadio(tag) {
      this.tagList.filter(x => {
        if (
          (x.groupid == tag.groupid && x.name == 'From') ||
          (x.groupid == tag.groupid && x.name == 'To')
        ) {
          x.value = ''
        }
      })
      tag.selected = true
      this.$nextTick(() => {
        this.selectTag(tag, this.min_max).then(() => {
          this.callApiUserlistFilter().then(count => {
            this.count = count
          })
        })
      })
    },
    onChangeNum($event) {
      const regex = /^[0-9.]\d*$/
      var match = $event.key.match(regex)
      if (match == null) {
        $event.preventDefault()
      }
    },
    changeInputNumber(even, idx, tag) {
      const { value } = even.target
      const reg = /^-?[0-9]*(\.[0-9]*)?$/
      if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
        tag.value = value
      }
      if (tag) {
        let find_min = this.tagList.filter(
          x => x.tag_group_name == tag.tag_group_name && x.name == 'From',
        )
        let find_max = this.tagList.filter(
          x => x.tag_group_name == tag.tag_group_name && x.name == 'To',
        )
        this.min_max = {
          max: find_max[0].value ? find_max[0].value : '',
          min: find_min[0].value ? find_min[0].value : '',
        }
        this.tagList.filter(x => {
          if (x.tag_group_name == tag.tag_group_name && x.type == 'radio') {
            x.selected = false
          }
        })
        if (
          this.tagList[idx].name == 'From' ||
          this.tagList[idx].name == 'To'
        ) {
          var name = tag.name == 'From' ? 'min' : 'max'
          this.min_max[name] = tag.value
        }
      }
      this.$nextTick(() => {
        console.log(tag, this.min_max)
        this.selectTag(tag, this.min_max).then(() => {
          this.callApiUserlistFilter().then(count => {
            this.count = count
          })
        })
      })
    },
    changeDate(event, tag) {
      let itemMax = this.tagList.filter(
        x => x.tag_group_name == tag.tag_group_name && x.name == 'To',
      )
      tag.value = event ? event.target.value : tag.value
      this.min_max[tag.name == 'To' ? 'max' : 'min'] = event
        ? event.target.value
        : tag.value
      this.tagList.filter(x => {
        if (x.tag_group_name == tag.tag_group_name && x.type == 'radio') {
          x.selected = false
        }
      })
      if (tag.name == 'From' && itemMax[0].value == '') {
        itemMax[0].value = moment().format(this.formatdate)
        this.min_max['max'] = moment().format(this.formatdate)
      }
      this.$nextTick(() => {
        this.selectTag(tag, this.min_max).then(() => {
          this.callApiUserlistFilter().then(count => {
            this.count = count
          })
        })
      })
    },
    getTagList() {
      return new Promise(resolve => {
        if (this.tagList.length > 0) {
          if (this.tagList.find(x => x.selected == true) != undefined)
            this.tagList_temp.tagList_selected.push(
              this.tagList.find(x => x.selected == true),
            )
        }
        this.tagList = []
        if (this.groups) {
          this.groups[0].tag_group.forEach((element, idx_group) => {
            element.tag_list.map((item, index) => {
              this.tagList.push({
                id: index,
                groupid: idx_group,
                name: item.tag_list_name,
                selected: false,
                value: item.value,
                totalResult: 0,
                type: item.type,
                condition: item.condition,
                tag_group_name: element.tag_group_name,
                tag_group_value: element.tag_group_value,
                criteria: 'and',
              })
            })
          })
        }
        this.$nextTick(() => {
          if (cache.getCache('filterUser')) {
            _.forEach(cache.getCache('filterUser'), item => {
              _.forEach(this.tagList, tag => {
                if (tag.id == item.id && tag.groupid == item.groupid) {
                  if (
                    (item.name == 'From' || item.name == 'To') &&
                    item.name == tag.name
                  ) {
                    tag.criteria = item.criteria
                    tag.value = item.value
                    this.min_max[item.name == 'From' ? 'min' : 'max'] =
                      item.value

                    this.selectTag(tag, this.min_max)
                  } else {
                    if (item.criteria) {
                      tag.criteria = item.criteria
                      tag.selected = true
                      this.min_max = {
                        max: '',
                        min: '',
                      }
                      this.selectTag(tag, this.min_max)
                    }
                  }
                }
              })
            })

            this.$nextTick(() => {
              this.callApiUserlistFilter().then(count => {
                this.count = count
                resolve(count)
              })
            })
          } else {
            this.callApiUserlistFilter().then(count => {
              this.count = count
              resolve(count)
            })
          }
        })
      })
    },
    // listGroup(tag) {
    //   if (!tag.selected) {
    //     let itemOld = this.checkedList.findIndex(x => x.name == tag.name)
    //     this.checkedList.splice(itemOld, 1)
    //   } else {
    //     this.checkedList.push({
    //       ...tag,
    //     })
    //   }

    //   this.groupList = this.checkedList.reduce((arr, curr) => {
    //     arr[curr.tag_group_name] = arr[curr.tag_group_name] || []
    //     arr[curr.tag_group_name].push(curr)
    //     return arr
    //   }, Object.create(null))
    // },
    selectTag(tag, min_max) {
      return new Promise(resolve => {
        let itemIdx = this.selectedTag.findIndex(
          x => x.tag_group_name == tag.tag_group_name,
        )
        if (itemIdx != -1) {
          if (tag.type != 'checkbox') {
            this.selectedTag.splice(itemIdx, 1)
          }
          if (tag.type == 'int') {
            if (min_max.min == '' && min_max.max == '') {
              this.selectedTag.splice(itemIdx, 1)
            } else {
              tag.calculator = min_max.min + ' - ' + min_max.max
              this.selectedTag.push(tag)
            }
          }
          if (tag.type == 'date') {
            let marge_min = min_max.min.replaceAll('-', '/')
            let marge_max = min_max.max.replaceAll('-', '/')
            if (marge_min == '' && marge_max == '') {
              this.selectedTag.splice(itemIdx, 1)
            } else {
              tag.calculator = marge_min + ' - ' + marge_max
              this.selectedTag.push({
                ...tag,
              })
            }
          }
          if (tag.type == 'checkbox') {
            if (!tag.selected) {
              let itemOld = this.selectedTag.findIndex(x => x.name == tag.name)
              this.selectedTag.splice(itemOld, 1)
              let itemSame = this.selectedTag.filter(
                x => x.tag_group_name == tag.tag_group_name,
              )
              itemSame.forEach((e, i) => {
                e.index_sub = i
                e.length_sub = itemSame.length
              })
            } else {
              this.selectedTag.filter(x => {
                if (x.tag_group_name == tag.tag_group_name && x.type == 'int') {
                  x.value = ''
                }
              })
              let indexSame = this.selectedTag.findIndex(
                x =>
                  x.groupid == tag.groupid && x.length_sub - 1 == x.index_sub,
              )
              this.selectedTag.splice(indexSame + 1, 0, tag)
              let itemSame = this.selectedTag.filter(
                x => x.tag_group_name == tag.tag_group_name,
              )
              itemSame.forEach((e, i) => {
                e.index_sub = i
                e.length_sub = itemSame.length
              })
            }
          }
        } else {
          if (tag.type == 'int') {
            tag.calculator = min_max.min + ' - ' + min_max.max
            this.selectedTag.push({
              ...tag,
            })
          }
          if (tag.type == 'date') {
            tag.calculator =
              min_max.min.replaceAll('-', '/') +
              ' - ' +
              min_max.max.replaceAll('-', '/')
            this.selectedTag.push({
              ...tag,
            })
          }

          if (tag.type == 'checkbox' && tag.selected == false) {
            this.selectedTag.find(item => {
              if (tag.name == item.name) {
                this.selectedTag.splice(itemIdx, 1)
                this.renderComponent++
              }
            })
          }
          if (tag.type == 'checkbox') {
            tag.index_sub = 0
            tag.length_sub = 1
            this.selectedTag.push({
              ...tag,
            })
          }
        }
        this.$nextTick(() => {
          if (tag.type == 'radio') {
            var isupdate = false
            this.selectedTag.map((x, idx) => {
              if (x.tag_group_name == tag.tag_group_name) {
                this.selectedTag[idx] = tag
                isupdate = true
              }
            })
            if (!isupdate) {
              this.selectedTag.push({
                ...tag,
              })
            }
            this.$forceUpdate()
            this.tagList.filter((x, index) => {
              if (x.groupid == tag.groupid) {
                if (x.id == tag.id) {
                  this.tagList[index].selected = true
                } else {
                  this.tagList[index].selected = false
                }
              }
            })
          }
          this.$nextTick(() => {
            resolve()
          })
        })
      })
    },
    toggleDropdownSort: function () {
      this.showDropdownSort = !this.showDropdownSort
    },
    selectSortItem: function (item) {
      this.selectedSortItem = item
      this.toggleDropdownSort()
    },
    // getvaluebygroupid(groupid, index) {
    //   if (this.tagList.filter(x => x.groupid == groupid)[index].value) {
    //     this.tagList.map((x, idx) => {
    //       if (x.groupid == groupid && x.selected) {
    //         this.tagList[idx].selected = false
    //         let index_selectedTag_clear = this.selectedTag.findIndex(
    //           x =>
    //             x.groupid == groupid &&
    //             x.tag_group_value == this.tagList[idx].tag_group_value,
    //         )
    //         this.selectedTag.splice(index_selectedTag_clear, 1)
    //       }
    //     })
    //   }
    //   return this.tagList.filter(x => x.groupid == groupid)[index].value
    // },
    getvaluebygroupname(groupname, name) {
      if (
        this.tagList.filter(
          x => x.tag_group_value == groupname && x.name == name,
        )[0]
      ) {
        if (
          this.tagList.filter(
            x => x.tag_group_value == groupname && x.name == name,
          ) &&
          this.tagList.filter(
            x => x.tag_group_value == groupname && x.name == name,
          )[0].value
        ) {
          this.tagList.map((x, idx) => {
            if (x.tag_group_value == groupname && x.selected) {
              this.tagList[idx].selected = false
              let index_selectedTag_clear = this.selectedTag.findIndex(
                x =>
                  x.tag_group_value == groupname &&
                  x.tag_group_value == this.tagList[idx].tag_group_value,
              )
              this.selectedTag.splice(index_selectedTag_clear, 1)
            }
          })
        }
        return this.tagList.filter(
          x => x.tag_group_value == groupname && x.name == name,
        )[0].value
      }
    },
    getvaluebycretirianame(groupname) {
      let data = []
      data = this.selectedTag.filter(x => x.tag_group_value == groupname)
      if (data.length == 0) {
        return 'and'
      }
      return data[0].criteria
    },
    getdate_param(condition) {
      switch (condition) {
        case 'thismonth':
          return {
            start_date: moment().startOf('month').format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
          }
        case 'lastmonth':
          return {
            start_date: moment()
              .subtract(1, 'months')
              .startOf('month')
              .format('YYYY-MM-DD'),
            end_date: moment()
              .subtract(1, 'months')
              .endOf('month')
              .format('YYYY-MM-DD'),
          }
        case '3months':
          return {
            start_date: moment()
              .startOf('month')
              .subtract(2, 'months')
              .format('YYYY-MM-DD'),
            end_date: moment().endOf('month').format('YYYY-MM-DD'),
          }
        case '6months':
          return {
            start_date: moment()
              .startOf('month')
              .subtract(5, 'months')
              .format('YYYY-MM-DD'),
            end_date: moment().endOf('month').format('YYYY-MM-DD'),
          }
        case 'thisyear':
          return {
            start_date: moment().startOf('year').format('YYYY-MM-DD'),
            end_date: moment().endOf('year').format('YYYY-MM-DD'),
          }
        case 'lastyear':
          return {
            start_date: moment()
              .startOf('year')
              .subtract(1, 'year')
              .format('YYYY-MM-DD'),
            end_date: moment()
              .endOf('year')
              .subtract(1, 'year')
              .format('YYYY-MM-DD'),
          }
        default:
          return {
            start_date: '',
            end_date: '',
          }
      }
    },
    // date_from_condiition(groupid) {
    //   let data = []
    //   this.tagList
    //     .filter(x => x.groupid == groupid && x.selected)
    //     .forEach(element => {
    //       data.push(element)
    //     })
    //   let index = data.findIndex(x => x.selected && x.type == 'radio')
    //   if (index < 0) return { start_date: '', end_date: '' }
    //   return this.getdate_param(data[index].condition)
    // },
    date_from_condiitionByName(groupname) {
      let data = []
      this.tagList
        .filter(x => x.tag_group_value == groupname && x.selected)
        .forEach(element => {
          data.push(element)
        })
      let index = data.findIndex(x => x.selected && x.type == 'radio')
      if (index < 0) return { start_date: '', end_date: '' }
      return this.getdate_param(data[index].condition)
    },
    // get_min_max(groupid) {
    //   let data = []
    //   data = this.tagList.filter(x => x.groupid == groupid && x.selected)
    //   let index = data.findIndex(x => x.selected)
    //   if (index < 0) return { min: '', max: '' }
    //   if (data[index].value.includes('above')) {
    //     let value_above = data[index].value.split('above')
    //     if (value_above && value_above[1]) {
    //       return { min: value_above[1], max: 'above' }
    //     }
    //   }

    //   let value = data[index].value.split('-')
    //   if (value && value[0] && value[1]) {
    //     return value[0].toLowerCase() == 'above'
    //       ? { min: value[1], max: 'above' }
    //       : { min: value[0], max: value[1] }
    //   }
    // },
    get_min_maxbyname(groupname) {
      let data = []
      data = this.tagList.filter(
        x => x.tag_group_value == groupname && x.selected,
      )
      let index = data.findIndex(x => x.selected)

      if (index < 0) return { min: '', max: '' }
      if (data[index].value.includes('above')) {
        let value_above = data[index].value.split('above')
        if (value_above && value_above[1]) {
          return { min: value_above[1], max: 'above' }
        }
      }
      let value = data[index].value.split('-')
      if (value) {
        if (value[0] && value[1]) {
          return value[0].toLowerCase() == 'above'
            ? { min: value[1], max: 'above' }
            : { min: value[0], max: value[1] }
        } else {
          return value[1]
            ? { min: value[0], max: value[1] }
            : { min: value[0], max: '' }
        }
      }
    },
    getselectedbyname(name, type = 'value') {
      var selecteddata = []
      this.tagList
        .filter(x => x.tag_group_value == name && x.selected)
        .forEach(element => {
          selecteddata.push(element[type])
        })
      return selecteddata
    },
    callApiUserlistFilter() {
      var params = {
        profile_update: {
          start_date: this.date_from_condiitionByName('profile_update')
            .start_date
            ? this.date_from_condiitionByName('profile_update').start_date
            : this.getvaluebygroupname('profile_update', 'From'),
          end_date: this.date_from_condiitionByName('profile_update').end_date
            ? this.date_from_condiitionByName('profile_update').end_date
            : this.getvaluebygroupname('profile_update', 'To'),
          criteria: this.getvaluebycretirianame('profile_update'),
        },
        joindate: {
          start_date: this.date_from_condiitionByName('joindate').start_date
            ? this.date_from_condiitionByName('joindate').start_date
            : this.getvaluebygroupname('joindate', 'From'),
          end_date: this.date_from_condiitionByName('joindate').end_date
            ? this.date_from_condiitionByName('joindate').end_date
            : this.getvaluebygroupname('joindate', 'To'),
          criteria: this.getvaluebycretirianame('joindate'),
        },
        birthday: {
          name: [...this.getselectedbyname('birthday')],
          criteria: 'and',
        },
        // age: {
        //   min: this.get_min_maxbyname('age').min
        //     ? this.get_min_maxbyname('age').min == 'above'
        //       ? ''
        //       : Number(this.get_min_maxbyname('age').min)
        //     : this.getvaluebygroupname('age', 'From') == ''
        //     ? this.getvaluebygroupname('age', 'From')
        //     : Number(this.getvaluebygroupname('age', 'From')),
        //   max: this.get_min_maxbyname('age').max
        //     ? this.get_min_maxbyname('age').max == 'above'
        //       ? ''
        //       : Number(this.get_min_maxbyname('age').max)
        //     : this.getvaluebygroupname('age', 'To') == ''
        //     ? this.getvaluebygroupname('age', 'To')
        //     : Number(this.getvaluebygroupname('age', 'To')),
        //   criteria: this.getvaluebycretirianame('age'),
        // },
        gender: {
          name: [...this.getselectedbyname('gender')],
          criteria: this.getvaluebycretirianame('gender'),
        },
        province: {
          name: [...this.getselectedbyname('province', 'name')],
          criteria: 'and',
        },
        fraud_status: {
          name: [...this.getselectedbyname('fraud_status')],
          criteria: this.getvaluebycretirianame('fraud_status'),
        },
        term_and_condition: {
          value: this.getselectedbyname('team_and_con')[0],
          criteria: this.getvaluebycretirianame('team_and_con'),
        },
        data_privacy: {
          value: this.getselectedbyname('dataprivacy')[0],
          criteria: this.getvaluebycretirianame('dataprivacy'),
        },
        marketing_option: {
          value: this.getselectedbyname('marketing_option')[0],
          criteria: this.getvaluebycretirianame('marketing_option'),
        },
        consent_age: {
          value: this.getselectedbyname('consent_age')[0],
          criteria: this.getvaluebycretirianame('consent_age'),
        },
        line_marketing: {
          value: this.getselectedbyname('line_marketing')[0],
          criteria: this.getvaluebycretirianame('line_marketing'),
        },
        notification_marketing: {
          value: this.getselectedbyname('notification_marketing')[0],
          criteria: this.getvaluebycretirianame('notification_marketing'),
        },
        sms_marketing: {
          value: this.getselectedbyname('sms_marketing')[0],
          criteria: this.getvaluebycretirianame('sms_marketing'),
        },
        email_marketing: {
          value: this.getselectedbyname('email_marketing')[0],
          criteria: this.getvaluebycretirianame('email_marketing'),
        },
        total_earn: {
          min: this.get_min_maxbyname('total_earn').min
            ? this.get_min_maxbyname('total_earn').min == 'above'
              ? ''
              : Number(this.get_min_maxbyname('total_earn').min)
            : this.getvaluebygroupname('total_earn', 'From') == ''
            ? this.getvaluebygroupname('total_earn', 'From')
            : Number(this.getvaluebygroupname('total_earn', 'From')),
          max: this.get_min_maxbyname('total_earn').max
            ? this.get_min_maxbyname('total_earn').max == 'above'
              ? ''
              : Number(this.get_min_maxbyname('total_earn').max)
            : this.getvaluebygroupname('total_earn', 'To') == ''
            ? this.getvaluebygroupname('total_earn', 'To')
            : Number(this.getvaluebygroupname('total_earn', 'To')),
          criteria: this.getvaluebycretirianame('total_earn'),
        },
        total_burn: {
          min: this.get_min_maxbyname('total_burn').min
            ? this.get_min_maxbyname('total_burn').min == 'above'
              ? ''
              : Number(this.get_min_maxbyname('total_burn').min)
            : this.getvaluebygroupname('total_burn', 'From') == ''
            ? this.getvaluebygroupname('total_burn', 'From')
            : Number(this.getvaluebygroupname('total_burn', 'From')),
          max: this.get_min_maxbyname('total_burn').max
            ? this.get_min_maxbyname('total_burn').max == 'above'
              ? ''
              : Number(this.get_min_maxbyname('total_burn').max)
            : this.getvaluebygroupname('total_burn', 'To') == ''
            ? this.getvaluebygroupname('total_burn', 'To')
            : Number(this.getvaluebygroupname('total_burn', 'To')),
          criteria: this.getvaluebycretirianame('total_burn'),
        },
        point_on_hand: {
          min: this.get_min_maxbyname('pointonhand').min
            ? this.get_min_maxbyname('pointonhand').min == 'above'
              ? ''
              : Number(this.get_min_maxbyname('pointonhand').min)
            : this.getvaluebygroupname('pointonhand', 'From') == ''
            ? this.getvaluebygroupname('pointonhand', 'From')
            : Number(this.getvaluebygroupname('pointonhand', 'From')),
          max: this.get_min_maxbyname('pointonhand').max
            ? this.get_min_maxbyname('pointonhand').max == 'above'
              ? ''
              : Number(this.get_min_maxbyname('pointonhand').max)
            : this.getvaluebygroupname('pointonhand', 'To') == ''
            ? this.getvaluebygroupname('pointonhand', 'To')
            : Number(this.getvaluebygroupname('pointonhand', 'To')),
          criteria: this.getvaluebycretirianame('pointonhand'),
        },
        point_date: {
          start_date: this.date_from_condiitionByName('point_date').start_date
            ? this.date_from_condiitionByName('point_date').start_date
            : this.getvaluebygroupname('point_date', 'From'),
          end_date: this.date_from_condiitionByName('point_date').end_date
            ? this.date_from_condiitionByName('point_date').end_date
            : this.getvaluebygroupname('point_date', 'To'),
          criteria: this.getvaluebycretirianame('point_date'),
        },
        last_active: {
          start_date: this.date_from_condiitionByName('last_active').start_date
            ? this.date_from_condiitionByName('last_active').start_date
            : this.getvaluebygroupname('last_active', 'From'),
          end_date: this.date_from_condiitionByName('last_active').end_date
            ? this.date_from_condiitionByName('last_active').end_date
            : this.getvaluebygroupname('last_active', 'To'),
          criteria: this.getvaluebycretirianame('last_active'),
        },
        last_redeem: {
          start_date: this.date_from_condiitionByName('last_redeem').start_date
            ? this.date_from_condiitionByName('last_redeem').start_date
            : this.getvaluebygroupname('last_redeem', 'From'),
          end_date: this.date_from_condiitionByName('last_redeem').end_date
            ? this.date_from_condiitionByName('last_redeem').end_date
            : this.getvaluebygroupname('last_redeem', 'To'),
          criteria: this.getvaluebycretirianame('last_redeem'),
        },
        last_scan: {
          start_date: this.date_from_condiitionByName('last_scan').start_date
            ? this.date_from_condiitionByName('last_scan').start_date
            : this.getvaluebygroupname('last_scan', 'From'),
          end_date: this.date_from_condiitionByName('last_scan').end_date
            ? this.date_from_condiitionByName('last_scan').end_date
            : this.getvaluebygroupname('last_scan', 'To'),
          criteria: this.getvaluebycretirianame('last_scan'),
        },
        last_earn: {
          start_date: this.date_from_condiitionByName('last_earn').start_date
            ? this.date_from_condiitionByName('last_earn').start_date
            : this.getvaluebygroupname('last_earn', 'From'),
          end_date: this.date_from_condiitionByName('last_earn').end_date
            ? this.date_from_condiitionByName('last_earn').end_date
            : this.getvaluebygroupname('last_earn', 'To'),
          criteria: this.getvaluebycretirianame('last_earn'),
        },
        registration_type: {
          name: [...this.getselectedbyname('registration_type')],
          criteria: 'and',
        },

        number_of_friend: {
          min: this.get_min_maxbyname('number_of_friend').min
            ? Number(this.get_min_maxbyname('number_of_friend').min)
            : this.getvaluebygroupname('number_of_friend', 'From') == ''
            ? this.getvaluebygroupname('number_of_friend', 'From')
            : Number(this.getvaluebygroupname('number_of_friend', 'From')),
          max: this.get_min_maxbyname('number_of_friend').max
            ? Number(this.get_min_maxbyname('number_of_friend').max)
            : this.getvaluebygroupname('number_of_friend', 'To') == ''
            ? this.getvaluebygroupname('number_of_friend', 'To')
            : Number(this.getvaluebygroupname('number_of_friend', 'To')),
          criteria: this.getvaluebycretirianame('number_of_friend'),
        },
        user_level: {
          name: [...this.getselectedbyname('user_levels')],
          criteria: 'and',
        },
      }
      if (!this.permissionmaskdata) {
        params.age = {
          min: this.get_min_maxbyname('age').min
            ? this.get_min_maxbyname('age').min == 'above'
              ? ''
              : Number(this.get_min_maxbyname('age').min)
            : this.getvaluebygroupname('age', 'From') == ''
            ? this.getvaluebygroupname('age', 'From')
            : Number(this.getvaluebygroupname('age', 'From')),
          max: this.get_min_maxbyname('age').max
            ? this.get_min_maxbyname('age').max == 'above'
              ? ''
              : Number(this.get_min_maxbyname('age').max)
            : this.getvaluebygroupname('age', 'To') == ''
            ? this.getvaluebygroupname('age', 'To')
            : Number(this.getvaluebygroupname('age', 'To')),
          criteria: this.getvaluebycretirianame('age'),
        }
      }
      for (const property in params) {
        if (
          typeof params[property].max !== 'undefined' &&
          params[property].max === ''
        ) {
          delete params[property].max
        }
        if (
          typeof params[property].min !== 'undefined' &&
          params[property].min === ''
        ) {
          delete params[property].min
        }
        // if (
        //   typeof params[property].min == 'undefined' &&
        //   typeof params[property].max == 'undefined' &&
        //   list_min_max.find(x => x == property)
        // ) {
        //   delete params[property].criteria
        // }
        for (const item in params[property]) {
          if (
            typeof params[property].value == 'undefined' &&
            params[property].criteria == 'and'
          ) {
            delete params[property].criteria
            delete params[property].value
          }
          if (
            (item == 'start_date' || item == 'end_date') &&
            params[property][item] == ''
          ) {
            delete params[property][item]
          }
          if (
            Array.isArray(params[property][item]) &&
            params[property][item].length == 0
          ) {
            delete params[property][item]
          }
          if (Object.keys(params[property]).length == 1 && item == 'criteria') {
            params[property] = {}
          }
        }
      }
      return new Promise(resolve => {
        this.callApiCount(params).then(count => {
          resolve(count)
        })
      })
    },
    loadSaveList(tag) {
      this.clearFilter()
      if (tag.criteria) {
        var params = JSON.parse(tag.criteria)
        var selectTagCache = []
        let clonejson = {}
        Object.keys(params).forEach(key => {
          if (params[key] === null) {
            delete params[key]
          }
        })
        _.forEach(params, (item, key) => {
          if (key == 'term_and_condition') {
            clonejson['team_and_con'] = item
          } else if (key == 'data_privacy') {
            clonejson['dataprivacy'] = item
          } else if (key == 'join_date') {
            clonejson['joindate'] = item
          } else if (key == 'point_on_hand') {
            clonejson['pointonhand'] = item
          } else {
            clonejson[key] = item
          }
        })
        _.forEach(this.tagList, taggroup => {
          _.forEach(clonejson, (item, key) => {
            if (taggroup.tag_group_value == key) {
              if (item.start_date && item.end_date) {
                if (
                  (taggroup.name == 'From' || taggroup.name == 'To') &&
                  taggroup.type == 'date'
                ) {
                  selectTagCache.push({
                    id: taggroup.id,
                    groupid: taggroup.groupid,
                    name: taggroup.name,
                    value: moment(
                      taggroup.name == 'From' ? item.start_date : item.end_date,
                    ).format(this.formatdate),
                    criteria: item.criteria ? item.criteria : 'and',
                  })
                }
              }
              if (taggroup.type == 'checkbox' && item.name) {
                _.forEach(item.name, name => {
                  if (taggroup.value == name) {
                    selectTagCache.push({
                      id: taggroup.id,
                      groupid: taggroup.groupid,
                      value: name,
                      criteria: item.criteria ? item.criteria : 'and',
                    })
                  }
                })
              }
              if (taggroup.type == 'radio' && item.type) {
                _.forEach(item.type, type => {
                  if (taggroup.value == type) {
                    selectTagCache.push({
                      id: taggroup.id,
                      groupid: taggroup.groupid,
                      value: type,
                      criteria: item.criteria ? item.criteria : 'and',
                    })
                  }
                })
              }
              if (taggroup.type == 'radio' && item.value !== null) {
                if (taggroup.value == item.value) {
                  selectTagCache.push({
                    id: taggroup.id,
                    groupid: taggroup.groupid,
                    value: item.value,
                    criteria: item.criteria ? item.criteria : 'and',
                  })
                }
              }
              if (taggroup.type == 'radio' && (item.min || item.max)) {
                if (item.min && item.max) {
                  let value = item.min + '-' + item.max
                  if (taggroup.value == value) {
                    selectTagCache.push({
                      id: taggroup.id,
                      groupid: taggroup.groupid,
                      value: taggroup.value,
                      criteria: item.criteria ? item.criteria : 'and',
                    })
                  }
                } else if (item.min) {
                  let value = 'above' + item.min
                  if (taggroup.value == value) {
                    selectTagCache.push({
                      id: taggroup.id,
                      groupid: taggroup.groupid,
                      value: taggroup.value,
                      criteria: item.criteria ? item.criteria : 'and',
                    })
                  }
                }
              }
              if (taggroup.type == 'int') {
                if (item.min !== null && item.max !== null) {
                  console.log(taggroup.groupid, 'min/max')
                  let value = item.min + '-' + item.max
                  let checkradio = this.tagList.filter(
                    tag =>
                      tag.tag_group_value == key &&
                      tag.type == 'radio' &&
                      tag.value == value,
                  )
                  if (checkradio.length == 0) {
                    if (taggroup.name == 'From') {
                      selectTagCache.push({
                        id: taggroup.id,
                        groupid: taggroup.groupid,
                        name: taggroup.name,
                        value: item.min.toString(),
                        criteria: item.criteria ? item.criteria : 'and',
                      })
                    }
                    if (taggroup.name == 'To') {
                      selectTagCache.push({
                        id: taggroup.id,
                        groupid: taggroup.groupid,
                        name: taggroup.name,
                        value: item.max.toString(),
                        criteria: item.criteria ? item.criteria : 'and',
                      })
                    }
                  }
                }
                if (item.min) {
                  let value = 'above' + item.min
                  let checkradio = this.tagList.filter(
                    tag =>
                      tag.tag_group_value == key &&
                      tag.type == 'radio' &&
                      tag.value == value,
                  )
                  if (taggroup.name == 'From' && checkradio.length == 0) {
                    selectTagCache.push({
                      id: taggroup.id,
                      groupid: taggroup.groupid,
                      name: taggroup.name,
                      value: item.min.toString(),
                      criteria: item.criteria ? item.criteria : 'and',
                    })
                  }
                }
                if (item.max) {
                  if (taggroup.name == 'To') {
                    selectTagCache.push({
                      id: taggroup.id,
                      groupid: taggroup.groupid,
                      name: taggroup.name,
                      value: item.max.toString(),
                      criteria: item.criteria ? item.criteria : 'and',
                    })
                  }
                }
              }
            }
          })
        })
        if (selectTagCache.length) {
          this.tagList.filter(x => {
            if (x.groupid == selectTagCache[0].groupid) {
              this.activeGroupName = x.tag_group_name
            }
          })
          this.groupsorder.filter((s, id) => {
            if (s.tag_group_name == this.activeGroupName) {
              this.activeGroupIdx = id
            }
          })
        }
        this.$nextTick(() => {
          cache.setCache('filterUser', selectTagCache)
          this.getTagList()
        })
      }
    },
    callApiCount(params) {
      this.filterParams = { params }
      return new Promise((resolve, reject) => {
        // BzbsUser.apiPostUserListCount(this.checkparams(params))
        BzbsUser.apiPostUserListCount(params)
          .then(res => {
            this.$emit('countbyfilter', res.data.result)
            resolve(res.data.result)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    // checkparams(params) {
    //   if (
    //     !_.isEmpty(params.profile_update) &&
    //     params.profile_update.start_date == '' &&
    //     params.profile_update.end_date == ''
    //   ) {
    //     params.profile_update = {}
    //   }
    //   if (
    //     !_.isEmpty(params.joindate) &&
    //     params.joindate.start_date == '' &&
    //     params.joindate.end_date == ''
    //   ) {
    //     params.joindate = {}
    //   }
    //   if (!_.isEmpty(params.birthday) && params.birthday.name.length == 0) {
    //     params.birthday = {}
    //   }
    //   if (!_.isEmpty(params.gender) && params.gender.name.length == 0) {
    //     params.gender = {}
    //   }
    //   if (!_.isEmpty(params.province) && params.province.name.length == 0) {
    //     params.province = {}
    //   }
    //   if (
    //     !_.isEmpty(params.fraud_status) &&
    //     params.fraud_status.type.length == 0
    //   ) {
    //     params.fraud_status = {}
    //   }
    //   if (
    //     !_.isEmpty(params.last_active) &&
    //     params.last_active.start_date === '' &&
    //     params.last_active.end_date === ''
    //   ) {
    //     params.last_active = {}
    //   }
    //   if (
    //     !_.isEmpty(params.last_redeem) &&
    //     params.last_redeem.start_date == '' &&
    //     params.last_redeem.end_date == ''
    //   ) {
    //     params.last_redeem = {}
    //   }
    //   if (
    //     !_.isEmpty(params.last_scan) &&
    //     params.last_scan.start_date == '' &&
    //     params.last_scan.end_date == ''
    //   ) {
    //     params.last_scan = {}
    //   }

    //   if (
    //     !_.isEmpty(params.registration_type) &&
    //     params.registration_type.name.length == 0
    //   ) {
    //     params.registration_type = {}
    //   }

    //   if (!_.isEmpty(params.user_level) && params.user_level.name.length == 0) {
    //     params.user_level = {}
    //   }

    //   return params
    // },
    // setcount(count) {
    //   this.count = count
    // },
    setcriteria(event, t) {
      if (t.name == 'from' || t.name == 'To') {
        this.tagList.filter(x => {
          if (x.groupid == t.groupid && (x.name == 'From' || x.name == 'To')) {
            if (!event) {
              x.criteria = 'or'
            } else {
              x.criteria = 'and'
            }
          }
        })
        this.selectedTag.filter(x => {
          if (x.groupid == t.groupid && (x.name == 'From' || x.name == 'To')) {
            if (!event) {
              x.criteria = 'or'
            } else {
              x.criteria = 'and'
            }
          }
        })
      } else {
        let index = this.tagList.findIndex(
          x => x.groupid == t.groupid && x.name == t.name,
        )
        let index_selected_tag = this.selectedTag.findIndex(
          x => x.groupid == t.groupid && x.name == t.name,
        )

        if (!event) {
          this.tagList[index].criteria = 'or'
          this.selectedTag[index_selected_tag].criteria = 'or'
        } else {
          this.tagList[index].criteria = 'and'
          this.selectedTag[index_selected_tag].criteria = 'and'
        }
      }
      this.$nextTick(() => {
        this.callApiUserlistFilter().then(count => {
          this.count = count
          this.forceRerender()
        })
      })
    },
    removeCriteria(item) {
      let index = this.tagList.findIndex(
        x => x.groupid == item.groupid && x.name == item.name,
      )
      this.tagList[index].selected = false
      this.selectedTag = this.selectedTag.filter(
        val => !(val.groupid == item.groupid && val.name == item.name),
      )
      this.tagList.filter(x => {
        if (x.tag_group_name == item.tag_group_name) {
          if (x.type == 'radio') {
            x.selected = false
          }
          if (x.name == 'From' || x.name == 'To') {
            x.value = ''
          }
        }
      })
      this.$nextTick(() => {
        this.callApiUserlistFilter().then(count => {
          this.count = count
          this.forceRerender()
        })
      })
    },
    removeCriteriaCheckbox(item) {
      console.log('removeCriteriaCheckbox', item)
      this.selectedTag = this.selectedTag.filter(
        val => !(val.groupid == item.groupid),
      )
      this.tagList.forEach(e => {
        if (e.tag_group_name == item.tag_group_name) {
          e.selected = false
        }
      })
      this.$nextTick(() => {
        this.callApiUserlistFilter().then(count => {
          this.count = count
          this.forceRerender()
        })
      })
    },
    forceRerender() {
      this.renderComponent++
    },
    disabledstart_date(startValue) {
      const endValue = this.endValue
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledend_date(endValue) {
      const startValue = this.startValue
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() >= endValue.valueOf()
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.box-filter {
  z-index: 100;
  label {
    width: 100%;
    cursor: pointer;
    display: block;
  }
  .tag {
    .v-list > .v-list-item {
      padding: 0;
      min-width: max-content;
    }
    label {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .input {
      padding: 10px 0;
    }
  }
  .criteria {
    .v-list {
      height: calc(100% - 40px);
    }
  }
  .box-label {
    width: 40%;
    float: left;
  }
  .box-number {
    padding: 8px 0;
    input {
      width: 75%;
    }
  }
  .box-number,
  .box-date {
    width: 60%;
    float: left;
    .form-textbox {
      color: $color-body;
      line-height: 22px;
      border: 1px solid $color-grey-line;
      padding: 3px 10px;
      background: #fff;
    }
  }
  .reset-filer {
    color: #f79443;
  }
}
</style>
<style lang="scss">
.box-filter {
  .v-list-item {
    .form-checkbox {
      width: 14px;
      height: 14px;
    }
  }
}
</style>
