<template>
  <a-card class="w-full min-h-full user-level">
    <nav class="filter-nav">
      <h4 class="mb-3">Set Level</h4>
      <!-- <div class="group-btn-left">
        <a-input
          v-model:value="search"
          placeholder="Search Level"
          class="input-search"
          ><template #prefix
            ><search-outlined
              class="pl-3"
              style="color: rgba(0, 0, 0, 0.25)" /></template
        ></a-input>
        <a-button type="primary" class="btn-search" @click="clicksearch"
          >Search</a-button
        >
      </div> -->
    </nav>
    <div :class="setperrmissionexport ? 'wrap-table select-td' : 'wrap-table-noper select-td'">
      <VLoadingTable :value="overlaytable" />
      <a-table row-key="key" class="fixed-head" :dataSource="dataLevel" :row-selection="{
        selectedRowKeys: rowSelection.selectedRowKeys,
        onChange: onSelectChange,
        columnTitle: rowSelection.columnTitle,
        getCheckboxProps: record => ({
          disabled: setperrmissionselect,
        }),
      }" :columns="columns" :pagination="false">
        <!-- <template #level="{ text }">
          <a class="font-paymentid" @click="showModal(record)">{{ text }}</a>
        </template> -->
        <template #expiredon="{ text }">
          <div>{{ text ? getdate(text) : '-' }}</div>
        </template>
      </a-table>
    </div>
    <hr />
    <div v-show="setperrmissionexport" class="text-center pt-3 pb-3">
      <a-button type="primary" class="btn-search b-update" @click="showModal()" :disabled="disable">
        Set Level
      </a-button>
    </div>
    <DisplayFields :value="showDisplayFields" :datalogs="datalogslevel" @apply="applyDisplayFields($event)"
      @handleClose="showDisplayFields = false">
    </DisplayFields>
    <a-modal title="Set Expiration Date" class="modal-set-expire" :visible="visible" @cancel="handleCancel" okText="Save"
      :width="355" :centered="true" @ok="UpdateUserLevel()" :closable="false">
      <a-radio-group v-model:value="setexpiry" @change="change">
        <a-radio class="radio-expiry" :value="1">Set to
          <a-date-picker style="width: 210px" v-model:value="expirydate" placeholder="Select Expiry" :showToday="false"
            :disabled-date="disabledDate" :disabled="setexpiry != 1" /></a-radio>
        <a-radio class="radio-expiry mt-2" :value="2">No expiration</a-radio>
      </a-radio-group>
    </a-modal>
  </a-card>
</template>

<script>
import _ from 'lodash'
// import { SearchOutlined } from '@ant-design/icons-vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import DisplayFields from '@/module/User/component/DisplayFieldsUserLevelLog.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserRedeem',
  components: {
    DisplayFields,
    // DateFilter,
    // SearchOutlined,
    // CalendarOutlined
  },
  props: {
    dataapp: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      setexpiry: 2,
      expirydate: null,
      visible: false,
      showDisplayFields: false,
      disable: true,
      setperrmissionselect: false,
      setperrmissionexport: true,
      rowSelection: {
        selectedRowKeys: [],
      },
      columns: [
        // {
        //   title: 'Set Level',
        //   dataIndex: 'setlevel',
        //   slots: { customRender: 'rowSelection' },
        //   key: 'setlevel',
        //   width: 120,
        //   align: 'left',
        // },
        {
          title: 'Level',
          dataIndex: 'level',
          slots: { customRender: 'level' },
          key: 'level',
          width: 120,
          align: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: 500,
          align: 'left',
        },
        {
          title: 'Earned On',
          dataIndex: 'earnedon',
          key: 'earnedon',
          width: 120,
          align: 'left',
        },
        {
          title: 'Expired On ',
          dataIndex: 'expiredon',
          slots: { customRender: 'expiredon' },
          key: 'expiredon',
          width: 120,
          align: 'left',
        },
      ],
      dataLevel: [],
      displayFields: [],
      checktrue: [],
      showExportModal: false,
      showPrepareModal: false,
      formatdate: 'DD/MMM/YYYY',
      setcheck: false,
    }
  },
  beforeCreate() {
    this.displayFields = _.cloneDeep(this.columns)
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'level') {
        this.$store.commit('setLoading', true)
        this.displayFields = _.cloneDeep(this.columns)
        this.getdatalevel().then(() => {
          this.$store.commit('setLoading', false)
          if (this.rowSelection.selectedRowKeys.length > 0) {
            return this.rowSelection.columnTitle = <div class='setlevel'>Select</div>
          } else {
            return this.rowSelection.columnTitle = <div class='setlevel'>Select</div>
          }
        })
      }
    },
  },
  computed() { },
  mounted() {
    if (this.$route.query.tab == 'level') {
      this.displayFields = _.cloneDeep(this.columns)
      this.getdatalevel().then(() => {
        if (this.rowSelection.selectedRowKeys.length > 0) {
          return this.rowSelection.columnTitle = <div class='setlevel'>Select</div>
        } else {
          return this.rowSelection.columnTitle = <div class='setlevel'>Select</div>
        }
      })
      this.overlay = false
    }
  },
  create() { },
  methods: {
    change() {
      if (this.setexpiry == 2) {
        this.expirydate = null
      }
    },
    getdate(date) {
      if (date.includes('9999')) {
        return 'No Expiry Date'
      } else {
        return moment(date).format('DD/MMM/YYYY')
      }
    },
    onSelectChange(selectedRowKeys) {
      console.log(
        'selectedRowKeys changed: ',
        this.rowSelection.selectedRowKeys,
      )
      console.log(this.rowSelection.selectedRowKeys, selectedRowKeys)
      if (selectedRowKeys.length > this.rowSelection.selectedRowKeys.length) {
        this.checktrue.push(
          selectedRowKeys.find(
            x => !this.rowSelection.selectedRowKeys.includes(x),
          ),
        )
      } else {
        var itemremove = this.rowSelection.selectedRowKeys.find(
          x => !selectedRowKeys.includes(x),
        )
        var removeindex = this.checktrue.indexOf(itemremove)
        if (removeindex > -1) {
          this.checktrue.splice(this.checktrue.indexOf(itemremove), 1)
        }
      }
      console.log(this.checktrue)
      this.rowSelection.selectedRowKeys = selectedRowKeys
      this.disable = false
      if (this.rowSelection.selectedRowKeys.length > 0) {
        return this.rowSelection.columnTitle = <div class='setlevel'>Select</div>
      } else {
        return this.rowSelection.columnTitle = <div class='selectlevel'>Select</div>
      }
    },
    getdatalevel() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetDataUserlevelRoleStraff(this.$route.params.id).subscribe(
          res => {
            if (res.result != null) {
              this.dataLevel = res.result.map(x => ({
                key: x.level_id,
                check: x.checked,
                level: x.level,
                description: x.description ? x.description : '-',
                earnedon: x.earn_on
                  ? moment(x.earn_on).format(this.formatdate)
                  : '-',
                expiredon: x.expired_on,
              }))
              this.checktrue = []
            }
            this.dataLevel.forEach(x => {
              if (x.check == true) {
                this.rowSelection.selectedRowKeys.push(x.key)
              }
            })
            this.setpermissionexport()
            this.setpermissionselect()
            resolve(this.datalevel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },

    UpdateUserLevel() {
      var userlevelupdate = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        user_levels: this.dataLevel.map(x => ({
          level_id: x.key,
          checked: this.rowSelection.selectedRowKeys.find(z => z == x.key)
            ? true
            : false,
          expired_date:
            this.checktrue.includes(x.key) && !x.checked
              ? this.expirydate
              : x.expiredon,
        })),
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutDatalevel(userlevelupdate)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
              'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            this.visible = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    showModal() {
      if (
        this.checktrue.length > 0 &&
        this.checktrue.some(
          x => !this.dataLevel.map(y => y.level_id).includes(x),
        )
      ) {
        this.visible = true
      } else {
        this.UpdateUserLevel()
      }
    },
    disabledDate(startValue) {
      var date = new Date()
      return startValue.valueOf() < date.setDate(date.getDate()).valueOf()
    },
    handleCancel() {
      this.visible = false
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows)
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows)
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          console.log('loadMore', this.page)
          this.getdatalevel().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    toggleDisplayFieldsLogs() {
      if (this.showDisplayFields == false) {
        this.getloghistory().then(() => {
          this.showDisplayFields = !this.showDisplayFields
        })
      } else {
        this.showDisplayFields = !this.showDisplayFields
      }
    },
    setpermissionexport() {
      if (
        this.dataapp.features.every(x => x.name != 'User-List-Level-Edit')
      ) {
        return (this.setperrmissionexport = false)
      }
    },
    setpermissionselect() {
      if (
        this.dataapp.features.every(x => x.name != 'User-List-Level-Edit')
      ) {
        return (this.setperrmissionselect = true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';

.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }

  .nav-search .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .line {
    width: 2px;
    height: 64px;
    float: right;
    background-color: $color-grey-line;
    position: absolute;
    top: 14%;
    left: calc(100% - 158px);
  }

  .nav-search {
    padding-bottom: 0px !important;

    .group-btn-left {
      float: right;
      display: flex;
      padding-right: 5px;
    }

    .group-btn {
      float: right;
      margin-left: 16px;
    }
  }

  .wrap-table {
    height: calc(100vh - 375px) !important;

    .ant-table-wrapper {
      min-width: inherit;
    }
  }

  .wrap-table-noper {
    display: block;
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 315px) !important;
  }
}

.font-paymentid {
  color: #f9a601;
  text-decoration: underline;
}

.tt {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #125eaf;
  color: #fff;
}

#rc-tabs-0-panel-points {
  position: relative;
}

.b-update {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  font-weight: 600;
}
</style>
<style lang="scss">
.radio-expiry {
  color: #666c8b !important;
  display: block !important;
  font-weight: 500 !important;
}

.user-level {
  .ant-table-row {
    vertical-align: top !important;
  }
}

.ant-picker-cell-disabled {
  pointer-events: unset !important;
}
</style>