<template>
  <div class="mb-4 b-6 bg-white pl-4 d-flex justify-content-start">
    <div class="py-2 d-flex align-items-center">
      <a-select
        v-if="
          datapermission.includes('Search-Serial-Campaign-View') &&
          datapermission.includes('Search-Serial-Coupon-View')
        "
        class="type-search"
        v-model:value="typesearch"
        :options="options_search"
        @change="changeType()"
      />
      <div class="ml-3 min-max-con">Coupon Serial :</div>
      <a-input
        v-model:value="search"
        class="advanced-search ml-10"
        placeholder="Search by Serial"
        @pressEnter="SearchSerial()"
      >
        <template #prefix>
          <a @click="SearchSerial()">
            <search-outlined />
          </a>
        </template>
      </a-input>
      <!-- <a-input
        v-model:value="search"
        placeholder="Search by Serial"
        class="input-search ml-10"
      />
      <a-button
        type="primary"
        :disabled="!search"
        @click="SearchSerial()"
        class="btn-search ml-3"
        ><search-outlined /> Search</a-button
      > -->
    </div>
  </div>
  <div class="table-scroll">
    <div class="wrap-table" @scroll="handleScroll">
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataCoupon"
        :columns="columns"
        :pagination="false"
      >
        <template #coupon_serial="{ record }">
          <div>{{ record.serial ? record.serial : '-' }}</div>
        </template>
        <template #user="{ record }">
          <div class="text-link">
            <router-link
              :to="{
                name: 'user_detail',
                params: {
                  id: record.user_id,
                  first_name: record.first_name ? record.first_name : '-',
                  last_name: record.last_name ? record.last_name : '-',
                },
              }"
            >
              {{ record.user_id }}
            </router-link>
          </div>
          <div>{{ record.first_name }} {{ record.last_name }}</div>
          <!-- {{ record.topic_detail ? record.topic_detail : '-' }} -->
        </template>
        <template #import_date="{ record }">
          <div>
            {{
              formatDate(
                record.import_date,
                'DD/MMM/YYYY - HH:mm',
                this.isTimezone * -1,
              )
            }}
          </div>
        </template>
        <template #used_status="{ record }">
          <template v-for="(item, index) in status">
            <a-tag
              v-if="record.is_used == item.name"
              :key="index"
              :color="item.color == 'default' ? null : item.color"
              class="tag-status"
            >
              <a-badge :status="item.color" :text="item.text" />
            </a-tag>
          </template>
        </template>
        <template #used_date="{ record }">
          <div>
            {{
              formatDate(
                record.used_date,
                'DD/MMM/YYYY - HH:mm',
                this.isTimezone * -1,
              )
            }}
          </div>
        </template>
        <template #points="{ record }">
          <div>
            {{ record.point }}
          </div>
        </template>
        <template #points_earn="{ record }">
          <div>
            {{ record.point_earn }}
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import ConfigManager from '@/config/ConfigManager.js'
// import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { config } from '@/config/bzbsconfig.js'
// import moment from 'moment'
// import dayjs from 'dayjs'
// import DateFilter from '@/module/User/component/DateFilter.vue'

export default {
  name: 'UserLevel',
  components: {
    // Footer,
    // DateFilter,
    // CalendarOutlined
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Help chat',
        },
      ],
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      startValue: null,
      endValue: null,
      endOpen: false,
      overlaytable: false,
      nomore: false,
      page: 1,
      search: '',
      showDisplayFieldsApproveDetail: false,
      userId: this.$route.params.id,
      typesearch: 'coupon',
      options_search: [
        {
          label: 'Campaign',
          value: 'campaign',
        },
        {
          label: 'Coupon',
          value: 'coupon',
        },
      ],
      typesearch2: 'serial',
      options_search2: [
        {
          label: 'Serial',
          value: 'serial',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
        {
          label: 'Agency ID',
          value: 'agency_id',
        },
        {
          label: 'Terminal ID',
          value: 'terminal_id',
        },
        {
          label: 'Brand ID',
          value: 'brand_id',
        },
      ],
      status: [
        {
          id: 1,
          name: true,
          text: 'Use',
          color: 'green',
        },
        {
          id: 2,
          name: false,
          text: 'Not use',
          color: 'red',
        },
      ],
      dataCoupon: [],
      columns: [
        {
          title: 'Coupon Serial',
          dataIndex: 'coupon_serial',
          slots: { customRender: 'coupon_serial' },
          key: 'coupon_serial',
          width: 160,
          align: 'left',
        },
        {
          title: 'User',
          dataIndex: 'user',
          slots: { customRender: 'user' },
          key: 'user',
          width: 160,
          align: 'left',
        },
        {
          title: 'Import Date',
          dataIndex: 'import_date',
          slots: { customRender: 'import_date' },
          key: 'import_date',
          width: 160,
          align: 'left',
        },
        {
          title: 'Used Status',
          dataIndex: 'used_status',
          slots: { customRender: 'used_status' },
          key: 'used_status',
          width: 160,
          align: 'left',
        },
        {
          title: 'Used Date',
          dataIndex: 'used_date',
          slots: { customRender: 'used_date' },
          key: 'used_date',
          width: 160,
          align: 'left',
        },
        {
          title: 'Points',
          dataIndex: 'points',
          slots: { customRender: 'points' },
          key: 'points',
          width: 140,
          align: 'right',
        },
        {
          title: 'Points Earn',
          dataIndex: 'points_earn',
          slots: { customRender: 'points_earn' },
          key: 'points_earn',
          width: 140,
          align: 'right',
        },
      ],
      formatdate: 'DD/MMM/YYYY',
    }
  },
  computed: {},
  mounted() {
    this.$store.commit('setLoading', false)
  },
  watch: {},
  methods: {
    changeType() {
      this.$emit('changeType', this.typesearch)
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getHelpchatList().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    SearchSerial() {
      this.$store.commit('setLoading', true)
      var dataSearch = {
        appid: ConfigManager.getAppId(),
        serial: this.search ? this.search : '',
      }
      this.PostSearchSerial(dataSearch).then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    PostSearchSerial(dataSearch) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostSearchSerialCoupon(dataSearch)
          .then(res => {
            this.dataCoupon = res.data.result
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.searchserial {
  .wrap-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 270px);
    .ant-table-wrapper {
      min-width: 1210px !important;
      width: 100%;
    }
  }
}
</style>
