<template>
  <div class="">
    <div class="">
      <a-row>
        <a-col :span="6" v-if="!hidetag">
          <div class="flex-none">
            <div
              class="criterial-and-or flex rounded border border-gray-300"
              style="width: 95%"
            >
              <div
                class="rounded-l flex-1 px-2 py-1 text-sm text-center"
                :class="[
                  item.criteria.toLowerCase() == 'and'
                    ? 'text-white bg-primary2'
                    : 'text-gray-700',
                ]"
              >
                AND
              </div>
              <!-- <div
                v-if="item.type != 'checkbox'"
                class="flex-1 rounded-r  px-2 py-1 cursor-pointer"
                :class="[
                  item.criteria.toLowerCase() != 'and'
                    ? 'text-white bg-primary2'
                    : 'text-gray-700',
                ]"
                @click="toggleState(item, 'or')"
              >
                OR
              </div> -->
            </div>
          </div>
        </a-col>
        <a-col :span="hidetag ? 22 : 16" class="pt-1">
          <div
            v-if="
              item.type != 'date' &&
              item.type != 'int' &&
              item.type != 'checkbox'
            "
            :class="[!hidetag ? 'pl-2' : '']"
            class="flex-auto"
          >
            {{ item.tag_group_name }} : {{ item.name }}
          </div>
          <div
            v-else-if="item.type == 'date' || item.type == 'int'"
            :class="[!hidetag ? 'pl-2' : '']"
            class="flex-auto"
          >
            {{ item.tag_group_name }} : {{ item.calculator }}
          </div>
          <div v-else-if="item.type == 'checkbox'" class="flex-auto pl-2">
            {{ item.tag_group_name }} : {{ item.name }}
          </div>
        </a-col>
        <a-col :span="2">
          <div class="flex-none">
            <a class="btn-remove" @click="removeCriteria(item)">
              <img
                class="float-right"
                src="@/assets/icons/button/btn-delete.svg"
              />
            </a>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CriteriaItem',
  props: {
    render: Number,
    hidetag: Boolean,
    item: {
      type: Object,
    },
    update: Number,
    index: Number,
  },
  data: function () {
    return {
      isAnd: true,
    }
  },
  mounted: function () {
    if (this.item.criteria.toLowerCase() == 'and') {
      this.isAnd = true
    } else {
      this.isAnd = false
    }
  },
  methods: {
    toggleState(item, state) {
      if (item.type == 'checkbox') return
      if (state == 'and') {
        this.isAnd = true
      } else {
        this.isAnd = false
      }
      this.$emit('change', true)
    },
    removeCriteria(item) {
      this.$emit('removeCriteria', item)
    },
  },
}
</script>
<style lang="scss" scoped>
.v-list-item {
  .wrapper_min_max {
    text-indent: 20px;
  }
  .btn-remove {
    display: block;
    padding: 5px;
  }
  .criterial-and-or {
    width: 82px;
  }
}
</style>
