<template>
  <div class="mb-4 b-6 bg-white pl-4 d-flex">
    <div class="py-2 d-flex align-items-center">
      <a-select
        v-if="
          datapermission.includes('Search-Serial-Campaign-View') &&
          datapermission.includes('Search-Serial-Coupon-View')
        "
        class="type-search"
        v-model:value="typesearch"
        :options="options_search"
        @change="changeType()"
      />
      <!-- <a-select
        v-if="typesearch != 'coupon'"
        class="type-search ml-10"
        v-model:value="typesearch2"
        :options="options_search2"
      /> -->

      <!-- <a-input
        v-model:value="searchserial"
        placeholder="Search by Serial"
        class="input-search ml-10"
      /> -->
      <div class="ml-3 min-max-con">Campaign ID :</div>
      <a-input
        v-model:value="searchcampaignid"
        class="advanced-search ml-10"
        placeholder="Search by ID"
        @pressEnter="SearchSerial()"
        @input="handleInput($event)"
      >
        <template #prefix>
          <a @click="SearchSerial()">
            <search-outlined />
          </a>
        </template>
      </a-input>
      <div class="ml-3 min-max-con">Campaign Serial :</div>
      <a-input
        v-model:value="searchserial"
        class="advanced-search ml-10"
        placeholder="Search by Serial"
        @pressEnter="SearchSerial()"
      >
        <template #prefix>
          <a @click="SearchSerial()">
            <search-outlined />
          </a>
        </template>
      </a-input>
      <!-- <a-input
        v-model:value="searchcampaignid"
        placeholder="Search by ID"
        class="input-search ml-10"
        @input="handleInput($event)"
      />
      <a-button
        type="primary"
        :disabled="!searchserial && !searchcampaignid"
        @click="SearchSerial()"
        class="btn-search ml-3"
        ><search-outlined /> Search</a-button
      > -->
    </div>
  </div>
  <div class="table-scroll" @scroll="handleScroll">
    <div class="wrap-table-level">
      <VLoadingTable :value="overlaytable" />
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="datacampaignlist"
        :columns="columns"
        :pagination="false"
      >
        <template #serial="{ record }">
          <div>{{ record.serial ? record.serial : '-' }}</div>
        </template>
        <template #campaign="{ record }">
          <div>
            {{ record.campaign_id ? record.campaign_id : '-' }}
          </div>
          <div>{{ record.campaign_name ? record.campaign_name : '-' }}</div>
        </template>
        <template #agency="{ record }">
          <div v-if="!record.agency_id">-</div>
          <div v-else>
            <div>{{ record.agency_id ? record.agency_id : '-' }}</div>
            <div>{{ record.agency_name ? record.agency_name : '-' }}</div>
          </div>
        </template>
        <template #user="{ record }">
          <div class="text-link">
            <a @click="goToUserDetail(record)"
            >
              {{ record.user_id }}
            </a>
          </div>
          <div>{{ record.first_name }} {{ record.last_name }}</div>
        </template>
        <template #redeem_date="{ record }">
          <div>
            {{ formatDate(record.redeem_date, 'DD/MMM/YYYY - HH:mm') }}
          </div>
        </template>
        <template #used_date="{ record }">
          <div>
            {{ formatDate(record.used_date, 'DD/MMM/YYYY - HH:mm') }}
          </div>
        </template>
        <template #terminal="{ record }">
          <div v-if="!record.merchant_info">-</div>
          <div v-else>
            <div>
              {{
                record.merchant_info.terminal_id
                  ? record.merchant_info.terminal_id
                  : '-'
              }}
            </div>
            <div>
              {{
                record.merchant_info.terminal_name
                  ? record.merchant_info.terminal_name
                  : '-'
              }}
            </div>
          </div>
        </template>
        <template #branch="{ record }">
          <div v-if="!record.merchant_info">-</div>
          <div v-else>
            <div>
              {{
                record.merchant_info.branch_id
                  ? record.merchant_info.branch_id
                  : '-'
              }}
            </div>
            <div>
              {{
                record.merchant_info.branch_name
                  ? record.merchant_info.branch_name
                  : '-'
              }}
            </div>
          </div>
        </template>
        <template #brand="{ record }">
          <div v-if="!record.merchant_info">-</div>
          <div v-else>
            <div>
              {{
                record.merchant_info.brand_id
                  ? record.merchant_info.brand_id
                  : '-'
              }}
            </div>
            <div>
              {{
                record.merchant_info.brand_name
                  ? record.merchant_info.brand_name
                  : '-'
              }}
            </div>
          </div>
        </template>
        <template #merchant="{ record }">
          <div v-if="!record.merchant_info">-</div>
          <div v-else>
            <div>
              {{
                record.merchant_info.merchant
                  ? record.merchant_info.merchant
                  : '-'
              }}
            </div>
            <div>
              {{
                record.merchant_info.merchant_name
                  ? record.merchant_info.merchant_name
                  : '-'
              }}
            </div>
          </div>
        </template>
        <template #used_status="{ record }">
          <template v-for="(item, index) in status">
            <a-tag
              v-if="record.is_used == item.name"
              :key="index"
              :color="item.color == 'default' ? null : item.color"
              class="tag-status"
            >
              <a-badge :status="item.color" :text="item.text" />
            </a-tag>
          </template>
          <a
            v-if="datapermission.includes('Search-Serial-Campaign-Edit')"
            class="text-link"
            @click="postChangeUserStatus(record)"
            ><edit-outlined
          /></a>
        </template>
      </a-table>
    </div>
  </div>
  <a-modal
    v-model:visible="showModalMarkUse"
    width="480px"
    wrap-class-name="modal-markuse"
    :closable="false"
    :centered="true"
    @ok="MarkUsed()"
    :ok-button-props="{ disabled: !remark }"
    @cancel="closeModal"
  >
    <div class="d-flex">
      <question-circle-outlined class="ico" />
      <div class="ml-5">
        <h3 class="mt-2">Confirm to change use status ?</h3>
      </div>
    </div>
    <div v-if="!datamarkuse.is_used" class="mt-1">
      You are about to change status to used.
    </div>
    <div v-else class="mt-1">You are about to change status to not used.</div>
    <div class="d-flex mt-4">
      <div><label class="false pr-2">*</label>Remark :</div>
      <!-- <div v-if="!datamarkuse.is_used" class="text-gray ml-1">(Optional) :</div> -->
    </div>
    <a-textarea
      v-model:value="remark"
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
  </a-modal>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import ConfigManager from '@/config/ConfigManager.js'
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import moment from 'moment'
// import dayjs from 'dayjs'
// import DateFilter from '@/module/User/component/DateFilter.vue'

export default {
  name: 'UserLevel',
  components: {
    // Footer,
    // DateFilter,
    // CalendarOutlined
    EditOutlined,
    QuestionCircleOutlined,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Help chat',
        },
      ],
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      startValue: null,
      endValue: null,
      endOpen: false,
      overlaytable: false,
      isloadmore: false,
      page: 1,
      searchcampaignid: '',
      searchserial: '',
      search: '',
      showDisplayFieldsApproveDetail: false,
      userId: this.$route.params.id,
      typesearch: 'campaign',
      options_search: [
        {
          label: 'Campaign',
          value: 'campaign',
        },
        {
          label: 'Coupon',
          value: 'coupon',
        },
      ],
      typesearch2: 'serial',
      options_search2: [
        {
          label: 'Serial',
          value: 'serial',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
        // {
        //   label: 'Agency ID ',
        //   value: 'agency_id',
        // },
        // {
        //   label: 'Terminal ID',
        //   value: 'terminal_id',
        // },
        // {
        //   label: 'Brand ID',
        //   value: 'brand_id',
        // },
      ],
      status: [
        {
          id: 1,
          name: true,
          text: 'Use',
          color: 'green',
        },
        {
          id: 2,
          name: false,
          text: 'Not use',
          color: 'red',
        },
      ],
      datajoblist: [
        {
          serial_number: '23012600001',
          item_number: '2',
          redeemed_date: '11/ Oct / 2021 - 15:30',
          used_status: false,
          used_date: '11/ Oct / 2021 - 15:30',
        },
      ],
      datacampaignlist: [],
      columns: [
        {
          title: 'Serial Number',
          dataIndex: 'serial',
          slots: { customRender: 'serial' },
          key: 'serial',
          width: 160,
          align: 'left',
        },
        {
          title: 'Campaign',
          dataIndex: 'campaign',
          slots: { customRender: 'campaign' },
          key: 'campaign',
          width: 260,
          align: 'left',
        },
        {
          title: 'Item Number',
          dataIndex: 'item_number',
          slots: { customRender: 'item_number' },
          key: 'item_number',
          width: 140,
          align: 'right',
        },
        {
          title: 'Agency',
          dataIndex: 'agency',
          slots: { customRender: 'agency' },
          key: 'agency',
          width: 180,
          align: 'left',
        },
        {
          title: 'User',
          dataIndex: 'user',
          slots: { customRender: 'user' },
          key: 'user',
          width: 260,
          align: 'left',
        },
        {
          title: 'Redeemed Date',
          dataIndex: 'redeem_date',
          slots: { customRender: 'redeem_date' },
          key: 'redeem_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Used Status',
          dataIndex: 'used_status',
          slots: { customRender: 'used_status' },
          key: 'used_status',
          width: 160,
          align: 'left',
        },
        {
          title: 'Used Date',
          dataIndex: 'used_date',
          slots: { customRender: 'used_date' },
          key: 'used_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Terminal',
          dataIndex: 'terminal',
          slots: { customRender: 'terminal' },
          key: 'terminal',
          width: 140,
          align: 'left',
        },
        {
          title: 'Branch',
          dataIndex: 'branch',
          slots: { customRender: 'branch' },
          key: 'branch',
          width: 140,
          align: 'left',
        },
        {
          title: 'Brand',
          dataIndex: 'brand',
          slots: { customRender: 'brand' },
          key: 'brand',
          width: 140,
          align: 'left',
        },
        {
          title: 'Merchant',
          dataIndex: 'merchant',
          slots: { customRender: 'merchant' },
          key: 'merchant',
          width: 140,
          align: 'left',
        },
      ],
      searchObject: {
        page_number: null,
        limit: null,
        appid: '',
        campaign_id: null,
        serial: '',
      },
      formatdate: 'DD/MMM/YYYY',
      remark: '',
      showModalMarkUse: false,
      datamarkuse: {},
    }
  },
  computed: {},
  mounted() {
    this.$store.commit('setLoading', false)
  },
  watch: {},
  methods: {
    goToUserDetail(record) {
      localStorage.setItem('userinfo', JSON.stringify(record))
      this.$router.push({
        name: 'user_detail',
        params: {
          id: record.user_id,
        },
      })
    },
    handleInput(event) {
      this.searchcampaignid = event.target.value.replace(/[^0-9]/g, '')
    },
    changeType() {
      this.$emit('changeType', this.typesearch)
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable && this.isloadmore) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.PostSearchSerial().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    SearchSerial() {
      this.$store.commit('setLoading', true)
      this.PostSearchSerial().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    PostSearchSerial() {
      var searchserial = {
        page_number: this.page,
        limit: 20,
        appid: ConfigManager.getAppId(),
        campaign_id: this.searchcampaignid
          ? parseInt(this.searchcampaignid)
          : null,
        serial: this.searchserial ? this.searchserial : null,
      }
      this.isloadmore = true
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostSearchSerialCampaign(searchserial)
          .then(res => {
            if (this.page == 1) {
              this.datacampaignlist = []
            }
            _.forEach(res.data.result, item => {
              this.datacampaignlist.push(item)
            })
            if (res.data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    postChangeUserStatus(record) {
      this.showModalMarkUse = true
      this.datamarkuse = record
      console.log(this.datamarkuse)
    },
    closeModal() {
      this.remark = ''
      this.showModalMarkUse = false
    },
    MarkUsed() {
      this.$store.commit('setLoading', true)
      var dataSearch = {
        appid: ConfigManager.getAppId(),
        campaign_id: this.datamarkuse.campaign_id,
        item_number: this.datamarkuse.item_number,
        serial: this.datamarkuse.serial,
        user_id: this.datamarkuse.user_id,
        remark: this.remark,
        statusmark: this.datamarkuse.is_used ? false : true,
      }
      this.PostSearchSerialMarkUsed(dataSearch).then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    PostSearchSerialMarkUsed(dataSearch) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostSearchSerialMarkUsed(dataSearch)
          .then(res => {
            this.showModalMarkUse = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                this.SearchSerial()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/style/base/base.scss';

.searchserial {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: auto;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    min-width: 2200px;
    max-height: calc(100vh - 270px);
  }

  .type-search {
    width: 150px;
  }

  .ml-10 {
    margin-left: 10px;
  }
}
.min-max-con {
  min-width: max-content;
}
.modal-markuse {
  .ant-modal-body {
    background: transparent !important;
  }

  .ico {
    font-size: calc(35 / 14 * 1rem);
    color: #faad14;
  }
}
</style>
