// import Account from "@/helper/AccountHelper.js";
import ConfigManager from "@/config/ConfigManager.js";
import CacheHelper from '@/helper/CacheHelper.js'
import BzbsApi from "@/core/Http/BzbsApi.js";
import { map } from "rxjs/operators";
import { AccountModel } from "../model/AccountModel.js";
var Cache = new CacheHelper(ConfigManager.getCacheType())

/**
 * @method apiRequestOTP
 * @method apiLogin
 * @method apiLogout
 * @method apiLoginLine
 * @method apiLoginDevice
 * @method apiReLoginDevice
 */
export default {
  /**
   * @param {AuthLoginModel} params /core/Account/config/AuthConfigModel.js
   */
  mstoken: function () {
    return Cache.getCache('msToken')
  },
  apiLogin: function (params) {
    const url = ConfigManager.getUsermgrUrl() + '/auth/bzbs_login'
    return BzbsApi.connectBzbsPostDataSci(url, null, params).pipe(
      map(res => {
        let accountModel = new AccountModel()
        accountModel.setDataFromApi(res.data)
        return accountModel
      }),
    )
  },

  /**
   * @param {String} token
   * @param {String} strFBToken
   * @param {String} strUUID
   */
  apiLogout: function (token, FacebookToken, uuid) {
    const params = {
      uuid: uuid,
      access_token: FacebookToken,
    }

    const url = ConfigManager.getUsermgrUrl() + '/auth/logout'
    return BzbsApi.connectBzbsPostDataSci(url, token, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  /**
   * @param {string} lineIdToken
   */
  apiLoginLine: function (lineIdToken) {
    const params = {
      app_id: ConfigManager.getAppId(),
      id_token: lineIdToken,
    }
    const url = ConfigManager.getUsermgrUrl() + '/auth/line_login'
    return BzbsApi.connectBzbsPostDataSci(url, null, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  /**
   * @param {AuthDeviceLoginModel} params /core/Account/model/AuthConfigModel.js
   */
  apiLoginDevice: function (params) {
    params.app_id = ConfigManager.getAppId()

    const url = ConfigManager.getUsermgrUrl() + '/auth/device_login'
    return BzbsApi.connectBzbsPostDataSci(url, null, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },

  /**
   * Resume Login
   * @param {string } token
   * @param {Object} data  // cache user
   */
  apiReLoginDevice: function (token, data) {
    const params = {
      uuid: data.uuid,
      app_id: ConfigManager.getAppId(),
      os: 'web',
      platform: 'web',
      mac_address: data.uuid,
      // device_noti_enable: data.isDeviceNotiEnable,
      // client_version: data.strClientVersion,
    }

    const url = ConfigManager.getUsermgrUrl() + '/auth/device_resume'
    return BzbsApi.connectBzbsPostDataSci(url, token, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },
  apiGetMenu(params) {
    const token = this.mstoken()
    var url = ConfigManager.getApiadB2CUrl() + '/api/v3.0/user/menus'
    return BzbsApi.connectBzbsGetToken(url, token, params)
  },
  apiGetPermission(params) {
    const token = this.mstoken()
    var url = ConfigManager.getApiadB2CUrl() + '/api/v3.0/user/permission'
    return BzbsApi.connectBzbsGetToken(url, token, params)
  },
  apiGetApplications(params) {
    const token = this.mstoken()
    var url = ConfigManager.getApiadB2CUrl() + '/api/v3.0/user/applications'
    return BzbsApi.connectBzbsGetToken(url, token, params)
  },
}
