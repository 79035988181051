<template>
  <a-card class="w-full min-h-full wallet">
    <nav class="filter-nav d-flex justify-content-between mb-3">
      <div class="d-flex gab-10">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            v-model:value="optionssearch"
            :options="options_search"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @keyup="enterFilter($event)"
            :allowClear="true"
          >
            <template #prefix>
              <a @click="applyFilter">
                <search-outlined />
              </a>
            </template>
            <template #suffix>
              <a @click="toggleAdvaceSearch">
                <img
                  class="ico-filter"
                  :class="{ active: checkActive }"
                  src="@/assets/icons/faders.svg"
                />
              </a>
              <FilterSearch
                ref="FilterSearch"
                v-model:value="showAdvaceSearch"
                :FilterConfig="optionlist"
                @applyFilter="applyFilter($event)"
                @handleClose="showAdvaceSearch = false"
              />
            </template>
          </a-input>
        </a-input-group>
        <!-- <div class="wrap-date-filter pr-3">
          <a-select
            class="select-search"
            v-model:value="statusdropdown"
            :options="status_dropdown"
          />
        </div>
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
          />
        </div>
        <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch"
            ><search-outlined /> Search</a-button
          >
        </div> -->
      </div>
      <div class="d-flex gab-10">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <!-- <a-button
          v-if="dataapp.includes('User-List-Wallet-Export')"
          type="teriary"
          @click="exportWalletReport"
        >
          <file-excel-outlined /> Export as excel
        </a-button> -->
      </div>
    </nav>
    <div class="wrap-table">
      <!-- <VLoadingTable :value="overlaytable" /> -->
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataList"
        :columns="displayFields"
        :pagination="false"
      >
        <template #status="{ record }">
          <template v-for="(itemstatus, index) in status">
            <a-tag
              v-if="record.status == itemstatus.name"
              :key="index"
              :color="itemstatus.color == 'default' ? null : itemstatus.color"
              class="tag-status"
            >
              <a-badge :status="itemstatus.color" :text="itemstatus.name" />
            </a-tag>
          </template>
        </template>
        <template #flag_id="{ record }">
          {{ record.flag_id || '-' }}
        </template>
        <template #flag_name="{ record }">
          {{ record.flag_name || '-' }}
        </template>
        <template #action="{ record }">
          <div
            v-if="record.status == 'Assigned'"
            @click="removeLevel(record)"
            class="fraud pointer"
          >
            Remove flag
          </div>
          <div v-else class="text-active pointer" @click="addLevel(record)">
            Add flag
          </div>
        </template>
      </a-table>
    </div>
  </a-card>
  <a-modal
    title="Add to level"
    class="modal-set-expire"
    :visible="modalAdd"
    @cancel="handleCancel"
    okText="Save"
    :width="450"
    :centered="true"
    @ok="AddUserFlag()"
    :closable="false"
  >
    <div class="mb-3">Select Level : {{ dataDetail.flag_name }}</div>
    <div class="mb-3">Set Expired :</div>
    <a-radio-group v-model:value="setexpiry" @change="change">
      <a-radio class="radio-expiry" :value="1"
        >Set to
        <a-date-picker
          style="width: 260px"
          v-model:value="expirydate"
          placeholder="Select Expiry"
          :showToday="false"
          :disabled-date="disabledDate"
          :disabled="setexpiry != 1"
      /></a-radio>
      <a-radio class="radio-expiry mt-2" :value="2">No expiration</a-radio>
    </a-radio-group>
  </a-modal>
  <DisplayFields
    ref="selectall"
    :value="showDisplayFields"
    :headers="columns"
    @apply="applyDisplayFields($event)"
    @handleClose="showDisplayFields = false"
  >
  </DisplayFields>
  <ExportModal
    :visible="showExportWalletModal"
    @handleSubmit="callAPiExportWallet($event)"
    @handleClose="showExportWalletModal = false"
  ></ExportModal>
  <ExportModal
    :visible="showExportWalletDetailModal"
    @handleSubmit="callAPiExportWalletDetail($event)"
    @handleClose="showExportWalletDetailModal = false"
  ></ExportModal>
  <PrepareModal
    :visible="showPrepareModal"
    @handleClose="showPrepareModal = false"
  ></PrepareModal>
</template>
<script>
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
// import { map } from "rxjs/operators";
import Util from '@/helper/Utility.js'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import FilterSearch from '@/components/Filter/FilterSearch.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import ConfigManager from '@/config/ConfigManager.js'
import { config } from '@/config/bzbsconfig.js'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'

export default {
  name: 'UserWallet',
  components: {
    DisplayFields,
    FilterSearch,
    ExportModal,
    PrepareModal,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      isTimezone: config.businessRule.timeZone.hours,
      visible2: false,
      drag: false,
      showDisplayFields: false,
      overlay: true,
      overlaytable: false,
      overlaytablemodal: false,
      nomoremodal: false,
      isLoadmore: false,
      modalPage: 1,
      has_conversion: false,
      setperrmissionexport: true,
      datawallets: [],
      card_id: '',
      statustype: [
        {
          id: 1,
          name: 'Stamp',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Wallet',
          color: 'yellow',
        },
      ],
      status: [
        {
          id: 0,
          name: 'Available',
          color: 'green',
        },
        {
          id: 1,
          name: 'Assigned',
          color: 'blue',
        },
      ],
      statusdropdown: '',
      status_dropdown: [
        {
          label: 'All Card Type',
          value: '',
        },
        {
          label: 'Stamp',
          value: 'stamp',
        },
        {
          label: 'Wallet',
          value: 'wallet',
        },
      ],
      optionlist: {
        status: true,
        daterange: false,
        options: [
          {
            label: 'All Status',
            value: '',
          },
          {
            label: 'Assigned',
            value: 'Assigned',
          },
          {
            label: 'Available',
            value: 'Available',
          },
        ],
      },
      searchObject: {
        cardid: '',
        status: '',
        paymentid: '',
        startdate: '',
        enddate: '',
      },
      optionssearch: 'name',
      options_search: [
        {
          label: 'Flag Name',
          value: 'name',
        },
      ],
      search: '',
      page: 1,
      columns: [
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 100,
          align: 'left',
        },
        {
          title: 'Flag ID',
          dataIndex: 'flag_id',
          slots: { customRender: 'flag_id' },
          key: 'flag_id',
          width: 140,
          align: 'left',
        },
        {
          title: 'Flag Name',
          dataIndex: 'flag_name',
          slots: { customRender: 'flag_name' },
          key: 'flag_name',
          width: 140,
          align: 'left',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          slots: { customRender: 'action' },
          key: 'action',
          width: 140,
          align: 'left',
        },
      ],
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Expiry Date',
      startdate: null,
      enddate: null,
      formatdate: 'DD/MMM/YYYY',
      showPrepareModal: false,
      modaltext: '',
      modalCardId: '',
      showAdvaceSearch: false,
      checkActive: false,
      dataSubscription: [],
      modalDetail: false,
      dataDetailRecord: [],
      selected: [],
      dataDetailItem: [],
      isCheckActive: '',
      pageItem: 1,
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      skeletonLoading: null,
      check: [],
      dataList: [],
      modalAdd: false,
      dataDetail: [],
      setexpiry: null,
      expirydate: null,
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'level-flag') {
        this.$store.commit('setLoading', true)
        var datapermission = JSON.parse(localStorage.getItem('permissionbyapp'))
        if (!datapermission.includes('User-List-Flag-Edit')) {
          this.columns = this.columns.filter(x => x.key != 'action')
        }
        this.displayFields = _.cloneDeep(this.columns)
        this.showAdvaceSearch = false
        // this.$refs.FilterSearch.resetFilter()
        this.checkActive = false
        this.getUserFlagList().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'level-flag') {
      var datapermission = JSON.parse(localStorage.getItem('permissionbyapp'))
      if (!datapermission.includes('User-List-Flag-Edit')) {
        this.columns = this.columns.filter(x => x.key != 'action')
      }
      this.displayFields = _.cloneDeep(this.columns)
      this.showAdvaceSearch = false
      // this.$refs.FilterSearch.resetFilter()
      this.checkActive = false
      this.getUserFlagList()
      this.overlay = false
    }
  },
  created() {},
  methods: {
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    enterFilter(key) {
      if (key.key == 'Enter' && this.showAdvaceSearch == false) {
        this.applyFilter()
      }
    },
    toggleAdvaceSearch() {
      this.showAdvaceSearch = !this.showAdvaceSearch
    },
    applyFilter(filter) {
      if (
        filter?.status != null ||
        (filter?.startdate?.length > 0 && filter?.enddate?.length > 0)
      ) {
        this.checkActive = true
      } else {
        this.checkActive = false
      }
      this.showAdvaceSearch = false
      this.$store.commit('setLoading', true)
      this.page = 1
      this.searchObject.name = this.optionssearch == 'name' ? this.search : ''
      this.searchObject.status = filter?.status ? filter?.status : ''
      this.showDateFilter = false
      this.getUserFlagList().then(() => {
        if (this.searchObject.name) {
          this.onSearch(this.searchObject.name)
        }
        this.$store.commit('setLoading', false)
      })
    },
    getUserFlagList() {
      var user_id = this.$route.params.id
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserFlagList(
          user_id,
          this.searchObject.status,
        ).subscribe(
          data => {
            this.dataList = data.result
            this.$store.commit('setLoading', false)
            resolve(this.dataList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    onSearch(value) {
      if (value != null) {
        this.dataList = _.filter(this.dataList, data => {
          if (
            data.flag_name
              .toLowerCase()
              .includes(value ? value.toLowerCase() : value)
          ) {
            return data
          }
        })
      } else {
        return this.dataList
      }
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getUserFlagList().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    exportWalletReport() {
      this.showExportWalletModal = true
    },
    exportWalletDetailReport() {
      this.showExportWalletDetailModal = true
    },
    addLevel(record) {
      var data = {
        app_id: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        flag_id: record.flag_id,
      }
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Confirm To Add Flag?',
        content: 'Add user to ' + record.flag_name + '.',
        okText: 'Yes, Add',
        cancelText: 'No',
        width: '480px',
        icon: <QuestionCircleOutlined />,
        okButtonProps: {
          type: 'primary',
        },
        onOk: () => {
          this.AddUserFlag(data)
        },
      })
    },
    AddUserFlag(data) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostAddUserFlag(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getUserFlagList()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleCancel() {
      this.expirydate = null
      this.modalAdd = false
    },
    removeLevel(record) {
      var data = {
        app_id: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        flag_id: record.flag_id,
      }
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Remove Flag Form User?',
        content:
          'User is already on this flag, by removing user cannot enjoy benefit at all.',
        okText: 'Yes, Remove',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.DeleteLevel(data)
        },
      })
    },
    DeleteLevel(data) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiDeleteLevelFlag(data).subscribe(
          res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getUserFlagList()
              },
            })
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }

  .nav-search .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .line {
    width: 2px;
    height: 64px;
    float: right;
    background-color: $color-grey-line;
    position: absolute;
    top: 14%;
    left: calc(100% - 158px);
  }

  .nav-search {
    .group-btn-left {
      float: right;
      display: flex;
      padding-right: 5px;
    }

    .group-btn {
      float: right;
      margin-left: 16px;
    }
  }

  .wrap-table {
    .ant-table-wrapper {
      min-width: inherit;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .card-type {
    text-align: center;
    width: 80px;
    color: #1d5ffb;
    border: 1px solid#1d5ffb;
    border-radius: 20px;
  }
}

.select-search {
  width: 130px !important;
}
</style>
<style lang="scss">
.modal-subscription {
  .main-content {
    display: flex;
    width: 100%;
    gap: 16px;
    .list {
      width: 68%;
    }
    .total {
      width: 32%;
    }
  }
  .border-total {
    margin-top: 10px;
    border: 1px solid #c9c9c9;
    background-color: #ffffff;
    padding: 5px;
    border-radius: 4px;
  }
  .ant-card.isactive-list {
    background-color: #fff6e6;
    border-color: #feca62;
  }
  .ant-card {
    margin-bottom: 10px;
  }
}
.wrapper-content-item {
  display: block;
  width: 100%;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  overflow-x: auto;
}
.fw-400 {
  font-weight: 400;
}
.bg-index {
  width: 24px;
  text-align: center;
  color: #ffffff;
  background-color: #494949;
  border-radius: 4px;
}
</style>
