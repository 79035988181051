<template>
  <div>
    <div v-if="value" class="backdrop fixed top-0 left-0 w-full h-full" @click="dismiss()"></div>
    <div class="fixed top-0 right-0 h-screen z-100" :style="containerStyle">
      <transition enter-active-class="transition ease-out duration-500" enter-from-class="transform-gpu translate-x-full"
        enter-to-class="transform-gpu  translate-x-0" leave-active-class="transition ease-in duration-300"
        leave-from-class="transform-gpu translate-x-0" leave-to-class="transform-gpu translate-x-full">
        <div class="h-full shadow-xl w-load-savelist bg-white border-l" :style="mainBlockStyle" v-if="value">
          <div v-if="modal_savelist" class="flex divide-y-2 flex-col h-full">
            <div class="panel-header flex-none">
              <div class="flex-grow font-semibold">Load Saved List</div>
              <div class="flex-none btn-dismiss" @click="dismiss(true)">
                <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
              </div>
            </div>
            <div class="border-b flex-auto overflow-y-auto">
              <div class="">
                <div class="v-list has-divider">
                  <div v-for="(item, idx) of  items " :key="`v-list-item-${idx}`" class="v-list-item">
                    <div class="flex items-center w-full">
                      <div class="flex-auto pl-4">
                        <div v-if="item.title.length > 28" :class="item.description
                          ? 'title-load title-list'
                          : 'title-load title-list pd-20'
                          ">
                          <a-tooltip placement="bottomLeft">
                            <template #title>
                              <span>{{ item.title }}</span>
                            </template>
                            {{ item.title }}
                          </a-tooltip>
                        </div>
                        <div v-else :class="item.description ? 'title-list' : 'title-list pd-20'
                          ">
                          {{ item.title }}
                        </div>
                        <div v-if="item.description.length > 28" class="title-load des-list">
                          <a-tooltip placement="bottomLeft">
                            <template #title>
                              <span>{{ item.description }}</span>
                            </template>
                            {{ item.description }}
                          </a-tooltip>
                        </div>
                        <div v-else class="des-list">
                          {{ item.description }}
                        </div>
                        <div class="text-xs text-gray mt-3">
                          Created date:
                          {{
                            formatdate(item.savelist_versions[0].create_date)
                          }}
                        </div>
                      </div>
                      <div class="flex-none pr-4">
                        <!-- <a-button
                        type="default"
                        v-if="datapermission.includes('User-List-Version-Save-List')"
                        class="ml-3 hover btn-icon"
                        @click="showHistorysSavelist(idx)"
                        >
                        <img class="left" src="@/assets/icons/button/ico-savelisthistory.svg" alt />
                        </a-button> -->
                        <div class="d-flex">
                          <a-tooltip placement="bottom">
                            <template #title>
                              <div>Refresh save list</div>
                            </template>
                            <a-button type="default" v-if="datapermission.includes(
                                  'User-List-Save-List-Refresh',
                                )
                                " class="ml-3 hover btn-icon" @click="refresh(item)">
                              <img class="left" src="@/assets/icons/button/Redo.svg" alt />
                            </a-button>
                          </a-tooltip>
                          <a-tooltip placement="bottom">
                            <template #title>
                              <div>Load save list</div>
                            </template>
                            <a-button type="primary" class="ml-3 btn-icon" v-if="datapermission.includes(
                              'User-List-Save-List-Load')
                              " :disabled="item.savelist_versions[0].status != 'Success'
    " @click="load(item)">
                              <img class="left" src="@/assets/icons/button/Schedule-w.svg" alt />
                            </a-button>
                          </a-tooltip>
                        </div>
                        <a class="d-block text-right mt-3" @click="showHistorysSavelist(idx)">View version</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="modal_historysavelist" class="flex divide-y-2 flex-col h-full history-list">
            <div class="panel-header flex-none">
              <div class="flex-grow font-semibold">
                <div class="d-flex">
                  <a class="pr-3" @click="backTosavelist()"><left-outlined class="arrow-gray" /></a>
                  <div v-if="items[idx].title.length > 28" class="title-load-version">
                    <a-tooltip placement="bottomLeft">
                      <template #title>
                        <span>{{ items[idx].title }}</span>
                      </template>
                      {{ items[idx].title }}
                    </a-tooltip>
                  </div>
                  <div v-else>
                    {{ items[idx].title }}
                  </div>
                </div>
              </div>
              <div class="flex-none btn-dismiss" @click="dismiss(true)">
                <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
              </div>
            </div>
            <div class="border-b flex-auto overflow-y-auto">
              <div class="p-4">
                <a-timeline>
                  <a-timeline-item v-for="( item, index ) of  items[idx].savelist_versions " :key="`index-${index}`">
                    <div class="row">
                      <div class="col-8">
                        <div>Version {{ item.version_no }}</div>
                        <div class="text-xs text-gray pt-1">
                          Created date:
                          {{ formatdate(item.create_date) }}
                        </div>
                      </div>
                      <div class="col-4 text-right">
                        <a-popover trigger="click" placement="bottomRight">
                          <template #content>
                            <div class="action-history">
                              <div v-if="item.status == 'Success' &&
                                datapermission.includes(
                                  'User-List-Save-List-Load',
                                )
                                " class="border-b history-action">
                                <a @click="
                                  clickloadversion(
                                    item,
                                    items[idx].id,
                                    items[idx],
                                    items[idx].title,
                                  )
                                  ">Load this version</a>
                              </div>
                              <div v-if="datapermission.includes(
                                'User-List-Save-List-Delete',
                              )
                                " class="history-action">
                                <a @click="
                                  clickdeleteversion(
                                    item,
                                    items[idx].id,
                                    items[idx].savelist_versions,
                                  )
                                  ">Delete this version</a>
                              </div>
                            </div>
                          </template>
                          <a-button class="btn-icon">
                            <img class="left" src="@/assets/icons/button/DotsThree.svg" alt />
                          </a-button>
                        </a-popover>
                      </div>
                    </div>
                  </a-timeline-item>
                </a-timeline>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { LeftOutlined } from '@ant-design/icons-vue'
export default {
  name: 'TableSavedList',
  inject: ['toolbarStates'],
  components: {
    LeftOutlined,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      modal_savelist: true,
      modal_historysavelist: false,
      idx: '',
      visible: false,
    }
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  watch: {
    value: function (val) {
      // console.log('save list', 'value', val)
      if (val) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
      this.modal_savelist = true
      this.modal_historysavelist = false
    },
  },
  emits: ['update:value'],
  methods: {
    backTosavelist() {
      this.modal_savelist = true
      this.modal_historysavelist = false
      this.visible = false
    },
    showHistorysSavelist(idx) {
      this.idx = idx
      this.modal_savelist = false
      this.modal_historysavelist = true
    },
    refresh(item) {
      this.$emit('clickrefresh', item)
    },
    load(tag) {
      this.$emit('clickload', tag)
      this.dismiss()
    },
    clickloadversion(item, id, itemlatest, title) {
      var data = { item: item, id: id, itemlatest: itemlatest, title: title }
      this.$emit('clickloadversion', data)
    },
    clickdeleteversion(item, id, itemlatest) {
      var data = { item: item, id: id, itemlatest: itemlatest }
      this.$emit('clickdeleteversion', data)
    },
    onClickAway: function () {
      console.log('asdsdadsadsasad')
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function () {
      this.toolbarStates.showPanelSavedList = false
      this.modal_savelist = true
      this.modal_historysavelist = false
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    formatdate(date) {
      return moment(date).format('DD MMM YY - HH:mm')
    },
  },
}
</script>
<style lang="scss" scoped>
.vbtn-load {
  color: #f79443;
  border: 1px solid #f79443;
}

.text-primary {
  color: #f9a601 !important;
}

.w-load-savelist {
  width: 30rem;

  .ant-popover-inner-content {
    width: 150px !important;
  }
}

.action-history {
  width: max-content;
}

.history-action {
  padding: 10px 10px 10px 10px;

  &:hover {
    background-color: #fff9f3;
  }
}

.title-load {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
}

.title-load-version {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
}

.pd-20 {
  padding-bottom: 20px;
}

.arrow-gray {
  color: #666666;
}

.title-list {
  font-size: 16px;
  font-weight: 500;
}

.des-list {
  color: #969696;
  font-size: 12px;
}
</style>
