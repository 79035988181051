<template>
  <AppLayout>
    <TitleBreadcrumbs class="mt-4 ml-5" title="OTP Log" />
    <div class="log-otp flex-1 w-full px-4">
      <!-- <div
        v-if="datasetFeatures.includes('Manage-Subject')"
        class="mb-3 text-right createsub"
      >
        <a-button type="primary" @click="createNewSubject()"
          ><plus-outlined />Create New Subject</a-button
        >
      </div> -->
      <div class="mb-4 b-6 bg-white pl-4 pr-4">
        <div class="py-2 d-flex justify-content-between">
          <div>
            <a-input-group class="search" compact>
              <a-select
                class="select-search"
                v-model:value="optionssearch"
                :options="options_search"
                @change="handleInput()"
              />
              <a-input
                v-model:value="contact_number"
                class="advanced-search"
                :placeholder="
                  optionssearch == 'contactnumber' ? 'Contact Number' : 'Email'
                "
                @pressEnter="clicksearchotp()"
              >
                <template #prefix>
                  <a @click="clicksearchotp()">
                    <search-outlined />
                  </a>
                </template>
              </a-input>
            </a-input-group>
          </div>
          <div>
            <a-button type="primary" @click="refreshSearch()"
              ><sync-outlined />Refresh</a-button
            >
          </div>
        </div>
      </div>
      <div class="table-scroll">
        <div class="wrap-table" @scroll="handleScroll">
          <VLoadingTable :value="overlaytable" />
          <a-table
            class="fixed-head"
            :columns="columns"
            :dataSource="dataOTP"
            :pagination="false"
          >
            <template #contact_number="{ record }">
              <div class="font-bold">
                {{ record.send_to ? contactNumber(record.send_to) : '-' }}
              </div>
            </template>
            <template #user_id="{ record }">
              {{ record.user_id ? record.user_id : '-' }}
            </template>
            <template #send_date="{ record }">
              <div v-if="!record.send_date">-</div>
              <div v-else>
                <div>
                  {{
                    record.send_date
                      ? formatDate(
                          record.send_date,
                          'DD/MMM/YYYY',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
                <div>
                  {{
                    record.send_date
                      ? formatDate(
                          record.send_date,
                          'HH:mm A',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
              </div>
            </template>
            <template #ref_code="{ record }">
              <div class="font-bold">
                {{ record.row_key ? record.row_key : '-' }}
              </div>
            </template>
            <template #otp="{ record }">
              <div class="font-bold">
                {{ record.password ? record.password : '-' }}
              </div>
            </template>
            <template #use_status="{ record }">
              {{ record.is_used ? 'Yes' : 'No' }}
            </template>
            <template #use_date="{ record }">
              <div v-if="!record.use_date">-</div>
              <div v-else>
                <div>
                  {{
                    record.use_date
                      ? formatDate(
                          record.use_date,
                          'DD/MMM/YYYY',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
                <div>
                  {{
                    record.use_date
                      ? formatDate(
                          record.use_date,
                          'HH:mm A',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
              </div>
            </template>
            <template #expire_date="{ record }">
              <div v-if="!record.expire_date">-</div>
              <div v-else>
                <div>
                  {{
                    record.expire_date
                      ? formatDate(
                          record.expire_date,
                          'DD/MMM/YYYY',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
                <div>
                  {{
                    record.expire_date
                      ? formatDate(
                          record.expire_date,
                          'HH:mm A',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
              </div>
            </template>
            <template #channel="{ record }">
              {{ record.channel ? record.channel : '-' }}
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <DisplayFieldsCreateSubject
      :value="showModalSubject"
      @handleClose="showModalSubject = false"
    >
    </DisplayFieldsCreateSubject>
    <DisplayFieldsEditSubject
      :value="showModalEditSubject"
      @handleClose="showModalEditSubject = false"
    >
    </DisplayFieldsEditSubject>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/default.vue'
import _ from 'lodash'
import DisplayFieldsCreateSubject from '@/module/CallCenter/component/DisplayFieldsCreateSubject.vue'
import DisplayFieldsEditSubject from '@/module/CallCenter/component/DisplayFieldsEditSubject.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import Util from '@/helper/Utility.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    DisplayFieldsCreateSubject,
    DisplayFieldsEditSubject,
    AppLayout,
    TitleBreadcrumbs,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      check: false,
      activeKey: null,
      columns: [
        {
          title: 'Send to',
          dataIndex: 'contact_number',
          slots: { customRender: 'contact_number' },
          key: 'contact_number',
          width: 120,
          align: 'left',
        },
        {
          title: 'User ID',
          dataIndex: 'user_id',
          slots: { customRender: 'user_id' },
          key: 'user_id',
          width: 120,
          align: 'left',
        },
        {
          title: 'Send date',
          dataIndex: 'send_date',
          slots: { customRender: 'send_date' },
          key: 'send_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Ref. code',
          dataIndex: 'ref_code',
          slots: { customRender: 'ref_code' },
          key: 'ref_code',
          width: 120,
          align: 'left',
        },
        {
          title: 'OTP',
          dataIndex: 'otp',
          slots: { customRender: 'otp' },
          key: 'otp',
          width: 120,
          align: 'left',
        },
        {
          title: 'Use status',
          dataIndex: 'use_status',
          slots: { customRender: 'use_status' },
          key: 'use_status',
          width: 120,
          align: 'left',
        },
        {
          title: 'Use date',
          dataIndex: 'use_date',
          slots: { customRender: 'use_date' },
          key: 'use_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Expired date',
          dataIndex: 'expire_date',
          slots: { customRender: 'expire_date' },
          key: 'expire_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Channel',
          dataIndex: 'channel',
          slots: { customRender: 'channel' },
          key: 'channel',
          width: 120,
          align: 'left',
        },
      ],
      showModalSubject: false,
      showModalEditSubject: false,
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
      active: true,
      statussearch: 'contactnumber',
      optionssearch: 'contactnumber',
      options_search: [
        {
          label: 'Contact Number',
          value: 'contactnumber',
        },
        {
          label: 'Email',
          value: 'email',
        },
      ],
      contact_number: '',
      page: 1,
      dataOTP: [],
      overlaytable: false,
      nomore: false,
    }
  },
  computed: {},
  mounted() {},
  created() {
    this.clicksearchotp()
  },
  methods: {
    handleInput() {
      this.contact_number = ''
    },
    contactNumber(number) {
      if (number.includes('@')) {
        return number
      } else {
        return Util.contactNumberFormat(number, '-')
      }
    },
    refreshSearch() {
      this.$store.commit('setLoading', true)
      this.contact_number = ''
      this.page = 1
      this.getOTP().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    clicksearchotp() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.getOTP().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getOTP() {
      var appid = Cache.getCache('app').id
      if (this.optionssearch == 'contactnumber') {
        this.contact_number = this.contact_number.replaceAll('-', '')
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetOTP(appid, this.contact_number, this.page).subscribe(
          data => {
            if (this.page == 1) {
              this.dataOTP = []
            }
            _.forEach(data.result, item => {
              this.dataOTP.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataOTP)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getOTP().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    clicktest() {
      this.check = !this.check
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.log-otp {
  .select-search {
    width: 150px;
  }
  .ant-collapse {
    margin-top: 10px !important;
  }
  .ant-collapse-header {
    background-color: #ffffff !important;
    border-radius: 12px !important;
    padding: 16px !important;
  }
  .ant-collapse-content-box {
    background-color: #f9f9f9 !important;
    border-radius: 12px !important;
  }
  // .ant-collapse-borderless > .ant-collapse-item {
  //   border-bottom: none !important;
  // }
  .icon {
    margin-right: 12px;
    width: 12px;
    color: #797979;
  }
  .content-col {
    padding-left: 45px;
    // border-top: 1px solid #f0f0f0;
    // border-bottom: 1px solid #f0f0f0;
  }
  .border-tb {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
  .h-col {
    padding: 0px 16px 0px 35px;
  }
  .createsub {
    position: absolute;
    right: 22px;
    top: 130px;
  }
  .wrap-table {
    background-color: #ffffff;
    display: block;
    width: 100%;
    max-height: calc(100vh - 255px);
    overflow-x: auto;
  }
  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
}
</style>
