<template>
  <div class="w-full min-h-full point">
    <a-card v-if="dataPointsDetail.expiring_points" class="mb-3">
      <h4>Points Overview</h4>
      <div class="d-flex gap-3">
        <div class="mt-2">
          <div class="point-balance">
            <div class="text-light-grey">Points on hands :</div>
            <div class="fw-700">
              {{ dataPointsDetail.points_balance.toLocaleString('en-US') }}
              Points
            </div>
          </div>
        </div>
        <div v-if="dataPointsDetail.points_balance > 0" class="mt-2">
          <a-popover
            trigger="click"
            placement="bottom"
            overlayClassName="point-ex"
            v-model:visible="visible5"
          >
            <template #content>
              <div
                v-for="(item, index) of dataPointsDetail.expiring_points.filter(
                  (x, index) => index > 0,
                )"
                :key="index"
              >
                <div class="d-flex justify-end px-3 py-2 gap-2">
                  <div class="fw-700 text-title-point">
                    {{ item.points.toLocaleString('en-US') }} Points
                  </div>
                  <div class="text-light-grey">
                    in
                    {{ formatDate(item.date, 'DD/MMM/YYYY', -7) }}
                  </div>
                </div>
              </div>
            </template>
            <template #title>
              <div class="d-flex gap-2">
                <div class="text-light-grey">About to expire :</div>
                <div class="fw-700 text-title-point">
                  {{
                    dataPointsDetail?.expiring_points[0]?.points.toLocaleString(
                      'en-US',
                    )
                  }}
                  Points
                </div>
                <div class="text-light-grey">
                  in
                  {{
                    formatDate(
                      dataPointsDetail?.expiring_points[0]?.date,
                      'DD/MMM/YYYY',
                      -7,
                    )
                  }}
                </div>
              </div>
            </template>
            <a-button>
              <div class="d-flex gap-2">
                <div class="text-light-grey">About to expire :</div>
                <div class="fw-700 text-title-point">
                  {{
                    dataPointsDetail?.expiring_points[0]?.points.toLocaleString(
                      'en-US',
                    )
                  }}
                  Points
                </div>
                <div class="text-light-grey">
                  in
                  {{
                    formatDate(
                      dataPointsDetail?.expiring_points[0]?.date,
                      'DD/MMM/YYYY',
                      -7,
                    )
                  }}
                </div>
                <img v-if="!visible5" src="@/assets/icons/double-drow.svg" />
                <img v-else src="@/assets/icons/double-drow-active.svg" />
              </div>
            </a-button>
          </a-popover>
        </div>
      </div>
    </a-card>
    <a-card>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <h4>Points History</h4>
        <nav class="filter-nav">
          <div class="d-flex">
            <a-select
              class="select-search"
              v-model:value="years"
              @change="reset()"
              :options="year_dropdown"
            />
            <a-popover
              v-model:visible="visible2"
              class="ml-3 mr-3"
              trigger="click"
              placement="bottom"
            >
              <template #content>
                <a-row class="popover">
                  <a-col :span="4">
                    <a-checkbox
                      v-model:checked="checkAll"
                      :indeterminate="false"
                      @change="onCheckAllChange"
                    >
                      All
                    </a-checkbox>
                  </a-col>
                  <a-col :span="20">
                    <a-checkbox-group v-model:value="monthly">
                      <a-row>
                        <a-col :span="24">
                          <a-checkbox value="0" :disabled="disabledmonth(0)"
                            >{{ this.years - 1 }} / Dec</a-checkbox
                          >
                        </a-col>
                        <a-col :span="8">
                          <a-checkbox value="1" :disabled="disabledmonth(1)"
                            >Jan</a-checkbox
                          >
                          <a-checkbox value="4" :disabled="disabledmonth(4)"
                            >Apr</a-checkbox
                          >
                          <a-checkbox value="7" :disabled="disabledmonth(7)"
                            >Jul</a-checkbox
                          >
                          <a-checkbox value="10" :disabled="disabledmonth(10)"
                            >Oct</a-checkbox
                          >
                          <a-checkbox value="13" :disabled="disabledmonth(13)"
                            >{{ this.years + 1 }} / Jan</a-checkbox
                          >
                        </a-col>
                        <a-col :span="8">
                          <a-checkbox value="2" :disabled="disabledmonth(2)"
                            >Feb</a-checkbox
                          >
                          <a-checkbox value="5" :disabled="disabledmonth(5)"
                            >May</a-checkbox
                          >
                          <a-checkbox value="8" :disabled="disabledmonth(8)"
                            >Aug</a-checkbox
                          >
                          <a-checkbox value="11" :disabled="disabledmonth(11)"
                            >Nov</a-checkbox
                          >
                        </a-col>
                        <a-col :span="8">
                          <a-checkbox value="3" :disabled="disabledmonth(3)"
                            >Mar</a-checkbox
                          >
                          <a-checkbox value="6" :disabled="disabledmonth(6)"
                            >Jun</a-checkbox
                          >
                          <a-checkbox value="9" :disabled="disabledmonth(9)"
                            >Sep</a-checkbox
                          >
                          <a-checkbox value="12" :disabled="disabledmonth(12)"
                            >Dec</a-checkbox
                          >
                        </a-col>
                      </a-row>
                    </a-checkbox-group>
                  </a-col>
                  <div class="my-3 pt-3 wrap-reset">
                    <a class="reset-all" @click="resetAll()">Reset all</a>
                  </div>
                </a-row>
              </template>
              <a-button style="width: 200px"
                >{{ setmonthly(monthly) }}
                <down-outlined class="mt-1 icon-down"
              /></a-button>
            </a-popover>
            <a-button
              type="primary"
              :disabled="!monthly.length"
              @click="clicksearch"
              ><search-outlined /> Search</a-button
            >
          </div>
          <a-button type="toggle" class="hover" @click="toggleDisplayFields">
            <img class="left" src="@/assets/icons/button/Control.svg" alt />
            Display fields
          </a-button>
          <a-button
            v-show="setperrmissionexport"
            type="teriary"
            @click="exportReport"
          >
            <file-excel-outlined /> Export as excel
          </a-button>
        </nav>
      </div>
      <div class="wrap-table-point" @scroll="handleScroll">
        <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="id"
          class="fixed-head"
          :dataSource="dataPoints"
          :columns="displayFields"
          :pagination="false"
        >
          <template #balance="{ record }">
            <div class="font-bold">
              {{ record.balance }}
            </div>
          </template>
          <template #change="{ record }">
            <div v-if="record.change < 0" class="fraud">
              {{ record.change.toLocaleString('en-US') }}
            </div>
            <div v-else>
              {{ '+' + record.change.toLocaleString('en-US') }}
            </div>
          </template>
          <template #description="{ record }">
            <div>
              {{ mapTextJSON(record.description) }}
            </div>
          </template>
        </a-table>
      </div>
      <DisplayFields
        ref="selectall"
        :value="showDisplayFields"
        :headers="columns"
        @apply="applyDisplayFields($event)"
        @handleClose="showDisplayFields = false"
      >
      </DisplayFields>
      <ExportModal
        :visible="showExportModal"
        @handleSubmit="handleExportModal($event)"
        @handleClose="showExportModal = false"
      ></ExportModal>
      <PrepareModal
        :visible="showPrepareModal"
        @handleClose="showPrepareModal = false"
      ></PrepareModal>
    </a-card>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
// content components
import { DownOutlined } from '@ant-design/icons-vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
// import DateFilter from "@/module/User/component/DateFilter.vue";
import ExportModal from '@/module/User/component/ExportModal.vue'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
// import { CalendarOutlined } from '@ant-design/icons-vue';
import { config } from '@/config/bzbsconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserRedeem',
  components: {
    DisplayFields,
    // DateFilter,
    DownOutlined,
    ExportModal,
    PrepareModal,
    // CalendarOutlined
  },
  props: {
    dataapp: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      visible2: false,
      hide: false,
      visible5: false,
      indeterminate: false,
      disabled: false,
      disable: true,
      checkAll: false,
      showDisplayFields: false,
      dataPoints: [],
      toy: [],
      searchObject: { month: 0, year: 0 },
      years: new Date().getFullYear(),
      monthly: [new Date().getMonth() + 1 + ''],
      filter_year_months: [],
      value: '',
      params: {
        name: '',
        description: '',
        filter: {
          user_id: '',
          appid: '',
          filter_year_months: [
            {
              month: 5,
              year: 2022,
            },
          ],
        },
      },
      page: '',
      month_dropdown: [
        {
          label: 'test',
          value: 0,
        },
        {
          label: 'Jan',
          value: 1,
        },
        {
          label: 'Feb',
          value: 2,
        },
        {
          label: 'Mar',
          value: 3,
        },
        {
          label: 'Apr',
          value: 4,
        },
        {
          label: 'May',
          value: 5,
        },
        {
          label: 'Jun',
          value: 6,
        },
        {
          label: 'Jul',
          value: 7,
        },
        {
          label: 'Aug',
          value: 8,
        },
        {
          label: 'Sep',
          value: 9,
        },
        {
          label: 'Oct',
          value: 10,
        },
        {
          label: 'Nov',
          value: 11,
        },
        {
          label: 'Dec',
          value: 12,
        },
        {
          label: 'Jan',
          value: 13,
        },
      ],
      year_dropdown: [],
      columns: [
        {
          title: 'Received Date',
          dataIndex: 'date',
          key: 'date',
          width: 160,
          align: 'left',
        },
        {
          title: 'Current',
          dataIndex: 'current',
          key: 'current',
          width: 120,
          align: 'right',
        },
        {
          title: 'Change',
          dataIndex: 'change',
          slots: { customRender: 'change' },
          key: 'change',
          width: 120,
          align: 'right',
        },
        {
          title: 'Balance ',
          dataIndex: 'balance',
          slots: { customRender: 'balance' },
          key: 'balance',
          width: 120,
          align: 'right',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          slots: { customRender: 'description' },
          key: 'description',
          width: 500,
          align: 'left',
        },
        {
          title: 'Expire Date',
          dataIndex: 'expire_date',
          key: 'expiredate',
          width: 120,
          align: 'left',
        },
        {
          title: 'Type ',
          dataIndex: 'type',
          key: 'type',
          width: 120,
          align: 'left',
        },
      ],
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      showExportModal: false,
      showPrepareModal: false,
      setperrmissionexport: true,
      dataPointsDetail: {},
    }
  },
  beforeCreate() {
    this.displayFields = _.cloneDeep(this.columns)
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'points') {
        this.displayFields = _.cloneDeep(this.columns)
        this.clicksearch()
      }
    },
    monthly: function (val) {
      this.indeterminate = !!val.length && val.length < 14
      var year = new Date().getFullYear()
      var month = new Date().getMonth() + 1
      if (this.years == year) {
        this.checkAll = val.length === month + 1
      } else {
        this.checkAll = val.length === 14
      }
    },
  },
  computed() {},
  mounted() {
    var startYear = new Date().getFullYear()
    var endYear = new Date().getFullYear() - 5
    for (let index = startYear; index >= endYear; index--) {
      this.year_dropdown.push({ label: index, value: index })
    }
    if (this.$route.query.tab == 'points') {
      this.displayFields = _.cloneDeep(this.columns)
      this.clicksearch()
      this.overlay = false
    }
    this.month_dropdown[0].label = this.years - 1 + ' / Dec'
    this.month_dropdown[13].label = this.years + 1 + ' / Jan'
  },
  created() {},
  methods: {
    mapTextJSON(text) {
      if (text) {
        if (text.includes('{')) {
          let form = JSON.parse(text).transfer_from
          let to = JSON.parse(text).transfer_to
          let point = JSON.parse(text).points
          if (typeof form == 'string') {
            let transfer_from = JSON.parse(form)
            let transfer_to = JSON.parse(to)
            return `Transfer ${point} points from ${transfer_from.UserId} ${transfer_from.FirstName} ${transfer_from.LastName} to ${transfer_to.UserId} ${transfer_to.FirstName} ${transfer_to.LastName}.`
          } else {
            return `Transfer ${point} points from ${form.UserId} ${form.FirstName} ${form.LastName} to ${to.UserId} ${to.FirstName} ${to.LastName}.`
          }
        } else {
          return text
        }
      } else {
        return '-'
      }
    },
    setmonthly(monthly) {
      if (monthly.length > 1) {
        return monthly.length + ' months' + ' selected'
      }
      if (monthly.length == 1) {
        var mon = this.month_dropdown.filter(x => {
          return monthly[0] == x.value
        })
        return mon[0].label
      }
    },
    resetAll() {
      this.monthly = [new Date().getMonth() + 1 + '']
    },
    reset() {
      var year = new Date().getFullYear()
      this.monthly = ''
      if (this.years == year) {
        this.monthly = [new Date().getMonth() + 1 + '']
      }
      this.month_dropdown[0].label = this.years - 1 + ' / Dec'
      this.month_dropdown[13].label = this.years + 1 + ' / Jan'
    },
    disabledmonth(monthval) {
      var year = new Date().getFullYear()
      var month = new Date().getMonth() + 1
      if (this.years == year) {
        if (month < monthval) {
          return true
        } else {
          return false
        }
      }
    },
    onCheckAllChange(e) {
      var year = new Date().getFullYear()
      var month = new Date().getMonth() + 1
      var p = []
      if (this.years == year) {
        for (var i = 0; i <= 14; i++) {
          if (i <= month) {
            p.push('' + i)
          }
        }
      } else {
        p = [
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
        ]
      }
      this.monthly = e.target.checked ? p : []
      this.indeterminate = false
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.callAPiPointDetails()
      this.page = ''
      this.filter_year_months = []
      this.monthly.forEach(month => {
        if (month == '0') {
          this.filter_year_months.push({ month: '12', year: this.years - 1 })
        } else if (month == '13') {
          this.filter_year_months.push({ month: '1', year: this.years + 1 })
        } else {
          this.filter_year_months.push({ month: month, year: this.years })
        }
      })
      this.callAPiReportPoint().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    callAPiPointDetails() {
      var data = {
        app_id: Cache.getCache('app').id,
        user_id: this.$route.params.id,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetPointDetail(data)
          .then(res => {
            this.dataPointsDetail = res.data.result
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    callAPiReportPoint() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserPointLogs2(
          this.$route.params.id,
          this.filter_year_months,
          this.page,
        )
          .then(data => {
            if (this.page == '') {
              this.dataPoints = []
            }
            this.page = data.data.lastid
            _.forEach(data.data.result, item => {
              item.description = item.description ? item.description : '-'
              item.date = moment(item.date)
                .add(this.isTimezone, 'hours')
                .format('DD/MMM/YYYY - HH:mm')
              if (item.expire_date.includes('Q')) {
                item.expire_date.split('Q')[1]
                if (item.expire_date.split('Q')[1] == '1') {
                  item.expire_date = '31/Mar/' + item.expire_date.split('Q')[0]
                }
                if (item.expire_date.split('Q')[1] == '2') {
                  item.expire_date = '30/Jun/' + item.expire_date.split('Q')[0]
                }
                if (item.expire_date.split('Q')[1] == '3') {
                  item.expire_date = '30/Sep/' + item.expire_date.split('Q')[0]
                }
                if (item.expire_date.split('Q')[1] == '4') {
                  item.expire_date = '31/Dec/' + item.expire_date.split('Q')[0]
                }
              } else {
                item.expire_date = item.expire_date
                  ? moment(item.expire_date.replace('M', '-'))
                      .endOf('month')
                      .format(this.formatdate)
                  : '-'
              }
              item.current = item.current.toLocaleString('en-US')
              item.balance = item.balance.toLocaleString('en-US')
              item.points = item.points.toLocaleString('en-US')
              this.dataPoints.push(item)
            })
            if (data.data.lastid == '') {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.setpermissionexport()
            this.$store.commit('setLoading', false)
            resolve(this.dataPoints)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.callAPiReportPoint().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.params.search.expiredate = params
      this.page = ''
      this.callAPiReportPoint()
    },
    exportReport() {
      this.showExportModal = true
    },
    handleExportModal(value) {
      var params = {
        filename: value.filename,
        description: value.description,
      }
      this.callAPiExportPoint(params).then(() => {
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    callAPiExportPoint(params) {
      this.params.filter.appid = ConfigManager.getAppId()
      this.params.name = params.filename
      this.params.description = params.description
      this.params.filter.filter_year_months = this.filter_year_months
      this.params.filter.user_id = this.$route.params.id
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostPointExport(this.params)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setpermissionexport() {
      if (
        this.dataapp.features.every(x => x.name != 'User-List-Point-Export')
      ) {
        return (this.setperrmissionexport = false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }
  .nav-search .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
  .line {
    width: 2px;
    height: 64px;
    float: right;
    background-color: $color-grey-line;
    position: absolute;
    top: 14%;
    left: calc(100% - 158px);
  }
  .nav-search {
    .group-btn-left {
      float: right;
      padding-right: 5px;
    }
    .group-btn {
      float: right;
    }
  }
  .wrap-table {
    .ant-table-wrapper {
      min-width: inherit;
    }
  }
}
.tt {
  margin-right: 10px;
  margin-left: 10px;
  background-color: #125eaf;
  color: #fff;
}
#rc-tabs-0-panel-points {
  position: relative;
}
.icon-down {
  float: right;
  width: 12px;
  color: rgba(0, 0, 0, 0.25) !important;
}
.reset-all {
  color: #f79443;
  text-decoration: underline;
}
.wrap-reset {
  border-top: 1px solid #ecedf3;
  width: 100%;
  text-align: center;
}
</style>
<style>
.popover {
  width: 420px !important;
}
.ant-popover-inner {
  box-shadow: 0 4px 10px 0 rgb(102 108 139 / 15%) !important;
}
.ant-popover-inner-content {
  padding: 0 !important;
}
.popover .ant-checkbox-wrapper {
  margin-left: 8px !important;
  margin-top: 15px !important;
}
.ant-popover-arrow-content {
  display: none !important;
}
.point {
  .ant-btn > .anticon {
    font-size: 13px !important;
  }
  .point-balance {
    height: 40px;
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    padding: 7px 15px;
    display: flex;
    gap: 6px;
  }
  .text-light-grey {
    color: #969696 !important;
  }
  .text-title-point {
    color: #4a4a4a !important;
  }
}
.point-ex {
  .text-light-grey {
    color: #969696 !important;
  }
  .text-date-point {
    font-weight: 700;
    color: #636363 !important;
  }
  .ant-popover-title {
    border-bottom: 1px solid #00000008;
  }
  .text-title-point {
    color: #4a4a4a !important;
  }
}
</style>
