<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <div class="userlevel d-flex justify-content-between">
        <TitleBreadcrumbs class="mt-4" title="User Flag" />
        <div class="mt-35">
          <a-button @click="toggleDisplayFieldsLogLevel()">Change Log</a-button>
        </div>
      </div>
    </template>
    <div class="userlevel overflow-x-hidden flex flex-col">
      <div class="flex-1 w-full px-4">
        <div class="mb-4 b-6 bg-white pl-4 pr-4">
          <div class="py-2 d-flex justify-content-between">
            <a-input
              v-model:value="search"
              class="advanced-search"
              placeholder="Search Flag"
              @pressEnter="clicksearch()"
            >
              <template #prefix>
                <a @click="clicksearch()">
                  <search-outlined />
                </a>
              </template>
            </a-input>
            <!-- <div class="d-flex">
              <a-input
                v-model:value="search"
                placeholder="Search Level"
                class="input-search"
              >
              </a-input>
              <a-button
                type="primary"
                class="btn-search ml-3"
                @click="clicksearch"
                ><search-outlined /> Search</a-button
              >
            </div> -->
            <div class="d-flex">
              <a-tooltip placement="bottom">
                <template #title>
                  <div>Create Flag</div>
                </template>
                <a-button
                  v-if="datapermissionbyapp.includes('User-Flag-Adjust')"
                  type="primary"
                  @click="Createflag"
                  ><plus-outlined /> New Flag
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div class="table-scroll">
          <div class="wrap-table-level">
            <VLoadingTable :value="overlaytable" />
            <a-table
              row-key="id"
              class="fixed-head"
              :dataSource="dataList"
              :columns="columns"
              :pagination="false"
            >
              <template #flag_id="{ text, record }">
                <a
                  v-if="datapermissionbyapp.includes('User-Flag-Adjust')"
                  class="font-level"
                  @click="editFlag(record)"
                  >{{ text }}</a
                >
                <div v-else>{{ text }}</div>
              </template>
              <template #total="{ record }">
                <div class="font-bold">
                  {{ record.total ? record.total : '-' }}
                </div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- <div class="flex-none">
      <Footer />
    </div> -->
      <a-modal
        title="Edit Flag"
        class="modal-set-expire"
        :visible="modalEdit"
        @cancel="handleCancel"
        okText="Save"
        :width="450"
        :centered="true"
        :closable="false"
      >
        <div class="mb-2">Name :</div>
        <a-input v-model:value="dataDetail.flag_name" :maxlength="80" />
        <template #footer>
          <div class="flex justify-between">
            <div>
              <a-button type="danger" @click="deleteFlag()"
                >Delete Flag</a-button
              >
            </div>
            <div>
              <a-button type="defalut" @click="handleCancel">Cancel</a-button>
              <a-button type="primary" @click="PutFlag()">Save</a-button>
            </div>
          </div>
        </template>
      </a-modal>
      <a-modal
        title="Create Flag"
        class="modal-set-expire"
        :visible="modalCreate"
        @cancel="handleCancel"
        okText="Save"
        :width="450"
        :centered="true"
        :closable="false"
      >
        <div class="mb-2">Name :</div>
        <a-input v-model:value="flagName" :maxlength="80" />
        <template #footer>
          <div class="flex justify-end">
            <div>
              <a-button type="defalut" @click="handleCancel">Cancel</a-button>
              <a-button type="primary" @click="PostFlag()">Save</a-button>
            </div>
          </div>
        </template>
      </a-modal>
    </div>
    <DisplayFieldsLogFlag
      :value="showDisplayFieldsLogLevel"
      :datalogslevel="datalogslevel"
      @handleClose="showDisplayFieldsLogLevel = false"
    >
    </DisplayFieldsLogFlag>
  </AppLayout>
</template>
<script>
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import DisplayFieldsLogFlag from '@/module/User/UserFlag/component/DisplayFieldsLogFlag.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    PlusOutlined,
    DisplayFieldsLogFlag,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name: null,
          breadcrumbName: 'User Level',
        },
      ],
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      disable: true,
      overlaytablelist: false,
      page: 1,
      search: '',
      showDisplayFieldsCreateNew: false,
      showDisplayFieldsLogLevel: false,
      showDisplayFieldsUpdateLevel: false,
      searchObject: {
        Level: '',
      },
      columns: [
        {
          title: 'Flag ID',
          dataIndex: 'flag_id',
          slots: { customRender: 'flag_id' },
          key: 'flag_id',
          width: 160,
          align: 'left',
        },
        {
          title: 'Flag Name',
          dataIndex: 'flag_name',
          key: 'flag_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'Total Users',
          dataIndex: 'total_user',
          slots: { customRender: 'total_user' },
          key: 'total_user',
          width: 120,
          align: 'right',
        },
      ],
      dataList: [],
      dataLevelname: '',
      permission: [],
      dataDetail: [],
      modalEdit: false,
      modalCreate: false,
      flagName: '',
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'User Level',
        },
      ]
    },
  },
  mounted() {
    this.clicksearch()
  },
  methods: {
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.getdatauserflag().then(() => {
        if (this.search) {
          this.onSearch(this.search)
        }
        this.$store.commit('setLoading', false)
      })
    },
    onSearch(value) {
      if (value != null) {
        this.dataList = _.filter(this.dataList, data => {
          if (
            data.flag_name
              .toLowerCase()
              .includes(value ? value.toLowerCase() : value)
          ) {
            return data
          }
        })
      } else {
        return this.dataList
      }
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    getdatauserflag() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserFlag().subscribe(
          data => {
            this.dataList = [...data.result]
            this.$store.commit('setLoading', false)
            resolve(this.dataList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    editFlag(record) {
      this.dataDetail = { ...record }
      this.modalEdit = true
    },
    Createflag() {
      this.modalCreate = true
    },
    handleCancel() {
      this.modalEdit = false
      this.modalCreate = false
      this.flagName = ''
    },
    PostFlag() {
      var data = {
        app_id: Cache.getCache('app').id,
        flag_name: this.flagName,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostFlag(data)
          .then(res => {
            this.modalCreate = false
            this.flagName = ''
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.clicksearch()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PutFlag() {
      var data = {
        app_id: Cache.getCache('app').id,
        flag_id: this.dataDetail.flag_id,
        flag_name: this.dataDetail.flag_name,
      }
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutFlag(data)
          .then(res => {
            this.modalEdit = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.clicksearch()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    deleteFlag() {
      var data = {
        app_id: Cache.getCache('app').id,
        flag_id: this.dataDetail.flag_id,
      }
      this.modalEdit = false
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Remove Flag?',
        content:
          'User is already on this flag, by removing user cannot enjoy benefit at all.',
        okText: 'Yes, Remove',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.DeleteLevel(data)
        },
      })
    },
    DeleteLevel(data) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiDeleteFlag(data).subscribe(
          res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.clicksearch()
              },
            })
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getloghistory() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetLogHistoryLogMain('MasterUserFlag').subscribe(
          res => {
            this.datalogslevel = res.result
            _.forEach(res.result, item => {
              item.new_data = JSON.parse(item.new_data)
              item.original_data = JSON.parse(item.original_data)
              item.log_message = item.log_message
                ? item.log_message.replaceAll('\r\n', '<br/>')
                : item.log_message
            })
            resolve(this.datalogslevel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getdatauserlevel().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getlevel() {
      this.clicksearch()
      this.getloghistory()
    },
    toggleDisplayFieldsCreateNew() {
      this.showDisplayFieldsCreateNew = !this.showDisplayFieldsCreateNew
    },
    toggleDisplayFieldsLogLevel() {
      this.getloghistory().then(() => {
        this.showDisplayFieldsLogLevel = !this.showDisplayFieldsLogLevel
      })
    },
    toggleDisplayFieldsUpdateLevel(level_name) {
      this.dataLevelname = level_name
      this.showDisplayFieldsUpdateLevel = !this.showDisplayFieldsUpdateLevel
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.userlevel {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }

  .nav-search {
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $color-grey-line;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 240px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 248px);
    overflow-x: auto;
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.userlevel {
  .ant-table-row {
    vertical-align: top !important;
  }
}
.mt-35 {
  margin-top: 30px;
}
</style>
