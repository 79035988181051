<template>
  <template v-if="item.index_sub == 0">
    <span class="">
      <div class="box-btn">
        <a class="btn-remove" @click="removeCriteria(item)">
          <img src="@/assets/icons/button/btn-delete.svg" />
        </a>
      </div>
    </span>
  </template>

  <div
    class="criterial-checkbox"
    :class="[item.index_sub + 1 == item.length_sub ? 'last_item' : '']"
  >
    <span v-if="item.index_sub == 0" class="">
      {{ item.tag_group_name }} : {{ item.name }}
    </span>
    <span v-if="item.index_sub != 0" class="">{{ item.name }}</span>
  </div>
  <template v-if="item.index_sub + 1 == item.length_sub">
    <span class="last-checkbox"></span>
  </template>
</template>

<script>
export default {
  name: 'CriteriaItem',
  props: {
    render: Number,
    hidetag: Boolean,
    item: {
      type: Object,
    },
    update: Number,
    index: Number,
  },
  data: function () {
    return {
      isAnd: true,
    }
  },
  mounted: function () {
    if (this.item.criteria == 'and') {
      this.isAnd = true
    } else {
      this.isAnd = false
    }
  },
  methods: {
    toggleState(item) {
      if (item.type == 'checkbox') return
      this.isAnd = !this.isAnd
      this.$emit('change', this.isAnd)
    },
    removeCriteria(item) {
      this.$emit('removeCriteria', item)
    },
  },
}
</script>
<style lang="scss" scoped>
.first-checkbox,
.last-checkbox {
  height: 1.2rem;
  display: block;
  width: 100%;
  position: relative;
}
.last-checkbox {
  height: 0.25rem;
}
.box-btn {
  position: absolute;
  right: 0;
  display: block;
  width: 50px;
  float: right;
  text-align: right;
  .btn-remove {
    display: inline-block;
    padding: 0px;
  }
}
// .first-checkbox + .criterial-checkbox {
// }
.criterial-checkbox {
  display: inline-block;
  padding: 4px 0;
  span {
    display: inline-block;
    vertical-align: middle;
    &:after {
      content: ',';
      padding-right: 4px;
      display: inline-block;
    }
  }
  &.last_item {
    padding-right: 20px;
    span:after {
      content: '';
    }
  }
}
.criterial-in {
  width: 82px;
  text-align: center;
  display: inline-block;
  > div {
    width: 100%;
  }
}
</style>
