<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <div class="d-flex justify-content-between">
        <div>
          <TitleBreadcrumbs class="mt-4" title="User Tag" />
        </div>
        <div class="mt-35">
          <a-button @click="toggleDisplayFieldsLogLevel()">Change Log</a-button>
        </div>
      </div>
      <div class="">
        <div class="flex-1 w-full">
          <div class="mb-4 b-6 bg-white pl-4 pr-4">
            <div class="py-2 d-flex justify-content-end">
              <div>
                <a-select
                  class="select-search"
                  style="width: 150px"
                  v-model:value="statusdropdown"
                  :options="status_dropdown"
                  @change="clicksearch()"
                />
                <!-- <a-button
                type="primary"
                class="btn-search ml-3"
                @click="clicksearch()"
                ><search-outlined /> Search</a-button
              > -->
              </div>
              <div>
                <!-- <a-button type="teriary" @click="exportReport">
                <file-excel-outlined /> Export as excel
              </a-button> -->
                <a-button
                  v-if="datapermissionbyapp.includes('User-Tag-Adjust')"
                  class="ml-3"
                  type="primary"
                  @click="toggleDisplayFieldsCreateNew"
                  >+ Create new tag
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-if="dataTagtypes.length == 0" class="text-webkit-center">
      <img :src="imgEmpty" />
      <div class="nodata text-center">No Data</div>
    </div>
    <div v-else class="usertag flex-1 w-full px-4">
      <div class="table-scroll">
        <div class="wrapper-content" @scroll="handleScroll">
          <!-- <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="id"
          class="fixed-head"
          :dataSource="dataTagtypes"
          :columns="columns"
          :pagination="false"
        >
          <template #status="{ record }">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="record.active == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge
                  v-if="record.active == item.name"
                  :status="item.color"
                  :text="item.text"
                />
              </a-tag>
            </template>
          </template>
          <template #tag_group="{ record }">
            {{ record.row_key ? record.row_key : '-' }}
          </template>
          <template #tag="{ record }">
            <div class="d-flex">
              <div
                v-for="(itemtype, idx) of record.tag_types"
                :key="`idx-${idx}`"
              >
                <a-tag>{{ itemtype.row_key }}</a-tag>
              </div>
            </div>
          </template>
          <template #total="{ record }">
            <div class="d-flex">
              <user-outlined class="ml-3" />
              <div class="ml-2 total">
                {{ record.count ? record.count : '0' }}
              </div>
              <tags-outlined class="ml-2" />
              <div class="ml-2 total">
                {{ record.tag_types.length ? record.tag_types.length : '0' }}/{{
                  checkActive(record.tag_types)
                }}
              </div>
            </div>
          </template>
          <template #action="{ record }">
            <a-button type="primary" @click="editTag(record)" ghost
              >Setting</a-button
            >
          </template>
        </a-table> -->
          <div class="content">
            <div v-for="(itemgroup, index) of dataTagtypes" :key="index">
              <div class="tag-group mb-3">
                <div class="d-flex align-items-center">
                  <caret-right-outlined
                    v-if="!itemgroup.is_show"
                    @click="toggleItem(itemgroup.name)"
                  />
                  <caret-down-outlined
                    v-if="itemgroup.is_show"
                    @click="toggleItem2(itemgroup.name)"
                  />
                  <h4 class="pr-2">
                    {{ itemgroup.name }}
                  </h4>
                  <template v-for="(item, index) in status">
                    <a-tag
                      v-if="itemgroup.active == item.name"
                      :key="index"
                      :color="item.color == 'default' ? null : item.color"
                      class="tag-status"
                    >
                      <a-badge
                        v-if="itemgroup.active == item.name"
                        :status="item.color"
                        :text="item.text"
                      />
                    </a-tag>
                  </template>
                  <!-- <tags-outlined class="text-primary-p" /> -->
                  <!-- <div class="total">
                    {{
                      dataTagTypeList.length ? dataTagTypeList.length : '0'
                    }}/{{ dataTagTypeList.length }}
                  </div> -->
                  <a-dropdown
                    v-if="datapermissionbyapp.includes('User-Tag-Adjust')"
                  >
                    <a-button
                      class="btn-icon d-flex justify-content-center align-items-center ms-auto more"
                      ><more-outlined
                    /></a-button>
                    <template #overlay>
                      <a-menu>
                        <!-- <a-menu-item @click="editTag(itemgroup)">
                          Add Tag Type
                        </a-menu-item> -->
                        <a-menu-item @click="deleteTaggroup(itemgroup.name)">
                          Delete Tag Group
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
                <hr v-if="itemgroup.is_show" />
                <div v-if="itemgroup.is_show" class="list-type">
                  <div
                    v-if="itemgroup.is_show"
                    :class="
                      dataTagTypeList.length == 0
                        ? 'd-flex gap-2'
                        : 'd-flex align-items-center gap-2'
                    "
                  >
                    <tags-outlined
                      :class="
                        dataTagTypeList.length == 0
                          ? 'text-primary-p pt-1'
                          : 'text-primary-p'
                      "
                    />
                    <div>Tag Type:</div>
                  </div>
                  <div
                    v-if="dataTagTypeList.length == 0"
                    class="text-webkit-center no"
                  >
                    <img :src="imgEmpty" />
                    <div class="nodata text-center">No Data</div>
                  </div>
                  <div
                    v-else
                    v-for="(itemtype, idx) of dataTagTypeList"
                    :key="`idx-${idx}`"
                  >
                    <a-tag
                      ><a-badge :status="'success'" />{{ itemtype.row_key }}
                      <a
                        v-if="datapermissionbyapp.includes('User-Tag-Adjust')"
                        @click="delectThisTag(itemgroup.name, itemtype.row_key)"
                        >x</a
                      ></a-tag
                    >
                  </div>
                  <a
                    v-if="!nomorelist"
                    class="loadmore text-link"
                    @click="loadMoreData(itemgroup.name)"
                    >Load more</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="flex-none">
      <Footer />
    </div> -->
    <a-modal
      :visible="modalEdittag"
      title="Edit Tag Details"
      okText="Save"
      :width="500"
      :centered="true"
      :closable="false"
    >
      <div class="row">
        <div class="col-4">
          <a-radio-group v-model:value="setedit" disabled>
            <a-radio class="radio-expiry pt-1" disabled :value="1">
              Tag type
            </a-radio>
          </a-radio-group>
        </div>
        <div class="col-8">
          <a-select
            class="w-100"
            :disabled="true"
            v-model:value="detailtaggroup.row_key"
          ></a-select>
          <a
            class="text-link"
            v-if="checkAdd"
            @click="addTagTypes(detailtag.length)"
            >Add More</a
          >
          <div
            v-for="(itemtype, index) of detailtag"
            :key="`tableconfigindex-${index}`"
          >
            <div class="mt-2 d-flex">
              <a-input
                v-model:value="itemtype.row_key"
                :disabled="itemtype?.active"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <a-button @click="handleCancel()">Cancel</a-button>
            <a-button
              @click="saveTags(detailtaggroup.row_key, detailtag)"
              type="primary"
              >Save</a-button
            >
          </div>
        </div>
      </template>
    </a-modal>
    <a-modal
      :visible="modalEditAndCreatetag"
      title="Create new tag"
      okText="Save"
      :width="500"
      :centered="true"
      :closable="false"
      @ok="createTag()"
      @cancel="closeModal()"
      :ok-button-props="{
        disabled: !checkcha || !checkcha2,
      }"
    >
      <div class="row">
        <div class="col-4">
          <a-radio-group
            v-model:value="setcreate"
            @change="changeTag(setcreate)"
          >
            <a-radio class="radio-expiry pt-1" :value="1"> Tag group </a-radio>
            <a-radio class="radio-expiry mt-4" :value="2"> Tag type </a-radio>
          </a-radio-group>
        </div>
        <div class="col-8">
          <div class="">
            <a-input
              class="w-100"
              v-model:value="tag_group"
              placeholder="Enter Tag Group Name"
              :maxlength="30"
              :disabled="setcreate != 1"
            />
            <div v-show="!checkcha" class="fs-12-error">
              <div>
                <exclamation-circle-outlined class="mr-1" />{{ texterror }}
              </div>
            </div>
          </div>
          <div class="mt-3">
            <a-select
              class="w-100"
              v-model:value="tag_group_options"
              :options="options_tag_group"
              placeholder="Please Select Tag group"
              :disabled="setcreate != 2"
            />
            <a-input
              v-if="tag_group_options"
              class="mt-3"
              placeholder="Please enter Tags name"
              :disabled="setcreate != 2"
              :maxlength="30"
              v-model:value="tag_name"
            />
            <div v-show="!checkcha2" class="fs-12-error">
              <div>
                <exclamation-circle-outlined class="mr-1" />{{ texterror }}
              </div>
            </div>
            <!-- <div
              v-for="(itemtype, index)  of setTagtype(tag_group_options)"
              :key="`tableconfigindex-${index}`"
            >
              <div v-for="(item, index2) of itemtype.tag_types" :key="index2">
                <div class="mt-2 d-flex">
                  <a-input v-model:value="item.row_key" :disabled="true" />
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <!-- <template #footer>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <a-button @click="handleCancel()">Cancel</a-button>
            <a-button @click="saveTagsRemove()" type="primary">Save</a-button>
          </div>
        </div>
      </template> -->
    </a-modal>
    <DisplayFieldsAddTag
      :value="showDisplayFieldsAddTag"
      @getTagGroup="getTagGroup($event)"
      @getTagTypes="getTagTypes($event)"
      @handleClose="showDisplayFieldsAddTag = false"
    >
    </DisplayFieldsAddTag>
    <DisplayFieldsEditTag
      :value="showDisplayFieldsEditTag"
      :itemtype="itemtype"
      :itemgroup="itemgroup"
      @getTagGroup="getTagGroup($event)"
      @getTagTypes="getTagTypes($event)"
      @handleClose="showDisplayFieldsEditTag = false"
    >
    </DisplayFieldsEditTag>
    <DisplayFieldsLogFlag
      :value="showDisplayFieldsLogLevel"
      :datalogslevel="datalogslevel"
      @handleClose="showDisplayFieldsLogLevel = false"
    >
    </DisplayFieldsLogFlag>
  </AppLayout>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import AppLayout from '@/components/Layout/default.vue'
import ConfigManager from '@/config/ConfigManager.js'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import _ from 'lodash'
import { DeleteOutlined } from '@ant-design/icons-vue'
import DisplayFieldsLogFlag from '@/module/User/UserFlag/component/DisplayFieldsLogFlag.vue'
import { config } from '@/config/bzbsconfig.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFieldsAddTag from '@/module/User/UserTags/component/DisplayFieldsAddTag.vue'
import DisplayFieldsEditTag from '@/module/User/UserTags/component/DisplayFieldsEditTag.vue'

export default {
  name: 'UserLevel',
  components: {
    // Footer,
    AppLayout,
    TitleBreadcrumbs,
    DisplayFieldsAddTag,
    DisplayFieldsEditTag,
    DisplayFieldsLogFlag,
  },
  data: function () {
    return {
      checkcha2: true,
      checkcha: true,
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      texterror: 'Only letters (a-z, ก-ฮ) and numbers (0-9) are allowed.',
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name: null,
          breadcrumbName: 'User Tag',
        },
      ],
      disable: true,
      isstatus: null,
      page: 1,
      search: '',
      showDisplayFieldsAddTag: false,
      showDisplayFieldsEditTag: false,
      itemgroup: [],
      itemtype: [],
      dataLevel: [],
      dataLevelname: '',
      grouptype_name: '',
      type_name: '',
      active: null,
      dataTagtypes: [],
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
      statusdropdown: null,
      status_dropdown: [
        {
          label: 'All',
          value: null,
        },
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      columns: [
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 120,
          align: 'left',
        },
        {
          title: 'Tag group',
          dataIndex: 'tag_group',
          slots: { customRender: 'tag_group' },
          key: 'tag_group',
          width: 120,
          align: 'left',
        },
        {
          title: 'Tag',
          dataIndex: 'tag',
          slots: { customRender: 'tag' },
          key: 'tag',
          width: 120,
          align: 'left',
        },
        {
          title: '',
          dataIndex: 'total',
          slots: { customRender: 'total' },
          key: 'total',
          width: 120,
          align: 'left',
        },
        {
          title: '',
          dataIndex: 'action',
          slots: { customRender: 'action' },
          key: 'action',
          width: 120,
          align: 'left',
        },
      ],
      detailtag: {},
      modalEdittag: false,
      tagtype: [],
      detailtaggroup: [],
      modalEditAndCreatetag: false,
      options_tag_group: [],
      setcreate: 1,
      tag_group_options: null,
      tag_group: '',
      tag_name: '',
      setedit: 1,
      checkAdd: true,
      totals: null,
      checkToggle: [],
      dataTagTypeList: [],
      page2: 1,
      nomorelist: null,
      showDisplayFieldsLogLevel: false,
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'User Tag',
        },
      ]
    },
  },
  created() {},
  mounted() {
    this.$store.commit('setLoading', true)
    this.getTagTypes().then(() => {
      this.$store.commit('setLoading', false)
    })
  },
  methods: {
    toggleItem(group) {
      this.callTagtypeList(group)
      _.forEach(this.dataTagtypes, x => {
        if (x.name == group) {
          x.is_show = true
        } else {
          x.is_show = false
        }
      })
    },
    toggleItem2(group) {
      this.callTagtypeList(group)
      _.forEach(this.dataTagtypes, x => {
        if (x.name == group) {
          x.is_show = false
        } else {
          x.is_show = false
        }
      })
    },
    checkCharacter(cha) {
      let regex = /^[0-9a-zA-Zก-๏ _]+$/
      if (regex.test(cha) || cha.length == 0) {
        this.checkcha = true
      } else {
        this.checkcha = false
      }
    },
    checkCharacter2(cha) {
      let regex = /^[0-9a-zA-Zก-๏ _]+$/
      if (regex.test(cha) || cha.length == 0) {
        this.checkcha2 = true
      } else {
        this.checkcha2 = false
      }
    },
    setTagtype(id) {
      let test = this.dataTagtypes.filter(x => x.row_key == id)
      return test
    },
    closeModal() {
      this.modalEditAndCreatetag = false
    },
    changeTag(tag) {
      if (tag == 1) {
        this.tag_group_options = null
      } else {
        this.tag_group = ''
        this.tag_name = ''
      }
    },
    createTag() {
      if (this.setcreate == 1) {
        this.createTaggroup()
      } else {
        this.createTagtypes()
      }
    },
    createTaggroup() {
      var datataggroup = {
        appid: ConfigManager.getAppId(),
        name: this.tag_group,
        active: true,
      }
      this.PostTagGroup(datataggroup)
    },
    PostTagGroup(datataggroup) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTagGroup(datataggroup)
          .then(res => {
            // this.visible = false
            this.modalEditAndCreatetag = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getTagTypes()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    saveTags(gruop, item) {
      let itemnew = item.filter(x => !x.active)
      var datatagtypes = {
        app_id: ConfigManager.getAppId(),
        group_name: gruop,
        name: itemnew[0].row_key,
        active: true,
      }
      this.PostTagTypes(datatagtypes)
    },
    createTagtypes() {
      var datatagtypes = {
        app_id: ConfigManager.getAppId(),
        group_name: this.tag_group_options,
        name: this.tag_name,
        active: true,
      }
      this.PostTagTypes(datatagtypes)
    },
    PostTagTypes(datatagtypes) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTagTypes(datatagtypes)
          .then(res => {
            // this.visible = false
            this.modalEdittag = false
            this.modalEditAndCreatetag = false
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getTagTypes()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    checkActive(tagtype) {
      var totalactive = null
      totalactive = tagtype.filter(x => x.active)
      return totalactive.length
    },
    clicksearch() {
      this.getTagTypes().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getTagTypes().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getTagTypes() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagGroup(this.statusdropdown, this.page).subscribe(
          data => {
            if (this.page == 1) {
              this.dataTagtypes = []
            }
            _.forEach(data.result, item => {
              this.dataTagtypes.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            _.forEach(this.dataTagtypes, (item, index) => {
              this.options_tag_group[index] = {
                label: item.name,
                value: item.name,
              }
              item.is_show = false
            })
            this.$store.commit('setLoading', false)
            resolve(data.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScrollTypelist(event) {
      if (!this.nomore2 && !this.overlaytable2) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable2 = true
          this.page2++
          this.getTagTypelist().then(() => {
            this.overlaytable2 = false
          })
        }
      }
    },
    loadMoreData(group) {
      if (!this.nomorelist) {
        this.page2++
        this.getTagTypelist(group)
      }
    },
    callTagtypeList(group) {
      this.page2 = 1
      this.getTagTypelist(group)
    },
    getTagTypelist(group) {
      this.$store.commit('setLoading', true)
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagTypeList(appid, group, this.page2).subscribe(
          data => {
            if (this.page2 == 1) {
              this.dataTagTypeList = []
            }
            _.forEach(data.result, item => {
              this.dataTagTypeList.push(item)
            })
            console.log(data.result.length)
            if (data.result.length < 20 || data.result.length == 0) {
              this.nomorelist = true
            } else {
              this.nomorelist = false
            }
            console.log('fesffsdfds', this.nomorelist)
            this.$store.commit('setLoading', false)
            resolve(this.dataTagTypeList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getTagTypesStatus() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagTypes(this.statusdropdown).subscribe(
          res => {
            this.dataTagtypes = res.result
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleDisplayFieldsCreateNew() {
      this.modalEditAndCreatetag = true
      this.tag_group_options = null
      this.tag_group = ''
      this.tag_name = ''
    },
    toggleDisplayFieldsEdit(event, itemgroup) {
      event.stopPropagation()
      this.showDisplayFieldsEditTag = !this.showDisplayFieldsEditTag
      this.itemgroup = itemgroup
    },
    editTag(record) {
      console.log('testetste')
      this.checkAdd = true
      this.modalEdittag = true
      this.detailtaggroup = record
      this.detailtag = [...record.tag_types]
      console.log(this.detailtag, 'testetste')
    },
    addTagTypes(total) {
      this.totals = total
      this.detailtag.push({})
      if (this.totals < this.detailtag.length) {
        this.checkAdd = false
      }
    },
    handleCancel() {
      console.log(this.detailtag)
      this.modalEdittag = false
    },
    // deleteThisTag(tag) {
    //   this.detailtag = this.detailtag.filter(x => x.row_key != tag)
    //   this.tagtype.push(tag)
    //   if (this.totals == this.detailtag.length) {
    //     this.checkAdd = true
    //   }
    // },
    delectThisTag(group, tagtype) {
      var datatagtypes = {
        app_id: ConfigManager.getAppId(),
        tag_group: group,
        is_filter_delete: true,
        list_type: [tagtype],
      }
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you sure you want to delete this (' + tagtype + ') tag?',
        content:
          'This action cannot be undone. Deleting this tag will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostTagsTypeRemove(datatagtypes)
        },
      })
    },
    saveTagsRemove() {
      var datatagtypes = {
        app_id: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        tag_types: this.tagtype,
      }
      this.PostTagsTypeRemove(datatagtypes)
    },
    PostTagsTypeRemove(datatagtypes) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRemoveTaggroup(datatagtypes)
          .then(res => {
            // this.visible = false
            this.$store.commit('setLoading', false)
            this.modalEdittag = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    deleteTaggroup(key) {
      this.modalEdittag = false
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you sure you want to delete this tag group?',
        content:
          'This action cannot be undone. Deleting this tag will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostTagsRemove(key)
        },
        onCancel: () => {},
      })
    },
    PostTagsRemove(key) {
      this.$store.commit('setLoading', true)
      var data = {
        app_id: ConfigManager.getAppId(),
        tag_group: key,
        is_filter_delete: false,
        list_type: null,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRemoveTaggroup(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            // this.visible = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    toggleDisplayFieldsLogLevel() {
      this.getloghistory().then(() => {
        this.showDisplayFieldsLogLevel = !this.showDisplayFieldsLogLevel
      })
    },
    getloghistory() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetLogHistoryLogMain('MasterUserTag').subscribe(
          res => {
            this.datalogslevel = res.result
            _.forEach(res.result, item => {
              item.new_data = JSON.parse(item.new_data)
              item.original_data = JSON.parse(item.original_data)
              item.log_message = item.log_message
                ? item.log_message.replaceAll('\r\n', '<br/>')
                : item.log_message
            })
            resolve(this.datalogslevel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.usertag {
  overflow-x: hidden;

  .table-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }
  .nav-search {
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $color-grey-line;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 240px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}
</style>
<style lang="scss">
.usertag {
  .wrapper-content {
    display: block;
    width: 100%;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
    overflow-x: auto;

    .link {
      color: #f79443;
      cursor: pointer;
    }
    .total {
      color: #969696;
    }
    hr {
      margin: 8px 0 8px 0;
      height: 2px !important;
      background-color: #c9c9c9 !important;
      opacity: 0.6 !important;
    }
  }
}
.tag-group {
  background-color: #ffffff;
  border: 1px solid #c9c9c9;
  padding: 8px;
  border-radius: 6px;
}
.ant-btn.btn-icon.more {
  height: 24px !important;
  width: 24px !important;
  padding: 0 !important;
  .anticon svg {
    margin-top: -3px !important;
    font-size: 16px !important;
  }
}
.list-type {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: 4px;
}
.text-primary-p {
  color: #f79443 !important;
}
.loadmore {
  position: absolute;
  right: 30px;
  bottom: 0px;
}
.no {
  margin-left: 35%;
  img {
    width: 60px !important;
  }
}
</style>
