<template>
  <a-modal
    class="modal-reset"
    :visible="showModal"
    title="New Download"
    :centered="true"
    :closable="false"
    okText="Continue"
    :footer="false"
    @cancel="handleClose"
  >
    <div class="">
      <a-form
        name="export"
        :model="formState"
        :label-col="{ span: 24 }"
        :wrapper-col="{ span: 24 }"
        @finish="onFinish"
      >
        <div class="">
          <a-form-item
            label="Filename"
            name="filename"
            :rules="[
              {
                required: true,
                message: 'Please input your filename!',
              },
            ]"
          >
            <a-input
              v-model:value="formState.filename"
              :class="[!checkcha ? 'input-error' : '']"
              :maxlength="100"
              @change="checkCharacter(formState.filename)"
            />
            <div v-show="!checkcha" class="fs-12-error">
              <div>
                <exclamation-circle-outlined class="mr-1" />{{ texterror }}
              </div>
            </div>
            
          </a-form-item>
          <a-form-item
            label="Description"
            name="description"
          >
            <a-textarea
              v-model:value="formState.description"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-item>
        </div>
        <div class="modal-footer">
          <a-button @click="handleClose">Cancel</a-button>
          <a-button
            type="primary"
            html-type="submit"
            :disabled="!checkcha"
            style="width: 100px"
            >Continue</a-button
          >
        </div>
      </a-form>
    </div>
  </a-modal>
</template>
<script>
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  name: 'Export',
  components: {
    ExclamationCircleOutlined,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  inject: ['toolbarStates'],
  watch: {
    visible(value, oldvalue) {
      if (value) {
        this.formState = {
          filename: '',
          description: '',
        }
      }
      if (value != oldvalue) {
        this.showModal = value
      }
    },
  },
  data: function () {
    return {
      formState: {
        filename: '',
        description: '',
      },
      showModal: false,
      checkcha: true,
      texterror: 'Only letters (a-z, ก-ฮ) and numbers (0-9) are allowed.',
      checkfilename: false,
    }
  },
  methods: {
    checkCharacter(cha) {
      let regex = /^[0-9a-zA-Zก-๏ ]+$/
      if (regex.test(cha) || cha.length == 0) {
        this.checkcha = true
      } else {
        this.checkcha = false
      }

    },
    onFinish(values) {
      this.formState = {
        filename: '',
        description: '',
      }
      this.$emit('handleSubmit', values)
    },
    handleClose() {
      this.showModal = false
      this.checkcha = true
      // this.toolbarStates.showExport = false
      this.$emit('handleClose')
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-footer {
  border-top: none !important;
}
.ant-btn-primary {
  width: 100%;
}

</style>
