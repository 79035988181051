<template>
  <a-card class="w-full min-h-full purchase">
    <nav class="filter-nav d-flex justify-content-between mb-3">
      <div class="d-flex gab-10">
        <a-input-group compact>
          <a-select
            class="select-search"
            v-model:value="optionssearch"
            :options="options_search"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @keyup="enterFilter($event)"
            :allowClear="true"
          >
            <template #prefix>
              <a @click="applyFilter">
                <search-outlined />
              </a>
            </template>
            <template #suffix>
              <a @click="toggleAdvaceSearch">
                <img
                  class="ico-filter"
                  :class="{ active: checkActive }"
                  src="@/assets/icons/faders.svg"
                />
              </a>
              <FilterSearch
                ref="FilterSearch"
                v-model:value="showAdvaceSearch"
                :FilterConfig="optionlist"
                @applyFilter="applyFilter($event)"
                @handleClose="showAdvaceSearch = false"
              />
            </template>
          </a-input>
        </a-input-group>
        <!-- <a-select v-model:value="statusdropdown" :options="status_dropdown" />
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
          />
        </div> -->
        <!-- <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch"
            ><search-outlined /> Search</a-button
          >
        </div> -->
      </div>
      <div class="d-flex gab-10">
        <a-button type="toggle" class="hover" @click="toggleDisplayFields">
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <a-button
          v-if="dataapp.includes('User-List-Purchase-Export')"
          type="teriary"
          @click="exportReport"
        >
          <file-excel-outlined /> Export as excel
        </a-button>
      </div>
    </nav>
    <div
      :class="purchasetype == 'Food' ? 'wrap-table' : 'wrap-table-good'"
      @scroll="handleScroll"
    >
      <VLoadingTable :value="overlaytablelist" />
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataPurchase"
        :columns="displayFields"
        :pagination="false"
      >
        <template #payment_id="{ text, record }">
          <a
            v-if="dataapp.includes('User-List-Purchase-Detail')"
            @click="showModal(text, record)"
            class="font-paymentid"
          >
            {{ text }}
          </a>
          <div v-else>
            {{ text }}
          </div>
        </template>
        <template #complete="{ record }">
          <template v-for="(item, index) in status">
            <a-tag
              v-if="record.complete == item.name"
              :key="index"
              :color="item.color == 'default' ? null : item.color"
              class="tag-status"
            >
              <a-badge
                v-if="record.complete == item.name"
                :status="item.color"
                :text="item.text"
              />
            </a-tag>
          </template>
        </template>
        <template #items="{ record }">
          <a-badge
            :count="record.items"
            :overflow-count="999999"
            :class="type == 'default' ? null : 'count-' + type"
          />
        </template>
        <template #type="{ record }">
          <div>{{ record.type }}</div>
          <div v-if="record.type.includes('In Store')">
            <div>
              {{ record.location }}
            </div>
            <div>
              {{ record.hub_code }}
            </div>
          </div>
          <div v-if="record.type.includes('Delivery')">
            {{ record.delivery_type }}
          </div>
        </template>
        <template #location="{ record }">
          <div>{{ record.location || '-' }}</div>
          <div>{{ record.hub_code || '-' }}</div>
        </template>
        <template #schedule="{ record }">
          <div>
            {{
              formatDate(record.schedule_at, 'DD/MMM/YYYY - HH:mm', -7) || '-'
            }}
          </div>
        </template>
        <template #driver="{ record }">
          <div
            v-if="
              record.driver?.driver_name || record.driver?.driver_contact_number
            "
          >
            <div>
              {{ record.driver?.driver_name || '-' }}
            </div>
            <div>
              {{ record.driver?.driver_contact_number || '-' }}
            </div>
          </div>
          <div v-else>-</div>
        </template>
        <template #pick_up="{ record }">
          {{ record.pick_up ? 'Yes' : 'No' }}
        </template>
        <template #status="{ record }">
          <template v-for="(itemstatus, index) in status">
            <a-tag
              v-if="record.status == itemstatus.name"
              :key="index"
              :color="itemstatus.color == 'default' ? null : itemstatus.color"
              class="tag-status"
            >
              <a-badge :status="itemstatus.color" :text="itemstatus.text" />
            </a-tag>
          </template>
        </template>
      </a-table>
    </div>
    <DisplayFields
      ref="selectall"
      :value="showDisplayFields"
      :headers="columns"
      @apply="applyDisplayFields($event)"
      @handleClose="showDisplayFields = false"
    >
    </DisplayFields>
    <ExportModal
      :visible="showExportModal"
      @handleSubmit="handleExportModal($event)"
      @handleClose="showExportModal = false"
    ></ExportModal>
    <PrepareModal
      :visible="showPrepareModal"
      @handleClose="showPrepareModal = false"
    ></PrepareModal>
    <a-modal
      :visible="visible"
      :title="'Payment ID :' + modalPaymentId"
      @cancel="handleCancel"
      class="modal-tablelist"
      width="1600px"
      :centered="true"
      :closable="false"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <div class="float-right mb-3">
        <a-tooltip placement="bottom">
          <template #title>
            <div>Export</div>
          </template>
          <a-button
            v-if="dataapp.includes('User-List-Purchase-Detail-Export')"
            type="teriary"
            @click="exportReportPaymentId"
          >
            <file-excel-outlined /> Export as excel
          </a-button>
        </a-tooltip>
      </div>
      <div class="wrap-table-modal" @scroll="handleScrollModal">
        <VLoadingTable :value="overlaytablemodal" />
        <a-table
          row-key="partitionkey"
          class="fixed-head font-table-modal"
          :dataSource="dataPayment"
          :columns="paymentColumns"
          :pagination="false"
        >
          <template #campaign_id="{ record }">
            <div>
              {{ record.campaign_name }}
              <label class="text-id">{{ record.campaign_id }}</label>
            </div>
          </template>
          <template #agency="{ record }">
            <div>
              {{ record.agency_name }}
              <label class="text-id">{{ record.agency_id }}</label>
            </div>
          </template>
          <template #shipping_status="{ record }">
            <div>
              {{ record.shipped ? 'Shipping is available' : 'No Shipping' }}
            </div>
            <div v-if="record.shipping_date">
              {{ formatDate(record.shipping_date) }}
            </div>
          </template>
          <template #delivery="{ record }">
            <div>
              {{ record.delivery_name }}
            </div>
            <div>
              {{ record.parcel_no }}
            </div>
          </template>
        </a-table>
      </div>
    </a-modal>
    <!-- <a-modal
      :visible="visible"
      @cancel="handleCancel"
      class="modal-tablelist"
      width="1400px"
      :centered="true"
      :closable="false"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <template #title>
        <div class="d-flex justify-content-between">
          <div>Payment ID : {{ modalPaymentId }}</div>
          <div class="d-flex fs-14 pt-1">
            <div>Order Date:</div>
            <div class="fs-ori">-</div>
            <div class="ml-4">Success date:</div>
            <div class="fs-ori">{{ dataByID.success_date }}</div>
          </div>
        </div>
      </template>
      <a-card>
        <div class="d-flex">
          <div class="fs-title">Order ID:</div>
          <div class="ml-2">
            {{ dataByID.order_id ? dataByID.order_id : '-' }}
          </div>
          <div class="fs-title ml-5">Payment ID:</div>
          <div class="ml-2">
            {{ dataByID.payment_id ? dataByID.payment_id : '-' }}
          </div>
          <div class="mx-3">|</div>
          <div class="fs-title">Agency Name:</div>
          <div class="ml-2">
            {{
              dataPayment[0]?.agency_name ? dataPayment[0]?.agency_name : '-'
            }}
          </div>
          <div class="fs-title ml-5">Agency ID:</div>
          <div class="ml-2">
            {{ dataPayment[0]?.agency_id ? dataPayment[0]?.agency_id : '-' }}
          </div>
        </div>
        <div class="d-flex mt-2">
          <div class="fs-title">Quantity:</div>
          <div class="ml-2">
            {{ dataPayment ? dataPayment.length : '-' }}
          </div>
          <div class="fs-title ml-5">Channel:</div>
          <div class="ml-2">
            {{ dataByID.channel ? dataByID.channel : '-' }}
          </div>
          <div class="fs-title ml-5">Price:</div>
          <div class="ml-2">
            {{ dataByID.amount ? dataByID.amount + ' THB' : '-' }}
          </div>
          <div class="fs-title ml-5">Discount:</div>
          <div class="ml-2">
            {{ dataByID.discount ? dataByID.discount + ' THB' : '-' }}
          </div>
          <div class="fs-title ml-5">Delivery Fees:</div>
          <div class="ml-2">
            {{
              dataByID.shipping_amount ? dataByID.shipping_amount + ' THB' : '-'
            }}
          </div>
          <div class="fs-title ml-5">Total:</div>
          <div class="ml-2">
            {{ dataByID.trans_amount ? dataByID.trans_amount + ' THB' : '-' }}
          </div>
          <div class="fs-title ml-5">Driver:</div>
          <div class="ml-2">
            {{
              dataByID.driver.driver_name ? dataByID.driver.driver_name : '-'
            }}
          </div>
        </div>
        <div class="d-flex mt-2">
          <div class="fs-title">Receiver Info:</div>
          <div class="ml-2">
            {{ dataByID.first_name }} {{ dataByID.last_name }}
          </div>
          <div class="fs-title ml-5">Tel.:</div>
          <div class="ml-2">-</div>
          <div class="fs-title ml-5">Email:</div>
          <div class="ml-2">-</div>
          <div class="fs-title ml-5">Address:</div>
          <div class="ml-2">
            {{ dataByID.address ? dataByID.address : '-' }}
          </div>
        </div>
      </a-card>
      <div class="row">
        <div class="col-9">
          <a-card>
            <div>Order ID:</div>
            <div class="timeline">
              <div>
                <div class="tracking">
                  <img src="@/assets/icons/prepare.svg" />
                </div>
                <div class="fs-title">Preparing</div>
              </div>
              <div class="tracking-line"></div>
              <div>
                <div class="tracking-active">
                  <img src="@/assets/icons/truck.svg" />
                </div>
                <div class="fs-title">Shipped</div>
                <div class="date-shipped"></div>
              </div>
            </div>
            <a-card
              v-for="(item, index) of dataPayment"
              :key="`index-${index}`"
            >
              <div class="d-flex justify-content-between">
                <div>
                  <div class="d-flex">
                    <div class="text-active fs-title">x{{ item.quantity }}</div>
                    <div class="ml-3 fs-title">{{ item.campaign_name }}</div>
                  </div>
                  <div class="d-flex by">
                    <div>Campaign ID: {{ item.campaign_id }}</div>
                    <div class="ml-5">Serial: {{ item.serial }}</div>
                  </div>
                </div>
                <div class="text-active fs-title mt-4">
                  {{ item.original_price }}
                </div>
              </div>
            </a-card>
          </a-card>
        </div>
        <div class="col-3">
          <a-card>
            <div class="d-flex justify-content-between">
              <h4>Payment summary</h4>
              <div>Items: {{ dataByID.items }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Price:</div>
              <div>{{ dataByID.amount }}</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Discount:</div>
              <div>
                {{
                  dataByID.discount && dataByID.discount != 0
                    ? dataByID.discount
                    : '-'
                }}
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Payment Method:</div>
              <div>-</div>
            </div>
            <div class="d-flex justify-content-between">
              <div>Redeem Points:</div>
              <div>-</div>
            </div>
            <div class="d-flex justify-content-between mt-2">
              <h4>Total</h4>
              <h4 class="text-active">{{ dataByID.amount }}</h4>
            </div>
          </a-card>
        </div>
      </div>
      <div class="wrap-table-modal" @scroll="handleScrollModal">
        <VLoadingTable :value="overlaytablemodal" />
        <a-table
          row-key="partitionkey"
          class="fixed-head font-table-modal"
          :dataSource="dataPayment"
          :columns="paymentColumns"
          :pagination="false"
        />
      </div>
    </a-modal> -->
    <ExportModal
      :visible="showExportPaymentIdModal"
      @handleSubmit="handleExportPaymentIdModal($event)"
      @handleClose="showExportPaymentIdModal = false"
    ></ExportModal>
  </a-card>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import dayjs from 'dayjs'
// content components
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
// import DateFilter from '@/module/User/component/DateFilter.vue'
import FilterSearch from '@/components/Filter/FilterSearch.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import { PurchaseType } from '@/helper/StaticValue.js'
// import { CalendarOutlined } from '@ant-design/icons-vue'
import CacheHelper from '@/helper/CacheHelper.js'
import { config } from '@/config/bzbsconfig.js'

var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserPurchase',
  components: {
    DisplayFields,
    // DateFilter,
    ExportModal,
    PrepareModal,
    FilterSearch,
    // CalendarOutlined,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      type: 'list',
      isTimezone: config.businessRule.timeZone.hours,
      setperrmissionexport: true,
      showDisplayFields: false,
      overlaytablelist: false,
      overlaytablemodal: false,
      visible: false,
      modalPaymentId: '',
      modalPage: 1,
      purchasetype: Cache.getCache('app').purchase_type,
      dataPurchase: [],
      dataPayment: [],
      optionssearch: 'paymentid',
      statusdropdown: '',
      searchObject: {
        paymentid: '',
        status: '',
        channel: '',
        startdate: '',
        enddate: '',
      },
      options_search: [
        {
          label: 'Payment ID',
          value: 'paymentid',
        },
        {
          label: 'Channel',
          value: 'channel',
        },
      ],
      status_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting',
          value: 'Waiting',
        },
        {
          label: 'Paid',
          value: 'Paid',
        },
        {
          label: 'Void',
          value: 'Void',
        },
        {
          label: 'Complete',
          value: 'Complete',
        },
        {
          label: 'Expired',
          value: 'Expired',
        },
        {
          label: 'WaitingCod',
          value: 'WaitingCod',
        },
        {
          label: 'InvalidPayment',
          value: 'InvalidPayment',
        },
        {
          label: 'Refund',
          value: 'Refund',
        },
        {
          label: 'DeductBPoint',
          value: 'DeductBPoint',
        },
        {
          label: 'PendingCod',
          value: 'PendingCod',
        },
      ],
      optionlist: {
        status: true,
        daterange: true,
        options: [
          {
            label: 'All Status',
            value: '',
          },
          {
            label: 'Waiting',
            value: 'Waiting',
          },
          {
            label: 'Paid',
            value: 'Paid',
          },
          {
            label: 'Void',
            value: 'Void',
          },
          {
            label: 'Completed',
            value: 'Complete',
          },
          {
            label: 'Expired',
            value: 'Expired',
          },
          {
            label: 'Waiting COD',
            value: 'WaitingCod',
          },
          {
            label: 'Invalid Payment',
            value: 'InvalidPayment',
          },
          {
            label: 'Refund',
            value: 'Refund',
          },
          {
            label: 'Deduct B Point',
            value: 'DeductBPoint',
          },
          {
            label: 'Pending COD',
            value: 'PendingCod',
          },
        ],
      },
      search: '',
      params: {
        name: '',
        filter: {
          user_id: '',
          appid: '',
          first_name: '',
          last_name: '',
          address: '',
          channel: '',
          payment_status: null,
          date_filter_type: null,
          start_date: null,
          end_date: null,
          payment_id: null,
        },
      },
      paramsPaymenId: {
        name: '',
        description: '',
        filter: {
          tran_id: 0,
          appid: '',
        },
      },
      page: 1,
      columns: [
        {
          title: 'Payment ID',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'payment_id',
          slots: { customRender: 'payment_id' },
          key: 'payment_id',
          width: 140,
          align: 'left',
        },
        {
          title: 'Product Name',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'product_name',
          slots: { customRender: 'product_name' },
          key: 'product_name',
          width: 300,
          align: 'left',
        },
        {
          title: 'Items',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'items',
          slots: { customRender: 'items' },
          key: 'items',
          width: 80,
          align: 'right',
        },
        {
          title: 'Price',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'amount',
          key: 'amount',
          width: 120,
          align: 'right',
        },
        {
          title: 'Delivery Fee',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'shipping_amount',
          key: 'shipping_amount',
          width: 140,
          align: 'right',
        },
        {
          title: 'Discount',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'discount',
          key: 'discount',
          width: 120,
          align: 'right',
        },
        {
          title: 'Total',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'trans_amount',
          key: 'trans_amount',
          width: 120,
          align: 'right',
        },
        {
          title: 'Channel',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'channel',
          key: 'channel',
          width: 80,
          align: 'left',
        },
        {
          title: 'Purchase Date',
          purchaseTypes: [PurchaseType.Good],
          dataIndex: 'success_date',
          key: 'success_date',
          width: 180,
          align: 'left',
        },
        {
          title: 'Order Date',
          purchaseTypes: [PurchaseType.Food],
          dataIndex: 'success_date',
          key: 'success_date',
          width: 180,
          align: 'left',
        },
        {
          title: 'Status',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 80,
          align: 'left',
        },
        {
          title: 'Order ID',
          purchaseTypes: [PurchaseType.Food],
          dataIndex: 'order_id',
          key: 'order_id',
          width: 180,
          align: 'left',
        },
        {
          title: 'Order Type',
          purchaseTypes: [PurchaseType.Food],
          slots: { customRender: 'type' },
          dataIndex: 'type',
          key: 'type',
          width: 180,
          align: 'left',
        },
        // {
        //   title: 'Location',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'location',
        //   slots: { customRender: 'location' },
        //   key: 'location',
        //   width: 130,
        //   align: 'left',
        // },
        {
          title: 'Schedule',
          purchaseTypes: [PurchaseType.Food],
          dataIndex: 'schedule',
          slots: { customRender: 'schedule' },
          key: 'schedule',
          width: 180,
          align: 'left',
        },
        // {
        //   title: 'Type',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'type',
        //   key: 'type',
        //   width: 120,
        //   align: 'left',
        // },
        {
          title: 'Driver',
          purchaseTypes: [PurchaseType.Food],
          dataIndex: 'driver',
          slots: { customRender: 'driver' },
          key: 'driver',
          width: 180,
          align: 'left',
        },
        // {
        //   title: 'Pick Up',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'pick_up',
        //   slots: { customRender: 'pick_up' },
        //   key: 'pick_up',
        //   width: 120,
        //   align: 'left',
        // },
        // {
        //   title: 'Hub Code',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'hubcode',
        //   key: 'hubcode',
        //   width: 120,
        //   align: 'left',
        // },
        // {
        //   title: 'Cancelation',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'cancellation',
        //   key: 'cancellation',
        //   width: 120,
        //   align: 'left',
        // },

        // {
        //   title: 'Completed',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'complete',
        //   slots: { customRender: 'complete' },
        //   key: 'complete',
        //   width: 120,
        //   align: 'left',
        // },
        // {
        //   title: 'Reason',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'reason',
        //   key: 'reason',
        //   width: 120,
        //   align: 'left',
        // },
      ].filter(x =>
        x.purchaseTypes.includes(Cache.getCache('app').purchase_type),
      ),
      paymentColumns: [
        {
          title: 'Campaign',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'campaign_id',
          key: 'campaign_id',
          slots: { customRender: 'campaign_id' },
          width: 420,
          align: 'left',
        },
        {
          title: 'Agency',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'agency_name',
          slots: { customRender: 'agency' },
          key: 'agency_name',
          width: 300,
          align: 'left',
        },
        {
          title: 'Category',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'category',
          key: 'category',
          width: 200,
          align: 'left',
        },
        {
          title: 'Serial',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'serial',
          key: 'serial',
          width: 160,
          align: 'left',
        },
        {
          title: 'Quantity',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'quantity',
          key: 'quantity',
          width: 100,
          align: 'right',
        },
        {
          title: 'Set ID',
          purchaseTypes: [PurchaseType.Food],
          dataIndex: 'ref_set_id',
          key: 'ref_set_id',
          width: 120,
          align: 'left',
        },
        {
          title: 'Reference Main Campaign',
          purchaseTypes: [PurchaseType.Food],
          dataIndex: 'ref_main_campaign_id',
          key: 'ref_main_campaign_id',
          width: 200,
          align: 'left',
        },
        {
          title: 'Use Points',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'point',
          key: 'point',
          width: 140,
          align: 'right',
        },
        {
          title: 'Price',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'original_price',
          key: 'original_price',
          width: 100,
          align: 'right',
        },
        {
          title: 'Discount',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'discount',
          key: 'discount',
          width: 100,
          align: 'right',
        },
        {
          title: 'Total',
          purchaseTypes: [PurchaseType.Food, PurchaseType.Good],
          dataIndex: 'price_per_unit',
          key: 'price_per_unit',
          width: 100,
          align: 'right',
        },
        // {
        //   title: 'Delivered',
        //   purchaseTypes: [PurchaseType.Food],
        //   dataIndex: 'shipping_date',
        //   key: 'shipping_date',
        //   width: 100,
        // },
        {
          title: 'Shiping Status',
          purchaseTypes: [PurchaseType.Good],
          dataIndex: 'shipping_date',
          slots: { customRender: 'shipping_status' },
          key: 'shipping_date',
          width: 180,
          align: 'left',
        },
        {
          title: 'Delivery',
          purchaseTypes: [PurchaseType.Good],
          dataIndex: 'delivery',
          slots: { customRender: 'delivery' },
          key: 'delivery',
          width: 180,
          align: 'left',
        },
      ],
      isLoadmore: false,
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      showExportModal: false,
      showPrepareModal: false,
      showExportPaymentIdModal: false,
      showAdvaceSearch: false,
      checkActive: false,
      status: [
        {
          id: 0,
          name: 'Waiting',
          text: 'Waiting',
          color: 'default',
        },
        {
          id: 1,
          name: 'Paid',
          text: 'Paid',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Void',
          text: 'Void',
          color: 'red',
        },
        {
          id: 3,
          name: 'Complete',
          text: 'Completed',
          color: 'green',
        },
        {
          id: 4,
          name: 'Expired',
          text: 'Expired',
          color: 'red',
        },
        {
          id: 5,
          name: 'WaitingCod',
          text: 'Waiting COD',
          color: 'yellow',
        },
        {
          id: 6,
          name: 'InvalidPayment',
          text: 'Invalid Payment',
          color: 'red',
        },
        {
          id: 7,
          name: 'Refund',
          text: 'Refund',
          color: 'purple',
        },
        {
          id: 8,
          name: 'Deduct B Point',
          text: 'Deduct B Point',
          color: 'default',
        },
        {
          id: 9,
          name: 'PendingCod',
          text: 'Pending COD',
          color: 'yellow',
        },
        {
          id: 10,
          name: 'Waiting Subscription',
          text: 'Waiting Subscription',
          color: 'yellow',
        },
        {
          id: 11,
          name: 'Cancel',
          text: 'Cancel',
          color: 'red',
        },
      ],
      dataByID: [],
    }
  },
  beforeCreate() {},
  watch: {
    $route(to) {
      if (to.query.tab == 'purchase') {
        this.columns = this.columns.filter(x =>
          x.purchaseTypes.includes(this.purchasetype),
        )
        this.displayFields = _.cloneDeep(this.columns)
        this.showAdvaceSearch = false
        this.dataPurchase = []
        this.page = 1
        this.$refs.FilterSearch.resetFilter()
        this.checkActive = false
        this.applyFilter()
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'purchase') {
      this.displayFields = _.cloneDeep(this.columns)
      this.showAdvaceSearch = false
      this.applyFilter()
      this.$refs.FilterSearch.resetFilter()
      this.checkActive = false
      this.overlay = false
    }
  },
  created() {},
  methods: {
    enterFilter(key) {
      if (key.key == 'Enter' && this.showAdvaceSearch == false) {
        this.applyFilter()
      }
    },
    toggleAdvaceSearch() {
      this.showAdvaceSearch = !this.showAdvaceSearch
    },
    applyFilter(filter) {
      if (
        filter?.status != null ||
        (filter?.startdate?.length > 0 && filter?.enddate?.length > 0)
      ) {
        this.checkActive = true
      } else {
        this.checkActive = false
      }
      this.showAdvaceSearch = false
      this.$store.commit('setLoading', true)
      this.page = 1
      this.searchObject.paymentid =
        this.optionssearch == 'paymentid' ? this.search : ''
      this.searchObject.channel =
        this.optionssearch == 'channel' ? this.search : ''
      this.searchObject.status = filter?.status ? filter?.status : ''
      this.searchObject.startdate = filter?.startdate
        ? dayjs(filter?.startdate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.searchObject.enddate = filter?.enddate
        ? dayjs(filter?.enddate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.showDateFilter = false
      this.callAPiReportPurchase().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    callAPiReportPurchase() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserPurchase(
          this.$route.params.id,
          this.page,
          this.searchObject.paymentid,
          this.searchObject.channel,
          this.searchObject.status,
          this.searchObject.startdate,
          this.searchObject.enddate,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataPurchase = []
            }
            _.forEach(data.result, item => {
              item.description = item.description ? item.description : '-'
              item.useddate = moment(item.timestamp).format(this.formatdate)
              // item.success_date = moment(item.success_date).format(this.formatdate);
              item.hubcode = item.hubcode ? item.hubcode : '-'
              item.type = item.type ? item.type : '-'
              item.order_id = item.order_id ? item.order_id : '-'
              item.success_date = item.success_date
                ? (item.success_date = moment(item.success_date)
                    .add(this.isTimezone, 'hours')
                    .format('DD/MMM/YYYY - HH:mm'))
                : '-'
              item.cancellation = item.cancellation ? item.cancellation : '-'
              item.order_id = item.order_id ? item.order_id : '-'
              item.order_plan = item.order_plan ? item.order_plan : '-'
              item.reason = item.reason ? item.reason : '-'
              item.amount = item.amount.toLocaleString('en-US')
              item.discount = item.discount.toLocaleString('en-US')
              item.items = item.items.toLocaleString('en-US')
              item.shipping_amount =
                item.shipping_amount.toLocaleString('en-US')
              item.trans_amount = item.trans_amount.toLocaleString('en-US')
              this.dataPurchase.push(item)
            })
            if (data.result.length < 10) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataPurchase)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    callAPiExportPurchase(params) {
      // this.params.filename = params.filename;
      this.params.description = params.description
      this.params.name = params.filename
      this.params.filter.appid = ConfigManager.getAppId()
      this.params.filter.user_id = this.$route.params.id
      ;(this.params.filter.payment_id = this.searchObject.paymentid
        ? this.searchObject.paymentid
        : null),
        (this.params.filter.channel = this.searchObject.channel),
        (this.params.filter.payment_status = this.searchObject.status
          ? this.searchObject.status
          : null),
        (this.params.filter.start_date = this.searchObject.startdate
          ? this.searchObject.startdate
          : null),
        (this.params.filter.end_date = this.searchObject.enddate
          ? this.searchObject.enddate
          : null),
        // this.params.filter.app_id = ConfigManager.getAppId();
        (this.params.filter.user_id = this.$route.params.id)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostPurchaseExport(this.params)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytablelist) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytablelist = true
          this.page++
          this.callAPiReportPurchase().then(() => {
            this.overlaytablelist = false
          })
        }
      }
    },
    handleScrollModal(event) {
      if (!this.nomoremodal && !this.overlaytablemodal && this.isLoadmore) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytablemodal = true
          this.modalPage++
          this.loadmoremodal().then(() => {
            this.overlaytablemodal = false
          })
        }
      }
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    exportReport() {
      this.showExportModal = true
    },
    exportReportPaymentId() {
      this.showExportPaymentIdModal = true
    },
    handleExportModal(value) {
      var params = {
        filename: value.filename,
        description: value.description,
      }
      this.callAPiExportPurchase(params).then(() => {
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    handleExportPaymentIdModal(value) {
      this.paramsPaymenId.name = value.filename
      this.paramsPaymenId.description = value.description
      this.paramsPaymenId.filter.appid = ConfigManager.getAppId()
      this.paramsPaymenId.filter.tran_id = this.modalPaymentId

      new Promise((resolve, reject) => {
        BzbsUser.apiPostPurchasePaymentIdExport(this.paramsPaymenId)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      }).then(() => {
        this.showExportPaymentIdModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    showModal(paymentId, dataByID) {
      this.isLoadmore = false
      this.modalPaymentId = paymentId
      this.dataByID = dataByID
      this.paymentColumns = this.paymentColumns.filter(x =>
        x.purchaseTypes.includes(this.purchasetype),
      )
      this.modalPage = 1
      this.dataPayment = []
      this.loadmoremodal().then(() => {
        this.isLoadmore = true
        this.visible = true
      })
    },
    loadmoremodal() {
      return new Promise(resolve => {
        BzbsUser.apiGetPurchasePaymentId(
          // this.$route.params.id,
          this.modalPaymentId,
          this.modalPage,
          this.appid,
        ).subscribe(data => {
          if (this.modalPage == 1) {
            this.dataPayment = []
          }
          _.forEach(data.result, item => {
            item.description = item.description ? item.description : '-'
            item.parcel_no = item.parcel_no ? item.parcel_no : '-'
            item.shipping_date = item.shipping_date ? item.shipping_date : '-'
            item.ref_set_id = item.ref_set_id ? item.ref_set_id : '-'
            item.ref_main_campaign_id = item.ref_main_campaign_id
              ? item.ref_main_campaign_id
              : '-'
            this.dataPayment.push(item)
          })
          if (data.result.length < 10) {
            this.nomoremodal = true
          } else {
            this.nomoremodal = false
          }
          resolve(this.dataPayment)
        })
      })
    },
    handleCancel() {
      this.dataPayment = []
      this.visible = false
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.nav-search {
  display: block;
  width: 100%;
  border-bottom: 2px solid $color-grey-line;
  padding-bottom: 15px;

  .search {
    .input-search {
      width: 200px;
      border-left: none;
    }

    .select-search {
      width: 130px;
    }
  }
}

.wrap-table {
  display: block;
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 340px);

  .ant-table-wrapper {
    min-width: 1980px !important;
    width: 100%;
  }
}
.wrap-table-good {
  display: block;
  width: 100%;
  overflow-x: auto;
  height: calc(100vh - 340px);

  .ant-table-wrapper {
    min-width: 1340px !important;
    width: 100%;
  }
}
.wrap-table-modal {
  display: block;
  width: 100%;
  height: 400px;
  overflow-x: auto;

  .ant-table-wrapper {
    min-width: 1400px;
  }
}

.font-paymentid {
  color: #f9a601;
  text-decoration: underline;
}

.filter-select {
  width: 300px;
}
.timeline {
  margin: 20px 0px 40px 0px;
  display: flex;
  .tracking {
    padding-top: 15px;
    text-align: -webkit-center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    background-color: #fff6e6;
  }
  .tracking-active {
    padding-top: 10px;
    text-align: -webkit-center;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    border: 2px solid #ffe5b3;
    background-color: #f9a601;
  }
  .tracking-line {
    width: 200px;
    height: 2px;
    background-color: #f9a601;
    margin-top: 30px;
  }
  .date-shipped {
    margin-left: -85px;
    color: #969696;
  }
}
</style>
<style lang="scss">
.fixed-head .ant-table-thead > tr > th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.title-modal {
  margin-top: 5px;
  color: #666666;
  font-size: 15px;
  font-weight: 500;
}
.purchase {
  .ant-table-row {
    vertical-align: top !important;
  }
}
.fs-ori {
  font-weight: 400;
}
.fs-title {
  font-weight: 500;
}
.modal-tablelist {
  .ant-table-row {
    vertical-align: top !important;
  }
}
</style>
