<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <div class="userlevel d-flex justify-content-between">
        <TitleBreadcrumbs class="mt-4" title="User Level" />
        <div class="mt-35">
          <a-button @click="toggleDisplayFieldsLogLevel()">Change Log</a-button>
        </div>
      </div>
    </template>
    <div class="userlevel overflow-x-hidden flex flex-col">
      <div class="flex-1 w-full px-4">
        <div class="mb-4 b-6 bg-white pl-4 pr-4">
          <div class="py-2 d-flex justify-content-between">
            <a-input
              v-model:value="search"
              class="advanced-search"
              placeholder="Search Level"
              @pressEnter="clicksearch()"
            >
              <template #prefix>
                <a @click="clicksearch()">
                  <search-outlined />
                </a>
              </template>
            </a-input>
            <!-- <div class="d-flex">
              <a-input
                v-model:value="search"
                placeholder="Search Level"
                class="input-search"
              >
              </a-input>
              <a-button
                type="primary"
                class="btn-search ml-3"
                @click="clicksearch"
                ><search-outlined /> Search</a-button
              >
            </div> -->
            <div class="d-flex">
              <a-tooltip placement="bottom">
                <template #title>
                  <div>Create Level</div>
                </template>
                <a-button
                  v-if="datapermissionbyapp.includes('User-Level-Adjust')"
                  type="primary"
                  @click="toggleDisplayFieldsCreateNew"
                  ><plus-outlined /> New Level
                </a-button>
              </a-tooltip>
            </div>
          </div>
        </div>
        <div class="table-scroll">
          <div class="wrap-table-level">
            <VLoadingTable :value="overlaytable" />
            <a-table
              row-key="id"
              class="fixed-head"
              :dataSource="dataLevel"
              :columns="columns"
              :pagination="false"
            >
              <template #level="{ text, record }">
                <a
                  v-if="datapermissionbyapp.includes('User-Level-Adjust')"
                  class="font-level"
                  @click="toggleDisplayFieldsUpdateLevel(record)"
                  >{{ text }}</a
                >
                <div v-else>{{ text }}</div>
              </template>
              <template #total="{ record }">
                <div class="font-bold">
                  {{ record.total ? record.total : '-' }}
                </div>
              </template>
              <template #description="{ record }">
                <div v-html="textHTML(record.description)"></div>
              </template>
              <template #how_to_earn="{ record }">
                <div v-html="textHTML(record.mission)"></div>
              </template>
              <template #benefits="{ record }">
                <div v-html="textHTML(record.benefits)"></div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- <div class="flex-none">
      <Footer />
    </div> -->
      <DisplayFieldsCreateNew
        :value="showDisplayFieldsCreateNew"
        @getlevel="getlevel($event)"
        @handleClose="showDisplayFieldsCreateNew = false"
      >
      </DisplayFieldsCreateNew>
      <DisplayFieldsUpdateLevel
        :value="showDisplayFieldsUpdateLevel"
        :datalevel="dataLevel"
        :datalevelname="dataLevelname"
        :disablesubmit="disable"
        @getlevel="getlevel($event)"
        @handleClose="showDisplayFieldsUpdateLevel = false"
      >
      </DisplayFieldsUpdateLevel>
      <DisplayFieldsLogLevel
        :value="showDisplayFieldsLogLevel"
        :datalogslevel="datalogslevel"
        @handleClose="showDisplayFieldsLogLevel = false"
      >
      </DisplayFieldsLogLevel>
    </div>
  </AppLayout>
</template>
<script>
// import TopBar from '@/components/Layout/TopBar/TopBar.vue'
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFieldsCreateNew from '@/module/User/UserLevel/component/DisplayFieldsCreateNew.vue'
import DisplayFieldsLogLevel from '@/module/User/UserLevel/component/DisplayFieldsLogLevel.vue'
import DisplayFieldsUpdateLevel from '@/module/User/UserLevel/component/DisplayFieldsUpdateLevel.vue'
import { PlusOutlined } from '@ant-design/icons-vue'
import Utility from '@/helper/Utility.js'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    // Footer,
    DisplayFieldsCreateNew,
    DisplayFieldsLogLevel,
    DisplayFieldsUpdateLevel,
    PlusOutlined,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name: null,
          breadcrumbName: 'User Level',
        },
      ],
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      disable: true,
      overlaytablelist: false,
      page: 1,
      search: '',
      showDisplayFieldsCreateNew: false,
      showDisplayFieldsLogLevel: false,
      showDisplayFieldsUpdateLevel: false,
      searchObject: {
        Level: '',
      },
      columns: [
        {
          title: 'Level',
          dataIndex: 'level',
          slots: { customRender: 'level' },
          key: 'level',
          width: 120,
          align: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: 250,
          align: 'left',
        },
        {
          title: 'Total Users',
          dataIndex: 'total',
          slots: { customRender: 'total' },
          key: 'total',
          width: 120,
          align: 'right',
        },
        {
          title: 'How to earn points',
          dataIndex: 'how_to_earn',
          slots: { customRender: 'how_to_earn' },
          key: 'how_to_earn',
          width: 250,
          align: 'left',
        },
        {
          title: 'Benefits',
          dataIndex: 'benefits',
          slots: { customRender: 'benefits' },
          key: 'benefits',
          width: 250,
          align: 'left',
        },
      ],
      dataLevel: [],
      dataLevelname: '',
      permission: [],
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'User Level',
        },
      ]
    },
  },
  mounted() {
    this.clicksearch()
  },
  methods: {
    textHTML(text) {
      return Utility.newLineToBr(text)
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.getdatauserlevel().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getdatauserlevel() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetDataUserlevelRoleAdmin(this.search, this.page).subscribe(
          res => {
            this.dataLevel = res.result.map(x => ({
              key: x.level_id,
              level: x.level_name,
              benefits: x.benefits ? x.benefits : '-',
              mission: x.mission ? x.mission : '-',
              description: x.description ? x.description : '-',
              total: x.total_users.toLocaleString('en-US'),
              expiredon: x.description ? x.description : '-',
            }))
            this.$store.commit('setLoading', false)
            resolve(this.dataLevel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getloghistory() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetLogHistoryUserlevelRoleAdmin().subscribe(
          res => {
            this.datalogslevel = res.result
            _.forEach(res.result, item => {
              item.new_data = JSON.parse(item.new_data)
              item.original_data = JSON.parse(item.original_data)
              item.log_message = item.log_message
                ? item.log_message.replaceAll('\r\n', '<br/>')
                : item.log_message
            })
            resolve(this.datalogslevel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getdatauserlevel().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getlevel() {
      this.clicksearch()
      this.getloghistory()
    },
    toggleDisplayFieldsCreateNew() {
      this.showDisplayFieldsCreateNew = !this.showDisplayFieldsCreateNew
    },
    toggleDisplayFieldsLogLevel() {
      this.getloghistory().then(() => {
        this.showDisplayFieldsLogLevel = !this.showDisplayFieldsLogLevel
      })
    },
    toggleDisplayFieldsUpdateLevel(level_name) {
      this.dataLevelname = level_name
      this.showDisplayFieldsUpdateLevel = !this.showDisplayFieldsUpdateLevel
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.userlevel {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 250px);
    max-height: calc(100vh - 250px);
  }

  .nav-search {
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $color-grey-line;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 240px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 248px);
    overflow-x: auto;
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.userlevel {
  .ant-table-row {
    vertical-align: top !important;
  }
  .mt-35 {
    margin-top: 30px;
  }
}
</style>
