import { createRouter, createWebHistory } from 'vue-router'

// import Default from '@/module/Home/page/Default.vue';
import Notfound from '@/module/Static/Notfound/page/Notfound.vue'

//#region login
import Login from '@/module/Auth/pages/Login.vue'
import Logout from '@/module/Auth/pages/Logout.vue'
import RedirectMS from '@/module/Auth/pages/RedirectMS'
import AuthUnauthorized from '@/module/Auth/pages/AuthUnauthorized'
import AuthUnauthorizedView from '@/module/Auth/pages/UnauthorizedView'
import SelectAppication from '@/module/Application/pages/SelectAppication'
import Joblist from '@/module/CallCenter/pages/CallCenterManagement/Joblist'
import ManageSubject from '@/module/CallCenterSetting/pages/ManageSubject'
import DownloadMangeCallcenter from '@/module/CallCenter/pages/CallCenterManagement/Donwload'
import CallCenterManagement from '@/module/CallCenter/pages/CallCenterManagement/CallCenterManagement'
import SearchUser from '@/module/CallCenter/pages/SearchUser'
import ApprovelistCallcenter from '@/module/CallCenter/pages/ApproveList'
import TicketManagement from '@/module/CallCenter/pages/TicketManagement/TicketManagement'
import Chanel from '@/module/CallCenterSetting/pages/Channel'
import OTPLog from '@/module/LogOTP/page/OTPLog'

import Test from '@/module/Test/page/Test.vue'
// import LayoutLeftMenu from '@/module/Layout/LeftMenu/page/LayoutLeftMenu.vue'
import UserList from '@/module/User/UserList/page/UserList.vue'
import UserListRealtime from '@/module/User/UserList/page/UserListRealtime.vue'
import UserLevel from '@/module/User/UserLevel/page/UserLevel.vue'
import UserFlag from '@/module/User/UserFlag/page/UserFlag.vue'
import UserTag from '@/module/User/UserTags/page/UserTagGroup.vue'
import UserDetail from '@/module/User/UserDetail/page/UserDetail.vue'
import DownloadMange from '@/module/Downloadmanage/pages/Donwload'
import ApprovePoint from '@/module/Setting/ApproveList/pages/ApproveList'
import ApproveReceipDetail from '@/module/Setting/ApproveList/pages/ApproveReceiptView'
import ApproveSurveyDetail from '@/module/Setting/ApproveList/pages/ApproveSurveyDetail'
import ConfigDatabase from '@/module/Setting/ConfigDatabase/pages/ConfigDatabase'
import HelpChat from '@/module/HelpChat/pages/HelpChat'
import Import from '@/module/Import/pages/Import'
import SearchSerial from '@/module/SearchSerial/pages/SearchSerial'
import FAQ from '@/module/FAQ/pages/FAQ'

import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import Account from '@/helper/AccountHelper'

import Notfindconfig from '@/module/PageNot/NoticonFig'

const routes = [
  {
    path: '/loginad',
    redirect: '/',
  },
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: requireAuth,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
  {
    path: '/redirectms',
    name: 'redirect-ms',
    component: RedirectMS,
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: AuthUnauthorized,
  },
  {
    path: '/unauthorizedview',
    name: 'unauthorizedview',
    component: AuthUnauthorizedView,
  },
  {
    path: '/loginSuccess',
    redirect: '/application',
  },
  {
    path: '/joblist',
    name: 'joblist',
    component: Joblist,
    beforeEnter: requireAuth,
  },
  {
    path: '/downloadcenter-callcenter',
    name: 'downloadcenter-callcenter',
    component: DownloadMangeCallcenter,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/managesubject',
    name: 'managesubject',
    component: ManageSubject,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/chanel',
    name: 'chanel',
    component: Chanel,
    beforeEnter: requireAuth,
  },
  {
    path: '/searchserial',
    name: 'searchserial',
    component: SearchSerial,
    beforeEnter: requireAuth,
  },
  {
     path: '/searchuser',
    name: 'searchuser',
    component: SearchUser,
    beforeEnter: requireAuth,
  },
    {
     path: '/approvelist-callcenter',
    name: 'approvelist-callcenter',
    component: ApprovelistCallcenter,
    beforeEnter: requireAuth,
  },
  {
    path: '/callcentermanagement',
    name: 'callcentermanagement',
    component: CallCenterManagement,
    beforeEnter: requireAuth,
  },
  {
    path: '/ticketmanagement',
    name: 'ticketmanagement',
    component: TicketManagement,
    beforeEnter: requireAuth,
  },
  {
    path: '/select-applications',
    name: 'select_application',
    component: SelectAppication,
    beforeEnter: requireAuth,
  },
  {
    path: '/test',
    name: 'test',
    component: Test,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/list',
    name: 'list',
    component: UserList,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/listrealtime',
    name: 'listrealtime',
    component: UserListRealtime,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/list/user/:id',
    name: 'user_detail',
    component: UserDetail,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/level',
    name: 'level',
    component: UserLevel,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/userflag',
    name: 'userflag',
    component: UserFlag,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/usertag',
    name: 'user_tag',
    component: UserTag,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/download',
    name: 'download',
    component: DownloadMange,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/approvelist',
    name: 'approvelist',
    component: ApprovePoint,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/approvereceiptdetail',
    name: 'approvereceiptdetail',
    component: ApproveReceipDetail,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/approvesurveydetail',
    name: 'approvesurveydetail',
    component: ApproveSurveyDetail,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/otplog',
    name: 'otplog',
    component: OTPLog,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/configdatabase',
    name: 'configdatabase',
    component: ConfigDatabase,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/helpchat',
    name: 'helpchat',
    component: HelpChat,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/faq',
    name: 'faq',
    component: FAQ,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/searchserial',
    name: 'searchserial',
    component: SearchSerial,
    beforeEnter: requireAuth,
  },
  {
    path: '/application/import',
    name: 'import',
    component: Import,
    beforeEnter: requireAuth,
  },
  {
    path: '/notfindconfig',
    name: 'Notfindconfig',
    component: Notfindconfig,
    beforeEnter: requireAuth,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Notfound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
function requireAuth(to, from, next) {
  var Cache = new CacheHelper(ConfigManager.getCacheType())
  console.log(to.name)
  if (
    to.name != 'redirect-ms' &&
    to.name != 'login' &&
    Cache.getCache('logout')
  ) {
    next('/logout')
  }
  if (Cache.getCache('msToken')) {
    if (to.path == '/') {
      if (Cache.getCache('app')) {
        Account.getMSMenu2(Cache.getCache('app').id).then(() => {
          Account.setDefaultMenu()
        })
      } else {
        next('/select-applications')
      }
    } else {
      next()
    }
  } else {
    if (to.name == 'redirect-ms' || to.name == 'login') {
      next()
    } else {
      next('/logout')
    }
  }
}
export default router
