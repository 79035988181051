const apiConfig = {
  b2cScopes: [
    'https://devaadb2cbuzzebees.onmicrosoft.com/2ea889c1-9d22-40de-83a9-b693466a5149/Access',
  ],
  // webApi: "https://localhost:44332/api/check"
}
const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    editProfile: 'B2C_1A_PROFILE_EDIT',
    changePassword: 'B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
    },
    editProfile: {
      authority:
        'https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT',
    },
    changePassword: {
      authority:
        'https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
    },
  },
  // names: {
  //     signUpSignIn: "B2C_1_susi",
  //     editProfile: "B2C_1_pe",
  // },
  // authorities: {
  //     signUpSignIn: {
  //         authority:
  //             "https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1_susi",
  //     },
  //     editProfile: {
  //         authority:
  //             "https://devaadb2cbuzzebees.b2clogin.com/devaadb2cbuzzebees.onmicrosoft.com/B2C_1_pe",
  //     },
  // },
  authorityDomain: 'devaadb2cbuzzebees.b2clogin.com',
}
export const config = {
  debug: false, //enable console.log

  // ------------- Development Settings ----------------
  api: 'dev',
  adB2CUrl:
    'https://dev-api-active-directory-b2c-wrapper.azurewebsites.net/ppperm',
  bzbUrl: 'https://dev.buzzebees.com',
  bzbBlobUrl: 'https://devproductization.blob.core.windows.net',
  usermanagementAPI: 'https://api-usermanagement.buzzebees-dev.com',
  usermgrUrl: 'https://dev-bzbs-usermgr-userlist.azurewebsites.net',
  bzbsModuleUrl: 'https://dev-module-app.azurewebsites.net',
  apiShoppingCartUrl: 'https://devservice.buzzebees.com/modules/shoppingcart',
  bzbsShoppingCartUrl: 'https://dev-web-shoppingcart.azurewebsites.net',
  bzbsBlobUrl: 'https://devproductplatformext.blob.core.windows.net',
  miscUrl: 'https://devmisc.buzzebees.com',
  MKTengineUrl: 'https://dev-web-marketingengine.azurewebsites.net',
  CampaignManagementUrl: 'https://campaign-management.buzzebees-dev.com',
  bzbUrlCampaignManagement: 'https://campaign-management.buzzebees-dev.com',
  UsermanagementUrl: 'https://end-users-management.buzzebees-dev.com',
  bzbUrlRuleBasedEngine: 'https://rule-based-engine.buzzebees-dev.com',
  UsermanualUrl: ' https://dev-wordpress-sdkdocument.azurewebsites.net',
  apiAnnouncement: 'https://dev-api-product-announcement.azurewebsites.net',
  bzbsGaId: '',
  liffId: '',
  // ------------------ End Settings ---------------------
  fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
  fbPermissions: ['email'],
  firebase: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  msalConfig: {
    auth: {
      clientId: '8ac891c5-9ee6-4245-91f3-6e2c9b89a327',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: window.location.origin + '/redirectms',
      postLogoutRedirectUri: window.location.origin,
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      allowRedirectInIframe: true,
    },
  },
  b2cPolicies: b2cPolicies,
  loginRequest: {
    scopes: ['openid', ...apiConfig.b2cScopes],
    // prompt: 'select_account',
  },
  tokenRequest: {
    scopes: [...apiConfig.b2cScopes],
    forceRefresh: false,
  },
  businessRule: {
    webFor: {
      TH: true, //Thailand
      VN: false, //Vietnam
    },
    ticket: {
      problem_type_id: 107,
      channel: 4,
    },
    timeZone: {
      hours: 7, //Th
    },
  },
}
