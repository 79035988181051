<template>
  <a-card class="w-full min-h-full user-badge dataprivacy">
    <div class="mb-4 text-webkit-center">
      <a-radio-group v-model:value="tabs">
        <a-radio-button value="mission">Mission</a-radio-button>
        <a-radio-button value="badge">Badge</a-radio-button>
      </a-radio-group>
    </div>
    <hr>
    <div v-if="tabs == 'mission'" class="mission">
      <Mission :dataapp="datapermission"/>
    </div>
    <div v-if="tabs == 'badge'">
      <Badge :dataapp="datapermission"/>
    </div>
  </a-card>
</template>
<script>
import _ from 'lodash'
import Badge from '@/module/User/UserDetail/component/UserBadge/Badge.vue'
import Mission from '@/module/User/UserDetail/component/UserBadge/Mission.vue'
// content components

export default {
  name: 'UserBadge',
  components: {
    Badge,
    Mission
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      tabs:'mission',
      datapermission: this.dataapp
    }
  },
  beforeCreate() {
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'badge') {
        this.displayFields = _.cloneDeep(this.columns)
        // this.callAPiReportRedeem().then(() => {
        //   this.$store.commit('setLoading', false)
        // })
        this.$store.commit('setLoading', false)
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'badge') {
      this.displayFields = _.cloneDeep(this.columns)
      // this.clicksearch()
      this.overlay = false
    }
  },
  created() {
  },
  methods: {
    setpermissionexport() {
      if (
        this.dataapp.features.every(x => x.name != 'User-List-Redeem-Export')
      ) {
        return (this.setperrmissionexport = false)
      }
    },
    showModalMissions(){
      this.missions_modal = true
    },
    handleCancel(){
      this.missions_modal = false
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }
}
.select-search {
  width: 150px !important;
}
.iswinner {
  font-size: 12px;
  .not-yet {
    color: #666666;
  }
  .winner {
    color: #2ec743;
  }
  .not-winner {
    color: #fba45b;
  }
}
.isactive{
  width: 10px;
  height: 10px;
  background-color: #22ba20;
  border-radius: 40px;
}
</style>
<style lang="scss">
.user-badge{
  .ant-table-row {
    vertical-align: top !important;
  }
}
</style>