<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-advancesearch bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Advance Search</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto p-4">
            <a-form
              layout="vertical"
              :model="formState"
              v-bind="formItemLayout"
            >
              <a-form-item
                v-if="formState.ref_id"
                label="Application"
                :rules="[
                  {
                    required: true,
                  },
                ]"
              >
                <a-select
                  v-model:value="formState.application"
                  placeholder="Select application"
                  :options="application_option"
                  :allowClear="true"
                  show-search
                ></a-select>
              </a-form-item>
              <a-form-item label="Contact Number">
                <a-input
                  v-model:value="formState.contact_number"
                  placeholder="Contact Number"
                  @change="handleInputContactNumber($event)"
                >
                  <template #prefix>
                    <img
                      class="left"
                      src="@/assets/icons/icon-mobile.svg"
                      alt
                    />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="Name">
                <a-input
                  v-model:value="formState.name"
                  placeholder="Contact Name"
                >
                  <template #prefix>
                    <user-outlined type="user" />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="Payment ID">
                <a-input
                  v-model:value="formState.payment_id"
                  placeholder="Payment ID"
                  @input="handleInputPaymentID($event)"
                >
                  <template #prefix>
                    <img class="left" src="@/assets/icons/icon-id.svg" alt />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="Help ID">
                <a-input
                  v-model:value="formState.help_id"
                  placeholder="Help ID"
                  @input="handleInputHelpID($event)"
                >
                  <template #prefix>
                    <img class="left" src="@/assets/icons/icon-id.svg" alt />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="Email">
                <a-input v-model:value="formState.email" placeholder="Email">
                  <template #prefix>
                    <img class="left" src="@/assets/icons/icon-id.svg" alt />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="User ID">
                <a-input
                  v-model:value="formState.user_id"
                  placeholder="User ID"
                >
                  <template #prefix>
                    <img class="left" src="@/assets/icons/icon-id.svg" alt />
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item label="Reference ID">
                <a-input
                  v-model:value="formState.ref_id"
                  placeholder="Reference ID"
                  @change="handleInputRefID()"
                >
                  <template #prefix>
                    <img class="left" src="@/assets/icons/icon-id.svg" alt />
                  </template>
                </a-input>
              </a-form-item>
            </a-form>
          </div>
          <div class="d-flex justify-content-between px-4 py-3">
            <div class="mt-2">
              <a class="link" @click="clearSearch">Clear</a>
            </div>
            <div class="d-flex">
              <a-button type="default" @click="dismiss(true)">
                Cancel
              </a-button>
              <a-button
                class="ml-3"
                type="primary"
                :disabled="check(formState)"
                @click="searchAdvance"
              >
                Search
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
// import _ from 'lodash'
// import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
    application_option: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      application: null,
      options_channel: [],
      dataChannel: [],
      options_subject: [],
      dataSubject: [],
      options_priority: [
        {
          label: 'Critical',
          value: 'Critical',
        },
        {
          label: 'High',
          value: 'High',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'Low',
          value: 'Low',
        },
      ],
      options_status: [
        {
          label: 'Open',
          value: 'Open',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
        {
          label: 'Cancel',
          value: 'Cancel',
        },
        {
          label: 'Close',
          value: 'Close',
        },
      ],
      option_topictype: [],
      idSubject: '',
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    handleInputRefID() {
      if (this.formState.ref_id == '') {
        this.formState.application = ''
      }
    },
    handleInputContactNumber(event) {
      this.formState.contact_number = event.target.value.replace(/[^0-9]/g, '')
    },
    handleInputPaymentID(event) {
      this.formState.payment_id = event.target.value.replace(/[^0-9]/g, '')
    },
    handleInputHelpID(event) {
      this.formState.help_id = event.target.value.replace(/[^0-9]/g, '')
    },
    check(obj) {
      if (Object.keys(obj).length === 0) {
        return true
      } else if (
        !this.formState.name &&
        !this.formState.contact_number &&
        !this.formState.payment_id &&
        !this.formState.email &&
        !this.formState.help_id &&
        !this.formState.ref_id &&
        !this.formState.user_id
      ) {
        return true
      } else if (this.formState.ref_id && !this.formState.application) {
        return true
      } else {
        return false
      }
    },
    clearSearch() {
      this.formState = {}
    },
    searchAdvance() {
      this.$emit('searchAdvance', this.formState)
    },
    dismiss: function () {
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log-advancesearch {
  width: 30rem;
  .ant-input-affix-wrapper {
    width: 100% !important;
  }
}
</style>
