<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-advancesearch bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Advance Search</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto p-4">
            <a-form
              layout="vertical"
              :model="formState"
              v-bind="formItemLayout"
            >
              <a-form-item
                label="Application"
                :rules="[
                  {
                    required: true,
                  },
                ]"
              >
                <a-select
                  v-model:value="formState.application"
                  placeholder="Select application"
                  :options="application_option"
                  :allowClear="true"
                  show-search
                  @change="selectApp(formState.application)"
                ></a-select>
              </a-form-item>
              <a-form-item label="Create Date">
                <a-date-picker
                  style="width: 365px"
                  v-model:value="formState.created_date"
                  placeholder="Create Date"
                  :showToday="false"
                  :disabled-date="disabledDate"
                />
              </a-form-item>
              <a-form-item label="Create By">
                <a-input
                  v-model:value="formState.created_by"
                  placeholder="Create By"
                />
              </a-form-item>
              <a-form-item label="Channel">
                <a-select
                  v-model:value="formState.channel"
                  placeholder="Select channel"
                  :options="options_channel"
                  :allowClear="true"
                ></a-select>
              </a-form-item>
              <a-form-item label="Subject">
                <a-select
                  v-model:value="formState.subject"
                  placeholder="Select subject"
                  :options="options_subject"
                  :allowClear="true"
                  @change="selectSubject(formState.subject)"
                ></a-select>
              </a-form-item>
              <a-form-item label="Topic Type">
                <a-select
                  v-model:value="formState.topic_type"
                  placeholder="Select topic type"
                  :options="option_topictype"
                  :allowClear="true"
                ></a-select>
              </a-form-item>
              <a-form-item label="Ticket ID">
                <a-input
                  v-model:value="formState.ticket_id"
                  placeholder="Search ticket ID"
                ></a-input>
              </a-form-item>
              <a-form-item label="Priority">
                <a-select
                  v-model:value="formState.priority"
                  placeholder="All priority"
                  :options="options_priority"
                  :allowClear="true"
                ></a-select>
              </a-form-item>
              <a-form-item label="Status">
                <a-select
                  v-model:value="formState.status"
                  placeholder="All status"
                  :options="options_status"
                  :allowClear="true"
                ></a-select>
              </a-form-item>
            </a-form>
          </div>
          <div class="d-flex justify-content-between px-4 py-3">
            <div class="mt-2">
              <a class="link" @click="clearSearch">Clear</a>
            </div>
            <div class="d-flex">
              <a-button type="default" @click="dismiss(true)">
                Cancel
              </a-button>
              <a-button
                class="ml-3"
                type="primary"
                :disabled="!formState.application"
                @click="searchAdvance"
              >
                Search
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
// import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
    application_option: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      application: null,
      options_channel: [],
      dataChannel: [],
      options_subject: [],
      dataSubject: [],
      options_priority: [
        {
          label: 'Critical',
          value: 'Critical',
        },
        {
          label: 'High',
          value: 'High',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'Low',
          value: 'Low',
        },
      ],
      options_status: [
        {
          label: 'Open',
          value: 'Open',
        },
        {
          label: 'Pending',
          value: 'Pending',
        },
        {
          label: 'Cancel',
          value: 'Cancel',
        },
        {
          label: 'Close',
          value: 'Close',
        },
      ],
      option_topictype: [],
      idSubject: '',
    }
  },
  watch: {},
  mounted() {},
  created: function () {
    this.application_option.filter(x => x.value == 'All')
  },
  methods: {
    disabledDate(startValue) {
      var date = new Date()
      return startValue.valueOf() > date.setDate(date.getDate()).valueOf()
    },
    selectApp(app) {
      this.subject = null
      this.channel = null
      this.options_subject = []
      this.options_channel = []
      if (app) {
        this.getChannel(app)
        this.getSubject(app)
      }
    },
    clearSearch() {
      this.formState = {}
    },
    getSubject(appid) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSubjectForCreateTicket(
          appid.split('|')[0],
          true,
        ).subscribe(
          res => {
            this.dataSubject = res.result
            this.options_subject = []
            this.options_subject = this.dataSubject.items
              .filter(x => x.parent_problem_type_id == 0 && x.is_show)
              .map((item, index) => {
                return {
                  label: item.type,
                  value: item.type,
                  key: index,
                  id: item.id,
                }
              })
            resolve(this.dataSubject)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    selectSubject(subject) {
      this.formState.topic_type = ''
      this.option_topictype = []
      var itemtag = this.dataSubject.items.find(x => x.type == subject)
      this.idSubject = itemtag.id
      var itemtype = this.dataSubject.items.filter(
        x => x.parent_problem_type_id == itemtag.id,
      )
      if (itemtype) {
        this.option_topictype = itemtype
          .filter(x => x.is_show)
          .map((item, index) => {
            return {
              label: item.type,
              value: item.type,
              key: index,
            }
          })
      }
    },
    getChannel(appid) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetChannelJoblist(appid.split('|')[0]).subscribe(
          res => {
            this.dataChannel = res.result
            this.options_channel = []
            this.options_channel = this.dataChannel.items.map((item, index) => {
              return {
                label: item.name,
                value: item.id,
                key: index,
              }
            })
            resolve(this.dataChannel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    searchAdvance() {
      this.$emit('searchAdvance', this.formState)
    },
    dismiss: function () {
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.w-log-advancesearch {
  width: 30rem;
}
</style>
