<template>
  <div class="errorconfig">
    <a-result
      status="404"
      title="Configuration Error"
      sub-title="Sorry, some config does not exist."
    >
      <ul class="bg-white ulList">
        <li v-for="(item, index) in qsSearch" :key="index">{{ item }}</li>
      </ul>
      <template #extra>
        <a-button type="primary" @click="backto()"> Back Home </a-button>
      </template>
    </a-result>
    <a-button @click="click()">test</a-button>
  </div>
</template>

<script>
import CommonMixin from '@/mixin/CommonMixin'

export default {
  mixins: [CommonMixin],
  metaInfo() {
    return {
      title: `Error - ${this.pageTitle}`,
    }
  },
  data() {
    return {
      qsSearch: null,
      total: 3,
      testtest:[],
    }
  },
  created() {
    window.scrollTo(0, 0)
    this.$store.commit('setLoading', false)
    this.qsSearch = this.$route.query
  },
  mounted() {},
  methods: {
    backto() {
      this.$router.push({
        name: 'logout',
      })
    },
  },
}
</script>

<style lang="scss">
.errorconfig {
  .ant-result-content {
    background-color: white;
  }
  .ulList {
    width: 50%;
    margin: 0 auto;
  }
}
</style>
