<template>
  <div class="user-badge mission">
    <nav class="nav-search mt-4">
      <div class="group-btn pl-3">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <a-button
          v-if="dataapp.includes('User-List-Badge-Export')"
          class="btn-export btn-icon hover"
          type="toggle"
          @click="exportReport"
        >
          <img class="left" src="@/assets/icons/button/Export.svg" alt /> Export
        </a-button>
      </div>
      <div class="group-btn-left">
        <div class="wrap-date-filter flex">
          <!-- <div class="ml-3">
          <a-checkbox-group class="pt-1" v-model:value="isstatus">
            <a-checkbox :value="1" >Locked Badge</a-checkbox>
            <a-checkbox :value="2" >Unlocked Badge</a-checkbox>
          </a-checkbox-group>
        </div> -->
          <a-select
            class="select-search"
            v-model:value="isstatus"
            :options="status_dropdown"
          />
        </div>
        <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch()"
            ><search-outlined /> Search</a-button
          >
        </div>
      </div>
    </nav>
    <div class="wrap-table" @scroll="handleScroll">
      <VLoadingTable :value="overlaytable" />
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataBadge"
        :columns="displayFields"
        :pagination="false"
      >
        <template #badge="{ record }">
          <a-image :src="record.image" :preview="false" :fallback="noimg" />
        </template>
        <template #name="{ record }">
          {{ record.name ? record.name : '-' }}
        </template>
        <template #description="{ record }">
          {{ record.description ? record.description : '-' }}
        </template>
        <template #points="{ record }">
          {{ record.ponits ? record.ponits : '-' }}
        </template>
        <template #complete="{ text }">
          <div>{{ text }}</div>
          <div class="text-webkit-center">
            <div class="isactive"></div>
          </div>
        </template>
        <template #level="{ record }">
          {{ record.level ? record.level : '-' }}
        </template>
        <template #max_level="{ record }">
          {{ record.max_levels ? record.max_levels : '-' }}
        </template>
        <template #active="{ record }">
          <div v-if="record.active != null">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="record.active == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge
                  v-if="record.active == item.name"
                  :status="item.color"
                  :text="item.text"
                />
              </a-tag>
            </template>
          </div>
          <div v-else>-</div>
        </template>
        <template #start_date="{ record }">
          <div>
            {{
              record.start_date
                ? formatDate(
                    record.start_date,
                    'DD MMM YYYY',
                    this.isTimezone * -1,
                  )
                : '-'
            }}
          </div>
        </template>
        <template #end_date="{ record }">
          <div>
            {{
              record.end_date
                ? formatDate(
                    record.end_date,
                    'DD MMM YYYY',
                    this.isTimezone * -1,
                  )
                : '-'
            }}
          </div>
        </template>
        <template #reset="{ record }">
          <div v-if="record.reset != null">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="record.reset == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge
                  v-if="record.reset == item.name"
                  :status="item.color"
                  :text="item.text"
                />
              </a-tag>
            </template>
          </div>
          <div v-else>-</div>
        </template>
        <template #delete="{ record }">
          <div v-if="record.delete != null">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="record.delete == item.name"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge
                  v-if="record.delete == item.name"
                  :status="item.color"
                  :text="item.text"
                />
              </a-tag>
            </template>
          </div>
          <div v-else>-</div>
        </template>
        <template #missions="{ record }">
          <div v-if="record.mission.length > 0">
            <a class="text-link" @click="showModalMissions(record.mission)"
              >View</a
            >
          </div>
        </template>
      </a-table>
    </div>
    <a-modal
      :visible="missions_modal"
      :title="modallist ? 'Mission Detail' : ' Type : ' + type"
      @cancel="handleCancel"
      class="modal-tablelist"
      width="1000px"
      :centered="true"
      :closable="false"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <div v-if="modallist">
        <div class="wrap-table-modal">
          <VLoadingTable :value="overlaytablemodal" />
          <a-table
            row-key="partitionkey"
            class="fixed-head font-table-modal"
            :dataSource="dataMissions"
            :columns="missionsColumns"
            :pagination="false"
          >
            <template #trace_plan_id="{ record }">
              {{ record.trace_plan_id ? record.trace_plan_id : '-' }}
            </template>
            <template #description="{ record }">
              {{ record.description ? record.description : '-' }}
            </template>
            <template #complete="{ record }">
              <div v-if="record.complete != null">
                <template v-for="(item, index) in status">
                  <a-tag
                    v-if="record.complete == item.name"
                    :key="index"
                    :color="item.color == 'default' ? null : item.color"
                    class="tag-status"
                  >
                    <a-badge
                      v-if="record.complete == item.name"
                      :status="item.color"
                      :text="item.text"
                    />
                  </a-tag>
                </template>
              </div>
              <div v-else>-</div>
            </template>
            <template #info="{ record }">
              <a class="font-view" @click="showInfoMission(record)">Info</a>
            </template>
          </a-table>
        </div>
      </div>
      <div v-if="modaldetail">
        <div class="">
          <a @click="backTomodallist()"><left-outlined class="backtolist" /></a>
          <!-- <span class="title-modal ml-3">Type : 
          <span v-if="type == 'Register'">Register</span>
          <span v-if="type == 'Login'">Login</span>
          <span v-if="type == 'Redeem'">Redeem</span>
          <span v-if="type == 'Purchase'">Purchase</span>
          <span v-if="type == 'Profile'">Profile</span>
          <span v-if="type == 'FriendsGetFriends'">Friends get friends</span>
          <span v-if="type == 'Survey'">Survey</span>
          <span v-if="type == 'Custom' || type == 'Earn'">Custom</span>
        </span> -->
        </div>
        <div class="p-4 wrap-table-modal">
          <div v-if="this.type == 'Register'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <a-row v-if="datamissioninfo">
              <a-col :span="6">
                <div class="text-gray">Start Date :</div>
                <div class="text-gray">Redeem Campaigns :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    datamissioninfo.start_date
                      ? datamissioninfo.start_date
                      : '-'
                  }}
                </div>
                <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                  <div>-</div>
                </div>
                <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                  <div
                    v-for="(redeem, index) of datamissioninfo.redeem_campaigns"
                    :key="`index-${index}`"
                  >
                    <div>{{ redeem.badge_id ? redeem.badge_id : '-' }}</div>
                    <div>
                      {{ redeem.badge_row_key ? redeem.badge_row_key : '-' }}
                    </div>
                    <div>
                      {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                    </div>
                    <div>{{ redeem.name ? redeem.name : '-' }}</div>
                    <div>{{ redeem.active ? redeem.active : '-' }}</div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
          <div v-if="this.type == 'Login'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <div v-if="datamissioninfo">
              <div class="flex mb-4">
                <div class="bg-collect mr-2" v-if="datamissioninfo.first_time">
                  <check-outlined class="collect" />First Time
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.first_time"
                >
                  <close-outlined class="not-collect" />First Time
                </div>
                <div class="bg-collect" v-if="datamissioninfo.consecutive">
                  <check-outlined class="collect" />Consecutive
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.consecutive"
                >
                  <close-outlined class="not-collect" />Consecutive
                </div>
              </div>
              <hr />
              <a-row class="mt-4">
                <a-col :span="12" class="flex">
                  <div class="text-gray">Day Amounts :</div>
                  <div class="pl-5">
                    {{
                      datamissioninfo.day_amounts
                        ? datamissioninfo.day_amounts
                        : '-'
                    }}
                  </div>
                </a-col>
                <a-col :span="12">
                  <a-row>
                    <a-col :span="12">
                      <div class="text-gray">Redeem Campaigns:</div>
                    </a-col>
                    <a-col :span="12">
                      <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                        <div>-</div>
                      </div>
                      <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                        <div
                          v-for="(
                            redeem, index
                          ) of datamissioninfo.redeem_campaigns"
                          :key="`index-${index}`"
                        >
                          <div>
                            {{ redeem.badge_id ? redeem.badge_id : '-' }}
                          </div>
                          <div>
                            {{
                              redeem.badge_row_key ? redeem.badge_row_key : '-'
                            }}
                          </div>
                          <div>
                            {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                          </div>
                          <div>{{ redeem.name ? redeem.name : '-' }}</div>
                          <div>{{ redeem.active ? redeem.active : '-' }}</div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </div>
          <div v-if="this.type == 'Redeem'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <div v-if="datamissioninfo">
              <div class="flex mb-4">
                <div class="bg-collect mr-2" v-if="datamissioninfo.is_campaign">
                  <check-outlined class="collect" />Campaign
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.is_campaign"
                >
                  <close-outlined class="not-collect" />Campaign
                </div>
                <div class="bg-collect" v-if="datamissioninfo.is_category">
                  <check-outlined class="collect" />Category
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.is_category"
                >
                  <close-outlined class="not-collect" />Category
                </div>
              </div>
              <hr />
              <a-row class="mt-4">
                <a-col :span="12" class="flex">
                  <div class="text-gray">Criterions :</div>
                  <div v-if="datamissioninfo.criterions.length == 0">
                    <div>-</div>
                  </div>
                  <div v-if="datamissioninfo.criterions.length > 0">
                    <div
                      v-for="(addon, index) of datamissioninfo.addon_data"
                      :key="`index-${index}`"
                    >
                      <div>{{ addon.id }} : {{ addon.name }}</div>
                    </div>
                  </div>
                </a-col>
                <a-col :span="12">
                  <a-row>
                    <a-col :span="12">
                      <div class="text-gray">Redeem Campaigns:</div>
                    </a-col>
                    <a-col :span="12">
                      <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                        <div>-</div>
                      </div>
                      <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                        <div
                          v-for="(
                            redeem, index
                          ) of datamissioninfo.redeem_campaigns"
                          :key="`index-${index}`"
                        >
                          <div>
                            badge_id :
                            {{ redeem.badge_id ? redeem.badge_id : '-' }}
                          </div>
                          <div>
                            badge_row_key :
                            {{
                              redeem.badge_row_key ? redeem.badge_row_key : '-'
                            }}
                          </div>
                          <div>
                            campaign_id :
                            {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                          </div>
                          <div>
                            name : {{ redeem.name ? redeem.name : '-' }}
                          </div>
                          <div>
                            active : {{ redeem.active ? redeem.active : '-' }}
                          </div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </div>
          <div v-if="this.type == 'Purchase'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <div v-if="datamissioninfo">
              <div class="flex mb-4">
                <div class="bg-collect mr-2" v-if="datamissioninfo.is_campaign">
                  <check-outlined class="collect" />Campaign
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.is_campaign"
                >
                  <close-outlined class="not-collect" />Campaign
                </div>
                <div class="bg-collect" v-if="datamissioninfo.is_category">
                  <check-outlined class="collect" />Category
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.is_category"
                >
                  <close-outlined class="not-collect" />Category
                </div>
                <div
                  class="bg-collect"
                  v-if="datamissioninfo.is_payment_channel"
                >
                  <check-outlined class="collect" />Payment Channel
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.is_payment_channel"
                >
                  <close-outlined class="not-collect" />Payment Channel
                </div>
                <div
                  class="bg-collect"
                  v-if="datamissioninfo.is_payment_amount"
                >
                  <check-outlined class="collect" />Payment Amount
                </div>
                <div
                  class="bg-not-collect mr-2"
                  v-if="!datamissioninfo.is_payment_amount"
                >
                  <close-outlined class="not-collect" />Payment Amount
                </div>
                <div class="bg-collect" v-if="datamissioninfo.is_sku">
                  <check-outlined class="collect" />SKU
                </div>
                <div class="bg-not-collect mr-2" v-if="!datamissioninfo.is_sku">
                  <close-outlined class="not-collect" />SKU
                </div>
              </div>
              <hr />
              <a-row class="mt-4">
                <a-col :span="6" class="text-gray">Payment Amount :</a-col>
                <a-col :span="10">{{
                  datamissioninfo.payment_amount
                    ? datamissioninfo.payment_amount
                    : '-'
                }}</a-col>
              </a-row>
              <a-row>
                <a-col :span="12">
                  <a-row>
                    <a-col :span="12" class="text-gray">Criterions :</a-col>
                    <a-col :span="10">
                      <div v-if="datamissioninfo.criterions.length == 0">
                        <div>-</div>
                      </div>
                      <div v-if="datamissioninfo.criterions.length > 0">
                        <div
                          v-for="(addon, index) of datamissioninfo.addon_data"
                          :key="`index-${index}`"
                        >
                          <div>{{ addon.id }} : {{ addon.name }}</div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="12">
                  <a-row>
                    <a-col :span="12">
                      <div class="text-gray">Redeem Campaigns:</div>
                    </a-col>
                    <a-col :span="12">
                      <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                        <div>-</div>
                      </div>
                      <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                        <div
                          v-for="(
                            redeem, index
                          ) of datamissioninfo.redeem_campaigns"
                          :key="`index-${index}`"
                        >
                          <div>
                            badge_id :
                            {{ redeem.badge_id ? redeem.badge_id : '-' }}
                          </div>
                          <div>
                            badge_row_key :
                            {{
                              redeem.badge_row_key ? redeem.badge_row_key : '-'
                            }}
                          </div>
                          <div>
                            campaign_id :
                            {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                          </div>
                          <div>
                            name : {{ redeem.name ? redeem.name : '-' }}
                          </div>
                          <div>
                            active : {{ redeem.active ? redeem.active : '-' }}
                          </div>
                        </div>
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </div>
          <div v-if="this.type == 'Profile'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <a-row v-if="datamissioninfo">
              <a-col :span="12">
                <a-row>
                  <a-col :span="10" class="text-gray">On Update :</a-col>
                  <a-col :span="12">
                    <div>
                      {{
                        datamissioninfo.on_update
                          ? datamissioninfo.on_update
                          : '-'
                      }}
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="10" class="text-gray">Limit :</a-col>
                  <a-col :span="12">
                    <div>
                      {{ datamissioninfo.limit ? datamissioninfo.limit : '-' }}
                    </div>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row>
                  <a-col :span="12">
                    <div class="text-gray">Redeem Campaigns:</div>
                  </a-col>
                  <a-col :span="12">
                    <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                      <div>-</div>
                    </div>
                    <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                      <div
                        v-for="(
                          redeem, index
                        ) of datamissioninfo.redeem_campaigns"
                        :key="`index-${index}`"
                      >
                        <div>
                          badge_id :
                          {{ redeem.badge_id ? redeem.badge_id : '-' }}
                        </div>
                        <div>
                          badge_row_key :
                          {{
                            redeem.badge_row_key ? redeem.badge_row_key : '-'
                          }}
                        </div>
                        <div>
                          campaign_id :
                          {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                        </div>
                        <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                        <div>
                          active : {{ redeem.active ? redeem.active : '-' }}
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div v-if="this.type == 'FriendsGetFriends'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <a-row v-if="datamissioninfo">
              <a-col :span="12">
                <a-row>
                  <a-col :span="10" class="text-gray">
                    <div>Points :</div>
                    <div>UserLevel :</div>
                    <div>Interval Limit :</div>
                    <div>Interval Period :</div>
                    <div>Stop Trace On :</div>
                  </a-col>
                  <a-col :span="12">
                    <div>{{ datamissioninfo.inviter_data.point }}</div>
                    <div>{{ datamissioninfo.inviter_data.user_level }}</div>
                    <div>{{ datamissioninfo.inviter_data.interval_limit }}</div>
                    <div>
                      {{
                        datamissioninfo.inviter_data.interval_period
                          ? datamissioninfo.inviter_data.interval_period
                          : '-'
                      }}
                    </div>
                    <div>
                      {{
                        datamissioninfo.inviter_data.stop_trace_on
                          ? datamissioninfo.inviter_data.stop_trace_on
                          : '-'
                      }}
                    </div>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row>
                  <a-col :span="12">
                    <div class="text-gray">Redeem Campaigns:</div>
                  </a-col>
                  <a-col :span="12">
                    <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                      <div>-</div>
                    </div>
                    <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                      <div
                        v-for="(
                          redeem, index
                        ) of datamissioninfo.redeem_campaigns"
                        :key="`index-${index}`"
                      >
                        <div>
                          badge_id :
                          {{ redeem.badge_id ? redeem.badge_id : '-' }}
                        </div>
                        <div>
                          badge_row_key :
                          {{
                            redeem.badge_row_key ? redeem.badge_row_key : '-'
                          }}
                        </div>
                        <div>
                          campaign_id :
                          {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                        </div>
                        <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                        <div>
                          active : {{ redeem.active ? redeem.active : '-' }}
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div v-if="this.type == 'Survey'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <a-row v-if="datamissioninfo">
              <a-col :span="12">
                <a-row>
                  <a-col :span="10" class="text-gray">
                    <div>Points :</div>
                    <div>UserLevel :</div>
                    <div>Criterions :</div>
                  </a-col>
                  <a-col :span="12">
                    <div>
                      {{ datamissioninfo.point ? datamissioninfo.point : '-' }}
                    </div>
                    <div>
                      {{
                        datamissioninfo.user_level
                          ? datamissioninfo.user_level
                          : '-'
                      }}
                    </div>
                    <div>
                      <div v-if="datamissioninfo.criterions.length == 0">
                        <div>-</div>
                      </div>
                      <div v-if="datamissioninfo.criterions.length > 0">
                        <div
                          v-for="(addon, index) of datamissioninfo.addon_data"
                          :key="`index-${index}`"
                        >
                          <div>{{ addon.id }} : {{ addon.name }}</div>
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row>
                  <a-col :span="12">
                    <div class="text-gray">Redeem Campaigns:</div>
                  </a-col>
                  <a-col :span="12">
                    <div v-if="datamissioninfo.redeem_campaigns.length == 0">
                      <div>-</div>
                    </div>
                    <div v-if="datamissioninfo.redeem_campaigns.length > 0">
                      <div
                        v-for="(
                          redeem, index
                        ) of datamissioninfo.redeem_campaigns"
                        :key="`index-${index}`"
                      >
                        <div>
                          badge_id :
                          {{ redeem.badge_id ? redeem.badge_id : '-' }}
                        </div>
                        <div>
                          badge_row_key :
                          {{
                            redeem.badge_row_key ? redeem.badge_row_key : '-'
                          }}
                        </div>
                        <div>
                          campaign_id :
                          {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                        </div>
                        <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                        <div>
                          active : {{ redeem.active ? redeem.active : '-' }}
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div v-if="this.type == 'Custom' || this.type == 'Earn'">
            <div v-if="!datamissioninfo" class="center">
              <img :src="imgEmpty" />
              <div class="nodata">No Data</div>
            </div>
            <div v-if="datamissioninfo">
              <a-row class="mb-4">
                <a-col :span="6" class="text-gray">Condition :</a-col>
                <a-col :span="10">{{
                  datamissioninfo.condition ? datamissioninfo.condition : '-'
                }}</a-col>
              </a-row>
              <hr />
              <a-row class="mt-4">
                <a-col :span="8">
                  <a-row>
                    <a-col :span="12" class="text-gray">Cooldown :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.cooldown ? datamissioninfo.cooldown : '-'
                    }}</a-col>
                    <a-col :span="12" class="text-gray">Expire :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.expire ? datamissioninfo.expire : '-'
                    }}</a-col>
                    <a-col :span="12" class="text-gray">Limit :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.limit ? datamissioninfo.limit : '-'
                    }}</a-col>
                    <a-col :span="12" class="text-gray">Owner :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.owner ? datamissioninfo.owner : '-'
                    }}</a-col>
                    <a-col :span="12" class="text-gray">Increment :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.increment
                        ? datamissioninfo.increment
                        : '-'
                    }}</a-col>
                  </a-row>
                </a-col>
                <a-col :span="8">
                  <a-row>
                    <a-col :span="13" class="text-gray">Time Rounding :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.time_rounding
                        ? datamissioninfo.time_rounding
                        : '-'
                    }}</a-col>
                    <a-col :span="13" class="text-gray">Keyword :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.keyword ? datamissioninfo.keyword : '-'
                    }}</a-col>
                    <a-col :span="13" class="text-gray">Need Approval :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.need_approval
                    }}</a-col>
                    <a-col :span="13" class="text-gray">Object ID :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.object_id
                        ? datamissioninfo.object_id
                        : '-'
                    }}</a-col>
                    <a-col :span="13" class="text-gray">Buzz Type :</a-col>
                    <a-col :span="10">{{
                      datamissioninfo.buzz_type
                        ? datamissioninfo.buzz_type
                        : '-'
                    }}</a-col>
                  </a-row>
                </a-col>
                <a-col :span="8">
                  <a-row>
                    <a-col :span="15" class="text-gray">Stop Trace On :</a-col>
                    <a-col :span="9">{{
                      datamissioninfo.stop_trace_on
                        ? datamissioninfo.stop_trace_on
                        : '-'
                    }}</a-col>
                    <a-col :span="15" class="text-gray">Allow Custom :</a-col>
                    <a-col :span="9">{{
                      datamissioninfo.allow_custom
                        ? datamissioninfo.allow_custom
                        : '-'
                    }}</a-col>
                    <a-col :span="15" class="text-gray">User Level :</a-col>
                    <a-col :span="9">{{
                      datamissioninfo.user_level
                        ? datamissioninfo.user_level
                        : '-'
                    }}</a-col>
                    <a-col :span="15" class="text-gray"
                      >Require User Level :</a-col
                    >
                    <a-col :span="9">{{
                      datamissioninfo.require_user_level
                        ? datamissioninfo.require_user_level
                        : '-'
                    }}</a-col>
                    <a-col :span="15" class="text-gray">Custom Info :</a-col>
                    <a-col :span="9">{{
                      datamissioninfo.custom_info
                        ? datamissioninfo.custom_info
                        : '-'
                    }}</a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <DisplayFields
      ref="selectall"
      :value="showDisplayFields"
      :headers="columns"
      @apply="applyDisplayFields($event)"
      @handleClose="showDisplayFields = false"
    >
    </DisplayFields>
    <ExportModal
      :visible="showExportModal"
      @handleSubmit="handleExportModal($event)"
      @handleClose="showExportModal = false"
    ></ExportModal>
    <PrepareModal
      :visible="showPrepareModal"
      @handleClose="showPrepareModal = false"
    ></PrepareModal>
  </div>
</template>

<script>
import _ from 'lodash'
// import moment from 'moment'
// import dayjs from 'dayjs'
// content components
import {
  LeftOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons-vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import ConfigManager from '@/config/ConfigManager.js'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserRedeem',
  components: {
    DisplayFields,
    ExportModal,
    PrepareModal,
    LeftOutlined,
    CloseOutlined,
    CheckOutlined,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      noimg: require('@/assets/icons/button/ico-img.svg'),
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      showDisplayFields: false,
      missions_modal: false,
      missions_detail_modal: false,
      overlaytable: false,
      hovered: false,
      modallist: true,
      modaldetail: false,
      overlaytablemodal: false,
      searchObject: {
        campaign_id: '',
        campaign_name: '',
        agency_name: '',
        category_name: '',
        serial: '',
        parcel_no: '',
        status: '',
        startdate: '',
        enddate: '',
      },
      isstatus: true,
      status_dropdown: [
        {
          label: 'Locked Badge',
          value: false,
        },
        {
          label: 'Unlocked Badge',
          value: true,
        },
      ],
      optionssearch: 'name',
      options_search: [
        {
          label: 'Name',
          value: 'name',
        },
      ],
      options_campaigntype: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Draw',
          value: 'Draw',
        },
        {
          label: 'Donate',
          value: 'Donate',
        },
        {
          label: 'Free',
          value: 'Free',
        },
        {
          label: 'Deal',
          value: 'Deal',
        },
        {
          label: 'Interface',
          value: 'Interface',
        },
      ],
      search: '',
      params: {
        name: '',
        description: '',
        filter: {
          user_id: '',
          appid: '',
          campaign_id: null,
          campaign_name: '',
          agency_name: '',
          category_name: '',
          serial: '',
          parcel_no: '',
          campaign_type: '',
          date_filter_type: null,
          is_shipped: null,
          delivered: null,
          is_used: null,
          start_date: null,
          end_date: null,
        },
      },
      page: 1,
      columns: [
        {
          title: 'Badge',
          dataIndex: 'badge',
          key: 'badge',
          slots: { customRender: 'badge' },
          // sorter: (a, b) => a.campaignid - b.campaignid,
          // sortDirections: ['descend'],
          width: 140,
          align: 'left',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          slots: { customRender: 'name' },
          key: 'name',
          align: 'left',
          width: 220,
        },
        {
          title: 'Description',
          dataIndex: 'description',
          slots: { customRender: 'description' },
          key: 'description',
          width: 320,
          align: 'left',
        },
        {
          title: 'Points',
          dataIndex: 'points',
          slots: { customRender: 'points' },
          key: 'points',
          width: 120,
          align: 'right',
        },
        {
          title: 'Active',
          dataIndex: 'active',
          slots: { customRender: 'active' },
          key: 'active',
          width: 120,
          align: 'left',
        },
        {
          title: 'Level',
          dataIndex: 'level',
          slots: { customRender: 'level' },
          key: 'level',
          width: 120,
          align: 'right',
        },
        {
          title: 'Max Level',
          dataIndex: 'max_level',
          slots: { customRender: 'max_level' },
          key: 'max_level',
          width: 120,
          align: 'right',
        },
        {
          title: 'Start Date',
          dataIndex: 'start_date',
          slots: { customRender: 'start_date' },
          key: 'start_date',
          width: 140,
          align: 'left',
        },
        {
          title: 'End Date',
          dataIndex: 'end_date',
          slots: { customRender: 'end_date' },
          key: 'end_date',
          width: 140,
          align: 'left',
        },
        {
          title: 'Group',
          dataIndex: 'group',
          slots: { customRender: 'group' },
          key: 'group',
          width: 120,
          align: 'left',
        },
        {
          title: 'Reset',
          dataIndex: 'reset',
          slots: { customRender: 'reset' },
          key: 'reset',
          width: 120,
          align: 'left',
        },
        {
          title: 'Delete',
          dataIndex: 'delete',
          slots: { customRender: 'delete' },
          key: 'delete',
          width: 120,
          align: 'left',
        },
        {
          title: 'Info',
          dataIndex: 'missions',
          slots: { customRender: 'missions' },
          key: 'missions',
          width: 120,
          align: 'left',
        },
      ],
      dataBadge: [],
      missionsColumns: [
        {
          title: 'Trace Plan ID',
          dataIndex: 'trace_plan_id',
          key: 'name',
          align: 'left',
          width: 200,
        },
        {
          title: 'Description',
          dataIndex: 'description',
          slots: { customRender: 'description' },
          key: 'description',
          align: 'left',
          width: 400,
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          align: 'left',
          width: 100,
        },
        {
          title: 'Current',
          dataIndex: 'current',
          key: 'current',
          align: 'left',
          width: 100,
        },
        {
          title: 'Complete',
          dataIndex: 'complete',
          slots: { customRender: 'complete' },
          key: 'complete',
          align: 'left',
          width: 100,
        },
        {
          title: 'Mission',
          dataIndex: 'info',
          slots: { customRender: 'info' },
          key: 'info',
          align: 'center',
          width: 100,
        },
      ],
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
      dataMissions: [],
      datatest: [],
      campaigntype: '',
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      showExportModal: false,
      showPrepareModal: false,
      is_unlock: false,
    }
  },
  beforeCreate() {
    this.displayFields = _.cloneDeep(this.columns)
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'badge') {
        this.displayFields = _.cloneDeep(this.columns)
        // this.callAPiReportRedeem().then(() => {
        //   this.$store.commit('setLoading', false)
        // })
        this.$store.commit('setLoading', false)
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'badge') {
      this.displayFields = _.cloneDeep(this.columns)
      // this.clicksearch()
      this.overlay = false
    }
  },
  created() {
    this.getBadgelist()
  },
  methods: {
    getBadgelist() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetBadgelist(
          this.$route.params.id,
          this.isstatus,
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataBadge = []
            }
            _.forEach(data.result, item => {
              this.dataBadge.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataBadge)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          console.log('loadMore', this.page)
          this.getBadgelist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },

    exportReport() {
      this.showExportModal = true
      console.log(this.showExportModal)
    },
    showModalMissions(mission) {
      this.missions_modal = true
      this.dataMissions = mission
      this.modallist = true
      this.modaldetail = false
    },
    handleCancel() {
      this.missions_modal = false
    },
    showInfoMission(datadetail) {
      this.overlaytablemodal = true
      this.type = datadetail.mapping_type
      if (this.type == 'FriendsGetFriends') {
        this.type = 'Friends get friends'
      } else if (this.type == 'Earn') {
        this.type = 'Custom'
      }
      this.row_key = 1
      this.partition_key = datadetail.trace_plan_id
      if (datadetail.mapping_type == 'Earn') {
        this.mapping_type = 'Custom'
      } else {
        this.mapping_type = datadetail.mapping_type
      }
      this.datamissioninfo = []
      this.getMissioninfo().then(() => {
        this.modallist = false
        this.modaldetail = true
        this.overlaytablemodal = false
      })
    },
    getMissioninfo() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetMissioninfo(
          this.row_key,
          this.partition_key,
          this.mapping_type,
        ).subscribe(
          res => {
            this.datamissioninfo = res.result[0]
            resolve(this.datamissioninfo)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    backTomodallist() {
      this.modaldetail = false
      this.modallist = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }
}
.select-search {
  width: 150px !important;
}
.iswinner {
  font-size: 12px;
  .not-yet {
    color: #666666;
  }
  .winner {
    color: #2ec743;
  }
  .not-winner {
    color: #fba45b;
  }
}
.isnull {
  width: 10px;
  height: 10px;
  background-color: #aaaaaa;
  border-radius: 40px;
}
.isactive {
  width: 10px;
  height: 10px;
  background-color: #22ba20;
  border-radius: 40px;
}
.isunactive {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 40px;
}
</style>
<style lang="scss">
.user-badge {
  .wrap-table .ant-table-wrapper {
    min-width: 1860px !important;
  }
  .ant-table-row {
    vertical-align: top !important;
  }
  .ant-image-img {
    width: 80px !important;
    height: 80px !important;
  }
  @media only screen and (max-width: 1343px) {
    .user-badge {
      .wrap-table {
        height: calc(100vh - 424px) !important;
      }
    }
  }
}
.backtolist {
  margin-top: -50px;
  margin-left: -25px;
  position: absolute;
}
</style>
