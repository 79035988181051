<template>
  <div></div>
</template>
<script>
import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)

export default {
  name: 'Logout',
  created() {
    this.init()
  },
  methods: {
    init() {
      Cache.removeAllCache()
      localStorage.clear()
      const date = new Date()
      date.setHours(date.getHours() + 24)
      var expires =
        '; expires=' + date.toUTCString() + '; path=/; SameSite=None; Secure'
      document.cookie =
        'msToken=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'
      document.cookie = 'logout=true; path=/; SameSite=None; Secure'

      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
      }
      myMSAL.logoutRedirect(logoutRequest)
    },
  },
}
</script>
<style lang="scss" scope>
body.body {
  min-width: 100%;
  background: #333;
  width: 100%;
  min-height: 100vh;
}
</style>
