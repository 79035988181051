<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40 FAQ"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-faq bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Edit Question</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <div class="d-flex align-items-center justify-content-between">
                <h4>Question:</h4>
              </div>
              <div class="mt-3">
                <a-input v-model:value="dataDetail.fAQ" />
                <div class="mt-3">
                  <div class="d-flex justify-content-between">
                    <h4 class="mt-2">Answer :</h4>
                    <div>
                      <a-button
                        v-if="dataDetail.answer_details.length < 5"
                        type="primary"
                        @click="AddTableTopicDetail()"
                        ghost
                        >+ Add Answer</a-button
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-for="(item, index2) of dataDetail.answer_details"
                  :key="`tabletopicdetailindex-${index2}`"
                >
                  <div class="mt-2">Answer :</div>
                  <div class="pt-2">
                    <a-input class="mb-2" v-model:value="item.faq_ans" />
                    <div class="d-flex justify-content-between">
                      <a-button
                        v-if="!item.faq_img_path && !imgPreview[index2]"
                        type="default"
                        class="action-edit"
                        @click="uploadArtWork(index2)"
                        >+ Add Attachment</a-button
                      >
                      <input
                        @change="onImgFileChange($event, index2)"
                        ref="uploadImg"
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        hidden
                      />
                      <a-image
                        v-if="item.faq_img_path && item.faq_img_path != 'null'"
                        class="img-pre"
                        :width="100"
                        :height="100"
                        :src="item.faq_img_path"
                      />
                      <a-image
                        v-if="imgPreview[index2]"
                        class="img-pre"
                        :width="100"
                        :height="100"
                        :src="imgPreview[index2]"
                      />
                      <a
                        v-if="
                          (item.faq_img_path && item.faq_img_path != 'null') ||
                          imgPreview[index2]
                        "
                        @click="deleteImg(index2)"
                        ><close-circle-filled
                          :style="{ color: '#fff' }"
                          class="fraud"
                      /></a>
                      <a @click="removeAnswer(index2)" class="fraud">Remove</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex px-4 py-3 justify-content-between">
            <div
              v-if="datapermission.includes('FAQ-Delete')"
              class="align-self-center"
            >
              <a class="fraud" @click="deleteFaq()">Delete Question</a>
            </div>
            <div>
              <a-button
                class="btn-create"
                type="default"
                @click="dismiss(true)"
              >
                Cancel
              </a-button>
              <a-button
                class="ml-3 btn-create"
                type="primary"
                @click="PostUpdateFaq()"
              >
                Save
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
// import _ from 'lodash'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
    dataFAQ: {
      type: Object,
    },
    deleteQuestion: {
      type: Function,
    },
  },
  computed: {},
  data: function () {
    return {
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      topic_types: [{}],
      topic_detail: [{}],
      application: null,
      option_application: [
        {
          label: 'Abbot',
          value: 'Abbot',
        },
      ],
      dataDetail: {},
      imgPreview: [],
      imgUpload: [],
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    data(data) {
      this.dataDetail = data
    },
    removeAnswer(index) {
      this.dataDetail.answer_details.splice(index, 1)
      delete this.imgPreview[index]
    },
    uploadArtWork(index) {
      this.$refs.uploadImg[index].value = null
      this.$refs.uploadImg[index].click()
    },
    onImgFileChange(e, index) {
      const _URL = window.URL || window.webkitURL
      let img
      let objectUrl = _URL.createObjectURL(e.target.files[0])
      this.imgUpload[index] = e.target.files[0]
      img = new Image()
      let vmthis = this
      img.onload = function () {
        vmthis.file = e.target.files[0]
        vmthis.imgPreview[index] = URL.createObjectURL(vmthis.file)
        _URL.revokeObjectURL(objectUrl)
      }
      img.src = objectUrl
    },
    PostUpdateFaq() {
      this.$store.commit('setLoading', true)
      console.log(this.dataDetail.answer_details)
      var items = this.dataDetail.answer_details.map((x, index) => ({
        answer: x.faq_ans,
        files: this.imgUpload[index] ? this.imgUpload[index] : null,
        urlpathimage: x.faq_img_path ? x.faq_img_path : null,
      }))
      const formData = {
        appid: ConfigManager.getAppId(),
        id: this.dataDetail.iD,
        faq: this.dataDetail.fAQ,
        answerdetails: items,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUpdateFaq(formData)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.dismiss()
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('PostGetFAQ')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            // this.errorApi(error)
            reject(error)
          })
      })
    },
    PutDeleteFaq() {
      var params = {
        appid: ConfigManager.getAppId(),
        ints: [this.dataDetail.iD],
      }
      BzbsUser.apiPutDeleteFaq(params).then(() => {
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('PostGetFAQ')
          },
        })
      })
    },
    deleteFaq() {
      this.$emit('handleClose')
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you sure you want to delete this question?',
        content:
          'This action cannot be undone. Deleting the question will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PutDeleteFaq()
        },
        onCancel: () => {},
      })
    },
    AddTableTopicTypes() {
      var itemtable = {}
      this.topic_types.push(itemtable)
    },
    AddTableTopicDetail() {
      var itemtable = {}
      this.dataDetail.answer_details.push(itemtable)
    },
    dismiss: function () {
      this.topic_detail = [{}]
      this.topic_types = [{}]
      this.imgPreview = []
      this.$emit('handleClose')
    },
    deleteImg(index) {
      this.imgPreview[index] = null
      this.dataDetail.answer_details[index].faq_img_path = ''
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
}
.pointer {
  cursor: pointer;
}
.w-log-faq {
  width: 35rem !important;
  .content-topic {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  .img-pre {
    border-radius: 6px !important;
  }
  .action-edit {
    width: 150px !important;
    height: 29px !important;
    padding: 2px 12px !important;
  }
  .ant-image-img {
    width: 100px !important;
    height: 100px !important;
  }
  .anticon.anticon-close-circle.fraud {
    position: relative;
    margin-left: -160px;
    margin-top: -14px;
    font-size: 18px;
  }
}
</style>
