<template>
  <AppLayout :disable-menu="true">
    <TitleBreadcrumbs
      class="mt-4 ml-5"
      title="Ticket Management"
      :databreadcrumbs="breadcrumbs"
    />
    <div class="callcenter">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="Subject" force-render>
          <ManageSubject />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Channel" force-render>
          <Channel />
        </a-tab-pane>
      </a-tabs>
    </div>
  </AppLayout>
</template>

<script>
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import AppLayout from '@/components/Layout/default.vue'
import ManageSubject from '@/module/CallCenter/pages/TicketManagement/ManageSubject.vue'
import Channel from '@/module/CallCenter/pages/TicketManagement/Channel.vue'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    // TopBar,
    // Footer,
    AppLayout,
    // SidebarMenu,
    TitleBreadcrumbs,
    ManageSubject,
    Channel,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: 'select_application',
          breadcrumbName: 'Select Applications',
        },
        {
          name: 'callcentermanagement',
          breadcrumbName: 'Call Center Management',
        },
        {
          name: null,
          breadcrumbName: 'Ticket Management',
        },
      ],
      activeKey: '1',
      startdate: null,
      enddate: null,
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      subject: null,
      sidebarHeaderconfig: { bgColor: '#fff' },
      sidebarMenu: [],
      sidebarOpen: true,
      overlay: true,
      applications: [],
      rawapp: [],
      mode: 'card',
      typesearch: 'serial_number',
      options_search: [
        {
          label: 'Serial Number',
          value: 'serial_number',
        },
      ],
      showModalAdvanceSearch: false,
      showModalTicketDetail: false,
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'My Applications',
        },
      ]
    },
  },
  mounted() {},
  created() {
    this.$store.commit('setLoading', false)
  },
  methods: {
    toggleAdvanceSearch() {
      this.showModalAdvanceSearch = !this.showModalAdvanceSearch
    },
    toggleTicketDetail() {
      this.showModalTicketDetail = !this.showModalTicketDetail
    },
    goToManageSubject() {
      this.$router.push({
        name: 'managesubject',
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    postChangeUserStatus() {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Confirm to change Use Status ?',
        content: 'You are about to change status to used.',
        okText: 'Confirm',
        cancelText: 'Cancel',
        width: '480px',
        icon: <QuestionCircleOutlined />,
        okButtonProps: {
          type: 'primary',
        },
        onOk: () => {},
        onCancel: () => {},
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.left-menu-width {
  // flex: 0 0 $left-menu-width;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 8%);
  width: $left-menu-width;
  z-index: 15;

  &.collapse {
    // width: 44px;
    width: 64px;
    // flex: 0 0 55px;
  }
}
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
  .box-select-app {
    padding: 0px 20px 20px 20px;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.callcenter {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: 2px solid #c9c9c961;
  }
}
</style>
