<template>
    <div>
        <a-modal
            v-model:visible="showModal"
            title="Create / Merge New List"
            :centered="true"
            @cancel="handleClose"
            okText='Save'
            @ok="submit"
            :closable="false"
        >
        <div class="">
            <a-form
                name="create-saved-list"
                :model="formState"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
                @finish="onFinish"
            >
            <div class="">
                <a-form-item
                    label="List Name"
                    name="tagname"
                    :rules="[
                        {
                            required: true,
                            message: 'Please input your list name!'
                        }
                    ]"
                >
                    <a-input v-model:value="formState.tagname" />
                </a-form-item>
                
                <a-form-item
                    label="Description"
                    name="tagdescription"
                    :rules="[
                        {
                            required: true,
                            message: 'Please input your description!'
                        }
                    ]"
                >
                    <a-textarea
                        v-model:value="formState.tagdescription"
                        :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                </a-form-item>
            </div>
                <!-- <div class="p-4 border-t-2 modal-footer">
                    <a-button
                        block
                        type="primary"
                        html-type="submit"
                    >
                        Save
                    </a-button>                  
                </div> -->
            </a-form>
            </div>
        </a-modal>
    </div>
</template>
<script>

export default {
    name: "CreateSavedList",
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    inject: ["toolbarStates"],
    watch: {
        visible (value, oldvalue){
            if(value != oldvalue){
                this.showModal = value          
                this.formState = {
                    tagname: '',
                    tagdescription: ''
                }
            }
        }
    },
    data: function() {
        return {
            formState: {
                tagname: '',
                tagdescription: ''
            },
            showModal: false
        };
    },
    methods: {
        onFinish (values) {
            this.formState = {
                tagname: '',
                tagdescription: ''
            }            
            this.$emit("handleSubmit", values)
        },
        handleClose(){
            this.showModal = false
            this.toolbarStates.showDialogCreateList = false
            console.log('showDialogCreateList close',this.toolbarStates.showDialogCreateList)
            this.$emit("handleClose")
        },        
    }
}
</script>
<style lang="scss" scoped>
.ant-btn-primary{
    width: 100%;
}
</style>

