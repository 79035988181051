<template>
    <div class="v-list-item hover:bg-gray-50 px-3" @click="clickItem()" :class="itemCls">
        <div class="flex items-start w-full">
            <div class="flex-none h-10 w-10 mr-10px">
                <v-avatar
                    :text="item.user.shortedName"
                    :bg-color="avatarBg"
                    :color="avatarTxtColor"
                    :size="40"
                ></v-avatar>
            </div>
            <div class="flex-auto">
                <span class="text-sm text-gray-1 font-bold">{{ item.user.firstName }}</span>
                <span class="text-sm text-gray-1 pl-2" v-html="item.content"></span>
                <div class="my-2 text-xs text-gray-400">{{ item.timestamp }}</div>
                <template v-if="notiType == 3">
                    <div>
                        <div class="flex items-start">
                            <button class="text-xs h-6 rounded-2xl bg-red-1-light text-red-1 ring-1 ring-transparent hover:ring-red-1">
                                <span class="px-3">Reject</span>
                            </button>
                            <button
                                class="text-xs h-6 rounded-2xl bg-green-1-light text-green-1 ml-10px  ring-1 ring-transparent hover:ring-green-1"
                            >
                                <span class="px-3">Approve</span>
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import VAvatar from "@/components/Avatar/Avatar.vue"
export default {
    name: "NotificationItem",
    props: {
        item: {
            type: Object
        }
    },
    components: {
        VAvatar
    },
    computed: {
        notiType: function () {
            // 1 = view, 2 = view/response  3 = approve
            if (this.item.action != null) {
                return this.item.action
            }

            return 1
        },
        itemCls: function () {
            let cls = []
            if (this.notiType != 3) {
                cls.push("cursor-pointer")
            }
            return cls
        },
        avatarBg: function () {
            if (this.notiType == 1) {
                return "bg-green-1-light"
            } else if (this.notiType == 2) {
                return "bg-blue-4-light"
            }
            return "bg-orange-2"
        },
        avatarTxtColor: function () {
            if (this.notiType == 1) {
                return "text-green-1"
            } else if (this.notiType == 2) {
                return "text-blue-4"
            }
            return "text-orange-1"
        }
    },
    methods: {
        clickItem: function () {
            if (this.notiType == 3) {
                // do nothing
            } else if (this.notiType == 2) {
                // view and response
            } else {
                // simple view
            }
        }
    }
}
</script>
