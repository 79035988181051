<template>
    <div class="flex w-auto h-4 cursor-pointer items-center" @click="value = !value">
        <img
            v-show="value == true"
            src="@/assets/icons/button/btn-toggle.on.svg"
            alt="toggle on"
            class="w-auto h-full"
        />
        <img
            v-show="value == false"
            src="@/assets/icons/button/btn-toggle.off.svg"
            alt="toggle off"
            class="w-auto h-full"
        />

        <template v-if="activeTextComputed != null && inactiveTextComputed != null">
            <div
                class="ml-2 text-sm"
                :class="[value ? 'text-blue-1' : 'text-gray-3']"
            >{{ value == true ? activeTextComputed : inactiveTextComputed }}</div>
        </template>
    </div>
</template>

<script>
import PropsHelper from "@/helper/PropsHelper"

export default {
    name: "VToggle",
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
        activeText: {
            type: String,
            default: undefined
        },
        inactiveText: {
            type: String,
            default: undefined
        }
    },
    computed: {
        value: {
            get: function () {
                return this.modelValue
            },
            set: function (val) {
                // console.log(val)
                this.$emit("update:modelValue", val)
                this.$emit("change", val)
            }
        },
        activeTextComputed: function () {
            return PropsHelper.getStringProp(this.activeText)
        },
        inactiveTextComputed: function () {
            return PropsHelper.getStringProp(this.inactiveText)
        }
    },
    emits: ["update:modelValue", "change"]
}
</script>
