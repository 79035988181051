<template>
  <a-card class="w-full min-h-full wallet">
    <nav class="filter-nav d-flex justify-content-between mb-3">
      <div class="d-flex gab-10">
        <a-input-group class="search" compact>
          <a-select
            class="select-search"
            v-model:value="optionssearch"
            :options="options_search"
          />
          <a-input
            v-model:value="search"
            class="advanced-search"
            placeholder="Search"
            @keyup="enterFilter($event)"
            :allowClear="true"
          >
            <template #prefix>
              <a @click="applyFilter">
                <search-outlined />
              </a>
            </template>
            <template #suffix>
              <a @click="toggleAdvaceSearch">
                <img
                  class="ico-filter"
                  :class="{ active: checkActive }"
                  src="@/assets/icons/faders.svg"
                />
              </a>
              <FilterSearch
                ref="FilterSearch"
                v-model:value="showAdvaceSearch"
                :FilterConfig="optionlist"
                @applyFilter="applyFilter($event)"
                @handleClose="showAdvaceSearch = false"
              />
            </template>
          </a-input>
        </a-input-group>
        <!-- <div class="wrap-date-filter pr-3">
          <a-select
            class="select-search"
            v-model:value="statusdropdown"
            :options="status_dropdown"
          />
        </div>
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
          />
        </div>
        <div class="wrap-filter">
          <a-button type="primary" class="btn-search" @click="clicksearch"
            ><search-outlined /> Search</a-button
          >
        </div> -->
      </div>
      <div class="d-flex gab-10">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>
        <a-button
          v-if="dataapp.includes('User-List-Wallet-Export')"
          type="teriary"
          @click="exportWalletReport"
        >
          <file-excel-outlined /> Export as excel
        </a-button>
      </div>
    </nav>
    <div class="wrap-table" @scroll="handleScroll">
      <!-- <VLoadingTable :value="overlaytable" /> -->
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="datawallets"
        :columns="displayFields"
        :pagination="false"
      >
        <template #card_id="{ record }">
          <a
            v-if="dataapp.includes('User-List-Wallet-Detail')"
            @click="showModal(record)"
            class="font-level"
            >{{ record.card_id }}</a
          >
          <div v-else>{{ record.card_id }}</div>
        </template>
        <template #type="{ text }">
          <template v-for="(item, index) in statustype">
            <a-tag
              v-if="text == item.name"
              :key="index"
              :color="item.color == 'default' ? null : item.color"
              class="tag-status"
            >
              <a-badge :status="item.color" :text="text" />
            </a-tag>
          </template>
          <!-- <div class="status">
            <div
              v-show="text === 'Stamp' || 'Wallet'"
              :class="{
                blue: text == 'Stamp',
                orange: text == 'Wallet',
              }"
            >
              {{ text }}
            </div>
            <div v-show="text === null" class="orange">Wallet</div>
          </div> -->
        </template>
        <template #expired_date="{ record }">
          <div>
            {{ record.expired_date ? getdate(record.expired_date) : '-' }}
          </div>
        </template>
        <template #has_conversion="{ text }">
          <div class="center" v-show="text">
            <img src="@/assets/icons/ico-true.svg" alt />
          </div>
          <div class="center" v-show="!text">
            <img src="@/assets/icons/ico-false.svg" alt />
          </div>
          <div class="center" v-show="text == null">-</div>
          <!-- <div class="card-type" v-show="text.length == 0">-</div> -->
        </template>
        <template #redeem="{ text, record }">
          <a @click="toggleDisplayFieldsRedeem(record)" class="font-level"
            >Redeem</a
          >
          {{ text }}
        </template>
      </a-table>
    </div>
    <a-modal
      :visible="modalWallet"
      :title="'Card ID :' + modalCardId"
      @cancel="handleCancel"
      class="modal-tablelist"
      width="1400px"
      :centered="true"
      :closable="false"
      :ok-button-props="{ style: { display: 'none' } }"
    >
      <div class="float-right mb-3">
        <a-tooltip placement="bottom">
          <template #title>
            <div>Export</div>
          </template>
          <a-button
            v-if="dataapp.includes('User-List-Wallet-Detail-Export')"
            class="ml-3 btn-icon hover"
            type="toggle"
            @click="exportWalletDetailReport"
          >
            <img class="icon" src="@/assets/icons/button/Export.svg" alt />
          </a-button>
        </a-tooltip>
      </div>
      <div class="wrap-table-modal" @scroll="handleScrollModal">
        <div :class="cardcolumn[5].key == 'source_ref' ? 'max-content' : ''">
          <VLoadingTable :value="overlaytablemodal" />
          <a-table
            row-key="partitionkey"
            class="fixed-head font-table-modal"
            :dataSource="dataCard"
            :columns="cardcolumn"
            :pagination="false"
          >
            <template #transaction_date="{ record }">
              <div>
                {{
                  formatDate(
                    record.transaction_date,
                    'DD MMM YYYY',
                    this.isTimezone * -1,
                  )
                }}
              </div>
              <div class="">
                {{
                  formatTime(
                    record.transaction_date,
                    'HH:mm',
                    this.isTimezone * -1,
                  )
                }}
              </div>
            </template>
            <template #status="{ text }">
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="text == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge :status="item.color" :text="text" />
                </a-tag>
              </template>
              <!-- <div class="status">
              <div
                :class="{
                  blue: record.status == 'Create',
                  orange: record.status == 'Refund',
                  green: record.status == 'Complete',
                  red: record.status == 'Fail'
                }"
              >
                {{ record.status }}
              </div>
            </div> -->
            </template>
            <template #description="{ record }">
              {{ record.description ? record.description : '-' }}
            </template>
            <template #point="{ record }">
              {{ record.point ? record.point : '-' }}
            </template>
            <template #source_issuer="{ record }">
              <div>
                {{
                  record.conversion.source_issuer
                    ? record.conversion.source_issuer
                    : '-'
                }}
              </div>
            </template>
            <template #destination_issuer="{ record }">
              <div>
                {{
                  record.conversion.destination_issuer
                    ? record.conversion.destination_issuer
                    : '-'
                }}
              </div>
            </template>
            <template #source_ref="{ record }">
              <div>
                {{ record.source_ref ? record.source_ref : '-' }}
              </div>
            </template>
            <template #dest_ref="{ record }">
              <div>
                {{ record.destination_ref ? record.destination_ref : '-' }}
              </div>
            </template>
            <template #source_point="{ record }">
              <div>
                {{
                  record.conversion.source_point
                    ? record.conversion.source_point
                    : '-'
                }}
              </div>
            </template>
            <template #destination_point="{ record }">
              <div>
                {{
                  record.conversion.destination_point
                    ? record.conversion.destination_point
                    : '-'
                }}
              </div>
            </template>
            <template #source_amount="{ record }">
              <div>
                {{
                  record.conversion.source_amount
                    ? record.conversion.source_amount
                    : '-'
                }}
              </div>
            </template>
            <template #destination_amount="{ record }">
              <div>
                {{
                  record.conversion.destination_amount
                    ? record.conversion.destination_amount
                    : '-'
                }}
              </div>
            </template>
            <template #point_expire="{ record }">
              <div v-if="!record.point_expire_date">-</div>
              <div v-else>
                <div>
                  {{
                    record.point_expire_date
                      ? formatDate(
                          record.point_expire_date,
                          'DD MMM YYYY',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
                <div class="">
                  {{
                    record.point_expire_date
                      ? formatTime(
                          record.point_expire_date,
                          'HH:mm',
                          this.isTimezone * -1,
                        )
                      : '-'
                  }}
                </div>
              </div>
            </template>
            <template #point_exchange_rate="{ record }">
              <div>
                {{
                  record.conversion.point_exchange_rate
                    ? record.conversion.point_exchange_rate
                    : '-'
                }}
              </div>
            </template>
            <template #source_transaction_id="{ record }">
              <div>
                {{
                  record.src_ref_transaction_id
                    ? record.src_ref_transaction_id
                    : '-'
                }}
              </div>
            </template>
            <template #destination_ref_transaction_id="{ record }">
              <div>
                {{
                  record.dest_ref_transaction_id
                    ? record.dest_ref_transaction_id
                    : '-'
                }}
              </div>
            </template>
            <template #info="{ record }">
              <a-popover
                v-model:visible="record.visible2"
                trigger="hover"
                placement="bottomRight"
              >
                <template #content>
                  <a-row class="popover2">
                    <a-col :span="11" style="border-right: 1px solid #ebebeb">
                      <a-row>
                        <a-col :span="11" class="text-right pr-2">
                          <div class="popovermodal">Customer :</div>
                        </a-col>
                        <a-col :span="13">
                          <div class="popovermodal-content">
                            {{
                              record.info.customer ? record.info.customer : '-'
                            }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="11" class="text-right pr-2">
                          <div class="popovermodal">Brand :</div>
                        </a-col>
                        <a-col :span="13">
                          <div class="popovermodal-content">
                            {{ record.info.brand ? record.info.brand : '-' }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="11" class="text-right pr-2">
                          <div class="popovermodal">Branch :</div>
                        </a-col>
                        <a-col :span="13">
                          <div class="popovermodal-content">
                            {{
                              record.info.branch_name
                                ? record.info.branch_name
                                : '-'
                            }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="11" class="text-right pr-2">
                          <div class="popovermodal">Terminal :</div>
                        </a-col>
                        <a-col :span="13">
                          <div class="popovermodal-content">
                            {{
                              record.info.terminal ? record.info.terminal : '-'
                            }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row v-show="has_conversion">
                        <!-- <a-col :span="11" class="text-right pr-2">
                        <div class="popovermodal">Issuer Fees :</div>
                      </a-col> -->
                        <!-- <a-col :span="13">
                        <div class="popovermodal-content">
                          {{
                            record.conversion.issuer_fees
                              ? record.conversion.issuer_fees
                              : '-'
                          }}
                        </div>
                      </a-col> -->
                      </a-row>
                      <a-row v-show="has_conversion">
                        <a-col :span="11" class="text-right pr-2">
                          <div class="popovermodal">
                            Destination Issuer Fee :
                          </div>
                        </a-col>
                        <a-col :span="13">
                          <div class="popovermodal-content">
                            {{ record.conversion.destination_issuer_fee }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row v-show="has_conversion">
                        <a-col :span="11" class="text-right pr-2">
                          <div class="popovermodal">Interchange Fees :</div>
                        </a-col>
                        <a-col :span="13">
                          <div class="popovermodal-content">
                            {{ record.conversion.interchange_fees }}
                          </div>
                        </a-col>
                      </a-row>
                    </a-col>
                    <a-col :span="12" class="pl-4">
                      <a-row>
                        <a-col :span="8" class="text-right pr-2">
                          <div class="popovermodal">Campaign :</div>
                        </a-col>
                        <a-col :span="16">
                          <div class="popovermodal-content">
                            {{
                              record.info.campaign_name
                                ? record.info.campaign_name
                                : '-'
                            }}
                          </div>
                          <div class="popovermodal-content">
                            ID :
                            {{
                              record.info.campaign_id
                                ? record.info.campaign_id
                                : '-'
                            }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row>
                        <a-col :span="8" class="text-right pr-2">
                          <div class="popovermodal">Acquirer :</div>
                        </a-col>
                        <a-col :span="16">
                          <div class="popovermodal-content">
                            {{ record.conversion.acquirer }}
                          </div>
                        </a-col>
                      </a-row>
                      <a-row v-show="has_conversion">
                        <a-col :span="8" class="text-right pr-2">
                          <div class="popovermodal">Remark :</div>
                        </a-col>
                        <a-col :span="16">
                          <div class="popovermodal-content">-</div>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                </template>
                <a class="font-view">view</a>
              </a-popover>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
  </a-card>
  <DisplayFieldsRedeem
    :value="showDisplayFieldsRedeem"
    @getlevel="getlevel($event)"
    @handleClose="showDisplayFieldsRedeem = false"
  >
  </DisplayFieldsRedeem>
  <DisplayFields
    ref="selectall"
    :value="showDisplayFields"
    :headers="columns"
    @apply="applyDisplayFields($event)"
    @handleClose="showDisplayFields = false"
  >
  </DisplayFields>
  <ExportModal
    :visible="showExportWalletModal"
    @handleSubmit="callAPiExportWallet($event)"
    @handleClose="showExportWalletModal = false"
  ></ExportModal>
  <ExportModal
    :visible="showExportWalletDetailModal"
    @handleSubmit="callAPiExportWalletDetail($event)"
    @handleClose="showExportWalletDetailModal = false"
  ></ExportModal>
  <PrepareModal
    :visible="showPrepareModal"
    @handleClose="showPrepareModal = false"
  ></PrepareModal>
</template>
<script>
import moment from 'moment'
import dayjs from 'dayjs'
import _ from 'lodash'
// import { map } from "rxjs/operators";
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import FilterSearch from '@/components/Filter/FilterSearch.vue'
import DisplayFieldsRedeem from '@/module/User/component/DisplayFieldsRedeem.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import ConfigManager from '@/config/ConfigManager.js'
import { CardType } from '@/helper/StaticValue.js'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserWallet',
  components: {
    DisplayFields,
    FilterSearch,
    ExportModal,
    PrepareModal,
    DisplayFieldsRedeem,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      visible2: false,
      drag: false,
      modalWallet: false,
      showDisplayFields: false,
      showDisplayFieldsRedeem: false,
      overlay: true,
      overlaytable: false,
      overlaytablemodal: false,
      nomoremodal: false,
      isLoadmore: false,
      modalPage: 1,
      has_conversion: false,
      setperrmissionexport: true,
      datawallets: [],
      card_id: '',
      statustype: [
        {
          id: 1,
          name: 'Stamp',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Wallet',
          color: 'yellow',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Refund',
          color: 'yellow',
        },
        {
          id: 3,
          name: 'Complete',
          color: 'green',
        },
        {
          id: 4,
          name: 'Fail',
          color: 'red',
        },
      ],
      statusdropdown: '',
      status_dropdown: [
        {
          label: 'All Card Type',
          value: '',
        },
        {
          label: 'Stamp',
          value: 'stamp',
        },
        {
          label: 'Wallet',
          value: 'wallet',
        },
      ],
      optionlist: {
        status: true,
        daterange: true,
        options: [
          {
            label: 'All Card Type',
            value: '',
          },
          {
            label: 'Stamp',
            value: 'stamp',
          },
          {
            label: 'Wallet',
            value: 'wallet',
          },
        ],
      },
      searchObject: {
        cardid: '',
        status: '',
        issuer: '',
        startdate: '',
        enddate: '',
      },
      optionssearch: 'cardid',
      options_search: [
        {
          label: 'Card ID',
          value: 'cardid',
        },
        {
          label: 'Issuer',
          value: 'issuer',
        },
      ],
      search: '',
      page: 1,
      columns: [
        {
          title: 'Card ID',
          dataIndex: 'card_id',
          slots: { customRender: 'card_id' },
          key: 'card_id',
          width: 120,
          align: 'left',
        },
        {
          title: 'Issuer',
          dataIndex: 'issuer',
          key: 'issuer',
          width: 140,
          align: 'left',
        },
        {
          title: 'Card Type',
          dataIndex: 'type',
          slots: { customRender: 'type' },
          key: 'type',
          width: 80,
          align: 'center',
        },
        {
          title: 'Conversion',
          dataIndex: 'has_conversion',
          slots: { customRender: 'has_conversion' },
          key: 'has_conversion',
          width: 80,
          align: 'center',
        },
        {
          title: 'Expiry Date',
          dataIndex: 'expired_date',
          slots: { customRender: 'expired_date' },
          key: 'expired_date',
          width: 80,
          align: 'left',
        },
        {
          title: 'Balance',
          dataIndex: 'balance',
          key: 'balance',
          width: 80,
          align: 'right',
        },
        // {
        //   title: '',
        //   dataIndex: 'redeem',
        //   slots: { customRender: 'redeem' },
        //   key: 'redeem',
        //   width: 80,
        //   align: 'center',
        // },
      ],
      dataWallet: [],
      cardcolumnmaster: [
        {
          title: 'Transaction ID',
          dataIndex: 'transaction_id',
          key: 'transaction_id',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 150,
          align: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          slots: { customRender: 'description' },
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 100,
          align: 'left',
        },
        {
          title: 'Channel',
          dataIndex: 'channel',
          key: 'channel',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 80,
          align: 'left',
        },
        {
          title: 'Transaction Date',
          dataIndex: 'transaction_date',
          slots: { customRender: 'transaction_date' },
          key: 'transaction_date',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 150,
          align: 'left',
        },
        {
          title: 'Stamp Count',
          dataIndex: 'stamp_count',
          key: 'stamp_count',
          CardTypes: [CardType.Stamp],
          width: 80,
          align: 'right',
        },
        {
          title: 'Point',
          dataIndex: 'point',
          slots: { customRender: 'point' },
          key: 'point',
          CardTypes: [CardType.Wallet],
          width: 80,
          align: 'right',
        },
        {
          title: 'Source Issuer',
          dataIndex: 'source_issuer',
          key: 'source_issuer',
          slots: { customRender: 'source_issuer' },
          CardTypes: [CardType.WalletConversion],
          width: 150,
          align: 'left',
        },
        {
          title: 'Destination Issuer',
          dataIndex: 'destination_issuer',
          key: 'destination_issuer',
          slots: { customRender: 'destination_issuer' },
          CardTypes: [CardType.WalletConversion],
          width: 150,
          align: 'left',
        },
        {
          title: 'Source Ref',
          dataIndex: 'source_ref',
          key: 'source_ref',
          slots: { customRender: 'source_ref' },
          CardTypes: [CardType.WalletConversion],
          width: 120,
          align: 'left',
        },
        {
          title: 'Destination Ref',
          dataIndex: 'dest_ref',
          key: 'dest_ref',
          slots: { customRender: 'dest_ref' },
          CardTypes: [CardType.WalletConversion],
          width: 120,
          align: 'left',
        },
        {
          title: 'Source Point',
          dataIndex: 'source_point',
          key: 'source_point',
          slots: { customRender: 'source_point' },
          CardTypes: [CardType.WalletConversion],
          width: 120,
          align: 'right',
        },
        {
          title: 'Destination Point',
          dataIndex: 'destination_point',
          key: 'destination_point',
          slots: { customRender: 'destination_point' },
          CardTypes: [CardType.WalletConversion],
          width: 150,
          align: 'right',
        },
        {
          title: 'Source Amt.',
          dataIndex: 'source_amount',
          key: 'source_amount',
          slots: { customRender: 'source_amount' },
          CardTypes: [CardType.WalletConversion],
          width: 80,
          align: 'right',
        },
        {
          title: 'Dest. Amt.',
          dataIndex: 'destination_amount',
          key: 'destination_amount',
          slots: { customRender: 'destination_amount' },
          CardTypes: [CardType.WalletConversion],
          width: 80,
          align: 'right',
        },
        {
          title: 'Point Exchange Rate',
          dataIndex: 'point_exchange_rate',
          key: 'point_exchange_rate',
          slots: { customRender: 'point_exchange_rate' },
          CardTypes: [CardType.WalletConversion],
          width: 150,
          align: 'right',
        },
        {
          title: 'Point Expire Date',
          dataIndex: 'point_expire',
          key: 'point_expire',
          slots: { customRender: 'point_expire' },
          CardTypes: [CardType.WalletConversion],
          width: 150,
          align: 'left',
        },
        {
          title: 'Source Cost',
          dataIndex: 'source_cost',
          key: 'source_cost',
          slots: { customRender: 'source_cost' },
          CardTypes: [CardType.WalletConversion],
          width: 120,
          align: 'right',
        },
        {
          title: 'Destination Cost',
          dataIndex: 'destination_cost',
          key: 'destination_cost',
          slots: { customRender: 'destination_cost' },
          CardTypes: [CardType.WalletConversion],
          width: 150,
          align: 'right',
        },
        {
          title: 'Source Ref Transaction ID',
          dataIndex: 'source_transaction_id',
          key: 'source_transaction_id',
          slots: { customRender: 'source_transaction_id' },
          CardTypes: [CardType.WalletConversion],
          width: 200,
          align: 'left',
        },
        {
          title: 'Destination Ref Transaction ID',
          dataIndex: 'destination_ref_transaction_id',
          key: 'destination_ref_transaction_id',
          slots: { customRender: 'destination_ref_transaction_id' },
          CardTypes: [CardType.WalletConversion],
          width: 220,
          align: 'left',
        },
        {
          title: 'Amount',
          dataIndex: 'amount',
          key: 'amount',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 80,
          align: 'right',
        },
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 80,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 80,
          align: 'left',
        },
        {
          title: 'Info',
          dataIndex: 'info',
          slots: { customRender: 'info' },
          key: 'info',
          CardTypes: [CardType.Wallet, CardType.Stamp],
          width: 80,
          align: 'center',
        },
      ],
      cardcolumn: [],
      test: true,
      dataCard: [],
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Expiry Date',
      startdate: null,
      enddate: null,
      formatdate: 'DD/MMM/YYYY',
      showExportWalletModal: false,
      showExportWalletDetailModal: false,
      showPrepareModal: false,
      modaltext: '',
      modalCardId: '',
      paramsWallet: {
        name: '',
        description: '',
        filter: {
          appid: '',
          user_id: '',
          card_id: '',
          issuer: '',
          type: null,
          from_expired_date: null,
          to_expired_date: null,
        },
      },
      paramsWalletDetail: {
        name: '',
        description: '',
        filter: {
          appid: '',
          user_id: '',
          card_id: '',
        },
      },
      showAdvaceSearch: false,
      checkActive: false,
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'wallet') {
        this.$store.commit('setLoading', true)
        this.displayFields = _.cloneDeep(this.columns)
        this.showAdvaceSearch = false
        this.$refs.FilterSearch.resetFilter()
        this.checkActive = false
        this.getWallets().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'wallet') {
      this.displayFields = _.cloneDeep(this.columns)
      this.showAdvaceSearch = false
      this.$refs.FilterSearch.resetFilter()
      this.checkActive = false
      this.getWallets()
      this.overlay = false
    }
  },
  created() {},
  methods: {
    enterFilter(key) {
      if (key.key == 'Enter' && this.showAdvaceSearch == false) {
        this.applyFilter()
      }
    },
    toggleAdvaceSearch() {
      this.showAdvaceSearch = !this.showAdvaceSearch
    },
    getdate(date) {
      return moment(date).format(this.formatdate)
    },
    getformatdate(date) {
      return moment(date).format('DD MMM YYYY')
    },
    getformattime(date) {
      return moment(date).format('HH:mm')
    },
    applyFilter(filter) {
      if (
        filter?.status != null ||
        (filter?.startdate?.length > 0 && filter?.enddate?.length > 0)
      ) {
        this.checkActive = true
      } else {
        this.checkActive = false
      }
      this.showAdvaceSearch = false
      this.$store.commit('setLoading', true)
      this.page = 1
      this.searchObject.cardid =
        this.optionssearch == 'cardid' ? this.search : ''
      this.searchObject.issuer =
        this.optionssearch == 'issuer' ? this.search : ''
      this.searchObject.status = filter?.status ? filter?.status : ''
      this.searchObject.startdate = filter?.startdate
        ? dayjs(filter?.startdate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.searchObject.enddate = filter?.enddate
        ? dayjs(filter?.enddate, this.formatdate).format('YYYY-MM-DD')
        : ''
      this.showDateFilter = false
      this.getWallets().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getWallets() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetWallets(
          this.$route.params.id,
          this.searchObject.cardid,
          this.searchObject.issuer,
          this.searchObject.status,
          this.searchObject.startdate,
          this.searchObject.enddate,
        ).subscribe(
          res => {
            this.datawallets = res.result
            resolve(this.datawallets)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScrollModal(event) {
      if (!this.nomoremodal && !this.overlaytablemodal && this.isLoadmore) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytablemodal = true
          this.modalPage++
          this.getWalletDetail().then(() => {
            this.overlaytablemodal = false
          })
        }
      }
    },
    showModal(record) {
      this.isLoadmore = false
      this.modalCardId = record.card_id
      this.has_conversion = record.has_conversion
      if (record.has_conversion) {
        this.cardcolumn = this.cardcolumnmaster.filter(
          x =>
            x.key != 'stamp_count' &&
            x.key != 'point' &&
            x.key != 'amount' &&
            x.key != 'description' &&
            x.key != 'source_amount' &&
            x.key != 'destination_amount',
        )
      } else {
        this.cardcolumn = this.cardcolumnmaster.filter(x =>
          x.CardTypes.includes(record.type),
        )
      }
      this.modalPage = 1
      this.dataCard = []
      this.getWalletDetail().then(() => {
        this.isLoadmore = true
        this.modalWallet = true
      })
    },
    getWalletDetail() {
      var card_id = this.modalCardId
      return new Promise(resolve => {
        BzbsUser.apiGetWalletDetail(
          this.$route.params.id,
          card_id,
          this.modalPage,
        ).subscribe(data => {
          if (this.modalPage == 1) {
            this.dataCard = []
          }
          _.forEach(data.result, item => {
            this.dataCard.push(item)
          })
          if (data.result.length < 10) {
            this.nomoremodal = true
          } else {
            this.nomoremodal = false
          }
          resolve(this.dataCard)
        })
      })
    },
    callAPiExportWallet(value) {
      this.paramsWallet.filter.appid = ConfigManager.getAppId()
      ;(this.paramsWallet.name = value.filename),
        (this.paramsWallet.description = value.description),
        (this.paramsWallet.filter.user_id = this.$route.params.id),
        (this.paramsWallet.filter.card_id = this.searchObject.cardid),
        (this.paramsWallet.filter.issuer = this.searchObject.issuer),
        (this.paramsWallet.filter.type = this.searchObject.status
          ? this.searchObject.status
          : null),
        (this.paramsWallet.filter.from_expired_date = this.searchObject
          .startdate
          ? this.searchObject.startdate
          : null),
        (this.paramsWallet.filter.to_expired_date = this.searchObject.enddate
          ? this.searchObject.enddate
          : null)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostWalletExport(this.paramsWallet)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      }).then(() => {
        this.showExportModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    callAPiExportWalletDetail(value) {
      this.paramsWalletDetail.filter.appid = ConfigManager.getAppId()
      this.paramsWalletDetail.name = value.filename
      this.paramsWalletDetail.description = value.description
      this.paramsWalletDetail.filter.card_id = this.modalCardId
      this.paramsWalletDetail.filter.user_id = this.$route.params.id
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostWalletDetailExport(this.paramsWalletDetail)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      }).then(() => {
        this.showExportWalletDetailModal = false
        setTimeout(() => {
          this.showPrepareModal = true
        }, 500)
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.callAPiReportCoupon().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    handleCancel() {
      this.modalWallet = false
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },
    exportWalletReport() {
      this.showExportWalletModal = true
    },
    exportWalletDetailReport() {
      this.showExportWalletDetailModal = true
    },
    toggleDisplayFieldsRedeem(level_name) {
      this.dataLevelname = level_name
      this.showDisplayFieldsRedeem = !this.showDisplayFieldsRedeem
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }

  .nav-search .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .line {
    width: 2px;
    height: 64px;
    float: right;
    background-color: $color-grey-line;
    position: absolute;
    top: 14%;
    left: calc(100% - 158px);
  }

  .nav-search {
    .group-btn-left {
      float: right;
      display: flex;
      padding-right: 5px;
    }

    .group-btn {
      float: right;
      margin-left: 16px;
    }
  }

  .wrap-table {
    .ant-table-wrapper {
      min-width: inherit;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .card-type {
    text-align: center;
    width: 80px;
    color: #1d5ffb;
    border: 1px solid#1d5ffb;
    border-radius: 20px;
  }
}

.select-search {
  width: 130px !important;
}
</style>
<style lang="scss">
.font-level {
  color: #f9a601;
  text-decoration: underline;
}

.font-view {
  color: #f79443;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-position: under;

  &:hover {
    color: #f79443;
    text-decoration: underline;
    text-decoration-style: dashed;
    text-underline-position: under;
  }
}

.card-type-stamp {
  text-align: center;
  width: 80px;
  color: #1d5ffb;
  border: 1px solid#1d5ffb;
  border-radius: 20px;
}

.card-type-wallet {
  text-align: center;
  width: 80px;
  color: #ff9f00;
  border: 1px solid#ff9f00;
  border-radius: 20px;
}

.complete {
  text-align: center;
  width: 80px;
  color: #22ba20;
  border: 1px solid#22ba20;
  border-radius: 20px;
}

.popover2 {
  width: 720px;
}

.popovermodal {
  color: #aaaaaa;
}

.popovermodal-content {
  color: #666666;
}

.center {
  text-align: -webkit-center;
}

.max-content {
  width: max-content;
}
</style>
