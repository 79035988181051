<template>
  <AppLayout :disable-menu="true">
    <div class="page-select-applications">
      <div class="d-flex justify-content-between">
        <TitleBreadcrumbs
          class="ml-5"
          title="Select Applications"
          :countapp="applications.length"
        />
        <div v-if="checkMenuCall" class="px20 pt-3">
          <div>
            <a-button @click="goToCCManagement()"
              >Call Center Management</a-button
            >
          </div>
        </div>
      </div>
      <a-row>
        <a-col :xs="24" :md="12">
          <Toolbar ref="onSearch" :mode="mode" @onSearch="onSearch($event)" />
        </a-col>
        <a-col :xs="24" :md="12" class="box-filter px20">
          <a-row type="flex" :gutter="8" class="justify-content-end">
            <a-col>
              <a-form-item
                name="filter"
                label="Filter"
                class="select-filter d-flex"
              >
                <a-select
                  v-model:value="filter"
                  class="w-100"
                  @change="changeFilter"
                >
                  <a-select-option value="OrderbyAsc">{{
                    'Order by: A-Z'
                  }}</a-select-option>
                  <a-select-option value="OrderbyDesc">{{
                    'Order by: Z-A'
                  }}</a-select-option>
                  <!-- <a-select-option value="OrderLastUpdate">{{
                    'Order by: Last update'
                  }}</a-select-option>
                  <a-select-option value="OrderCreateDate">{{
                    'Order by: Create date'
                  }}</a-select-option> -->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col>
              <a-radio-group v-model:value="type" button-style="solid">
                <a-radio-button value="card">
                  <appstore-outlined />
                </a-radio-button>
                <a-radio-button value="table">
                  <unordered-list-outlined />
                </a-radio-button>
              </a-radio-group>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <div class="flex items-stretch w-full layout-outer">
        <div
          class="h-full w-full flex-auto max-w-full overflow-y-auto overflow-x-hidden"
        >
          <div class="page-select-app overflow-x-hidden flex flex-col">
            <div class="wrap-select-app">
              <div v-if="skeletonLoading" class="content-wrapper">
                <a-card>
                  <a-skeleton active />
                </a-card>
              </div>
              <div v-else class="content-wrapper">
                <Cards ref="Card" v-if="type == 'card'" :app="applications" />
                <Table v-if="type == 'table'" :app="applications" />
              </div>
            </div>
          </div>
        </div>
        <div class="flyout-zone">
          <notification-panel />
          <user-account-panel />
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import _ from 'lodash'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
// common components
// import TopBar from '@/components/Layout/TopBar/TopBar.vue'
// import SidebarMenu from '@/module/Layout/LeftMenu/component/SidebarMenu.vue'
import NotificationPanel from '@/module/Layout/LeftMenu/component/NotificationPanel.vue'
import UserAccountPanel from '@/module/Layout/LeftMenu/component/UserAccountPanel.vue'
// import Footer from '@/components/Layout/Footer/Footer.vue'
import Toolbar from '../component/Toolbar.vue'
import Cards from '../component/Cards.vue'
import Table from '../component/Table.vue'
import { Application } from '@/core/Application/model/Application'
import AppLayout from '@/components/Layout/default.vue'
import BzbsApp from '@/core/Application/callapi/BzbsApp'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
var cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    // TopBar,
    // Footer,
    AppLayout,
    Toolbar,
    // SidebarMenu,
    TitleBreadcrumbs,
    NotificationPanel,
    UserAccountPanel,
    Cards,
    Table,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: 'select-applications',
          breadcrumbName: 'Select Applications',
        },
      ],
      menuproduct:
        JSON.parse(localStorage.getItem('checkcreateticket')) == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('checkcreateticket')),
      datasetFeatures: JSON.parse(localStorage.getItem('setFeatures')),
      sidebarHeaderconfig: { bgColor: '#fff' },
      sidebarMenu: [],
      sidebarOpen: true,
      overlay: true,
      applications: [],
      rawapp: [],
      mode: 'card',
      permission: [],
      checkMenuCall: null,
      applist: [],
      dataad: [],
      dataapp: [],
      filter: 'OrderbyAsc',
      type: 'card',
      skeletonLoading: true,
      current: 1,
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'My Applications',
        },
      ]
    },
  },
  mounted() {
    if (this.menuproduct) {
      this.checkMenuCall = this.menuproduct.find(x =>
        x.features.find(i => i.name.includes('Call-Center-Management')),
      )
    }
    // this.checkMenuCall = this.permission.menus.find(x => x.name == 'callcenter')
  },
  created() {
    this.ininit()
  },
  methods: {
    ininit() {
      this.sidebarMenu = [
        {
          text: 'Applications',
          badge: null,
          to: '/select-applications',
          remark: 'Please select application<br>to continue',
        },
      ]
      cache.removeCache('app')
      localStorage.removeItem('Useraccount')
      this.SideMenuStore.methods.open()
      if (
        localStorage.getItem('applications') &&
        localStorage.getItem('rawapp')
      ) {
        this.applications = JSON.parse(localStorage.getItem('applications'))
        this.rawapp = JSON.parse(localStorage.getItem('rawapp'))
        this.skeletonLoading = false
        this.callGetApp().then(() => {
          this.applications = JSON.parse(localStorage.getItem('applications'))
          this.rawapp = JSON.parse(localStorage.getItem('rawapp'))
          this.$refs.onSearch.onSearch()
          this.skeletonLoading = false
        })
        this.$store.commit('setLoading', false)
      } else {
        this.callGetApp().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
    getad() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            localStorage.removeItem('applist')
            this.dataad = res.result
            this.dataapp = this.dataad.applications.filter(x =>
              x.features.find(y => y.name.includes('Call-Center')),
            )
            if (this.dataapp) {
              this.applist = this.dataapp.map(x => {
                return {
                  appId: x.application_id,
                  appName: x.name,
                }
              })
              localStorage.setItem(
                'permissionappforcallcenter',
                JSON.stringify(this.dataapp),
              )
              localStorage.setItem('applist', JSON.stringify(this.applist))
            }
            resolve(this.dataad)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    goToCCManagement() {
      this.$store.commit('setLoading', true)
      this.getad().then(() => {
        this.$router.push({
          name: 'searchuser',
        })
      })
    },
    goToSerialEDC() {
      this.$router.push({
        name: 'searchserial',
      })
    },
    onSideBarToggle(val) {
      this.sidebarOpen = val
    },
    onSearch(value) {
      this.current = 1
      this.$refs.Card.onChangePage(1)
      console.log(this.applications)
      if (value != null) {
        console.log(this.applications.length)
        console.log(this.rawapp.length)
        this.applications = _.filter(this.rawapp, app => {
          console.log(
            'app',
            app.agency_name.includes(value ? value.toLowerCase() : value),
          )
          if (
            app.agency_id.toString().includes(value) ||
            app.agency_name
              .toLowerCase()
              .includes(value ? value.toLowerCase() : value) ||
            app.appid.toString().includes(value) ||
            app.name.toLowerCase().includes(value ? value.toLowerCase() : value)
          ) {
            return app
          }
        })
        console.log(this.applications)
      } else {
        return this.applications
      }
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    changeMode(val) {
      this.mode = val
    },
    changeFilter() {
      this.skeletonLoading = true
      this.callGetApp().then(() => {
        this.skeletonLoading = false
      })
    },
    callGetApp() {
      var filter_application = this.filter
      return new Promise((resolve, reject) => {
        BzbsApp.apiGetApplication(filter_application).subscribe(
          res => {
            this.applications = []
            this.rawapp = []
            res.result.map(item => {
              this.applications.push(new Application(item))
              this.rawapp.push(new Application(item))
            })
            localStorage.setItem(
              'applications',
              JSON.stringify(this.applications),
            )
            localStorage.setItem('rawapp', JSON.stringify(this.rawapp))
            this.skeletonLoading = false
            resolve(this.applications)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';

.left-menu-width {
  // flex: 0 0 $left-menu-width;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 8%);
  width: $left-menu-width;
  z-index: 15;

  &.collapse {
    // width: 44px;
    width: 64px;
    // flex: 0 0 55px;
  }
}

.page-select-app {
  .wrap-select-app {
    padding: 0px 20px 0px 20px;
  }

  .box-select-app {
    padding: 0px 20px 20px 20px;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';

.px20 {
  padding: 0 20px;
}
</style>
