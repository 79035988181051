import { createApp } from 'vue'
import Antd from 'ant-design-vue/lib'
import App from './App.vue'
import router from './router'
import store from './store'
import * as rxjs from 'rxjs'
import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

// import AppConfig from "@/config/vueconfig.js";
import ErrorApiMixin from '@/mixin/ErrorApiMixin.js'
import CommonMixin from '@/mixin/CommonMixin.js'
import Tracking from '@/plugins/Tracking.js'
import Filter from '@/plugins/Filter.js'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
// import 'ant-design-vue/dist/antd.min.css'
import './index.css'
import '@splidejs/splide/dist/css/splide.min.css'
import VueApexCharts from 'vue3-apexcharts'
import VueGoogleMaps from '@fawmi/vue-google-maps'

//#region global component
import VButton from '@/components/Button/Button.vue'
import VSvgIcon from '@/components/Icon/SvgIcon'
import VOverlay from '@/components/Overlay/Overlay'
import VLoading from '@/components/Loading/Loading'
import VLoadingTable from '@/components/Loading/LoadingTable'
import VDialog from '@/components/Dialog/Dialog'
import VCard from '@/components/Card/VCard'
//#endregion
import 'ant-design-vue/dist/antd.less'
// import 'ant-design-vue/dist/antd.css';

// import ClickOutsideDirective from "@/plugins/directive/clickOutside"
import VueClickAway from 'vue3-click-away'
//#endregion
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import axios from 'axios'
import {
  SearchOutlined,
  CloseOutlined,
  FileExcelOutlined,
  ControlOutlined,
  ScheduleOutlined,
  SolutionOutlined,
  PlusOutlined,
  TagOutlined,
  RightOutlined,
  CalendarOutlined,
  UpOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UnorderedListOutlined,
  DownloadOutlined,
  CustomerServiceOutlined,
  SettingOutlined,
  ImportOutlined,
  QuestionCircleOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  EditOutlined,
  EyeOutlined,
  PhoneOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
  ArrowRightOutlined,
  SyncOutlined,
  CloseCircleFilled,
  UserOutlined,
  TagsOutlined,
  AppstoreOutlined,
  MoreOutlined,
  WarningOutlined,
  FileOutlined,
  CheckCircleOutlined,
  ArrowLeftOutlined,
  BookOutlined,
  FileTextOutlined,
  ArrowDownOutlined
} from '@ant-design/icons-vue'

const checkToken = async configreq => {
  let testRequest = config.tokenRequest
  testRequest.account = Cache.getCache('msUserData')
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    let response
    try {
      const date = new Date()
    date.setHours(date.getHours() + 24)
    var expires = '; expires=' + date.toUTCString()
      response = await myMSAL.acquireTokenSilent(testRequest)
      document.cookie = 'msToken=' + response.accessToken + expires
      configreq.headers.Authorization = 'Bearer ' + response.accessToken
      resolve(configreq)
    } catch (e) {
      document.cookie.split(';').forEach(function (c) {
        document.cookie = c
          .replace(/^ +/, '')
          .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
      })
      localStorage.clear()
      sessionStorage.clear()
      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
      }
      myMSAL.logoutRedirect(logoutRequest)
      reject(e)
    }
  })
}
axios.interceptors.request.use(async config => {
  let newconfig = await checkToken(config)
  return newconfig
})
createApp(App)
  .use(BootstrapVue3)
  .use(rxjs)
  .use(Antd)
  .use(store)
  .use(router)
  .use(Tracking)
  .use(Filter)
  .use(Filter)

  // .use(VueClipboard)
  .use(VueClickAway)
  .use(VueApexCharts)
  .use(Vue3Lottie)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCS3hEafX_r_XFeEe33wX5D5XmE9RnC2RU',
      // language: 'de',
    },
  })
  .mixin(CommonMixin)
  .mixin(ErrorApiMixin)
  //#region registe global component
  .component('VBtn', VButton)
  .component('VSvgIcon', VSvgIcon)
  .component('VOverlay', VOverlay)
  .component('VLoading', VLoading)
  .component('VLoadingTable', VLoadingTable)
  .component('VDialog', VDialog)
  .component('VCard', VCard)
  .component('apexchart', VueApexCharts)

  .component('SearchOutlined', SearchOutlined)
  .component('CloseOutlined', CloseOutlined)
  .component('FileExcelOutlined', FileExcelOutlined)
  .component('ControlOutlined', ControlOutlined)
  .component('ScheduleOutlined', ScheduleOutlined)
  .component('SolutionOutlined', SolutionOutlined)
  .component('PlusOutlined', PlusOutlined)
  .component('TagOutlined', TagOutlined)
  .component('RightOutlined', RightOutlined)
  .component('CalendarOutlined', CalendarOutlined)
  .component('UpOutlined', UpOutlined)
  .component('DownOutlined', DownOutlined)
  .component('MenuFoldOutlined', MenuFoldOutlined)
  .component('MenuUnfoldOutlined', MenuUnfoldOutlined)
  .component('UnorderedListOutlined', UnorderedListOutlined)
  .component('DownloadOutlined', DownloadOutlined)
  .component('CustomerServiceOutlined', CustomerServiceOutlined)
  .component('SettingOutlined', SettingOutlined)
  .component('ImportOutlined', ImportOutlined)
  .component('QuestionCircleOutlined', QuestionCircleOutlined)
  .component('CaretRightOutlined', CaretRightOutlined)
  .component('CaretDownOutlined', CaretDownOutlined)
  .component('EditOutlined', EditOutlined)
  .component('EyeOutlined', EyeOutlined)
  .component('PhoneOutlined', PhoneOutlined)
  .component('DeleteOutlined', DeleteOutlined)
  .component('CloseCircleOutlined', CloseCircleOutlined)
  .component('ArrowRightOutlined', ArrowRightOutlined)
  .component('SyncOutlined', SyncOutlined)
  .component('CloseCircleFilled', CloseCircleFilled)
  .component('UserOutlined', UserOutlined)
  .component('TagsOutlined', TagsOutlined)
  .component('AppstoreOutlined', AppstoreOutlined)
  .component('MoreOutlined', MoreOutlined)
  .component('WarningOutlined', WarningOutlined)
  .component('FileOutlined', FileOutlined)
  .component('CheckCircleOutlined', CheckCircleOutlined)
  .component('ArrowLeftOutlined', ArrowLeftOutlined)
    .component('BookOutlined', BookOutlined)
  .component('ArrowDownOutlined', ArrowDownOutlined)
  .component('FileTextOutlined', FileTextOutlined)
  

  // import BzbsApi from '@/core/Http/BzbsApi.js'
  // import ConfigManager from '@/config/ConfigManager.js'
  // import CacheHelper from '@/helper/CacheHelper.js'
  // import Config from '@/config/vueconfig.js'
  // var Cache = new CacheHelper(ConfigManager.getCacheType())

  //#endregion
  // .directive('click-outside', ClickOutsideDirective)
  // new Vue({
  //   router,
  //   store,
  //   watch: {},
  //   created() {
  //     if (!Cache.getCache('bzbsConfig')) {
  //       var url
  //       if (window.location.hostname == 'localhost') {
  //         url = 'https://dev-ad-usermanagement-api.azurewebsites.net/api/version'
  //       } else {
  //         url = window.location.origin + '/api/version'
  //       }
  //       BzbsApi.connectBzbsGetBlob(url).then(res => {
  //         var response = res.data.api_setting
  //         var env = res.data.api_setting.build
  //         var texterror = 'Please set config '
  //         let errorArray = {}
  //         let catchConfig = {}
  //         if (env !== 'dev') {
  //           catchConfig.apiUrl = ConfigManager.getUsermgrAPI()
  //           catchConfig.baseUrl = ConfigManager.getUsermgrUrl()
  //           catchConfig.blobUrl = ConfigManager.getBlobUrl()
  //           catchConfig.campaignUrl = Config().CampaignManagementUrl
  //         } else {
  //           Object.keys(response).forEach(i => {
  //             if (response[i]) {
  //               if (response[i].includes(env) || i == 'baseVersion') {
  //                 catchConfig[i] = response[i]
  //               } else {
  //                 errorArray[i] = i
  //               }
  //             } else {
  //               errorArray[i] = i
  //             }
  //           })
  //         }
  //         if (
  //           Object.keys(errorArray).length > 0 &&
  //           window.location.pathname !== '/error/notfindconfig'
  //         ) {
  //           this.errorApi(texterror.concat(errorArray))
  //         } else {
  //           Cache.setCache('bzbsConfig', catchConfig)
  //           this.$router.go()
  //         }
  //       })
  //     }
  //   },
  //   mounted() {},
  //   render: h => h(App),
  // })

  .mount('#app')
