<template>
  <a-layout-header :class="[{ collapsed: collapsed }, $store.state.themecolor]">
    <div class="row mx-0">
      <!-- <div class="col-6 pl-0">
        <div class="action-col d-sm-block d-flex align-items-center">
          <a-select
            v-if="appList && $route.params.appid"
            :default-value="$route.params.appid"
            class="app-select"
            @change="handleChange"
          >
            <a-select-option
              v-for="(item, index) in appList"
              :key="index"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
      </div> -->
      <!-- <div class="tag-beta">
        <img src="@/assets/imgs/beta-version-2.png" />
      </div> -->
      <div class="col-12 d-flex justify-content-end pr-0">
        <div class="action-col d-flex">
          <!-- <a
            class="announcement"
            @click="openAllAnnouncement"
          >
            <a-badge :count="sumUnRead">
              <img :src="img_noti" />
            </a-badge>
          </a> -->
          <a-dropdown v-if="user" :trigger="['click']" class="dropdownpad">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              <div class="account-text w-auto">
                <span class="account-name">
                  {{
                    user.idTokenClaims.given_name
                      ? user.idTokenClaims.given_name
                      : user.name
                  }}
                  {{
                    user.idTokenClaims.family_name
                      ? user.idTokenClaims.family_name
                      : ''
                  }}
                </span>
                <span class="account-role">
                  {{ user.username }}
                </span>
              </div>
              <i class="ibzb-down"></i>
            </a>
            <template #overlay>
              <a-menu class="header-dropdown">
                <!-- <a-menu-item v-if="!showBtnAddMS">
                <a @click="gotoMSflow">
                  <span class="text">
                    go to ad
                  </span>
                </a>
              </a-menu-item> -->
                <a-menu-item>
                  <a class="d-flex" :href="userManualURL" target="_blank">
                    <img :src="img_manual" />
                    <span class="text">User Manual</span>
                  </a>
                </a-menu-item>
                <a-menu-item
                  v-for="item in getAccountMenuList()"
                  :key="item.to.name"
                >
                  <router-link :to="item.to">
                    <span class="text">{{ item.title }}</span>
                  </router-link>
                </a-menu-item>
                <a-menu-item class="logout">
                  <a class="d-flex justify-content-center" @click="logout">
                    <span class="text">Sign Out</span>
                    <img :src="img_logout" />
                  </a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <div v-else>
            <router-link :to="{ name: 'login' }" class="color-primary">
              Login
            </router-link>
          </div>
          <a
            v-if="showFuncAnnouncement"
            class="announcement"
            @click="openAllAnnouncement"
          >
            <a-badge :count="sumUnRead">
              <span class="anticon">
                <i class="ibzb-noti"></i>
              </span>
            </a-badge>
          </a>
        </div>
      </div>
    </div>
    <ModalAnnouncement
      v-if="showModalEmergency"
      :isShow="showModalEmergency"
      :data="dataEmergency"
      @closeModalAnnouncement="closeModalAnnouncement('Emergency')"
      @checkBox="checkBox($event)"
    />
    <ModalAnnouncement
      v-if="showModalAnnouncement"
      :isShow="showModalAnnouncement"
      :data="dataAnnouncement"
      @closeModalAnnouncement="closeModalAnnouncement('Announcement')"
      @checkBox="checkBox($event)"
    />
    <!-- TermAndCondition -->
    <ModalTermAndCondition
      v-if="showModalTerm"
      :is-show="showModalTerm"
      :data="dataTerm"
      @closeModal="closeModalTermAndCondition()"
      @checkBox="checkBoxTermAndCondition($event)"
    />
    <a-modal
      v-model:visible="showModalAnnouncementList"
      width="600px"
      wrap-class-name="modal-announcement-list"
      :closable="false"
      :centered="false"
      :mask="false"
      :mask-closable="true"
      :ok-button-props="{ style: { display: 'none' } }"
      :cancel-button-props="{ style: { display: 'none' } }"
      @cancel="closeModal"
    >
      <ModalAnnouncementList
        :data="dataListAnnouncement"
        :sum-read="sumRead"
        :sum-un-read="sumUnRead"
        @getAllAnnouncement="getAllListAnnouncement()"
      />
    </a-modal>
    <div
      v-if="showEmergency"
      class="alert-emergency"
      @click="showEmergencyDetail()"
    >
      <span class="text">
        <img :src="info" alt="info" class="icon" />
        {{ this.dataEmergency.title }}</span
      >
    </div>
  </a-layout-header>
  <v-breadcrumb
    :breadcrumb="breadcrumb"
    :class="showEmergency ? 'mt-5' : ''"
    :propsMode="breadcrumbPropsMode"
  >
    <span class="text-sm text-black-1" v-text="defaultTitle"></span>
  </v-breadcrumb>
  <template v-if="hasSlot('toolbar')">
    <div class="mx-4 mb-4 b-6 bg-white px-4">
      <slot name="toolbar"></slot>
    </div>
  </template>
</template>
<script>
import moment from 'moment'
import CommonMixin from '@/mixin/CommonMixin'
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
import ModalAnnouncement from '@/components/Layout/Header/ModalAnnouncement'
import ModalAnnouncementList from '@/components/Layout/Header/ModalListAnnouncement'
import ModalTermAndCondition from '@/components/Layout/Header/ModalTermAndCondition.vue'
import LocaleMixin from '@/mixin/LocaleMixin'
// import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
const usermanual = ConfigManager.getUsermanualUrl()
// const myMSAL = new Msal.PublicClientApplication(config.msalConfig)

export default {
  name: 'Header',
  mixins: [CommonMixin, LocaleMixin],
  props: {
    appList: Array,
    breadcrumb: {
      type: Array,
      default: () => [],
    },
    breadcrumbPropsMode: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    VBreadcrumb: Breadcrumb,
    ModalAnnouncement,
    ModalAnnouncementList,
    ModalTermAndCondition,
    // VAvatar: Avatar,
  },
  data() {
    return {
      currentRoute: this.$router.currentRoute,
      img_logout:
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/logout.svg',
      img_manual:
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/manual.svg',
      img_noti: config.bzbBlobUrl + '/themebackoffice/assets/3/img/noti.svg',
      info: config.bzbBlobUrl + '/themebackoffice/assets/3/img/info.svg',
      showBtnAddMS: false,
      userManualURL: '',
      sumRead: 0,
      sumUnRead: 0,
      showFuncAnnouncement: false,
      showModalAnnouncement: false,
      showEmergency: false,
      showModalEmergency: false,
      showModalAnnouncementList: false,
      dataAnnouncement: {},
      dataEmergency: {},
      dataListAnnouncement: {},
      checkPostType: '',
      conditionsCheckBox: false,
      dataTerm: {},
      showModalTerm: false,
      checkBoxTerm: false,
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(newValue) {
        this.$store.commit('setCollapsed', newValue)
      },
    },
    user: {
      get() {
        return Cache.getCache('msUserData')
      },
    },
  },
  created() {
    // if (Cache.getCache('is_MSUser') == 'true') {
    //   this.showBtnAddMS = true
    // }
    this.getTermAndCondition()
    if (
      this.currentRoute.name != 'select_application' &&
      this.currentRoute.name != 'unauthorized' &&
      this.currentRoute.name != 'callcentermanagement' &&
      config.businessRule.webFor.TH
    ) {
      this.showFuncAnnouncement = true
      this.getAllAPI()
    }
    if (!config.businessRule.webFor.TH) {
      this.showEmergency = false
      this.$store.commit('showEmergency', this.showEmergency)
    }
    this.userManualURL = usermanual
  },
  methods: {
    hasSlot: function (name = 'default') {
      return !!this.$slots[name]
    },
    getAccountMenuList() {
      return []
    },
    getAllAPI() {
      this.getTermAndCondition().then(() => {
        this.getAllAnnouncement()
      })
    },
    getAllAnnouncement() {
      return new Promise(resolve => {
        Promise.all([
          this.getLastAnnouncement(),
          this.getLastAnnouncement(true),
        ]).then(() => {
          this.getAllListAnnouncement().then(() => {
            resolve()
          })
        })
      })
    },
    getLastAnnouncement(hasEmergency) {
      return new Promise((resolve, reject) => {
        let params = {
          role_name: null,
        }
        if (hasEmergency) {
          params.is_emergency = true
        } else {
          if (
            this.$store.state.isCallAnnouncement ||
            Cache.getCache('callAnnouncement') == moment().format('YYYY-MM-DD')
          ) {
            resolve()
            return
          }
        }
        BzbsUser.apiGetLastAnnouncement(params).subscribe(res => {
          if (hasEmergency) {
            this.dataEmergency = res.result.announcement
            if (this.dataEmergency) {
              this.showEmergency = true
            } else {
              this.showEmergency = false
            }
            this.$store.commit('showEmergency', this.showEmergency)
          } else {
            this.dataAnnouncement = res.result.announcement
            this.$store.commit('callAnnouncement', true)
            if (this.dataAnnouncement) {
              // Cache.setCache('callAnnouncement', moment().format('YYYY-MM-DD'))
              // this.showModalAnnouncement = true
            } else {
              this.showModalAnnouncement = false
            }
          }
          resolve()
        })
        error => {
          reject(error)
        }
      })
    },
    getAllListAnnouncement() {
      let params = {
        role_name: null,
        limit: 50,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetListAnnouncement(params).subscribe(res => {
          this.dataListAnnouncement = res.result
          if (res) {
            var Read = _.filter(this.dataListAnnouncement, item => {
              if (!item.is_unread) {
                return item
              }
            })
            var UnRead = _.filter(this.dataListAnnouncement, item => {
              if (item.is_unread) {
                return item
              }
            })
            this.sumRead = Read.length
            this.sumUnRead = UnRead.length
          }
          resolve(res.result)
        })
        error => {
          reject(error)
        }
      })
    },
    closeModal() {
      this.showModalAnnouncementList = false
      this.getAllListAnnouncement()
    },
    checkBox(value) {
      this.conditionsCheckBox = value
    },
    showEmergencyDetail() {
      this.showModalEmergency = true
    },
    openAllAnnouncement() {
      this.showModalAnnouncementList = true
    },
    closeModalAnnouncement(type) {
      return new Promise((resolve, reject) => {
        let params = {
          id:
            type == 'Emergency'
              ? this.dataEmergency.id
              : this.dataAnnouncement.id,
          is_accept: this.conditionsCheckBox,
        }
        BzbsUser.apiReadAnnouncement(params)
          .then(() => {
            if (type == 'Emergency') {
              this.showModalEmergency = false
              this.getLastAnnouncement(true)
            } else {
              this.showModalAnnouncement = false
            }
            this.conditionsCheckBox = false
            this.getAllListAnnouncement()
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getTermAndCondition() {
      this.dataTerm = {}
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTermAndCondition().subscribe(res => {
          if (res.result) {
            this.dataTerm = res.result
            this.showModalTerm = res.result ? true : false
          }
          resolve(res)
        })
        error => {
          reject(error)
        }
      })
    },
    checkBoxTermAndCondition(value) {
      this.checkBoxTerm = value
    },
    closeModalTermAndCondition() {
      return new Promise((resolve, reject) => {
        let params = {
          id: this.dataTerm.id,
          is_accept: this.checkBoxTerm,
        }
        BzbsUser.apiReadAnnouncement(params)
          .then(() => {
            this.checkBoxTerm = false
            this.showModalTerm = false
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout() {
      this.value = false
      Cache.setCache('loading', false)
      this.$router.push({ name: 'logout' })
    },
    gotoSelectZone() {
      this.$router.push({ name: 'signinzone' })
    },
    handleChange(e) {
      this.$router.replace({
        name: this.$route.name,
        params: { appid: e },
      })
      location.reload()
    },
    gotoMSflow() {
      Cache.set('msStatus', 'migrate')
      Cache.set('redirectUrl', this.$route.path)
      this.$router.push({ name: 'redirect-ms' })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/_mixin.scss';
@import '@/style/base/base.scss';

.tag-beta {
  width: 150px;
  position: absolute;
  top: 50%;
  left: 52%;
  z-index: 2;
  transform: translate(-50%, -50%);
  color: $color-primary;

  img {
    transform: rotate(7deg);
  }

  @include breakpoint-max($tablet-xs) {
    display: none;
  }
}
</style>
