<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40 FAQ"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-subject bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Create Question</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <div class="d-flex align-items-center justify-content-between">
                <h4>Question:</h4>
                <!-- <a-button
                  type="primary"
                  style="width: 150px"
                  @click="AddTableTopicTypes()"
                  ><plus-outlined /> Add Question</a-button
                > -->
              </div>
              <div>
                <div class="mt-3">
                  <a-input v-model:value="question" />
                  <div class="mt-3">
                    <div class="d-flex justify-content-between">
                      <div class="mt-2">Answer :</div>
                      <div>
                        <a-button
                          v-if="answer_detail.length < 5"
                          type="primary"
                          @click="AddTableTopicDetail()"
                          ghost
                          >+ Add Answer</a-button
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-for="(item, index2) of answer_detail"
                    :key="`tabletopicdetailindex-${index2}`"
                  >
                    <div class="pt-2">
                      <a
                        v-if="index2 != 0"
                        class="close"
                        @click="deleteAnswer(index2)"
                      >
                        <close-outlined />
                      </a>
                      <a-input v-model:value="item.topictype" />
                      <a-upload
                        v-model:file-list="item.fileList"
                        list-type="picture"
                        :max-count="1"
                        :beforeUpload="[
                          function () {
                            false
                          },
                        ]"
                      >
                        <a class="text-link">+ Add Attachment</a>
                      </a-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-none px-4 py-3 right">
            <a-button class="btn-create" type="default" @click="dismiss(true)">
              Cancel
            </a-button>
            <a-button
              class="ml-3 btn-create"
              type="primary"
              @click="PostCreateFaq()"
            >
              Create
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      question: '',
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      topic_types: [{}],
      answer_detail: [{}],
      application: null,
      option_application: [
        {
          label: 'Abbot',
          value: 'Abbot',
        },
      ],
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    // AddTableTopicTypes() {
    //   var itemtable = {}
    //   this.topic_types.push(itemtable)
    // },
    PostCreateFaq() {
      this.$store.commit('setLoading', true)
      var items = this.answer_detail.map(x => ({
        answer: x.topictype,
        files: x.fileList ? x.fileList[0].originFileObj : null,
      }))
      const formData = {
        appid: ConfigManager.getAppId(),
        faq: this.question,
        answerdetails: items,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateFaq(formData)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.dismiss()
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$emit('PostGetFAQ')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            // this.errorApi(error)
            reject(error)
          })
      })
    },
    AddTableTopicDetail() {
      var itemtable = {}
      this.answer_detail.push(itemtable)
    },
    deleteAnswer(index) {
      this.answer_detail.splice(index, 1)
    },
    dismiss: function () {
      this.question = ''
      this.answer_detail = [{}]
      this.topic_types = [{}]
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
}
.pointer {
  cursor: pointer;
}
.w-log-subject {
  width: 35rem !important;
  .content-topic {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
  .close{
    color: none !important;
    float: right ;
  }
}
</style>
