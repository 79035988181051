<template>
  <div class="w-full min-h-full">
    <nav class="nav-search mb-3">
      <div class="group-btn-left d-flex">
        <div class="mr-3">
          <a-select
            class="select-search"
            v-model:value="years"
            :options="year_dropdown"
            @change="changeYear"
          />
        </div>
        <div class="pt-2">
          <a-checkbox-group v-model:value="quarter">
            <a-checkbox :value="0">{{ years - 1 }} / Q4</a-checkbox>
            <a-checkbox :value="1" :disabled="disabledmonth(1)">Q1</a-checkbox>
            <a-checkbox :value="2" :disabled="disabledmonth(2)">Q2</a-checkbox>
            <a-checkbox :value="3" :disabled="disabledmonth(3)">Q3</a-checkbox>
            <a-checkbox :value="4" :disabled="disabledmonth(4)">Q4</a-checkbox>
            <a-checkbox :value="5" :disabled="disabledmonth(5)"
              >{{ years + 1 }} / Q1</a-checkbox
            >
          </a-checkbox-group>
        </div>
        <!-- <a-input
          v-model:value="search"
          placeholder="Search Level"
          class="input-search"
          ><template #prefix
            ><search-outlined
              class="pl-3"
              style="color: rgba(0, 0, 0, 0.25)" /></template
        ></a-input> -->
        <a-button
          :disabled="!(quarter && quarter.length > 0)"
          type="primary"
          class="btn-search"
          @click="clicksearch()"
          ><search-outlined /> Search</a-button
        >
      </div>
    </nav>
    <div class="pt-5 text-center">
      <a-button>
        <a v-show="!disableprevmonth()" @click="prevmonth()"
          ><caret-left-outlined
        /></a>
        <span class="px-3"
          >{{ this.current_filtermonth?.label }}
          {{ this.current_filtermonth?.years }}</span
        >
        <a v-show="!disablenextmonth()" @click="nextmonth()"
          ><caret-right-outlined
        /></a>
      </a-button>
      <a-popover
        v-model:visible="visible2"
        trigger="hover"
        placement="bottomRight"
      >
        <template #content>
          <div class="p-4">
            <div class="d-flex">
              <img class="pr-3" src="@/assets/icons/ico-type-login.svg" />Login
            </div>
            <div class="d-flex mt-4">
              <img
                class="pr-3"
                src="@/assets/icons/ico-type-regis.svg"
              />Register
            </div>
            <div class="d-flex mt-4">
              <img
                class="pr-3"
                src="@/assets/icons/ico-type-redeem.svg"
              />Redeem
            </div>
            <div class="d-flex mt-4">
              <img
                class="pr-3"
                src="@/assets/icons/ico-type-purchase.svg"
              />Purchase
            </div>
            <div class="d-flex mt-4">
              <img class="pr-3" src="@/assets/icons/ico-type-fgf.svg" />Friends
              get friends
            </div>
            <div class="d-flex mt-4">
              <img
                class="pr-3"
                src="@/assets/icons/ico-type-survey.svg"
              />Survey
            </div>
            <div class="d-flex mt-4">
              <img
                class="pr-3"
                src="@/assets/icons/ico-type-profile.svg"
              />Profile
            </div>
            <div class="d-flex mt-4">
              <img class="pr-3" src="@/assets/icons/ico-type-earn.svg" />Earn
            </div>
            <div class="d-flex mt-4">
              <img
                class="pr-3"
                src="@/assets/icons/ico-type-custom.svg"
              />Custom
            </div>
          </div>
        </template>
        <a-button class="mr-3 hover" style="float: right">
          <info-circle-outlined class="mt-1 icon-down" />
        </a-button>
      </a-popover>
      <apexchart
        type="scatter"
        height="250"
        width="99%"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import { InfoCircleOutlined } from '@ant-design/icons-vue'
import { CaretRightOutlined } from '@ant-design/icons-vue'
import { CaretLeftOutlined } from '@ant-design/icons-vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import moment from 'moment'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserTimeline',
  components: {
    InfoCircleOutlined,
    CaretRightOutlined,
    CaretLeftOutlined,
  },
  emits: ['getlog'],
  data: function () {
    return {
      visible2: false,
      years: new Date().getFullYear(),
      quarter: null,
      datalog: [],
      filter_year_months: [],
      current_filerquarter: [],
      current_filtermonth: null,
      filter_quarter: [
        {
          label: 'January',
          quarter: 1,
          value: 1,
        },
        {
          label: 'February',
          quarter: 1,
          value: 2,
        },
        {
          label: 'March',
          quarter: 1,
          value: 3,
        },
        {
          label: 'April',
          quarter: 2,
          value: 4,
        },
        {
          label: 'May',
          quarter: 2,
          value: 5,
        },
        {
          label: 'June',
          quarter: 2,
          value: 6,
        },
        {
          label: 'July',
          quarter: 3,
          value: 7,
        },
        {
          label: 'August',
          quarter: 3,
          value: 8,
        },
        {
          label: 'September',
          quarter: 3,
          value: 9,
        },
        {
          label: 'October',
          quarter: 4,
          value: 10,
        },
        {
          label: 'November',
          quarter: 4,
          value: 11,
        },
        {
          label: 'December',
          quarter: 4,
          value: 12,
        },
      ],
      options: {
        dataLabels: {
          enabled: false,
          style: {
            fontSize: '13px',
            colors: ['#125eaf'],
          },
          background: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        annotations: {
          points: [
            {
              x: 5,
              y: 6,
              marker: {
                size: 0,
              },
              image: {
                path: require('@/assets/icons/ico-type-earn.svg'),
              },
            },
            {
              x: 5,
              y: 8,
              marker: {
                size: 0,
              },
              image: {
                path: require('@/assets/icons/ico-type-survey.svg'),
              },
            },
            {
              x: 5,
              y: 10,
              marker: {
                size: 0,
              },
              image: {
                path: require('@/assets/icons/ico-type-redeem.svg'),
              },
            },
            {
              x: 5,
              y: 12,
              marker: {
                size: 0,
              },
              image: {
                path: require('@/assets/icons/ico-type-purchase.svg'),
              },
            },
            {
              x: 15,
              y: 1,
              marker: {
                size: 0,
              },
              image: {
                offsetY: -25,
                path: require('@/assets/icons/ico-type-regis.svg'),
              },
            },
            {
              x: 19,
              y: 1,
              marker: {
                size: 0,
              },
              image: {
                offsetY: -25,
                path: require('@/assets/icons/ico-type-profile.svg'),
              },
            },
          ],
        },
        tooltip: {
          custom: function ({ dataPointIndex, w }) {
            var date = window.set(w.config.series[0].data[dataPointIndex][0])
            var title_date =
              '<div class="arrow_box">' +
              "<div class='text-left'>" +
              moment(date.date).format('DD MMM YY') +
              '</div>' +
              '<hr>'
            date.items.forEach(x => {
              title_date =
                title_date + '<div class="float-left">' + x.type + '</div>'
              title_date =
                title_date +
                '<div class="float-right text-ell">' +
                x.description +
                '</div>' +
                '<br>'
            })
            title_date = title_date + '</div>'
            return title_date
            // '<div class="float-left">' + "Custom" + "</div>" +
            // '<div class="float-right">' + "1" + "</div>" + '<br>' +
            // "</div>"
          },
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        grid: {
          show: false,
        },
        yaxis: {
          show: false,
          max: 18,
        },
        stroke: {
          show: true,
        },
        xaxis: {
          min: 1,
        },
        markers: {
          show: true,
          size: 10,
          colors: '#dae9ff',
          shape: 'circle',
          hover: {
            colors: '#dae9ff',
          },
        },
      },
      series: [
        {
          name: 'Get Point',
          data: [],
        },
      ],
      year_dropdown: [],
    }
  },
  beforeCreate() {},
  watch: {
    $route(to) {
      if (to.query.tab == 'timeline') {
        this.$store.commit('setLoading', true)
        this.PostDatatracelogs().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  computed() {},
  mounted() {
    window.set = this.set
    var startYear = new Date().getFullYear()
    var endYear = new Date().getFullYear() - 4
    for (let index = startYear; index >= endYear; index--) {
      this.year_dropdown.push({ label: index, value: index })
    }
    this.quarter = [
      this.filter_quarter.find(x => x.value == new Date().getMonth() + 1)
        .quarter,
    ]
    this.clicksearch()
  },
  create() {},
  methods: {
    set(date) {
      return this.datalog.find(
        x =>
          moment(x.date).format('YYYY') == this.current_filtermonth.years &&
          moment(x.date).format('M') == this.current_filtermonth.value &&
          moment(x.date).format('DD') == date,
      )
    },
    changeYear() {
      if (this.years != new Date().getFullYear()) {
        this.quarter = null
      } else {
        this.quarter = [
          this.filter_quarter.find(x => x.value == new Date().getMonth() + 1)
            .quarter,
        ]
      }
    },
    disabledmonth(quarter) {
      var quarternow = [
        this.filter_quarter.find(x => x.value == new Date().getMonth() + 1)
          .quarter,
      ]
      var year = new Date().getFullYear()
      if (this.years == year) {
        if (quarternow < quarter) {
          return true
        } else {
          return false
        }
      }
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.filter_year_months = []
      this.current_filerquarter = []
      this.quarter.forEach(quar => {
        if (quar == 0) {
          this.filter_quarter
            .filter(x => x.quarter == 4)
            .forEach(x => {
              x.years = this.years - 1
              this.current_filerquarter.push(x)
            })
          this.filter_year_months.push({ year: this.years - 1, quarter: 4 })
        } else if (quar == 5) {
          this.filter_quarter
            .filter(x => x.quarter == 1)
            .forEach(x => {
              x.years = this.years + 1
              this.current_filerquarter.push(x)
            })
          this.filter_year_months.push({ year: this.years + 1, quarter: 1 })
        } else {
          this.filter_quarter
            .filter(x => x.quarter == quar)
            .forEach(x => {
              this.current_filerquarter.push({
                label: x.label,
                quarter: x.quarter,
                value: x.value,
                years: this.years,
              })
            })
          this.filter_year_months.push({ year: this.years, quarter: quar })
        }
      })
      this.current_filerquarter = this.current_filerquarter.sort(
        (a, b) => a.years - b.years || a.value - b.value,
      )
      console.log(this.current_filerquarter)
      if (
        this.years == new Date().getFullYear() &&
        this.current_filerquarter[this.current_filerquarter.length - 1]
          .quarter ==
          this.filter_quarter.find(x => x.value == new Date().getMonth() + 1)
            .quarter
      ) {
        this.current_filtermonth = this.current_filerquarter.findLast(
          x => x.value == new Date().getMonth() + 1,
        )
      } else {
        this.current_filtermonth =
          this.current_filerquarter[this.current_filerquarter.length - 1]
      }
      this.$emit('getlog', this.filter_year_months)
      this.PostDatatracelogs().then(() => {
        this.$store.commit('setLoading', false)
        this.setmonthinchart()
      })
    },
    prevmonth() {
      this.current_filtermonth =
        this.current_filerquarter[
          this.current_filerquarter.indexOf(this.current_filtermonth) - 1
        ]
      this.setmonthinchart()
    },
    nextmonth() {
      this.current_filtermonth =
        this.current_filerquarter[
          this.current_filerquarter.indexOf(this.current_filtermonth) + 1
        ]
      this.setmonthinchart()
    },
    disableprevmonth() {
      return (
        JSON.stringify(this.current_filtermonth) ==
        JSON.stringify(this.current_filerquarter[0])
      )
    },
    disablenextmonth() {
      console.log('disablenextmonth')

      return (
        JSON.stringify(this.current_filtermonth) ==
          JSON.stringify(
            this.current_filerquarter[this.current_filerquarter.length - 1],
          ) ||
        (this.current_filtermonth?.value == new Date().getMonth() + 1 &&
          this.current_filtermonth?.years == new Date().getFullYear())
      )
    },
    getimg(type) {
      switch (type) {
        case 'Login':
          return require('@/assets/icons/ico-type-login-chart.svg')
        case 'Register':
          return require('@/assets/icons/ico-type-regis-chart.svg')
        case 'Redeem':
          return require('@/assets/icons/ico-type-redeem-chart.svg')
        case 'Purchase':
          return require('@/assets/icons/ico-type-purchase-chart.svg')
        case 'Friendsgetfriends':
          return require('@/assets/icons/ico-type-fgf-chart.svg')
        case 'Survey':
          return require('@/assets/icons/ico-type-survey-chart.svg')
        case 'Profile':
          return require('@/assets/icons/ico-type-profile-chart.svg')
        case 'Earn':
          return require('@/assets/icons/ico-type-earn-chart.svg')
        case 'Custom':
          return require('@/assets/icons/ico-type-custom-chart.svg')
      }
    },
    setmonthinchart() {
      var img = []
      this.datalog
        .filter(
          x =>
            moment(x.date).format('YYYY') == this.current_filtermonth.years &&
            moment(x.date).format('M') == this.current_filtermonth.value,
        )
        .forEach(x =>
          img.push(
            ...Array.from(new Set(x.items.map(y => y.type))).map(
              (y, index) => ({
                x: parseInt(moment(x.date).format('DD')),
                y: 2 + 2 * (index + 1),
                marker: {
                  size: 0,
                },
                image: {
                  path: this.getimg(y),
                },
              }),
            ),
          ),
        )
      img.push(
        ...this.datalog
          .filter(
            x =>
              moment(x.date).format('YYYY') == this.current_filtermonth.years &&
              moment(x.date).format('M') == this.current_filtermonth.value,
          )
          .map(x => ({
            x: parseInt(moment(x.date).format('DD')),
            y: 2,
            marker: {
              size: 0,
              style: {
                cssClass: 'pointer-none',
              },
            },
            label: {
              borderColor: '#ffffff00',
              offsetY: 10,
              style: {
                color: '#125eaf',
                background: '#ffffff00',
                fontWeight: '700',
                cssClass: 'pointer-none',
              },
              text: x.is_over_nine_time ? x.counts + '+' : x.counts,
            },
          })),
      )
      this.options.annotations.points = img
      this.options.xaxis.max = new Date(
        this.current_filtermonth.years,
        this.current_filtermonth.value,
        0,
      ).getDate()
      this.options.xaxis.tickAmount =
        new Date(
          this.current_filtermonth.years,
          this.current_filtermonth.value,
          0,
        ).getDate() - 1
      this.options = { ...this.options }
      this.series[0].data = this.datalog
        .filter(
          x =>
            moment(x.date).format('YYYY') == this.current_filtermonth.years &&
            moment(x.date).format('M') == this.current_filtermonth.value,
        )
        .map(x => [parseInt(moment(x.date).format('DD')), 2])
      this.series = [...this.series]
    },
    PostDatatracelogs() {
      var userlevelupdate = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        quarter_items: this.filter_year_months,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostDatatracelogs(userlevelupdate)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            this.datalog = res.data.result
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }
  .nav-search .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }
  .line {
    width: 2px;
    height: 64px;
    float: right;
    background-color: $color-grey-line;
    position: absolute;
    top: 14%;
    left: calc(100% - 158px);
  }
  .nav-search {
    .group-btn-left {
      float: right;
      padding-right: 5px;
      .input-search {
        width: 350px;
      }
    }
    .group-btn {
      float: left;
      margin-left: 16px;
    }
  }
  .wrap-table {
    .ant-table-wrapper {
      min-width: inherit;
    }
  }
}
.apexcharts-xaxis-tick {
  height: 20px;
}
.d-flex {
  display: flex;
}
</style>
<style>
.arrow_box {
  width: 300px;
  padding: 10px;
}
.text-ell {
  text-align: right;
  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pointer-none {
  pointer-events: none;
}
.apexcharts-point-annotations {
  pointer-events: none;
}
</style>
