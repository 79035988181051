<template>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-80 bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Display Fields</div>
            <div class="flex-none btn-dismiss" @click="dismiss(true)">
              <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <div class="v-list">
                <div class="mb-4 flex">
                  <v-checkbox
                    v-model:value="selectAll"
                    label="All"
                    class="checkbox-display-Fields pointer select-all"
                    @change="toggleSelectAll()"
                  ></v-checkbox>
                </div>
                <draggable
                  v-model="myArray"
                  group="people"
                  @start="drag = true"
                  @end="drag = false"
                  item-key="key"
                >
                  <template #item="{ element, index }">
                    <div
                      class="mb-4 flex"
                      :class="{ 'last:mb-0': index == myArray.length - 1 }"
                    >
                    <img class="icon" src="@/assets/icons/dragdrop.svg" alt />
                      <v-checkbox
                        v-model:value="
                          columnStates.find(x => x.key == element.key).value
                        "
                        :label="element.title"
                        class="checkbox-display-Fields pointer"
                        @change="checkUIState()"
                      ></v-checkbox>
                    </div>
                  </template>
                </draggable>
                <!-- <template
                  v-for="(header, idx) of headers"
                  :key="`displayFields-${idx}`"
                >
                  <div
                    class="mb-4"
                    :class="{ 'last:mb-0': idx == headers.length - 1 }"
                  >
                    <v-checkbox
                      v-model:value="columnStates[idx].value"
                      :label="header.title"
                      class="checkbox-display-Fields"
                      @change="checkUIState()"
                    ></v-checkbox>
                  </div>
                </template> -->
              </div>
            </div>
          </div>
          <div class="flex-none px-4 py-3">
            <a-button type="primary" @click="applyDF()"> Apply </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VCheckbox from '@/components/Form/Checkbox.vue'
// helper
import Util from '@/helper/Utility'
import draggable from 'vuedraggable'

export default {
  name: 'DisplayFields',
  components: {
    VCheckbox,
    draggable,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  data: function () {
    return {
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      myArray: [
        {
          name: 'test1',
          id: 1,
        },
        {
          name: 'test2',
          id: 2,
        },
        {
          name: 'test3',
          id: 3,
        },
      ],
      columnStates: [],
      selectAll: true,
      oldStates: [],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {},
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function (flagResetState = true) {
      console.log('dismiss')
      if (flagResetState) {
        this.resetState()
      } else {
        this.setState()
      }
      console.log('handleClose')
      this.$emit('handleClose')
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
    applyDF: function () {
      this.dismiss(false)
      var sort = []
      this.myArray.forEach(item => {
        sort.push(this.columnStates.find(x => x.key == item.key))
      })
      this.$emit('apply', [...sort])
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.select-all{
  padding-left: 14px;
}
</style>
