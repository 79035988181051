import Util from "@/helper/Utility.js";
/**
 * @exports AccountModel
 * @exports ProfileModel
 * @exports ProfileParamsBuilder
 * @exports ProfileAddressModel
 * @exports AddressParamsBuilder
 * @exports ProfileShippingAddressModel
 * @exports ShippingAddressParamsBuilder
 */

export function AccountModel() {
    this.token = null;
    this.userId = null;
    this.userLevel = null;
    this.uuid = null;

    this.appId = null;
    this.canRedeem = null;
    this.loginChanel = null;
    this.isFbUser = null;
    this.joinDate = null;
    this.locale = null;
    this.platform = null;

    this.point = {
        point: 0,
        time: null,
    }
    this.pdpa = {
        privacy: null,
        terms: null,
    };

    this.setDataFromApi = function (data) {
        this.token = data.token;
        this.userId = data.userId;
        this.userLevel = data.userLevel;
        this.uuid = data.uuid;

        this.appId = data.appId;
        this.canRedeem = data.canRedeem;
        this.loginChanel = data.channel;
        this.isFbUser = data.isFbUser;
        this.joinDate = data.joinDate;
        this.locale = data.locale;
        this.platform = data.platform;

        this.point = {
            point: data.updated_points.points,
            time: data.updated_points.time,
        }
        this.pdpa = {
            privacy: data.DataPrivacy,
            terms: data.TermAndCondition,
        };
    };
}


export function ProfileModel (bzbsProfile) {
    this.userId = bzbsProfile.UserId || '';
    this.name = bzbsProfile.Name || '';
    this.firstName = bzbsProfile.FirstName || '';
    this.lastName = bzbsProfile.LastName || '';

    this.gender = bzbsProfile.Gender || '';
    this.birthDate = bzbsProfile.BirthDate || '';
    this.age = bzbsProfile.Age || '';
    this.contactNumber = bzbsProfile.Contact_Number || '';
    this.email = bzbsProfile.Email || '';
    this.nationalIdCard = bzbsProfile.NationalIdCard || '';
    this.locale = bzbsProfile.Locale || '';

    this.isNotificationEnable = bzbsProfile.NotificationEnable || '';
    this.postToFacebook = bzbsProfile.PostToFacebook || '';
    this.modifyDate = bzbsProfile.ModifyDate || '';
    this.creditCardHolder = bzbsProfile.CreditCardHolder || '';
    this.creditCardNo = bzbsProfile.CreditCardNo || '';
    this.creditCardType = bzbsProfile.CreditCardType || '';
    this.creditCardExpireMonth = bzbsProfile.CreditCardExpireMonth || '';
    this.creditCardExpireYear = bzbsProfile.CreditCardExpireYear || '';
    this.passport = bzbsProfile.Passport || '';
    this.membershipId = bzbsProfile.MembershipId || '';
    this.membershipUserName = bzbsProfile.MembershipUserName || '';
    this.loginType = bzbsProfile.LoginType || '';

    // Custom fields
    this.displayName = bzbsProfile.DisplayName;
    this.playboyCampaignId = bzbsProfile.PlayboyCampaignId;
    this.userType = bzbsProfile.UserType;
    this.userTypeName = bzbsProfile.UserTypeName;
    this.otherUserId = bzbsProfile.OtherUserId;
    this.categoryConfig = bzbsProfile.CategoryConfig;

    this.phonePurchase = bzbsProfile.PhonePurchase || '';
    this.income = bzbsProfile.Income || '';
    this.interests = bzbsProfile.Interests || '';
    this.region = bzbsProfile.Region || '';
    this.refercode = bzbsProfile.ReferCode || '';

    this.maritalStatus = bzbsProfile.MaritalStatus || '';
    this.nationality = bzbsProfile.Nationality || '';
    this.religion = bzbsProfile.Religion || '';
    this.highestEducation = bzbsProfile.HighestEducation || '';
    this.occupation = bzbsProfile.Occupation || '';
    this.driverLicenseNumber = bzbsProfile.DriverLicenseNumber || '';

    this.latitude = bzbsProfile.Latitude || '';
    this.longitude = bzbsProfile.Longitude || '';
    this.joinDate = bzbsProfile.JoinDate || '';

    this.updated_points = bzbsProfile.updated_points || '';
    if (bzbsProfile.updated_points_other) this.updated_points_other = bzbsProfile.updated_points_other || '';

    this.isAllowTermAndCondition = bzbsProfile.TermAndCondition || null;
    this.isAllowDataPrivacy = bzbsProfile.DataPrivacy || null;

    this.buzzebees = bzbsProfile.buzzebees || "";

    this.getParams = function() {
        var params = {
            firstname: this.firstName,
            lastname: this.lastName,
            gender: this.gender,
            birthdate: this.birthDate,
            contact_Number: this.contactNumber,
            email: this.email,
            idcard: this.nationalIdCard,
            locale: this.locale,
            notification: this.isNotificationEnable,
            income: this.income,
            interests: this.interests,
            region: this.religion,
            maritalstatus: this.maritalStatus,
            nationality: this.nationality,
            highesteducation: this.highestEducation,
            occupation: this.occupation,
            passport: this.passport,
            driverlicense: this.driverLicenseNumber,
            displayname: this.firstName + ' ' + this.lastName,
        };

        return Util.cleanObjectEmptyStr(params);
    };
}

export function ProfileParamsBuilder() {
    this.getParams = function(profile) {
        if (!profile) return;
        var params = {
            firstname: profile.firstName,
            lastname: profile.lastName,
            gender: profile.gender,
            birthdate: profile.birthDate,
            contact_Number: profile.contactNumber,
            email: profile.email,
            idcard: profile.nationalIdCard,
            locale: profile.locale,
            notification: profile.isNotificationEnable,
            income: profile.income,
            interests: profile.interests,
            region: profile.religion,
            maritalstatus: profile.maritalStatus,
            nationality: profile.nationality,
            highesteducation: profile.highestEducation,
            occupation: profile.occupation,
            passport: profile.passport,
            driverlicense: profile.driverLicenseNumber,
            displayname: profile.driverLicenseNumber,
        };

        return Util.cleanObjectEmptyStr(params);
    };
}

export function ProfileAddressModel(bzbsProfile) {
    if (bzbsProfile) {
        this.number = bzbsProfile.Number || '';
        this.moo = bzbsProfile.Moo || '';
        this.village = bzbsProfile.Village || '';
        this.road = bzbsProfile.Road || '';
        this.soi = bzbsProfile.Soi || '';
        this.building = bzbsProfile.Building || '';
        this.room = bzbsProfile.Room || '';
        this.floor = bzbsProfile.Floor || '';
        this.address = bzbsProfile.Address || '';
        this.fullAddress = bzbsProfile.FullAddress || '';
        this.zipcode = bzbsProfile.Zipcode || '';
        this.countryCode = bzbsProfile.CountryCode || '';
        this.provinceCode = bzbsProfile.ProvinceCode || '';
        this.districtCode = bzbsProfile.DistrictCode || '';
        this.subDistrictCode = bzbsProfile.SubDistrictCode || '';
        this.countryName = bzbsProfile.CountryName || '';
        this.provinceName = bzbsProfile.ProvinceName || '';
        this.districtName = bzbsProfile.DistrictName || '';
        this.subDistrictName = bzbsProfile.SubDistrictName || '';
    } else {
        this.number = '';
        this.moo = '';
        this.village = '';
        this.road = '';
        this.soi = '';
        this.building = '';
        this.room = '';
        this.floor = '';
        this.address = '';
        this.fullAddress = '';
        this.zipcode = '';
        this.countryCode = '';
        this.provinceCode = '';
        this.districtCode = '';
        this.subDistrictCode = '';
        this.countryName = '';
        this.provinceName = '';
        this.districtName = '';
        this.subDistrictName = '';
    }
}

export function AddressParamsBuilder() {
    this.getParams = function(addr) {
        if (!addr) return;
        var params = {
            address: addr.address,
            village: addr.village,
            building: addr.building,
            number: addr.number,
            moo: addr.moo,
            room: addr.room,
            floor: addr.floor,
            soi: addr.soi,
            road: addr.road,
            country_code: addr.countryCode,
            country_name: addr.countryName,
            province_code: addr.provinceCode,
            province_name: addr.provinceName,
            district_code: addr.districtCode,
            district_name: addr.districtName,
            subdistrict_code: addr.subDistrictCode,
            subdistrict_name: addr.subDistrictName,
            zipcode: addr.zipcode,
        };

        return Util.cleanObjectEmptyStr(params);
    };
}

export function ProfileShippingAddressModel(bzbsProfile) {
    if (bzbsProfile) {
        if(bzbsProfile.ShippingZipcode) {
            //Initial with profile shipping addres.
            this.firstName = bzbsProfile.ShippingFirstName || '';
            this.lastName = bzbsProfile.ShippingLastName || '';
            this.contactNumber = bzbsProfile.ShippingContactNumber || '';
            this.email = bzbsProfile.ShippingEmail || '';
            this.address = bzbsProfile.ShippingAddress || '';
            this.zipcode = bzbsProfile.ShippingZipcode || '';
            this.provinceCode = bzbsProfile.ShippingProvinceCode || null;
            this.districtCode = bzbsProfile.ShippingDistrictCode || null;
            this.subDistrictCode = bzbsProfile.ShippingSubDistrictCode || null;
            this.provinceName = bzbsProfile.ShippingProvinceName || '';
            this.districtName = bzbsProfile.ShippingDistrictName || '';
            this.subDistrictName = bzbsProfile.ShippingSubDistrictName || '';
        } else {
            //Initial with profile address.
            this.firstName = bzbsProfile.FirstName || '';
            this.lastName = bzbsProfile.LastName || '';
            this.contactNumber = bzbsProfile.Contact_Number || '';
            this.email = bzbsProfile.Email || '';
            this.address = bzbsProfile.Address || '';
            this.zipcode = bzbsProfile.Zipcode || '';
            this.provinceCode = bzbsProfile.ProvinceCode || null;
            this.districtCode = bzbsProfile.DistrictCode || null;
            this.subDistrictCode = bzbsProfile.SubDistrictCode || null;
            this.provinceName = bzbsProfile.ProvinceName || '';
            this.districtName = bzbsProfile.DistrictName || '';
            this.subDistrictName = bzbsProfile.SubDistrictName || '';
        }
    } else {
        //Initial default.
        this.firstName = '';
        this.lastName = '';
        this.contactNumber = '';
        this.email = '';
        this.address = '';
        this.zipcode = '';
        this.provinceCode = null;
        this.districtCode = null;
        this.subDistrictCode = null;
        this.provinceName = '';
        this.districtName = '';
        this.subDistrictName = '';
    }
}

export function ShippingAddressParamsBuilder() {
    /**
     * @param {ProfileShippingAddressModel} addr // This params should be created by ProfileShippingAddressModel.
     */
    this.getParams = function(addr) {
        if (!addr) return {};
        var params = {
            shippingfirstname: addr.firstName,
            shippinglastname: addr.lastName,
            shipping_contact_number: addr.contactNumber,
            shipping_email: addr.email,
            shipping_address: addr.address,
            shipping_zipcode: addr.zipcode,
            shipping_province_code: addr.provinceCode,
            shipping_province_name: addr.provinceName,
            shipping_district_code: addr.districtCode,
            shipping_district_name: addr.districtName,
            shipping_subdistrict_code: addr.subDistrictCode,
            shipping_subdistrict_name: addr.subDistrictName,
        };

        return Util.cleanObjectEmptyStr(params);
    };
}
