<template>
  <div
    class="wrapper-toolbar page-select-applications b-6 flex items-center justify-start"
  >
    <a-input-search
      type="text"
      placeholder="Find your application"
      class="input-search"
      v-model:value="value"
      @search="onSearch(value)"
    >
      <template #enterButton>
        <a-button class="btn-icon" @click="onSearch(value)">
          <search-outlined />
        </a-button>
      </template>
    </a-input-search>
    <!-- <a-input
      class="search-app"
      placeholder="Search applicaiton"
      v-model:value="value"
    />
    <a-button type="primary" @click="onSearch(value)" class="btn-search ml-2 mr-4" >Search</a-button>
    <ul class="flex list-mode">
      <li>
        <a :class="{ active: mode == 'card' }" @click="changeMode('card')">
          <img class="unactive" src="@/assets/icons/card.svg" />
          <img class="active" src="@/assets/icons/card-active.svg" />
        </a>
      </li>
      <li>
        <a :class="{ active: mode == 'table' }" @click="changeMode('table')">
          <img class="unactive" src="@/assets/icons/table.svg" />
          <img class="active" src="@/assets/icons/table-active.svg" />
        </a>
      </li>
    </ul> -->
  </div>
</template>
<script>
export default {
  name: 'SelectAppToolbar',
  props: {
    mode: {
      type: String,
    },
  },
  components: {},
  mixins: [],
  emits: [],
  data: function () {
    return {
      value: null,
    }
  },
  created() {
    this.mode == 'card'
  },
  mounted() {},
  methods: {
    onSearch() {
      this.$emit('onSearch', this.value)
    },
    changeMode(val) {
      this.$emit('onChangeMode', val)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.wrapper-toolbar {
  padding: 0 20px;
  .search-app {
    width: 240px;
    display: block;
  }
  .list-mode {
    padding: 0;
    margin: 0;
    border-radius: 5px;
    border: solid 1px $color-border;
    li + li {
      border-left: solid 1px $color-border;
    }
    a {
      width: 24px;
      height: 24px;
      text-align: center;
      font-size: 15px;
      display: block;
      .active {
        display: none;
      }
      .unactive {
        display: block;
      }
      &.active {
        background: #eeeeee;
      }
      &:hover {
        background: #d9d9d9;
      }
      &.active {
        .active {
          display: block;
        }
        .unactive {
          display: none;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.search-app.ant-input-affix-wrapper {
  > input.ant-input {
    float: right;
    width: calc(100% - 24px);
    font-size: 12px;
  }
  > .ant-input-suffix {
    float: left;
    display: block;
    margin: -1px 5px 0 0;
  }
}
.input-search {
  height: 40px;
  .btn-icon .anticon {
    line-height: 1;
    font-size: 22px !important;
    color: #636363 !important;
  }
}
</style>
