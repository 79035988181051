// import { register } from "../../module/Register/locale/register.en.js";

import { profile } from "@/module/Profile/locale/profile.en.js";

export const en = {
    default: {
        search: 'SEARCH',
        confirm: 'CONFIRM',
        ok: 'OK',
        cancel: 'CANCEL',
        close: 'CLOSE',
    },

    profile: profile,

    date: {
        days: 'day',
        months: 'month',
        years: 'year',
        minutes: 'minute',
        minutes_short: 'min',
        hours: 'hour',
        hours_short: 'hr',
        date_flase: 'Please select your birthday.',
        month_full: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        month_short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        month_full_arr: [{ strvalue: "01", strtext: 'January' },
        { strvalue: '02', strtext: 'February' },
        { strvalue: '03', strtext: 'March' },
        { strvalue: '04', strtext: 'April' },
        { strvalue: '05', strtext: 'May' },
        { strvalue: '06', strtext: 'June' },
        { strvalue: '07', strtext: 'July' },
        { strvalue: '08', strtext: 'August' },
        { strvalue: '09', strtext: 'September' },
        { strvalue: '10', strtext: 'October' },
        { strvalue: '11', strtext: 'November' },
        { strvalue: '12', strtext: 'December' }]
    },
    redeem_button: {
        default: 'Redeem',
        none: '',
        soldOut: 'Sorry, This reward is reaching redemption limit.',
        soldOutBuy: 'Sorry, This reward is reaching redemption limit.',
        maxPerPerson: 'Sorry, You have already redeemed this reward.',
        redeemCoolDown: 'This campaign has been used for this period.',
        nextDate: '<br/>at : ',
        exceedBuyPointLimitPerday: '',
        conditionIsInvalid: 'This campaign requires user action before redemption, please review the conditions first.',
        campaignExpired: 'Campaign has expired.',
        campaignExpiredBuy: 'Campaign has expired.',
        campaignNotAvailable: 'Sorry. This reward is not available',
        campaignNotAvailableBuy: 'Sorry. This product is not available',
        notEnoughPoint: 'Your point are not enough to redeem this campaign.',
        notSupport: 'This campaign is not support.'
    },
    confirm_redeem: {
        confirm: "Confirm",
        cancel: "Cancel",

        headline: 'Confirm',
        default: 'Confirm to redeem',
        defualt_have_time: 'Serial will available for {0} after confirm',
        delivery: 'Please enter shipping information to redeem',
        free: 'Serial will available for {0} after confirm',
        free_no_timer: 'Confirm to redeem',
        free_delivery: 'Please enter shipping information to redeem',
        draw: 'Confirm to join campaign <br/> Winner will be announced after {0}',
        draw_delivery: 'Please enter shipping information to redeem',
        deal: 'Serial will available for {0} after confirm',
        deal_no_timer: 'Confirm to redeem',
        deal_delivery: 'Please enter shipping information to redeem',
        buy: 'Confirm to purchase',
        buy_delivery: 'Please enter shipping information to buy',
        interface: 'This will redirect to website after confirm',
    },
    purchase: {
        show_code: 'view code',
        code_expired: 'This serial has expired.',

        delivery: 'Product will be mailed based on Campaign Condition.',
        shipped: 'The Product has been delivered.',
        parcel_no: 'ParcelNo',

        not_draw1: 'Not yet drawn,',
        not_draw2: 'please check back at a later date',
        draw_winner1: 'You are the winner',
        draw_winner2: '',
        draw_not_win1: 'Sorry you are not the winner,',
        draw_not_win2: 'better luck next',
    },
    serial: {
        close_btn: 'Close',
        default_privilege_message: 'As design',
        show_code_at_shop: 'Please show code below to claim your privilege',

        delivery: 'Product will be mailed based on Campaign Condition.',

        draw: 'Thank you for joining us.<br/>Winner will be announced after {0}',
        draw_after: 'after',

        qr_code: 'QR Code',
        barcode: 'Barcode',
        code: 'Code',

        copy_code: 'Copy Code',

        expire_in: 'Expire in ',
        code_expired: 'This serial has expired.',

        redeem_on: 'Redeem on {0}'
    },
    shipping: {
        headline: 'Shipping address',

        firstname: {
            name: 'Firstname',
            placeholder: 'Firstname',
            required: 'Please enter firstname',
            too_short: '',
            invalid: '',
        },
        lastname: {
            name: 'Lastname',
            placeholder: 'Lastname',
            required: 'Please enter lastname',
            too_short: '',
            invalid: '',
        },
        addressName: {
            name: 'Address name',
            placeholder: 'Address name',
            required: 'Please enter address name',
            too_short: '',
            invalid: '',
        },
        address:  {
            name: 'Address',
            placeholder: 'Address',
            required: 'Please enter address',
            too_short: '',
            invalid: '',
        },
        contactnumber: {
            name: 'Contact number',
            placeholder: 'Contact number',
            required: 'Contact number',
            too_short: 'Contact number must be {0} digits',
            invalid: 'Incorrect contact number format',
        },
        nationalid: {
            name: 'National id',
            placeholder: 'National id',
            required: 'Please enter national id',
            too_short: 'National id number must be {0} digits',
            invalid: 'Incorrect national id format',
        },
        email: {
            name: 'Email',
            placeholder: 'Email',
            required: 'Please enter email address',
            too_short: '',
            invalid: 'Incorrect email format',
        },
        zipcode: {
            name: 'Zipcode',
            placeholder: 'Zipcode',
            required: 'Please enter zipcode',
            too_short: 'Zipcode must be {0} digits',
            invalid: 'Invalid zipcode',
        },
        subdistrict: {
            name: 'Sub district',
            placeholder: 'Please selece sub-district',
            required: 'Please selece sub-district',
            too_short: '',
            invalid: '',
        },
        district: {
            name: 'District',
            placeholder: 'Please selece district',
            required: 'Please selece district',
            too_short: '',
            invalid: '',
        },
        province: {
            name: 'Province',
            placeholder: 'Please selece province',
            required: 'Please selece province',
            too_short: '',
            invalid: '',
        },

        isDefaultAddress: 'Is default address.',
        defaultAddress: 'Default',
        save_address: 'Save address for next privilege',
        save_address_buy: 'Save address for next puchase',

        confirm_btn: 'Confirm',
        cancel_btn: 'Cancel',

        alert_headline: 'Shipping address',
        alert_ok_btn: 'OK',
    },
    tax: {
        personType: {
            name: 'ออกในนาม',
            placeholder: '',
            required: 'กรุณาเลือก',
            too_short: '',
            invalid: '',
        },
        title: {
            name: 'คำนำหน้าชื่อ',
            placeholder: '',
            required: 'กรุณาเลือก',
            too_short: '',
            invalid: '',
        },
        companyName: {
            name: 'ชื่อ / บริษัท',
            placeholder: 'ชื่อ / บริษัท',
            required: 'กรุณากรอกข้อมูลให้ครบถ้วน',
            too_short: '',
            invalid: '',
        },
        building: {
            name: 'อาคาร',
            placeholder: 'อาคาร',
            required: '',
            too_short: '',
            invalid: '',
        },
        room: {
            name: 'เลขที่ห้อง',
            placeholder: 'เลขที่ห้อง',
            required: '',
            too_short: '',
            invalid: '',
        },
        floor: {
            name: 'ชั้นที่',
            placeholder: 'ชั้นที่',
            required: '',
            too_short: '',
            invalid: '',
        },
        village: {
            name: 'หมู่บ้าน',
            placeholder: 'หมู่บ้าน',
            required: '',
            too_short: '',
            invalid: '',
        },
        number: {
            name: 'เลขที่',
            placeholder: 'เลขที่',
            required: '',
            too_short: '',
            invalid: '',
        },
        moo: {
            name: 'หมู่ที่',
            placeholder: 'หมู่ที่',
            required: '',
            too_short: '',
            invalid: '',
        },
        soi: {
            name: 'ซอย',
            placeholder: 'ซอย',
            required: '',
            too_short: '',
            invalid: '',
        },
        road: {
            name: 'ถนน',
            placeholder: 'ถนน',
            required: '',
            too_short: '',
            invalid: '',
        },
    },
    main: {
        home_page: "Home",
        login: "Login",
        campaign: "Campaign",
        category: 'Category',
        map: "Map",
        terms: "Terms And Conditions",
        history: 'History',
        profile: "Profile",
        logout: "Logout",
        lang: "Language",
        msgLogout: "Logout ?",
        cancel: "Cancel",
    },
    campaign: {
        point: 'Point',
        get_point: 'Get Point : ',
        use_point: 'Use Point : ',
    },
    detail: {
        detail: 'Details',
        condition: 'Conditions',

        point_unit: 'Point',
        get_point: 'Get Point : ',
        use_point: 'Use Point : ',
        available_date: 'Date',
        expire_date: 'Expire date : {0}',

        color: {
            name: 'Color',
            placeholder: 'Please select color',
            required: 'Please select color',
        },
        size: {
            name: 'Size',
            placeholder: 'Please select size',
            required: 'Please select size',
        },
    },
    history: {
        point: 'Point',
        get_point: 'Get Point : ',
        use_point: 'Use Point : ',

        parcel_number: 'Parcel No. : ',
    }
};
