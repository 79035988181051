<template>
  <AppLayout :breadcrumb="bcItems">
    <template v-slot:breadcrumb>
      <div class="d-flex justify-content-between">
        <TitleBreadcrumbs
          class="mt-4"
          :title="firstName + ' ' + lastName"
          :databreadcrumbs="breadcrumbs"
        />
        <div
          v-if="
            activeKey == 'account' &&
            permission.includes('User-List-Account-History-OTP-View')
          "
          class="mt-50"
        >
          <a-button @click="toggleOTP()">OTP History</a-button>
        </div>
      </div>
    </template>
    <div class="page-userdetail max-w-full w-full flex flex-col">
      <div class="flex-1 w-full px-4">
        <a-tabs :animated="false" :activeKey="activeKey" @change="changeTabs">
          <template #rightExtra>
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'level'"
              @click="toggleDisplayFields"
              >Change Log</a
            >
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'account'"
              @click="toggleDisplayFieldsAccount"
              >Change Log</a
            >
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'subAccount'"
              @click="toggleAdditionalProfile"
              >Change Log</a
            >
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'dataprivacy'"
              @click="toggleDataPrivacyHistory"
              >Change Log</a
            >
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'redeem'"
              @click="toggleDataRedeemHistory"
              >Change Log</a
            >
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'level-flag'"
              @click="toggleDataFlagHistory"
              >Change Log</a
            >
            <a
              v-if="permission.includes('User-History-Log-View')"
              class="log"
              v-show="this.activeKey == 'tags'"
              @click="toggleDataFlagHistory"
              >Change Log</a
            >
          </template>
          <a-tab-pane
            :class="
              tab.key == 'account'
                ? 'ant-tabs-content2'
                : tab.key == 'subAccount'
                ? 'ant-tabs-content-additional'
                : tab.key == 'dataprivacy'
                ? 'ant-tabs-content-dataprivacy'
                : tab.key == 'points'
                ? 'ant-tabs-content-point'
                : 'ant-tabs-content'
            "
            v-for="tab in tab2"
            :key="tab.key"
            :tab="tab.title"
            force-render
          >
            <template v-if="tab.key == 'account'">
              <Profile :dataapp="dataapp" @profile="setProfile($event)" />
            </template>
            <template v-else-if="tab.key == 'coupon'">
              <Coupon :dataapp="permission" />
            </template>
            <template v-else-if="tab.key == 'redeem'">
              <Redeem :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'purchase'">
              <Purchase :dataapp="permission" />
            </template>
            <template v-else-if="tab.key == 'points'">
              <Points :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'level'">
              <Level :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'level-flag'">
              <LevelFlag :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'timeline'">
              <Timeline :dataapp="dataapp" @getlog="clicksearch($event)" />
            </template>
            <template v-else-if="tab.key == 'subAccount'">
              <SubAccount :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'tags'">
              <Tags :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'dataprivacy'">
              <DataPrivacy :dataapp="dataapp" />
            </template>
            <template v-else-if="tab.key == 'badge'">
              <Badge :dataapp="permission" />
            </template>
            <template v-else-if="tab.key == 'subscription'">
              <Subscription :dataapp="permission" />
            </template>
            <template v-else>
              <Wallet :dataapp="permission" />
            </template>
          </a-tab-pane>
        </a-tabs>
        <div class="mt-5" v-show="activeKey == 'timeline'">
          <a-radio-group
            v-model:value="value1"
            @change="tabtab()"
            button-style="solid"
          >
            <a-radio-button value="overview">Overview</a-radio-button>
            <a-radio-button
              v-show="datalogsdetail"
              v-for="(data, index) of datalogsdetail?.result.types"
              :key="`index-${index}`"
              :value="data"
              >{{ data }}</a-radio-button
            >
          </a-radio-group>
          <div v-show="value1 === 'overview'">
            <div class="mt-5 content-timeline">
              <a-row>
                <a-col :span="8">
                  <div class="h-tabs">Last Seen</div>
                  <div>{{ datalastseen ?? '-' }}</div>
                </a-col>
                <a-col :span="8">
                  <div class="h-tabs">Last Active</div>
                  <div>{{ datalastactive ?? '-' }}</div>
                </a-col>
                <a-col :span="8">
                  <div class="h-tabs">Last Action</div>
                  <div>{{ datalastaction ?? '-' }}</div>
                </a-col>
              </a-row>
            </div>
          </div>
          <div v-show="value1 != 'overview'">
            <div class="mt-5 content-timeline">
              <div class="wrap-table-modal" @scroll="handleScrollModal">
                <VLoadingTable :value="overlaytablemodal" />
                <div
                  class="hover-content"
                  v-for="(data, index) of dataredeem"
                  :key="`index-${index}`"
                >
                  <div class="pt-3">
                    <span class="">Timestamp :</span>
                    <span class="ml-4">{{ getdate(data.trans_date) }}</span>
                  </div>
                  <div class="mb-3">
                    <span class="pb-3">Description :</span>
                    <span class="ml-4">{{ data.description }}</span>
                  </div>
                  <hr />
                  <!-- <div class="col-4">Description :</div>
                  <div class="col-8">{{ data.description }}</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <Footer class="flex-none w-footer" :fixed="true"></Footer> -->
      <a-modal
        :visible="modalOTP"
        @cancel="handleCancel"
        :width="680"
        :centered="true"
        :closable="false"
        :ok-button-props="{ style: { display: 'none' } }"
      >
        <template #title>
          <div class="d-flex justify-content-between">
            <div>OTP History</div>
            <a @click="refreshOTP()" class="link fs-14 pt-2">Refresh</a>
          </div>
        </template>
        <a-table
          row-key="partitionkey"
          class="fixed-head"
          :dataSource="dataOTP"
          :columns="columnsOTP"
          :pagination="false"
        >
          <template #send_date="{ record }">
            <div>
              {{
                record.send_date
                  ? formatDate(
                      record.send_date,
                      'DD / MMM / YYYY HH:mm:ss a',
                      this.isTimezone * -1,
                    )
                  : '-'
              }}
            </div>
          </template>
          <template #ref_code="{ record }">
            <div class="font-bold">{{ record.row_key }}</div>
          </template>
          <template #otp="{ record }">
            <div class="font-bold">{{ record.password }}</div>
          </template>
          <template #used_date="{ record }">
            <div>
              {{
                record.use_date
                  ? formatDate(
                      record.use_date,
                      'DD / MMM / YYYY HH:mm:ss a',
                      this.isTimezone * -1,
                    )
                  : '-'
              }}
            </div>
          </template>
          <template #expired_date="{ record }">
            <div>
              {{
                record.expire_date
                  ? formatDate(
                      record.expire_date,
                      'DD / MMM / YYYY HH:mm:ss a',
                      this.isTimezone * -1,
                    )
                  : '-'
              }}
            </div>
          </template>
        </a-table>
      </a-modal>
      <DisplayFieldsLevel
        :value="showDisplayFieldsLevel"
        :historylogs="historylogs"
        @apply="applyDisplayFields($event)"
        @handleClose="showDisplayFieldsLevel = false"
      >
      </DisplayFieldsLevel>
      <DisplayFieldsAccount
        :value="showDisplayFieldsAccount"
        :historylogs="historylogs"
        @apply="applyDisplayFields($event)"
        @handleClose="showDisplayFieldsAccount = false"
      >
      </DisplayFieldsAccount>
      <DisplayFieldsAdditionalProfile
        :value="showAdditionalProfile"
        :historylogs="historylogs"
        @apply="applyDisplayFields($event)"
        @handleClose="showAdditionalProfile = false"
      >
      </DisplayFieldsAdditionalProfile>
      <DisplayFieldsDatapivacyLogHistory
        :value="showDataPrivacyHistory"
        :historylogs="historylogs"
        @apply="applyDisplayFields($event)"
        @handleClose="showDataPrivacyHistory = false"
      >
      </DisplayFieldsDatapivacyLogHistory>
      <DisplayFieldsDataredeemLogHistory
        :value="showDataRedeemHistory"
        :historylogs="historylogs"
        @apply="applyDisplayFields($event)"
        @handleClose="showDataRedeemHistory = false"
      >
      </DisplayFieldsDataredeemLogHistory>
      <DisplayFieldsDataFlagHistory
        :value="showDataFlagHistory"
        :historylogs="historylogs"
        @handleClose="showDataFlagHistory = false"
      >
      </DisplayFieldsDataFlagHistory>
      <DisplayFieldsDataTagHistory
        :value="showDataTagHistory"
        :historylogs="historylogs"
        @handleClose="showDataTagHistory = false"
      >
      </DisplayFieldsDataTagHistory>
    </div>
  </AppLayout>
</template>

<script>
import _ from 'lodash'
// common components
// import { LeftCircleOutlined } from '@ant-design/icons-vue'
// import Footer from '@/components/Layout/Footer/Footer.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import Profile from '@/module/User/UserDetail/component/UserProfile/UserProfile.vue'
import Redeem from '@/module/User/UserDetail/component/UserRedeem.vue'
import Coupon from '@/module/User/UserDetail/component/UserCoupon.vue'
import Purchase from '@/module/User/UserDetail/component/UserPurchase.vue'
import Points from '@/module/User/UserDetail/component/UserPoints.vue'
import Level from '@/module/User/UserDetail/component/UserLevel.vue'
import LevelFlag from '@/module/User/UserDetail/component/UserLevelFlag.vue'
import Timeline from '@/module/User/UserDetail/component/UserTimeline.vue'
import SubAccount from '@/module/User/UserDetail/component/UserSubAccountOld.vue'
import DataPrivacy from '@/module/User/UserDetail/component/UserDataPrivacy.vue'
import Wallet from '@/module/User/UserDetail/component/UserWallet.vue'
import Subscription from '@/module/User/UserDetail/component/UserSubscription.vue'
import Tags from '@/module/User/UserDetail/component/UserTags.vue'
import Badge from '@/module/User/UserDetail/component/UserBadge/UserBadge.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFieldsLevel from '@/module/User/component/DisplayFieldsUserLevelLog.vue'
import DisplayFieldsAccount from '@/module/User/component/DisplayFieldsAccountLog.vue'
import DisplayFieldsAdditionalProfile from '@/module/User/component/DisplayFieldsAdditionalLogHistory.vue'
import DisplayFieldsDatapivacyLogHistory from '@/module/User/component/DisplayFieldsDatapivacyLogHistory.vue'
import DisplayFieldsDataredeemLogHistory from '@/module/User/component/DisplayFieldsDataredeemLogHistory.vue'
import DisplayFieldsDataFlagHistory from '@/module/User/component/DisplayFieldsLogFlag.vue'
import DisplayFieldsDataTagHistory from '@/module/User/component/DisplayFieldsLogTag.vue'
import moment from 'moment'
import AppLayout from '@/components/Layout/default.vue'
import { QuestionCircleOutlined } from '@ant-design/icons-vue'
import { config } from '@/config/bzbsconfig.js'

var Cache = new CacheHelper(ConfigManager.getCacheType())

//#endregion

export default {
  name: 'UserDetail',
  components: {
    // LeftCircleOutlined,
    // Footer,
    TitleBreadcrumbs,
    Profile,
    Coupon,
    Redeem,
    Purchase,
    Points,
    Level,
    LevelFlag,
    Timeline,
    DataPrivacy,
    SubAccount,
    DisplayFieldsLevel,
    DisplayFieldsAccount,
    DisplayFieldsAdditionalProfile,
    DisplayFieldsDatapivacyLogHistory,
    DisplayFieldsDataredeemLogHistory,
    DisplayFieldsDataFlagHistory,
    DisplayFieldsDataTagHistory,
    Wallet,
    Tags,
    Badge,
    Subscription,
    AppLayout,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      coupon: Cache.getCache('app').has_coupon,
      showDisplayFieldsLevel: false,
      showDisplayFieldsAccount: false,
      showAdditionalProfile: false,
      showDataPrivacyHistory: false,
      value1: 'overview',
      datalastaction: null,
      datalastseen: null,
      datalastactive: null,
      dataredeem: [],
      datalogsdetail: null,
      overlaytablemodal: false,
      selectquarter: {},
      dataapp: [],
      page: 1,
      tabs: [
        {
          key: 'account',
          title: 'Account',
          permission: 'User-List-Account-View',
        },
        {
          key: 'subAccount',
          title: 'Additional Profile',
          permission: 'User-List-Additional-Profile-View',
        },
        {
          key: 'coupon',
          title: 'Coupon',
          permission: 'User-List-Coupon-View',
        },
        {
          key: 'purchase',
          title: 'Purchase',
          permission: 'User-List-Purchase-View',
        },
        {
          key: 'redeem',
          title: 'Redeem',
          permission: 'User-List-Redeem-View',
        },
        {
          key: 'points',
          title: 'Points',
          permission: 'User-List-Point-View',
        },
        {
          key: 'subscription',
          title: 'Subscription',
          permission: 'User-List-Subscription-View',
        },
        {
          key: 'level-flag',
          title: 'Flag',
          permission: 'User-List-Flag-View',
        },
        {
          key: 'level',
          title: 'Level',
          permission: 'User-List-Level-View',
        },
        {
          key: 'timeline',
          title: 'Timeline',
          permission: 'User-List-Timeline-View',
        },
        {
          key: 'dataprivacy',
          title: 'Data Privacy',
          permission: 'User-List-Data-Privacy-View',
        },
        {
          key: 'wallet',
          title: 'Wallet',
          permission: 'User-List-Wallet-View',
        },
        {
          key: 'tags',
          title: 'Tags',
          permission: 'User-List-Tag-View',
        },
        {
          key: 'badge',
          title: 'Mission',
          permission: 'User-List-Mission-View',
        },
      ],
      userId: this.$route.params.id,
      firstName:
        localStorage.getItem('userinfo') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('userinfo')).first_name,
      lastName:
        localStorage.getItem('userinfo') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('userinfo')).last_name,
      activeKey: 'account',
      formatdate: 'DD/MMM/YYYY',
      tab2: [],
      permission: [],
      showDataRedeemHistory: false,
      modalOTP: false,
      dataOTP: [],
      columnsOTP: [
        {
          title: 'Send Date',
          dataIndex: 'send_date',
          slots: { customRender: 'send_date' },
          key: 'send_date',
          width: 160,
          align: 'left',
        },
        {
          title: 'Ref. Code',
          dataIndex: 'ref_code',
          slots: { customRender: 'ref_code' },
          key: 'ref_code',
          width: 120,
          align: 'center',
        },
        {
          title: 'OTP',
          dataIndex: 'otp',
          slots: { customRender: 'otp' },
          key: 'otp',
          width: 100,
          align: 'center',
        },
        {
          title: 'Used Date',
          dataIndex: 'used_date',
          slots: { customRender: 'used_date' },
          key: 'used_date',
          width: 160,
          align: 'left',
        },
        {
          title: 'Expired Date',
          dataIndex: 'expired_date',
          slots: { customRender: 'expired_date' },
          key: 'expired_date',
          width: 160,
          align: 'left',
        },
      ],
      contact_number: '',
      showDataFlagHistory: false,
      showDataTagHistory: false,
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name:
            this.permission.includes('User-List-Real-Time-Data') &&
            this.permission.includes('User-List-View')
              ? 'list'
              : this.permission.includes('User-List-Real-Time-Data')
              ? 'listrealtime'
              : 'list',
          breadcrumbName: 'User List',
        },
        {
          name: null,
          breadcrumbName: 'User ID : ' + this.userId,
        },
      ]
    },
  },
  mounted() {},

  created: function () {
    this.getad().then(() => {
      if (this.$route.query.tab) {
        this.activeKey = this.$route.query.tab
        this.$store.commit('setLoading', false)
      } else {
        if (this.tab2[0].key) {
          this.activeKey = this.tab2[0].key
          this.changeTabs(this.activeKey)
          // this.activeKey = this.tab2[0].key
          // this.$route.query.tab = this.tab2[0].key
          // this.$store.commit('setLoading', false)
          // console.log(this.activeKey)
        }
      }
      if (this.coupon != true) {
        this.tabs = this.tabs.filter(item => {
          return item.key != 'coupon'
        })
      }
    })
  },
  methods: {
    setProfile(profile) {
      this.contact_number = profile.user_info?.contact_number
        ? profile.user_info?.contact_number
        : ''
    },
    refreshOTP() {
      this.getOTP()
    },
    toggleOTP() {
      this.getOTP().then(() => {
        this.modalOTP = true
      })
    },
    getOTP() {
      this.$store.commit('setLoading', true)
      var appid = Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiLogGetOTP(appid, this.contact_number, this.page).subscribe(
          res => {
            this.dataOTP = res.result
            this.$store.commit('setLoading', false)
            resolve(this.dataOTP)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleCancel() {
      this.modalOTP = false
    },
    resendOTP() {
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Confirm to Resend OTP?',
        content: 'You are about to Resend OTP.',
        okText: 'Confirm',
        cancelText: 'Cancel',
        width: '480px',
        icon: <QuestionCircleOutlined />,
        okButtonProps: {
          type: 'primary',
        },
        onOk: () => {},
        onCancel: () => {},
      })
    },
    getad() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            this.dataad = res.result
            this.dataapp = this.dataad.applications.find(
              x => x.application_id == Cache.getCache('app').id,
            )
            this.permission = this.dataapp.features.map(x => x.name)
            this.tabs.forEach(x => this.settabpermission(x.key, x.permission))
            this.tab2 = this.tabs.sort(function (a, b) {
              if (a.title < b.title) {
                return -1
              }
              if (a.title > b.title) {
                return 1
              }
              return 0
            })
            resolve(this.dataad)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    settabpermission(tab, keytab) {
      if (this.dataapp.features.every(x => x.name != keytab)) {
        this.tabs = this.tabs.filter(item => {
          return item.key != tab
        })
      }
    },
    tabtab() {
      this.page = 1
      this.dataredeem = this.datalogsdetail.result.timeline_types.find(
        x => x.type == this.value1,
      )?.details
    },
    backToUserList() {
      this.$router.push({
        name: 'list',
      })
    },
    changeTabs(activeKey) {
      this.showDisplayFieldsAccount = false
      this.showAdditionalProfile = false
      this.showDisplayFieldsLevel = false
      this.showDataPrivacyHistory = false
      this.showDataRedeemHistory = false
      this.showDataFlagHistory = false
      this.showDataTagHistory = false
      this.activeKey = activeKey
      var query = { tab: activeKey }
      _.forEach(this.$route.query, (value, key) => {
        if (key != 'tab') {
          query[key] = value
        }
      })
      this.$router.push({
        name: 'user_detail',
        params: {
          id: this.userId,
        },
        query: query,
      })
    },
    checkTabs() {
      this.datalogsdetail.result.types.includes('Login')
    },
    getdate(date) {
      return moment(date).format(this.formatdate)
    },
    handleScrollModal(event) {
      if (!this.nomoremodal && !this.overlaytablemodal) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytablemodal = true
          this.page++
          this.PostDatatracelogsdetails(this.selectquarter).then(() => {
            this.overlaytablemodal = false
          })
        }
      }
    },
    clicksearch(quarter) {
      this.value1 = 'overview'
      this.PostDatatracelogsdetails(quarter)
    },
    PostDatatracelogsdetails(quarter) {
      var userlevelupdate = {
        page_number: this.page,
        limit: 10,
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        quarter_items: quarter,
      }
      this.selectquarter = quarter
      console.log(userlevelupdate.quarter_items)
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostDatatracelogsdetail(userlevelupdate)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            if (this.page == 1) {
              this.dataredeem = []
              this.datalogsdetail = res.data
              this.datalastaction = this.datalogsdetail.result.overview
                .last_action
                ? moment(
                    this.datalogsdetail.result.overview.last_action,
                  ).format(this.formatdate)
                : '-'
              this.datalastactive = this.datalogsdetail.result.overview
                .last_active
                ? moment(
                    this.datalogsdetail.result.overview.last_active,
                  ).format(this.formatdate)
                : '-'
              this.datalastseen = this.datalogsdetail.result.overview.last_seen
                ? moment(this.datalogsdetail.result.overview.last_seen).format(
                    this.formatdate,
                  )
                : '-'
            } else {
              this.dataredeem.push(
                ...res.data.result.timeline_types.find(
                  x => x.type == this.value1,
                ).details,
              )
            }
            if (res.data.page_number == -1) {
              this.nomoremodal = true
            } else {
              this.nomoremodal = false
            }
            resolve(res.data.data)
            this.getdate()
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    gethistorylogs() {
      this.$store.commit('setLoading', true)
      var type = ''
      if (this.activeKey == 'account') {
        type = 'Account'
      }
      if (this.activeKey == 'level') {
        type = 'UserLevel'
      }
      if (this.activeKey == 'subAccount') {
        type = 'UserDetail'
      }
      if (this.activeKey == 'dataprivacy') {
        type = 'DataPrivacy'
      }
      if (this.activeKey == 'redeem') {
        type = 'Redeem'
      }
      if (this.activeKey == 'level-flag') {
        type = 'UserFlag'
      }
      if (this.activeKey == 'tags') {
        type = 'UserTag'
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetLogHistoryLog(this.$route.params.id, type).subscribe(
          res => {
            this.historylogs = res.result
            _.forEach(res.result, item => {
              item.new_data = JSON.parse(item.new_data)
              item.original_data = JSON.parse(item.original_data)
              item.log_message = item.log_message
                ? item.log_message.replaceAll('\r\n', '<br/>')
                : item.log_message
            })
            this.$store.commit('setLoading', false)
            resolve(this.historylogs)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleDisplayFields() {
      if (this.showDisplayFieldsLevel == false) {
        this.gethistorylogs().then(() => {
          this.showDisplayFieldsLevel = !this.showDisplayFieldsLevel
        })
      } else {
        this.showDisplayFieldsLevel = !this.showDisplayFieldsLevel
      }
    },
    toggleAdditionalProfile() {
      if (this.showAdditionalProfile == false) {
        this.gethistorylogs().then(() => {
          this.showAdditionalProfile = !this.showAdditionalProfile
        })
      } else {
        this.showAdditionalProfile = !this.showAdditionalProfile
      }
    },
    toggleDisplayFieldsAccount() {
      if (this.showDisplayFieldsAccount == false) {
        this.gethistorylogs().then(() => {
          this.showDisplayFieldsAccount = !this.showDisplayFieldsAccount
        })
      } else {
        this.showDisplayFieldsAccount = !this.showDisplayFieldsAccount
      }
    },
    toggleDataPrivacyHistory() {
      if (this.showDataPrivacyHistory == false) {
        this.gethistorylogs().then(() => {
          this.showDataPrivacyHistory = !this.showDataPrivacyHistory
        })
      } else {
        this.showDataPrivacyHistory = !this.showDataPrivacyHistory
      }
    },
    toggleDataRedeemHistory() {
      if (this.showDataRedeemHistory == false) {
        this.gethistorylogs().then(() => {
          this.showDataRedeemHistory = !this.showDataRedeemHistory
        })
      } else {
        this.showDataRedeemHistory = !this.showDataRedeemHistory
      }
    },
    toggleDataFlagHistory() {
      if (this.showDataFlagHistory == false) {
        this.gethistorylogs().then(() => {
          this.showDataFlagHistory = !this.showDataFlagHistory
        })
      } else {
        this.showDataFlagHistory = !this.showDataFlagHistory
      }
    },
    toggleDataTagHistory() {
      if (this.showDataTagHistory == false) {
        this.gethistorylogs().then(() => {
          this.showDataTagHistory = !this.showDataTagHistory
        })
      } else {
        this.showDataTagHistory = !this.showDataTagHistory
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
</style>
<style lang="scss">
@import '@/style/base/base.scss';

.page-userdetail {
  .gab-10 {
    gap: 10px;
  }

  .filter-nav {
    display: flex;
    gap: 10px;

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
    }

    .ant-input-group {
      width: unset !important;
    }
  }

  .ant-tabs-nav-wrap {
    background: #fff;
    border-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-width: 2px;
    padding: 0 15px;
    // margin-bottom: 10px;
  }

  .ant-tabs-content {
    background: #fff;
    border-radius: 6px;
    max-height: calc(100vh - 258px);
  }

  .ant-tabs-content2 {
    background: #fff;
    border-radius: 6px;
    max-height: calc(100vh - 258px);
  }

  .ant-tabs-content-point {
    background: #f9f9f9;
    border-radius: 6px;
    max-height: calc(100vh - 258px);
  }
  
  .ant-tabs-content-dataprivacy {
    background: #f9f9f9;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0px !important;
    border-radius: 6px;
    height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
  }

  .ant-tabs-content-additional {
    background: #f9f9f9;
    padding: 0px !important;
    border-radius: 6px;
    height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
  }

  .ant-tabs-tabpane {
    color: $color-body;
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .nav-search {
    display: block;
    width: 100%;
    padding-bottom: 15px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-left: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid $color-grey-line;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .datefilter {
      right: 0 !important;
      left: unset !important;
    }

    .group-btn-left {
      float: right;
      border-right: 1px solid #ecedf3;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .group-btn {
      float: right;
    }
  }

  .h-tabs {
    color: #a6a6b6;
  }

  .wrap-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 340px);

    .ant-table-wrapper {
      min-width: 2200px;
      width: 100%;
    }
  }
  .wrap-table-point {
    display: block;
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 465px);
  }

  @media only screen and (max-width: 1268px) {
    .wrap-table {
      display: block;
      width: 100%;
      overflow-x: auto;
      height: calc(100vh - 400px);

      .ant-table-wrapper {
        min-width: 2200px;
        width: 100%;
      }
    }
  }
}

.w-footer {
  width: calc(100% - 240px);
}

.ant-tabs-extra-content {
  background-color: white;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-width: 2px;
  padding: 12px 20px;
  height: 48px;
}

.content-timeline {
  background: #fff;
  border-radius: 6px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0 2px 4px 0 rgb(102 108 139 / 10%);
}

.hover-content:hover {
  background: #f7f7f7;
}

.wrap-table-modal {
  display: block;
  width: 100%;
  min-height: 410px;
  max-height: 410px;
  overflow-x: auto;
}

.fs-14 {
  font-size: 14px;
}

.mt-50 {
  margin-top: 50px;
}
</style>
