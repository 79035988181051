<template>
  <div class="user-badge onetime dataprivacy">
    <nav class="nav-search mt-4">
      <div class="group-btn pl-3">
        <a-button
          type="toggle"
          class="btn-icon hover"
          @click="toggleDisplayFields"
        >
          <img class="left" src="@/assets/icons/button/Control.svg" alt />
          Display fields
        </a-button>

        <a-button
          v-if="dataapp.includes('User-List-Mission-Export')"
          class="btn-export btn-icon hover"
          type="toggle"
          @click="exportReport"
        >
          <img class="left" src="@/assets/icons/button/Export.svg" alt /> Export
        </a-button>
      </div>
      <div class="group-btn-left">
        <a-select
          class="select-search"
          placeholder="All Types"
          v-model:value="statustype"
          :options="itemtypes"
        />
        <a-button type="primary" class="btn-search" @click="clicksearch(true)"
          ><search-outlined /> Search</a-button
        >
      </div>
    </nav>
    <div class="wrap-table" @scroll="handleScroll">
      <VLoadingTable :value="overlaytable" />
      <a-table
        row-key="partitionkey"
        class="fixed-head"
        :dataSource="dataMissions"
        :columns="displayFields"
        :pagination="false"
      >
        <template #image="{ record }">
          <div v-if="record.image">
            <a-image :src="record.image" :preview="false" :fallback="noimg" />
          </div>
          <div v-if="!record.image">
            <a-image :src="noimg" :preview="false" :fallback="noimg" />
          </div>
        </template>
        <template #partitionKey="{ record }">
          <div>{{ record.trace_plan_keys.partition_key }}</div>
        </template>
        <template #name="{ record }">
          <div>{{ record.name ? record.name : '-' }}</div>
        </template>
        <template #description="{ record }">
          <div>{{ record.description ? record.description : '-' }}</div>
        </template>
        <template #start_date="{ record }">
          <div>
            {{
              record.start_date
                ? formatDate(
                    record.start_date,
                    'DD MMM YYYY',
                    this.isTimezone * -1,
                  )
                : '-'
            }}
          </div>
        </template>
        <template #end_date="{ record }">
          <div>
            {{
              record.end_date
                ? formatDate(
                    record.end_date,
                    'DD MMM YYYY',
                    this.isTimezone * -1,
                  )
                : '-'
            }}
          </div>
        </template>
        <template #point="{ record }">
          <div>{{ record.points ? record.points[0] : '-' }}</div>
        </template>
        <template #user_level="{ record }">
          <div>{{ record.user_level ? record.user_level : '-' }}</div>
        </template>
        <template #interval_limit="{ record }">
          <div>{{ record.interval_limit ? record.interval_limit : '-' }}</div>
        </template>
        <template #interval_period="{ record }">
          <div>{{ record.interval_period ? record.interval_period : '-' }}</div>
        </template>
        <template #stop_trace_on="{ record }">
          <div>{{ record.stop_trace_on ? record.stop_trace_on : '-' }}</div>
        </template>
        <template #active="{ record }">
          <template v-for="(item, index) in status">
            <a-tag
              v-if="record.active == item.name"
              :key="index"
              :color="item.color == 'default' ? null : item.color"
              class="tag-status"
            >
              <a-badge
                v-if="record.active == item.name"
                :status="item.color"
                :text="item.text"
              />
            </a-tag>
          </template>
        </template>
        <template #missions="{ record }">
          <a class="text-link" @click="showModalMissions(record)">View</a>
        </template>
      </a-table>
    </div>
    <a-modal
      :visible="missions_modal"
      @cancel="handleCancel"
      :title="'Type : ' + type"
      class="modal-tablelist"
      width="650px"
      :ok-button-props="{ style: { display: 'none' } }"
      :closable="false"
      :centered="true"
    >
      <!-- <div class="py-4 px-4 border-b-modal">
        <span class="title-modal">Type : 
          <span v-if="type == 'Register'">Register</span>
          <span v-if="type == 'Login'">Login</span>
          <span v-if="type == 'Redeem'">Redeem</span>
          <span v-if="type == 'Purchase'">Purchase</span>
          <span v-if="type == 'Profile'">Profile</span>
          <span v-if="type == 'FriendsGetFriends'">Friends get friends</span>
          <span v-if="type == 'Survey'">Survey</span>
          <span v-if="type == 'Custom' || type == 'Earn'">Custom</span>
        </span>
      </div> -->
      <div class="">
        <div v-if="this.type == 'Register'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <a-row v-if="datamissioninfo">
            <a-col :span="6">
              <div class="text-gray">Start Date :</div>
              <div class="text-gray">Redeem Campaigns :</div>
            </a-col>
            <a-col :span="18">
              <div>
                {{
                  datamissioninfo.start_date ? datamissioninfo.start_date : '-'
                }}
              </div>
              <div
                v-if="
                  datamissioninfo.redeem_campaigns &&
                  datamissioninfo.redeem_campaigns.length == 0
                "
              >
                <div>-</div>
              </div>
              <div
                v-if="
                  datamissioninfo.redeem_campaigns &&
                  datamissioninfo.redeem_campaigns.length > 0
                "
              >
                <div
                  v-for="(redeem, index) of datamissioninfo.redeem_campaigns"
                  :key="`index-${index}`"
                >
                  <div>{{ redeem.badge_id ? redeem.badge_id : '-' }}</div>
                  <div>
                    {{ redeem.badge_row_key ? redeem.badge_row_key : '-' }}
                  </div>
                  <div>{{ redeem.campaign_id ? redeem.campaign_id : '-' }}</div>
                  <div>{{ redeem.name ? redeem.name : '-' }}</div>
                  <div>{{ redeem.active ? redeem.active : '-' }}</div>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
        <div v-if="this.type == 'Login'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <div v-if="datamissioninfo">
            <div class="flex mb-4">
              <div class="bg-collect mr-2" v-if="datamissioninfo.first_time">
                <check-outlined class="collect" />First Time
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.first_time"
              >
                <close-outlined class="not-collect" />First Time
              </div>
              <div class="bg-collect" v-if="datamissioninfo.consecutive">
                <check-outlined class="collect" />Consecutive
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.consecutive"
              >
                <close-outlined class="not-collect" />Consecutive
              </div>
            </div>
            <hr />
            <a-row class="mt-4">
              <a-col :span="12" class="flex">
                <div class="text-gray">Day Amounts :</div>
                <div class="pl-5">
                  {{
                    datamissioninfo.day_amounts
                      ? datamissioninfo.day_amounts
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="12">
                <a-row>
                  <a-col :span="12">
                    <div class="text-gray">Redeem Campaigns:</div>
                  </a-col>
                  <a-col :span="12">
                    <div
                      v-if="
                        datamissioninfo.redeem_campaigns &&
                        datamissioninfo.redeem_campaigns.length == 0
                      "
                    >
                      <div>-</div>
                    </div>
                    <div
                      v-if="
                        datamissioninfo.redeem_campaigns &&
                        datamissioninfo.redeem_campaigns.length > 0
                      "
                    >
                      <div
                        v-for="(
                          redeem, index
                        ) of datamissioninfo.redeem_campaigns"
                        :key="`index-${index}`"
                      >
                        <div>{{ redeem.badge_id ? redeem.badge_id : '-' }}</div>
                        <div>
                          {{
                            redeem.badge_row_key ? redeem.badge_row_key : '-'
                          }}
                        </div>
                        <div>
                          {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                        </div>
                        <div>{{ redeem.name ? redeem.name : '-' }}</div>
                        <div>{{ redeem.active ? redeem.active : '-' }}</div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
        <div v-if="this.type == 'Redeem'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <div v-if="datamissioninfo">
            <div class="flex mb-4">
              <div class="bg-collect mr-2" v-if="datamissioninfo.is_campaign">
                <check-outlined class="collect" />Campaign
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.is_campaign"
              >
                <close-outlined class="not-collect" />Campaign
              </div>
              <div class="bg-collect" v-if="datamissioninfo.is_category">
                <check-outlined class="collect" />Category
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.is_category"
              >
                <close-outlined class="not-collect" />Category
              </div>
            </div>
            <hr />
            <a-row class="mt-4">
              <a-col :span="12" class="flex">
                <div class="text-gray">Criterions :</div>
                <div class="pl-5">
                  <div
                    v-if="
                      datamissioninfo.criterions &&
                      datamissioninfo.criterions.length == 0
                    "
                  >
                    <div>-</div>
                  </div>
                  <div
                    v-if="
                      datamissioninfo.criterions &&
                      datamissioninfo.criterions.length > 0
                    "
                  >
                    <div
                      v-for="(addon, index) of datamissioninfo.addon_data"
                      :key="`index-${index}`"
                    >
                      <div>{{ addon.id }} : {{ addon.name }}</div>
                    </div>
                  </div>
                </div>
              </a-col>
              <a-col :span="10">
                <a-row>
                  <a-col :span="14">
                    <div class="text-gray">Redeem Campaigns:</div>
                  </a-col>
                  <a-col :span="10">
                    <div
                      v-if="
                        datamissioninfo.redeem_campaigns &&
                        datamissioninfo.redeem_campaigns.length == 0
                      "
                    >
                      <div>-</div>
                    </div>
                    <div
                      v-if="
                        datamissioninfo.redeem_campaigns &&
                        datamissioninfo.redeem_campaigns.length > 0
                      "
                    >
                      <div
                        v-for="(
                          redeem, index
                        ) of datamissioninfo.redeem_campaigns"
                        :key="`index-${index}`"
                      >
                        <div>{{ redeem.badge_id ? redeem.badge_id : '-' }}</div>
                        <div>
                          {{
                            redeem.badge_row_key ? redeem.badge_row_key : '-'
                          }}
                        </div>
                        <div>
                          {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                        </div>
                        <div>{{ redeem.name ? redeem.name : '-' }}</div>
                        <div>{{ redeem.active ? redeem.active : '-' }}</div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
        <div v-if="this.type == 'Purchase'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <div v-if="datamissioninfo">
            <div class="flex mb-4">
              <div class="bg-collect mr-2" v-if="datamissioninfo.is_campaign">
                <check-outlined class="collect" />Campaign
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.is_campaign"
              >
                <close-outlined class="not-collect" />Campaign
              </div>
              <div class="bg-collect mr-2" v-if="datamissioninfo.is_category">
                <check-outlined class="collect" />Category
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.is_category"
              >
                <close-outlined class="not-collect" />Category
              </div>
              <div
                class="bg-collect mr-2"
                v-if="datamissioninfo.is_payment_channel"
              >
                <check-outlined class="collect" />Payment Channel
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.is_payment_channel"
              >
                <close-outlined class="not-collect" />Payment Channel
              </div>
              <div
                class="bg-collect mr-2"
                v-if="datamissioninfo.is_payment_amount"
              >
                <check-outlined class="collect" />Payment Amount
              </div>
              <div
                class="bg-not-collect mr-2"
                v-if="!datamissioninfo.is_payment_amount"
              >
                <close-outlined class="not-collect" />Payment Amount
              </div>
              <div class="bg-collect mr-2" v-if="datamissioninfo.is_sku">
                <check-outlined class="collect" />SKU
              </div>
              <div class="bg-not-collect mr-2" v-if="!datamissioninfo.is_sku">
                <close-outlined class="not-collect" />SKU
              </div>
            </div>
            <hr />
            <a-row class="mt-4">
              <a-col :span="6" class="text-gray">Payment Amount :</a-col>
              <a-col :span="10">{{
                datamissioninfo.payment_amount
                  ? datamissioninfo.payment_amount
                  : '-'
              }}</a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-row>
                  <a-col :span="12" class="text-gray">Criterions :</a-col>
                  <a-col :span="10">
                    <div
                      v-if="
                        datamissioninfo.criterions &&
                        datamissioninfo.criterions.length == 0
                      "
                    >
                      <div>-</div>
                    </div>
                    <div
                      v-if="
                        datamissioninfo.criterions &&
                        datamissioninfo.criterions.length > 0
                      "
                    >
                      <div
                        v-for="(addon, index) of datamissioninfo.addon_data"
                        :key="`index-${index}`"
                      >
                        <div>{{ addon.id }} : {{ addon.name }}</div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
              <a-col :span="12">
                <a-row>
                  <a-col :span="12">
                    <div class="text-gray">Redeem Campaigns:</div>
                  </a-col>
                  <a-col :span="12">
                    <div
                      v-if="
                        datamissioninfo.redeem_campaigns &&
                        datamissioninfo.redeem_campaigns.length == 0
                      "
                    >
                      <div>-</div>
                    </div>
                    <div
                      v-if="
                        datamissioninfo.redeem_campaigns &&
                        datamissioninfo.redeem_campaigns.length > 0
                      "
                    >
                      <div
                        v-for="(
                          redeem, index
                        ) of datamissioninfo.redeem_campaigns"
                        :key="`index-${index}`"
                      >
                        <div>
                          badge_id :
                          {{ redeem.badge_id ? redeem.badge_id : '-' }}
                        </div>
                        <div>
                          badge_row_key :
                          {{
                            redeem.badge_row_key ? redeem.badge_row_key : '-'
                          }}
                        </div>
                        <div>
                          campaign_id :
                          {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                        </div>
                        <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                        <div>
                          active : {{ redeem.active ? redeem.active : '-' }}
                        </div>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
        <div v-if="this.type == 'Profile'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <a-row v-if="datamissioninfo">
            <a-col :span="12">
              <a-row>
                <a-col :span="10" class="text-gray">On Update :</a-col>
                <a-col :span="12">
                  <div>
                    {{
                      datamissioninfo.on_update
                        ? datamissioninfo.on_update
                        : '-'
                    }}
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="10" class="text-gray">Limit :</a-col>
                <a-col :span="12">
                  <div>
                    {{ datamissioninfo.limit ? datamissioninfo.limit : '-' }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row>
                <a-col :span="12">
                  <div class="text-gray">Redeem Campaigns:</div>
                </a-col>
                <a-col :span="12">
                  <div
                    v-if="
                      datamissioninfo.redeem_campaigns &&
                      datamissioninfo.redeem_campaigns.length == 0
                    "
                  >
                    <div>-</div>
                  </div>
                  <div
                    v-if="
                      datamissioninfo.redeem_campaigns &&
                      datamissioninfo.redeem_campaigns.length > 0
                    "
                  >
                    <div
                      v-for="(
                        redeem, index
                      ) of datamissioninfo.redeem_campaigns"
                      :key="`index-${index}`"
                    >
                      <div>
                        badge_id : {{ redeem.badge_id ? redeem.badge_id : '-' }}
                      </div>
                      <div>
                        badge_row_key :
                        {{ redeem.badge_row_key ? redeem.badge_row_key : '-' }}
                      </div>
                      <div>
                        campaign_id :
                        {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                      </div>
                      <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                      <div>
                        active : {{ redeem.active ? redeem.active : '-' }}
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div v-if="this.type == 'FriendsGetFriends'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <a-row v-if="datamissioninfo">
            <a-col :span="12">
              <a-row>
                <a-col :span="10" class="text-gray">
                  <div>Points :</div>
                  <div>UserLevel :</div>
                  <div>Interval Limit :</div>
                  <div>Interval Period :</div>
                  <div>Stop Trace On :</div>
                </a-col>
                <a-col :span="12">
                  <div>{{ datamissioninfo.inviter_data.point }}</div>
                  <div>{{ datamissioninfo.inviter_data.user_level }}</div>
                  <div>{{ datamissioninfo.inviter_data.interval_limit }}</div>
                  <div>
                    {{
                      datamissioninfo.inviter_data.interval_period
                        ? datamissioninfo.inviter_data.interval_period
                        : '-'
                    }}
                  </div>
                  <div>
                    {{
                      datamissioninfo.inviter_data.stop_trace_on
                        ? datamissioninfo.inviter_data.stop_trace_on
                        : '-'
                    }}
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row>
                <a-col :span="12">
                  <div class="text-gray">Redeem Campaigns:</div>
                </a-col>
                <a-col :span="12">
                  <div
                    v-if="
                      datamissioninfo.redeem_campaigns &&
                      datamissioninfo.redeem_campaigns.length == 0
                    "
                  >
                    <div>-</div>
                  </div>
                  <div
                    v-if="
                      datamissioninfo.redeem_campaigns &&
                      datamissioninfo.redeem_campaigns.length > 0
                    "
                  >
                    <div
                      v-for="(
                        redeem, index
                      ) of datamissioninfo.redeem_campaigns"
                      :key="`index-${index}`"
                    >
                      <div>
                        badge_id : {{ redeem.badge_id ? redeem.badge_id : '-' }}
                      </div>
                      <div>
                        badge_row_key :
                        {{ redeem.badge_row_key ? redeem.badge_row_key : '-' }}
                      </div>
                      <div>
                        campaign_id :
                        {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                      </div>
                      <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                      <div>
                        active : {{ redeem.active ? redeem.active : '-' }}
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div v-if="this.type == 'Survey'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <a-row v-if="datamissioninfo">
            <a-col :span="12">
              <a-row>
                <a-col :span="6" class="text-gray">
                  <div>Points :</div>
                  <div>UserLevel :</div>
                  <div>Criterions :</div>
                </a-col>
                <a-col :span="18">
                  <div>
                    {{ datamissioninfo.point ? datamissioninfo.point : '-' }}
                  </div>
                  <div>
                    {{
                      datamissioninfo.user_level
                        ? datamissioninfo.user_level
                        : '-'
                    }}
                  </div>
                  <div
                    v-if="
                      datamissioninfo.criterions &&
                      datamissioninfo.criterions.length == 0
                    "
                  >
                    <div>-</div>
                  </div>
                  <div
                    v-if="
                      datamissioninfo.criterions &&
                      datamissioninfo.criterions.length > 0
                    "
                  >
                    <div
                      v-for="(addon, index) of datamissioninfo.addon_data"
                      :key="`index-${index}`"
                    >
                      <div>{{ addon.id }} : {{ addon.name }}</div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="12">
              <a-row>
                <a-col :span="12">
                  <div class="text-gray">Redeem Campaigns:</div>
                </a-col>
                <a-col :span="12">
                  <div
                    v-if="
                      datamissioninfo.redeem_campaigns &&
                      datamissioninfo.redeem_campaigns.length == 0
                    "
                  >
                    <div>-</div>
                  </div>
                  <div
                    v-if="
                      datamissioninfo.redeem_campaigns &&
                      datamissioninfo.redeem_campaigns.length > 0
                    "
                  >
                    <div
                      v-for="(
                        redeem, index
                      ) of datamissioninfo.redeem_campaigns"
                      :key="`index-${index}`"
                    >
                      <div>
                        badge_id : {{ redeem.badge_id ? redeem.badge_id : '-' }}
                      </div>
                      <div>
                        badge_row_key :
                        {{ redeem.badge_row_key ? redeem.badge_row_key : '-' }}
                      </div>
                      <div>
                        campaign_id :
                        {{ redeem.campaign_id ? redeem.campaign_id : '-' }}
                      </div>
                      <div>name : {{ redeem.name ? redeem.name : '-' }}</div>
                      <div>
                        active : {{ redeem.active ? redeem.active : '-' }}
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div v-if="this.type == 'Custom' || this.type == 'Earn'">
          <div v-if="!datamissioninfo" class="center">
            <img :src="imgEmpty" />
            <div class="nodata">No Data</div>
          </div>
          <div v-if="datamissioninfo">
            <a-row class="mb-4">
              <a-col :span="6" class="text-gray">Condition :</a-col>
              <a-col :span="10">{{
                datamissioninfo.condition ? datamissioninfo.condition : '-'
              }}</a-col>
            </a-row>
            <hr />
            <a-row class="mt-4">
              <a-col :span="8">
                <a-row>
                  <a-col :span="12" class="text-gray">Cooldown :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.cooldown ? datamissioninfo.cooldown : '-'
                  }}</a-col>
                  <a-col :span="12" class="text-gray">Expire :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.expire ? datamissioninfo.expire : '-'
                  }}</a-col>
                  <a-col :span="12" class="text-gray">Limit :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.limit ? datamissioninfo.limit : '-'
                  }}</a-col>
                  <a-col :span="12" class="text-gray">Owner :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.owner ? datamissioninfo.owner : '-'
                  }}</a-col>
                  <a-col :span="12" class="text-gray">Increment :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.increment ? datamissioninfo.increment : '-'
                  }}</a-col>
                </a-row>
              </a-col>
              <a-col :span="8">
                <a-row>
                  <a-col :span="13" class="text-gray">Time Rounding :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.time_rounding
                      ? datamissioninfo.time_rounding
                      : '-'
                  }}</a-col>
                  <a-col :span="13" class="text-gray">Keyword :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.keyword ? datamissioninfo.keyword : '-'
                  }}</a-col>
                  <a-col :span="13" class="text-gray">Need Approval :</a-col>
                  <a-col :span="10">{{ datamissioninfo.need_approval }}</a-col>
                  <a-col :span="13" class="text-gray">Object ID :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.object_id ? datamissioninfo.object_id : '-'
                  }}</a-col>
                  <a-col :span="13" class="text-gray">Buzz Type :</a-col>
                  <a-col :span="10">{{
                    datamissioninfo.buzz_type ? datamissioninfo.buzz_type : '-'
                  }}</a-col>
                </a-row>
              </a-col>
              <a-col :span="8">
                <a-row>
                  <a-col :span="15" class="text-gray">Stop Trace On :</a-col>
                  <a-col :span="9">{{
                    datamissioninfo.stop_trace_on
                      ? datamissioninfo.stop_trace_on
                      : '-'
                  }}</a-col>
                  <a-col :span="15" class="text-gray">Allow Custom :</a-col>
                  <a-col :span="9">{{
                    datamissioninfo.allow_custom
                      ? datamissioninfo.allow_custom
                      : '-'
                  }}</a-col>
                  <a-col :span="15" class="text-gray">User Level :</a-col>
                  <a-col :span="9">{{
                    datamissioninfo.user_level
                      ? datamissioninfo.user_level
                      : '-'
                  }}</a-col>
                  <a-col :span="15" class="text-gray"
                    >Require User Level :</a-col
                  >
                  <a-col :span="9">{{
                    datamissioninfo.require_user_level
                      ? datamissioninfo.require_user_level
                      : '-'
                  }}</a-col>
                  <a-col :span="15" class="text-gray">Custom Info :</a-col>
                  <a-col :span="9">{{
                    datamissioninfo.custom_info
                      ? datamissioninfo.custom_info
                      : '-'
                  }}</a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </a-modal>
    <DisplayFields
      ref="selectall"
      :value="showDisplayFields"
      :headers="columns"
      @apply="applyDisplayFields($event)"
      @handleClose="showDisplayFields = false"
    >
    </DisplayFields>
    <ExportModal
      :visible="showExportModal"
      @handleSubmit="handleExportModal($event)"
      @handleClose="showExportModal = false"
    ></ExportModal>
    <PrepareModal
      :visible="showPrepareModal"
      @handleClose="showPrepareModal = false"
    ></PrepareModal>
  </div>
</template>

<script>
import _ from 'lodash'
// import moment from 'moment'
// import dayjs from 'dayjs'
// content components
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import ConfigManager from '@/config/ConfigManager.js'
import DisplayFields from '@/module/User/component/DisplayFieldsUserDetail.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import PrepareModal from '@/module/User/component/PrepareModal.vue'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons-vue'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserRedeem',
  components: {
    DisplayFields,
    ExportModal,
    PrepareModal,
    CloseOutlined,
    CheckOutlined,
  },
  props: {
    dataapp: {
      type: Array,
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      noimg: require('@/assets/icons/button/ico-img.svg'),
      showDisplayFields: false,
      isstatus: null,
      missions_modal: false,
      overlaytable: false,
      statustype: null,
      status_types: [],
      searchObject: {
        campaign_id: '',
        campaign_name: '',
        agency_name: '',
        category_name: '',
        serial: '',
        parcel_no: '',
        status: '',
        startdate: '',
        enddate: '',
      },
      optionssearch: 'name',
      options_search: [
        {
          label: 'Name',
          value: 'name',
        },
      ],
      options_campaigntype: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Draw',
          value: 'Draw',
        },
        {
          label: 'Donate',
          value: 'Donate',
        },
        {
          label: 'Free',
          value: 'Free',
        },
        {
          label: 'Deal',
          value: 'Deal',
        },
        {
          label: 'Interface',
          value: 'Interface',
        },
      ],
      search: '',
      params: {
        name: '',
        description: '',
        filter: {
          user_id: '',
          appid: '',
          campaign_id: null,
          campaign_name: '',
          agency_name: '',
          category_name: '',
          serial: '',
          parcel_no: '',
          campaign_type: '',
          date_filter_type: null,
          is_shipped: null,
          delivered: null,
          is_used: null,
          start_date: null,
          end_date: null,
        },
      },
      type: '',
      typeregister: false,
      typeloginonetime: false,
      typeloginconsecutive: false,
      typeredeem: false,
      typepurchase: false,
      typeprofile: false,
      typeinvite: false,
      typereferal: false,
      typebirthmonth: false,
      page: 1,
      columns: [
        {
          title: '',
          dataIndex: 'image',
          key: 'image',
          slots: { customRender: 'image' },
          // sorter: (a, b) => a.campaignid - b.campaignid,
          // sortDirections: ['descend'],
          width: 140,
          align: 'left',
        },
        {
          title: 'PartitionKey',
          dataIndex: 'partitionKey',
          slots: { customRender: 'partitionKey' },
          key: 'partitionKey',
          width: 220,
          align: 'left',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          slots: { customRender: 'name' },
          key: 'name',
          width: 220,
          align: 'left',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          slots: { customRender: 'description' },
          key: 'description',
          width: 320,
          align: 'left',
        },
        {
          title: 'Start Date',
          dataIndex: 'start_date',
          slots: { customRender: 'start_date' },
          key: 'start_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'End Date',
          dataIndex: 'end_date',
          slots: { customRender: 'end_date' },
          key: 'end_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Active',
          dataIndex: 'active',
          slots: { customRender: 'active' },
          key: 'active',
          width: 120,
          align: 'center',
        },
        {
          title: 'Point',
          dataIndex: 'point',
          slots: { customRender: 'point' },
          key: 'point',
          width: 100,
          align: 'right',
        },
        {
          title: 'User Level',
          dataIndex: 'user_level',
          slots: { customRender: 'user_level' },
          key: 'user_level',
          width: 120,
          align: 'left',
        },
        {
          title: 'Interval Limit',
          dataIndex: 'interval_limit',
          slots: { customRender: 'interval_limit' },
          key: 'intv_limit',
          width: 140,
          align: 'right',
        },
        {
          title: 'Interval Period',
          dataIndex: 'interval_period',
          slots: { customRender: 'interval_period' },
          key: 'intv_period',
          width: 160,
          align: 'right',
        },
        {
          title: 'Stop Trace On',
          dataIndex: 'stop_trace_on',
          slots: { customRender: 'stop_trace_on' },
          key: 'stop_trace_on',
          width: 160,
          align: 'right',
        },
        {
          title: 'Info',
          dataIndex: 'missions',
          slots: { customRender: 'missions' },
          key: 'missions',
          width: 100,
          align: 'center',
        },
      ],
      missionsColumns: [
        {
          title: 'Issuer',
          dataIndex: 'issuer',
          key: 'name',
          align: 'left',
          width: 550,
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          align: 'left',
          width: 150,
        },
        {
          title: 'Current',
          dataIndex: 'current',
          key: 'current',
          align: 'left',
          width: 150,
        },
        {
          title: 'Complete',
          dataIndex: 'complete',
          slots: { customRender: 'complete' },
          key: 'complete',
          align: 'center',
          width: 150,
        },
      ],
      dataMissions: [],
      campaigntype: '',
      displayFields: [],
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      formatdate: 'DD/MMM/YYYY',
      showExportModal: false,
      showPrepareModal: false,
      datamissioninfo: [],
      row_key: '',
      partition_key: '',
      mapping_type: '',
      itemtypes: [],
      lastid: '',
      datalastid: '1',
      checksearch: false,
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
    }
  },
  beforeCreate() {
    this.displayFields = _.cloneDeep(this.columns)
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'badge') {
        this.displayFields = _.cloneDeep(this.columns)
        this.getMissionlist().then(() => {
          this.$store.commit('setLoading', false)
        })
        this.$store.commit('setLoading', false)
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'badge') {
      this.displayFields = _.cloneDeep(this.columns)
      this.clicksearch()
      this.overlay = false
      this.getTracelogTypesMission()
    }
  },
  created() {},
  methods: {
    clicksearch(checksearch) {
      if (checksearch) {
        this.datalastid = ''
      } else {
        if (this.datalastid == '1') {
          this.datalastid = ''
        }
      }
      this.getMissionlist()
    },
    getMissionlist() {
      this.$store.commit('setLoading', true)
      if (this.statustype == 'alltypes') {
        this.statustype = null
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetMissionlist(
          this.$route.params.id,
          this.statustype,
          this.datalastid,
        ).subscribe(
          data => {
            if (this.datalastid == '') {
              this.dataMissions = []
            }
            this.datalastid = data.lastid
            _.forEach(data.result, item => {
              this.dataMissions.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataMissions)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getMissioninfo() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetMissioninfo(
          this.row_key,
          this.partition_key,
          this.mapping_type,
        ).subscribe(
          res => {
            this.datamissioninfo = res.result[0]
            resolve(this.datamissioninfo)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleDisplayFields() {
      this.showDisplayFields = !this.showDisplayFields
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          if (this.datalastid != '') {
            this.overlaytable = true
            this.getMissionlist().then(() => {
              this.overlaytable = false
            })
          }
        }
      }
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        displayFields_select.push({
          title: '',
          dataIndex: '',
          key: '',
          width: this.$refs.selectall.selectAll ? 60 : 0,
        }),
          (this.displayFields = _.cloneDeep(displayFields_select))
      })
    },

    exportReport() {
      this.showExportModal = true
      console.log(this.showExportModal)
    },
    showModalMissions(datadetail) {
      this.$store.commit('setLoading', true)
      this.type = datadetail.mapping_type
      if (this.type == 'FriendsGetFriends') {
        this.type = 'Friends get friends'
      } else if (this.type == 'Earn') {
        this.type = 'Custom'
      }
      this.row_key = datadetail.trace_plan_keys.row_key
      this.partition_key = datadetail.trace_plan_keys.partition_key
      if (datadetail.mapping_type == 'Earn') {
        this.mapping_type = 'Custom'
      } else {
        this.mapping_type = datadetail.mapping_type
      }
      this.datamissioninfo = []
      this.getMissioninfo().then(() => {
        this.missions_modal = true
        this.$store.commit('setLoading', false)
      })
    },
    handleCancel() {
      this.missions_modal = false
    },
    getTracelogTypesMission() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTracelogTypesMission().subscribe(
          res => {
            this.typesmission = res.result
            _.forEach(this.typesmission, (item, index) => {
              this.itemtypes[index] = {
                label: item.mapping_type,
                value: item.mapping_type,
              }
              if (this.itemtypes[index].value == 'FriendsGetFriends') {
                this.itemtypes[index].label = 'Friends get friends'
              }
            })
            this.itemtypes.unshift({
              label: 'All Types',
              value: 'alltypes',
            })
            this.itemtypes = this.itemtypes.filter(x => {
              return x.label != 'Earn'
            })
            resolve(this.typesmission)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.page-userdetail {
  .campaign_type {
    width: 120px;
    float: left;
    margin-left: 10px;
  }
}
.select-search {
  width: 150px !important;
}
.iswinner {
  font-size: 12px;
  .not-yet {
    color: #666666;
  }
  .winner {
    color: #2ec743;
  }
  .not-winner {
    color: #fba45b;
  }
}
.isnull {
  width: 10px;
  height: 10px;
  background-color: #aaaaaa;
  border-radius: 40px;
}
.isactive {
  width: 10px;
  height: 10px;
  background-color: #22ba20;
  border-radius: 40px;
}
.isunactive {
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 40px;
}
</style>
<style lang="scss">
.user-badge {
  .select-search {
    width: 165px !important;
  }
  .wrap-table {
    height: calc(100vh - 415px) !important;
    max-height: calc(100vh - 415px) !important;
  }
  .ant-table-row {
    vertical-align: top !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: #f79443 !important;
  }
}
.onetime {
  .wrap-table .ant-table-wrapper {
    min-width: 1900px !important;
  }
  @media only screen and (max-width: 1343px) {
    .user-badge {
      .wrap-table {
        height: calc(100vh - 460px) !important;
      }
    }
  }
}
.collect {
  width: 10px !important;
  color: #2ec743 !important;
  vertical-align: inherit !important;
  margin-right: 5px !important;
}
.not-collect {
  width: 10px !important;
  color: #fb7290 !important;
  vertical-align: inherit !important;
  margin-right: 5px !important;
}
.bg-collect {
  border-radius: 20px;
  background-color: #edffef;
  padding: 0px 10px 0px 10px;
}
.bg-not-collect {
  border-radius: 20px;
  background-color: #fff5f8;
  padding: 0px 10px 0px 10px;
}
.mock {
  width: 70px;
  height: 70px;
  border-radius: 4px;
  background-color: #d9d9d9;
}
</style>
