<template>
  <!-- <a-row class="wrap-card-app">
    <a-col
      v-for="item in app"
      :key="item.appid"
      :sm="{ span: 24 }"
      :md="{ span: 12 }"
      :lg="{ span: 8 }"
    >
      <div class="card mb-5">
        <div class="detail-app">
          <div class="box-logo">
            <img :src="item.logo" @error="imageLoadError"/>
          </div>
          <div class="box-app-detail">
            <b>{{ item.name }}</b>
            <a-button type="primary" @click="selectApp(item)">
              MANAGE
            </a-button>
          </div>
        </div>
        <div class="detail-userdaily">
          <div class="flex items-center justify-between bg-white">
            <p>{{ item.new_user }}</p>
            <p>Daily New Users</p>
          </div>
          <div class="flex items-center justify-between bg-white">
            <p>{{ item.active_user }}</p>
            <p>Daily Active Users</p>
          </div>
          <div class="flex items-center justify-between bg-white">
            <p>{{ item.active_30day }}</p>
            <p>30 Days Active User</p>
          </div>
          <div class="flex items-center justify-between bg-white">
            <p>{{ item.total_users }}</p>
            <p>Total User</p>
          </div>
        </div>
      </div>
    </a-col>
  </a-row> -->
  <div class="wrapper-card">
    <a-row :gutter="16">
      <template v-for="(item, index) in app" :key="item.appid">
        <a-col
          v-if="
            index + 1 <= pagesize * current &&
            index + 1 > pagesize * (current - 1)
          "
          class="gutter-row"
          :xs="24"
          :sm="12"
          :lg="8"
          :xl="6"
        >
          <a-card class="app-item" @click="selectApp(item)">
            <div class="app-frame">
              <div class="d-flex justify-content-between">
                <img class="logo" :src="item.logo" @error="imageLoadError" />
                <a-badge
                  :count="'Total User : ' + item.total_users"
                  :overflow-count="999999"
                  :class="type == 'default' ? null : 'count-' + type"
                />
              </div>
              <h2 v-if="item.name.length < 25" class="short-text">
                {{ item.name }}
              </h2>
              <a-tooltip v-else placement="bottom">
                <template #title>
                  <span>{{ item.name }}</span>
                </template>
                <h2 class="short-text">
                  {{ item.name }}
                </h2>
              </a-tooltip>
              <p class="short-text text-caption">
                {{ 'App ID' }} {{ item.appid }}
              </p>
              <p class="short-text text-caption">
                {{ 'Agency' }} : {{ item.agency_id }} ( {{ item.agency_name }} )
              </p>
              <div class="bg-detail mt-2">
                <div class="detail-userdaily">
                  <div class="flex items-center justify-between">
                    <p class="text-caption-detail">
                      Daily New Users : {{ item.new_user }}
                    </p>
                  </div>
                  <div class="flex items-center justify-between">
                    <p class="text-caption-detail">
                      Daily Active Users : {{ item.active_user }}
                    </p>
                  </div>
                  <div class="flex items-center justify-between">
                    <p class="text-caption-detail">
                      30 Days Active User : {{ item.active_30day }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <a-button class="mt-3" block @click="selectApp(item)">
                Select Application
              </a-button> -->
            </div>
          </a-card>
        </a-col>
      </template>
    </a-row>
  </div>
  <div v-if="app.length > pagesize" class="box-pagination">
    <a-pagination
      :total="app.length"
      v-model:current="current"
      v-model:pageSize="pagesize"
      :show-size-changer="false"
      @change="onChangePage"
    />
  </div>
</template>
<script>
import CacheHelper from '@/helper/CacheHelper.js'
var cache = new CacheHelper('cookie')
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { config } from '@/config/bzbsconfig.js'
import Account from '@/helper/AccountHelper'
import ConfigManager from '@/config/ConfigManager.js'
var cacheSearch = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'CardApplication',
  props: {
    app: Object,
  },
  components: {},
  mixins: [],
  emits: [],
  data() {
    return {
      dataad: [],
      dataapp: {},
      permissionbyapp: [],
      type: 'list',
      current: 1,
    }
  },
  computed: {
    pagesize: {
      get() {
        if (this.windowWidth < 380) {
          return 2
        } else {
          if (this.windowWidth < 576) {
            return 6
          } else {
            if (this.windowWidth < 1024) {
              return 12
            } else {
              if (this.windowWidth < 1440) {
                return 15
              } else {
                return 18
              }
            }
          }
        }
      },
    },
    tableHeight: {
      get() {
        if (this.windowWidth < 768) {
          return this.windowHeight - 360
        } else {
          return this.windowHeight - 307
        }
      },
    },
  },
  created() {},
  mounted() {},
  methods: {
    imageLoadError(event) {
      event.target.src =
        config.bzbBlobUrl + '/themebackoffice/assets/3/img/blank.png'
    },
    onChangePage(pageNumber) {
      this.current = pageNumber
    },
    selectApp(app) {
      cache.setCache('app', {
        id: app.appid,
        name: app.name,
        logo: app.logo,
        purchase_type: app.purchase_type,
        has_coupon: app.has_coupon,
      })
      cacheSearch.removeCache('setFilter')
      cacheSearch.removeCache('setFilterTotal')
      cacheSearch.removeCache('filterUser')
      cacheSearch.removeCache('handleSearch')
      cacheSearch.removeCache('SearchRealtime')
      cacheSearch.removeCache('totalBadge')
      this.$store.commit('setIsSearch', false)
      this.getad().then(() => {
        this.$store.commit('setIsSearch', false)
        localStorage.setItem('permission', JSON.stringify(this.dataapp))
        localStorage.setItem(
          'permissionbyapp',
          JSON.stringify(this.permissionbyapp),
        )
        this.$forceUpdate()
        Account.getMSMenu2(app.appid).then(() => {
          Account.setDefaultMenu()
        })
      })
    },
    getad() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            this.dataad = res.result
            this.dataapp = this.dataad.applications.find(
              x => x.application_id == cache.getCache('app')?.id,
            )
            if (this.dataapp) {
              this.permissionbyapp = this.dataapp.features.map(x => x.name)
            }
            resolve(this.dataad)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.wrap-card-app {
  margin: 0 -12px;

  .ant-col {
    padding: 0 12px;
  }

  .card {
    margin-bottom: 20px;
    box-shadow: none;
    background-color: #ffffff;
    border: 1px solid #f5f5f5;
    border-radius: 6px;

    .detail-app {
      padding: 20px;
      overflow: hidden;
      border-bottom: 2px solid #f5f5f5;

      .box-logo {
        width: 80px;
        height: 80px;
        padding: 8px;
        border-radius: 15px;
        background-color: #fff;
        float: left;
      }

      .box-app-detail {
        float: left;
        width: calc(100% - 80px);
        padding-left: 20px;

        b {
          display: block;
        }

        .ant-btn {
          margin-top: 10px;
        }
      }
    }

    .detail-userdaily {
      padding: 20px;

      + .detail-userdaily {
        border-top: 1px solid $color-bg-app;
      }

      p {
        padding: 5px 0;
      }
    }
  }
}

.bg-detail {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 6px;
}

.img-logo {
  width: 120px;
  height: 120px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.app-item {
  .image {
    width: 120px;
    height: 120px;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  p,
  h2 {
    margin: 0;
    padding: 0;
  }

  h2 {
    margin-bottom: 12px !important;
  }

  .ant-btn {
    margin-top: calc(2 * var(--spacing));
  }
  .text-caption-detail {
    font-size: 12px;
  }
}
</style>
