<template>
  <div class="channelmaster">
    <!-- <a @click="toggleTicketDetail">test</a> -->
    <div class="mb-4 b-6 bg-white pl-4 pr-4 d-flex justify-content-end">
      <div class="py-2 d-flex">
        <a-select
          class="select-search"
          v-model:value="status_channel"
          placeholder="All Status"
          :options="subject_option"
        />
        <a-button type="primary" @click="clicksearch()" class="btn-search mx-3"
          ><search-outlined />Search</a-button
        >
        <div class="">
          <a-tooltip placement="bottom" class="ml-3">
            <template #title>
              <div>Create Channel</div>
            </template>
            <a-button
              class="hover btn-icon"
              @click="toggleCreateChannel()"
              :color="'blue-1'"
            >
              <img class="left" src="@/assets/icons/button/ico-plus.svg" alt />
            </a-button>
          </a-tooltip>
        </div>
      </div>
    </div>

    <div class="wrap-table" @scroll="handleScroll">
      <!-- <VLoadingTable :value="overlaytable" /> -->
      <a-table row-key="partitionkey" :columns="columns" :pagination="false">
        <!-- <template #channel="{ record }">
              <a
                v-if="datapermission.includes('Channel-Adjust')"
                class="text-link"
                @click="toggleChannelDetail(record)"
                >{{ record.name ? record.name : '-' }}</a
              >
              <div v-else>{{ record.name ? record.name : '-' }}</div>
            </template>
            <template #status="{ record }">
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="record.active == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge :status="item.color" :text="item.text" />
                </a-tag>
              </template>
            </template> -->
      </a-table>
      <div class="table-scroll-joblist">
        <div v-for="(data, index) of dataChannel.items" :key="`index-${index}`">
          <div class="bg-list mt-3">
            <div class="row">
              <div class="col-10">
                <a class="text-link" @click="toggleChannelDetail(data)"
                  ><h4 class="text-link">
                    {{ data.name ? data.name : '-' }}
                  </h4></a
                >
              </div>
              <div class="col-2">
                <template v-for="(item, index) in status">
                  <a-tag
                    v-if="data.active == item.name"
                    :key="index"
                    :color="item.color == 'default' ? null : item.color"
                    class="tag-status"
                  >
                    <a-badge :status="item.color" :text="item.text" />
                  </a-tag>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a-modal
    class="modal-set-expire"
    :visible="modalCreateChannel"
    @cancel="handleCancel"
    title="Create Channel"
    okText="Save"
    :width="400"
    :centered="true"
    @ok="PostCreateChannel()"
    :ok-button-props="{ disabled: !channel_name }"
    :closable="false"
  >
    <div class="mb-2"><label class="false mr-1">*</label>Channel</div>
    <a-input
      style="width: 340px"
      :maxlength="50"
      v-model:value="channel_name"
    />
    <!-- <div class="mb-2 mt-3">Status</div>
    <a-radio-group v-model:value="isactive">
      <a-radio :value="1">Active</a-radio>
      <a-radio :value="2">Inactive</a-radio>
    </a-radio-group> -->
  </a-modal>
  <a-modal
    class="modal-set-expire"
    :visible="modalEditChannel"
    @cancel="handleCancel"
    title="Edit Channel"
    :width="420"
    :centered="true"
    @ok="PostEditChannel()"
    :closable="false"
  >
    <div class="mb-2"><label class="false mr-1">*</label>Channel</div>
    <a-input v-model:value="recordChannel.name" />
    <div class="mb-2 mt-3"><label class="false mr-1">*</label>Status</div>
    <a-radio-group v-model:value="recordChannel.active">
      <a-radio :value="true">Active</a-radio>
      <a-radio :value="false">Inactive</a-radio>
    </a-radio-group>
    <template #footer>
      <div class="d-flex justify-content-between">
        <div>
          <a-button @click="deleteChannel" type="danger">Delete</a-button>
        </div>
        <div>
          <a-button @click="handleCancel">Cancel</a-button>
          <a-button
            type="primary"
            :disabled="!recordChannel.name"
            @click="PostEditChannel"
            >Save</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script>
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { DeleteOutlined } from '@ant-design/icons-vue'

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    // TopBar,
    // Footer,
    // SidebarMenu,
  },
  data: function () {
    return {
      appId_list: JSON.parse(localStorage.getItem('applist')),
      subject: 'all',
      mode: 'card',
      status_channel: true,
      subject_option: [
        {
          label: 'All status',
          value: null,
        },
        {
          label: 'Active',
          value: true,
        },
        {
          label: 'Inactive',
          value: false,
        },
      ],
      dataChannel: [],
      dataTest: [],
      columns: [
        {
          title: 'Channel',
          dataIndex: 'channel',
          slots: { customRender: 'channel' },
          key: 'channel',
          width: 580,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 120,
          align: 'left',
        },
      ],
      status: [
        {
          id: 1,
          name: false,
          text: 'Inactive',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Active',
          color: 'green',
        },
        {
          id: 3,
          name: null,
          text: 'Default',
          color: 'default',
        },
      ],
      modalCreateChannel: false,
      modalEditChannel: false,
      showModalAdvanceSearch: false,
      showModalTicketDetail: false,
      channelId: '',
      statusId: null,
      channel: '',
      value: null,
      isactive: null,
      channel_name: '',
      recordChannel: {},
      recorddata: {},
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'My Applications',
        },
      ]
    },
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'adjustpoint') {
        this.getChannelList()
      }
    },
  },
  mounted() {
    this.getChannelList().then(() => {
      this.$store.commit('setLoading', false)
    })
  },
  methods: {
    clicksearch() {
      this.getChannelList()
    },
    handleCancel() {
      this.channel = ''
      this.value = null
      this.recordChannel = {}
      this.modalCreateChannel = false
      this.modalEditChannel = false
    },
    toggleChannelDetail(record) {
      this.recordChannel = { ...record }
      this.channel_name = record.name
      this.isactive = record.active ? 1 : 2
      this.modalEditChannel = true
    },
    toggleCreateChannel() {
      this.channel_name = ''
      this.isactive = null
      this.modalCreateChannel = !this.modalCreateChannel
    },
    toggleTicketDetail() {
      this.showModalTicketDetail = !this.showModalTicketDetail
    },
    getChannelList() {
      this.$store.commit('setLoading', true)
      var fromData = {}
      fromData = {
        appId_list: this.appId_list.map(x => x.appId),
        active: this.status_channel,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostMasterChannel(fromData)
          .then(res => {
            this.dataChannel = res.data.result
            this.$store.commit('setLoading', false)
            resolve(res.result)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    getChannel() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetChannel(this.status_channel).subscribe(
          res => {
            this.dataChannel = res.result
            this.$store.commit('setLoading', false)
            resolve(this.dataChannel)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    PostEditChannel() {
      var channel = {
        AppId: null,
        name: this.recordChannel.name,
        active: this.recordChannel.active,
        id: this.recordChannel.id,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateChannel(channel)
          .then(res => {
            this.modalEditChannel = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getChannelList()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostCreateChannel() {
      var channel = {
        AppId: null,
        name: this.channel_name,
        active: true,
        id: 0,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateChannel(channel)
          .then(res => {
            this.modalCreateChannel = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getChannelList()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    deleteChannel() {
      this.modalEditChannel = false
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you sure you want to delete this channel?',
        content:
          'This action cannot be undone. Deleting the channel will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostdeleteChannel()
        },
        onCancel: () => {},
      })
    },
    PostdeleteChannel() {
      var channel = {
        AppId: null,
        id: this.recordChannel.id,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRemoveChannel(channel)
          .then(res => {
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getChannelList()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';
.left-menu-width {
  // flex: 0 0 $left-menu-width;
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 8%);
  width: $left-menu-width;
  z-index: 15;

  &.collapse {
    // width: 44px;
    width: 64px;
    // flex: 0 0 55px;
  }
}
.page-select-app {
  .wrap-select-app {
    height: calc(100vh - 258px);
    overflow-y: auto;
  }
  .box-select-app {
    padding: 0px 20px 20px 20px;
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.channelmaster {
  .select-search {
    width: 200px;
    padding-left: 15px;
  }
  .wrap-date-filter {
    position: relative;
  }
  .datefilter {
    right: 0;
    left: unset;
  }
  .table-scroll-joblist {
    overflow-x: auto;
    overflow-y: auto;
    // box-shadow: 0 2px 4px 0 rgb(102 108 139 / 10%);
    height: calc(100vh - 370px);
  }
  .managesub {
    position: absolute;
    right: 22px;
    top: 152px;
  }
  .bg-list {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #c9c9c9;
  }
  .ant-table-tbody {
    display: none !important;
  }
}
</style>
