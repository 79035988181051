<template>
  <AppLayout>
    <div class="content-wrapper">
      <Unauthorized />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/Layout/default'
import Unauthorized from '@/components/Auth/UnauthorizedView'
export default {
  name: 'PageUnAuthorized',
  components: {
    AppLayout,
    Unauthorized,
  },
  metaInfo() {
    return {
      title: `Unauthorized - ${this.pageTitle}`,
    }
  },
  data() {
    return {}
  },
  created() { },
}
</script>
