export function Application(obj) {
  this.appid = obj.application.appid
  this.logo = obj.application.logo
  this.name = obj.application.name
  this.purchase_type = obj.application.purchase_type
  this.total_users =
    obj.application && typeof obj.application.total_users == 'number'
      ? obj.application.total_users.toLocaleString()
      : '-'
  this.active_30day =
    obj.userdaily && typeof obj.userdaily.active_30day == 'number'
      ? obj.userdaily.active_30day.toLocaleString()
      : '-'
  this.active_user =
    obj.userdaily && typeof obj.userdaily.active_user == 'number'
      ? obj.userdaily.active_user.toLocaleString()
      : '-'
  this.burn_point =
    obj.userdaily && typeof obj.userdaily.burn_point == 'number'
      ? obj.userdaily.burn_point.toLocaleString()
      : '-'
  this.burn_users =
    obj.userdaily && typeof obj.userdaily.burn_users == 'number'
      ? obj.userdaily.burn_users.toLocaleString()
      : '-'
  this.earn_and_burn_user =
    obj.userdaily && typeof obj.userdaily.earn_and_burn_user == 'number'
      ? obj.userdaily.earn_and_burn_user.toLocaleString()
      : '-'
  this.earn_point =
    obj.userdaily && typeof obj.userdaily.earn_point == 'number'
      ? obj.userdaily.earn_point.toLocaleString()
      : '-'
  this.entered_code =
    obj.userdaily && typeof obj.userdaily.entered_code == 'number'
      ? obj.userdaily.entered_code.toLocaleString()
      : '-'
  this.entered_user =
    obj.userdaily && typeof obj.userdaily.entered_user == 'number'
      ? obj.userdaily.entered_user.toLocaleString()
      : '-'
  this.new_user =
    obj.userdaily && typeof obj.userdaily.new_user == 'number'
      ? obj.userdaily.new_user.toLocaleString()
      : '-'
  this.redeem_cross_luckydraw =
    obj.userdaily && typeof obj.userdaily.redeem_cross_luckydraw == 'number'
      ? obj.userdaily.redeem_cross_luckydraw.toLocaleString()
      : '-'
  this.redeem_cross_luckydraw_user =
    obj.userdaily && typeof obj.userdaily.redeem_cross_luckydraw == 'number'
      ? obj.userdaily.redeem_cross_luckydraw.toLocaleString()
      : '-'
  this.redeem_luckydraw =
    obj.userdaily && typeof obj.userdaily.redeem_luckydraw == 'number'
      ? obj.userdaily.redeem_luckydraw.toLocaleString()
      : '-'
  this.redeem_luckydraw_user =
    obj.userdaily && typeof obj.userdaily.redeem_luckydraw_user == 'number'
      ? obj.userdaily.redeem_luckydraw_user.toLocaleString()
      : '-'
  this.redeem_privilege =
    obj.userdaily && typeof obj.userdaily.redeem_privilege == 'number'
      ? obj.userdaily.redeem_privilege.toLocaleString()
      : '-'
  this.redeem_privilege_user =
    obj.userdaily && typeof obj.userdaily.redeem_privilege_user == 'number'
      ? obj.userdaily.redeem_privilege_user.toLocaleString()
      : '-'

  this.has_coupon = obj.application.has_coupon
  this.agency_id = obj.application?.agency?.id
  this.agency_name = obj.application?.agency?.name
}
