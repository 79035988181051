import ConfigManager from '@/config/ConfigManager.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
import { map } from 'rxjs/operators'

export default {
  apiGetApplication(filter_application) {
    if (filter_application != 'undefined' && filter_application != undefined) {
          var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/dashboard/applications?filter_application=' +
      filter_application
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
    } else {
     var urls =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/dashboard/applications'
    return BzbsApi.connectBzbsGet(urls).pipe(
      map(res => {
        return res.data
      }),
      )
    }
   
  },
  // apiPostQueuesExport(params) {
  // 	var url = ConfigManager.getUsermgrAPI() + "/api/v1.0/worker/queues";
  // 	return BzbsApi.connectBzbsPost(url, params)
  // },
}
