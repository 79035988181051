<template>
  <div class="app">
    <router-view />
    <VLoading v-if="currentRoute.name != 'redirect-ms'" id="appLoading" />
  </div>
</template>
<script>
import moment from 'moment'
//store
import SideMenuStore from '@/store/SideMenuStore.js'
import TopBarStore from '@/store/TopBarStore'
import ConfigStore from '@/store/ConfigStore.js'
import { config } from '@/config/bzbsconfig.js'
const newbof = config.bzbBlobUrl

export default {
  data() {
    return {
      currentRoute: this.$router.currentRoute,
    }
  },
  watch: {
    $route(to, from) {
      if (!to.hash && to.name !== from.name) {
        this.$store.commit('setLoading', true)
      }
    },
  },
  mounted() {
    document.title = 'User Management'

    let theme = document.createElement('link')
    theme.type = 'text/css'
    theme.rel = 'stylesheet'
    theme.href =
      newbof + '/themebackoffice/assets/css/5.css?v=' + moment().format('x')
    document.head.appendChild(theme)
  },
  created: function () {
    // ConfigStore.methods.init()
    // this.getConfigAzure()
  },
  provide: {
    SideMenuStore: SideMenuStore,
    TopBarStore: TopBarStore,
    ConfigStore: ConfigStore,
  },
}
</script>
<style lang="scss">
@import './style/base/base.scss';
@import './style/main.scss';
</style>
