<template>
  <a-card class="card-account">
    <a-row v-if="profile" class="min-h-full">
      <a-col
        :sm="{ span: 24 }"
        :md="{ span: 12 }"
        :lg="{ span: 6 }"
        class="min-h-full left-form"
      >
        <user-profile-left-timeline
          v-if="profile"
          :profile="profile"
          :dataapp="dataapp"
          :datauserblock="datauserblock"
          :datablacklist="datablacklist"
          :datacouponblock="datacouponblock"
          @changeTab="tab = $event"
        />
      </a-col>
      <a-col
        :sm="{ span: 24 }"
        :md="{ span: 12 }"
        :lg="{ span: 18 }"
        class="min-h-0 border-l-2 right-form"
      >
        <div class="flex flex-col min-h-full w-full divide-y-2">
          <div class="flex-auto min-h-0">
            <div class="p-4 pt-0">
              <user-profile-form
                v-if="profile"
                :dataapp="dataapp"
                :profile="profile"
              />
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row v-else class="min-h-full load">
      <a-skeleton avatar :paragraph="{ rows: 18 }" active />
    </a-row>
  </a-card>
</template>

<script>
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// content components
import UserProfileForm from '@/module/User/UserDetail/component/UserProfile/UserProfileForm.vue'
import UserProfileLeftTimeline from '@/module/User/UserDetail/component/UserProfile/UserProfileLeftTimeline.vue'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

//#endregion

export default {
  name: 'UserProfile',
  components: {
    UserProfileForm,
    UserProfileLeftTimeline,
  },
  props: {
    dataapp: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      tab: 0,
      disableTabNav: false,
      userActive: true,
      profile: null,
      datablacklist: null,
      datauserblock: null,
      datacouponblock: null,
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'account') {
        this.$store.commit('setLoading', true)
        this.getprofile().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'account') {
      this.$store.commit('setLoading', true)
      this.getprofile().then(() => {
        this.$store.commit('setLoading', false)
      })
    }
  },
  created() {},
  methods: {
    getprofile() {
      var appid = Cache.getCache('app').id
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetUserProfile(this.$route.params.id, appid).subscribe(
          res => {
            this.profile = res.result
            this.datablacklist = res.result.is_blacklist
            this.datauserblock = res.result.is_blocked_user
            this.datacouponblock = res.result.is_blocked_coupon
            localStorage.setItem(
              'Useraccount',
              JSON.stringify(this.profile.user_info),
            )
            this.$emit('profile', this.profile)
            resolve(this.profile)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/base/base.scss';

.card-account {
  height: calc(100vh - 258px);
  margin-bottom: unset !important;

  .right-form {
    overflow-x: auto !important;
    height: calc(100vh - 293px);
  }

  .left-form {
    overflow-x: auto !important;
    height: calc(100vh - 293px);
  }

  .load {
    height: calc(100vh - 293px);
  }
}

.page-body {
  margin-bottom: $footer-height;
}
</style>
