<template>
  <a-tabs
    v-if="data"
    v-model:activeKey="clickTabs"
    @change="callback"
    force-render
  >
    <a-tab-pane key="1">
      <template #tab>
        <a-badge :count="sumUnRead + sumRead" class="count-list">All</a-badge>
      </template>
      <ContentAnnouncement
        :data="data"
        :clicktabs="clickTabs"
        @getAllAnnouncement="getAllAnnouncement($event)"
      />
    </a-tab-pane>
    <a-tab-pane key="2" :disabled="sumUnRead > 0 ? false : true">
      <template #tab>
        <a-badge :count="sumUnRead">Unread</a-badge>
      </template>
      <ContentAnnouncement
        :data="data"
        :sum-un-read="sumUnRead"
        :clicktabs="clickTabs"
        @getAllAnnouncement="getAllAnnouncement($event)"
      />
    </a-tab-pane>
    <a-tab-pane key="3" :disabled="sumRead > 0 ? false : true">
      <template #tab>
        <a-badge :count="sumRead" class="count-list">Read</a-badge>
      </template>
      <ContentAnnouncement
        :data="data"
        :clicktabs="clickTabs"
        @getAllAnnouncement="getAllAnnouncement($event)"
      />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import ContentAnnouncement from '@/components/Layout/Header/ContentAnnouncement.vue'
export default {
  name: 'Announcement',
  components: {ContentAnnouncement},
  props: {
    isShow: Boolean,
    data: Array,
    sumRead: Number,
    sumUnRead: Number,
  },
  data() {
    return {
      clickTabs: '1',
    }
  },
  created() {},
  methods: {
    callback(key) {
      this.clickTabs = key
    },
    getAllAnnouncement() {
      this.$emit('getAllAnnouncement')
    },
  },
}
</script>
<style lang="scss">
.modal-announcement-list{
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #c9c9c9 !important;
  }
  .ant-tabs-nav{
    margin-left: 0px !important;
    padding-left: 16px !important;
  }
  .ant-tabs-tab{
    padding: 12px 16px !important;  
  }
  .ant-tabs-tab + .ant-tabs-tab{
    margin: unset;
  }
  .title-text{
    font-size: 1.25rem !important;
  }
}
</style>
