<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs class="mt-4" title="Approve List" />
    </template>
    <div class="main-menu approvepoint overflow-x-hidden flex flex-col">
      <a-tabs v-model:activeKey="activeKey" @change="changeTabs">
        <a-tab-pane
          v-for="tab in tab2"
          :key="tab.key"
          :tab="tab.title"
          force-render
        >
          <template v-if="tab.key == 'adjustpoint'">
            <ApprovePoint />
          </template>
          <template v-if="tab.key == 'importpoint'">
            <ApproveImportPoint />
          </template>
          <template v-if="tab.key == 'privacy'">
            <ApproveDatalist />
          </template>
          <template v-if="tab.key == 'receipt'">
            <ApproveReceipt />
          </template>
          <template v-if="tab.key == 'survey'">
            <ApproveSurvey />
          </template>
          <template v-if="tab.key == 'wallet'">
            <ApproveWallet />
          </template>
          <template v-if="tab.key == 'tranferpoint'">
            <ApproveTranferPoint />
          </template>
        </a-tab-pane>
      </a-tabs>
      <DisplayFieldsApproveDetail
        :value="showDisplayFieldsApproveDetail"
        :dataApproveByID="dataApproveByID"
        :approvepointpermission="approvepointpermission"
        :rejectpermission="rejectpermission"
        :managepointpermission="managepointpermission"
        @getapprovelist="getApprovePointslist($event)"
        @handleClose="showDisplayFieldsApproveDetail = false"
      >
      </DisplayFieldsApproveDetail>
    </div>
  </AppLayout>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import DisplayFieldsApproveDetail from '@/module/Setting/ApproveList/component/DisplayFieldsApprovePoint.vue'
import ApprovePoint from '@/module/Setting/ApproveList/pages/ApprovePoint.vue'
import ApproveDatalist from '@/module/Setting/ApproveList/pages/ApproveDataprivacy.vue'
import ApproveImportPoint from '@/module/Setting/ApproveList/pages/ApproveImportPoint.vue'
import ApproveReceipt from '@/module/Setting/ApproveList/pages/ApproveReceipt.vue'
import ApproveSurvey from '@/module/Setting/ApproveList/pages/ApproveSurvey.vue'
import ApproveWallet from '@/module/Setting/ApproveList/pages/ApproveWallet.vue'
import ApproveTranferPoint from '@/module/Setting/ApproveList/pages/ApproveTranferPoint.vue'
import moment from 'moment'
import dayjs from 'dayjs'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    // Footer,
    DisplayFieldsApproveDetail,
    ApproveDatalist,
    ApproveImportPoint,
    ApproveReceipt,
    ApprovePoint,
    ApproveSurvey,
    ApproveWallet,
    ApproveTranferPoint,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Setting',
        },
        {
          name: null,
          breadcrumbName: 'Approve List',
        },
      ],
      datapermission: JSON.parse(localStorage.getItem('permission')),
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      activeKey: null,
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      showDisplayFieldsApproveDetail: false,
      showDisplayFieldsApproveDataPrivacy: false,
      approvelistpermission: true,
      approvepointlistpermission: true,
      approvepointpermission: true,
      rejectpermission: true,
      managepointpermission: true,
      searchObject: {
        status: '',
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdropdown: null,
      status_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Created',
          value: 'Create',
        },
        {
          label: 'Approved',
          value: 'L1Approved',
        },
        {
          label: 'Rejected',
          value: 'Reject',
        },
      ],
      status_noti: [
        {
          id: 1,
          name: false,
          text: 'No',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Yes',
          color: 'green',
        },
      ],
      columns: [
        {
          title: 'User ID',
          dataIndex: 'user',
          slots: { customRender: 'user' },
          key: 'user',
          width: 120,
          align: 'left',
        },
        {
          title: 'Reason',
          dataIndex: 'reason',
          slots: { customRender: 'reason' },
          key: 'reason',
          width: 160,
          align: 'left',
        },
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 120,
          align: 'left',
        },
        {
          title: 'Create Date',
          dataIndex: 'created_date',
          slots: { customRender: 'create_date' },
          key: 'create_date',
          width: 120,
          align: 'left',
        },
        {
          title: 'Create By',
          dataIndex: 'create_by',
          slots: { customRender: 'create_by' },
          key: 'create_by',
          width: 120,
          align: 'left',
        },
        {
          title: 'Send Notification',
          dataIndex: 'send_notification',
          slots: { customRender: 'send_notification' },
          key: 'send_notification',
          width: 140,
          align: 'center',
        },
        // {
        //   title: 'Notification Message',
        //   dataIndex: 'message',
        //   slots: { customRender: 'message' },
        //   key: 'message',
        //   width: 220,
        //   align: 'left',
        // },
        // {
        //   title: 'Channel',
        //   dataIndex: 'channel',
        //   slots: { customRender: 'channel' },
        //   key: 'channel',
        //   width: 120,
        //   align: 'left',
        // },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'L1Approved',
          color: 'green',
        },
      ],
      dataApprove: [],
      dataApproveByID: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      pageId: this.$route.params.id,
      tabs: [
        {
          key: 'adjustpoint',
          title: 'Adjust Points',
          permission: 'Approve-List-Adjust-Points-View',
        },
        {
          key: 'importpoint',
          title: 'Import Points',
          permission: 'Approve-List-Import-Points-View',
        },
        {
          key: 'privacy',
          title: 'Privacy',
          permission: 'Approve-List-Privacy-View',
        },
        {
          key: 'receipt',
          title: 'Receipt',
          permission: 'Approve-List-Receipt-View',
        },
        {
          key: 'survey',
          title: 'Survey',
          permission: 'Approve-List-Survey-View',
        },
        {
          key: 'wallet',
          title: 'Wallet',
          permission: 'Approve-List-Wallet-View',
        },
        {
          key: 'tranferpoint',
          title: 'Transfer Points',
          permission: 'Approve-List-Transfer-Points-View',
        },
      ],
      tab2: [],
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'Setting',
        },
        {
          text: '<div class="font-normal text-sm text-gray-400">Approve List</div>',
        },
      ]
    },
  },
  mounted() {
    this.$store.commit('setLoading', true)
    this.clicksearch()
  },
  watch: {},
  methods: {
    changeTabs(activeKey) {
      this.activeKey = activeKey
      var query = { tab: activeKey }
      _.forEach(this.$route.query, (value, key) => {
        if (key != 'tab') {
          query[key] = value
        }
      })
      this.$router.push({
        name: 'approvelist',
        query: query,
      })
    },
    // changeTabs(tabs){
    //   if(tabs == 2){
    //     this.clicksearchprivacy()
    //   }else if(tabs == 1){
    //     this.clicksearch()
    //   }
    // },
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Created'
        case 'L1Approved':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    clicksearch() {
      this.$store.commit('setLoading', false)
      this.searchObject.status = this.statusdropdown
      this.page = 1
      this.showDateFilter = false
      this.setapprovelistpermission()
      this.setapprovepermission()
      this.setrejectpermission()
      this.setmanagepointpermission()
      this.tabs.forEach(x => this.settabpermission(x.key, x.permission))
      this.tab2 = this.tabs.sort(function (a, b) {
        if (a.title < b.title) {
          return -1
        }
        if (a.title > b.title) {
          return 1
        }
        return 0
      })
      if (this.$route.query.tab) {
        this.activeKey = this.$route.query.tab
        this.$store.commit('setLoading', false)
      } else {
        if (this.tab2[0].key) {
          this.activeKey = this.tab2[0].key
          this.changeTabs(this.activeKey)
        }
      }
    },
    settabpermission(tab, keytab) {
      if (this.datapermissionbyapp.every(x => x != keytab)) {
        this.tabs = this.tabs.filter(item => {
          return item.key != tab
        })
      }
    },
    getApprovePointslist() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetAdjustPoint(
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.status = this.statusdropdown
            ? this.statusdropdown
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataApprove = []
            }
            _.forEach(data.result, item => {
              this.dataApprove.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            resolve(this.dataApprove)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getApprovePointslist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApprovePointByID(data_approveid) {
      this.dataApproveByID = data_approveid
      this.showDisplayFieldsApproveDetail = !this.showDisplayFieldsApproveDetail
    },
    toggleApproveListByID() {
      this.showDisplayFieldsApproveDataPrivacy =
        !this.showDisplayFieldsApproveDataPrivacy
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    setapprovelistpermission() {
      if (
        this.datapermission.features.every(
          x => x.name != 'Approve-Privacy-List',
        )
      ) {
        return (this.approvelistpermission = false)
      }
      if (
        this.datapermission.features.every(
          x => x.name != 'Approve-List-Adjust-Points-View',
        )
      ) {
        return (this.approvepointlistpermission = false)
      }
    },
    setapprovepermission() {
      if (this.datapermission.features.every(x => x.name != 'Approve-Points')) {
        return (this.approvepointpermission = false)
      }
    },
    setrejectpermission() {
      if (this.datapermission.features.every(x => x.name != 'Reject-Points')) {
        return (this.rejectpermission = false)
      }
    },
    setmanagepointpermission() {
      if (
        this.datapermission.features.every(
          x => x.name != 'Approve-List-Adjust-Points-Edit',
        )
      ) {
        return (this.managepointpermission = false)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approvepoint {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }

  .nav-search {
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;

    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';

.main-menu {
  .datefilter {
    right: 0;
    left: unset;
  }
}

.approvepoint {
  .ant-table-row {
    vertical-align: top !important;
  }

  .ant-table-thead {
    vertical-align: top !important;
  }

  .ant-tabs-nav-wrap {
    background: #fff;
    border-radius: 6px;
    padding: 0 15px;
    border-bottom-width: 1px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: unset !important;
  }
}
</style>
