<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-receipt bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex">
              {{ dataDetailReceipt._id.split('|')[1] }}
              <h4 class="pr-2">{{ dataDetailReceipt.user_id }}</h4>
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="dataDetailReceipt.status == item.status"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge :status="item.color" :text="item.name" />
                </a-tag>
              </template>
            </div>
          </div>
          <div class="border-b d-flex flex-auto overflow-y-auto">
            <div class="w-50">
              <a-image :src="dataDetailReceipt.receipt_image" />
            </div>
            <div class="w-50">
              <div class="bg-con">
                <div>
                  Upload Date :{{ formatDate(dataDetailReceipt.upload_date) }}
                </div>
                <div>
                  Amount :
                  {{ dataDetailReceipt.amount.toLocaleString() }}
                </div>
                <div>Purchase Form : {{ dataDetailReceipt.shop_name }}</div>
                <div>Transaction ID : {{ dataDetailReceipt.row_key }}</div>
              </div>
              <div class="bg-con">
                <h5 class="mb-2">User</h5>
                <div class="d-flex">
                  <div class="pr-1">
                    Name: {{ dataDetailReceipt.first_name }}
                    {{ dataDetailReceipt.last_name }}
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    Contact number :
                    {{ contactNumber(dataDetailReceipt.contact_number) }}
                  </div>
                </div>
                <div>Email : {{ dataDetailReceipt.email || '-' }}</div>
              </div>
              <div class="bg-con">
                <h5 class="mb-2">Campaign Info</h5>
                <div class="d-flex">
                  <div>
                    Campaign Name: {{ dataDetailReceipt.campaign_name }}
                  </div>
                </div>
                <div>
                  Campaign ID:
                  {{ dataDetailReceipt.campaign_id }}
                </div>
                <div>
                  Campaign Item:
                  {{ dataDetailReceipt.campaign_item_no }}
                </div>
                <div>
                  Campaign Period:
                  {{ formatDate(dataDetailReceipt.campaign_start_date) }} -
                  {{ formatDate(dataDetailReceipt.campaign_expire_date) }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-if="dataDetailReceipt.status == 0" class="p-3">
            <div class="h-detail">Remark</div>
            <a-textarea
              v-model:value="remark"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
            <div class="mt-1 h-detail">
              * Rejection require remark, approval is optional.
            </div>
          </div> -->
          <div v-if="dataDetailReceipt.status != 0" class="p-4">
            <a-row>
              <a-col :span="6">
                <div class="h-detail">Remark :</div>
              </a-col>
              <a-col :span="18">
                <div v-if="!dataRemark">
                  {{
                    dataDetailReceipt.remark ? dataDetailReceipt.remark : '-'
                  }}
                </div>
                <div v-else>
                  {{
                    dataRemark.approve_reject_reason
                      ? dataRemark.approve_reject_reason
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="h-detail">Responded date :</div>
              </a-col>
              <a-col :span="18">
                <div v-if="!dataRemark">
                  {{
                    dataDetailReceipt.modify_date
                      ? formatDate(dataDetailReceipt.modify_date)
                      : '-'
                  }}
                </div>
                <div v-else>
                  {{
                    dataRemark.approve_reject_date
                      ? formatDate(
                          dataRemark.approve_reject_date,
                          'DD/MMM/YYYY',
                        )
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="h-detail">Respondent :</div>
              </a-col>
              <a-col :span="18">
                <div v-if="!dataRemark">
                  {{
                    dataDetailReceipt.modify_by
                      ? dataDetailReceipt.modify_by
                      : '-'
                  }}
                </div>
                <div v-else>
                  {{
                    dataRemark.approve_reject_by
                      ? dataRemark.approve_reject_by.name
                      : '-'
                  }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            :class="
              dataDetailReceipt.status != 0
                ? 'px-4 py-3'
                : 'flex justify-between px-4 py-3'
            "
          >
            <div v-if="dataDetailReceipt.status == 0">
              <a-button
                @click="confirmAction('Reject')"
                type="danger"
                class="btn-create mr-3"
                >Reject</a-button
              >
            </div>
            <div>
              <a-button
                type="primary-2"
                :class="
                  dataDetailReceipt.status != 0
                    ? 'w-maxmax btn-create'
                    : 'mr-3 btn-create'
                "
                @click="dismiss(true)"
              >
                Close
              </a-button>
              <a-button
                v-if="dataDetailReceipt.status == 0"
                type="primary"
                class="btn-create mr-3"
                @click="confirmAction('Resubmit')"
              >
                Resubmit
              </a-button>
              <a-button
                v-if="dataDetailReceipt.status == 0"
                type="success"
                class="btn-create"
                @click="Approve"
              >
                Approve
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    class="modal-set-expire"
    :visible="modalReject"
    @cancel="handleCancel"
    okText="Confirm"
    :width="400"
    title="Remark"
    :centered="true"
    :closable="false"
    :ok-button-props="{ disabled: !remark }"
    @ok="confirmRejectorResubmit()"
  >
    <div class="font-modal-delete">
      <div><label class="fraud">*</label> Reason</div>
      <a-textarea
        v-model:value="remark"
        :auto-size="{ minRows: 3, maxRows: 3 }"
      />
    </div>
  </a-modal>
</template>

<script>
// helper
import Util from '@/helper/Utility'
// import moment from 'moment'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// import _ from 'lodash'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataDetailReceipt: {
      type: Array,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['clicksearchprivacy'],
  data: function () {
    return {
      demoimg: require('@/assets/imgs/imgdemo-survey.png'),
      modalReject: false,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      remark: '',
      isErrorName: false,
      status: [
        {
          id: 1,
          status: 0,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          status: 1,
          name: 'Approve',
          color: 'green',
        },
        {
          id: 3,
          status: -1,
          name: 'Reject',
          color: 'red',
        },
      ],
      dataRemark: [],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.getRemark()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {},
  created: function () {},
  methods: {
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Created'
        case 'Approve':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    getRemark() {
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetReceiptsApproveDetail(
          appid,
          this.dataDetailReceipt.partition_key,
          this.dataDetailReceipt.row_key,
        ).subscribe(
          data => {
            this.dataRemark = data.result
            this.$store.commit('setLoading', false)
            resolve(this.dataRemark)
          },
          err => {
            this.dataRemark = null
            console.log(this.dataRemark)
            reject(err)
          },
        )
      })
    },
    onChange() {
      this.setdisable = false
      if (this.levelname) {
        this.isErrorName = false
      }
    },
    confirmAction(action) {
      this.action = action
      this.modalReject = true
    },
    confirmRejectorResubmit() {
      if (this.action == 'Reject') {
        this.PostReject()
      } else {
        this.PostResubmit()
      }
    },
    Approve() {
      this.$store.commit('setLoading', true)
      this.PostApprove().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchadjustwallet')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostApprove() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.dataDetailReceipt.user_id
          ? this.dataDetailReceipt.user_id
          : '',
        event_name: this.dataDetailReceipt._id
          ? this.dataDetailReceipt._id.split('|')[1]
          : '',
        receipt_rowkey: this.dataDetailReceipt.row_key
          ? this.dataDetailReceipt.row_key
          : '',
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApproveReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                this.$emit('callRefresh')
                this.$emit('handleClose')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    Reject() {
      this.$store.commit('setLoading', true)
      this.PostReject().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchadjustwallet')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostReject() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.dataDetailReceipt.user_id
          ? this.dataDetailReceipt.user_id
          : '',
        event_name: this.dataDetailReceipt._id
          ? this.dataDetailReceipt._id.split('|')[1]
          : '',
        receipt_rowkey: this.dataDetailReceipt.row_key
          ? this.dataDetailReceipt.row_key
          : '',
        remark: this.remark,
      }
      this.modalReject = false
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                this.$emit('callRefresh')
                this.$emit('handleClose')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostResubmit() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.dataDetailReceipt.user_id
          ? this.dataDetailReceipt.user_id
          : '',
        event_name: this.dataDetailReceipt._id
          ? this.dataDetailReceipt._id.split('|')[1]
          : '',
        receipt_rowkey: this.dataDetailReceipt.row_key
          ? this.dataDetailReceipt.row_key
          : '',
        remark: this.remark,
      }
      this.modalReject = false
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostResubmitReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                this.$emit('callRefresh')
                this.$emit('handleClose')
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function () {
      this.levelname = ''
      this.description = ''
      this.earnpoint = ''
      this.benefits = ''
      this.remark = ''
      this.$emit('clicksearchprivacy')
      this.$emit('handleClose')
    },
    handleCancel() {
      this.modalReject = false
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
  .input-error {
    border-color: #ff5252 !important;
  }
  .text-error {
    color: #ff5252;
  }
  .pd-t-21 {
    padding-top: 21px;
  }
}
.w100 {
  width: 100% !important;
}
.pointer {
  cursor: pointer;
}
.w-log-receipt {
  width: 62rem;
  .bg-con {
    border-radius: 6px;
    background-color: #f5f5f5;
  }
  .ant-image {
    position: relative !important;
    display: inline-block !important;
    height: 100% !important;
  }
  .ant-image-img {
    width: 100% !important;
    height: 100% !important;
  }
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}
.cursor-disabled {
  cursor: not-allowed;
}
</style>
