<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-subject bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">View/Edit Subject</div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4 border-b">
              <div>Subject :</div>
              <a-select
                v-model:value="subject"
                class="w-100"
                placeholder="คำถามเกี่ยวกับแอปพลิเคชั่น/เว็บไซต์/ไลน์"
              />
            </div>
            <div class="p-4">
              <div class="d-flex align-items-center justify-content-between">
                <div>Topic:</div>
                <a-button
                  type="primary"
                  class="btn-create"
                  @click="AddTableTopicTypes()"
                  >Add Topic Type</a-button
                >
              </div>
              <div
                v-for="(item, index) of topic_types"
                :key="`tabletopictypeindex-${index}`"
              >
                <div class="mt-3 content-topic">
                  <div class="border-b">
                    <div class="p-3">
                      <div>Topic Type {{ index + 1 }} :</div>
                      <a-input v-model:value="topictype" />
                    </div>
                  </div>
                  <div
                    v-for="(item, index2) of topic_detail"
                    :key="`tabletopicdetailindex-${index2}`"
                  >
                    <div class="px-3 pt-2 ml-4">
                      <div>Topic Detail {{ index2 + 1 }} :</div>
                      <a-input v-model:value="topictype" />
                      <div class="mt-2">Basic Solution :</div>
                      <a-textarea
                        v-model:value="remark"
                        :auto-size="{ minRows: 3, maxRows: 3 }"
                      />
                    </div>
                  </div>
                  <div class="p-3">
                    <a class="text-link ml-4" @click="AddTableTopicDetail()"
                      >Add Topic Detail</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-none px-4 py-3 right">
            <a-button class="btn-create" type="default" @click="dismiss(true)">
              Cancel
            </a-button>
            <a-button
              class="ml-3 btn-create"
              type="primary"
              @click="dismiss(true)"
            >
              Create
            </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// helper
// import Util from '@/helper/Utility'
// import moment from 'moment'
// import _ from 'lodash'

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    historylogs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  data: function () {
    return {
      fileList: [],
      formState: {},
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      topic_types: [{}],
      topic_detail: [{}],
    }
  },
  watch: {},
  mounted() {},
  created: function () {},
  methods: {
    AddTableTopicTypes() {
      var itemtable = {}
      this.topic_types.push(itemtable)
    },
    AddTableTopicDetail() {
      var itemtable = {}
      this.topic_detail.push(itemtable)
    },
    dismiss: function () {
      this.topic_detail = [{}]
      this.topic_types = [{}]
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
}
.pointer {
  cursor: pointer;
}
.w-log-subject {
  width: 35rem !important;
  .content-topic {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}
</style>
