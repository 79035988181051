<template>
  <div class="w-full min-h-full">
    <a-card
      v-if="JSON.stringify(userdetail) == JSON.stringify({}) || !userdetail"
    >
      <div
        v-if="JSON.stringify(userdetail) == JSON.stringify({})"
        class="mainNodata text-webkit-center"
      >
        <img :src="imgEmpty" />
        <div class="nodata">No Data</div>
      </div>
      <div v-if="!userdetail" class="mainNodata text-webkit-center">
        <img :src="imgEmpty" />
        <div class="nodata">No Data</div>
      </div>
    </a-card>
    <div v-if="userdetail" class="mainContainer d-flex">
      <div v-if="Object.keys(userdetail).length > 2" class="tableData">
        <div
          v-for="(title, index) of Object.keys(userdetail)"
          :key="`index-${index}`"
          class="content"
        >
          <div class="tablelistname" :class="{ active: currenttab == title }">
            <a @click="changetab(title)">
              {{ title }}
            </a>
          </div>
        </div>
      </div>
      <div
        :class="
          Object.keys(userdetail).length > 2 ? 'information' : 'information100'
        "
      >
        <div>
          <!-- <div v-if="Object.keys(userdetail).length != 0"> -->
          <div class="content">
            <div
              v-for="(title, index) of Object.keys(userdetail)"
              :key="`index-${index}`"
            >
              <a-card
                v-if="!Array.isArray(userdetail[title])"
                :id="title"
                class="objType"
              >
                <div class="informationHeader d-flex justify-content-between">
                  <div class="d-flex">
                    <h4>{{ title }}</h4>
                    <a
                      v-if="
                        JSON.stringify(userdetail[title]) !=
                          JSON.stringify({}) &&
                        datapermissionbyapp.includes(
                          'User-List-Additional-Profile-Edit',
                        )
                      "
                      class="text-link pl-2"
                      @click="editContent(index)"
                      ><edit-outlined class="edit"
                    /></a>
                  </div>
                  <div v-if="edit[index]">
                    <a-button @click="cancelEdit(index)">Cancel</a-button>
                    <a-button
                      class="ml-3"
                      type="primary"
                      @click="UpdateSubAccount()"
                      >Update</a-button
                    >
                  </div>
                </div>
                <div
                  v-if="
                    JSON.stringify(userdetail[title]) == JSON.stringify({}) ||
                    JSON.stringify(userdetail[title]) == JSON.stringify([])
                  "
                  class="objType center"
                >
                  <img :src="imgEmpty" />
                  <div class="nodata">No Data</div>
                </div>
                <div
                  v-else
                  :class="
                    'inpcontainer ' + (keyname.includes('Json') ? '' : '')
                  "
                  v-for="keyname in Object.keys(userdetail[title])"
                  :key="keyname"
                >
                  <div class="row mt-2">
                    <div class="col-3">
                      <div>{{ keyname }} :</div>
                    </div>
                    <div class="col-9">
                      <div
                        v-if="
                          !edit[index] &&
                          !isValidDate(userdetail[title][keyname], keyname)
                        "
                        class="textcontent"
                      >
                        {{ userdetail[title][keyname] }}
                      </div>
                      <div
                        v-if="
                          !edit[index] &&
                          isValidDate(userdetail[title][keyname], keyname)
                        "
                        class="textcontent"
                      >
                        {{
                          formatD(
                            userdetail[title][keyname],
                            'DD MMM YYYY - HH:mm',
                          )
                        }}
                      </div>
                      <a-input
                        v-model:value="userdetail[title][keyname]"
                        v-if="
                          edit[index] &&
                          !isValidDate(userdetail[title][keyname], keyname)
                        "
                        :disabled="
                          keyname == 'PartitionKey' ||
                          keyname == 'RowKey' ||
                          keyname == 'Timestamp' ||
                          keyname.includes('Timestamp')
                        "
                        @change="disablebutton()"
                      ></a-input>
                      <div
                        v-if="
                          isValidDate(userdetail[title][keyname], keyname) &&
                          edit[index]
                        "
                      >
                        <a-date-picker
                          format="YYYY-MM-DD - HH:mm"
                          :show-time="{ format: 'HH:mm' }"
                          v-model:value="userdetail[title][keyname]"
                          :allowClear="false"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a-card>
              <a-card
                v-if="Array.isArray(userdetail[title])"
                :id="title"
                class="arrayType"
              >
                <div class="informationHeader d-flex justify-content-between">
                  <div class="d-flex">
                    <h4>{{ title }}</h4>
                    <a
                      v-if="
                        JSON.stringify(userdetail[title]) !=
                          JSON.stringify([]) &&
                        datapermissionbyapp.includes(
                          'User-List-Additional-Profile-Edit',
                        )
                      "
                      class="pl-2"
                      @click="editContentArray(title)"
                      ><edit-outlined class="edit"
                    /></a>
                  </div>
                  <div v-if="editArray[title]">
                    <a-button @click="cancelEditArray(title)">Cancel</a-button>
                    <a-button
                      class="ml-3"
                      type="primary"
                      @click="UpdateSubAccount()"
                      >Update</a-button
                    >
                  </div>
                </div>
                <div
                  v-if="
                    JSON.stringify(userdetail[title]) == JSON.stringify({}) ||
                    JSON.stringify(userdetail[title]) == JSON.stringify([])
                  "
                  class="objType center"
                >
                  <img :src="imgEmpty" />
                  <div class="nodata">No Data</div>
                </div>
                <a-collapse
                  v-else
                  class="arrayTypeItem"
                  v-model:activeKey="activeKey"
                  expandIconPosition="right"
                  v-for="(item, index) in userdetail[title]"
                  :key="item"
                >
                  <a-collapse-panel :key="index" :header="title + (index + 1)">
                    <div
                      v-for="keyname in Object.keys(item)"
                      :key="keyname"
                      :class="
                        'inpcontainer pr-3 ' +
                        (keyname.includes('Json') ? '' : '')
                      "
                    >
                      <div class="row mt-2">
                        <div class="col-3">
                          <div class="pl-4">{{ keyname }} :</div>
                        </div>
                        <div class="col-9">
                          <div
                            v-if="
                              !editArray[title] &&
                              !isValidDate(item[keyname], keyname)
                            "
                            class="textcontent"
                          >
                            {{ item[keyname] }}
                          </div>
                          <div
                            v-if="
                              !editArray[title] &&
                              isValidDate(item[keyname], keyname)
                            "
                            class="textcontent"
                          >
                            {{ formatD(item[keyname], 'DD-MMM-YYYY - HH:mm') }}
                          </div>
                          <a-input
                            v-if="
                              editArray[title] &&
                              !isValidDate(item[keyname], keyname)
                            "
                            v-model:value="item[keyname]"
                            :disabled="
                              keyname == 'PartitionKey' ||
                              keyname == 'RowKey' ||
                              keyname == 'Timestamp' ||
                              keyname.includes('Timestamp')
                            "
                            @change="disablebutton()"
                          ></a-input>
                          <div
                            v-if="
                              isValidDate(item[keyname], keyname) &&
                              editArray[title]
                            "
                          >
                            <a-date-picker
                              format="YYYY-MM-DD - HH:mm"
                              :show-time="{ format: 'HH:mm' }"
                              v-model:value="item[keyname]"
                              :allowClear="false"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-collapse-panel>
                </a-collapse>
              </a-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VInputText from '@/components/Input/label/InputText.vue'
// import VInputTextInlineLabel from '@/components/Input/label/InputTextInlineLabel.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import _ from 'lodash'
// import moment from 'moment'
import { FormHelper } from '@/helper/FormHelper'
var Cache = new CacheHelper(ConfigManager.getCacheType())
import { config } from '@/config/bzbsconfig.js'
import { EditOutlined } from '@ant-design/icons-vue'
import * as dayjs from 'dayjs'

export default {
  components: {
    // VInputTextInlineLabel,
    // VInputText,
    EditOutlined,
  },
  data() {
    return {
      test: 'aaaa',
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      errInfo: FormHelper.genErrorInfo(10),
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      currentType: '',
      activeKey: [],
      userdetail: [],
      userdetailTemp: [],
      currenttab: '',
      formatTimestamp: 'DD MMM YYYY HH:MM',
      disable: true,
      edit: [],
      editArray: [],
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'subAccount' && !to.hash) {
        this.$store.commit('setLoading', true)
        this.getadditionalprofile().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'subAccount') {
      this.$store.commit('setLoading', true)
      this.getadditionalprofile().then(() => {
        this.$store.commit('setLoading', false)
      })
    }
  },
  created() {},
  methods: {
    formatD(date) {
      return dayjs(date.$d).format('YYYY-MM-DD - HH:mm')
    },
    changetab(title) {
      this.currenttab = title
      location.href = '#' + title
      // this.currentType = Array.isArray(this.userdetail[title])
      //   ? 'array'
      //   : 'object'
    },
    isValidDate(date, keyname) {
      return date.$d != undefined && !keyname.includes('Timestamp')
    },
    editContent(index) {
      this.edit[index] = !this.edit[index]
    },
    editContentArray(index) {
      this.editArray[index] = !this.editArray[index]
    },
    cancelEdit(index) {
      this.edit[index] = false
    },
    cancelEditArray(index) {
      this.editArray[index] = false
    },
    getadditionalprofile() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetAdditionalProfile(this.$route.params.id).subscribe(
          res => {
            this.userdetail = _.cloneDeep(res.result)
            this.userdetailTemp = _.cloneDeep(res.result)
            if (this.userdetail) {
              this.currenttab = Object.keys(this.userdetail)[0]
              this.currentType = Array.isArray(this.userdetail[this.currenttab])
                ? 'array'
                : 'object'
              _.forEach(Object.keys(this.userdetail[this.currenttab]), item => {
                var value = this.userdetail[this.currenttab][item]
                var newDate = new Date(value)
                if (
                  (newDate instanceof Date &&
                    !isNaN(newDate) &&
                    isNaN(value) &&
                    !item.includes('Timestamp') &&
                    item.includes('date')) ||
                  item.includes('Date')
                ) {
                  var date = dayjs(value)
                    .add(-7, 'hours')
                    .format('YYYY-MM-DDTHH:mm:ss')
                  this.userdetail[this.currenttab][item] = dayjs(
                    date,
                    'YYYY-MM-DDTHH:mm:ss',
                  )
                }
              })
              if (Object.keys(this.userdetail)[1]) {
                var keyarray = Object.keys(this.userdetail)[1]
                _.forEach(this.userdetail[keyarray], item => {
                  _.forEach(Object.keys(item), itemarray => {
                    var value = item[itemarray]
                    var newDate = new Date(value)
                    if (
                      (newDate instanceof Date &&
                        !isNaN(newDate) &&
                        isNaN(value) &&
                        !itemarray.includes('Timestamp') &&
                        itemarray.includes('date')) ||
                      itemarray.includes('Date')
                    ) {
                      var date = dayjs(value)
                        .add(-7, 'hours')
                        .format('YYYY-MM-DDTHH:mm:ss')
                      item[itemarray] = dayjs(date, 'YYYY-MM-DDTHH:mm:ss')
                    }
                  })
                })
              }
              if (Object.keys(this.userdetail)[2]) {
                var keyobject = Object.keys(this.userdetail)[2]
                _.forEach(Object.keys(this.userdetail[keyobject]), item => {
                  var value = this.userdetail[keyobject][item]
                  var newDate = new Date(value)
                  if (
                    (newDate instanceof Date &&
                      !isNaN(newDate) &&
                      isNaN(value) &&
                      !item.includes('Timestamp') &&
                      item.includes('date')) ||
                    item.includes('Date')
                  ) {
                    var date = dayjs(value)
                      .add(-7, 'hours')
                      .format('YYYY-MM-DDTHH:mm:ss')
                    this.userdetail[keyobject][item] = dayjs(
                      date,
                      'YYYY-MM-DDTHH:mm:ss',
                    )
                  }
                })
              }
            }

            // _.forEach(res.result, item => {
            //   item.Timestamp = moment(item.Timestamp).format(
            //     this.formatTimestamp,
            //   )
            // })
            resolve(this.userdetail)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    UpdateSubAccount() {
      this.$store.commit('setLoading', true)
      var keyarray = Object.keys(this.userdetail)[0]
      _.forEach(Object.keys(this.userdetail[keyarray]), item => {
        var valuechange = this.userdetail[keyarray][item]
        var valueori = this.userdetailTemp[keyarray][item]
        if (valuechange.$d != undefined && !item.includes('Timestamp')) {
          if (
            dayjs(valuechange).format('YYYY-MM-DDTHH:mm:ss') !=
            dayjs(valueori).add(-7, 'hours').format('YYYY-MM-DDTHH:mm:ss')
          ) {
            if (item.includes('date') || item.includes('Date')) {
              this.userdetail[keyarray][item] = dayjs(
                this.userdetail[keyarray][item],
              ).format('YYYY-MM-DDTHH:mm:ss')
            }
          }
        }
      })
      if (Object.keys(this.userdetail)[1]) {
        var keyarray1 = Object.keys(this.userdetail)[1]
        _.forEach(this.userdetail[keyarray1], (item, index) => {
          _.forEach(Object.keys(item), itemarray => {
            var valuechange = item[itemarray]
            var valueori = this.userdetailTemp[keyarray1][index][itemarray]
            if (
              valuechange.$d != undefined &&
              !itemarray.includes('Timestamp')
            ) {
              if (
                dayjs(valuechange).format('YYYY-MM-DDTHH:mm:ss') !=
                dayjs(valueori).add(-7, 'hours').format('YYYY-MM-DDTHH:mm:ss')
              ) {
                if (itemarray.includes('date') || itemarray.includes('Date')) {
                  item[itemarray] = dayjs(item[itemarray]).format(
                    'YYYY-MM-DDTHH:mm:ss',
                  )
                }
              }
            }
          })
        })
      }
      if (Object.keys(this.userdetail)[2]) {
        var keyarray2 = Object.keys(this.userdetail)[2]
        _.forEach(Object.keys(this.userdetail[keyarray2]), item => {
          var valuechange = this.userdetail[keyarray2][item]
          var valueori = this.userdetailTemp[keyarray2][item]
          if (valuechange.$d != undefined && !item.includes('Timestamp')) {
            if (
              dayjs(valuechange).format('YYYY-MM-DDTHH:mm:ss') !=
              dayjs(valueori).add(-7, 'hours').format('YYYY-MM-DDTHH:mm:ss')
            ) {
              if (item.includes('date') || item.includes('Date')) {
                this.userdetail[keyarray2][item] = dayjs(
                  this.userdetail[keyarray2][item],
                ).format('YYYY-MM-DDTHH:mm:ss')
              }
            }
          }
        })
      }
      var subaccountupdate = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        data: this.userdetail,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutAdditionalProfile(subaccountupdate)
          .then(res => {
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$store.commit('setLoading', true)
                this.getadditionalprofile().then(() => {
                  this.cancelEdit()
                  this.$store.commit('setLoading', false)
                })
              },
            })
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    update(event, currenttab, keyname) {
      this.userdetail[currenttab][keyname] = event
    },
    update2(event, item, keyname) {
      item[keyname] = event
    },
    disablebutton() {
      this.disable = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/_mixin.scss';

.pd30 {
  padding: 15px;
}

.btn-search {
  width: 221px;
  height: 35px;
}

.headerText {
  font-size: 16px;
  font-weight: 600;
  color: #666666;
}

.mainNodata {
  min-height: calc(100vh - 200px);
  margin-top: 10%;
}

.mainContainer {
  a {
    color: unset !important;
  }

  height: fit-content;
  @include flex();
  gap: 18px;

  .textcontent {
    color: #b0b0b0;
  }

  .tableData {
    width: 22%;
    height: 100%;
    border-right: 1px solid #dcdddf;
  }

  .information {
    width: 78%;
    overflow: auto;
    display: block;
    height: calc(100vh - 260px);

    .edit {
      color: #f9a601 !important;
    }
  }

  .information100 {
    width: 100%;
    overflow: auto;
    display: block;
    height: calc(100vh - 260px);

    .edit {
      color: #f9a601 !important;
    }
  }

  .content {
    height: fit-content;

    // overflow-y: scroll;
    .objType {
      padding-right: 30px;
    }

    .arrayType {
      // padding: 0 30px;
      .arrayTypeItem {
        margin-top: 12px;
      }
    }

    .nodata {
      color: #c4c4c4;
    }

    .imgnodata {
      width: 64px;
      margin: 0px auto;
    }

    .tablelistname {
      height: 39px;
      padding: 10px;
      @include flex(flex-start, center);
      font-size: 14px;
      cursor: pointer;

      &.active {
        color: #f9a601 !important;
        border-right: 2px solid #f9a601 !important;
      }
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
</style>
<style lang="scss">
.information {
  .ant-picker {
    width: -webkit-fill-available;
  }
}
</style>
