import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

import ConfigManager from "@/config/ConfigManager.js";
import { TrackingService } from "@/helper/TrackingHelper.js";

export default {
    install: (app) => {
        let config = ConfigManager.getFirebaseSetup();
        let firebaseAnalytics = null;
        if (config.apiKey) {
            firebase.initializeApp(config);
            firebase.analytics();
            firebaseAnalytics = firebase.analytics();
        }


        app.mixin({
            data: function () {
                return {
                    firebaseTrack: firebaseAnalytics,
                    tracking: null,
                }
            },
            created: function () {
                this.tracking = new TrackingService(this.firebaseTrack);
            },
        });
    },
};
