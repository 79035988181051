<template>
  <a-modal v-model:visible="showModal" :centered="true" :closable="false" @cancel="handleClose">
    <template #title>
      <div>
        <h1 class="text-center">Your File is Preparing</h1>
      </div>
    </template>
    <div class="text-center m-4">
      Please wait while we prepare your files for download.
    </div>
    <div class="text-center mb-4">
      You can check the progress on the download page from the link below or
      access it through the sidebar menu.
    </div>
    <template #footer>
      <div class="modal-footer">
        <a-button block type="primary" @click="handleToDownload">
          Go To Download Center
        </a-button>
      </div>
    </template>
  </a-modal>
</template>
<script>
export default {
  name: 'Prepare',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    visible(value, oldvalue) {
      if (value != oldvalue) {
        this.showModal = value
        console.log('visible', this.visible)
      }
    },
  },
  data: function () {
    return {
      showModal: false,
    }
  },
  methods: {
    handleClose() {
      this.showModal = false
      this.$emit('handleClose')
    },
    handleToDownload() {
      this.handleClose()
      this.$router.push({
        name: 'download',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-100 {
  width: 100%;
}
</style>
