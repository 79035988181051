<template>
    <div class="fixed top-0 right-0 h-screen z-40" :style="containerStyle">
        <transition
            enter-active-class="transition ease-out duration-500"
            enter-from-class="transform-gpu translate-x-full"
            enter-to-class="transform-gpu  translate-x-0"
            leave-active-class="transition ease-in duration-300"
            leave-from-class="transform-gpu translate-x-0"
            leave-to-class="transform-gpu translate-x-full"
        >
            <div
                class="h-full shadow-xl w-80 bg-white border-l"
                :style="mainBlockStyle"
                v-if="value"
                v-click-away="onClickAway"
            >
                <div class="flex divide-y-2 flex-col h-full">
                    <div class="panel-header flex-none">
                        <div class="flex-grow font-semibold">Load Saved List</div>
                        <div class="flex-none btn-dismiss" @click="dismiss(true)">
                            <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
                        </div>
                    </div>
                    <div class="border-b flex-auto overflow-y-auto">
                        <div class="p-4">
                            <div class="v-list has-divider">
                                <template v-for="(item, idx) of items" :key="`v-list-item-${idx}`">
                                    <div class="v-list-item">
                                        <div class="flex items-center w-full">
                                            <div class="flex-auto">
                                                <div class="text-sm text-gray-800">{{ item.title }}</div>
                                                <div
                                                    class="text-xs text-gray-500"
                                                >Last saved: {{ item.last_saved }}</div>
                                            </div>
                                            <div class="flex-none">
                                                <v-btn
                                                    class="text-blue-700 border border-blue-700 hover:ring hover:ring-gray-300"
                                                    :custom="true"
                                                    sm
                                                    @click="addNewUser($event)"
                                                >Load</v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "TableSavedList",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        top: {
            type: Number,
            default: 0
        },
        items: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        mainBlockStyle: function () {
            return {
                paddingBottom: `${this.top}px`
            }
        },
        containerStyle: function () {
            return {
                top: `${this.top}px`,
            }
        }
    },
    watch: {
        value: function (val) {
            // console.log('save list', 'value', val)
            if (val) {
                this.disableScroll();
            } else {
                this.enableScroll()
            }
        }
    },
    emits: ['update:value'],
    methods: {
        onClickAway: function () {
            this.dismiss();
            this.$nextTick(() => {
                this.$emit("onClickAway")
            })
        },
        dismiss: function () {
            this.$emit("update:value", false)
        },
        disableScroll: function () {
            setTimeout(() => {
                document.body.classList.add('overflow-hidden')
            }, 100);
        },
        enableScroll: function () {
            document.body.classList.remove('overflow-hidden')
        },
    }
}
</script>
