<template>
  <AppLayout :breadcrumb="bcItems">
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs class="mt-4" title="Download Center" />
    </template>
    <template v-slot:toolbar>
      <Toolbar
        @clickexport="modalController.exportModal = true"
        @clearDownloaded="onclickClearlist()"
        @callsearchData="searchData($event)"
      >
      </Toolbar>
    </template>
    <div
      class="download-management page max-w-full overflow-x-hidden w-full flex flex-col"
    >
      <!-- <TopBar class="flex-none" :breadcrumb="bcItems">
      <template v-slot:toolbar>
        <Toolbar
          @clickexport="modalController.exportModal = true"
          @clearDownloaded="onclickClearlist()"
          @callsearchData="searchData($event)"
        >
        </Toolbar>
      </template>
    </TopBar> -->
      <div class="flex-1 w-full p-5 wrap-table">
        <VLoadingTable :value="overlaytable" />
        <div v-if="items.length == 0" class="pt-5 center">
          <img :src="imgEmpty" />
          <div class="nodata text-center">No Data</div>
        </div>
        <div v-if="items">
          <div class="wrapper-list" v-for="(item, index) in items" :key="index">
            <a-row type="flex">
              <a-col :span="7" class="pd-col">
                <div class="text-h-column">File</div>
                <div v-if="item.description.length > 28" class="title-load">
                  <a-tooltip placement="bottomLeft">
                    <template #title>
                      <span>{{ item.title }}</span>
                    </template>
                    {{ item.title }}
                  </a-tooltip>
                </div>
                <div v-else>
                  {{ item.title }}
                </div>
              </a-col>
              <a-col :span="4" class="pd-col" align="left">
                <div class="text-h-column">Created on</div>
                <div>{{ datefomat(item.created_date) }}</div>
              </a-col>
              <a-col :span="3" class="pd-col" align="left">
                <div class="text-h-column">Items</div>
                <div>{{ item.details.total_row }}</div>
              </a-col>
              <a-col :span="10">
                <span class="float-left" v-show="item.status == 'Success'">
                  <div style="color: #22ba20">Ready</div>
                </span>
                <div class="float-right">
                  <a-button
                    v-show="
                      item.details.total_row != 0 &&
                      item.edm_url &&
                      item.status == 'Success'
                    "
                    type="primary"
                    :size="size"
                    @click="onClickDownload(item.edm_url)"
                  >
                    Download EDM List
                  </a-button>
                  <a-button
                    v-show="item.status == 'Success'"
                    type="primary"
                    class="ml-3"
                    :size="size"
                    @click="onClickDownload(item.blob_url)"
                  >
                    Download
                  </a-button>
                </div>
                <span v-show="item.status != 'Success'">
                  {{ item.complete_percentage }} % Complete
                </span>
                <a-progress
                  v-show="item.status != 'Success'"
                  :percent="item.complete_percentage"
                  :show-info="false"
                  strokeColor="#22ba20"
                />
              </a-col>
            </a-row>
            <a-row class="mt-5">
              <a-col :span="24" align="right"
                ><span class="btn-remove" @click="onclickRemove(item.id)"
                  >Remove</span
                ></a-col
              >
            </a-row>
          </div>
        </div>
      </div>
      <!-- <div class="flex-none">
      <Footer />
    </div> -->
      <div class="flyout-zone">
        <VTableSavedList
          v-model="toolbarStates.showPanelSavedList"
          :top="offsetTop"
          :items="itemsSavedList"
        ></VTableSavedList>
        <VLoading :value="overlay" />
      </div>
    </div>
  </AppLayout>
</template>
<script>
import moment from 'moment'
import { computed as vueComputed, createVNode } from 'vue'
import AppLayout from '@/components/Layout/default.vue'
// import Footer from '@/components/Layout/Footer/Footer.vue'

import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import VTableSavedList from '../component/TableSavedList.vue'
import Toolbar from '../component/Toolbar.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
// helper
import { FormHelper } from '@/helper/FormHelper'
// composable
import useToolbarComposable from '@/composables/user/ToolbarComposable'
// import { DownloadManagementmodal } from "@/core/UserManagement/model/DownloadManagement";
import ConfigManager from '@/config/ConfigManager.js'
import { config } from '@/config/bzbsconfig.js'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  name: 'UserList',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    // Footer,
    // eslint-disable-next-line vue/no-unused-components
    VTableSavedList,
    Toolbar,
  },
  computed: {
    bcItems() {
      return [
        {
          text: 'Download Center',
        },
      ]
    },
    fraudSlotName() {
      return 'item.fraud'
    },
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'Download Center',
        },
      ],
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
      modalController: {
        exportModal: false,
        prepareModalModal: false,
      },
      headers: [
        {
          text: 'Partition Key',
          key: 'partitionKey',
        },
        {
          text: 'Timestamp',
          key: 'ts',
          sort: true,
          width: 136,
        },
        {
          text: 'Birthday',
          key: 'bd',
          sort: true,
          width: 130,
        },
        {
          text: 'Contact Number',
          key: 'contactNo',
          sort: true,
          width: 170,
        },
        {
          text: 'Email',
          key: 'email',
          sort: true,
        },
        {
          text: 'First Name',
          key: 'firstName',
          sort: true,
          width: 150,
        },
        {
          text: 'Last Name',
          key: 'lastName',
          sort: true,
          width: 150,
        },
        {
          text: 'Gender',
          key: 'gender',
          sort: true,
          width: 120,
        },
        {
          text: 'Join Date',
          key: 'joinDt',
          sort: true,
          width: 130,
        },
        {
          text: 'Province',
          key: 'province',
          sort: true,
          width: 130,
        },
        {
          text: 'Fraud Status',
          key: 'fraud',
          sort: true,
          width: 150,
        },
        {
          text: 'User Level',
          key: 'userLevel',
          sort: true,
          width: 130,
        },
        {
          text: 'Total Earn',
          key: 'totalEarn',
          sort: true,
          width: 130,
          headerAlign: 'right',
          align: 'right',
        },
        {
          text: 'Total Burn',
          key: 'totalBurn',
          sort: true,
          width: 130,
          headerAlign: 'right',
          align: 'right',
        },
        {
          text: 'Point on hand',
          key: 'pointOnHand',
          sort: true,
          width: 170,
          headerAlign: 'right',
          align: 'right',
        },
        {
          text: 'Last Active',
          key: 'lastActive',
          sort: true,
          width: 140,
        },
        {
          text: 'Last Redeem',
          key: 'lastRedeem',
          sort: true,
          width: 150,
        },
        {
          text: 'Last Scan',
          key: 'lastScan',
          sort: true,
          width: 130,
        },
        {
          text: 'Registration <br /> Type',
          key: 'regisType',
          sort: true,
          width: 180,
          headerAlign: 'center',
          align: 'center',
        },
        {
          text: 'No. of friends',
          key: 'totalFriend',
          sort: true,
          width: 170,
          headerAlign: 'right',
          align: 'right',
        },
      ],
      inter: null,
      items: [],
      overlay: false,
      overlaytable: false,
      noMoreData: false,
      page: 1,
      maxPage: 4,
      itemsPerPage: 25,
      dialogCreateNewList: false,
      model: {
        listName: '',
        description: '',
      },
      errInfo: FormHelper.genErrorInfo(2),
      uiDisplayFilter: false,
      uiDisplaySavedList: false,
      offsetTop: 0,
      itemsSavedList: [],
      tableMaxHeight: '0px',
      clearListDownloaded: false,
      params_search: {
        start_created_date: null,
        end_created_date: null,
        // clearlist: "",
        search_filename: null,
        // staffid: ""
      },
    }
  },
  provide() {
    return {
      tableHeaders: vueComputed(() => this.headers),
    }
  },
  setup: function () {
    const { states } = useToolbarComposable()

    return {
      toolbarStates: states,
    }
  },
  mounted: function () {
    this.inter = setInterval(() => {
      this.callsearchData(this.params_search)
    }, 5000)
  },
  created() {
    this.callsearchData(this.params_search).then(() => {
      this.$store.commit('setLoading', false)
    })
  },
  methods: {
    searchData(params) {
      this.$store.commit('setLoading', true)
      this.params_search.search_filename = params.filename
      this.params_search.start_created_date = params.startdate
      this.params_search.end_created_date = params.enddate
      this.$emit('handleClose')
      this.callsearchData(this.params_search).then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    datefomat(date) {
      return moment(date).format('DD MMM YY - HH:mm')
    },
    togglemodal: function () {
      this.modalController.exportModal = false
    },
    loadMore() {
      console.log('call load more', this.items.length)
      if (this.page == 4) {
        this.noMoreData = true
        return
      }

      this.overlay = true
      setTimeout(() => {
        this.overlay = false
        this.page++
      }, 500)
    },
    dismissDialogCreateNewList() {
      if (this.dialogCreateNewList) {
        this.dialogCreateNewList = false
      }
    },
    saveNewList: function () {
      this.toolbarStates.showDialogCreateList = false
    },
    applyFilte: function () {
      this.toolbarStates.showPanelFilter = false
    },
    showPrepareModal() {
      this.modalController.prepareModalModal = true
      this.modalController.exportModal = false
    },
    searchchange(e) {
      this.overlaytable = true
      this.params_search.search_filename = e.target.value
      this.callsearchData(this.params_search).then(() => {
        this.overlaytable = false
      })
    },
    onChangeStartdate(e) {
      this.params_search.start_created_date = e
      console.log(this.params_search.start_created_date)
      console.log(this.params_search.end_created_date)
      if (
        this.params_search.start_created_date &&
        this.params_search.end_created_date
      ) {
        this.callsearchData(this.params_search)
      }
    },
    onChangeEnddate(e) {
      this.params_search.end_created_date = e
      console.log(this.params_search.end_created_date)

      if (
        this.params_search.start_created_date &&
        this.params_search.end_created_date
      ) {
        this.callsearchData(this.params_search)
      }
    },
    callsearchData(params) {
      return new Promise((resolve, reject) => {
        params.appid = ConfigManager.getAppId()
        BzbsUser.apiGetAllExportData(
          null,
          null,
          this.params_search.start_created_date,
          this.params_search.end_created_date,
          this.params_search.search_filename,
          this.page,
        ).subscribe(
          res => {
            console.log(res.result)
            this.items = res.result
            if (
              this.items.every(item => {
                Number(item.details.total_row)
                return (
                  (item.details.total_row =
                    item.details.total_row.toLocaleString('en-US')),
                  item.complete_percentage == 100 && item.status == 'Success'
                )
              })
            ) {
              clearInterval(this.inter)
            }
            resolve(this.items)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    onclickClearlist() {
      let that = this
      this.$confirm({
        title: 'Do you want to clear all items?',
        centered: true,
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {
          style: 'color:red;',
        }),
        onOk() {
          that.clearlist()
        },

        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
    clearlist() {
      var allid = []
      this.items.forEach(data => {
        allid.push(data.id)
      })
      var params = {
        appid: ConfigManager.getAppId(),
        ids: allid,
      }
      BzbsUser.apiPutClearList(params).then(data => {
        this.callsearchData(this.params_search)
        console.log(data)
      })
    },
    onclickRemove(id) {
      let that = this
      this.$confirm({
        title: 'Do you want to delete these items?',
        centered: true,
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', {
          style: 'color:red;',
        }),
        onOk() {
          that.callapiremove(id)
        },

        onCancel() {
          console.log('Cancel')
        },
        class: 'test',
      })
    },
    callapiremove(id) {
      //   this.params_search.clearlist = "0";
      //   this.params_search.uid = uid;
      //   this.callsearchData(this.params_search);
      var params = {
        appid: ConfigManager.getAppId(),
        ids: [id],
      }
      BzbsUser.apiPutClearList(params).then(data => {
        this.callsearchData(this.params_search)
        console.log(data)
      })
    },
    onClickDownload(url) {
      window.location = url
      // if (item.status == 1) {
      //     //พร้อมดาวโหลด  user story :244009
      //     var params = {
      //         uid: item.uid,
      //         download: true
      //     };
      //     this.ExportRefresh(params);
      //     //พร้อมดาวโหลด user story :244009
      // }
    },
    ExportRefresh(params) {
      console.log(params)
      var appid = ConfigManager.getAppId()
      BzbsUser.apiPostUserExportRefresh(params, appid)
        .then(res => {
          if (res.data.data[0].Link) window.location = res.data.data[0].Link
        })
        .catch(error => {
          this.errorApi(error)
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
.download-management {
  min-height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
  .wrap-table {
    max-height: calc(100vh - 227px);
  }
  .wrapper-list {
    .progress-tube {
      .ant-progress-outer {
        padding-right: 14px;
        .ant-progress-inner {
          .ant-progress-bg {
            background-color: #22ba20;
          }
        }
      }
      .ant-progress-text {
        display: none;
      }
    }
  }
  .loadtable {
    height: calc(100vh - 110px) !important;
  }
}
</style>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.download-management {
  .wrapper-list {
    height: auto;
    padding: 12px 30px 10px;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(102, 108, 139, 0.1);
    background-color: #fff;
    color: #666c8b;
    max-width: 1000px;
    margin: 0 auto 20px;
    .title-load {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 180px;
    }
    .pd-col {
      padding-right: 23px;
    }
    .btn-remove {
      cursor: pointer;
      color: #fb7290;
    }
    .success {
      color: #22ba20;
    }
    .unsuccess {
      color: #555;
    }
    .failed {
      color: red;
    }
    .percent {
      color: #c4c4c4;
    }
  }
  .btn-remove {
    cursor: pointer;
    color: #fb7290;
  }
  .success {
    color: #22ba20;
  }
  .unsuccess {
    color: #555;
  }
  .failed {
    color: red;
  }
  .percent {
    color: #c4c4c4;
  }
  .text-h-column {
    font-size: 12px;
  }
}
</style>
