<template>
  <AppLayout :disable-menu="false" :menu-callcenter="true">
    <TitleBreadcrumbs class="ml-5" title="Search User" />
    <div class="search-user px-4">
      <div class="mb-4 b-6 bg-white pl-4 pr-4">
        <div class="py-2 d-flex gap-3">
          <!-- <div v-if="ref_id" class="input-search">
            <div><label class="false mr-1 ml-re">*</label> Application :</div>
            <a-select
              class="select-search"
              v-model:value="application"
              allow-clear
              placeholder="Plase Select Application"
              :options="application_option"
            />
          </div> -->
          <div class="input-search">
            <div>Contact Number :</div>
            <a-input
              v-model:value="contact_number"
              placeholder="Enter Contact Number"
              @change="handleInputContactNumber($event)"
              ><template #prefix>
                <img class="left" src="@/assets/icons/icon-mobile.svg" alt />
              </template>
            </a-input>
          </div>
          <div class="input-search">
            <div>Name :</div>
            <a-input v-model:value="name" placeholder="Enter Contact Name"
              ><template #prefix>
                <user-outlined type="user" />
              </template>
            </a-input>
          </div>
          <!-- <div class="input-search">
            <div>Email :</div>
            <a-input v-model:value="email" placeholder="Enter Email"
              ><template #prefix>
                <mail-outlined />
              </template>
            </a-input>
          </div> -->
          <!-- <div class="input-search">
            <div>Payment ID :</div>
            <a-input v-model:value="payment_id" placeholder="Enter Payment ID"
              ><template #prefix>
                <img class="left" src="@/assets/icons/icon-id.svg" alt />
              </template>
            </a-input>
          </div> -->
          <!-- <div class="input-search">
            <div>Reference ID :</div>
            <a-input v-model:value="ref_id" placeholder="Enter Reference ID"
              ><template #prefix>
                <img class="left" src="@/assets/icons/icon-id.svg" alt />
              </template>
            </a-input>
          </div> -->
          <div class="input-search">
            <div>Payment ID :</div>
            <a-input
              v-model:value="payment_id"
              placeholder="Enter Payment ID"
              @input="handleInputPaymentID($event)"
              ><template #prefix>
                <img class="left" src="@/assets/icons/icon-id.svg" alt />
              </template>
            </a-input>
          </div>
          <div class="input-search">
            <div>Help ID :</div>
            <a-input
              v-model:value="help_id"
              placeholder="Enter Help ID"
              @input="handleInputHelpID($event)"
              ><template #prefix>
                <img class="left" src="@/assets/icons/icon-id.svg" alt />
              </template>
            </a-input>
          </div>
          <!-- <div class="input-search">
            <div>User ID :</div>
            <a-input v-model:value="user_id" placeholder="Enter User ID"
              ><template #prefix>
                <img class="left" src="@/assets/icons/icon-id.svg" alt />
              </template>
            </a-input>
          </div> -->

          <a-button
            type="primary"
            :disabled="checkDisableSearch()"
            @click="searchUser()"
            class="btn-search mt-4"
            ><search-outlined /> Search</a-button
          >
          <a-button
            type="default"
            @click="clearsearch()"
            class="btn-search mt-4"
            >Clear</a-button
          >
          <a-tooltip placement="bottom">
            <template #title>
              <div>Advance Search</div>
            </template>
            <a-button
              class="hover btn-icon mt-4"
              @click="toggleAdvanceSearch()"
              :color="'blue-1'"
            >
              <img class="left" src="@/assets/icons/FileSearch.svg" alt />
            </a-button>
          </a-tooltip>
        </div>
      </div>
      <div class="wrap-table" @scroll="handleScroll">
        <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="partitionkey"
          class="fixed-head"
          :dataSource="datauserlist"
          :columns="columns"
          :pagination="false"
        >
          <template #application_name="{ record }">
            <div>{{ record.app_name || '-' }}</div>
          </template>
          <template #contact_number="{ record }">
            <div>
              {{
                record.contact_number && record.contact_number != '-'
                  ? contactNumber(record.contact_number)
                  : '-'
              }}
            </div>
          </template>
          <template #name="{ record }">
            <div
              v-if="record.first_name || record.last_name"
              class="d-flex gap-2"
            >
              <div>{{ record.first_name }}</div>
              <div>{{ record.last_name }}</div>
            </div>
            <div v-else>-</div>
          </template>
          <template #user_id="{ record }">
            <a class="link" @click="selectApp(record)">{{ record.userid }}</a>
          </template>
        </a-table>
      </div>
    </div>
    <ExportModal
      :visible="showExportModal"
      @handleSubmit="handleExportModal($event)"
      @handleClose="showExportModal = false"
    ></ExportModal>
    <PrepareModal
      :visible="showPrepareModal"
      @handleClose="showPrepareModal = false"
    ></PrepareModal>
    <DisplayFieldsAdvanceSearch
      ref="advancesearch"
      :value="showModalAdvanceSearch"
      :application_option="application_option"
      @searchAdvance="searchAdvance($event)"
      @handleClose="showModalAdvanceSearch = false"
    >
    </DisplayFieldsAdvanceSearch>
    <DisplayFieldsTicketDetail
      ref="jobDetail"
      :value="showModalTicketDetail"
      :dataDetailAll="dataDetailAll"
      :dataUserInfo="dataUserInfo"
      :dataDetail="dataDetail"
      :appName="appName"
      @clicksearch="clicksearch($event)"
      @handleClose="showModalTicketDetail = false"
    >
    </DisplayFieldsTicketDetail>
  </AppLayout>
</template>

<script>
// import moment from 'moment'
// import dayjs from 'dayjs'
import _ from 'lodash'
// import { config } from '@/config/bzbsconfig.js'
import Util from '@/helper/Utility.js'
import DisplayFieldsAdvanceSearch from '@/module/CallCenter/component/DisplayFieldsAdvanceSearchUser.vue'
import DisplayFieldsTicketDetail from '@/module/CallCenter/component/DisplayFieldsTicketDetail.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import PrepareModal from '@/module/CallCenter/component/PrepareModal.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import Account from '@/helper/AccountHelper'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import BzbsApp from '@/core/Application/callapi/BzbsApp'
var cache = new CacheHelper('cookie')
var cacheSearch = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserDetail',
  inject: ['SideMenuStore', 'AccountStore', 'TopBarStore'],
  components: {
    AppLayout,
    DisplayFieldsAdvanceSearch,
    DisplayFieldsTicketDetail,
    PrepareModal,
    ExportModal,
    TitleBreadcrumbs,
  },
  data: function () {
    return {
      datauserlist: [],
      appId_list: JSON.parse(localStorage.getItem('applist')),
      page: 1,
      contact_number: '',
      ref_id: '',
      payment_id: '',
      help_id: '',
      user_id: '',
      name: '',
      email: '',
      overlaytable: false,
      nomore: false,
      columns: [
        {
          title: 'Application Name',
          dataIndex: 'application_name',
          key: 'application_name',
          slots: { customRender: 'application_name' },
          width: 100,
          align: 'left',
        },
        {
          title: 'Contact Number',
          dataIndex: 'contact_number',
          key: 'contact_number',
          slots: { customRender: 'contact_number' },
          width: 100,
          align: 'left',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          slots: { customRender: 'name' },
          width: 100,
          align: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          slots: { customRender: 'email' },
          width: 100,
          align: 'left',
        },
        {
          title: 'User ID',
          dataIndex: 'user_id',
          key: 'user_id',
          slots: { customRender: 'user_id' },
          width: 100,
          align: 'left',
        },
      ],
      dataad: [],
      dataapp: {},
      permissionbyapp: [],
      application: null,
      application_option: [],
      showModalAdvanceSearch: false,
    }
  },
  computed: {
    bcItems: function () {
      return [
        {
          text: 'My Applications',
        },
      ]
    },
  },
  mounted() {},
  created() {
    this.$store.commit('setLoading', false)
    this.application_option = this.appId_list.map(item => {
      return {
        label: item.appName,
        value: `${item.appId}|${item.appName}`,
      }
    })
  },
  methods: {
    handleInputContactNumber(event) {
      this.contact_number = event.target.value.replace(/[^0-9]/g, '')
    },
    handleInputPaymentID(event) {
      this.payment_id = event.target.value.replace(/[^0-9]/g, '')
    },
    handleInputHelpID(event) {
      this.help_id = event.target.value.replace(/[^0-9]/g, '')
    },
    toggleAdvanceSearch() {
      this.showModalAdvanceSearch = !this.showModalAdvanceSearch
    },
    checkDisableSearch() {
      if (
        !this.contact_number &&
        !this.name &&
        !this.email &&
        !this.payment_id &&
        !this.ref_id &&
        !this.help_id &&
        !this.user_id
      ) {
        return true
      } else if (this.ref_id && !this.application) {
        return true
      } else {
        return false
      }
    },
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    searchUser() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.PostSearchUser().then(() => {
        this.$refs.advancesearch.clearSearch()
        this.$store.commit('setLoading', false)
      })
    },
    clearsearch() {
      this.contact_number = ''
      this.payment_id = ''
      this.ref_id = ''
      this.help_id = ''
      this.user_id = ''
      this.name = ''
      this.email = ''
    },
    searchAdvance(data) {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.contact_number = data.contact_number
      this.payment_id = data.payment_id
      this.ref_id = data.ref_id
      this.help_id = data.help_id
      this.user_id = data.user_id
      this.name = data.name
      this.email = data.email
      this.application = data.application.split('|')[0]
      this.PostSearchUser().then(() => {
        this.clearsearch()
        this.showModalAdvanceSearch = false
        this.$store.commit('setLoading', false)
      })
    },
    PostSearchUser() {
      var searchserial = {
        page_number: this.page,
        limit: 20,
        contact_number: this.contact_number?.replaceAll('-', ''),
        payment_id: this.payment_id,
        ref_id: this.ref_id,
        help_id: this.help_id,
        user_id: this.user_id,
        name: this.name,
        email: this.email,
        appid: this.application,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostSearchUser(searchserial)
          .then(res => {
            if (this.page == 1) {
              this.datauserlist = []
            }
            _.forEach(res.data.result, item => {
              if (item.app_id) {
                this.datauserlist.push(item)
              }
            })
            if (res.data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.$store.commit('setLoading', true)
          this.overlaytable = true
          this.page++
          this.PostSearchUser().then(() => {
            this.overlaytable = false
            this.$store.commit('setLoading', false)
          })
        }
      }
    },
    selectApp(record) {
      this.callGetApp(record).then(() => {
        cacheSearch.removeCache('setFilter')
        cacheSearch.removeCache('setFilterTotal')
        cacheSearch.removeCache('filterUser')
        cacheSearch.removeCache('handleSearch')
        cacheSearch.removeCache('SearchRealtime')
        cacheSearch.removeCache('totalBadge')
        this.getad(record).then(() => {
          this.$store.commit('setIsSearch', false)
          localStorage.setItem('permission', JSON.stringify(this.dataapp))
          localStorage.setItem(
            'permissionbyapp',
            JSON.stringify(this.permissionbyapp),
          )
          this.$forceUpdate()
          Account.getMSMenu2(record.app_id).then(() => {
            let user = {
              first_name: record.first_name,
              last_name: record.last_name,
            }
            localStorage.setItem('userinfo', JSON.stringify(user))
            this.$router.push({
              name: 'user_detail',
              params: {
                id: record.userid,
              },
            })
          })
        })
      })
    },
    callGetApp(record) {
      return new Promise((resolve, reject) => {
        BzbsApp.apiGetApplication().subscribe(
          res => {
            console.log('app_id', record.app_id)
            var app = res.result.filter(
              x => x.application.appid == record.app_id,
            )
            console.log('appapp', app)
            cache.setCache('app', {
              id: app[0].application.appid,
              name: app[0].application.name,
              logo: app[0].application.logo,
              purchase_type: app[0].application.purchase_type,
              has_coupon: app[0].application.has_coupon,
            })
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getad(record) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            this.dataad = res.result
            this.dataapp = this.dataad.applications.find(
              x => x.application_id == record.app_id,
            )
            if (this.dataapp) {
              this.permissionbyapp = this.dataapp.features.map(x => x.name)
            }
            resolve(this.dataapp)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/style/base/base.scss';
.search-user {
  .wrap-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    height: calc(100vh - 264px);
    .ant-table-wrapper {
      width: 100%;
    }
  }
  .mt-24 {
    margin-top: 24px;
  }
  .input-search {
    img {
      width: 16px !important;
    }
    .ant-input-affix-wrapper .anticon {
      font-size: 14px !important;
    }
    .ant-input-affix-wrapper {
      width: 200px !important;
    }
    @media (max-width: 1854px) {
      .ant-input-affix-wrapper {
        width: 190px !important;
      }
    }
    @media (max-width: 1440px) {
      .ant-input-affix-wrapper {
        width: 150px !important;
      }
    }
    @media (max-width: 1024px) {
      .ant-input-affix-wrapper {
        width: 130px !important;
      }
    }
  }
  .ant-input {
    width: 130px;
  }
  .ant-select {
    width: 200px;
    border-color: #c9c9c9 !important;
  }
  .ant-select-selector {
    border-color: #c9c9c9 !important;
  }
}
</style>
