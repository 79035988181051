import ConfigManager from '@/config/ConfigManager.js'
import axios from 'axios'
import { Observable } from 'rxjs'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

/**
 * @method connectBzbsGet
 * @method connectBzbsDelete
 * @method connectBzbsPostDataSci
 * @method connectBzbsPut
 * @method connectBzbsGetBlob
 */
export default {
  /**
   * Connect Buzzebees GET method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data (optional)
   * @return Observable
   */
  connectBzbsGet: function (url, data) {
    return this.connectMethod(url, data, 'get')
  },
  connectBzbsGetToken: function (url, token, data) {
    var header = {
      Authorization: 'Bearer ' + Cache.getCache('msToken'),
    }
    if (data == null) {
      data = {}
    }
    // data.device_app_id = this.config().client_id;
    if (token != null) {
      header.Authorization = 'Bearer ' + token
    }
    return axios({
      method: 'get',
      url: url,
      params: data,
      headers: header,
    })
  },

  /**
   * Connect Buzzebees DELETe method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data (optional)
   * @return Observable
   */
  connectBzbsDelete: function (url, data) {
    return this.connectMethod(url, data, 'delete')
  },
  connectBzbsDeleteData: function (url, data) {
    return this.connectMethodData(url, data, 'delete')
  },

  /**
   * Connect Buzzebees POST method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data parameter
   * @param {string} uploadKey (optional)
   * @param {object} uploadfile (optional)
   */
  // connectBzbsPostDataSci: function (url, data, uploadKey, uploadfile) {
  //     return this.connectWithBody(url, data, uploadKey, uploadfile, 'post');
  // },
  connectBzbsPostDataSci: function (url, token, data, uploadKey, uploadfile) {
    // data.device_app_id = this.config().client_id;
    var form = this.arrToFormData(data)
    if (uploadKey) form.append(uploadKey)
    if (uploadfile) {
      for (var i in uploadfile) {
        form.append(i, uploadfile[i])
      }
    }
    data.staffid = token ? token : Cache.getCache('msToken')
    return axios({
      method: 'post',
      url: url,
      data: data,
    })
  },
  connectBzbsGetBody: function (url, data, uploadKey, uploadfile) {
    var header = { 'Content-Type': 'application/json' }
    var form = this.arrToFormData(data)
    if (uploadKey) form.append(uploadKey)
    if (uploadfile) {
      for (var i in uploadfile) {
        form.append(i, uploadfile[i])
      }
    }
    header.Authorization = 'Bearer ' + Cache.getCache('msToken')
    return fetch(url, {
      method: 'GET',
      headers: new Headers(header),
    })
  },
  connectBzbsPost: function (url, data, uploadKey, uploadfile) {
    var header = {}
    var form = this.arrToFormData(data)
    if (uploadKey) form.append(uploadKey)
    if (uploadfile) {
      for (var i in uploadfile) {
        form.append(i, uploadfile[i])
      }
    }
    header.Authorization = 'Bearer ' + Cache.getCache('msToken')
    return axios({
      method: 'post',
      headers: header,
      url: url,
      data: data,
    })
  },
  connectBzbsPostRawData: function (url, data) {
    var header = {}

    header.Authorization = 'Bearer ' + Cache.getCache('msToken')

    return axios({
      method: 'post',
      url: url,
      data: data,
      headers: header,
    })
  },
  /**
   * Connect Buzzebees PUT method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data parameter
   * @param {string} uploadKey (optional)
   * @param {object} uploadfile (optional)
   */
  // connectBzbsPut: function(url, data, uploadKey, uploadfile) {
  //     // data.staffid = Cache.getCache('msToken')
  //     var token = Cache.getCache('msToken')
  //     return this.connectWithBody(
  //         url,
  //         token,
  //         data,
  //         uploadKey,
  //         uploadfile,
  //         "put"
  //     );
  // },
  connectBzbsPut: function (url, data, uploadKey, uploadfile) {
    var header = {}
    var form = this.arrToFormData(data)
    if (uploadKey) form.append(uploadKey)
    if (uploadfile) {
      for (var i in uploadfile) {
        form.append(i, uploadfile[i])
      }
    }
    header.Authorization = 'Bearer ' + Cache.getCache('msToken')
    return axios({
      method: 'put',
      headers: header,
      url: url,
      data: data,
    })
  },
  /**
   * Connect Buzzebees with Method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data parameter
   * @param {string} uploadKey (optional)
   * @param {object} uploadfile (optional)
   * @param {string} method post, put
   */
  connectWithBody: function (url, data, uploadKey, uploadfile, method) {
    var header = {}
    // data.device_app_id = this.config().client_id;
    var form = this.arrToFormData(data)
    if (uploadKey) form.append(uploadKey)
    if (uploadfile) {
      for (var i in uploadfile) {
        form.append(i, uploadfile[i])
      }
    }

    header.Authorization = 'Bearer ' + Cache.getCache('msToken')
    //   data.staffid = Cache.getCache('msToken')

    return axios({
      method: method,
      url: url,
      data: data,
      headers: header,
    })
  },

  /**
   * Connect Buzzebees with Method
   * @param {string} url string url
   * @param {string} token (optional)
   * @param {object} data (optional)
   * @param {string} method (required) get, delete
   * @return Observable
   */
  connectMethod: function (url, data, method) {
    var header = {
      // "App-Id": ConfigManager.getAppId()
      Authorization: 'Bearer ' + Cache.getCache('msToken'),
    }
    if (data == null) {
      data = {}
    }
    // data.device_app_id = this.config().client_id;
    // if (token != null) {
    //     header.Authorization = "token " + token;
    // }
    // data.staffid = Cache.getCache('msToken')
    return new Observable(subscriber => {
      axios({
        method: method,
        url: url,
        params: data,
        headers: header,
      })
        .then(res => {
          // subscriber.next(res);
          var data = this.convertResponse(res)

          if (data.isError) {
            subscriber.error(res)
          } else {
            subscriber.next(res)
          }
        })
        .catch(err => {
          subscriber.error(err)
        })
    })
  },
  connectMethodData: function (url, data, method) {
    var header = {
      // "App-Id": ConfigManager.getAppId()
      Authorization: 'Bearer ' + Cache.getCache('msToken'),
    }
    if (data == null) {
      data = {}
    }
    // data.device_app_id = this.config().client_id;
    // if (token != null) {
    //     header.Authorization = "token " + token;
    // }
    // data.staffid = Cache.getCache('msToken')
    return new Observable(subscriber => {
      axios({
        method: method,
        url: url,
        data: data,
        headers: header,
      })
        .then(res => {
          // subscriber.next(res);
          var data = this.convertResponse(res)

          if (data.isError) {
            subscriber.error(res)
          } else {
            subscriber.next(res)
          }
        })
        .catch(err => {
          subscriber.error(err)
        })
    })
  },

  setErrorObj: function (err) {
    var error = {
      error: err,
      id: null,
    }
    if (err.response && err.response.data) {
      if (err.response.data.error == undefined) return
      error = {
        id: err.response.data.error.id,
        code: err.response.data.error.code,
        message: err.response.data.error.message,
        type: err.response.data.error.type,
      }
    }
    return error
  },

  /**
   * Convert data to array of FormData
   * @param {Array|Dictionary} arr
   */
  arrToFormData: function (arr) {
    var form = new FormData()
    for (var i in arr) {
      form.append(i, arr[i])
    }
    return form
  },

  /**
   * Connect Buzzebees GET Blob
   * @param {string} url string url
   * @return Promise
   */
  // connectBzbsGetBlob: function(url) {
  //     return new Observable(subscriber => {
  //         axios({
  //             method: "get",
  //             url: url
  //         })
  //             .then(res => {
  //                 subscriber.next(res);
  //             })
  //             .catch(err => {
  //                 subscriber.error(this.setErrorObj(err));
  //             });
  //     });
  // },

  connectBzbsGetBlob: function (url) {
    return axios({
      method: 'get',
      url: url,
    })
  },

  convertResponse: function (res) {
    var data = res.data
    if (data.success === true || data.success === false) {
      return {
        isError: data.success ? false : true,
        data: data,
      }
    } else {
      return {
        isError: false,
        data: data,
      }
    }
  },
}
