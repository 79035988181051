<template>
  <a-card class="w-full min-h-full userlist-tag">
    <nav class="flex justify-between mb-3">
      <a-input
                  v-model:value="search"
                  placeholder="Search taggroup"
                  @pressEnter="clicksearch()"
                >
                  <template #prefix>
                    <a @click="clicksearch()">
                      <search-outlined />
                    </a>
                  </template>
                </a-input>
      <a-button
        v-if="datapermissionbyapp.includes('User-List-Tag-Adjust')"
        type="primary"
        @click="toggleDisplayFieldsAddtag()"
      >
        + Add more group Tags
      </a-button>
    </nav>
    <div class="mainContainer">
      <div class="wrap-table" @scroll="handleScroll">
        <!-- <div>
          <div class="row">
            <div
              v-for="(itemgroup2, index) of dataTags"
              :key="`index-${index}`"
              class="col-3"
            >
              <div class="tag-group mb-3">
                <h4>{{ itemgroup2.tag_group }}</h4>
                <div class="d-flex flex-wrap">
                  <div
                    v-for="(itemtype, idx) of itemgroup2.user_tags"
                    :key="`idx-${idx}`"
                  >
                    <div class="mt-2 d-flex flex-warp">
                      <a-tag style="flex-grow: 1">{{
                        itemtype.tag_type
                      }}</a-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div v-for="(itemgroup, index) of dataTags" :key="`index-${index}`">
          <div class="row">
            <div class="col-3"></div>
          </div>
          <div class="tag-group mb-3">
            <div class="d-flex">
              <h4>{{ itemgroup.tag_group }}</h4>
            </div>
            <div class="d-flex mt-3">
              <div
                v-for="(itemtype, idx) of itemgroup.user_tags"
                :key="`idx-${idx}`"
              >
                <a-tag>{{ itemtype.tag_type }}</a-tag>
              </div>
            </div>
          </div>
        </div> -->
        <div v-for="(itemgroup, index) of dataTags" :key="`index-${index}`">
          <div class="tag-group mb-3">
            <div class="d-flex align-items-center">
              <h4 class="pr-2">
                {{ itemgroup.tag_group }}
              </h4>
              <a-dropdown
                v-if="datapermissionbyapp.includes('User-List-Tag-Adjust')"
              >
                <a-button
                  class="btn-icon d-flex justify-content-center align-items-center ms-auto more"
                  ><more-outlined
                /></a-button>
                <template #overlay>
                  <a-menu>
                    <!-- <a-menu-item @click="editTag(itemgroup)">
                          Add Tag Type
                        </a-menu-item> -->
                    <a-menu-item
                      @click="saveTagsGroupRemove(itemgroup.tag_group)"
                    >
                      Delete Tag Group
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
            </div>
            <hr class="line-tag" />
            <div class="list-type">
              <div class="d-flex align-items-center gap-2">
                <tags-outlined class="text-primary-p" />
                <div>Tag Type:</div>
              </div>
              <div
                v-for="(itemtype, idx) of itemgroup.user_tags"
                :key="`idx-${idx}`"
              >
                <a-tag
                  ><a-badge status="success" />{{ itemtype.tag_type }}
                  <a
                    v-if="datapermissionbyapp.includes('User-List-Tag-Adjust')"
                    @click="saveTagsRemove(itemtype.tag_type)"
                    >x</a
                  ></a-tag
                >
              </div>
            </div>
          </div>
        </div>
        <!-- <VLoadingTable :value="overlaytable" />
        <a-table
          bordered
          row-key="id"
          class="fixed-head table-tag"
          :dataSource="dataTags"
          :columns="tagcolumn"
          :pagination="false"
        >
          <template #tag_group="{ record }">
            <a class="link" @click="toggleRemoveTag(record)">{{
              record.tag_group
            }}</a>
          </template>
          <template #tag_type="{ record }">
            <div
              v-if="record.user_tags"
              :class="
                datapermissionbyapp.includes('User-List-Tags-Edit')
                  ? ''
                  : 'pointer-events-none'
              "
            >
              <div class="d-flex flex-wrap gap-2">
                <div
                  v-for="(itemtype, idx) of record.user_tags"
                  :key="`idx-${idx}`"
                  class=""
                >
                  <div>
                    <a-tag @close="log(itemtype.tag_type)">{{
                      itemtype.tag_type
                    }}</a-tag>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!record.user_tags">-</div>
          </template>
          <template #action="{ record }">
            <div
              v-if="dataTagtypes.some(x => x.row_key == record.tag_group)"
              class="action"
            >
              <a-button type="primary" @click="toggleAddTag(record)" ghost
                ><plus-outlined /> Add Tag</a-button
              >
            </div>
          </template>
        </a-table> -->
      </div>
      <!-- <div class="text-center mt-3 pb-3 width-fill-full">
        <a-button
          v-if="datapermissionbyapp.includes('User-List-Tags-Edit')"
          class="btn-addgroup b-update"
          @click="toggleDisplayFieldsAddtag()"
        >
          <plus-outlined />
          Add more group Tags
        </a-button>
      </div> -->
    </div>
  </a-card>
  <a-modal
    :visible="modalEditAndCreatetag"
    title="Add tag"
    okText="Save"
    :width="500"
    :centered="true"
    :closable="false"
    @ok="saveTags()"
    @cancel="handleCancel()"
  >
    <div>
      <div>Tag Group:</div>
      <a-select
        v-model:value="tag_group"
        :options="options_tag_group"
        class="width-fill-full mt-2"
        placeholder="Please Select Tag Group"
        @change="selectTag(tag_group)"
      ></a-select>
      <div class="mt-3">Tag Type:</div>
      <a-select
        v-model:value="tag_name"
        :options="options_tag_name"
        :disabled="!tag_group"
        mode="tags"
        placeholder="Please Enter Tags name"
        class="width-fill-full mt-2"
      ></a-select>
      <!-- <a-input
        class="mt-3"
        placeholder="Please enter Tags name"
        :disabled="!tag_group_options"
        v-model:value="tag_name"
      /> -->
    </div>
  </a-modal>
  <a-modal
    :visible="modalAddtag"
    @cancel="handleCancel"
    okText="Save"
    :width="500"
    :centered="true"
    @ok="saveTags()"
    :closable="false"
  >
    <template #title>
      <div>
        Add tag
        <label class="fs-14"
          >( Tag Group : {{ detailaddtag.tag_group }} )</label
        >
      </div>
    </template>
    <div class="mb-4">
      <div class="mb-2">Select Tag Type :</div>
      <a-select
        v-model:value="tag_name"
        :options="options_tag_name"
        mode="tags"
        class="width-fill-full"
      ></a-select>
    </div>
  </a-modal>
  <a-modal
    :visible="modalRemovetag"
    @cancel="handleCancel"
    okText="Save"
    :width="500"
    :centered="true"
    wrap-class-name="modal-tag"
    @ok="saveTagsRemove()"
    :closable="false"
  >
    <template #title>
      <div>
        Remove Tag
        <label class="fs-14">( Tag Group : {{ detailtag.tag_group }} )</label>
      </div>
    </template>
    <div class="mb-4">
      <div class="mb-2">Tag Type :</div>

      <div v-if="detailtag.user_tags">
        <div class="d-flex flex-wrap gap-2 tag-type">
          <div
            v-for="(itemtype, idx) of detailtag.user_tags"
            :key="`idx-${idx}`"
          >
            <a-tag
              >{{ itemtype.tag_type }}
              <close-outlined
                class="close"
                @click="log(itemtype.tag_type, idx)"
            /></a-tag>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <DisplayFieldsAddTag
    :value="showDisplayFieldsAddTag"
    :dataTags="dataTags"
    @getTags="getTags($event)"
    @handleClose="showDisplayFieldsAddTag = false"
  >
  </DisplayFieldsAddTag>
</template>

<script>
// import VInputText from '@/components/Input/label/InputText.vue'
// import VInputTextInlineLabel from '@/components/Input/label/InputTextInlineLabel.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import DisplayFieldsAddTag from '@/module/User/component/DisplayFieldsAddTag.vue'
import _ from 'lodash'
// import moment from 'moment'
import { DeleteOutlined } from '@ant-design/icons-vue'
import { FormHelper } from '@/helper/FormHelper'

export default {
  components: {
    DisplayFieldsAddTag,
    // VInputTextInlineLabel,
    // VInputText,
  },
  data() {
    return {
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      showDisplayFieldsAddTag: false,
      errInfo: FormHelper.genErrorInfo(10),
      currentType: '',
      activeKey: [],
      userdetail: [],
      currenttab: '',
      formatTimestamp: 'DD MMM YYYY HH:MM',
      disable: true,
      modalEditAndCreatetag: false,
      tagcolumn: [
        {
          title: 'Tag Group',
          dataIndex: 'tag_group',
          key: 'tag_group',
          slots: { customRender: 'tag_group' },
          width: 200,
          align: 'left',
        },
        {
          title: 'Tag Type',
          dataIndex: 'tag_type',
          key: 'tag_type',
          slots: { customRender: 'tag_type' },
          width: 800,
          align: 'left',
        },
        {
          title: '',
          dataIndex: 'action',
          key: 'action',
          slots: { customRender: 'action' },
          width: 100,
          align: 'center',
        },
      ],
      dataTags: [],
      tag_types_remove: [],
      modalAddtag: false,
      options_tag_group: [],
      tag_group: null,
      options_tag_name: [],
      tag_name: [],
      modalRemovetag: false,
      dataTagtypes: [],
      detailtag: {},
      detailaddtag: {},
      tag_group_options: null,
      options_tag_type: [],
      allTypes: [],
      search: '',
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'tags') {
        this.$store.commit('setLoading', true)
        this.getTags().then(() => {
          this.$store.commit('setLoading', false)
        })
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'tags') {
      this.$store.commit('setLoading', true)
      this.getTags().then(() => {
        this.$store.commit('setLoading', false)
      })
    }
  },
  methods: {
    log(itemremove, idx) {
      this.detailtag.user_tags = this.detailtag.user_tags.filter(
        (x, index) => index != idx,
      )
      console.log(this.detailtag.user_tags)
      this.$forceUpdate()
      this.tag_types_remove.push(itemremove)
    },
    clicksearch(){
      this.getTags().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getTags() {
      this.$store.commit('setLoading', true)
      this.getTagTypes()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTags(this.$route.params.id).subscribe(
          res => {
            this.dataTags = []
            _.forEach(res.result, item => {
              this.dataTags.push(item)
            })
            let datafilter = _.filter(this.dataTags, app => {
                if (
                  app.tag_group.toLowerCase().includes(this.search ? this.search.toLowerCase() : this.search)
                ) {
                  return app
                }
            })
            this.dataTags = datafilter
            this.$store.commit('setLoading', false)
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getTagTypes() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTagTypes().subscribe(
          res => {
            this.dataTagtypes = res.result
            _.forEach(this.dataTagtypes, (item, index) => {
              this.options_tag_group[index] = {
                label: item.row_key,
                value: item.row_key,
              }
            })
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleRemoveTag(detail) {
      console.log(detail)
      this.detailtag = { ...detail }
      this.modalRemovetag = true
      this.$forceUpdate()
    },
    saveTagsRemove(tagtype) {
      var datatagtypes = {
        appid: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        tag_types: [tagtype],
        delete_multi: false,
      }
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you want to remove this tag type ?',
        content:
          'This action cannot be undone. Deleting this tag will permanently remove it from the system.',
        okText: 'Yes, Delete',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostTagsRemove(datatagtypes)
        },
      })
    },
    saveTagsGroupRemove(group) {
      var datatagtypes = {
        appid: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        tag_types: [group],
        delete_multi: true,
      }
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Are you want to remove this tag group ?',
        content: 'This tag group will remove from user.',
        okText: 'Yes, Remove',
        cancelText: 'No',
        width: '480px',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.PostTagsRemove(datatagtypes)
        },
      })
    },
    PostTagsRemove(datatagtypes) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTagsRemove(datatagtypes)
          .then(res => {
            // this.visible = false
            this.modalRemovetag = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getTags()
                this.tag_types_remove = []
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },

    toggleDisplayFieldsAddtag() {
      this.getTagtypeAll()
      this.tag_group = null
      this.tag_name = []
      this.modalEditAndCreatetag = true
    },
    selectTag(tag_group) {
      this.tag_name = []
      this.options_tag_name = []
      var itemtag = this.dataTagtypes.find(x => x.row_key == tag_group)
      _.forEach(itemtag.tag_types, item => {
        if (!this.allTypes.includes(item.row_key)) {
          this.options_tag_name.push({
            label: item.row_key,
            value: item.row_key,
          })
        }
      })
    },
    toggleAddTag(record) {
      this.detailaddtag = { ...record }
      this.tag_name = []
      this.getTagtypeAll()
      this.selectTag(record.tag_group)
      this.modalAddtag = true
    },
    getTagtypeAll() {
      this.allTypes = this.dataTags
        .map(x => {
          return x.user_tags.map(z => z.tag_type)
        })
        .flat()
    },
    saveTags() {
      var datatagtypes = {
        appid: ConfigManager.getAppId(),
        user_id: this.$route.params.id,
        tag_group: this.tag_group,
        tag_types: this.tag_name,
      }
      this.PostTagTypes(datatagtypes)
    },
    PostTagTypes(datatagtypes) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTags(datatagtypes)
          .then(res => {
            // this.visible = false
            this.modalEditAndCreatetag = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.getTags()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleCancel() {
      this.modalEditAndCreatetag = false
      this.modalAddtag = false
      this.modalRemovetag = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/_mixin.scss';
@import '@/style/base/base.scss';

.userlist-tag {
  .b-update {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    font-weight: 600;
  }
  .wrap-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    height: calc(100vh - 340px);

    .ant-table-wrapper {
      min-width: 1000px;
      width: 100%;
    }
  }

  .wrap-table-noper {
    display: block;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100vh - 290px);

    .ant-table-wrapper {
      min-width: 1000px;
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.userlist-tag {
  .anticon {
    vertical-align: inherit !important;
  }
  .btn-addgroup {
    border-style: dashed !important;
    height: 53px !important;
  }
  .tag-group {
    background-color: #f5f5f5;
    border: 1px solid #c9c9c9;
    border-radius: 6px;
    padding: 16px;
    overflow: hidden;
  }
  .action {
    .ant-btn {
      min-width: 100px;
      height: 29px;
      padding: 1px 8px 4px 8px;
      svg {
        width: 16px;
      }
    }
  }
}
.modal-tag {
  .close {
    font-size: 10px !important;
    cursor: pointer !important;
  }
}
.line-tag {
  margin: 8px 0 8px 0 !important;
  height: 2px !important;
  background-color: #c9c9c9 !important;
  opacity: 0.6 !important;
}
</style>
