<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs
        class="mt-4"
        title="Survey name: 5678 Cosmetic - Clearance -2021 August "
        :parent="parent"
        :databreadcrumbs="breadcrumbs"
      />
    </template>
    <div class="main-menu approvesurvey overflow-x-hidden flex flex-col">
      <div class="flex-1 w-full px-4">
        <div class="table-scroll">
          <nav class="nav-search">
            <div class="d-flex justify-content-between">
              <div>
                <a-input-group class="search" compact>
                  <a-select
                    class="select-search"
                    v-model:value="typesearch"
                    :options="options_search"
                  />
                  <a-input
                    v-model:value="search"
                    placeholder="Enter Receipt Number"
                    class="input-search"
                  />
                </a-input-group>
                <a-select
                  class="select-status"
                  v-model:value="statusdata"
                  placeholder="All Status"
                  :options="statusdata_dropdown"
                />
                <a-button
                  :disabled="
                    (startValue && !endValue) || (!startValue && endValue)
                  "
                  type="primary"
                  class="btn-search"
                  @click="clicksearchprivacy()"
                  ><search-outlined />Search</a-button
                >
              </div>
              <div>
                <a-button type="teriary"
                  ><file-excel-outlined /> Export as excel</a-button
                >
              </div>
            </div>
          </nav>
          <div class="row">
            <div class="col-4">
              <div class="bg-white bg-list">
                <div class="px-3">
                  <div class="h-date">
                    <div class="row">
                      <div class="col-1 align-self-center">
                        <h4>#1</h4>
                      </div>
                      <div class="col-7">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex">
                            <h4 class="pr-3">BZB33556622</h4>
                            <template v-for="(item, index) in status">
                              <a-tag
                                v-if="'Approved' == item.name"
                                :key="index"
                                :color="
                                  item.color == 'default' ? null : item.color
                                "
                                class="tag-status"
                              >
                                <a-badge :status="item.color" text="Approved" />
                              </a-tag>
                            </template>
                          </div>
                        </div>
                        <div class="mt-2">
                          <div class="text-gray">
                            Redemption Date: 30 / Apr / 2022 - 23:59
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-right">
                        <div>Courtney Henry</div>
                        <div>089-191-1919</div>
                      </div>
                    </div>
                  </div>
                  <div class="h-date">
                    <div class="row">
                      <div class="col-1 align-self-center">
                        <h4>#2</h4>
                      </div>
                      <div class="col-7">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex">
                            <h4 class="pr-3">BZB33556622</h4>
                            <template v-for="(item, index) in status">
                              <a-tag
                                v-if="'Waiting' == item.name"
                                :key="index"
                                :color="
                                  item.color == 'default' ? null : item.color
                                "
                                class="tag-status"
                              >
                                <a-badge :status="item.color" text="Waiting" />
                              </a-tag>
                            </template>
                          </div>
                        </div>
                        <div class="mt-2">
                          <div class="text-gray">
                            Redemption Date: 30 / Apr / 2022 - 23:59
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-right">
                        <div>Courtney Henry</div>
                        <div>089-191-1919</div>
                      </div>
                    </div>
                  </div>
                  <div class="h-date">
                    <div class="row">
                      <div class="col-1 align-self-center">
                        <h4>#3</h4>
                      </div>
                      <div class="col-7">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex">
                            <h4 class="pr-3">BZB33556622</h4>
                            <template v-for="(item, index) in status">
                              <a-tag
                                v-if="'Rejected' == item.name"
                                :key="index"
                                :color="
                                  item.color == 'default' ? null : item.color
                                "
                                class="tag-status"
                              >
                                <a-badge :status="item.color" text="Rejected" />
                              </a-tag>
                            </template>
                          </div>
                        </div>
                        <div class="mt-2">
                          <div class="text-gray">
                            Redemption Date: 30 / Apr / 2022 - 23:59
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-right">
                        <div>Courtney Henry</div>
                        <div>089-191-1919</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <div class="bg-white bg-detail">
                <div class="content-detail p-3">
                  <div class="total">
                    <div>Info Details</div>
                    <div class="row">
                      <div class="col-3">
                        <div>Jira Wangtorlabh</div>
                      </div>
                      <div class="col-3">
                        <div>Contact number: 089-919-9218</div>
                      </div>
                      <div class="col-6">
                        <div>
                          Address: Asthon Nonthaburi 41/12 Moo.3 Salaklang
                          Bangkouy Nonthaburi 11130
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-3">
                    <div class="d-flex">
                      <div class="number mr-2">1</div>
                      <div>What super power do you wish you had?</div>
                    </div>
                    <div class="ml-num">Lorem ipsum dolor sit</div>
                    <div class="d-flex mt-3">
                      <div class="number mr-2">2</div>
                      <div>Succession Planning Survey</div>
                    </div>
                    <div class="ml-num">feeling afraid to express emotions</div>
                    <div class="d-flex mt-3">
                      <div class="number mr-2">3</div>
                      <div>Succession Planning Survey</div>
                    </div>
                    <div class="ml-num">
                      aving feelings of sadness, anger, or depressed thoughts
                      most days of the week
                    </div>
                    <div class="d-flex mt-3">
                      <div class="number mr-2">4</div>
                      <div>Upload photos of your cars</div>
                    </div>
                    <div class="ml-num">
                      <a-image :width="120" :src="demoimg" />
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    datapermissionbyapp.includes('Approve-List-Survey-Edit')
                  "
                  class="p-3 d-flex border-t"
                >
                  <a-button type="primary" @click="confirmAction(true)"
                    >Approve</a-button
                  >
                  <a-button
                    type="danger"
                    class="ml-3"
                    @click="confirmAction(false)"
                    >Reject</a-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  <a-modal
    v-model:visible="showModalConfirm"
    width="480px"
    wrap-class-name="modal-markuse"
    :closable="false"
    :centered="true"
    okText="Confirm"
    @ok="MarkUsed()"
    @cancel="closeModal"
  >
    <div class="d-flex">
      <question-circle-outlined class="ico" />
      <div class="ml-5">
        <h3 v-if="action" class="mt-2">Are you sure to approve?</h3>
        <h3 v-else class="mt-2">Are you sure to reject?</h3>
      </div>
    </div>
    <div v-if="action" class="mt-1">
      You are about to approve survey this user account.
    </div>
    <div v-else class="mt-1">
      You are about to reject survey this user account.
    </div>
    <div class="d-flex mt-4">
      <div>
        <label class="false" v-if="!action">*</label> Remark
        <label v-if="!action">:</label>
      </div>
      <div v-if="action" class="text-gray ml-1">(Optional) :</div>
    </div>
    <a-textarea
      v-model:value="remark"
      :maxlength="80"
      :auto-size="{ minRows: 3, maxRows: 3 }"
    />
  </a-modal>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
// import _ from 'lodash'
// import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
import moment from 'moment'
import dayjs from 'dayjs'
import { FileExcelOutlined } from '@ant-design/icons-vue'

export default {
  name: 'UserList',
  components: {
    // Footer,
    // CalendarOutlined,
    // SearchOutlined,
    AppLayout,
    TitleBreadcrumbs,
    FileExcelOutlined,
  },
  data: function () {
    return {
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      demoimg: require('@/assets/imgs/imgdemo-survey.png'),
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveDataPrivacy: false,
      searchObject: {
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting Confirm',
          value: 'Waiting',
        },
        {
          label: 'Approved',
          value: 'Approved',
        },
        {
          label: 'Rejected',
          value: 'Rejected',
        },
      ],
      typesearch: 'user_id',
      options_search: [
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'User Name',
          value: 'user_name',
        },
        {
          label: 'Contact number',
          value: 'contact_number',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Rejected',
          color: 'red',
        },
        {
          id: 3,
          name: 'Approved',
          color: 'green',
        },
      ],
      datalists: [],
      dataApproveList: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetails: [],
      id: '',
      datalist: [],
      parent: {
        query: '5',
      },
      showModalConfirm: false,
      action: true,
      remark: '',
    }
  },
  computed: {
    breadcrumbs: function () {
      return [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name: 'approvepoint',
          page: '5',
          breadcrumbName: 'Approve List',
        },
        {
          name: null,
          breadcrumbName: '5678 Cosmetic - Clearance -2021 August',
        },
      ]
    },
  },
  mounted() {
    this.$store.commit('setLoading', false)
    // this.clicksearchprivacy()
  },
  watch: {},
  methods: {
    confirmAction(action) {
      this.action = action
      this.showModalConfirm = !this.showModalConfirm
    },
    closeModal() {
      this.remark = ''
      this.showModalConfirm = false
    },
    textStatus(status) {
      switch (status) {
        case 'Waiting':
          return 'Created'
        case 'Approved':
          return 'Approved'
        case 'Rejected':
          return 'Rejected'
      }
    },
    clicksearchprivacy() {
      this.$store.commit('setLoading', true)
      this.searchObject.statusdata = this.statusdata
      this.page = 1
      this.showDateFilter = false
      this.setmanagepermission()
      this.getApprovePrivacylist().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getApprovePrivacylist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApproveListByID(id) {
      this.id = id
      this.getdataDetails().then(() => {
        this.checkData()
        this.showDisplayFieldsApproveDataPrivacy =
          !this.showDisplayFieldsApproveDataPrivacy
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    setmanagepermission() {
      if (this.datapermission.features.every(x => x.name != 'Manage-Privacy')) {
        return (this.managepermission = false)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.approvesurvey {
  overflow-x: hidden;
  .table-scroll {
    // background: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 195px);
    max-height: calc(100vh - 195px);
  }
  .bg-list {
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 195px);
    max-height: calc(100vh - 195px);
  }
  .bg-detail {
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 195px);
    max-height: calc(100vh - 195px);
    .content-detail {
      overflow: auto;
      min-height: calc(100vh - 340px);
      max-height: calc(100vh - 340px);
    }
  }
  .select-status {
    width: 150px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .total {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
  }
  .number {
    width: 23px;
    border-radius: 100px;
    text-align: -webkit-center;
    background-color: #f9a601;
    color: #fff;
  }
  .ml-num {
    margin-top: 15px;
    margin-left: 30px;
    background-color: #f5f5f5;
    display: inline-block;
    padding: 2px;
    color: #636363;
    font-weight: 600;
    border-radius: 4px;
  }
  .h-date {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
  }
  .img-detail {
    background-color: #f5f5f5;
  }
  .nav-search {
    background-color: #fff;
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;
    .search {
      float: left;
      width: auto;
      .input-search {
        width: 200px;
        border-left: none;
      }
      .select-search {
        width: 130px;
      }
    }
    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      padding-right: 10px;
      padding-left: 10px;
    }
    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }
  }
}
</style>
<style lang="scss">
.approvesurvey {
  .datefilter {
    left: 0 !important;
    right: unset !important;
  }
}
</style>
